import React from 'react';
import { getHeadCells } from '../../../../../components/Headcell/Headcell';
import { DashboardCustomTable } from '../../../../../components/Table';

const UserByAgeTable = ({ data, currencyCode = 'ZZZ' }) => {
  if (data.length === 0) {
    return [];
  }

  // Separate "Total" row from the data
  const totalRow = data.find((row) => row['Age Group'] === 'Total');
  const filteredData = data.filter((row) => row['Age Group'] !== 'Total');

  if (filteredData.length === 0) {
    return null; // Return null or another fallback if filteredData is empty
  }

  const firstDataItem = filteredData[0];
  const defaultFormatFunction = (row, key) => String(row[key]);
  const excludedKeys = [];

  return (
    <DashboardCustomTable
      headCells={getHeadCells(
        firstDataItem,
        excludedKeys,
        currencyCode,
        defaultFormatFunction
      )}
      rows={filteredData}
      totalRow={totalRow}
      rowIdField='Age Group'
      verticalBorders
    />
  );
};
export { UserByAgeTable };
