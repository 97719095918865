/* eslint-disable no-unused-vars */
import { authInstance } from '../api/authentication.instance';
import { openInstance } from '../api/open.instance';
import {
  projectFeedListURI,
  createprojectFeedListURI,
  importedListURI,
  feedListURI,
  createImportFormURI,
  getFileMappingUri,
  downloadFileUri,
  createFeedFormURI,
  scheduleSettingsURI,
  qualityURI,
  filterListURI,
  actionListURI,
  scheduleFormURI,
  demoQualityURI,
} from '../constants/endpoints';

// demo quality page get data from url
const getURLDataDemoQuality = (data) => openInstance.post(demoQualityURI, data);

// all get requests
const getProjectList = (data) =>
  authInstance.get(projectFeedListURI(data.company_id));

const getimportedList = (data) =>
  authInstance.get(importedListURI(data.company_id, data.feedProjectId));

const getFeedList = (data) =>
  authInstance.get(feedListURI(data.company_id, data.projectId));

const getImportForm = (data) =>
  authInstance.get(importedListURI(data.company_id, data.projectId), {
    params: {
      file_id: data.fileId,
    },
  });
const getFileMapping = ({ companyId, projectId, fileId }) =>
  authInstance.get(getFileMappingUri(companyId, projectId), {
    params: {
      file_id: fileId,
    },
  });
// const getAllItems = ({ companyId, projectId, pageNo }) =>
//   authInstance.get(`api/feed-management/list-items/${companyId}/${projectId}`, {
//     params: {
//       page: pageNo,
//     },
//   });

const getEnhanceListItems = ({ companyId, projectId, feedId, pageNo }) =>
  authInstance.get(`api/feed-management/enhance/${companyId}/${projectId}`, {
    params: {
      feed_id: feedId,
      page: pageNo,
    },
  });

const getFeedForm = (data) =>
  authInstance.get(feedListURI(data.company_id, data.projectId), {
    params: {
      feed_id: data.feedId,
    },
  });

const getProjectForm = (data) =>
  authInstance.get(projectFeedListURI(data.company_id), {
    params: {
      feed_project_id: data.projectId,
    },
  });

const getQualityForm = ({ companyId, projectId, feedId }) =>
  authInstance.get(qualityURI(companyId, projectId), {
    params: {
      feed_id: feedId,
    },
  });

const getFilterModal = (data) =>
  authInstance.get(filterListURI(data.company_id, data.projectId), {
    params: {
      feed_id: data.feedId,
    },
  });

const getActionModal = (data) =>
  authInstance.get(actionListURI(data.company_id, data.projectId), {
    params: {
      feed_id: data.feedId,
    },
  });

const getFeedPreview = ({ companyId, feedProjectId, feedId }) =>
  authInstance.get(
    `api/feed-management/preview/${companyId}/${feedProjectId}`,
    {
      params: {
        feed_id: feedId,
      },
    }
  );

const getChannelFields = ({ companyId, feedProjectId, feedId }) =>
  authInstance.get(
    `api/feed-management/channel-fields/${companyId}/${feedProjectId}`,
    {
      params: {
        feed_id: feedId,
      },
    }
  );
const getProjectFields = ({ companyId, feedProjectId, feedId }) =>
  authInstance.get(
    `api/feed-management/project-fields/${companyId}/${feedProjectId}`
  );

const getFilterDetails = ({ companyId, feedProjectId, feedId, filterId }) =>
  authInstance.get(`api/feed-management/filter/${companyId}/${feedProjectId}`, {
    params: {
      feed_id: feedId,
      filter_id: filterId,
    },
  });

const getActionDetails = ({ companyId, feedProjectId, actionId, feedId }) =>
  authInstance.get(`api/feed-management/action/${companyId}/${feedProjectId}`, {
    params: {
      feed_id: feedId,
      action_id: actionId,
    },
  });

//  all create/post requests
const addProjectName = (data) =>
  authInstance.post(createprojectFeedListURI, data);

const postImportForm = (data) => authInstance.post(createImportFormURI, data);

const postFeedForm = (data) => authInstance.post(createFeedFormURI, data);

const postScheduleForm = (data) => authInstance.post(scheduleSettingsURI, data);

const patchScheduleForm = ({ companyId, projectId, data }) =>
  authInstance.put(`api/feed-management/schedule/${companyId}/${projectId}`, {
    schedule_id: data.schedule_id,
    time_interval: data.time_interval,
    timezone: data.timezone,
    repeat: data.repeat,
    enabled: true,
  });

const downloadFile = (companyId, projectId, fileId) =>
  authInstance.get(downloadFileUri(companyId, projectId), {
    params: {
      file_id: fileId,
    },
  });

const addProjectField = ({
  companyId,
  projectId,
  fileId,
  name,
  dataType,
  isList,
  requirements,
}) =>
  authInstance.post('/api/feed-management/fields', {
    company_id: companyId,
    feed_project_id: projectId,
    file_id: fileId,
    name,
    data_type: dataType,
    is_list: isList,
    requirements,
  });

const createAction = ({ projectId, companyId, feedId, actionData }) =>
  authInstance.post(`/api/feed-management/action`, {
    feed_project_id: projectId,
    company_id: companyId,
    feed_id: feedId,
    ...actionData,
  });

const createfilter = ({ projectId, companyId, feedId, filterData }) =>
  authInstance.post(`/api/feed-management/filter`, {
    feed_project_id: projectId,
    company_id: companyId,
    feed_id: feedId,
    ...filterData,
  });

const editFilter = ({ projectId, companyId, filterData, filterId }) =>
  authInstance.patch(`api/feed-management/filter/${companyId}/${projectId}`, [
    filterData,
  ]);

const editMultipleFilters = ({ projectId, companyId, filters }) =>
  authInstance.patch(
    `api/feed-management/filter/${companyId}/${projectId}`,
    filters
  );

const editAction = ({ projectId, companyId, actionData, actionId }) =>
  authInstance.patch(`api/feed-management/action/${companyId}/${projectId}`, [
    actionData,
  ]);

const editMultipleActions = ({ projectId, companyId, actions }) =>
  authInstance.patch(
    `api/feed-management/action/${companyId}/${projectId}`,
    actions
  );

// edit

const editMapping = ({ companyId, projectId, fileId, fields, combineScheme }) =>
  authInstance.put(
    `api/feed-management/project-fields/${companyId}/${projectId}`,
    {
      file_id: fileId,
      fields,
      combine_scheme: combineScheme,
    }
  );

// delete
const deleteField = ({ companyId, projectId, id }) =>
  authInstance.delete(
    `/api/feed-management/project-fields/${companyId}/${projectId}`,
    {
      data: {
        project_field_id: id,
      },
    }
  );

   

// all delete requests
const deleteImportedFile = (companyId, projectSelectId, data) =>
  authInstance.delete(importedListURI(companyId, projectSelectId), {
    data: { file_id: data.file_id },
  });

const deleteFeedListFile = (companyId, projectSelectId, data) =>
  authInstance.delete(feedListURI(companyId, projectSelectId), {
    data: { feed_id: data.feed_id },
  });

// all patch requests
const patchActivateImportedFile = (companyId, projectSelectId, data) =>
  authInstance.patch(importedListURI(companyId, projectSelectId), {
    file_id: data.file_id,
    active: data.active,
  });

const patchDeactivateImportedFile = (companyId, projectSelectId, data) =>
  authInstance.patch(importedListURI(companyId, projectSelectId), {
    file_id: data.file_id,
    active: data.active,
  });

const patchActivateFeedListFile = (companyId, projectSelectId, data) =>
  authInstance.patch(feedListURI(companyId, projectSelectId), {
    feed_id: data.feed_id,
    active: data.active,
  });

const patchDeactivateFeedListFile = (companyId, projectSelectId, data) =>
  authInstance.patch(feedListURI(companyId, projectSelectId), {
    feed_id: data.feed_id,
    active: data.active,
  });

const patchImportForm = (companyId, ProjectId, createPatchData) =>
  authInstance.patch(importedListURI(companyId, ProjectId), createPatchData);

const patchFeedForm = (companyId, ProjectId, createPatchData) =>
  authInstance.patch(feedListURI(companyId, ProjectId), createPatchData);

const patchProjectForm = (companyId, createPatchData) =>
  authInstance.patch(projectFeedListURI(companyId), createPatchData);

const patchQuality = ({ companyId, projectId, feedId, fields }) =>
  authInstance.put(
    `api/feed-management/channel-fields/${companyId}/${projectId}`,
    {
      feed_id: feedId,
      fields,
    }
  );

const deleteAction = ({ companyId, projectId, actionId }) =>
  authInstance.delete(`api/feed-management/action/${companyId}/${projectId}`, {
    data: {
      action_id: actionId,
    },
  });

const deleteFilter = ({ companyId, projectId, filterId }) =>
  authInstance.delete(`api/feed-management/filter/${companyId}/${projectId}`, {
    data: {
      filter_id: filterId,
    },
  });

const deleteProject = (companyId, feedProjectId) =>
  authInstance.delete(`api/feed-management/project/${companyId}`, {
    data: {
      feedproject_id: feedProjectId,
    },
  });

const enhanceFeed = ({ companyId, projectId, feedId }) =>
  authInstance.post('/api/feed-management/enhance', {
    company_id: companyId,
    feed_project_id: projectId,
    feed_id: feedId,
  });
const getScheduleForm = (data) =>
  authInstance.get(scheduleFormURI(data.company_id, data.projectId));

const finalizeXML = (companyId, projectId, feedId) =>
  authInstance.get(
    `/api/feed-management/download-feed/${companyId}/${projectId}`,
    {
      params: {
        feed_id: feedId,
      },
    }
  );

const feedManagementService = {
  getProjectList,
  addProjectName,
  getimportedList,
  getFeedList,
  postImportForm,
  getImportForm,
  getFileMapping,
  downloadFile,
  addProjectField,
  editMapping,
  deleteField,
  // getAllItems,
  deleteImportedFile,
  postFeedForm,
  patchActivateImportedFile,
  patchDeactivateImportedFile,
  deleteFeedListFile,
  patchActivateFeedListFile,
  patchDeactivateFeedListFile,
  patchImportForm,
  getFeedForm,
  patchFeedForm,
  getProjectForm,
  getEnhanceListItems,
  getFeedPreview,
  getChannelFields,
  createAction,
  createfilter,
  getProjectFields,
  patchProjectForm,
  postScheduleForm,
  getQualityForm,
  getFilterModal,
  getActionModal,
  getScheduleForm,
  getFilterDetails,
  getActionDetails,
  editAction,
  editFilter,
  deleteAction,
  deleteFilter,
  editMultipleFilters,
  editMultipleActions,
  enhanceFeed,
  deleteProject,
  patchQuality,
  finalizeXML,
  patchScheduleForm,
  getURLDataDemoQuality,
  // deleteImportedField
};

export { feedManagementService };
