import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableFooter,
  Collapse,
  Stack,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// eslint-disable-next-line import/no-extraneous-dependencies
import { visuallyHidden } from '@mui/utils';
import {
  CustomRow,
  CustomHeadRow,
  CollapsedContainerCell,
  OpenButton,
  ParentRow,
  CollapsedRow,
} from './styles';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = ({ headCells, order, orderBy, onRequestSort, className }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <CustomHeadRow className={className}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </CustomHeadRow>
    </TableHead>
  );
};

const Row = ({
  row,
  outerHeadCells,
  innerHeadCells,
  innerDataField,
  innerRowIdField,
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <ParentRow open={open}>
        {outerHeadCells.map((headCell, index) => {
          if (index === 0) {
            return (
              <TableCell
                component='th'
                scope='row'
                key={headCell.id}
                align={headCell.alignRight ? 'right' : 'left'}
              >
                {headCell.formatFunction(row)}
              </TableCell>
            );
          }
          return (
            <TableCell
              key={headCell.id}
              align={headCell.alignRight ? 'right' : 'left'}
            >
              {headCell.formatFunction(row)}
            </TableCell>
          );
        })}
        <TableCell>
          {row[`${innerDataField}`] && (
            <OpenButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}
              className='expandButton'
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </OpenButton>
          )}
        </TableCell>
      </ParentRow>
      <TableRow>
        {row[`${innerDataField}`] && (
          <CollapsedContainerCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={outerHeadCells.length + 1}
          >
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Table
                size='small'
                aria-label='purchases'
                sx={{ borderCollapse: 'separate' }}
              >
                <TableHead sx={{ visibility: 'collapse' }}>
                  <CustomHeadRow>
                    {innerHeadCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                      >
                        {/* this is to replicate the width of headcells in CustomTableHead which will make sure the inner and outer columns are aligned */}
                        <TableSortLabel>
                          {headCell.label}
                          <Box component='span' sx={visuallyHidden} />
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </CustomHeadRow>
                </TableHead>
                <TableBody>
                  {row[`${innerDataField}`].map((innerRow) => (
                    <CollapsedRow key={innerRow[`${innerRowIdField}`]}>
                      {innerHeadCells.map((headCell, index) => {
                        const formattedValue = headCell.id === 'Clicks' 
                        ?headCell.formatFunction(innerRow)
                        .split(",")
                        .map((item, ind) => (ind === 0 ? item  : Math.floor(+item)))
                        .join(", ") 
                        : headCell.formatFunction(innerRow);
                        if (index === 0) {
                          return (
                            <TableCell
                              key={headCell.id}
                              align={headCell.alignRight ? 'right' : 'left'}
                              component='th'
                              scope='row'
                            >
                            {formattedValue}
                             
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={headCell.id}
                            align={headCell.alignRight ? 'right' : 'left'}
                          >
                          {formattedValue}
                          </TableCell>
                        );
                      })}
                      {/* <TableCell /> */}
                      <TableCell>
                        <OpenButton
                          sx={{ visibility: 'collapse' }}
                          aria-label='expand row'
                          size='small'
                          onClick={() => setOpen(!open)}
                          className='expandButton'
                        >
                          {open ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </OpenButton>
                      </TableCell>
                    </CollapsedRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </CollapsedContainerCell>
        )}
      </TableRow>
    </>
  );
};

// works like and takes similar props like the regular CustomTable component.
// the inner / collapsed rows' data is in one field of the the outer rows.
const CollapsibleTable = ({
  outerHeadCells, // headcells for outer rows
  innerHeadCells, // headcells for inner rows
  innerDataField, // name of fields which holds the inner rows
  outerRowIdField, // name of field which has unique data in outer rows. used in keys for outer rows.
  innerRowIdField, // name of field which has unique data in inner rows. used in keys for inner rows.
  rows, // array of objects. data
  totalRow,
  rowsPerPage = 10,
  className,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Stack height='100%' overflow='hidden'>
      <TableContainer>
        <Table stickyHeader aria-label='collapsible table'>
          <EnhancedTableHead
            headCells={outerHeadCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            className={className}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <Row
                  key={row[`${outerRowIdField}`]}
                  row={row}
                  outerHeadCells={outerHeadCells}
                  innerHeadCells={innerHeadCells}
                  innerDataField={innerDataField}
                  innerRowIdField={innerRowIdField}
                />
              ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6}> </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter sx={{ flexShrink: 0 }}>
            <CustomRow>
              <TableCell component='th' scopr='row' align='left'>
                Total
              </TableCell>
              {outerHeadCells.slice(1).map((headCell) => {
                if (headCell.numeric) {
                  return (
                    <TableCell key={headCell.id} align='right'>
                      {headCell.totalFunction(rows, totalRow)}
                    </TableCell>
                  );
                }
                return <TableCell key={headCell.id} align='right' />;
              })}
              <TableCell>
                <OpenButton className='expandButton'> </OpenButton>
              </TableCell>
            </CustomRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {/* show pagination only when rows.length is greater than rowsPerPage */}
      {rows.length > rowsPerPage ? (
        <TablePagination
          // single value in array hides the rowsPerPage selector
          rowsPerPageOptions={[rowsPerPage]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      ) : null}
    </Stack>
  );
};
export { CollapsibleTable };
