import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
// import EastIcon from '@mui/icons-material/East';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ForgetPasswordSchema } from '../Schemas';
import { StyledLink } from './Formik/Styles';
import { FormikField } from './Formik';
import { HeadingWithUnderline } from '../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { AuthService } from '../../../services/auth';
import {
  ErrorAlert,
  SuccessAlert,
} from '../../../widgets/NewAlerts/ErrorAlert';

const ForgetPasswordForm = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [text, setText] = useState('Send Reset Link ');
  const initialValues = {
    email: '',
  };

  const [showTimer, setShowTimer] = useState(false);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const [email, setEmail] = useState('');

  const domainToSignInPage = {
    'gmail.com': 'https://mail.google.com/',
    'rediff.com': 'https://mail.rediff.com/',
    'yahoo.com': 'https://login.yahoo.com/',
    'icloud.com': 'https://www.icloud.com/',
  };

  const defaultSignInPage = 'https://login.live.com/';

  const redirectToSignInPage = () => {
    // Extract the domain from the entered email
    const domain = email.split('@')[1];

    if (domainToSignInPage[domain]) {
      const url = domainToSignInPage[domain];
      window.open(url, '_blank');
    } else {
      window.open(defaultSignInPage, '_blank');
    }
  };

  useEffect(() => {
    let timer;

    if (showTimer) {
      timer = setInterval(() => {
        if (minutes === 0 && seconds === 0) {
          clearInterval(timer);
          setShowTimer(false);
          setSuccessMessage(() => null);
        } else if (seconds === 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          setSeconds(seconds - 1);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [showTimer, minutes, seconds]);

  const handleForgetPassword = (values, formikHelpers) => {
    setText('Sending...');
    AuthService.forgetPassword(values)
      .then((response) => {
        if (response.status === 200) {
          setSuccessMessage(
            'We have emailed you a link to reset your password.'
          );
          setErrorMessage(() => null);
          setText('Send Reset Link');
          formikHelpers.resetForm();
          setMinutes(5);
          setSeconds(0);
          setShowTimer(true);
        }
      })
      .catch((error) => {
        setText('Send Reset Link');
        setSuccessMessage(() => null);
        if (error.response) {
          if (error.response.status === 500)
            setErrorMessage('Oops! something went wrong. Please try again.');
          else if (error.response.status === 400) {
            navigate('/signinsubmit');
          } else {
            setErrorMessage(error.response.data?.errors);
          }
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleForgetPassword}
      validationSchema={ForgetPasswordSchema}
    >
      {({ values, dirty, errors, touched, handleChange, handleBlur }) => (
        <Form>
          {errorMessage && <ErrorAlert message={errorMessage} />}
          {successMessage && <SuccessAlert message={successMessage} />}
          <HeadingWithUnderline
            heading='Forget Password?'
            sx={{ textAlign: 'start', pt: 2 }}
          />
          <Box sx={{ pb: 3 }}>
            <FormikField
              required
              name='email'
              type='email'
              placeholder='Enter email Id'
              value={values.email.toLowerCase()}
              label='Email'
              error={Boolean(errors.email) && touched.email}
              helperText={touched.email && errors.email}
              onChange={(e) => {
                setEmail(e.target.value);
                handleChange(e);
              }}
              onBlur={handleBlur}
            />
          </Box>

          <Stack direction='column' alignItems='center'>
            {showTimer && (
              <Typography variant='smallRegular' mb={1}>
                Resend Email in{' '}
                <span style={{ color: '#0096ff', fontWeight: '600' }}>
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}{' '}
                </span>
              </Typography>
            )}
            <Button
              variant='contained'
              type='submit'
              disabled={!dirty || showTimer}
              fullWidth
            >
              {text}
            </Button>
          </Stack>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            mt={1}
          >
            <StyledLink to='/signin'>Sign In</StyledLink>
            <Button
              onClick={() => redirectToSignInPage(email)}
              disabled={!showTimer}
              variant='text'
              sx={{
                '&:hover': {
                  background: 'transparent',
                  textDecoration: 'underline',
                },
              }}
            >
              <Typography
                variant='smallMedium'
                sx={{
                  paddingRight: '2px',
                }}
              >
                Go to Email
              </Typography>
              <ChevronRightIcon
                fontSize='small'
                sx={{ color: !showTimer ? '#c0c0c0' : '#0096ff !important' }}
              />
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
export { ForgetPasswordForm };
