import React, { useContext , useState } from 'react';
import _ from 'lodash';

// MUI imports
import { TableContainer, Button, Box, Stack } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

// Internal component import
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { PaginatedTable } from '../../../../components/PaginatedTable';
import { GlobalErrorComponent } from '../../../../components/GlobalErrorComponent';
import { useGetSearchEnhanceItemsQuery } from '../../../../api/RTK/services/feedItems';

// Styled component imports
import { StyledEnhanceTable } from '../StyledComponents/StyledCommon';

const renderCell = (val) =>{
  if (Array.isArray(val)) {
    return `[${val.join(", ")}]`;
  }
  return val;
}
 // accept data as the argument instead of accepting state and using state.data
 const pickHeadCells = (data) =>
  data?.data[0]
    ? (Object.keys(data?.data[0]) && [
      {
        id: 'image',
        numeric: false,
        label: 'Image',
        disablePadding: false,
        alignRight: false,
        formatFunction: (row) => (
          <img
            src={Array.isArray(row.image_link) ? row.image_link[0] : row.image_link || row.g_image_link ||  row['g:image_link']}
            alt='item_image'
            height='70'
              width='50'
              style={{
                maxWidth: '100px',
                maxHeight: '100px',
                objectFit: 'contain',
              }}
          />
        ),
      },
      ...Object.keys(data?.data[0]).map((field) => ({
        id: field,
        numeric: false,
        label: _.startCase(field),
        disablePadding: false,
        alignRight: false,
        formatFunction: (row) =>
          ['description', 'additional_image_link'].includes(field) ? (
            <Box
              as='span'
              sx={{
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {renderCell(row[field])}
            </Box>
          ) : (
            renderCell(row[field])
          ),
      })),
    ]) ||
    []
    : [];

const EnhanceMainListTable = ({ search, onDataLoading , setDisable ,fromNewPage ,
  setfromNewPage}) => {
  const [ isResizable , setResizable] = useState(Array.from({ length : 50 } ,  () => false));
  const [maxWidth, setMaxWidth] = useState(Array.from({ length : 50 } ,  () => 300));
  const navigate = useNavigate();
  const handleFeeds = () => {
    navigate('../feeds/quality');
  };

  const { company, user } = useContext(UserInfoContext);
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );
  const feedId = useSelector((state) => state.feedSummaryRedux.feedId);
  return (
    <StyledEnhanceTable>
      <TableContainer sx={{ borderBottom: '1px solid #dedede' }}>
        <ErrorBoundary fallback={<GlobalErrorComponent />}>
          <PaginatedTable
            rowIdField='id'
            rowsPerPage={10}
            verticalBorders={false}
            displayFooter={false}
            styleBodyCell={(index) => ({
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: !isResizable[index] ? `${maxWidth[index]}px` : '10px',
            })}
            setMaxWidth={(index, value) => {
              setMaxWidth(prevMaxWidth => {
                const newMaxWidth = [...prevMaxWidth];
                newMaxWidth[index] = value;
                return newMaxWidth;
              });
            }}
            setResizable={(index, value) => {
              setResizable(prevResizable => {
                const newResizable = [...prevResizable];
                newResizable[index] = value;
                return newResizable;
              });
            }}
            fetcherHook={useGetSearchEnhanceItemsQuery}
            args={{
              companyId: company.id,
              projectId: projectSelectId,
              feedId,
              search,
            }}
            linkRows
            linkFormatter={() => `./item-details`}
            linkStateFormatter={(row) => row}
            headCellGenerator={pickHeadCells}
            onDataLoading={onDataLoading}
            setDisable={setDisable}
            fromNewPage={fromNewPage}
            setfromNewPage={setfromNewPage}
          />
        </ErrorBoundary>
      </TableContainer>
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{ padding: '25px' }}
      >
        <Button
          sx={{
            width: '200px',
            margin: '5px',
            textAlign: 'center',
            textDecoration: 'none',
          }}
          variant='outlined'
          as={Link}
          to='../feeds/feed-settings'
        >
          Back
        </Button>
        <Button
          sx={{
            width: '200px',
            margin: '5px',
            display: 'flex',

          }}
          variant='contained'
          onClick={handleFeeds}
        >
          {(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') ? 'Save & Next' : 'Next'}
        </Button>
      </Stack>
    </StyledEnhanceTable>
  );
};

export { EnhanceMainListTable };
