import React from 'react';

import _ from 'lodash';
import { Box } from '@mui/material';
import { Map } from '../../../../../components/Map';
import { MapLegend } from '../../../../../components/MapLegend';

const filterFunction = (data, filter, platform) =>
  data.map((d) => ({
    value: d[filter.value],
    label: _.capitalize(d[`${platform === 'meta' ? 'Region' : 'region_name'}`]),
    latitude: d.latitude,
    longitude: d.longitude,
  }));

const maxValue = (data) =>
  data.reduce(
    (prev, d) =>
      prev > Math.max(...Object.values(d).filter((v) => typeof v === 'number'))
        ? prev
        : Math.max(...Object.values(d).filter((v) => typeof v === 'number')),
    0
  );

const UserByLocationChart = ({ data, filter, platform, currencyCode }) => {
  const newData = data?.filter(
    (key) => (key.region_name || key.Region) !== 'Total'
  );

  const filteredData = filterFunction(newData, filter, platform);

  const isCurrency =
    filter.value === 'online_sales' ||
    filter.value === 'marketing_cost' ||
    filter.value === 'Spend';
  return (
    <Box display='flex' flexGrow={1} minHeight={0}>
      <Box
        flex='1 0 0'
        sx={{
          display: 'none',
          '@media(min-width:600px)': {
            display: 'block',
          },
        }}
      >
        <MapLegend
          data={filteredData}
          metricName={filter.name}
          isCurrency={isCurrency}
          currencyCode={currencyCode}
        />
      </Box>
      <Box flex='2 0 0'>
        <Map
          metricName={filter.name}
          data={filteredData}
          isCurrency={isCurrency}
          currencyCode={currencyCode}
          idField='label'
          maxValue={maxValue(newData)}
        />
      </Box>
    </Box>
  );
};

export { UserByLocationChart };
