import React from 'react';

import _ from 'lodash';

import { StyledBar } from './styles';

// data: object. The default chartjs dataobject.
// yTitle: string. The Scale displayed on y axis
const BarGraph = ({
  data,
  yTitle,
  xTitle,
  currencyCode = 'ZZZ',
  isCurrency = false,
  metricName,
}) => {
  // position datalabel on below bar top if value is greater than this
  const mean = _.mean(data.datasets[0].data);
  const roundTo =
    metricName.toLowerCase() === 'roas' || metricName.toLowerCase() === 'cpc'
      ? 1
      : 0;
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: Boolean(xTitle),
          text: `${xTitle}`,
          color: '#323C46',
          font: {
            size: 14,
            family: 'Montserrat',
            weight: '600',
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: '#323C46',
          font: {
            size: 14,
            family: 'Montserrat',
            weight: '600',
          },
        },
      },
      y: {
        title: {
          display: true,
          text: `${yTitle}`,
          color: '#323C46',
          font: {
            size: 14,
            family: 'Montserrat',
            weight: '600',
          },
        },
        grid: {
          display: true,
          drawBorder: true,
          lineWidth: 1,
          color: '#D4D4D4',
        },
        ticks: {
          color: '#323C46',
          font: {
            size: 14,
            family: 'Montserrat',
            weight: '600',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'rgba(255,255,255,1)',
        titleColor: '#000',
        bodyColor: '#000',
        borderColor: 'rgba(222, 222, 222, 0.8)',
        borderWidth: 1,
        bodySpacing: 8,
        titleFont: {
          size: 12,
          weight: '600',
          family: 'Montserrat',
        },
        bodyFont: {
          size: 12,
          weight: '600',
          family: 'Montserrat',
        },
        callbacks: {
          label(context) {
            if (isCurrency) {
              return `${context.dataset.label}: ${currencyCode} ${context.formattedValue}`;
            }
            return `${context.dataset.label}: ${_.round(context.raw, roundTo)}`;
          },
        },
      },
      datalabels: {
        align(context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value < mean ? 'end' : 'start';
        },
        clamp: false,
        anchor(context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value < 0.0 ? 'start' : 'end';
        },
        backgroundColor: null,
        borderColor: null,
        borderRadius: 4,
        borderWidth: 1,
        color(context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value < mean ? '#000' : '#fff';
        },
        font: {
          size: 16,
          weight: 600,
          family: 'Montserrat',
        },
        offset: 4,
        padding: 5,
        rotation: 270,
        formatter(value) {
          return _.round(value, roundTo).toLocaleString();
        },
      },
    },
    layout: {
      padding: {
        right: 30,
      },
    },
  };

  return <StyledBar options={options} data={data} />;
};

export { BarGraph };

