import React from 'react';
import { FetchingInfoDispatchContext } from './FetchingInfoDispatchContext';
import { UserInfoContext } from './UserInfoContext';
import { UserInfoDispatchContext } from './UserInfoDispatchContext';

const BaseProvider = ({
  userInfo,
  userInfoDispatch,
  fetchingInfoDispatch,
  children,
}) => (
  <UserInfoContext.Provider value={userInfo}>
    <UserInfoDispatchContext.Provider value={userInfoDispatch}>
      <FetchingInfoDispatchContext.Provider value={fetchingInfoDispatch}>
        {children}
      </FetchingInfoDispatchContext.Provider>
    </UserInfoDispatchContext.Provider>
  </UserInfoContext.Provider>
);

export { BaseProvider };
