import React from 'react';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DoughnutChart } from '../../../../../components/DoughnutChart';

const dataFormatter = (data, filter, colorMap) => ({
  labels: data.map((d) => d.Device),
  datasets: [
    {
      label: [filter.name],
      data: data.map((d) => d[filter.name]),
      backgroundColor: data.map((d) => colorMap[`${d.Device}`]),
      hoverOffset: 0,
      borderWidth: 0,
      hoverBorderWidth: 0,
    },
  ],
});
const MetricWithCurrency = [
  'All Conversion value',
  'Average Cost',
  'Conversion value',
  'Cost',
  'Cost per All Conversions',
  'Cost per Conversions values',
  'All Conversion values (by Conversion time)',
];

const UserByDeviceChart = ({
  data,
  filter,
  colorMap,
  currencyCode = 'ZZZ',
}) => {
  Chart.register(ChartDataLabels);
  if (!filter) {
    return null;
  }
  const newData = data?.filter((key) => key.Device !== 'Total');
  const dataForDoughnut = dataFormatter(newData, filter, colorMap);

  return (
    <DoughnutChart
      data={dataForDoughnut}
      idField='device'
      currencyCode={currencyCode}
      isCurrency={MetricWithCurrency.includes(filter.value)}
      metricName={filter.name}
    />
  );
};

export { UserByDeviceChart };
