import React from 'react';
import { getHeadCells } from '../../../../components/Headcell/Headcell';
import { DashboardCustomTable } from '../../../../components/Table';

const CampaignSummaryTable = ({ data, currencyCode = 'ZZZ' }) => {
  if (data.length === 0) {
    return [];
  }

  const firstDataItem = data[0];
  const defaultFormatFunction = (row, key) => String(row[key]);
  const excludedKeys = ['Campaign Id'];

  // Separate "Total" row from the data
  const totalRow = data.find((row) => row['Campaign Id'] === 'Total');
  const filteredData = data.filter((row) => row['Campaign Id'] !== 'Total');

  return (
    <DashboardCustomTable
      headCells={getHeadCells(
        firstDataItem,
        excludedKeys,
        currencyCode,
        defaultFormatFunction
      )}
      rows={filteredData}
      totalRow={totalRow}
      rowIdField='Campaign Id'
      verticalBorders
    />
  );
};

export { CampaignSummaryTable };
