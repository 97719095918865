import React, { useState } from 'react';
import { useDispatch , useSelector } from 'react-redux';

// MUI imports
import { Container, Stack, Button } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Internal imports
import { ErrorBoundary } from 'react-error-boundary';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { EnhanceMainListTable } from '../Components/OtherComponents/EnhanceMainListTable';
// import { FilterOutModal } from '../Components/OtherComponents/FilterOutModal';
import { ToggleButtonFeeds } from '../Components/OtherComponents/ToggleButtonFeeds';
import { ActionMainModal } from '../Components/OtherComponents/ActionMainModal';
import { PageErrors } from '../../../components/PageErrors';
import { SearchWithButton } from '../Components/GlobalComponents/SearchWithButton';
import { AddColumnModal } from '../Components/OtherComponents/AddColumnModal';
import { setSearchTermEnahnce } from '../Redux/PaginationVales';
// Styled imports
import { StyledPaper } from './StyledMainBox';

// main function component
const EnhancePage = () => {
  const dispatch = useDispatch()
  const search = useSelector((state) => state.PaginationValuesReducers.searchTermEnahnce)
  const [toggled, setToggled] = useState('Enhance');
  const [count, setCount] = useState(1);
  const [disable, setDisable] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  // const [searchTerm, setSearchTerm] = useState('');
  const [fromNewPage , setfromNewPage] = useState(false)
  const [openCustomCol, setopenCustomCol] = useState(false);
  const handleSearchButtonClick = (value) => {
    if (!isDataLoading) {
      dispatch(setSearchTermEnahnce(value.trim()))
      // setSearchTerm(value.trim());
      setfromNewPage(true)
    }
  };

  const reloadList = () => {
    setCount(count + 1);
  };

  return (
    <>
      <ErrorBoundary fallback={<PageErrors />}>
        <Container maxWidth='xxl'>
          <TitleCard title='Enhance'>
            <Stack
              direction='row'
              spacing={2}
              sx={{
                width: '100%',
                '@media (max-width: 1100px)': {
                  flexDirection: 'column',
                },
                '@media (max-width: 769px)': {
                  flexDirection: 'column',
                },
              }}
            >
              <Stack
                direction='row'
                alignItems='center'
                spacing={2}
                sx={{
                  width: '100%',
                  '@media (max-width: 1100px)': {
                    marginBottom: '16px !important',
                  },
                }}
              >
                <SearchWithButton
                  placeholder='Search'
                  showEndAdornment
                  onClick={handleSearchButtonClick}

                />

               {/*  <FilterOutModal modalTitle='Filters' reloadList={reloadList} /> */}
                <ActionMainModal modalTitle='Actions' reloadList={reloadList} />
              </Stack>

              <Stack
                 direction='row'
                 alignItems='center'
                 spacing={2}
                 marginLeft={2}
                 sx={{
                   width: '100%',
                   '@media (max-width: 1100px)': {
                     flexDirection: 'row',
                     marginLeft:'0 !important',
                   },
                   '@media (max-width: 769px)': {
                     flexDirection: 'row',
                     gap: '16px'
                   },
                 }}
              >
                <ToggleButtonFeeds toggled={toggled} setToggled={setToggled} />
                <Button
                  variant='pillOutlined'
                  disableRipple
                  sx={{
                    height: '44px !important',
                    minWidth: '250px !important',
                    '&:hover': { svg: { color: '#0096ff !important' } },
                    svg: { paddingRight: '5px', fontSize: '30px' },
                    '@media (max-width: 769px)': {
                      marginLeft: '0 !important',
                      minWidth: '220px !important'
                    },
                  }}
                  onClick={() => setopenCustomCol(true)}
                  disabled={disable}
                >
                  <EditNoteOutlinedIcon />
                  <span>Customise Columns</span>
                  
                </Button>
              </Stack>
            </Stack>
          </TitleCard>

          <StyledPaper sx={{ padding: '0px' }}>
            <EnhanceMainListTable
              search={search}
              onDataLoading={setIsDataLoading}
              setDisable={setDisable}
              fromNewPage={fromNewPage}
              setfromNewPage={setfromNewPage}
            />
          </StyledPaper>
        </Container>
      </ErrorBoundary>
      <AddColumnModal
        openModel={openCustomCol}
        setModelOpen={setopenCustomCol}
        modalTitle='Add or Delete Columns'
        setftype=''
      />
    </>
  );
};

export { EnhancePage };
