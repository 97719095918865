const methodOptions = [
  { subheader: 'Text' },
  { value: 'SET_TO_VALUE', name: 'set to value' },
  { value: 'APPEND_VALUE', name: 'append value' },
  { value: 'COPY_VALUE', name: 'copy value' },
  { value: 'COMBINE_VALUE', name: 'combine value' },
  { value: 'SEARCH_FOR_VALUE', name: 'search for value' },
  { value: 'REPLACE_VALUE', name: 'replace value' },
  { value: 'LOOKUP_AND_REPLACE_VALUE', name: 'lookup and replace value' },
  { value: 'SEARCH_AND_REPLACE_VALUE', name: 'search and replace value' },
  { value: 'SPLIT_TEXT', name: 'split text' },
  { value: 'MAXIMUM_LENGTH', name: 'maximum length' },
  { value: 'MODIFY_TEXT', name: 'modify text' },
  { subheader: 'MATH' },
  { value: 'ROUND_NUMBER', name: 'round number' },
  { value: 'REFORMAT_NUMBER', name: 'reformat number' },
  { value: 'CALCULATE', name: 'calculate' },
  { value: 'CALCULATE_SUM', name: 'calculate sum' },
  { value: 'CALCULATE_LENGTH', name: 'calculate length' },
  { value: 'CALCULATE_LIST_LENGTH', name: 'calculate list length' },
  { subheader: 'Item' },
  { value: 'GROUP_ITEMS', name: 'group items' },
  { value: 'SPLIT_ITEMS', name: 'split items' },
  { value: 'DEDULICATE_ITEMS', name: 'deduplicate items' },
  { value: 'CALCULATE_ITEM_GROUP', name: 'calculate item groups' },
  { subheader: 'List' },
  { value: 'SORT_LIST', name: 'sort list' },
  { value: 'SLICE_LIST', name: 'slice list' },
  { value: 'SPLIT_TEXT_TO_LIST', name: 'split text to list' },
  { value: 'JOIN_LIST_TO_TEXT', name: 'join list to text' },
  { value: 'DEDULICATE_LIST', name: 'deduplicate list' },
];

const GTINMethodOptions = [
  { subheader: 'Text' },
  { value: 'SET_TO_VALUE', name: 'set to value' },
  { value: 'APPEND_VALUE', name: 'append value' },
  { value: 'COPY_VALUE', name: 'copy value' },
  { subheader: 'Item' },
  { value: 'GROUP_ITEMS', name: 'group items' },
  { value: 'SPLIT_ITEMS', name: 'split items' },
  { value: 'DEDULICATE_ITEMS', name: 'deduplicate items' },
];

export { methodOptions, GTINMethodOptions };

