import { styled } from '@mui/system';
import Slider from 'react-slick';

const StyledSlider = styled(Slider)`
  height: 100%;

  .slick-list,
  .slick-track {
    height: 100% !important;
  }

  .slick-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .slick-slide > div {
    height: 100%;
  }
`;

export { StyledSlider };
