import React, { useMemo, useContext } from 'react';

import { Databox } from '../../../../../../components/Databox';
import { Loading } from '../../../../../../components/Databox/Loading';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { CampaignSummaryTable } from '../../../../Databoxes/CampaignSummary';

import { getDemoDashboardService } from '../../../../../../services/DemoDashboard';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { AccountContext } from '../../accountContext';

const CampaignSummaryContainer = ({ platform }) => {
  const { currencyCode } = useContext(AccountContext);
  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'CAMPAIGN_SUMMARY',
    }),
    []
  );

  const [state] = useDataFetcher(
    {},
    getDemoDashboardService(platform),
    memoizedArgs
  );
  return (
    <Databox>
      <DataboxHeading
        title='Campaign Summary'
        info='Ad campaign insights are summarized in a systematic tabular format, making the
        data approachable.'
      />
      <FetchingDisplay
        isError={state.isError}
        isLoading={state.isLoading}
        LoadingElement={<Loading />}
        ErrorElement={<ErrorDisplay />}
        SuccessElement={
          <CampaignSummaryTable data={state.data} currencyCode={currencyCode} />
        }
      />
    </Databox>
  );
};

export { CampaignSummaryContainer };
