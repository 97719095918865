/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useField } from 'formik';
import _ from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { TextInput } from '../../../../../../components/Inputs/TextInput';
import { SelectInput } from '../../../../../../components/Inputs/SelectInput';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';

const HiddenHR = () => (
  <Box
    component='hr'
    sx={{
      margin: 0,
      border: 0,
      height: 0,
      width: '100%',
      marginBottom: '-1rem',
    }}
  />
);

const FlexTextInput = ({
  label,
  placeholder,
  id,
  hideLabel,
  type = 'text',
  labelIsAdjacent = true,
}) => {
  const { user } = useContext(UserInfoContext);
  return (
    <TextInput
      name={id}
      label={label}
      placeholder={placeholder}
      id={id}
      hideLabel={hideLabel}
      type={type}
      labelIsAdjacent={labelIsAdjacent}
      disabled={
        user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR'
      }
      sx={{
        width: '100%',
        '@media(min-width:800px)': {
          width: 'auto',
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            flex: 'initial',
            width: '300px',
            maxWidth: '100%',
          },
        },
        '& .Mui-disabled': {
          backgroundColor: '#ffffff',
        },
      }}
    />
  );
};

const Sidebar = ({ count }) => (
  <Box
    sx={{
      position: 'absolute',
      left: '0px',
      top: '1px',
      bottom: '1px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '32px',
      zIndex: 4,
      borderRight: '1px solid',
      borderColor: 'border.main',
      padding: '8px 0px',
      '& .MuiTypography-smallRegular': {
        transform: 'translateY(-1px)',
      },
    }}
  >
    {_.range(1, count + 1, 1).map((num) => (
      <Typography variant='smallRegular' key={num}>
        {num}
      </Typography>
    ))}
  </Box>
);

const MultilineFlexTextInput = ({
  label,
  hideLabel = false,
  placeholder,
  id,
}) => {
  const [field, meta] = useField(id);
  const [rowCount, setRowCount] = useState(5);
  const { user } = useContext(UserInfoContext);
  useEffect(() => {
    const newCount = field.value.split('\n').length;
    if (newCount >= 5) {
      setRowCount(newCount);
    }
  }, [field.value]);
  return (
    <FormControl
      fullWidth
      error={meta.error && meta.touched}
      sx={{
        flex: '1 1 300px',
        maxWidth: '400px',
        maxHeight: '150px',
        overflow: 'auto',
      }}
      disabled={
        user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR'
      }
    >
      <InputLabel
        htmlFor='id'
        sx={{
          transform: 'none',
          position: 'static',
          flexShrink: 0,
          mb: 1,
          ...(hideLabel && visuallyHidden),
        }}
      >
        {label}
      </InputLabel>
      <Box sx={{ position: 'relative', display: 'flex' }}>
        <Sidebar count={rowCount} />
        <OutlinedInput
          name={id}
          sx={{
            backgroundColor: '#FFF',
            minHeight: '45px',
            overflow: 'auto',
            flex: 1,
            padding: '8px 40px',
            '& .MuiInputBase-inputMultiline': { padding: 0, lineHeight: 1.5 },
            '& .Mui-disabled': {
              backgroundColor: 'transparent',
            },
          }}
          id={id}
          type='text'
          {...field}
          placeholder={placeholder}
          multiline
          minRows={5}
        />
      </Box>
      {meta.error && meta.touched && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

const FlexSelectInput = ({
  id,
  options,
  label,
  hideLabel = false,
  optionValueCallback = (o) => o,
  optionContentCallback = (o) => o,
}) => (
  <SelectInput
    name={id}
    id={id}
    label={label}
    labelIsAdjacent
    hideLabel={hideLabel}
    options={options || []}
    optionValueCallback={optionValueCallback}
    optionContentCallback={optionContentCallback}
    sx={{
      width: '100%',
      '@media(min-width:800px)': {
        width: 'auto',
        '& .MuiInputBase-root.MuiOutlinedInput-root': {
          flex: 'initial',
          width: '300px',
          maxWidth: '100%',
        },
      },
    }}
  />
);

export { HiddenHR, FlexTextInput, MultilineFlexTextInput, FlexSelectInput };
