import React, { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

// MUI imports
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// main function component
const Search = ({
  data,
  setData,
  searchKey,
  placeholder,
  onSearch,
  showEndAdornment,
  showStartAdornment,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  // The handleSearch function is being passed through throttle as an argument and a new function is being returned. This new function can only be called every 500ms, as specified in the throttle call. Therefore, when handleSearch is logged to the console, it is actually the throttled function with the built-in delay between calls.
  useEffect(() => {
    setSearchTerm('');
  }, []);

  const handleSearch = throttle((event) => {
    const { value } = event.target;
    setSearchTerm(value);

    const filteredData = data.filter((item) =>
      item[searchKey].toLowerCase().includes(value.toLowerCase())
    );
    setData(filteredData);

    if (typeof onSearch === 'function') {
      onSearch(value);
    }
  }, 500);

  const handleChange = (event) => {
    handleSearch(event);
  };

  return (
    <TextField
      type='search'
      placeholder={placeholder}
      value={searchTerm}
      onChange={handleChange}
      autoComplete='off'
      sx={{
        input: {
          paddingLeft: '5px',
        },
        backgroundColor: '#ffffff',
        borderRadius: '30px',
        '& .MuiOutlinedInput-root': {
          border: 'none !important',
        },
        boxShadow: '0px 0px 10px rgba(0, 0, 0, .08)',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#ffffff',
        },
      }}
      InputProps={{
        style: {
          height: '40px',
          borderRadius: '30px',
        },
        classes: {
          root: 'custom-input-root',
        },
        startAdornment: showStartAdornment && (
          <InputAdornment position='end'>
            <SearchIcon
              sx={{
                fontSize: '20px',
                color: (theme) => theme.palette.primary.main,
              }}
            />
          </InputAdornment>
        ),
        endAdornment: showEndAdornment && (
          <InputAdornment position='end'>
            <SearchIcon sx={{ fontSize: '20px', color: 'primary' }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export { Search };
