import React, { useState, useEffect, useContext } from 'react';

import { Button, Box } from '@mui/material';

import { TopProductsContainer } from './Containers/TopProductsContainer';
import { QuickStatsContainer } from './Containers/QuickStatsContainer';
import { ShoppingProductsInsightsContainer } from './Containers/ShoppingProductsInsightsContainer';
import { UserByGenderContainer } from './Containers/UserByGenderContainer';
import { UserByAgeContainer } from './Containers/UserByAgeContainer';
import { UserByDeviceContainer } from './Containers/UserByDeviceContainer';
import { MonthlyInsightsContainer } from './Containers/MonthlyInsightsContainer';
import { YearlyInsightsContainer } from './Containers/YearlyInsightsContainer';
import { AdInsightsContainer } from './Containers/AdInsightsContainer';
import { ResizableGrid } from '../../../../components/ResizableGrid';
import { AdInsightsMetaContainer } from './Containers/AdInsightsMetaContainer';
import { CampaignSummaryContainer } from './Containers/CampaignSummaryContainer';
import { ConversionFunnelContainer } from './Containers/ConversionFunnelContainer';
import { PMaxContainer } from './Containers/PMaxContainer';
import { AccountContext } from './accountContext';
import { UserByLocationContainer } from './Containers/UserByLocationContainer';
import { CTABox } from './CTABox';
import { DemoDashboardToolbar } from './Toolbar';
import { Joyride } from './Joyride';
import { UserInfoContext } from '../../../../context/UserInfoContext';

const MetaDashboard = () => (
  <>
    <Box
      sx={{
        my: { xs: 2, sx: 2, md: 2, lg: 2, xl: 3 },
        mx: { xs: 2, sx: 2, md: 2, lg: 3.5, xl: 4 },
      }}
    >
      <QuickStatsContainer platform='meta' />
    </Box>
    <ResizableGrid platform='meta' demo>
      {/* you can either use 'div's as wrappers or call use React.forwarRef on components for react-grid-layout to work with them */}
      <div key='adInsghtsMt'>
        <AdInsightsMetaContainer platform='meta' className='filterGuide' />
      </div>
      <div key='cnvrsnFnnl'>
        <ConversionFunnelContainer platform='meta' />
      </div>
      <div key='cmpgnSmmry'>
        <CampaignSummaryContainer platform='meta' />
      </div>
      <div key='usrByGndr'>
        <UserByGenderContainer platform='meta' />
      </div>
      <div key='usrByAg'>
        <UserByAgeContainer platform='meta' />
      </div>
      <div key='usrByDvc'>
        <UserByDeviceContainer platform='meta' />
      </div>
      <div key='yrlyInsghts'>
        <YearlyInsightsContainer platform='meta' />
      </div>
      <div key='mnthlyInsghts'>
      <MonthlyInsightsContainer platform='meta' />
    </div>
      <div key='lctn'>
        <UserByLocationContainer platform='meta' />
      </div>
    </ResizableGrid>
  </>
);

const GoogleDashboard = () => (
  <>
    <Box
      sx={{
        my: { xs: 2, sx: 2, md: 2, lg: 2, xl: 3 },
        mx: { xs: 2, sx: 2, md: 2, lg: 3.5, xl: 4 },
      }}
    >
      <QuickStatsContainer platform='google' />
    </Box>
    <ResizableGrid platform='google' demo>
      {/* you can either use 'div's as wrappers or call use React.forwarRef on components for react-grid-layout to work with them */}
      <div key='lctn'>
        <UserByLocationContainer platform='google' />
      </div>
      <div key='tpPrd'>
        <TopProductsContainer platform='google' />
      </div>
      <div key='shppngPrdctsInsghts'>
        <ShoppingProductsInsightsContainer
          platform='google'
          className='filterGuide'
        />
      </div>
      <div key='adInsghts'>
        <AdInsightsContainer platform='google' />
      </div>
      <div key='usrByGndr'>
        <UserByGenderContainer platform='google' />
      </div>
      <div key='usrByAg'>
        <UserByAgeContainer platform='google' />
      </div>
      <div key='usrByDvc'>
        <UserByDeviceContainer platform='google' />
      </div>
      <div key='yrlyInsghts'>
        <YearlyInsightsContainer platform='google' />
      </div>
      <div key='perfrmnceMax'>
        <PMaxContainer platform='google' />
      </div>
      <div key='mnthlyInsghts'>
        <MonthlyInsightsContainer platform='google' />
      </div>
    </ResizableGrid>
  </>
);

const DemoDashboard = () => {
  const [run, setRun] = useState(false);
  const [stepIndexState, setStepIndexState] = useState(0);
  const { isLoggedIn } = useContext(UserInfoContext);

  useEffect(() => {
    setTimeout(() => {
      if (window.localStorage.getItem('guideHasRun') !== 'true') {
        setRun(true);
        window.localStorage.setItem('guideHasRun', 'true');
      }
    }, 3000);
  }, []);

  const CompanyName = 'Cosmo Style';

  const accountList = [
    {
      id: '1',
      platform: 'googleAds',
      name: 'Google Ads Account 1',
      currencyCode: 'USD',
    },
    {
      id: '2',
      platform: 'meta',
      name: 'Meta Account 1',
      currencyCode: 'EUR',
    },
  ];

  const [selectedAccount, setSelectedAccount] = useState(accountList[0]);

  const handleSelectedAccountChange = (account) => setSelectedAccount(account);

  return (
    <AccountContext.Provider value={selectedAccount}>
      <Joyride
        run={run}
        className='reactJoy'
        setRun={setRun}
        stepIndexState={stepIndexState}
        setStepIndexState={setStepIndexState}
      />
      <div>
        <Button
          sx={{
            position: 'fixed',
            zIndex: '999',
            bottom: '8px',
            right: '12px',

            padding: '5px 15px',
          }}
          variant='contained'
          onClick={() => {
            setRun(true);
            setStepIndexState(0);
          }}
        >
          Start Tour
        </Button>
      </div>
      {!isLoggedIn ? (
        <CTABox
          className='startNow'
          ctaText='Do you wish to see your own data in this dashboard?'
        />
      ) : null}

      <DemoDashboardToolbar
        CompanyName={CompanyName}
        selectedAccount={selectedAccount}
        accountList={accountList}
        handleSelectedAccountChange={handleSelectedAccountChange}
        className='accountDate'
      />
      {selectedAccount.platform === 'meta' ? (
        <MetaDashboard />
      ) : (
        <GoogleDashboard />
      )}
    </AccountContext.Provider>
  );
};

export { DemoDashboard };
