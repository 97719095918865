import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  FieldType: 'Autocomplete',
};

const FieldTypeSliceRedux = createSlice({
  name: 'FieldType',
  initialState,
  reducers: {
    setFieldTypeState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.FieldType = action.payload;
    },
  },
});

// eslint-disable-next-line import/no-default-export
export default FieldTypeSliceRedux.reducer;
export const { setFieldTypeState } = FieldTypeSliceRedux.actions;
