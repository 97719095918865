// react imports
import React from 'react';

// third party imports
import { styled, keyframes } from '@mui/system';
import { Box, Button, Menu, MenuItem, Autocomplete } from '@mui/material';

// autocomplete animation
const pulseAnimation = keyframes`
 0% {
      outline: 1px solid #0096ff;
      box-shadow: none;
    }
  8.33% {
        outline: 2px solid #0096ff;
        box-shadow: 0px 0px 20px rgba(0, 150, 255, 1);
      }
    16.66% {
      outline: 1px solid #0096ff;
      box-shadow: none;
    }
    25% {
        outline: 2px solid #0096ff;
        box-shadow: 0px 0px 20px rgba(0, 150, 255, 1);
      }
    33.33% {
      outline: 1px solid #0096ff;
      box-shadow: none;
    }
    100% {
      outline: 1px solid #0096ff;
      box-shadow: none;
    }
`;

// Styled Autocomplete component with conditional pulse animation
const CustomAutocomplete = styled(Autocomplete, {
  shouldForwardProp: (prop) => prop !== 'showPulse',
})(({ showPulse, hasvalue }) => ({
  '& .MuiOutlinedInput-root': {
    padding: hasvalue ? '0.5rem 1rem !important' : '0.6rem 1rem !important',
    color: '#323C46',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '500',
    position: 'relative',
    '& fieldset': {
      border: 'none',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      borderRadius: '999px',
      boxShadow: 'none',
      animation: showPulse ? `${pulseAnimation} 3s infinite linear` : 'none',
      transition: 'opacity 100ms',
      pointerEvents: 'none',
      zIndex: 1,
    },
    '&:hover::after': {
      animation: 'none',
      outline: ' 1px solid #0096ff',
    },
  },
}));

const StyledMenu = styled(
  ({ anchorEl, id, MenuListProps, onClose, open, className, children }) => (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 42,
        horizontal: 'left',
      }}
      className={className}
      elevation={1}
      id={id}
      MenuListProps={MenuListProps}
      PaperProps={{
        sx: { width: anchorEl && anchorEl.offsetWidth },
      }}
      onClose={onClose}
      open={open}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {children}
    </Menu>
  )
)({
  padding: 0,
});

const StyledButtonText = styled(Box)({
  flexGrow: 1,
  textAlign: 'start',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

const StyledMenuItem = styled(MenuItem)({
  padding: '0.5rem 1rem',
  '& .itemChild': {
    width: '100%',
  },
  '& .accountName': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const PulsingButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'showPulse',
})(
  ({ showPulse }) =>
    `
    ${
      showPulse &&
      `
      &::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 999px;
  box-shadow: none;
  animation: showPulse ? 'pulse 3s infinite linear' : 'none',
  outline: showPulse ? 1px solid #0096ff : 'none !important';
  transition: opacity 100ms;
  @keyframes pulse {
    0% {
      outline: 1px solid #0096ff;
      box-shadow: none;
    }
    8.33% {
        outline: 2px solid #0096ff;
        box-shadow: 0px 0px 20px rgba(0, 150, 255, 1);
      }
    16.66% {
      outline: 1px solid #0096ff;
      box-shadow: none;
    }
    25% {
        outline: 2px solid #0096ff;
        box-shadow: 0px 0px 20px rgba(0, 150, 255, 1);
      }
    33.33% {
      outline: 1px solid #0096ff;
      box-shadow: none;
    }
    100% {
      outline: 1px solid #0096ff;
      box-shadow: none;
    }
  
    }
  }
  
  &:hover::after {
    opacity: 0;
    animation: none;
  }
  `
    }
  `
);

export {
  StyledMenu,
  StyledButtonText,
  StyledMenuItem,
  PulsingButton,
  CustomAutocomplete,
};
