import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

// MUI imports
import { Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { UserInfoContext } from '../../../../context/UserInfoContext';

// Main function for component
const BreadcrumbsDynamic = () => {
  const location = useLocation();
  const path = location.pathname.split(/[/]/);
  let path2 = path[2].charAt(0).toUpperCase() + path[2].slice(1);
  const path3 = path[3]
    ? path[3].charAt(0).toUpperCase() + path[3].slice(1)
    : null;
  const path4 = path[4]
    ? path[4].charAt(0).toUpperCase() + path[4].slice(1)
    : null;

  const { company } = useContext(UserInfoContext);

  const projectSelectName = useSelector(
    (state) => state.allProjectsRedux.projectName
  );

  const feedSelectName = useSelector((state) => state.feedSummaryRedux.feedName);

  if (feedSelectName) {
    if (path2 === 'Feeds' && path3) {
      path2 += ` - ${feedSelectName}`;
    }
  }

  const breadcrumbs = [
    <Typography
      component={Link}
      underline='none'
      key='1'
      color='#999999'
      to='.'
      sx={{
        textDecoration: 'none',
        color: '#999999',
        fontWeight: '500',
        '&:hover': {
          color: '#0096ff',
          textDecoration: 'underline',
          transition: 'all 0.3s linear',
        },
      }}
    >
      {company ? company.name : 'Company'}
    </Typography>,

    <Typography
      component={Link}
      underline='none'
      key='2'
      color='#999999'
      to='/feed-management/imports '
      sx={{
        textDecoration: 'none',
        color: '#999999',
        fontWeight: '500',
        '&:hover': {
          color: '#0096ff',
          textDecoration: 'underline',
          transition: 'all 0.3s linear',
        },
      }}
    >
      {projectSelectName}
    </Typography>,
    path3 ? (
      <Typography
        key='4'
        color='#999999'
        sx={{
          '&:hover': {
            color: '#0096ff',
            textDecoration: 'underline',
            transition: 'all 0.3s linear',
          },
          textDecoration: 'none',
          fontWeight: '500',
        }}
        component={Link}
        to={`/${path[1]}/${path[2]}`}
      >
        {path2}
      </Typography>
    ) : (
      <Typography key='5' color='#999999' sx={{ fontWeight: '500' }}>
        {path2}
      </Typography>
    ),
    path4 && !path4.match(/\d+/g) ? (
      <Typography
        key='7'
        color='#999999'
        sx={{
          '&:hover': {
            color: '#0096ff',
            textDecoration: 'underline',
            transition: 'all 0.3s linear',
          },
          textDecoration: 'none',
          fontWeight: '500',
        }}
        component={Link}
        to={`/${path[1]}/${path[2]}/${path[3]}`}
      >
        {path3}
      </Typography>
    ) : (
      path3 && (
        <Typography key='9' color='#999999' sx={{ fontWeight: '500' }}>
          {path3}
        </Typography>
      )
    ),
    path4 && !path4.match(/\d+/g) ? (
      <Typography key='11' color='#999999' sx={{ fontWeight: '500' }}>
        {path4}
      </Typography>
    ) : null,
  ];

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
      sx={{ marginTop: '14px', marginLeft: '24px' }}
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export { BreadcrumbsDynamic };
