/* eslint-disable camelcase */
import { api } from '../api';

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateDateRange: builder.mutation({
      query: ({ dateRange }) => ({
        url: `/users/selected-dashboard-date`,
        method: 'PATCH',
        body: {
          date_range: dateRange,
        },
      }),
    }),
    // get all agency with company
    getProjectList: builder.query({
      query: () => ({
        url: `/users/project-user-list`,
        method: 'GET',
      }),
      providesTags: (result) => {
        let agencyUserIds = [];
        let companyUserIds = [];

        if (result) {
          if (result?.agency_user) {
            agencyUserIds = result?.agency_user.map(({ id }) => ({
              type: 'PROJECTLIST',
              id,
            }));
          }
          if (result?.company_users) {
            companyUserIds = result?.company_users.flatMap(({ users }) =>
              users.map(({ id }) => ({ type: 'PROJECTLIST', id }))
            );
          }
        }

        return [
          ...agencyUserIds,
          ...companyUserIds,
          { type: 'PROJECTLIST', id: 'LIST' },
          { type: 'USERPROFILE', id: 'USER' },
        ];
      },
      refetchOnMountOrArgChange: true,
    }),
    getUsersDetails: builder.query({
      query: () => ({
        url: `/users/invite`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'PROJECTLIST', id }],
    }),
    createUsers: builder.mutation({
      query: ({ name, email, projectID, userType }) => ({
        url: `/users/invite`,
        method: 'POST',
        body: {
          name,
          email,
          project_id: projectID,
          user_type: userType,
        },
      }),
      // invalidatesTags: [{ type: 'PROJECTLIST', id: 'LIST' }],
    }),
    createAgencyCompanyUsers: builder.mutation({
      query: ({ name, email, projectID, userType, companyId }) => ({
        url: `/users/invite`,
        method: 'POST',
        body: {
          name,
          email,
          project_id: projectID,
          user_type: userType,
          company_id: companyId,
        },
      }),
      // invalidatesTags: [{ type: 'PROJECTLIST', id: 'LIST' }],
    }),
    createCompanyUsers: builder.mutation({
      query: ({ name, email, projectID, userType }) => ({
        url: `/users/invite`,
        method: 'POST',
        body: {
          name,
          email,
          project_id: projectID,
          user_type: userType,
        },
      }),
      // invalidatesTags: [{ type: 'PROJECTLIST', id: 'LIST' }],
    }),

    getUsersAccessDetails: builder.query({
      query: ({ id }) => ({
        url: `/users/access-details/${id}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...(Array.isArray(result)
                ? result.map(({ id }) => ({ type: 'ACCESSDETAILS', id }))
                : []),
              { type: 'ACCESSDETAILS', id: 'ACCESS' },
            ]
          : [{ type: 'ACCESSDETAILS', id: 'ACCESS' }],
    }),
    editUsersDetails: builder.mutation({
      query: ({ id, data }) => ({
        url: `/users/access-details/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'PROJECTLIST', id },
        { type: 'ACCESSDETAILS', id: 'ACCESS' },
      ],
    }),
    deleteUsersDetails: builder.mutation({
      query: ({ id }) => ({
        url: `/users/access-details/${id}`,
        method: 'DELETE',
      }),

      invalidatesTags: (result, error, { id }) => [{ type: 'PROJECTLIST', id }],
    }),

    // agency detail's calls
    getAgencyDetails: builder.query({
      query: () => ({
        url: `/accounts/agency`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...(Array.isArray(result)
                ? result.map(({ id }) => ({ type: 'DETAILS', id }))
                : []),
              { type: 'DETAILS', id: 'DETAIL' },
            ]
          : [{ type: 'DETAILS', id: 'DETAIL' }],
      refetchOnMountOrArgChange: true,
    }),
    editAgencyDetails: builder.mutation({
      query: ({ name }) => ({
        url: `/accounts/agency`,
        method: 'PATCH',
        body: {
          name,
        },
      }),
      invalidatesTags: [{ type: 'DETAILS', id: 'DETAIL' }],
    }),
    companyDetails: builder.mutation({
      query: ({ id, data }) => ({
        url: `/details/${id}`,
        method: 'PATCH',
        body: {
          data,
        },
      }),
      invalidatesTags: [{ type: 'DETAILS', id: 'DETAIL' }],
    }),

    // password confirmation
    passwordConfirmation: builder.mutation({
      query: ({ password }) => ({
        url: `auth/request-authenticate`,
        method: 'POST',
        body: {
          password,
        },
      }),
    }),

    // user profile
    getProfile: builder.query({
      query: () => ({
        url: `/auth/profile`,
        method: 'GET',
      }),
      providesTags: ['USERPROFILE'],
    }),
    editProfile: builder.mutation({
      query: ({ full_name, phone_number, username }) => ({
        url: `/auth/profile`,
        method: 'PATCH',
        body: {
          full_name,
          phone_number,
          username,
        },
      }),
      invalidatesTags: ['USERPROFILE'],
    }),
  }),
});

export const {
  useGetProjectListQuery,
  useGetUsersDetailsQuery,
  useGetUsersAccessDetailsQuery,
  useCreateUsersMutation,
  useCreateCompanyUsersMutation,
  useEditUsersDetailsMutation,
  useDeleteUsersDetailsMutation,
  useGetAgencyDetailsQuery,
  useEditAgencyDetailsMutation,
  useCreateAgencyCompanyUsersMutation,
  useCompanyDetailsMutation,
  useUpdateDateRangeMutation,
  usePasswordConfirmationMutation,
  useGetProfileQuery,
  useEditProfileMutation,
} = userApi;
