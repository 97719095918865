import { Box, Paper } from '@mui/material';
import { styled } from '@mui/system';

const FooterContainer = styled(Paper)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 10px 2rem;
  position: sticky;
  bottom: 0; /* Stick the footer to the bottom */
  width: 100%;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  @media (max-width: 337px) {
    align-items: start;
  }
`;

const BadgeWrapper = styled(Box)`
  & .partnerBadgeLink {
    display: block;
    max-height: 60px;
    max-width: 60px;
    position: relative;
  }

  & .partnerBadgeLink.meta {
    max-height: 45px;
    max-width: 80px;
  }

  & .partnerBadge {
    max-width: 100%;
    max-height: 100%;
  }
`;

export { BadgeWrapper, FooterContainer };
