import {
  object,
  array,
  number,
  boolean,
  ref,
  string,
  addMethod,
  ValidationError,
} from 'yup';

// check if importedFields have unique mapped_to values
addMethod(
  array,
  'unique',
  function (message, mapper = (field) => field.mapped_to) {
    return this.test('unique', message, (list) => {
      let errors = [];
      // list of mapped_to pkid
      const pkidList = list.map(mapper);
      // if list and a set generated from the list has different lengths, the list has a repeated value
      if (!(list.length === new Set(pkidList).size)) {
        // create map where key is the pkid and value is array of indexes where the mapped_to appears in pkidList
        // this map keeps track of the indexes at which the pkid appears in the pkidList
        // if any key (pkid) has more than one value, it is repeated.
        const valueMap = pkidList.reduce((acc, cur, index) => {
          if (typeof cur === 'number') {
            return {
              ...acc,
              [cur]: [...(acc[cur] || []), index],
            };
          }
          return acc;
        }, {});

        const duplicateValIndexes = Object.values(valueMap)
          .filter((pkidIndexes) => pkidIndexes.length > 1) // only keep the entries which have more than one pkid, i.e. are repeated
          .reduce((acc, indexArr) => [...acc, ...indexArr], []);

        // set errors for the repeated indexes
        // these pkid indexes correspond to the imported_field indexes
        errors = duplicateValIndexes.map(
          (pkidIndex) =>
            new ValidationError(
              `Duplicate fields not allowed`,
              pkidList[pkidIndex],
              `importedFields[${pkidIndex}].mapped_to`
            )
        );
      }
      return new ValidationError(errors);
    });
  }
);

const mappingValidationSchema = object({
  projectFields: array().of(
    object({
      pkid: number().required(),
    })
  ),
  importedFields: array()
    .of(
      object({
        mapped_to: number().test(
          'is-correctly-mapped',
          'Imported Fields Must Be Mapped To a Project Field',
          (v, context) =>
            [
              ...context.from[1].value.projectFields.map((field) => field.pkid),
            ].includes(v) || !v
        ),
        imports: boolean()
          .required(),
        // .oneOf([ref('mapped_to')], (context) => {
        //   if (
        //     typeof context.resolved[0] !== 'number' &&
        //     context.value === true
        //   ) {
        //     return 'Cannot Import an Unmapped Field';
        //   }
        //   return null;
        // }),
        present: boolean(),
      })
    )
    .unique(),
  join_type: string().oneOf(['APPEND', 'JOIN_FIELD']),
  join_on: string().nullable().when('join_type', {
    is: 'JOIN_FIELD',
    then: (schema) =>
      schema
        .required('Select field to be joined on')
        .oneOf([ref('projectFields')], (context) => {
          if (
            context.resolved
              .map((field) => field.name)
              .includes(context.originalValue)
          ) {
            return null;
          }
          return `'Join-on' field must be one of the project fields`;
        }),
  }),
});

export { mappingValidationSchema };
