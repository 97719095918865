import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  button: 'Metric',
};

const metricSliceRedux = createSlice({
  name: 'toggleButton',
  initialState,
  reducers: {
    setState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.button = action.payload;
    },
  },
});

// eslint-disable-next-line import/no-default-export
export default metricSliceRedux.reducer;
export const { setState } = metricSliceRedux.actions;
