import { useField } from 'formik';
import { fieldMap } from '../maps/fieldMap';

const ValueSelector = ({ name, methodPath, fields }) => {
  const [{ value: method }] = useField(methodPath);

  if (method && !['DO_NOTHING', 'EXCLUDE'].includes(method)) {
    return fieldMap(fields, name)[method];
  }

  return null;
};

export { ValueSelector };
