import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { Paper } from '@mui/material';

const ToolbarWrapper = styled(({ children, className, sx }) => (
  <Paper variant='toolbar' className={className} sx={sx}>
    {children}
  </Paper>
))(
  ({ colwidth = 1000, theme }) => `
      align-items: center;
      background-color: transparent;
      box-shadow: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: ${theme.spacing(4)};
      justify-content: space-between;
      &.MuiPaper-root{
       padding: 1rem 0rem;
       margin: 0rem;
       z-index:111
      }

      @media (max-width:${colwidth}px) {
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
      }

      & .toolbarChild{
        flex: 1 0 calc(34% - 16px);
        
        @media (max-width:1500px) {
          min-width: 0px;
          flex: 1 0 0;
          &:nth-of-type(1){
            flex: 0 0 0;
            min-width: fit-content;
          }
        }
      }
    `
);

// shown below the header.
// sticks to the top on scroll and changes background to #fff
const Toolbar = ({ colwidth, children }) => {
  useEffect(() => {
    const el = document.querySelector('.toolbar');
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
      { threshold: [1] }
    );

    observer.observe(el);
  }, []);
  return (
    <ToolbarWrapper className='toolbar' colwidth={colwidth}>
      {children}
    </ToolbarWrapper>
  );
};

export { Toolbar };
