import * as React from 'react';
import { Typography, Checkbox, Grid } from '@mui/material';

const CheckBoxComponent = ({ account, index, handleCheckbox, list }) => {
  const isCheckedAndDisabled = list?.some(
    (item) => item.customer_id === account?.customer_id
  );
  return (
    <Grid container display='flex' alignItems='center'>
      <Grid
        item
        xs={2}
        sm={2}
        md={2}
        lg={2}
        xl={2}
        display='flex'
        justifyContent='center'
      >
        <Checkbox
          value={index}
          onChange={(event) => handleCheckbox(event)}
          defaultChecked={isCheckedAndDisabled}
          disabled={isCheckedAndDisabled}
          sx={{
            '.Mui-disabled': {
              color: 'rgb(0 0 0 / 10%)',
            },
          }}
        />
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{ pr: 1 }}>
        <Typography
          variant='smallRegular'
          sx={{ textOverflow: 'ellipsis', wordBreak: 'break-all' }}
        >
          {account?.name ? account?.name : account?.customer_id}
        </Typography>
      </Grid>
    </Grid>
  );
};

export { CheckBoxComponent };
