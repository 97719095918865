import React from 'react';
import { Typography, TextField } from '@mui/material';
import {
  StyledFormikWrapper,
  StyledFormikField,
  StyledInputLabel,
} from './StyledFormikField';

const FormikField = ({
  name,
  label,
  required = true,
  type,
  placeholder,
  value,
  error,
  helperText,
  onChange,
  onBlur,
  readOnly = false,
}) => (
  <StyledFormikWrapper className='FormikField'>
    <StyledInputLabel htmlFor={name}>
      <Typography variant='h5'>{label}</Typography>
    </StyledInputLabel>
    <StyledFormikField
      id={name}
      required={required}
      as={TextField}
      variant='outlined'
      fullWidth
      autoComplete='on'
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      error={error}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
      inputProps={{ readOnly }}
    />
  </StyledFormikWrapper>
);

export { FormikField };
