import React, { useContext, useState } from 'react';

import { Backdrop, Fade, Button, Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import { WooCommerceSchema } from '../../../../../Components/Forms/Schema';
import { FormikField } from '../../../../../Components/Forms/FormikField';
import { AccountsService } from '../../../../../../../services/accounts';
import {
  StyledGlobalModal,
  StyledModal,
  StyledNavigationBox,
} from '../utils/StyledPlatformComponents';

import { LogoHeadingWithUnderline } from '../../../../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { LinkComponent } from '../utils/PlatformComponents';
import { CloseButton } from '../../../../../../../widgets/CloseButton';
import { WooLogo } from '../../../../../../../constants/endpoints/assests/logos';
import { BrokenWireframe } from '../../../../../../../constants/endpoints/assests/artWork';
import { UserInfoContext } from '../../../../../../../context/UserInfoContext';
import { LoadingDialog } from '../../../../../../UserManagement/Components/LoadingDialog';

const WooPlatform = ({ open, handleCloseModal, setAssociation }) => {
  const { company } = useContext(UserInfoContext);
  const [openModal, setOpenModal] = useState(false);
  const [accountsError, setAccountsError] = useState(false);
  const [errorCopy, setErrorCopy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    account_name: '',
    website_url: '',
    consumer_key: 'ck_',
    consumer_secret: 'cs_',
  };

  const handleOnSubmit = async (values, formikHelpers) => {
    try {
      formikHelpers.resetForm();
      const payload = {
        data: [
          {
            name: values.account_name,
            customer_id: values.website_url,
            consumer_key: values.consumer_key,
            consumer_secret: values.consumer_secret,
            platform: 'WOO_COMMERCE',
          },
        ],
        company_id: company ? company.id : null,
      };
      setIsLoading(true);
      setOpenModal(true);
      const response = await AccountsService.createAccounts(payload);
      if (response.status === 201) {
        setOpenModal(true);
        setTimeout(() => {
          setOpenModal(false);
          setAssociation((association) => association + 1);
          handleCloseModal();
        }, 2000);
        setIsLoading(false)
      } else {
        setOpenModal(true);
        setAccountsError(true);
        setTimeout(() => {
          setOpenModal(false);
          handleCloseModal();
        }, 2000);
        setIsLoading(false)
      }
    } catch (error) {
      setAccountsError(true);
      setErrorCopy(error?.response?.data?.errors[0]?.[values.account_name][0]);
      setOpenModal(true);
      setTimeout(() => {
        setOpenModal(false);
      }, 2000);
      setOpenModal(true);
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <>
      <StyledModal
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <StyledGlobalModal>
            <LogoHeadingWithUnderline
              src={WooLogo}
              heading='Link WooCommerce Platform'
            />
            {/* <Box sx={{ mb: 2 }}>
              <Typography variant='smallRegular'>
                Watch the video to know where you can find WooCommerce URL,
                Consumer Key, and Consumer Secret.
              </Typography>
            </Box> */}
            <Box>
              <Formik
                initialValues={initialValues}
                onSubmit={handleOnSubmit}
                validationSchema={WooCommerceSchema}
              >
                {({ values, errors, handleChange, handleBlur }) => (
                  <Form>
                    <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
                      <FormikField
                        name='account_name'
                        label='Name'
                        required
                        type='text'
                        placeholder='Enter your WooCommerce Account name'
                        value={values.account_name}
                        error={Boolean(errors.account_name)}
                        helperText={errors.account_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
                      <FormikField
                        name='website_url'
                        label='WooCommerce Website URL'
                        required
                        type='text'
                        placeholder='Enter your WooCommerce website URL'
                        value={values.website_url}
                        error={Boolean(errors.website_url)}
                        helperText={errors.website_url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
                      <FormikField
                        name='consumer_key'
                        label='Consumer Key'
                        required
                        type='text'
                        placeholder='ck_000000000000'
                        value={values.consumer_key}
                        error={Boolean(errors.consumer_key)}
                        helperText={errors.consumer_key}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
                      <FormikField
                        name='consumer_secret'
                        label='Consumer Secret'
                        required
                        type='text'
                        placeholder='cs_000000000000'
                        value={values.consumer_secret}
                        error={Boolean(errors.consumer_secret)}
                        helperText={errors.consumer_secret}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Button
                      variant='contained'
                      type='submit'
                      fullWidth
                      sx={{ my: 1 }}
                      disableFocusRipple
                      disableRipple
                    >
                      Link
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
            <StyledNavigationBox>
              <LinkComponent
                text='Go to WooCommerce Platform'
                to='https://woocommerce.com/'
              />
            </StyledNavigationBox>
            <CloseButton onClick={handleCloseModal} />
          </StyledGlobalModal>
        </Fade>
      </StyledModal>
      <LoadingDialog
        open={openModal}
        isLoading={isLoading}
        isError={accountsError}
        onClose={() => setOpenModal(false)}
        successMessage='Account added successfully'
        errorMessage={
          errorCopy ? <p>{errorCopy}</p> : <p>Error while adding the account</p>
        }
      />
    </>
  );
};

const WooComPlatform = ({ open, handleCloseModal }) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    localStorage.setItem('wooComLink', JSON.stringify('true'));
    navigate('/accounts');
    handleCloseModal();
  };

  return (
    <StyledModal
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <StyledGlobalModal>
          <LogoHeadingWithUnderline src={WooLogo} heading='Link WooCommerce' />
          <Typography
            variant='smallRegular'
            color='error'
            sx={{
              mt: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ErrorOutlineIcon color='error' sx={{ mr: 1 }} /> You do not have
            any WooCommerce accounts linked yet
          </Typography>
          <Box
            component='img'
            src={BrokenWireframe}
            sx={{ width: '100%', my: 3 }}
          />
          <Box
            display='flex'
            justifyContent='center'
            alignItems='flex-start'
            flexDirection='column'
            component='ol'
            sx={{
              mb: 2,
              gap: 2,
              listStylePosition: 'inside',
            }}
          >
            <Typography variant='h5'>How to link:</Typography>
            <Typography variant='smallRegular' component='li'>
              Navigate to WooCommerce linking page.
            </Typography>
            <Typography variant='smallRegular' component='li'>
              Input the necessary details on the linking page and proceed by
              clicking &quot;Link WooCommerce&quot;.
            </Typography>
            <Typography variant='smallRegular' component='li'>
              Upon completion, the account will be linked.
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            gap={3}
          >
            <Button
              variant='outlined'
              sx={{ width: '100%' }}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              onClick={handleOnClick}
              variant='contained'
              sx={{ width: '100%' }}
            >
              Link WooCommerce
            </Button>
          </Box>
          <CloseButton onClick={handleCloseModal} />
        </StyledGlobalModal>
      </Fade>
    </StyledModal>
  );
};

export { WooPlatform, WooComPlatform };
