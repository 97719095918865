/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  feedId: '',
  feedChannel: '',
  feedName: '',
  feedlist: [],
};

const feedSummaryRedux = createSlice({
  name: 'feedSummary',
  initialState,
  reducers: {
    setFeedId: (state, action) => {
      state.feedId = action.payload;
    },
    setFeedChannel: (state, action) => {
      state.feedChannel = action.payload;
    },
    setFeedName: (state, action) => {
      state.feedName = action.payload;
    },
    clearInputValue: (state) => {
      state.feedId = initialState.feedId;
      state.feedChannel = initialState.feedChannel;
      state.feedName = initialState.feedName;
      state.feedlist = initialState.feedlist;
    },
    updateFeedList: (state, action) => {
      state.feedlist = action.payload;
    },
  },
});

export const {
  setFeedId,
  setFeedChannel,
  setFeedName,
  clearInputValue,
  updateFeedList,
} = feedSummaryRedux.actions;

// eslint-disable-next-line import/no-default-export
export default feedSummaryRedux.reducer;
