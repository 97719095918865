/* eslint-disable no-nested-ternary */
import React, { useMemo, useContext, useState } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { CustomizeButton } from '../../../../../../widgets/CustomizeButton';
import { AdInsights } from '../../../../Databoxes/AdInsights';
import { Databox } from '../../../../../../components/Databox';
import { Loading } from '../../../../../../components/Databox/Loading';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { getDashboardDataService } from '../../../../../../utils/getDashboardDataService';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';
import { CustomizedColumnModal } from '../../../../../MetricAndDimension/DashboardDatabox/CustomizeColumn';
import { ErrorComponent } from '../../../../../../components/GlobalErrorComponent';

const AdInsightsContainer = ({ dateRange, accountId }) => {
  const [open, setOpen] = useState(false);
  const { account } = useContext(UserInfoContext);
  const [count, setCount] = useState(0);
  const memoizedArgs = useMemo(
    () => ({
      account_id: accountId,
      date_range: dateRange,
      databox_type: 'ADS_INSIGHTS',
    }),
    [accountId, dateRange]
  );
  const [state] = useDataFetcher(
    [],
    getDashboardDataService('google'),
    memoizedArgs,
    count
  );
  const onCustomizeClick = () => {
    setOpen(true);
  };

  return (
    <Databox>
      <ErrorBoundary
        fallback={
          <ErrorComponent
            title='Ad Insights'
            info='Know the progress of the ad campaigns of your account.'
          />
        }
      >
        <DataboxHeading
          title='Ad Insights'
          info='Know the progress of the ad campaigns of your account.'
        >
          <CustomizeButton
            onCustomizeClick={onCustomizeClick}
            account={account}
          />
          {open && (
            <CustomizedColumnModal
              open={open}
              setOpen={setOpen}
              databoxType='ads_insights'
              accountId={accountId}
              count={count}
              setCount={setCount}
            />
          )}
        </DataboxHeading>{' '}
        <FetchingDisplay
          isLoading={state.isLoading}
          isError={state.isError}
          LoadingElement={<Loading />}
          ErrorElement={
            <ErrorDisplay
              refresh={() => {
                setCount(count + 1);
              }}
              message={
                state.errorInfo?.code === 204
                  ? 'No data available for selected date range.'
                  : state.errorInfo?.code === 400
                  ? "Couldn't load data"
                  : 'Something went wrong'
              }
              showRefreshButton={
                !(
                  state.errorInfo?.code === 400 || state.errorInfo?.code === 204
                )
              }
            />
          }
          SuccessElement={
            <AdInsights
              data={state.data}
              currencyCode={account.currency_code}
            />
          }
        />{' '}
      </ErrorBoundary>
    </Databox>
  );
};

export { AdInsightsContainer };
