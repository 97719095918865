import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Typography, CircularProgress } from '@mui/material';
import { CancelRounded, CheckCircleRounded } from '@mui/icons-material';
import { FetchingDisplay } from '../../../../components/FetchingDisplay';

const defaultOnClose = () => {};

const LoadingDialog = ({
  open,
  isLoading,
  isError,
  onClose = defaultOnClose,
  successMessage = 'Success',
  errorMessage = 'Error',
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
    PaperProps={{
      sx: {
        padding: 3,
        borderRadius: 3,
        height: '170px',
        width: '380px',
        display: 'grid',
        placeContent: 'center',
        justifyItems: 'center',
      },
    }}
  >
    <FetchingDisplay
      isLoading={isLoading}
      isError={isError}
      LoadingElement={<CircularProgress />}
      SuccessElement={
        <>
          <CheckCircleRounded
            sx={{ color: 'primary.main', height: '2rem', width: '2rem' }}
          />
          <Typography variant='bodySemiBold' textAlign='center'>
            {successMessage}
          </Typography>
        </>
      }
      ErrorElement={
        <>
          <CancelRounded
            sx={{ color: 'error.main', height: '2rem', width: '2rem' }}
          />
          <Typography variant='bodySemiBold' textAlign='center'>
            {errorMessage}
          </Typography>
        </>
      }
    />
  </Dialog>
);
export { LoadingDialog };
