import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { theme } from '../../../../../Maintheme';

const StyledLinkNotification = styled(Paper)`
  margin: 16px 0px;
  padding: 8px 16px;
  border: 1px solid ${theme.palette.error.main};
  background: #ffeee9;
  .errorOutlineIcon {
    color: ${theme.palette.error.main};
    margin-right: 8px;
  }
  .notificationButton {
    border: 1px solid #ff823c;
    background: ${theme.palette.error.main};
    color: #fff;
    border-radius: 5px;
    font-size: 0.8rem;
    &:hover {
      background: ${theme.palette.error.main};
      opacity: 0.8;
    }
  }
`;

export { StyledLinkNotification };
