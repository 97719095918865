import React from 'react';
import { Typography, Box, Card, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { GoogleAnalyticsLogo } from '../../constants/endpoints/assests/logos';

const PoasOverlay = () => (
  <Card
    sx={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0px',
      left: '0px',
      borderRadius: '.8rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    }}
  >
    <Card
      sx={{
        borderRadius: '.8rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        p: 4,
      }}
    >
      <Typography
        variant='smallMedium'
        color='error'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        To get Marketing cost and to calculate Profit link your
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            img: {
              width: '30px',
              mr: 1,
            },
          }}
        >
          <img src={GoogleAnalyticsLogo} alt='Google Analytics Logo ' />
          <Typography variant='smallMedium'>
            Google Anlaytics Account
          </Typography>
        </Box>
      </Typography>
      <Button
        variant='contained'
        sx={{ maxWidth: '280px', mt: 3 }}
        component={Link}
        to='/accounts'
      >
        Link Google Analytics
      </Button>
    </Card>
  </Card>
);

export { PoasOverlay };

