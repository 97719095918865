import React from 'react';
import { Stack } from '@mui/material';
import { CustomSlider } from '../../../../components/CustomSlider';
import { Stat } from './Stat';

const QuickStats = ({ data }) => {
  const settings = {
    dots: false,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <Stack
      component={CustomSlider}
      CustomSlider
      settings={settings}
      sx={{
        width: '100%',
      }}
    >
      {data.map((d, index) => (
        <Stat
          key={d.label}
          icon={d.icon}
          type={index % 2 === 0 ? 'blue' : 'orange'}
          label={d.label}
          value={d.value}
        />
      ))}
    </Stack>
  );
};

export { QuickStats };
