import React, { useState } from 'react';
import _ from 'lodash';

import {
  Grid,
  Stack,
  Typography,
  MenuItem,
  Button,
  Divider,
  Paper,
  Box,
} from '@mui/material';

import { InfoTooltip } from '../../../../../components/InfoTooltip';
import { currencies } from './CurrencyList';
import { RangeSliderComponent } from './RangeSlider';
import { DoughnutGraph } from '../POASGraphs/DoughnutGraph';

import {
  StyledPOAScalculatorWrapper,
  StyledTextField,
  StyledSelect,
} from './StyledPOASCalculator';

const POASCalculator = ({
  setPOAS,
  poas,
  setProfitPercentage,
  profitPercentage,
  totalProfit,
  setTotalProfit,
}) => {
  const [inputValue, setInputValue] = useState('10000');
  const [demoCurrency, setDemoCurrency] = useState('INR');
  const [editButtonState, setEditButtonState] = useState(false);
  const handleChange = (event) => {
    setDemoCurrency(event.target.value);
  };
  const handleInputValueChange = (event) => {
    const inputNumber = event.target.value;
    const numbers = /^[0-9.,]+$/;
    if (inputNumber.match(numbers)) {
      setInputValue(event.target.value.replaceAll(',', ''));
    } else {
      alert('Please Enter a valid number');
    }
  };

  const [productMakingCost, setProductMakingCost] = useState(10);
  const [marketingCost, setMarketingCost] = useState(15);
  const [tax, setTax] = useState(18);
  const [packingCost, setPackingCost] = useState(20);
  const [shippingCost, setShippingCost] = useState(12);
  const [otherCosts, setOtherCosts] = useState(8);

  const demoprofitPercentage =
    100 -
    (productMakingCost +
      marketingCost +
      tax +
      packingCost +
      shippingCost +
      otherCosts);

  const [poasSheetData, setPoasSheetData] = useState([]);

  const data = {
    labels: [
      productMakingCost,
      marketingCost,
      tax,
      packingCost,
      shippingCost,
      otherCosts,
      demoprofitPercentage,
    ],
    datasets: [
      {
        label: 'slices',
        backgroundColor: [
          '#0096FF',
          '#323C46',
          '#505961',
          '#6F767D',
          '#8E9499',
          '#ADB1B5',
          '#D6D8DA',
        ],
        data: [
          demoprofitPercentage,
          productMakingCost,
          marketingCost,
          tax,
          shippingCost,
          packingCost,
          otherCosts,
        ],
      },
    ],
  };

  const calculatePoas = () => {
    setEditButtonState(true);
    setPoasSheetData([
      {
        product_making_cost: productMakingCost,
        marketing_cost: marketingCost,
        packing_cost: packingCost,
        demo_tax: tax,
        shipping_cost: shippingCost,
        other_cost: otherCosts,
        demp_poas: poas,
        profit: `${demoprofitPercentage}%`,
      },
    ]);
  };

  return (
    <StyledPOAScalculatorWrapper>
      <Paper sx={{ borderRadius: '.8rem', mb: 4 }}>
        <Box className='POAScalculator'>
          <Box sx={{ p: 3 }}>
            <Typography variant='h4'>POAS & Profit Calculator</Typography>
          </Box>
          <Divider />
          {editButtonState === false && (
            <Box sx={{ p: 3 }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant='body1' className='POASSubtitle'>
                  Note&#8758; For best results, please make sure to put accurate
                  numbers for the desired period (a month, a quarter, or a year)
                </Typography>
              </Box>
              <Grid container>
                <Grid item xs={12} md={6} className='POASTextField'>
                  <Box display='flex' alignItems='center'>
                    <Typography variant='smallMedium' sx={{ mr: 1 }}>
                      Total Revenue
                    </Typography>
                    <InfoTooltip title='Total amount earned from selling your product(s).' />
                  </Box>
                  <Box
                    display='flex'
                    alignItems='center'
                    className='POASTextFieldBox'
                  >
                    <StyledTextField
                      id='outlined-size-small'
                      defaultValue={inputValue}
                      variant='standard'
                      onChange={handleInputValueChange}
                    />
                    <StyledSelect
                      labelId='demo-simple-select-standard-label'
                      id='demo-simple-select-standard'
                      value={demoCurrency}
                      onChange={handleChange}
                      variant='standard'
                      MenuProps={{
                        PaperProps: {
                          sx: { maxHeight: '250px', width: 'auto' },
                        },
                      }}
                    >
                      {Object.keys(currencies).map((item) => (
                        <MenuItem
                          value={currencies[item].code}
                          sx={{ px: 1, py: 0.5 }}
                        >
                          {currencies[item].code}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </Box>
                </Grid>
              </Grid>

              <RangeSliderComponent
                profitPercentage={profitPercentage}
                poas={poas}
                setProductMakingCost={setProductMakingCost}
                productMakingCost={productMakingCost}
                setMarketingCost={setMarketingCost}
                marketingCost={marketingCost}
                setTax={setTax}
                tax={tax}
                setPackingCost={setPackingCost}
                packingCost={packingCost}
                setShippingCost={setShippingCost}
                shippingCost={shippingCost}
                setOtherCosts={setOtherCosts}
                otherCosts={otherCosts}
                valueProps={_.round(_.toNumber(inputValue))}
                setPOAS={setPOAS}
                setProfitPercentage={setProfitPercentage}
                setTotalProfit={setTotalProfit}
              />
              <Stack
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{ mt: 1.5 }}
              >
                <Button
                  variant='contained'
                  onClick={calculatePoas}
                  sx={{ width: 'auto' }}
                >
                  Calculate POAS and Profit
                </Button>
              </Stack>
            </Box>
          )}
          {editButtonState === true && (
            <DoughnutGraph
              demoCurrency={demoCurrency}
              poas={poas}
              profitPercentage={demoprofitPercentage}
              inputValue={inputValue}
              setEditButtonState={setEditButtonState}
              productMakingCost={productMakingCost}
              marketingCost={marketingCost}
              tax={tax}
              shippingCost={shippingCost}
              packingCost={packingCost}
              otherCosts={otherCosts}
              doughnutData={data}
              profit={totalProfit}
              poasSheetData={poasSheetData}
            />
          )}
        </Box>
      </Paper>
    </StyledPOAScalculatorWrapper>
  );
};

export { POASCalculator };

