import React, { useContext } from 'react';
// third party imports
import { ToggleButton, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
// Styled component imports
import { StyledToggleButtonGroup } from '../StyledComponents/StyledCommon';
import { UserInfoContext } from '../../../../context/UserInfoContext';

const StatusToggle = ({
  className,
  alignment,
  setAlignment,
  toggleClass,
  name,
  onClick,
}) => {
  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const { user } = useContext(UserInfoContext);

  const projectFeeds = useSelector(
    (state) => state.allProjectsRedux.feeds
  );

  const areAllFeedsInactive = (projectFeeds && projectFeeds.length === 0) || (projectFeeds && projectFeeds.length > 0 && projectFeeds.every((feed) => !feed.active));

  return (
    <Tooltip
      title={areAllFeedsInactive ? 'Activate atleast one feed to activate the project' : ''}
      arrow
      placement='bottom-end'
    >
      <StyledToggleButtonGroup
        name={name}
        color='primary'
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label='Platform'
        className={className}
        sx={{
          borderRadius: '30px',
          border: '1px solid #DEDEDE',
          height: '40px',
        }}
        disabled={areAllFeedsInactive || (user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}

      >

        <ToggleButton
          value='Active'
          className={toggleClass}
          sx={{
            border: 'none',
            borderRadius: '30px',
            ...(areAllFeedsInactive || (user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR') && {
              border: 'none !important',
            }),
          }}
          onClick={onClick}

        >
          Active
        </ToggleButton>




        <ToggleButton
          value='Inactive'
          className={areAllFeedsInactive ? 'deactive' : toggleClass}
          sx={{
            border: 'none',
            borderRadius: '30px',
            ...(areAllFeedsInactive && {
              border: 'none !important',
            }),
          }}
          onClick={onClick}
        >
          Inactive
        </ToggleButton>
      </StyledToggleButtonGroup>
    </Tooltip>
  );
};

export { StatusToggle };
