import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as SelectAvocado } from '../../assests/images/artWork/SelectAvocado.svg';

const SelectAccOrCompany = ({
  promptText = 'You need to select a Company and an Account to see your data',
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 2,
      padding: 2,
      height: '60%',
    }}
  >
    <Paper elevation={1} sx={{ padding: 2, borderRadius: 3 }}>
      <Typography
        variant='bodyMedium'
        sx={{
          display: 'block',
          maxWidth: '40ch',
          textAlign: 'center',
          color: 'error.main',
        }}
      >
        {promptText}
      </Typography>
    </Paper>
    <Box
      sx={{
        width: 'fit-content',
        maxWidth: '100%',
        flex: '1 0 0 ',
        aspectRatio: '0.77',
        '& svg': {
          height: '100%',
        },
      }}
    >
      <SelectAvocado />
    </Box>
  </Box>
);

export { SelectAccOrCompany };
