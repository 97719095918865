import React, { useContext, useEffect, useState } from 'react';
import { Button, Typography, Box, Stack, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { HeadingWithUnderline } from '../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { usersService } from '../../../services/users';
import { DashboardService } from '../../../services/dashboard';
import { fetchUserInfo } from '../../../services/auth';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { UserInfoDispatchContext } from '../../../context/UserInfoDispatchContext';
import {
  setCompany,
  setCompanyList,
  setUser,
} from '../../../utils/userInfoDispatchFunctions';

const InvitationForm = ({ invites }) => {
  const { companyList, user } = useContext(UserInfoContext);
  const dispatch = useContext(UserInfoDispatchContext);
  const [inviteAccepted, setInviteAccepted] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const acceptInvite = (token, invitedBy) => {
    usersService
      .acceptInvite(token, invitedBy)
      .then(() => usersService.rejectInvite(token, invitedBy, true))
      .then(() => fetchUserInfo())
      .then((userInfo) => {
        const newCompanyList = DashboardService.getCompanyListWithoutMetrics();
        return Promise.all([userInfo, newCompanyList]);
      })
      .then(([userInfo, newCompanyList]) => {
        setUser(dispatch, userInfo);
        setCompanyList(dispatch, newCompanyList?.data);
        if (userInfo.project_type === 'AGENCY') {
          setCompany(dispatch, null);
        } else {
          setCompany(dispatch, newCompanyList?.data[0]);
        }
        setInviteAccepted(true);
        navigate('/companies');
      })
      .catch((error) => {
        console.log('Error in promise chain:', error);
      });
  };
  const rejectInvitation = () => {
    Promise.all(
      invites.map((invite) =>
        usersService.rejectInvite(invite.token, invite.invited_by)
      )
    ).then(() => {
      if (companyList.length === 0) {
        return navigate('/create-company');
      }
      return navigate('/companies');
    });
  };

  useEffect(() => {
    if (inviteAccepted) {
      fetchUserInfo();
      if (user.projectType === 'AGENCY') {
        return navigate('/companies');
      }
      return navigate('/accounts');
    }
    return null;
  }, [user, inviteAccepted, navigate]);

  return (
    <Stack sx={{ textAlign: 'center', gap: 2 }}>
      <HeadingWithUnderline
        heading={
          invites.length === 1
            ? `${
                !invites[0].company
                  ? invites[0].project.name
                  : invites[0].company
              } ${_.capitalize(
                !invites[0].company
                  ? invites[0].project.project_type
                  : 'COMPANY'
              )} have invited you to join them with 
              ${
                ['SUB_ADMIN', 'ADMIN'].includes(invites[0].user_type)
                  ? 'Admin'
                  : 'Collaborator'
              } access`
            : 'Invitations'
        }
      />
      {user.projectName && (
        <Typography
          color='error'
          variant='smallMedium'
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            textAlign: 'left',
          }}
        >
          <ErrorOutlineIcon fontSize='small' sx={{ mr: 1 }} /> You have only
          access to one company. By accepting this invitation you will no longer
          have access to your present company {user.projectName}
        </Typography>
      )}
      {invites.length > 1 ? (
        <Box>
          {invites?.map((invite) => (
            <Stack
              key={invite.token}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              flexDirection='row'
              sx={{ borderBottom: '1px solid #DEDEDE', padding: '12px 0px' }}
            >
              <Typography variant='smallBold' sx={{ textAlign: 'left' }}>
                {!invite.company ? invite.project.name : invite.company}
                &nbsp;
                {_.capitalize(
                  !invite.company ? invite.project.project_type : 'COMPANY'
                )}
                &nbsp;have invited you to join them with&nbsp;
                {['SUB_ADMIN', 'ADMIN'].includes(invite?.user_type)
                  ? 'Admin'
                  : 'Collaborator'}
                &nbsp;access
              </Typography>
              <Button
                variant='contained'
                sx={{ width: '90px' }}
                onClick={() => acceptInvite(invite.token, invite.invited_by)}
                fullWidth
              >
                Accept
              </Button>
            </Stack>
          ))}
        </Box>
      ) : (
        <Button
          variant='contained'
          onClick={() => acceptInvite(invites[0].token, invites[0].invited_by)}
          fullWidth
        >
          Accept Invitation
        </Button>
      )}
      <Box mt={1}>
        <Typography variant='smallBold'>OR</Typography>
      </Box>
      <Button
        variant='outlined'
        onClick={() => rejectInvitation(invites)}
        fullWidth
      >
        Reject Invitation
      </Button>
      <Typography variant='smallBold'>
        {!user.projectName
          ? `and create own account`
          : `and continue with current company`}
      </Typography>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ backgroundColor: '#fff' }}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Text in a modal
          </Typography>
        </Box>
      </Modal>
    </Stack>
  );
};
export { InvitationForm };
