import { styled } from '@mui/system';

const AdContainer = styled('div')(
  () => `
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    gap: 1rem;
`
);

const AdWrapper = styled('article')(
  ({ theme }) => `
    flex: 1 0 34%;
    border: 1px solid ${theme.palette.border.main};
    border-radius: 0.75rem;
    min-width: 350px;
  `
);

const DataList = styled('dl')(
  ({ theme }) => `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: 1px solid ${theme.palette.border.main}
`
);

const NameValuePair = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 0 34%',
  flexDirection: 'row-reverse',
  padding: 1,
  gap: 1,

  '&:nth-of-type(odd)': {
    borderRight: `1px solid ${theme.palette.border.main}`,
  },

  '&:not(:last-child, :nth-last-of-type(2))': {
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
}));

export { AdContainer, AdWrapper, DataList, NameValuePair };
