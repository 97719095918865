// third party imports
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const LinkChild = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.backgroundColor,
  color: theme.palette.text.primary,
  '.active &': {
    color: theme.palette.primary.main,
  },
  display: 'flex',
  flexDirection: 'row',
  height: '5rem',
  textDecoration: 'none',
  padding: '0rem 1rem',
  position: 'relative',
  transition: '150ms',
  '&:after': {
    content: '""',
    bottom: '0px',
    display: 'block',
    position: 'absolute',
    height: '0.25rem',
    left: '0px',
    right: '0px',
    backgroundColor: theme.palette.backgroundColor,
    '.active &': {
      backgroundColor: theme.palette.primary.main,
    },
    transition: '150ms',
  },
  '&:hover': {
    color: theme.palette.primary.main,
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '&:focus': {
    color: theme.palette.primary.main,
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const LinkText = styled(Typography)(({ theme }) => ({
  '.Mui-focusVisible &': {
    outline: `2px solid ${theme.palette.primary.main}`,
  },
}));

export { LinkChild, LinkText };

