import React, { useState, useEffect, useContext } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

import _ from 'lodash';
import moment from 'moment';

import { POASCalculator } from '../POASDataboxes/POASDemoDashboard/POASCalculator/POASCalculator';
import { POASLinkNotification } from '../POASDataboxes/POAS/POASLinkNotification/POASLinkNotification';
import { POASCalculatorMain } from '../POASDataboxes/POAS/POASCalculator/POASCalculator';

import { DashboardService } from '../../../services/dashboard';
import { stateTypes } from '../../../constants/stateTypes';

import { POASMainDiv } from './StyledPOASmain';
import { POASToolbar } from './POASToolbar';
import { UserInfoContext } from '../../../context/UserInfoContext';

const { LOADING, SUCCESS, ERROR } = stateTypes;

const POASmain = () => {
  const { company, account, accountlist, isLoggedIn } =
    useContext(UserInfoContext);
  const [state, setState] = useState(LOADING);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [poas, setPOAS] = useState(0);
  const [currency, setCurrency] = useState('');
  const [profitPercentage, setProfitPercentage] = useState(0);
  const [requestData, setRequestData] = useState({});
  const [getResponse, setGetResponse] = useState([]);
  const poasList = [];
  const profitList = [];
  const [selectedObject, setSelectedObject] = useState({});

  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [
      {
        label: 'POAS',
        data: poasList,
        fill: false,
        backgroundColor: '#FF823C',
        borderColor: 'rgb(26, 143, 240)',
        yAxisID: 'y1',
        minbarThickness: 30,
        maxBarThickness: 60,
        borderRadius: 5,
      },
      {
        label: 'Profit',
        data: profitList,
        fill: false,
        backgroundColor: '#0096FF',
        borderColor: 'rgb(255, 107, 61)',
        yAxisID: 'y',
        minbarThickness: 30,
        maxBarThickness: 60,
        borderRadius: 5,
      },
    ],
  });
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const prevMonth = new Date(year, month - 1, 1);
  const [dateList, setDateList] = useState([
    moment(prevMonth).format('MMMM-YYYY'),
  ]);
  const [value, setValue] = useState(_.last(dateList));

  const [totalProfit, setTotalProfit] = useState(10);
  const dateRangeList = [];
  const graphDateList = [];

  const [isShown, setIsShown] = useState(true);
  const getPoasData = (companyId, selectedAccountId, poasMonth) => {
    if (
      !_.isUndefined(companyId) &&
      !_.isUndefined(selectedAccountId) &&
      !_.isUndefined(poasMonth)
    ) {
      DashboardService.getProfitData(companyId, selectedAccountId, poasMonth)
        .then((response) => {
          const responseData = response.data;

          if (
            !_.isEmpty(responseData) &&
            Object.keys(responseData[0]).length > 1
          ) {
            setGetResponse(responseData);
            responseData.forEach((data) => {
              poasList.push(data.poas);
              profitList.push(data.profit_percentage);
              dateRangeList.push(
                moment(data.poas_month.slice(0, 7)).format('MMMM-YYYY')
              );
              dateRangeList.forEach((element) => {
                graphDateList.push(moment(element).format('MMMM'));
              });
              setDateList(dateRangeList);
              setGraphData({
                labels: graphDateList,
                datasets: [
                  {
                    label: 'POAS',
                    data: poasList,
                    fill: false,
                    backgroundColor: '#FF823C',
                    borderColor: 'rgb(26, 143, 240)',
                    yAxisID: 'y1',
                    minbarThickness: 30,
                    maxBarThickness: 60,
                    borderRadius: 5,
                  },
                  {
                    label: 'Profit',
                    data: profitList,
                    fill: false,
                    backgroundColor: '#0096FF',
                    borderColor: 'rgb(255, 107, 61)',
                    yAxisID: 'y',
                    minbarThickness: 30,
                    maxBarThickness: 60,
                    borderRadius: 5,
                  },
                ],
              });
            });
          } else {
            setTotalRevenue(_.round(responseData[0].total_revenue));
          }
          setState(SUCCESS);
        })
        .catch((error) => {
          setState(ERROR);
          // eslint-disable-next-line no-console
          console.error('failed to get POAS databox Response', error);
        });
    }
  };

  useEffect(() => {
    setState(LOADING);
    if (!_.isEmpty(account) && !_.isEmpty(company)) {
      getPoasData(
        company.id,
        account.id,
        moment(prevMonth).format('YYYY-MM-DD')
      );
      setCurrency(account.currency_code);
      setRequestData({
        company_id: company.id,
        account_id: account.id,
        poas_month: moment(`01 ${value.replace('-', ' ')}`).format(
          'YYYY-MM-DD'
        ),
        total_revenue: totalRevenue,
      });
    }
  }, [account, accountlist, company]);

  useEffect(() => {
    if (!_.isEmpty(getResponse) && Object.keys(getResponse[0]).length > 1) {
      const revenue = getResponse.find(
        (data) =>
          data.poas_month ===
          moment(`01 ${value.replace('-', ' ')}`).format('YYYY-MM-DD')
      );

      setSelectedObject(revenue);
      if (revenue) {
        setTotalRevenue(_.round(revenue.total_revenue));
      } else {
        setTotalRevenue(
          getResponse.filter(
            (obj) => !Object.keys(obj).includes('poas_month')
          )[0].total_revenue
        );
      }
      getResponse.forEach((data) => {
        poasList.push(data.poas);
        profitList.push(data.profit_percentage);
      });
    }
  }, [value, getResponse]);

  return (
    <>
      <Container maxWidth={false}>
        {date.getDate() === 1 && (
          <POASLinkNotification
            prevMonth={prevMonth}
            isShown={isShown}
            setIsShown={setIsShown}
          />
        )}
      </Container>
      {isLoggedIn ? (
        <POASToolbar />
      ) : (
        <Box textAlign='center' sx={{ mt: 4 }}>
          <Typography variant='h1'>POAS & Profit Calculator</Typography>
        </Box>
      )}
      <POASMainDiv>
        <Container maxWidth={false} sx={{ py: 4 }}>
          <Box
            display='flex'
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            sx={{ mb: 2 }}
          >
            <Typography variant='body1'>
              Profit On Ad Spend (POAS) is a metric used by marketers to
              calculate the profit earned on ad spend. POAS can help you
              evaluate the efficiency of your ad campaign better.
            </Typography>
          </Box>
          <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item sm={12} md={6}>
              <Typography variant='body1'>
                POAS &#61; Profit &#8725; Ads Spend
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography variant='body1'>
                Expenses &#61; Marketing &#43; Packing &#43; Shipping &#43;
                Making &#43; Tax
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography variant='body1'>
                Profit &#61; Revenue &#8722; Expenses
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography variant='body1'>
                Ads Spend &#61; Marketing Cost
              </Typography>
            </Grid>
          </Grid>
          {isLoggedIn ? (
            <Box>
              <POASCalculatorMain
                state={state}
                requestData={requestData}
                setTotalRevenue={setTotalRevenue}
                totalRevenue={totalRevenue}
                currency={currency}
                dateRangeList={dateList}
                setPOAS={setPOAS}
                poas={poas}
                setProfitPercentage={setProfitPercentage}
                profitPercentage={profitPercentage}
                setGraphData={setGraphData}
                graphData={graphData}
                getResponse={getResponse}
                value={value}
                setValue={setValue}
                setTotalProfit={setTotalProfit}
                totalProfit={totalProfit}
                selectedObject={selectedObject}
                setSelectedObject={setSelectedObject}
              />
            </Box>
          ) : (
            <POASCalculator
              setPOAS={setPOAS}
              poas={poas}
              setProfitPercentage={setProfitPercentage}
              profitPercentage={profitPercentage}
              setTotalProfit={setTotalProfit}
              totalProfit={totalProfit}
            />
          )}
        </Container>
      </POASMainDiv>
    </>
  );
};
export { POASmain };
