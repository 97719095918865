import React from 'react';
import { ToggleButton } from '@mui/material';
import { StyledToggleButtonGroup } from '../StyledComponents/StyledCommon';

const ToggleAffectedButton = ({ toggled, setToggled }) => {

    const handleToggled = (event, newToggled) => {
        if (newToggled !== null) {
            setToggled(newToggled);
        }
    };


    return (
        <StyledToggleButtonGroup
            value={toggled}
            exclusive
            onChange={handleToggled}
            aria-label="toggle button group"
            className="twoToggleParent"
        >
            <ToggleButton
                value="Removed Items"
                aria-label="toggle button 1"
                color={toggled === 'Removed Items' ? 'primary' : 'standard'}
                className="twoToggle1"
            >
                Removed Items
            </ToggleButton>
            <ToggleButton
                value="Remaining Items"
                aria-label="toggle button 2"
                color={toggled === 'Remaining Items' ? 'primary' : 'standard'}
                className="twoToggle2"
            >
                Remaining Items
            </ToggleButton>
        </StyledToggleButtonGroup>
    );
};

export { ToggleAffectedButton };
