import {
  getCellRange,
  toTitleCase,
  aggregateCalculator,
  aggregateFunctionGenerator,
  isCurrency,
} from './index';

const createTableForObject = (
  sheet,
  label,
  dataVariable,
  currencyCode,
  total = true
) => {
  sheet.mergeCells(
    getCellRange(
      1,
      1,
      Object.keys(dataVariable[Object.keys(dataVariable)[0]][0]).length
    )
  );
  const labelCell = sheet.getCell(`A1`);
  labelCell.value = toTitleCase(label);
  labelCell.alignment = { horizontal: 'center' };

  Object.entries(dataVariable).forEach(([key, value], index) => {
    sheet.mergeCells(
      getCellRange(index * 16 + 2, 1, Object.keys(value[0]).length)
    );

    const titleCell = sheet.getCell(
      `${String.fromCharCode(65)}${index * 16 + 2}`
    );

    titleCell.value = key;
    titleCell.alignment = { horizontal: 'center' };
    sheet.addTable({
      name: `Table${toTitleCase(key)}`,
      ref: `A${index * 16 + 3}`,
      headerRow: true,
      totalsRow: false,
      style: {
        theme: null,
        showRowStripes: true,
      },
      columns: Object.keys(value[0]).map((k) => ({
        name: isCurrency(toTitleCase(k))
          ? `${toTitleCase(k)} (${currencyCode})`
          : toTitleCase(k),
        filterButton: false,
      })),
      rows: value.map((v) => Object.values(v)),
    });

    const aggregateFunctionArray = aggregateFunctionGenerator(value[0]);
    Object.keys(value[0]).forEach((innerKey, i) => {
      const cell = sheet.getCell(
        `${String.fromCharCode(i + 65)}${index * 16 + value.length + 4}`
      );

      if (total) {
        cell.value = aggregateCalculator(
          aggregateFunctionArray,
          value,
          innerKey,
          i
        );
      }
    });
  });
};

export { createTableForObject };
