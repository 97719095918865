import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Button, CircularProgress } from '@mui/material';
import { Form, Formik } from 'formik';
import { FormikField, FormikPasswordField } from './Formik';
import { FormikPhone } from '../../../widgets/FormikForm/FormikField';
import { StyledLink, StyledMuiLink } from './Formik/Styles';
import { AuthService } from '../../../services/auth';
import { SignUpSchema } from '../Schemas';
import { ScrollToError } from './utils/ErrorScroll';
import { ErrorAlert } from '../../../widgets/NewAlerts/ErrorAlert';
import { HeadingWithUnderline } from '../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { PasswordStrengthChecker } from './utils/PasswordStrengthChecker';

const SignUpForm = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const targetElement = useRef(null);

  const initialValues = {
    full_name: '',
    username: '',
    email: '',
    phone_number: '',
    password: '',
    confirm_password: '',
  };
  const signUpHandler = (values, formikHelpers) => {
    if (!navigator.onLine) {
      setErrorMessage(
        'Internet connection is not available. Please check your connection.'
      );
      setShowMessage(false);
      return;
    }
    AuthService.signup(values)
      .then((resp) => {
        if (resp.status === 201) {
          navigate('/signupsubmit');
        }
        formikHelpers.resetForm();
      })

      .catch((err) => {
        setShowMessage(false);
        if (err.response) {
          setErrorMessage(() => err?.response?.data?.error?.detail);
          targetElement?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        } else {
          setErrorMessage('Action failed due to an error. Please retry later.');
        }
      });
  };

  const trimFullName = (values) => {
    const trimmedFullName = values.full_name.trim();
    return { ...values, full_name: trimmedFullName };
  };

  return (
    <Formik
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => {
        setShowMessage(true);
        const trimmedValues = trimFullName(values);
        signUpHandler(trimmedValues, formikHelpers);
      }}
      validationSchema={SignUpSchema}
    >
      {({
        values,
        dirty,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form>
          <HeadingWithUnderline
            heading='Create a FREE Account'
            sx={{ textAlign: 'left' }}
          />
          <Box sx={{ pb: 2 }}>
            <FormikField
              required
              name='full_name'
              type='text'
              placeholder='Enter Full Name'
              value={values.full_name}
              label='Full Name'
              error={Boolean(errors.full_name) && touched.full_name}
              helperText={touched.full_name && errors.full_name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
          <Box sx={{ pb: 2 }}>
            <FormikField
              required
              name='username'
              type='text'
              placeholder='Enter Username'
              value={values.username}
              label='Username'
              error={Boolean(errors.username) && touched.username}
              helperText={touched.username && errors.username}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
          <Box sx={{ pb: 2 }}>
            <FormikField
              required
              name='email'
              type='email'
              placeholder='Enter Email ID'
              value={values.email.toLowerCase()}
              label='Email'
              error={Boolean(errors.email) && touched.email}
              helperText={touched.email && errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
          <Box sx={{ pb: 2 }}>
            <FormikPhone
              name='phone_number'
              label='Phone'
              value={values.phone_number}
              error={touched.phone_number && errors.phone_number}
              onChange={(phoneNumber, country, e, value) => {
                handleChange(e);
                setFieldValue('phone_number', value);
              }}
              onBlur={handleBlur}
            />
            {touched.phone_number && errors.phone_number ? (
              <Typography color='error' variant='smallerRegular'>
                {errors.phone_number}
              </Typography>
            ) : null}
          </Box>
          <Box sx={{ pb: 2 }}>
            <FormikPasswordField
              required
              name='password'
              type={showPassword ? 'text' : 'password'}
              placeholder='Enter Password'
              value={values.password}
              label='Password'
              error={Boolean(errors.password) && touched.password}
              helperText={touched.password && errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
              setShowPassword={setShowPassword}
              showPassword={showPassword}
            />
            <PasswordStrengthChecker value={values.password} />
          </Box>
          <Box sx={{ pb: 3 }}>
            <FormikPasswordField
              required
              name='confirm_password'
              type={showPassword ? 'text' : 'password'}
              placeholder='Confirm Password'
              value={values.confirm_password}
              label='Confirm Password'
              error={
                Boolean(errors.confirm_password) && touched.confirm_password
              }
              helperText={touched.confirm_password && errors.confirm_password}
              onChange={handleChange}
              onBlur={handleBlur}
              setShowPassword={setShowPassword}
              showPassword={showPassword}
            />
          </Box>
          <ScrollToError />
          <Box sx={{ margin: '0px 0px 8px 0px' }}>
            <Typography variant='smallRegular'>
              By creating an account, I agree to the
              <StyledMuiLink
                href='https://adhelp.io/terms-and-conditions'
                target='_blank'
                sx={{ paddingLeft: '5px' }}
              >
                Terms and Conditions
              </StyledMuiLink>
            </Typography>
          </Box>
          {errorMessage && (
            <Box ref={targetElement} sx={{ mb: '12px' }}>
              <ErrorAlert message={errorMessage} />
            </Box>
          )}
          <Button
            type='submit'
            variant='contained'
            disabled={!dirty}
            fullWidth
            onSubmit={handleSubmit}
          >
            {showMessage ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress
                  sx={{
                    color: '#fff',
                    width: '24px !important',
                    height: '24px !important',
                  }}
                />
              </Box>
            ) : (
              'Sign Up for FREE'
            )}
          </Button>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={{ margin: '8px 0px 0px 0px' }}
          >
            <Typography variant='smallRegular'>
              Already have an account?
              <StyledLink to='/signin' sx={{ paddingLeft: '5px' }}>
                Sign in
              </StyledLink>
            </Typography>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export { SignUpForm };
