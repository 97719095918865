/* eslint-disable react/jsx-props-no-spreading */
import {
  KeyboardArrowDownRounded,
  SwitchLeft,
  SwitchRight,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { useField } from 'formik';
import React, { useContext, useState } from 'react';
import { visuallyHidden } from '@mui/utils';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';

/* 
  This component deals with the value field which has this skeleton
  value: {
    value: '',
    type: ''
  }
  the 'field' variable is the parent value
  and 'childField' variable is the child hook
  
  It can switch between text and select inputs.
*/
const TextAndSelectInput = ({ name, options, hideLabel = true, label, sx }) => {
  const { user } = useContext(UserInfoContext);
  const [field, , helpers] = useField(name);
  const [childField, childMeta, childHelpers] = useField(`${name}.value`);
  const [inputValue, setInputValue] = useState(childField.value);
  // toggle between FIELD and VALUE input
  const toggleIsField = () => {
    if (field.value.type === 'static') {
      helpers.setValue({
        value: options[0],
        type: 'field',
      });
      setInputValue(options[0]);
    } else {
      helpers.setValue({
        value: '',
        type: 'static',
      });
      setInputValue('');
    }
  };
  return (
    <FormControl
      fullWidth
      error={childMeta.error && childMeta.touched}
      sx={{
        width: '100%',
        '@media(min-width:800px)': {
          width: 'auto',
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            flex: 'initial',
            width: '300px',
            maxWidth: '100%',
          },
        },
        ...sx,
      }}
      disabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
    >
      <InputLabel
        htmlFor='id'
        sx={{
          transform: 'none',
          position: 'static',
          flexShrink: 0,
          mb: 1,
          ...(hideLabel && visuallyHidden),
        }}
      >
        {label}
      </InputLabel>
      <Box sx={{ display: 'flex' }}>
        {field.value?.type === 'static' ? (
          <OutlinedInput
            name={name}
            sx={{
              backgroundColor: '#FFF',
              borderRadius: '4px 0px 0px 4px',
              borderRight: 'none',
              height: '45px',
              flex: 1,
              '& .Mui-disabled': {
                backgroundColor: 'transparent'
              }
            }}
            id={name}
            type='text'
            placeholder='Enter Value or Select Field'
            {...childField}
          />
        ) : (
          <Autocomplete
            disableClearable

            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              if (event) {
                setInputValue(newInputValue);
              }
            }}
            // sets the default value and tells the autocomplete component that something is selected
            // this prevents the autocomplete inputValue from going blank when you click in and out of the input box without selecting any option EVER due to nothing being selected
            defaultValue={childField.value || options[0]}
            freeSolo={false}
            options={options}
            // Select name from object for FIELD input type.
            getOptionLabel={(option) => option}
            // Clear on blur
            onChange={(_, val) => {
              childHelpers.setValue(val);
            }}
            onBlur={childField.onBlur}
            // disabled because clearing sets the value to null which messes with yup's type validation

            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Enter Value or Select Field'
                sx={{
                  backgroundColor: '#FFF',
                }}
                size='medium'
                name={childField.name}
                value={childField.value}
                onBlur={childField.onBlur}
                error={childMeta.error && childMeta.touched}
              />
            )}
            ListboxProps={{ style: { maxHeight: '300px' } }}
            popupIcon={<KeyboardArrowDownRounded />}
            sx={{ flex: 1 }}
          >
            <MenuItem value='' />
          </Autocomplete>
        )}

        <IconButton
          onClick={toggleIsField}
          sx={{
            backgroundColor: 'background.default',
            borderRadius: '0px 4px 4px 0px',
            border: '1px solid',
            borderColor: 'border.main',
          }}
          disabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
        >
          {field.value.type === 'field' ? <SwitchLeft /> : <SwitchRight />}
        </IconButton>
      </Box>

      {childMeta.error && childMeta.touched && (
        <FormHelperText>{childMeta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export { TextAndSelectInput };
