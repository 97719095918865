import React, { useState, useMemo, useContext } from 'react';

import { Box } from '@mui/material';

import { AccessibilityRounded, TableChartOutlined } from '@mui/icons-material';
import { Filter } from '../../../../../../components/Filter';
import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';

import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { UserByGenderChart } from '../../../../Databoxes/UserByGender/Chart';
import { UserByGenderTable } from '../../../../Databoxes/UserByGender/Table';
import { CustomSwitch } from '../../../../../../components/CustomSwitch/CustomSwitch';
import { Loading } from '../../../../../../components/Databox/Loading';

import { getDemoDashboardService } from '../../../../../../services/DemoDashboard';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { AccountContext } from '../../accountContext';

const getFilterList = (platform) =>
  platform === 'meta'
    ? [
        {
          name: 'Revenue',
          value: 'Revenue',
        },
        {
          name: 'Cost',
          value: 'Cost',
        },
        {
          name: 'Clicks',
          value: 'Clicks',
        },
        {
          name: 'Conversions',
          value: 'Conversions',
        },
        {
          name: 'ROAS',
          value: 'ROAS',
        },
      ]
    : [
        {
          name: 'Clicks',
          value: 'Clicks',
        },
        {
          name: 'Cost',
          value: 'Cost',
        },
        {
          name: 'Conversions',
          value: 'Conversions',
        },
        {
          name: 'Revenue',
          value: 'Revenue',
        },
        {
          name: 'ROAS',
          value: 'ROAS',
        },
      ];

const DisplayComponentSelector = ({ showTable, chart, table }) => (
  <Box flexGrow={1} minHeight={0}>
    {showTable ? table : chart}
  </Box>
);

const UserByGenderContainer = ({ platform }) => {
  const { currencyCode } = useContext(AccountContext);
  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'GENDER',
    }),
    []
  );

  const [state] = useDataFetcher(
    [],
    getDemoDashboardService(platform),
    memoizedArgs
  );
  const [filter, setFilter] = useState(getFilterList(platform)[0]);
  // eslint-disable-next-line no-unused-vars
  const [showTable, setShowTable] = useState(false);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleChange = () => {
    setShowTable(!showTable);
  };

  return (
    <Databox>
      <DataboxHeading
        title='Users by Gender'
        info='Know your audience categorized by gender, and target users in a better way. Click on the table icon to switch to a tabular format.'
      >
        {!showTable ? (
          <Filter
            label='Filter'
            selectedFilter={filter}
            filterList={getFilterList(platform)}
            handleFilterChange={handleFilterChange}
          />
        ) : null}
      </DataboxHeading>
      <FetchingDisplay
        isError={state.isError}
        isLoading={state.isLoading}
        LoadingElement={<Loading />}
        ErrorElement={<ErrorDisplay />}
        SuccessElement={
          <DisplayComponentSelector
            showTable={showTable}
            chart={
              <Box p={2} height='100%' sx={{ position: 'relative' }}>
                <UserByGenderChart
                  data={state.data}
                  filter={filter.value}
                  currencyCode={currencyCode}
                />
              </Box>
            }
            table={
              <UserByGenderTable
                data={state.data}
                platform={platform}
                currencyCode={currencyCode}
              />
            }
          />
        }
      />

      <Box sx={{ display: 'flex', justifyContent: 'end', padding: 2 }}>
        <CustomSwitch
          checked={showTable}
          handleChange={handleChange}
          CheckedIcon={TableChartOutlined}
          Icon={AccessibilityRounded}
        />
      </Box>
    </Databox>
  );
};

export { UserByGenderContainer };
