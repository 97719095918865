import React, { useState, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Databox } from '../../../../../../components/Databox';
import { Loading } from '../../../../../../components/Databox/Loading';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { TopProducts } from '../../../../Databoxes/TopProducts';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { ErrorComponent } from '../../../../../../components/GlobalErrorComponent';

import { getDashboardDataService } from '../../../../../../utils/getDashboardDataService';

const TopProductsContainer = ({ platform, accountId, dateRange }) => {
  const [count, setCount] = useState(0);
  const memoizedArgs = useMemo(
    () => ({
      account_id: accountId,
      date_range: dateRange,
      databox_type: 'TOP_PRODUCTS',
    }),
    [accountId, dateRange]
  );

  const [state] = useDataFetcher(
    [],
    getDashboardDataService(platform),
    memoizedArgs,
    count
  );

  return (
    <Databox>
      <ErrorBoundary
        fallback={
          <ErrorComponent
            title='Top Products Insights'
            info='Know the most successful products of your business.'
          />
        }
      >
        <DataboxHeading
          title='Top Products Insights'
          info='Know the most successful products of your business.'
        />
        <FetchingDisplay
          isError={state.isError}
          isLoading={state.isLoading}
          LoadingElement={<Loading />}
          ErrorElement={
            <ErrorDisplay
              refresh={() => {
                setCount(count + 1);
              }}
              message={
                state.errorInfo?.message === "'segments.product_item_id'" ||
                state.errorInfo?.code === 400 ||
                state.errorInfo?.code === 204
                  ? 'No data available for selected date range.'
                  : `Couldn't load data`
              }
              showRefreshButton={
                !(
                  state.errorInfo?.message === "'segments.product_item_id'" ||
                  state.errorInfo?.code === 400 ||
                  state.errorInfo?.code === 204
                )
              }
            />
          }
          SuccessElement={<TopProducts data={state.data} />}
        />
      </ErrorBoundary>
    </Databox>
  );
};

export { TopProductsContainer };
