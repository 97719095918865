import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Fab,
  Typography,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { useLocation } from 'react-router-dom';
import {
  GoogleAdsLogo,
  GoogleAnalyticsLogo,
  MetaIconLogo,
  TagManagerLogo,
  MerchantCenterLogo,
  ShopifyLogo,
  WooLogo,
} from '../../../../../../constants/endpoints/assests/logos';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';

import { Platform } from './parts/Platform';
import { MetaPlatform } from './parts/MetaPlatform';
import {
  ShopifyPlatform,
  ShopifyPlatformNonAdhelp,
} from './parts/ShopifyPlatform';

import {
  useGetAccountsQuery,
  useGetCredQuery,
  useGetOauthUrlQuery,
} from '../../../../../../api/RTK/services/platformFlow';
import { WooPlatform } from './parts/WooPlatform';

const PLATFORMS = {
  GOOGLE_ADS: 'Google Ads',
  META: 'Meta Ads',
  GOOGLE_ANALYTICS: 'Google Analytics',
  MERCHANT_CENTER: 'Google Merchant Center',
  TAG_MANAGER: 'Google Tag Manager',
  SHOPIFY: 'Shopify',
  WOO_COMMERCE: 'WooCommerce',
};

const LOGOS = {
  GOOGLE_ADS: GoogleAdsLogo,
  GOOGLE_ANALYTICS: GoogleAnalyticsLogo,
  MERCHANT_CENTER: MerchantCenterLogo,
  TAG_MANAGER: TagManagerLogo,
  META: MetaIconLogo,
  SHOPIFY: ShopifyLogo,
  WOO_COMMERCE: WooLogo,
};

const LoadingStatusMenu = ({
  isSelected,
  getAccountsFetching,
  getAccountsSuccess,
  getAccountsError,
}) => {
  if (isSelected && getAccountsFetching)
    return (
      <Typography variant='smallerRegular'>
        Fetching
        <CircularProgress
          size={14}
          sx={{
            marginLeft: '4px',
            color: 'primary',
          }}
        />
      </Typography>
    );

  if (isSelected && getAccountsSuccess)
    return (
      <>
        <Typography variant='smallerRegular'>Fetched</Typography>
        <DoneIcon
          sx={{
            marginLeft: '4px',
            fontSize: '16px',
            color: '#0096ff',
          }}
        />
      </>
    );

  if (isSelected && getAccountsError)
    return (
      <>
        <Typography variant='smallerRegular'>Error</Typography>
        <CloseIcon
          sx={{
            marginLeft: '4px',
            fontSize: '16px',
          }}
        />
      </>
    );

  return null;
};

const LinkSpeedDial = ({ setAssociation }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [openPlatform, setOpenPlatform] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [platformHeadingName, setPlatformHeadinName] = useState(null);
  const [platformLogo, setPlatformLogo] = useState(null);
  const [openMeta, setOpenMeta] = useState(false);
  const [openShopifyPlatform, setOpenShopifyPlatform] = useState(false);
  const [openWooPlatform, setOpenWooPlatform] = useState(false);
  const { user } = useContext(UserInfoContext);

  const data = localStorage.getItem('wooComLink');

  const location = useLocation();
  const isRedirectedFromDashboard =
    location.state && location.state.fromDashboard;

  const {
    data: getCredResponse,
    isError: getCredError,
    isFetching: getCredFetching,
    error: errorCredResponse,
    isSuccess: credSuccess,
  } = useGetCredQuery(platform, {
    refetchOnMountOrArgChange: true,
    skip: !platform,
  });

  const {
    data: getOauthUrlResponse,
    isSuccess: getOauthUrlSuccess,
    error: errorOauthUrlResponse,
  } = useGetOauthUrlQuery(platform, {
    skip: !platform || credSuccess || user.role !== 'OWNER',
  });

  const {
    data: getAccountsResponse,
    isError: getAccountsError,
    isSuccess: getAccountsSuccess,
    isFetching: getAccountsFetching,
    isLoading: getAccountsLoading,
    error: errorAccountResponse,
    reset,
  } = useGetAccountsQuery(platform, {
    refetchOnMountOrArgChange: true,
    skip: !platform || (getCredError && !getOauthUrlSuccess),
  });

  const OnClickPlatform = (platformName) => {
    setAnchorEl(null);
    setOpenPlatform(true);
    setOpen(false);
    setPlatform(platformName);
    setPlatformHeadinName(PLATFORMS[platformName]);
    setPlatformLogo(LOGOS[platformName]);
  };

  useEffect(() => {
    if (platform) {
      if (getCredError && getOauthUrlSuccess) {
        if (user?.role === 'OWNER') {
          window.open(
            getOauthUrlResponse?.authentication_url,
            '_parent',
            'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, width=700, height=900, left=500, top=300, titlebar=no, popup=true'
          );
        } else {
          setMessage(
            'Kindly reach out to the account Owner for assistance with reconnecting this account.'
          );
        }
      }
    }
  }, [platform, getCredResponse, getCredError, getOauthUrlSuccess]);

  useEffect(() => {
    if (isRedirectedFromDashboard) {
      setOpen(true);
    }
  }, [isRedirectedFromDashboard]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const OnClickMeta = () => {
    setAnchorEl(null);
    setOpenMeta(true);
    setOpen(false);
  };

  const OnClickShopifyPlatform = () => {
    setAnchorEl(null);
    setOpenShopifyPlatform(true);
    setOpen(false);
  };

  const OnClickWooPlatform = () => {
    setAnchorEl(null);
    setOpenWooPlatform(true);
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpenPlatform(false);
    setOpenMeta(false);
    setOpenShopifyPlatform(false);
    setOpenWooPlatform(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (JSON.parse(data)) {
      OnClickWooPlatform();
      localStorage.removeItem('wooComLink');
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Fab
          color='primary'
          aria-label='add'
          onClick={handleClick}
          size='large'
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          disableRipple
          sx={{ zIndex: '9' }}
        >
          <AddIcon fontSize='large' />
        </Fab>
        <Typography sx={{ display: 'block' }} variant='h5'>
          Connect New Account
        </Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 1,
          sx: {
            padding: '4px',
            borderRadius: '8px',
            minWidth: '350px',
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {Object.keys(PLATFORMS).map((platformKey, index) => (
          <span key={platformKey}>
            <LinkAccount
              imgSrc={LOGOS[platformKey]}
              AltText={`${platformKey} logo`}
              accountName={PLATFORMS[platformKey]}
              isSelected={platform === platformKey}
              getAccountsFetching={getAccountsFetching}
              getAccountsSuccess={getAccountsSuccess}
              getAccountsError={getAccountsError}
              onClick={() => {
                if (PLATFORMS[platformKey] === 'Meta Ads') {
                  OnClickMeta();
                } else if (PLATFORMS[platformKey] === 'Shopify') {
                  OnClickShopifyPlatform();
                } else if (PLATFORMS[platformKey] === 'WooCommerce') {
                  OnClickWooPlatform();
                } else {
                  OnClickPlatform(platformKey);
                }
              }}
            />

            {index === Object.keys(PLATFORMS).length - 1 ? null : <Divider />}
          </span>
        ))}
      </Menu>
      <ShopifyPlatform
        open={openShopifyPlatform}
        handleCloseModal={handleCloseModal}
        setAssociation={setAssociation}
      />
      <ShopifyPlatformNonAdhelp />
      <WooPlatform
        open={openWooPlatform}
        handleCloseModal={handleCloseModal}
        setAssociation={setAssociation}
      />
      <MetaPlatform
        open={openMeta}
        handleCloseModal={handleCloseModal}
        setAssociation={setAssociation}
      />
      <Platform
        open={openPlatform}
        handleCloseModal={handleCloseModal}
        setAssociation={setAssociation}
        getPlatform={platform}
        isFetching={getCredFetching}
        isError={getCredError}
        platformLogo={platformLogo}
        platformHeadingName={platformHeadingName}
        message={message}
        getOauthUrlResponse={getOauthUrlResponse}
        errorCredResponse={errorCredResponse}
        errorOauthUrlResponse={errorOauthUrlResponse}
        errorAccountResponse={errorAccountResponse}
        getOauthUrlSuccess={getOauthUrlSuccess}
        getAccountsError={getAccountsError}
        getAccountsResponse={getAccountsResponse}
        getAccountsLoading={getAccountsLoading}
        getAccountsSuccess={getAccountsSuccess}
        getAccountsFetching={getAccountsFetching}
        reset={reset}
      />
    </>
  );
};

const LinkAccount = ({
  onClick,
  imgSrc,
  AltText,
  accountName,
  getAccountsFetching,
  isSelected,
  getAccountsSuccess,
  getAccountsError,
}) => (
  <MenuItem
    onClick={onClick}
    sx={{
      padding: '8px 8px 8px 8px',
      '&.MuiMenuItem-root': {
        minHeight: '100%',
        width: '100%',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ListItemIcon sx={{ width: '22px', height: '22px' }}>
        <img src={imgSrc} alt={AltText} />
      </ListItemIcon>
      <Typography variant='smallMedium' sx={{ marginLeft: '8px' }}>
        {accountName}
      </Typography>
    </Box>
    <ListItemIcon>
      <LoadingStatusMenu
        isSelected={isSelected}
        getAccountsFetching={getAccountsFetching}
        getAccountsSuccess={getAccountsSuccess}
        getAccountsError={getAccountsError}
      />
    </ListItemIcon>
  </MenuItem>
);

export { LinkSpeedDial };
