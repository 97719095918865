import { styled } from '@mui/system';

// MUI imports
import { Box, FormControl, MenuItem } from '@mui/material';

const StyledBox = styled(Box)`
  .Mui-disabled{
    .MuiSelect-select{
    background-color: transparent;
    }
    .MuiSelect-iconOutlined{
      background-color: transparent;
    }
  }

  .MuiSelect-select {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 0px !important;
  }
  &.importInputForm {
    flex-basis: 50%;
    flex-grow: 1;
    padding-left: 15px;
    &:hover {
      .MuiFormLabel-root.MuiInputLabel-root {
        color: #323c46;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding-left: 0px;
    }
    .importInput {
      margin-top: 25px;
      input {
        height: 17px;
      }
    }
  }
  &.authInputs {
    flex-basis: 50%;
    flex-grow: 1;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding-left: 0px;
    }
    .importInput {
      margin-top: 25px;
      input {
        height: 17px;
      }
    }
  }
  &.authPass {
    flex-basis: 50%;
    flex-grow: 1;
    padding-left: 15px;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding-left: 0px;
    }
    .importInput {
      margin-top: 25px;
      input {
        height: 17px;
      }
    }
  }
  &.importSelectForm {
    flex-basis: 50%;
    flex-grow: 1;
    padding-right: 15px;
    .MuiSelect-outlined {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding-right: 0px;
    }
    .importDropdown {
      height: 40px;
      margin-top: 25px;
      svg {
        font-size: 21px;
        right: 10px;
      }
    }
    .MuiInputLabel-root.Mui-focused {
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
  &.importInputSecondLine {
    flex-basis: 70%;
    flex-grow: 1;
    padding: 0px 30px;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding: 0px;
    }
    .importInput {
      margin-top: 48px;
      @media screen and (max-width: 1200px) {
        margin-top: 35px;
      }
      input {
        height: 17px;
      }
    }
  }
  &.importSelectSecondLineFirst {
    flex-basis: 10%;
    flex-grow: 1;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
    }
    .importDropdown {
      height: 40px;
      margin-top: 25px;
    }
  }
  &.importSelectSecondLine {
    flex-basis: 20%;
    flex-grow: 1;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
    }
    .importDropdown {
      height: 40px;
      margin-top: 48px;
      @media screen and (max-width: 1200px) {
        margin-top: 35px;
      }
    }
    .MuiInputLabel-root.Mui-focused {
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
  &.importSelectThirdLine {
    flex-basis: 33.3%;
    flex-grow: 1;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    .MuiSelect-select {
      // max-width: 350px;
      // margin-left: 100px;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
    }
    .importDropdown {
      height: 40px;
      margin-top: 25px;
    }
  }
  &.importSelectMiddle {
    flex-basis: 33.3%;
    flex-grow: 1;
    padding: 0px 30px;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding: 0px;
    }
    .importDropdown {
      height: 40px;
      margin-top: 25px;
    }
  }
  &.projectInput {
    flex-basis: 50%;
    flex-grow: 1;
    padding-right: 15px;
    @media (max-width: 1200px) {
      padding-right: 0px;
    }
    &:hover {
      .MuiFormLabel-root.MuiInputLabel-root {
        color: #323c46;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding-left: 0px;
    }
    .importInput {
      margin-top: 25px;
      input {
        height: 17px;
      }
    }
  }
  &.projectSelect {
    flex-basis: 50%;
    flex-grow: 1;
    &:hover {
      .MuiFormLabel-root.MuiInputLabel-root {
        color: #323c46;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 50%;
      width: 100%;
      padding-right: 0px;
    }
    .importDropdown {
      height: 40px;
      margin-top: 25px;
      svg {
        font-size: 21px;
        right: 10px;
      }
    }
    .MuiInputLabel-root.Mui-focused {
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
  &.newFilterInput {
    flex-basis: 50%;
    flex-grow: 1;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding-left: 0px;
    }
    .importInput {
      margin-top: 25px;
      input {
        height: 17px;
      }
    }
  }
  .errorMsg {
    position: absolute;
    margin-left: 2px;
    margin-top: 2px;
    color: #e13f2a;
    opacity: 1;
    font-size: 12px;
  }
  .form-error {
    opacity: 1;
  }
  .hasError {
    border: 1px solid #e13f2a;
    border-radius: 4px;
  }
`;

const StyledFormControl = styled(FormControl)(
  ({ theme }) => `
  &.importSelectForm {
    flex-basis: 50%;
    flex-grow: 1;
    padding-right: 15px;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding-right: 0px;
    }
    .importDropdown {
      height: 40px;
      margin-top: 25px;
      
      svg{
        font-size: 21px;
        right:10px;
      }
    }
    .MuiInputLabel-root.Mui-focused{
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
  .MuiInputLabel-root.Mui-focused{
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .placeholder {
    color: #bbb8b8;
  }
  label[data-shrink='false']
    + .MuiInputBase-formControl
    .css-1c95932-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-left: 10px;
  }
  .css-4t3cqk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: ${theme.palette.text.primary};
    color: rgba(0, 0, 0, 0.6) !important;
  }
  &.importInputForm {
    flex-basis: 50%;
    flex-grow: 1;
    padding-left: 15px;
    &:hover{
      .MuiFormLabel-root.MuiInputLabel-root{
      color: #323c46;
      }   
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding-left: 0px;
    }
    .importInput {
      margin-top: 25px;
      input {
        height: 17px;
      }
    }
  }
  &.importSelectSecondLineFirst{
    flex-basis: 10%;
    flex-grow: 1;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
    }
    .importDropdown {
      height: 40px;
      margin-top: 25px;
    }
  }
  &.importSelectSecondLine{
    flex-basis: 20%;
    flex-grow: 1;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
    }
    .importDropdown {
      height: 40px;
      margin-top: 48px;
      @media screen and (max-width: 1200px) {
        margin-top: 35px;
      }
    }
    .MuiInputLabel-root.Mui-focused{
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
  &.importInputSecondLine {
    flex-basis: 70%;
    flex-grow: 1;
    padding: 0px 30px;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding: 0px;
    }
    .importInput {
      margin-top: 48px;
      @media screen and (max-width: 1200px) {
        margin-top: 35px;
      }
      input {
        height: 17px;
      }
    }
  }
  &.importSelectThirdLine{
    flex-basis: 33.3%;
    flex-grow: 1;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
    }
    .importDropdown {
      height: 40px;
      margin-top: 25px;
    }
  }
  &.importSelectMiddle{
    flex-basis: 33.3%;
    flex-grow: 1;
    padding: 0px 30px;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding: 0px;
    }
    .importDropdown {
      height: 40px;
      margin-top: 25px;
    }
  }
  &.projectInput {
    flex-basis: 50%;
    flex-grow: 1;
    padding-right: 15px;
    &:hover {.MuiFormLabel-root{
      color: #323c46;}
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding-left: 0px;
    }
    .importInput {
      margin-top: 25px;
      input {
        height: 17px;
      }
    }
  }
    
  &.newFilterInput {
    flex-basis: 50%;
    flex-grow: 1;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding-left: 0px;
    }
    .importInput {
      margin-top: 25px;
      input {
        height: 17px;
      }
    }
  }
  &.feedInputPreview {
    flex-basis: 80%;
    flex-grow: 1;
    padding-right: 15px;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    @media screen and (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
      padding-left: 0px;
    }
    .importInput {
      margin-top: 25px;
      input {
        height: 17px;
      }
    }
  }
  &.i2{
    padding-right: 0px;
  }
  &.fPreview{
    .importDropdown{
      margin-top: 48px !important;
    }
  }
  &.feedChannel{
    padding-left: 0px;
      &:hover .MuiFormLabel-root{
      color: #323c46;
    }
    .Mui-disabled{
      text-fill-color: rgba(153, 153, 153, 1);
    }
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
    .importInput{
      input{
        padding-left: 0px;
      }
    }
  }
  &.feedSelect{
    padding-right: 0px;
    padding-left: 15px;
    @media (max-width: 1200px){
      padding-left: 0px;
    }
    &:hover .MuiFormLabel-root{
      color: #323c46;
    }
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
  }
  &.projectSelect {
    flex-basis: 50%;
    flex-grow: 1;
    padding-right: 15px;
   &:hover{ .MuiFormLabel-root {
      color: #323c46;
    }
  }
    @media screen and (max-width: 1200px) {
      flex-basis: 50%;
      width: 100%;
      padding-right: 0px;
    }
    .importDropdown {
      height: 40px;
      margin-top: 25px;
      svg{
        font-size: 21px;
        right:10px;
      }
    }
    .MuiInputLabel-root.Mui-focused{
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
  &.scheduleSelect {
    padding-right: 0px;
    .MuiFormControl-root:hover .MuiFormLabel-root {
      color: #323c46;
    }
  }
`
);

const StyledMenuItem = styled(MenuItem)`
  &.selectMenu {
    padding: 10px;
    z-index: 999999999999;
  }
  .MuiMenu-paper {
    top: 50px !important;
    z-index: 999999999999999;
  }
`;

const StyledButtonWrap = styled('div')(
  ({ theme }) => `
  margin-top: 20px;
  .importFileButton{
    background-color: ${theme.palette.primary.main};
    color:  ${theme.palette.background.main};
    width: 200px;
    margin: 25px 10px 25px 10px;
    &:hover{
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.background.main}; 
    } 
  }
`
);

export { StyledFormControl, StyledMenuItem, StyledButtonWrap, StyledBox };
