import { api } from '../api';

const feedFileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllItems: builder.query({
      query: ({ companyId, projectId, pageNo, search }) => ({
        url: `/feed-management/list-items/${companyId}/${projectId}`,
        params: {
          page: pageNo,
          search,
        },
      }),
      providesTags: (result, query, arg) => [
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),
    itemsOverview: builder.query({
      query: ({ companyId, projectId, feedId }) => ({
        url: `/feed-management/items-overview`,
        method: 'POST',
        body: {
          company_id: companyId,
          feed_project_id: projectId,
          feed_id: feedId,
        },
      }),
      providesTags: (result, query, arg) => [
        { type: 'FEEDITEMS', id: `LIST-FEED-${arg.feedId}` },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),
    getSearchEnhanceItems: builder.query({
      query: ({ companyId, projectId, pageNo, search, feedId }) => ({
        url: `/feed-management/enhance/${companyId}/${projectId}`,
        params: {
          feed_id: feedId,
          page: pageNo,
          search,
        },
      }),
      providesTags: (result, query, arg) => [
        { type: 'FEEDITEMS', id: `LIST-FEED-${arg.feedId}` },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),
    enhanceFeed: builder.mutation({
      query: ({ companyId, projectId, feedId }) => ({
        url: `/feed-management/enhance`,
        method: 'POST',
        body: {
          company_id: companyId,
          feed_project_id: projectId,
          feed_id: feedId,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FEED', id: arg.feedId },
        { type: 'FEEDITEMS', id: `LIST-FEED-${arg.feedId}` },
      ],
    }),
    updateDownloadLink: builder.mutation({
      query: ({ companyId, projectId, feedId }) => ({
        url: `/feed-management/download-feed/${companyId}/${projectId}`,
        method: 'GET',
        params: {
          feed_id: feedId,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FEED', id: arg.feedId },
      ],
    }),
  }),
});

export const {
  useGetAllItemsQuery,
  useItemsOverviewQuery,
  useGetSearchEnhanceItemsQuery,
  useEnhanceFeedMutation,
  useUpdateDownloadLinkMutation,
} = feedFileApi;
