/*
isLoading, isError: Boolean
LoadingElement, ErrorElement, SuccessElement: Component
*/
const FetchDisplay = ({
  isLoading,
  isError,
  isPrefetch,
  PrefetchElement,
  LoadingElement,
  ErrorElement,
  SuccessElement,
}) => {
  if (isPrefetch) {
    return PrefetchElement;
  }

  if (isError) {
    return ErrorElement;
  }
  if (isLoading) {
    return LoadingElement;
  }

  return SuccessElement;
};

export { FetchDisplay };
