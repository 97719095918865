import * as React from 'react';
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { CloseButton } from '../../widgets/CloseButton';
import { StyledGlobalModal } from '../Modal/styles';

const DataSendingModalBase = ({
  open,
  handleCloseModal,
  status,
  children,
  successMessage,
  errorMessage,
}) => (
  <Modal open={open} onClose={handleCloseModal} closeAfterTransition>
    <Fade in={open}>
      <StyledGlobalModal>
        <Backdrop
          open={['SENDING', 'SENDSUCCESS', 'SENDERROR'].includes(status)}
          sx={{ zIndex: 99999, borderRadius: 3, backgroundColor: '#FFF' }}
        >
          { }
          {status === 'SENDING' ? <CircularProgress sx={{ margin: '0px auto 0px auto' }} /> : null}
          {status === 'SENDSUCCESS' ? (
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              gap={1}
            >
              <CheckCircle
                sx={{
                  color: 'primary.main',
                }}
              />
              <Typography variant='bigSemiBold'>{successMessage}</Typography>
            </Box>
          ) : null}
          {status === 'SENDERROR' ? (
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              gap={1}
            >
              <Cancel
                sx={{
                  color: 'error.main',
                }}
              />
              <Box display='flex' flexDirection='column' alignItems='center'>
                <Typography variant='bigSemiBold'>{errorMessage}</Typography>
                <Typography variant='bodySemiBold'>Try Again</Typography>
              </Box>
            </Box>
          ) : null}
        </Backdrop>
        {children}
        <CloseButton onClick={handleCloseModal} />
      </StyledGlobalModal>
    </Fade>
  </Modal>
);

export { DataSendingModalBase };
