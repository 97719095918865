import { styled } from '@mui/system';
import { Box, InputLabel } from '@mui/material';
import { Field } from 'formik';

const StyledFormikWrapper = styled(Box)(
  () => `
      display: flex;
      flex-direction: column;
     
    `
);

const StyledFormikField = styled(Field)(
  ({ theme }) => `
 .MuiOutlinedInput-input{
    padding: ${theme.spacing(1.2)} ${theme.spacing(1)}
 }
 '&.Mui-focused &.MuiOutlinedInput-notchedOutline'{
  border-color:${theme.palette.error.main};
 }
 .MuiFormHelperText-root{
    margin-left: 0px;
    margin-right: 0px;
    color:${theme.palette.error.main};
 }
`
);

const StyledInputLabel = styled(InputLabel)(
  ({ theme }) => `
      margin-bottom:${theme.spacing(1)} `
);

export { StyledFormikWrapper, StyledFormikField, StyledInputLabel };
