import { api } from '../api';

const feedFileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    generateMapping: builder.mutation({
      query: ({ companyId, projectId, fileId }) => ({
        url: `/feed-management/download/${companyId}/${projectId}`,
        method: 'GET',
        params: {
          file_id: fileId,
        },
      }),
      providesTags: (result, query, arg) => [
        { type: 'MAPPINGS', id: arg.fileId },
      ],
      invalidatesTags: (result, query, arg) => [
        { type: 'MAPPINGS', id: arg.fileId },
        { type: 'FIELDS', id: `PROJECT-${arg.projectId}` },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'FILES', id: arg.fileId },
      ],
    }),
    getMapping: builder.query({
      query: ({ companyId, projectId, fileId }) => ({
        url: `/feed-management/fields/${companyId}/${projectId}`,
        params: {
          file_id: fileId,
        },
      }),
      providesTags: (result, query, arg) => [
        { type: 'MAPPINGS', id: arg.fileId },
        { type: 'FIELDS', id: `PROJECT-${arg.projectId}` },
      ],
    }),
    updateMapping: builder.mutation({
      query: ({ companyId, projectId, fileId, fields, combineScheme }) => ({
        url: `/feed-management/fields/${companyId}/${projectId}`,
        method: 'PUT',
        body: {
          file_id: fileId,
          fields,
          combine_scheme: combineScheme,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'MAPPINGS', id: arg.fileId },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'FIELDS', id: `PROJECT-${arg.projectId}` },
      ],
    }),
    runImports: builder.mutation({
      query: ({ companyId, projectId }) => ({
        url: `/feed-management/runnow`,
        method: 'POST',
        body: {
          company_id: companyId,
          feed_project_id: projectId,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FIELDS', id: `PROJECT-${arg.projectId}` },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'FILES', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'FEEDITEMS', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'PROJECTS', id: arg.projectId },
        { type: 'FEED', id: `PROJECT-${arg.projectId}` },
      ],
    }),
  }),
});

export const {
  useGenerateMappingMutation,
  useGetMappingQuery,
  useUpdateMappingMutation,
  useRunImportsMutation,
} = feedFileApi;
