import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { getAccessToken, setAccessToken } from '../../utils/token';
import { apiBaseUrl } from '../../constants/api';

const baseQuery = fetchBaseQuery({
  baseUrl: `${apiBaseUrl}/api/`,
  prepareHeaders: (headers, { endpoint }) => {
    const token = getAccessToken();

    // send token if present
    // do not send token while sending a request to the 'refresh' endpoint as it throws an error in that case
    if (token && endpoint !== 'refresh') {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
  credentials: 'include',
  mode: 'cors',
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = await baseQuery(
      {
        url: '/auth/token/refresh',
        method: 'POST',
      },
      { ...api, endpoint: 'refresh' },
      extraOptions
    );
    if (refreshResult.data) {
      setAccessToken(refreshResult.data.access);
      // store the new token
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'FILES',
    'PROJECTS',
    'ITEMS',
    'PROJECTDATA',
    'MAPPINGS',
    'FEEDITEMS',
    'FIELDS',
    'ACTIONS',
    'FILTERS',
    'METRIC',
    'DIMENSION',
    'CRED',
    'OAUTHURL',
    'PLATFORM',
    'ACCOUNTS',
    'AGENCY',
    'COMPANY',
    'DETAILS',
    'PROJECTLIST',
    'ACCESSDETAILS',
    'USERPROFILE'
  ],
  endpoints: () => ({}),
});

export { api };
