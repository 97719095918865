import React from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { Add, DeleteOutlined } from '@mui/icons-material';

const OperationsHelpers = ({ insert, index, showRemove, remove, sx, disabled }) => (
  <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2, ...sx }}>
    <Button
      onClick={() =>
        insert(index + 1, {
          field: 'all fields',
          action: '',
          value: '',
        })
      }
      variant='outlined'
      sx={{
        backgroundColor: '#FFF',
        color: 'text.grey',
        border: '1px solid',
        borderColor: 'border.main',
        height: '45px',
        '&:hover': {
          color: 'primary.main',
        },
      }}
      startIcon={<Add />}
      disabled={disabled}
    >
      and
    </Button>
    {showRemove && (
      <IconButton
        onClick={() => remove(index)}
        variant='outlined'
        sx={{
          backgroundColor: '#FFF',
          color: 'text.grey',
          border: '1px solid',
          borderRadius: 1,
          borderColor: 'border.main',
          height: '45px',
          '&:hover': {
            color: 'primary.main',
          },
        }}
      >
        <DeleteOutlined />
      </IconButton>
    )}
  </Box>
);
export { OperationsHelpers };
