import React, { useRef } from 'react';
import { Box, Button } from '@mui/material';

import { Form, Formik } from 'formik';
import { EditCompanySchema } from '../../../Forms/Schemas';
import { FormikField } from '../../../Forms/Form/Formik';
import { ColoPicker } from '../../../Forms/Form/utils/ColorPicker';

import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';

const EditCompanyForm = ({ handleSubmit, handleClose, initialValues }) => {
  const formikRef = useRef();

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        companyName: initialValues.companyName || '',
        color: initialValues.color || '',
      }}
      onSubmit={(values) => {
        handleSubmit(values.companyName, values.color);
      }}
      validationSchema={EditCompanySchema}
    >
      {({
        values,
        isSubmitting,
        errors,
        touched,
        handleChange,
        handleBlur,
      }) => (
        <Form noValidate>
          <HeadingWithUnderline heading='Edit Company Details' />
          <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
            <FormikField
              name='companyName'
              label='Company Name'
              required
              type='text'
              placeholder='Enter Your Business Name'
              value={values.companyName}
              error={Boolean(errors.companyName) && touched.companyName}
              helperText={touched.companyName && errors.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
          <ColoPicker
            name='color'
            value={values.color}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.color}
          />
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            gap={3}
          >
            <Button
              variant='outlined'
              onClick={handleClose}
              sx={{ width: '100%', mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              type='submit'
              disabled={isSubmitting}
              fullWidth
            >
              Update
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export { EditCompanyForm };
