import React, { useContext, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { Box, Button, Stack, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledLink } from './Formik/Styles';
import { SignInSchema } from '../Schemas';
import { AuthenticationService } from '../../../services/auth';
import { ScrollToError } from './utils/ErrorScroll';
import { ErrorAlert } from '../../../widgets/NewAlerts/ErrorAlert';
import { FormikField, FormikPasswordField } from './Formik';
import { HeadingWithUnderline } from '../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { getAccessToken, setAccessToken } from '../../../utils/token';
import { usersService } from '../../../services/users';
import { UserInfoDispatchContext } from '../../../context/UserInfoDispatchContext';
import {
  userLogin,
  userLogout,
} from '../../../utils/userInfoDispatchFunctions';
import { FetchingInfoDispatchContext } from '../../../context/FetchingInfoDispatchContext';

const SignInForm = () => {
  const dispatch = useContext(UserInfoDispatchContext);
  const fetchingInfoDispatch = useContext(FetchingInfoDispatchContext);
  const navigate = useNavigate();
  const { verificationToken } = useParams();
  const targetElement = useRef(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [resetButton, setResetButton] = useState(false);

  const initialValues = {
    email: '',
    password: '',
  };

  const verifyToken = async () => {
    if (!verificationToken) return 'Token does not exist';

    try {
      const response = await usersService.verification(verificationToken);
      return response;
    } catch (error) {
      if (error.response.data.errors === 'Email already verified') {
        return error;
      }
      throw error;
    }
  };
  const handleOnSubmit = async (values, formikHelpers) => {
    if (!navigator.onLine) {
      setErrorMessage(
        'Internet connection is not available. Please check your connection.'
      );
      return;
    }
    try {
      setShowMessage(true);
      await verifyToken();
      const response = await AuthenticationService.login({
        ...values,
        email: values.email.toLowerCase().trim(),
      });
      fetchingInfoDispatch({ type: 'loading' });
      setAccessToken(response.data.access);
      userLogin(dispatch, { accessToken: getAccessToken() });
      formikHelpers.resetForm();
    } catch (error) {
      try {
        setShowMessage(false);
        userLogout(dispatch);
        if (
          error?.response?.status === 400 &&
          error?.response?.data?.error?.detail === 'User is not verified'
        ) {
          setErrorMessage(error?.response?.data?.error?.detail);
          setResetButton(true);
        } else if (error?.response?.status === 403) {
          navigate('/signinsubmit');
        } else {
          setErrorMessage(error?.response?.data?.error?.detail);
        }
      } catch (_error) {
        setErrorMessage('Action failed due to an error. Please retry later.');
      }
    }
  };

  return (
    <Formik
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={SignInSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <HeadingWithUnderline heading='Sign In' sx={{ textAlign: 'left' }} />
          {errorMessage && (
            <Box ref={targetElement} sx={{ mb: '12px' }}>
              <ErrorAlert message={errorMessage} />
            </Box>
          )}
          <Box sx={{ pb: 2 }}>
            <FormikField
              required
              placeholder='Enter Email ID'
              name='email'
              type='email'
              value={values.email.toLowerCase()}
              label='Email'
              error={Boolean(errors.email) && touched.email}
              helperText={touched.email && errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
          <Box sx={{ pb: 3 }}>
            <FormikPasswordField
              required
              name='password'
              type={showPassword ? 'text' : 'password'}
              placeholder='Enter Password'
              value={values.password}
              label='Password'
              error={Boolean(errors.password) && touched.password}
              helperText={touched.password && errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
              setShowPassword={setShowPassword}
              showPassword={showPassword}
            />
          </Box>
          <ScrollToError />
          <Button variant='contained' type='submit' fullWidth>
            {showMessage ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress
                  sx={{
                    color: '#fff',
                    width: '24px !important',
                    height: '24px !important',
                  }}
                />
              </Box>
            ) : (
              'Sign In'
            )}
          </Button>
          {resetButton && (
            <Button
              variant='contained'
              type='submit'
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => navigate('/resetsubmit')}
            >
              Resend Verification Link Again
            </Button>
          )}
          <Stack
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            flexDirection='row'
            sx={{ margin: '8px 0px 0px 0px' }}
          >
            <StyledLink to='/forgot-password'>Forgot Password?</StyledLink>
            <StyledLink to='/signup'>New User?</StyledLink>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export { SignInForm };
