const { NODE_ENV } = process.env;
const prodSettings = {
  apiBaseUrl: 'https://api.adhelp.io',
  staticPageBaseUrl: 'https://api.adhelp.io',
  env: 'DEV',
};
const devSettings = {
  apiBaseUrl: 'http://localhost:8000',
  staticPageBaseUrl: 'http://localhost:8000',
  // apiBaseUrl: 'https://api.adhelp.io',
  // staticPageBaseUrl: 'https://api.adhelp.io',
  env: 'PROD',
};
const settings = NODE_ENV === 'production' ? prodSettings : devSettings;

export { settings };
