/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useContext, useState } from 'react';
import { Route, useNavigate, useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

// MUI imports
import {
  TableContainer,
  Typography,
  Button,
  Stack,
  Select,
  MenuItem,
  Grid,
  Chip,
  Box,
} from '@mui/material';

// Internal component imports
import { MapperQualityTable } from '../GlobalComponents/MapperQualityTable/index';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { mappingValidationSchema } from '../../Schemas';

// Styled component import
import { StyledQualityContainer } from '../StyledComponents/StyledCommon';
import {
  useFinalizeXMLMutation,
  useUpdateFinalMappingMutation,
} from '../../../../api/RTK/services/feedFeed';
import { DataSendingModalBaseRTK } from '../../../../components/DataSendingModalBaseRTK';
import { ErrorSnackBar } from '../../../../widgets/SnackbarNew/ErrorSnackBar/ErrorSnackBar';


// convert null to '' for mapped_to
// use '' in mapped_to if imports is false
const preFormatter = (internalFields) =>
  internalFields.map((field) => {
    if (!field.mapped_to) {
      return {
        ...field,
        mapped_to: '',
      };
    }
    return field;
  });

const formatter = (values) => ({
  fields: values.channelFields.map((field) => {
    // set mapped_to to null if it is an empty string or if imports is false
    if (!field.mapped_to) {
      return {
        ...field,
        mapped_to: null,
      };
    }
    return field;
  }),
});

const FeedsQualityTable = ({
  reloader,
  channelFields,
  internalFields,
  channelInfo,
  marketType,
  explanation,
  imageSrc1,
  imageSrc2,
  imageSrc3,
  text1,
  text2,
  text3,
  classNameInternalField,
  tooltipClass,
  qualityScoreClass,
}) => {
  const [totalSum, setTotalSum] = useState(0);
  const [totalFields, setTotalFields] = useState(0);
  const [totalMandatory, setTotalMandatory] = useState(0);
  const [totalRecommended, setTotalRecommended] = useState(0);
  const [totalOptional, setTotalOptional] = useState(0);
  const [totalChecks, setTotalChecks] = useState(0);
  const [mandatoryFailed, setMandatoryFailed] = useState(0);
  const [recommendedFailed, setRecommendedFailed] = useState(0);
  const [optionalFailed, setOptionalFailed] = useState(0);
  const [patchValue, setPatchValue] = useState({});
  const [isFormDirty, setIsFormDirty] = useState(false);
  const dispatch = useDispatch();
  const formikRef = useRef();
  const feedSelectId = useSelector((state) => state.feedSummaryRedux.feedId);

  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const { user, isLoggedIn, company } = useContext(UserInfoContext);

  const navigate = useNavigate();
  const handlePreview = () => {
    navigate('../feeds/preview');
  };

  const [updateFinalMapping, { isLoading, isError, reset, isSuccess }] =
    useUpdateFinalMappingMutation();

  const [
    finalizeXML,
    {
      isLoading: finalizeLoading,
      isError: finalizeError,
      reset: finalizeReset,
      isSuccess: finalizeSuccess,
    },
  ] = useFinalizeXMLMutation();

  // patch request
  const handlePatchSubmit = (values) => {
    updateFinalMapping({
      companyId: company.id,
      projectId: projectSelectId,
      feedId: feedSelectId,
      ...formatter(values),
    })
      .unwrap()
      .then((response) =>
        Promise.all([
          response,
          finalizeXML({
            companyId: company.id,
            projectId: projectSelectId,
            feedId: feedSelectId,
          }),
        ])
      )
      .then(([postResponse]) => {
        setTimeout(() => {
          navigate('../feeds/preview');
        }, 500);
      });
  };
  const [loadCount, setLoadCount] = useState(0);

  const reloadList = () => setLoadCount(loadCount + 1);
  const snackbarstyle = {
    backgroundColor: '#fff0e7 !important',
    color: '#FF823C !important',
    width : '500px !important',
    padding : '10px 20px',
    border:"1px solid #FF823C",
    zIndex: 1000999 , position: 'fixed', bottom: '20px', left: '20px'
  };

  return (
    <>
      <Formik
        initialValues={{
          channelFields: preFormatter(channelFields),
          internalFields,
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (isLoggedIn && (user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR')) {
            handlePatchSubmit(values);
          }
          // navigate('../feeds/preview');
          // setSubmitting(false);
        }}
        validationSchema={!isLoggedIn ? mappingValidationSchema : null}
        enableReinitialize
      >
        {({ values, handleChange, handleBlur, isSubmitting }) => {
          const mappedProjectFields = values.channelFields.map(
            (f) => f.mapped_to
          );
          return (
            <>
              <Grid container>
                <Grid item lg={10} xs={12}>
                  <Typography component='p' sx={{ paddingRight: '10px' }}>
                    {imageSrc1 && (
                      <>
                        <span style={{ paddingRight: '2px' }}>
                          {explanation}{' '}
                        </span>
                        <img
                          src={imageSrc1}
                          alt='image1'
                          height={15}
                          width={15}
                          style={{ marginRight: '4px', marginTop: '2px' }}
                        />
                        <span style={{ paddingRight: '6px' }}>{text1}</span>
                        <img
                          src={imageSrc2}
                          alt='image2'
                          height={15}
                          width={15}
                          style={{ marginRight: '4px', marginTop: '2px' }}
                        />
                        <span style={{ paddingRight: '6px' }}>{text2}</span>
                        <img
                          src={imageSrc3}
                          alt='iamge 3'
                          height={15}
                          width={15}
                          style={{ marginRight: '4px', marginTop: '2px' }}
                        />
                        {text3}
                      </>
                    )}
                    {!imageSrc1 && explanation}
                  </Typography>

                  <Stack
                    direction={{ lg: 'row', xs: 'column' }}
                    alignItems={{ lg: 'center', xs: 'flex-start' }}
                    spacing={1}
                    sx={{ margin: '20px auto' }}
                  >
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <Box
                        sx={{
                          '@media screen and (max-width: 480px)': {
                            margin: '5px',
                          },
                        }}
                      >
                        <Chip
                          label={
                            <span>
                              <strong style={{ fontSize: '16px' }}>{totalChecks}</strong>/{totalFields}
                            </span>
                          }
                          variant='filled'
                          sx={{
                            backgroundColor: '#e3f7e9',
                            color: '#6cd189',
                            marginRight: '4px',
                          }}
                        />
                      </Box>
                      <Box>
                        Total fields - passed
                      </Box>
                      <Box
                        sx={{
                          '@media screen and (max-width: 480px)': {
                            margin: '5px',
                          },
                        }}
                      >
                        <Chip
                          label={
                            <span>
                              <strong style={{ fontSize: '16px' }}>{mandatoryFailed}</strong>/{totalMandatory}
                            </span>
                          }
                          variant='filled'
                          sx={{
                            backgroundColor: '#fce7e5',
                            color: '#eb8175',
                            marginRight: '4px',
                          }}
                        />
                      </Box>
                      <Box>
                        Mandatory fields - Failed
                      </Box>
                    </Stack>
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <Box
                        sx={{
                          '@media screen and (max-width: 480px)': {
                            margin: '5px',
                          },
                        }}
                      >
                        <Chip
                          label={
                            <span>
                              <strong style={{ fontSize: '16px' }}>{recommendedFailed}</strong>/{totalRecommended}
                            </span>
                          }
                          variant='filled'
                          sx={{
                            backgroundColor: '#fff0e7',
                            color: '#ffa26e',
                            marginRight: '4px',
                          }}
                        />
                      </Box>
                      <Box>
                        Recommended fields - Failed
                      </Box>

                      <Box
                        sx={{
                          '@media screen and (max-width: 480px)': {
                            margin: '5px',
                          },
                        }}
                      >
                        <Chip
                          label={
                            <span>
                              <strong style={{ fontSize: '16px' }}>{optionalFailed}</strong>/{totalOptional}
                            </span>
                          }
                          variant='filled'
                          sx={{
                            backgroundColor: '#dedede',
                            color: '#999999',
                            marginRight: '4px',
                          }}
                        />
                      </Box>
                      <Box>
                        Optional fields - Failed
                      </Box>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item lg={2} xs={12}>
                  <Chip
                    sx={{
                      paddingTop: '50px',
                      paddingBottom: '50px',
                      backgroundColor: '#e0f2ff',
                      paddingRight: '20px',
                      paddingLeft: '20px',
                      border: '1px solid #0096ff',
                    }}
                    className={qualityScoreClass}
                    label={
                      <Stack
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                        padding='35px'
                      >
                        {totalSum < 70 ? (
                          <Typography
                            component='h4'
                            sx={{
                              color: '#E13F2A',
                              fontWeight: '600',
                              fontSize: '42px',
                            }}
                          >
                            {totalSum}
                            <span
                              style={{
                                color: '#E13F2A',
                                fontWeight: '600',
                                fontSize: '24px',
                              }}
                            >
                              %
                            </span>
                          </Typography>
                        ) : (
                          <Typography
                            component='h4'
                            sx={{
                              color: '#0096ff',
                              fontWeight: '600',
                              fontSize: '42px',
                            }}
                          >
                            {totalSum}
                            <span
                              style={{
                                color: '#0096ff',
                                fontWeight: '600',
                                fontSize: '24px',
                              }}
                            >
                              %
                            </span>
                          </Typography>
                        )}
                        <Typography
                          component='h6'
                          sx={{
                            color: '#323C46',
                            fontWeight: '500',
                            fontSize: '14px',
                          }}
                        >
                          Feed Quality
                        </Typography>
                      </Stack>
                    }
                    variant='outlined'
                  />
                </Grid>
              </Grid >

              <Form style={{ padding: '15px 0px' }}>
                {!isLoggedIn ? (
                  <TableContainer
                    sx={{
                      '@media screen and (max-width: 768px)': {
                        overflow: 'auto !important',
                      },
                    }}
                  >
                    <MapperQualityTable
                      reloader={reloader}
                      channelFields={channelFields}
                      channelInfo={channelInfo}
                      marketType={marketType}
                      setTotalSum={setTotalSum}
                      setTotalFields={setTotalFields}
                      setTotalMandatory={setTotalMandatory}
                      setTotalRecommended={setTotalRecommended}
                      setTotalOptional={setTotalOptional}
                      setTotalChecks={setTotalChecks}
                      setMandatoryFailed={setMandatoryFailed}
                      setOptionalFailed={setOptionalFailed}
                      setRecommendedFailed={setRecommendedFailed}
                      classNameInternalField={classNameInternalField}
                      tooltipClass={tooltipClass}
                    />
                  </TableContainer>
                ) : (
                  <StyledQualityContainer
                    sx={{
                      width: '100%',
                      maxHeight: '800px',
                      borderRadius: '8px',
                    }}
                  >
                    <TableContainer>
                      <MapperQualityTable
                        reloader={reloader}
                        channelFields={channelFields}
                        channelInfo={channelInfo}
                        marketType={marketType}
                        setTotalSum={setTotalSum}
                        setTotalFields={setTotalFields}
                        setTotalMandatory={setTotalMandatory}
                        setTotalRecommended={setTotalRecommended}
                        setTotalOptional={setTotalOptional}
                        setTotalChecks={setTotalChecks}
                        setMandatoryFailed={setMandatoryFailed}
                        setOptionalFailed={setOptionalFailed}
                        setRecommendedFailed={setRecommendedFailed}
                        classNameInternalField={classNameInternalField}
                        setIsFormDirty={setIsFormDirty}
                        setPatchValue = {setPatchValue }
                      />
                    </TableContainer>
                  </StyledQualityContainer>
                )}
                {!isLoggedIn ? (
                  <Stack
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                      '@media screen and (max-width: 991px)': {
                        position: 'fixed',
                        zIndex: '999',
                        width: '100%',
                        padding: '36px',
                        backgroundColor: '#ffffff',
                        bottom: '0px',
                        marginBottom: '0px',
                        left: '0px',
                        right: '0px',
                        boxShadow: '0px 0px 10px rgba(0,0,0,.08)',
                      },
                    }}
                  >
                    <Button
                      sx={{
                        position: 'fixed',
                        zIndex: '999',
                        bottom: '8px',
                        left: '12px',
                        padding: '5px 10px',
                        '@media screen and (max-width: 991px)': {
                          left: 'auto',
                          bottom: '18px',
                          padding: '5px 24px',
                        },
                      }}
                      variant='contained'
                      type='submit'
                      onClick={() => navigate('../feed-management/form')}
                    >
                      Check your Feed Quality
                    </Button>
                  </Stack>
                ) : (
                  <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    sx={{ marginTop: '40px', marginBottom: '20px' }}
                  >
                    <Button
                      sx={{
                        width: '200px',
                        margin: '5px',
                      }}
                      variant='outlined'
                      onClick={() => navigate('../feeds')}
                    >
                      Back
                    </Button>

                    <Button
                      sx={{
                        width: '200px',
                        margin: '5px',

                      }}
                      variant='contained'
                      type='submit'
                    >
                      {(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') ? 'Save & Next' : 'Next'}
                    </Button>
                  </Stack>
                )}
              </Form>
            </>
          );
        }}
      </Formik >
      {isFormDirty && (
            <ErrorSnackBar
              page='quality'
              message="You have unsaved changes."
              autoHideDuration={null}
              onPatchClick={() => handlePatchSubmit(patchValue)}
              sx={snackbarstyle}
            />
          )}
      <DataSendingModalBaseRTK
        open={
          isLoading ||
          finalizeLoading ||
          isError ||
          isSuccess ||
          finalizeError ||
          finalizeSuccess
        }
        handleCloseModal={() => {
          reset();
          finalizeReset();
        }}
        isLoading={isLoading || finalizeLoading}
        isError={isError || finalizeError}
        isSuccess={isSuccess && finalizeSuccess}
        successMessage={(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') ? 'Updated Mapping' : null}
        errorMessage='Unable to Edit Mapping'
      >
        <Box sx={{ height: '200px' }}> </Box>
      </DataSendingModalBaseRTK>
    </>
  );
};

export { FeedsQualityTable };
