import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { CreateCompanySchema } from '../../../Forms/Schemas';
import { FormikField } from '../../../Forms/Form/Formik';
import { StyledLink } from '../../../Forms/Form/Formik/Styles';
import { ErrorAlert } from '../../../../widgets/NewAlerts/ErrorAlert';
import { VideoModal } from '../../../../widgets/VideoModal';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { CompanyService } from '../../../../services/company';
import { fetchUserInfo } from '../../../../services/auth';
import {
  setCompany,
  setCompanyList,
  setUser,
  userLogout,
} from '../../../../utils/userInfoDispatchFunctions';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { UserInfoDispatchContext } from '../../../../context/UserInfoDispatchContext';

const initialValues = {
  name: '',
  website_url: '',
  mcc_id: '',
};

const CreateCompanyFormOnboarding = () => {
  const navigate = useNavigate();
  const { companyList } = useContext(UserInfoContext);
  const dispatch = useContext(UserInfoDispatchContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const targetElement = useRef(null);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = false;
  }, [!isMounted]);

  const handleOnSubmit = (values) => {
    CompanyService.createCompanyProject({
      name: values.name,
      website_url: values.website_url,
      company_colour: 1,
      mcc_id: values.mcc_id,
    })
      .then(() => setShowMessage(true))
      .then(() =>
        CompanyService.createCompany(
          values.name,
          values.website_url,
          values.mcc_id,
          'BLUE'
        )
      )
      .then((response) => Promise.all([response.data, fetchUserInfo()]))
      .then(([newCompany, userInfoResponse]) => {
        setCompanyList(dispatch, [newCompany]);
        setCompany(dispatch, newCompany);
        setUser(dispatch, userInfoResponse.data);
      })
      .catch((error) => {
        if (isMounted.current) {
          setShowMessage(false);
          setErrorMessage(error?.response?.data?.errors);
          targetElement?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        }
      });
  };

  useEffect(() => {
    if (companyList?.length > 0) {
      return navigate(`/accounts`, { replace: true });
    }
    return null;
  }, [companyList]);

  // Child modal
  const [openVideo, setOpenVideo] = useState(false);
  const handleOpenVideo = () => {
    setOpenVideo(true);
  };
  const handleCloseVideo = () => {
    setOpenVideo(false);
  };

  return (
    <>
      <Box ref={targetElement}>
        {errorMessage && <ErrorAlert message={errorMessage} />}
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={CreateCompanySchema}
      >
        {({ values, dirty, errors, touched, handleChange, handleBlur }) => (
          <Form noValidate>
            <HeadingWithUnderline heading='Create Company' />
            <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
              <FormikField
                name='name'
                label='Company Name'
                required
                type='text'
                placeholder='Enter your business name'
                value={values.name}
                error={Boolean(errors.name) && touched.name}
                helperText={touched.name && errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
              <FormikField
                name='website_url'
                label='Website URL'
                required
                type='url'
                placeholder='Enter your business website URL'
                value={values.website_url}
                error={Boolean(errors.website_url) && touched.website_url}
                helperText={touched.website_url && errors.website_url}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box
              sx={{
                height: (theme) => theme.spacing(10.8),
                position: 'relative',
              }}
            >
              <Typography
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'absolute',
                  top: '10px',
                  fontSize: '.7rem',
                }}
                variant='smallRegularFaded'
              >
                Optional
              </Typography>
              <FormikField
                name='mcc_id'
                label='MCC ID'
                type='text'
                placeholder='ID is mandatory if you have Google ads manager account'
                value={values.mcc_id}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <VideoModal
              openVideo={openVideo}
              handleOpen={handleOpenVideo}
              handleClose={handleCloseVideo}
            />
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              gap={3}
            >
              {companyList.length !== 0 && (
                <Button
                  variant='outlined'
                  type='submit'
                  onClick={() => navigate(-1)}
                  fullWidth
                >
                  Cancel
                </Button>
              )}
              <Button
                variant='contained'
                type='submit'
                disabled={!dirty}
                fullWidth
              >
                {showMessage ? 'Creating...' : 'Create'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Box display='flex' justifyContent='center' mt={1}>
        {companyList.length === 0 ? (
          <StyledLink
            onClick={() => {
              userLogout(dispatch);
            }}
          >
            Sign Out
          </StyledLink>
        ) : (
          <StyledLink to='/accounts'>Go to Accounts Page</StyledLink>
        )}
      </Box>
    </>
  );
};

export { CreateCompanyFormOnboarding };
