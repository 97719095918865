import React from 'react';
import { Typography, Box, Fade, Divider, Stack } from '@mui/material';
import {
  Action1If,
  Action1Then,
  Action2If,
  Action2Then,
} from '../../../../../../../constants/endpoints/assests/artWork';
import { CloseButton } from '../../../../../../../widgets/CloseButton';
import { StyledGlobalModal, StyledModal } from './StyledPlatformComponents';

const WooComUserBrowseModal = ({ open, handleCloseModal }) => (
  <StyledModal
    open={open}
    onClose={handleCloseModal}
    closeAfterTransition
    sx={{ overflowY: 'auto' }}
  >
    <Fade
      in={open}
      sx={{
        top: '70%',
        mb: 2,
        '@media(min-width:900px)': {
          top: '10%',
          minWidth: '600px',
        },
        '@media(min-width:1200px)': {
          top: '25%',
          minWidth: '600px',
        },
        '@media(min-width:1400px)': {
          top: '65%',
          minWidth: '700px',
        },
        '@media(min-width:1800px)': {
          top: '55%',
          minWidth: '800px',
        },
        minWidth: '92%',
      }}
    >
      <StyledGlobalModal sx={{ maxWidth: '100%' }}>
        <Box
          sx={{
            paddingBottom: (theme) => `${theme.spacing(2)}`,
            borderBottom: (theme) =>
              `.08rem solid ${theme.palette.primary.main}`,
            marginBottom: (theme) => `${theme.spacing(2)}`,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography variant='h4'>
            In-built actions and their uses for Google Shopping
          </Typography>
        </Box>
        <Stack spacing={3}>
          <Box gap={3}>
            <Typography variant='h5'>
              Action 1: Generate Product Description
            </Typography>
            <Typography variant='smallRegular'>
              Approximately 15% of all search terms that shopping ads appear for
              are matched to words and phrases within product description. If
              your product lacks descriptions or contains insufficient
              information, simply select this action and apply it.
            </Typography>
            <Box component='img' src={Action1If} sx={{ width: '100%' }} />
            <Box component='img' src={Action1Then} sx={{ width: '100%' }} />
            <Typography variant='smallRegular'>
              This will create a product description combining title (if there
              is one already) + color +size + brand + gender
            </Typography>
          </Box>
          <Divider />
          <Box gap={3}>
            <Typography variant='h5'>
              Action 2: Fix in_stock availability field
            </Typography>
            <Typography variant='smallRegular'>
              Google Shopping exclusively accepts &quot;in_stock&quot; and
              &quot;out_of_stock&quot; as valid values for availability. Even if
              your items are available, they must adhere precisely to these
              formats to be accepted.
            </Typography>
            <Box component='img' src={Action2If} sx={{ width: '100%' }} />
            <Box component='img' src={Action2Then} sx={{ width: '100%' }} />
            <Typography variant='smallRegular'>
              This will change all the availability values which contain in, In,
              iN, IN to in_stock
            </Typography>
          </Box>
        </Stack>
        <CloseButton onClick={handleCloseModal} />
      </StyledGlobalModal>
    </Fade>
  </StyledModal>
);

export { WooComUserBrowseModal };
