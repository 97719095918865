import React from 'react';

import { Button, Grid, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import moment from 'moment';

import { StyledLinkNotification } from './StyledPOASLinkNotification';

const POASLinkNotification = ({ setIsShown, isShown, prevMonth }) => {
  const handleLinkClick = (e) => {
    e.preventDefault();
    setIsShown(false);
  };

  return (
    isShown && (
      <StyledLinkNotification>
        <Grid
          container
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs={10} display='flex' alignItems='center'>
            <ErrorOutlineIcon className='errorOutlineIcon' />
            <Typography variant='smallMedium'>
              Enter values for the month of {moment(prevMonth).format('MMMM')}{' '}
              to compare with other months.
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
          >
            <Button className='notificationButton' onClick={handleLinkClick}>
              Close
            </Button>
          </Grid>
        </Grid>
      </StyledLinkNotification>
    )
  );
};

export { POASLinkNotification };
