import {
    GoogleShopping,
    DSA,
    Pinterest,
    Tiktok,
    MetaAds,
    Bing,
} from './QualityScoreAllChannels';

// common functional component to call like QualityScoreCalc.GoogleShopping 
const QualityScoreCalc = {
    GoogleShopping,
    DSA,
    Pinterest,
    Tiktok,
    MetaAds,
    Bing,
};

export { QualityScoreCalc };
