import React from 'react';

import { Grid, Typography, Box } from '@mui/material';
import { InfoTooltip } from '../../../../../components/InfoTooltip';
import { RangeSlider, RangeSliderBox } from './StyledRangeSlider';

const RangeSliderComponent = ({
  valueProps,
  setPOAS,
  setProfitPercentage,
  setProductMakingCost,
  productMakingCost,
  setMarketingCost,
  marketingCost,
  setTax,
  tax,
  setPackingCost,
  packingCost,
  setShippingCost,
  shippingCost,
  setOtherCosts,
  otherCosts,
  setTotalProfit,
}) => {
  const handleChangeProductCost = (event, newValue) => {
    setProductMakingCost(newValue);
  };

  const handleChangeMarketing = (event, newValue) => {
    setMarketingCost(newValue);
  };

  const handleChangeTax = (event, newValue) => {
    setTax(newValue);
  };

  const handleChangePacking = (event, newValue) => {
    setPackingCost(newValue);
  };

  const handleChangeShiping = (event, newValue) => {
    setShippingCost(newValue);
  };

  const handleChangeOther = (event, newValue) => {
    setOtherCosts(newValue);
  };

  const calculatedProductMakingCost = (
    (productMakingCost / 100) *
    parseFloat(valueProps)
  ).toFixed(0);

  const calculatedMarketingCost = (
    (marketingCost / 100) *
    parseFloat(valueProps)
  ).toFixed(0);

  const calculatedTax = ((tax / 100) * parseFloat(valueProps)).toFixed(0);

  const calculatedPackingCost = (
    (packingCost / 100) *
    parseFloat(valueProps)
  ).toFixed(0);

  const calculatedShippingCost = (
    (shippingCost / 100) *
    parseFloat(valueProps)
  ).toFixed(0);

  const calculatedOtherCosts = (
    (otherCosts / 100) *
    parseFloat(valueProps)
  ).toFixed(0);

  const totalSpend =
    parseFloat(calculatedProductMakingCost) +
    parseFloat(calculatedMarketingCost) +
    parseFloat(calculatedTax) +
    parseFloat(calculatedPackingCost) +
    parseFloat(calculatedShippingCost) +
    parseFloat(calculatedOtherCosts);

  const totalProfit = (parseFloat(valueProps) - totalSpend).toFixed(0);

  setTotalProfit(totalProfit);

  setProfitPercentage(
    ((parseFloat(valueProps) / parseFloat(totalSpend)) * 100 - 100).toFixed(2)
  );

  const POAS = (
    parseFloat(totalProfit) / parseFloat(calculatedMarketingCost)
  ).toFixed(2);
  setPOAS(POAS);

  return (
    <Grid container>
      <Grid item xs={12} md={6} className='sliderBox'>
        <RangeSliderLabel
          propsLabel='Product Making Cost'
          propsTooltipLabel='Money spent on making your product(s).'
        />
        <RangeSliderNew
          propsValue={productMakingCost}
          propsOnChange={handleChangeProductCost}
          propsContent={calculatedProductMakingCost}
          propsInputValue={valueProps}
        />
      </Grid>
      <Grid item xs={12} md={6} className='sliderBox'>
        <RangeSliderLabel
          propsLabel='Marketing Cost'
          propsTooltipLabel='Total amount spent on marketing your product(s).'
        />
        <RangeSliderNew
          propsValue={marketingCost}
          propsOnChange={handleChangeMarketing}
          propsContent={calculatedMarketingCost}
          propsInputValue={valueProps}
        />
      </Grid>
      <Grid item xs={12} md={6} className='sliderBox'>
        <RangeSliderLabel
          propsLabel='Tax'
          propsTooltipLabel='Amount spent on tax.'
        />
        <RangeSliderNew
          propsValue={tax}
          propsOnChange={handleChangeTax}
          propsContent={calculatedTax}
          propsInputValue={valueProps}
        />
      </Grid>
      <Grid item xs={12} md={6} className='sliderBox'>
        <RangeSliderLabel
          propsLabel='Packing Cost'
          propsTooltipLabel='Total amount spent on packaging.'
        />
        <RangeSliderNew
          propsValue={packingCost}
          propsOnChange={handleChangePacking}
          propsContent={calculatedPackingCost}
          propsInputValue={valueProps}
        />
      </Grid>
      <Grid item xs={12} md={6} className='sliderBox'>
        <RangeSliderLabel
          propsLabel='Shipping Cost'
          propsTooltipLabel='Total money spent on shipping.'
        />
        <RangeSliderNew
          propsValue={shippingCost}
          propsOnChange={handleChangeShiping}
          propsContent={calculatedShippingCost}
          propsInputValue={valueProps}
        />
      </Grid>
      <Grid item xs={12} md={6} className='sliderBox'>
        <RangeSliderLabel
          propsLabel='Other Costs'
          propsTooltipLabel='Money spent on miscellaneous expenses, if any.'
        />
        <RangeSliderNew
          propsValue={otherCosts}
          propsOnChange={handleChangeOther}
          propsContent={calculatedOtherCosts}
          propsInputValue={valueProps}
        />
      </Grid>
    </Grid>
  );
};

const RangeSliderNew = ({
  propsValue,
  propsOnChange,
  propsContent,
  propsInputValue,
}) => {
  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]+$/g, '');

  const valueLabelFormat = (props) => {
    const units = ['%'];
    const scaledValue = props;
    return `${scaledValue} ${units}`;
  };

  return (
    <RangeSliderBox display='flex' alignItems='center'>
      <span className='spanStyle'>0</span>
      <RangeSlider
        value={propsValue}
        onChange={propsOnChange}
        min={0}
        max={100}
        defaultValue={8}
        size='small'
        valueLabelDisplay='on'
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        sx={{
          '& .MuiSlider-thumb::before': {
            content: `'${addCommas(propsContent.toLocaleString())}'`,
          },
        }}
      />
      <span className='spanStyle'>
        {addCommas(removeNonNumeric(propsInputValue))}
      </span>
    </RangeSliderBox>
  );
};

const RangeSliderLabel = ({ propsLabel, propsTooltipLabel }) => (
  <Box display='flex' alignItems='center' className='sliderLabel'>
    <Typography variant='smallMedium' sx={{ mr: 1 }}>
      {propsLabel}
    </Typography>
    <InfoTooltip title={propsTooltipLabel} />
  </Box>
);

export { RangeSliderComponent };
