import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const GlobalLoadingComponent = () => (
  <Box
    sx={{
      width: '100%',
      padding: '300px 0px',
      display: 'grid',
      placeItems: 'center',
    }}
  >
    <CircularProgress />
  </Box>
);

export { GlobalLoadingComponent };
