import { styled } from '@mui/system';
import { FormControlLabel, RadioGroup } from '@mui/material';

const StyledStyledFormControlLabel = styled(FormControlLabel)(
  ({ theme }) => `
    flex-direction: column;
    align-items: flex-start;
    background-color: ${theme.palette.background.main};
    border: 1px solid ${theme.palette.border.main};
    border-radius: 8px;
    margin-left: unset;
    width: 100%;
    height: 100%;
    position: relative;
    .listIcon{
        color: ${theme.palette.primary.main};
      }
    .MuiRadio-root{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        border-radius: 8px 8px 0px 0px;
        border-bottom: 1px solid ${theme.palette.border.main};
        &:hover{
            background-color: ${theme.palette.background.main};
          };
    }
    .Mui-checked{
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.background.main} !important;
        &:hover{
            background-color: ${theme.palette.primary.main};
          };     
          .listIcon{
            color: ${theme.palette.primary.main};
          }
    }
    .radioName{
        position: absolute;
        left: 48px;
    }
    .whiteText{ 
        color: ${theme.palette.background.main};
    };
    .radioLabel{
        padding: 0px;
        .MuiListItemIcon-root{
            min-width: auto;
            padding-right: 8px;
            padding-top: 10px;
            color: #8AD2FF !important;
            svg{
                font-size: 14px;
            }
        }
        .MuiListItem-root{
            align-items: flex-start;
            padding-left: 12px;
            padding-top: 4px;
            user-select: none;
        }
    }
)`
);

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: flex-start;
`;

export { StyledStyledFormControlLabel, StyledRadioGroup };
