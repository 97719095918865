/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Button, Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { CancelRounded, CheckCircleSharp } from '@mui/icons-material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';

const DeleteDialog = ({ open, handleClose, handleConfirm }) => {
  const closeModalWithClick = () => {
    handleClose();
  };

  useEffect(() => {
    if (!open) {
      handleClose();
    }
  }, [open]);

  const handleDeleteConfirm = () => {
    handleConfirm();
  };

  return (
    <Dialog
      open={open}
      onClose={closeModalWithClick}
      PaperProps={{
        sx: {
          maxHeight: '180px',
          maxWidth: '450px',
          minHeight: '150px',
          minWidth: '300px',
          borderRadius: '12px',
          display: 'grid',
          placeContent: 'center',
          justifyItems: 'center',
        },
      }}
    >
      <DialogContent>
        <HeadingWithUnderline heading=' Are you sure you want to remove?' />
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 3,
            width: '100%',
          }}
        >
          <Button onClick={handleClose} variant='outlined' fullWidth>
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} variant='contained' fullWidth>
            Remove
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const SuccessDialog = ({ msgopen, closeModalWithClick }) => (
  <Dialog
    open={msgopen}
    onClose={closeModalWithClick}
    PaperProps={{
      sx: {
        borderRadius: '12px',
        display: 'grid',
        placeContent: 'center',
        justifyItems: 'center',
      },
    }}
  >
    <DialogContent>
      <Box
        sx={{
          display: 'grid',
          placeItems: 'center',
          height: '100%',
          maxHeight: '150px',
          maxWidth: '350px',
          minHeight: '120px',
          minWidth: '300px',
        }}
        component='span'
      >
        <CheckCircleSharp
          sx={{ height: '2rem', width: '2rem', color: 'primary.main' }}
        />
        <Typography
          variant='bodySemiBold'
          fontSize='1.125rem'
          textAlign='center'
          component='p'
        >
          Success
        </Typography>
      </Box>
    </DialogContent>
  </Dialog>
);

const ErrorDialog = ({ msgopen, copy, closeModalWithClick }) => (
  <Dialog
    open={msgopen}
    onClose={closeModalWithClick}
    PaperProps={{
      sx: {
        borderRadius: '12px',
        display: 'grid',
        placeContent: 'center',
        justifyItems: 'center',
      },
    }}
  >
    <DialogContent>
      <Box
        sx={{
          display: 'grid',
          placeItems: 'center',
          height: '100%',
          maxHeight: '150px',
          maxWidth: '350px',
          minHeight: '120px',
          minWidth: '300px',
        }}
        component='span'
      >
        <CancelRounded
          sx={{ height: '2rem', width: '2rem', color: 'error.main' }}
        />
        <Typography
          variant='bodySemiBold'
          fontSize='1.125rem'
          textAlign='center'
          component='p'
        >
          {copy?.data?.error?.details || 'Error'}
        </Typography>
      </Box>
    </DialogContent>
  </Dialog>
);
export { DeleteDialog, SuccessDialog, ErrorDialog };
