import axios from 'axios';

import { apiBaseUrl } from '../constants/api';

// use this if you need to pass an accessToken with the request
const authInstance = axios.create({
  baseURL: apiBaseUrl,
  // for CORS credentials
  withCredentials: true,
  timeout: 85000,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

export { authInstance };

