import { api } from '../api';

const feedProjectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllProjects: builder.query({
      query: ({ companyId }) => `/feed-management/project/${companyId}`,
      providesTags: (result, query, arg) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'PROJECTS', id })),
            { type: 'PROJECTS', id: `LIST-COMPANY-${arg.companyId}` },
          ]
          : [{ type: 'PROJECTS', id: `LIST-COMPANY-${arg.companyId}` }],
    }),

    getProjectDetails: builder.query({
      query: ({ companyId, projectId }) => ({
        url: `/feed-management/project/${companyId}`,
        method: 'GET',
        params: {
          feed_project_id: projectId,
        },
      }),
      providesTags: (result) => [{ type: 'PROJECTS', id: result?.id }],
    }),

    createProject: builder.mutation({
      query: ({ companyId, name }) => ({
        url: '/feed-management/project',
        method: 'POST',
        body: {
          company_id: companyId,
          name,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'PROJECTS', id: `LIST-COMPANY-${arg.companyId}` },
      ],
    }),

    editProject: builder.mutation({
      query: ({ companyId, data }) => ({
        url: `/feed-management/project/${companyId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'PROJECTS', id: arg.projectId },
        { type: 'PROJECTS', id: `LIST-COMPANY-${arg.companyId}` },
      ],
    }),

    deleteProject: builder.mutation({
      query: ({ companyId, projectId }) => ({
        url: `/feed-management/project/${companyId}`,
        method: 'DELETE',
        body: {
          feedproject_id: projectId,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'PROJECTS', id: arg.projectId },
      ],
    }),

    addProjectField: builder.mutation({
      query: ({
        companyId,
        projectId,
        fileId,
        name,
        dataType,
        isList,
        requirements,
      }) => ({
        url: `/feed-management/fields`,
        method: 'POST',
        body: {
          company_id: companyId,
          feed_project_id: projectId,
          file_id: fileId,
          name,
          data_type: dataType,
          is_list: isList,
          requirements,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'MAPPINGS', id: arg.fileId },
        { type: 'FIELDS', id: `PROJECT-${arg.projectId}` },
      ],
    }),

    editProjectField: builder.mutation({
      query: ({ companyId, projectId, data }) => ({
        url: `/feed-management/project-fields/${companyId}/${projectId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'MAPPINGS', id: arg.fileId },
        { type: 'FIELDS', id: `PROJECT-${arg.projectId}` },
      ],
    }),

    deleteProjectField: builder.mutation({
      query: ({ companyId, projectId, id }) => ({
        url: `/feed-management/project-fields/${companyId}/${projectId}`,
        method: 'DELETE',
        body: {
          project_field_id: id,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'MAPPINGS', id: arg.fileId },
        { type: 'FIELDS', id: `PROJECT-${arg.projectId}` },
      ],
    }),

    getProjectsFields: builder.query({
      query: ({ companyId, projectId }) =>
        `/feed-management/project-fields/${companyId}/${projectId}`,
      providesTags: (result, query, arg) => [
        { type: 'FIELDS', id: `PROJECT-${arg.projectId}` },
      ],
    }),

    createSchedule: builder.mutation({
      query: ({ data }) => ({
        url: '/feed-management/schedule',
        method: 'POST',
        body: data,
      }),
    }),

    getSchedule: builder.query({
      query: ({ companyId, projectId }) => ({
        url: `/feed-management/schedule/${companyId}/${projectId}`,
      }),
    }),

    updateSchedule: builder.mutation({
      query: ({ companyId, projectId, data }) => ({
        url: `/feed-management/schedule/${companyId}/${projectId}`,
        method: 'PUT',
        body: {
          schedule_id: data.schedule_id,
          time_interval: data.time_interval,
          timezone: data.timezone,
          repeat: data.repeat,
          enabled: true,
        },
      }),
    }),
    deleteFieldById: builder.mutation({
      query: ({ companyId, projectId, fileId , fieldId }) => ({
        url: `/feed-management/fields/${companyId}/${projectId}`,
        method: 'DELETE',
        body: {
          field_id: fieldId,
          file_id:fileId
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'MAPPINGS', id: arg.fileId },
        { type: 'FIELDS', id: `PROJECT-${arg.projectId}` },
      ],
    }),
    
  }),
});

const {
  useGetAllProjectsQuery,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectDetailsQuery,
  useGetScheduleQuery,
  useAddProjectFieldMutation,
  useEditProjectFieldMutation,
  useDeleteProjectFieldMutation,
  useGetProjectsFieldsQuery,
  useEditProjectMutation,
  useCreateScheduleMutation,
  useUpdateScheduleMutation,
  useDeleteFieldByIdMutation 
} = feedProjectApi;
export {
  feedProjectApi,
  useGetAllProjectsQuery,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectDetailsQuery,
  useGetScheduleQuery,
  useAddProjectFieldMutation,
  useEditProjectFieldMutation,
  useDeleteProjectFieldMutation,
  useGetProjectsFieldsQuery,
  useEditProjectMutation,
  useCreateScheduleMutation,
  useUpdateScheduleMutation,
  useDeleteFieldByIdMutation 
};
