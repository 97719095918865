// React imports
import * as React from 'react';

// Third Party imports
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

// Styled imports
import {
  CustomSwitchTrack,
  CustomSwitchThumb,
  CustomSwitchInput,
  CustomSwitchRoot,
} from './StyledCustomSwitch';
/* 
icon:  ReactComponent svg / Mui Icon
checkedIcon: ReactComponent svg / Mui Icon
checked: Boolean
handleChange: callback to change the 'checked' state in calling component
*/
const CustomSwitch = ({ Icon, CheckedIcon, checked, handleChange }) => (
  <CustomSwitchRoot>
    <CustomSwitchTrack>
      {!Icon || !CheckedIcon ? (
        <>
          <RadioButtonUncheckedIcon />
          <RadioButtonCheckedIcon />
        </>
      ) : (
        <>
          <Icon />
          <CheckedIcon />
        </>
      )}
    </CustomSwitchTrack>
    <CustomSwitchThumb checked={checked}>
      {!Icon || !CheckedIcon
        ? (() => {
            if (checked) return <RadioButtonCheckedIcon />;
            return <RadioButtonUncheckedIcon />;
          })()
        : (() => {
            if (checked) return <CheckedIcon />;
            return <Icon />;
          })()}
      {}
    </CustomSwitchThumb>
    <CustomSwitchInput
      type='checkbox'
      aria-label='Demo switch'
      checked={checked}
      onChange={handleChange}
    />
  </CustomSwitchRoot>
);

export { CustomSwitch };

