// react imports
import React from 'react';
import { Link, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

// styled imports
import { LinkChild, LinkText } from './styles';

const SidebarLink = ({ text, to, onClick, badge = false, number = null }) => (
  <li>
    <Link
      component={NavLink}
      to={to}
      underline='none'
      color='inherit'
      display='block'
      variant='sidebar'
      onClick={onClick}
    >
      <LinkChild
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: ' space-between',
        }}
      >
        <LinkText fontSize='1rem' fontWeight={500}>
          {text}
        </LinkText>
        {badge && (
          <Typography
            sx={{
              color: '#fff',
              backgroundColor: '#0096FF',
              borderRadius: ' 100%',
              width: '24px',
              height: '24px',
              display: 'grid',
              placeItems: 'center',
            }}
            variant='smallBold'
          >
            {number}
          </Typography>
        )}
      </LinkChild>
    </Link>
  </li>
);

export { SidebarLink };
