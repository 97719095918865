import { Box } from '@mui/material';
import React from 'react';
import { CheckboxInput } from '../../../../../../../components/Inputs/CheckboxInput';

const WrappedCheckbox = ({ name, label, sx }) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: 'border.main',
      padding: 1,
      backgroundColor: 'background.main',
      borderRadius: 1,
      width: 'fit-content',
      display: 'flex',
      alignItems: 'center',
      height: '45px',
      ...sx,
    }}
  >
    <CheckboxInput name={name} label={label} />
  </Box>
);

export { WrappedCheckbox };
