/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { TableChartOutlined } from '@mui/icons-material';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import { Databox } from '../../../../../../components/Databox';
import { Loading } from '../../../../../../components/Databox/Loading';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';
import { PMaxTable } from '../../../../Databoxes/PMax/Table';
import { PMaxChart } from '../../../../Databoxes/PMax/Chart';
import { CustomSwitch } from '../../../../../../components/CustomSwitch/CustomSwitch';
import { Filter } from '../../../../../../components/Filter';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { getDashboardDataService } from '../../../../../../utils/getDashboardDataService';

const DisplayComponentSelector = ({ showTable, chart, table }) => (
  <Box flexGrow={1} minHeight={0}>
    {showTable ? table : chart}
  </Box>
);


const PMaxContainer = ({ accountId, platform, dateRange }) => {
  const { account } = useContext(UserInfoContext);
  const [count, setCount] = useState(0);
  const [filterListForPlatform, setFilterListForPlatform] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState();

  const memoizedArgs = useMemo(
    () => ({
      account_id: accountId,
      date_range: dateRange,
      databox_type: 'pmax_insights',
    }),
    [accountId, dateRange]
  );

  const [state] = useDataFetcher(
    [],
    getDashboardDataService(platform),
    memoizedArgs,
    count
  );

  useEffect(() => {
    const originalData = state.data || [];
    const firstItem =
      originalData && originalData.length > 0 ? originalData[0] : {};

    const MainfilterList = Object.keys(firstItem).map((key) => ({
      name: key,
      value: key,
    }));

    const filteredFilterList =
      MainfilterList &&
      MainfilterList.filter(
        (filter) =>
          filter.value !== 'Campaign Id' &&
          filter.value !== 'Verticals' &&
          filter.value !== 'Campaign Name'
      );

    if (filteredFilterList && filteredFilterList.length > 0) {
      setSelectedFilter(filteredFilterList[0]);
    }

    setFilterListForPlatform(filteredFilterList);
  }, [state.data]);

  const handleFilterChange = (newFilter) => {
    setSelectedFilter(newFilter);
  };

  const handleSwitchChange = () => {
    setShowTable(!showTable);
  };

  return (
    <Databox>
      <DataboxHeading
        title='PMax Campaigns'
        info='Know the performance max data & analyse on which sections to invest more.'
      >
        {state?.data && !showTable && (
          <Filter
            label='Filter'
            selectedFilter={selectedFilter}
            filterList={filterListForPlatform}
            handleFilterChange={handleFilterChange}
          />
        )}
      </DataboxHeading>

      <FetchingDisplay
        isLoading={state.isLoading}
        isError={state.isError}
        LoadingElement={<Loading />}
        ErrorElement={
          <ErrorDisplay
            refresh={() => {
              setCount(count + 1);
            }}
            message={
              state.errorInfo?.code === 204
                ? 'No data available for selected date range.'
                : `Couldn't load data`
            }
            showRefreshButton={!(state.errorInfo?.code === 204)}
          />
        }
        SuccessElement={
          <DisplayComponentSelector
            showTable={showTable}
            chart={
              <Box p={2} height='100%'>
                <PMaxChart
                  data={state.data}
                  xTitle='Campaigns'
                  yTitle={selectedFilter?.name}
                  currencyCode={account.currency_code}
                  selectedFilter={selectedFilter}
                />
              </Box>
            }
            table={
              <PMaxTable
                data={state.data}
                currencyCode={account.currency_code}
              />
            }
          />
        }
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', padding: 2 }}>
        <CustomSwitch
          checked={showTable}
          handleChange={handleSwitchChange}
          CheckedIcon={TableChartOutlined}
          Icon={InsertChartOutlinedTwoToneIcon}
        />
      </Box>
    </Databox>
  );
};

export { PMaxContainer };
