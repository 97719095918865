import React, { useState } from 'react';
import { Box, Fab, Typography, Card } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CreateCompanyModal } from '../../../../Components/Modals/CreateCompany';

const ConnectCompanyButton = ({ reloadFunction }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <Card
      sx={{
        height: '100%',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '180px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Fab
          color='primary'
          aria-label='add'
          size='large'
          disableRipple
          onClick={handleOpen}
          sx={{ zIndex: 110 }}
        >
          <AddIcon fontSize='large' />
        </Fab>
        <Typography sx={{ display: 'block' }} variant='h5'>
          Connect New Company
        </Typography>
      </Box>
      <CreateCompanyModal
        open={open}
        handleCloseModal={handleCloseModal}
        reloadFunction={reloadFunction}
      />
    </Card>
  );
};
export { ConnectCompanyButton };
