import React from 'react';

import { Box } from '@mui/system';
import { Button, Grid, Stack, Typography } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

// eslint-disable-next-line import/no-unresolved
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { StyledDiv, StyledPaper, StyledBox } from './StyledDoughnutGraph';
import { ExportExcel } from '../../../../../widgets/ExportToExcel/ExportExcel';

ChartJS.register(ArcElement, Tooltip, Legend);
let percentage = 0;

const columns = [
  'Product making cost',
  'Marketing Cost',
  'Packing Cost',
  'Tax',
  'Shipping',
  'Other Costs',
  'POAS',
  'Profit Percentage',
];
const sheetNames = ['Poas Data'];

const POASDoughnutGraph = ({ demoCurrency, inputValue, doughnutData }) => (
  <StyledBox>
    <Doughnut
      className='doughnutGraph'
      data={doughnutData}
      options={{
        responsive: true,
        borderWidth: 0,
        plugins: {
          tooltip: {
            enabled: false,
          },
          datalabels: {
            formatter: (value, ctx) => {
              const { datasets } = ctx.chart.data;
              if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                const sum = datasets[0].data.reduce((a, b) => a + b, 0);
                percentage = `${Math.round((value / sum) * 100)}%`;
                return percentage;
              }
              return percentage;
            },
            align: 'center',
            color: '#fff',
            font: {
              size: '14px',
              family: "'Montserrat', sans-serif ",
              weight: '500',
            },
          },

          legend: {
            display: false,
          },
        },
      }}
      plugins={[ChartDataLabels]}
    />
    <Box
      display='flex'
      justifyContent='center'
      flexDirection='column'
      className='centerText'
    >
      <Typography variant='h4'>Revenue</Typography>
      <Typography variant='h4'>
        {demoCurrency}{' '}
        {inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </Typography>
    </Box>
  </StyledBox>
);

const DoughnutGraphIndicators = ({
  profitPercentage,
  productMakingCost,
  marketingCost,
  tax,
  shippingCost,
  packingCost,
  otherCosts,
}) => (
  <Stack direction='column'>
    <IndicatorComponent
      IndicatorColor='#0096FF'
      PercentValue={profitPercentage}
      IndicatorName='Profit'
    />
    <IndicatorComponent
      IndicatorColor='#323C46'
      PercentValue={productMakingCost}
      IndicatorName='Product making cost'
    />
    <IndicatorComponent
      IndicatorColor='#505961'
      PercentValue={marketingCost}
      IndicatorName='Marketing cost'
    />
    <IndicatorComponent
      IndicatorColor='#6F767D'
      PercentValue={tax}
      IndicatorName='Tax'
    />

    <IndicatorComponent
      IndicatorColor='#8E9499'
      PercentValue={shippingCost}
      IndicatorName='Shipping cost'
    />
    <IndicatorComponent
      IndicatorColor='#ADB1B5'
      PercentValue={packingCost}
      IndicatorName='Packing cost'
    />
    <IndicatorComponent
      IndicatorColor='#D6D8DA'
      PercentValue={otherCosts}
      IndicatorName='Other cost'
    />
  </Stack>
);

const IndicatorComponent = ({
  IndicatorColor,
  PercentValue,
  IndicatorName,
}) => (
  <Stack
    direction='row'
    display='flex'
    justifyContent='flex-start'
    alignItems='center'
  >
    <StyledDiv style={{ backgroundColor: IndicatorColor }} />
    <Typography variant='p'>
      {PercentValue}% {IndicatorName}
    </Typography>
  </Stack>
);

const DoughnutGraph = ({
  poas,
  profitPercentage,
  demoCurrency,
  inputValue,
  setEditButtonState,
  productMakingCost,
  marketingCost,
  tax,
  shippingCost,
  packingCost,
  otherCosts,
  doughnutData,
  profit,
  poasSheetData,
}) => (
  <StyledPaper sx={{ borderRadius: '.8rem', mb: 4 }}>
    <Box sx={{ p: 3 }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          display='flex'
          flexDirection='column'
          justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start' }}
          alignItems='flex-start'
        >
          <Typography className='doughnutResult'>POAS is {poas}</Typography>
          <Typography variant='smallMedium' display='flex' alignItems='center'>
            Profit percentage is
            <Typography variant='h5' sx={{ marginLeft: '6px' }}>
              {profitPercentage} %
            </Typography>
          </Typography>
          <Typography variant='smallMedium' display='flex' alignItems='center'>
            Total profit is
            <Typography variant='h5' sx={{ marginLeft: '6px' }}>
              {demoCurrency}{' '}
              {profit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Typography>
          </Typography>
          {/* <Typography variant='p' display='flex' alignItems='center'>
            Total spend is
            <Typography variant='h5' sx={{ marginLeft: '6px' }}>
              INR 77,000
            </Typography>
          </Typography> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{ padding: '16px' }}
          display='flex'
          justifyContent={{ xs: 'center', sm: 'center', md: 'center' }}
          alignItems='center'
        >
          <POASDoughnutGraph
            inputValue={inputValue}
            demoCurrency={demoCurrency}
            doughnutData={doughnutData}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          display='flex'
          justifyContent={{ xs: 'center', sm: 'center', md: 'center' }}
          alignItems='center'
        >
          <DoughnutGraphIndicators
            productMakingCost={productMakingCost}
            marketingCost={marketingCost}
            tax={tax}
            shippingCost={shippingCost}
            packingCost={packingCost}
            otherCosts={otherCosts}
            profitPercentage={profitPercentage}
          />
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          className='downloadEditButton'
        >
          <ExportExcel
            filename='Poas Report'
            sheetNames={sheetNames}
            columnList={columns}
            dataToWrite={poasSheetData}
            title='Poas Insights Report'
          />
          <Button
            variant='contained'
            sx={{ width: 'auto' }}
            onClick={() => {
              setEditButtonState(false);
            }}
          >
            Edit
            <ModeEditIcon
              sx={{
                ml: 1,
                fontSize: '1rem',
              }}
            />
          </Button>
        </Grid>
      </Grid>
    </Box>
  </StyledPaper>
);

export { DoughnutGraph };
