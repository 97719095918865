import React from 'react';
import ReactJoyride, { EVENTS, STATUS, ACTIONS } from 'react-joyride';

const steps = [
  {
    title: 'Pick an ad account and date range',
    content:
      'Fetch analytical data straight from your Google or Meta ad account for a specific date range.',
    target: '.accountDate',
    placement: 'auto',
    disableBeacon: true,
    disableScrolling: false,
  },
  {
    title: 'Filter your top products',
    content:
      'Understand data with ease by filtering your products based on their popularity, revenue, stock availability, etc.',
    target: '.filterGuide',
    placement: 'auto',
    disableBeacon: true,
    disableScrolling: false,
  },
  {
    title: 'Click the Start for Free button on the right to create a free account',
    content:
      'If you need an AdHelp.io expert to assist you, write to us at support@adhelp.io',
    target: '.startNow',
    placement: 'auto',
    disableBeacon: true,
    disableScrolling: false,
  },
];

export const Joyride = (props) => {
  const { setRun, run, stepIndexState, setStepIndexState } = props;

  const handleJoyrideCallback = (data) => {
    const { status, type, action } = data;

    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED, ACTIONS.CLOSE];

    if (finishedStatuses.includes(status) || action === 'close') {
      setRun(false);
    } else if (
      data.index === stepIndexState &&
      (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND)
    ) {
      setStepIndexState((prev) => prev + (action === ACTIONS.PREV ? -1 : 1));
    }
  };

  return (
    <ReactJoyride
      run={run}
      steps={steps}
      stepIndex={stepIndexState}
      disableBeacon
      scrollHeight={false}
      continuous
      scrollOffset={80}
      scrollToFirstStep
      spotlightClicks={false}
      showProgress
      callback={handleJoyrideCallback}
      styles={{
        options: {
          zIndex: 10000000,
          primaryColor: '#0096ff',
        },
        tooltipContainer: {
          textAlign: 'left',
          paddingTop: '15px',
        },
        tooltipContent: {
          paddingLeft: '0px',
        },
      }}
      locale={{
        last: 'Done',
      }}
    />
  );
};
