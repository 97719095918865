/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const repeat = [
  {
    title: 'Every 24 hours',
    value: '24'
  },
  {
    title: 'Every 12 hours',
    value: '12'
  },
  {
    title: 'Every 6 hours',
    value: '6',
  },
  {
    title: 'Every 3 hours',
    value: '3',
  },
  {
    title: 'Every hour',
    value: '1',
  },
];

const startTime = [
  {
    title: '00:00-00:15',
    value: '00:00-00:15'
  },
  {
    title: '00:15-00:30',
    value: '00:15-00:30'
  },
  {
    title: '00:45-01:00',
    value: '00:45-01:00'
  },
  {
    title: '01:15-01:30',
    value: '01:15-01:30'
  },
  {
    title: '01:30-01:45',
    value: '01:30-01:45'
  },
  {
    title: '01:45-02:00',
    value: '01:45-02:00'
  },
  {
    title: '02:00-02:15',
    value: '02:00-02:15 '
  },
  {
    title: '02:15-02:30',
    value: '02:15-02:30'
  },
  {
    title: '02:30-02:45',
    value: '02:30-02:45'
  },
  {
    title: '02:45-03:00',
    value: '02:45-03:00'
  },
  {
    title: '03:00-03:15',
    value: '03:00-03:15 '
  },
  {
    title: '03:15-03:30',
    value: '03:15-03:30'
  },
  {
    title: '03:30-03:45',
    value: '03:30-03:45'
  },
  {
    title: '03:45-04:00',
    value: '03:45-04:00'
  },
  {
    title: '04:00-04:15',
    value: '04:00-04:15 '
  },
  {
    title: '04:15-04:30',
    value: '04:15-04:30'
  },
  {
    title: '04:30-04:45',
    value: '04:30-04:45'
  },
  {
    title: '04:45-05:00',
    value: '04:45-05:00'
  },
  {
    title: '05:00-05:15',
    value: '05:00-05:15 '
  },
  {
    title: '05:15-05:30',
    value: '05:15-05:30'
  },
  {
    title: '05:30-05:45',
    value: '05:30-05:45'
  },
  {
    title: '05:45-06:00',
    value: '05:45-06:00'
  },
  {
    title: '06:00-06:15',
    value: '06:00-06:15 '
  },
  {
    title: '06:15-06:30',
    value: '06:15-06:30'
  },
  {
    title: '06:30-06:45',
    value: '06:30-06:45'
  },
  {
    title: '06:45-07:00',
    value: '06:45-07:00'
  },
  {
    title: '07:00-07:15',
    value: '07:00-07:15'
  },
  {
    title: '07:15-07:30',
    value: '07:15-07:30'
  },
  {
    title: '07:30-07:45',
    value: '07:30-07:45'
  },
  {
    title: '07:45-08:00',
    value: '07:45-08:00'
  },
  {
    title: '08:00-08:15',
    value: '08:00-08:15 '
  },
  {
    title: '08:00-08:15',
    value: '08:00-08:15'
  },
  {
    title: '08:30-08:45',
    value: '08:30-08:45'
  },
  {
    title: '08:45-09:00',
    value: '08:45-09:00'
  },
  {
    title: '09:00-09:15',
    value: '09:00-09:15'
  },
  {
    title: '09:15-09:30',
    value: '09:15-09:30'
  },
  {
    title: '09:30-09:45',
    value: '09:30-09:45'
  },
  {
    title: '09:45-10:00',
    value: '09:45-10:00'
  },
  {
    title: '10:00-10:15',
    value: '10:00-10:15'
  },
  {
    title: '10:15-10:30',
    value: '10:15-10:30'
  },
  {
    title: '10:30-10:45',
    value: '10:30-10:45'
  },
  {
    title: '10:45-11:00',
    value: '10:45-11:00'
  },
  {
    title: '11:00-11:15',
    value: '11:00-11:15'
  },
  {
    title: '11:15-11:30',
    value: '11:15-11:30'
  },
  {
    title: '11:30-11:45',
    value: '11:30-11:45'
  },
  {
    title: '11:45-12:00',
    value: '11:45-12:00'
  },
  {
    title: '12:00-12:15',
    value: '12:00-12:15'
  },
  {
    title: '12:15-12:30',
    value: '12:15-12:30'
  },
  {
    title: '12:30-12:45',
    value: '12:30-12:45'
  },
  {
    title: '12:45-13:00',
    value: '12:45-13:00'
  },

  {
    title: '13:00-13:15',
    value: '13:00-13:15'
  },
  {
    title: '13:15-13:30',
    value: '13:15-13:30'
  },
  {
    title: '13:30-13:45',
    value: '13:30-13:45'
  },
  {
    title: '13:45-14:00',
    value: '13:45-14:00'
  },
  {
    title: '14:00-14:15',
    value: '14:00-14:15'
  },
  {
    title: '14:15-14:30',
    value: '14:15-14:30'
  },
  {
    title: '14:30-14:45',
    value: '14:30-14:45'
  },
  {
    title: '14:45-15:00',
    value: '14:45-15:00'
  },
  {
    title: '15:00-15:15',
    value: '15:00-15:15'
  },
  {
    title: '15:15-15:30',
    value: '15:15-15:30'
  },
  {
    title: '15:30-15:45',
    value: '15:30-15:45'
  },
  {
    title: '15:45-16:00',
    value: '15:45-16:00'
  },
  {
    title: '16:00-16:15',
    value: '16:00-16:15 '
  },
  {
    title: '16:15-16:30',
    value: '16:15-16:30'
  },
  {
    title: '16:30-16:45',
    value: '16:30-16:45'
  },
  {
    title: '16:45-17:00',
    value: '16:45-17:00'
  },
  {
    title: '17:00-17:15',
    value: '17:00-17:15'
  },
  {
    title: '17:15-17:30',
    value: '17:15-17:30'
  },
  {
    title: '17:30-17:45',
    value: '17:30-17:45'
  },
  {
    title: '17:45-18:00',
    value: '17:45-18:00'
  },
  {
    title: '18:00 - 18:15',
    value: '18:00 - 18:15'
  },
  {
    title: '18:15 - 18:30',
    value: '18:15 - 18:30'
  },
  {
    title: '18:30 - 18:45',
    value: '18:30 - 18:45'
  },
  {
    title: '18:45 - 19:00',
    value: '18:45 - 19:00'
  },
  {
    title: '19:00 - 19:15',
    value: '19:00 - 19:15'
  },
  {
    title: '19:15 - 19:30',
    value: '19:15 - 19:30'
  },
  {
    title: '19:30 - 19:45',
    value: '19:30 - 19:45'
  },
  {
    title: '19:45 - 20:00',
    value: '19:45 - 20:00'
  },
  {
    title: '20:00 - 20:15',
    value: '20:00 - 20:15'
  },
  {
    title: '20:15 - 20:30',
    value: '20:15 - 20:30'
  },
  {
    title: '20:30 - 20:45',
    value: '20:30 - 20:45'
  },
  {
    title: '20:45 - 21:00',
    value: '20:45 - 21:00'
  },
  {
    title: '21:00-21:15',
    value: '21:00-21:15 '
  },
  {
    title: '21:15-21:30',
    value: '21:15-21:30'
  },
  {
    title: '21:30-21:45',
    value: '21:30-21:45'
  },
  {
    title: '21:45-21:00',
    value: '21:45-22:00'
  },
  {
    title: '22:00-22:15',
    value: '22:00-22:15 '
  },
  {
    title: '22:15-22:30',
    value: '22:15-22:30'
  },
  {
    title: '22:30-22:45',
    value: '22:30-22:45'
  },
  {
    title: '22:45-22:00',
    value: '22:45-23:00'
  },
  {
    title: '23:00-23:15',
    value: '23:00-23:15 '
  },
  {
    title: '23:15-23:30',
    value: '23:15-23:30'
  },
  {
    title: '23:30-23:45',
    value: '23:30-23:45'
  },
  {
    title: '23:45-23:00',
    value: '23:45-00:00'
  },
];


const initialState = {
  timezone: [],
  selectOnly: repeat[0].value,
  secondSelect: startTime[0].value,
}

const scheduleFormRedux = createSlice({
  name: 'scheduleFormRedux',
  initialState,
  reducers: {
    updateVal: (state, { payload: { key, val } }) => {
      // eslint-disable-next-line no-param-reassign
      state[key] = val;
    },
    clearInputValue: (state) => {
      // eslint-disable-next-line no-param-reassign, no-unused-vars
      state.timezone = initialState.timezone
    },

  },

});


// eslint-disable-next-line import/group-exports
export const { updateVal, clearInputValue } = scheduleFormRedux.actions;

// eslint-disable-next-line import/no-default-export
export default scheduleFormRedux.reducer;
