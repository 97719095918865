import React, { useState } from 'react';
import { Typography, CardMedia, Box, Link, Button } from '@mui/material';
import { StyledBox, StyledTextField } from './StyledPlatformComponents';

const CopyText = () => {
  const [text, setText] = useState('support@adhelp.io');
  const [copyButton, setCopyButton] = useState('Copy');
  const [copyButtonColor, setCopyButtonColor] = useState('#0096FF');

  const inputHandler = (event) => {
    setText(event.target.value);
  };

  const copy = async () => {
    await navigator.clipboard.writeText(text);
    setCopyButton('Copied');
    setCopyButtonColor('#1DB949');
  };

  return (
    <StyledBox>
      <StyledTextField
        type='text'
        variant='standard'
        value={text}
        onChange={inputHandler}
      />
      <Button
        onClick={copy}
        disabled={!text}
        variant='contained'
        sx={{
          backgroundColor: copyButtonColor,
          width: '100px',
          height: '100%',
          borderRadius: '0.2rem',
          border: `1px solid ${copyButtonColor}`,
          '&:hover': {
            background: `${copyButtonColor} !important`,
            border: `1px solid ${copyButtonColor}`,
          },
        }}
      >
        <Typography color='#fff' variant='smallRegular'>
          {copyButton}
        </Typography>
      </Button>
    </StyledBox>
  );
};

const VideoCard = ({ src }) => (
  <Box sx={{ width: '100%', borderRadius: '4px', my: 2 }}>
    <CardMedia
      component='video'
      src={src}
      controls
      sx={{ width: '100%', border: 'none', borderRadius: '4px' }}
    />
  </Box>
);

const LinkComponent = ({ text, to }) => (
  <Link underline='hover' href={to} sx={{ px: 0.5 }} target='_blank'>
    <Typography variant='smallRegular' textAlign='center'>
      {text}
    </Typography>
  </Link>
);

export { CopyText, VideoCard, LinkComponent };
