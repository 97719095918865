import React, { useState } from 'react';
import { Fade, Button, Box } from '@mui/material';

import { Form, Formik } from 'formik';
import { FormikField } from '../../../../../../Forms/Form/Formik';
import { HeadingWithUnderline } from '../../../../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { MccIdFormSchema } from '../../../../../Components/Forms/Schema';
import { StyledGlobalModal, StyledModal } from './StyledPlatformComponents';
import { CloseButton } from '../../../../../../../widgets/CloseButton';
import { VideoModal } from '../../../../../../../widgets/VideoModal';

const MccIdForm = () => {
  const initialValues = {
    mcc_id: '',
  };
  const handleOnSubmit = (formikHelpers) => {
    formikHelpers.resetForm();
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // child modal
  const [openVideo, setOpenVideo] = useState(false);
  const handleOpenVideo = () => {
    setOpenVideo(true);
  };
  const handleCloseVideo = () => {
    setOpenVideo(false);
  };

  return (
    <Box>
      <Button onClick={handleOpen}>Open modal</Button>
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <Box>
          <Fade in={open}>
            <StyledGlobalModal>
              <HeadingWithUnderline
                heading='As you have Google Manager account, we require MCC ID to link
                  your accounts'
              />
              <Box>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleOnSubmit}
                  validationSchema={MccIdFormSchema}
                >
                  {({
                    values,
                    dirty,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                  }) => (
                    <Form>
                      <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
                        <FormikField
                          name='mcc_id'
                          label='MCC ID'
                          required
                          type='text'
                          placeholder='000-000-0000'
                          value={values.mcc_id}
                          error={Boolean(errors.mcc_id) && touched.mcc_id}
                          helperText={touched.mcc_id && errors.mcc_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>
                      <VideoModal
                        openVideo={openVideo}
                        handleOpen={handleOpenVideo}
                        handleClose={handleCloseVideo}
                      />
                      <Button
                        variant='contained'
                        type='submit'
                        disabled={!dirty}
                        fullWidth
                      >
                        Send
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Box>
              <CloseButton onClick={handleClose} />
            </StyledGlobalModal>
          </Fade>
        </Box>
      </StyledModal>
    </Box>
  );
};

export { MccIdForm };
