// react imports
import React, { useMemo } from 'react';

// third party imports
import { Button } from '@mui/material';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';

import { useDataFetcher } from '../../Hooks/useDataFetcher';
import { getDemoDashboardService } from '../../services/DemoDashboard';
import { generateExcel } from './GenerateExcel';

const DownloadExcelButton = ({ platform = 'google', currencyCode = 'ZZZ' }) => {
  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'ALL',
    }),
    [platform]
  );

  const [state] = useDataFetcher(
    [],
    getDemoDashboardService(platform),
    memoizedArgs
  );
  if (state.isLoading) {
    return (
      <Button
        variant='pill'
        startIcon={<DownloadForOfflineOutlinedIcon color='primary' />}
      >
        Loading
      </Button>
    );
  }
  return (
    <Button
      variant='pill'
      startIcon={<DownloadForOfflineOutlinedIcon color='primary' />}
      onClick={() => {
        generateExcel(state.data, currencyCode, platform);
      }}
      fullWidth
    >
      Download
    </Button>
  );
};

export { DownloadExcelButton };
