import React, { useContext, useEffect, useState } from 'react';

// mui imports
// CircularProgress
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { UserByAgeContainer } from './Containers/UserByAgeContainer';
import { UserByGenderContainer } from './Containers/UserByGenderContainer';
import { UserByDeviceContainer } from './Containers/UserByDeviceContainer';
import { AdInsightsContainer } from './Containers/AdInsightsContainer';
import { QuickStatsContainer } from './Containers/QuickStatsContainer';
import { ConversionFunnelContainer } from './Containers/ConversionFunnelContainer';
import { MonthlyInsightsContainer } from './Containers/MonthlyInsightsContainer';
import { YearlyInsightsContainer } from './Containers/YearlyInsightsContainer';
import { CampaignSummaryContainer } from './Containers/CampaignSummaryContainer';
import { AdInsightsMetaContainer } from './Containers/AdInsightsMetaContainer';
import { ShoppingProductsInsightsContainer } from './Containers/ShoppingProductsInsightsContainer';
import { UserByLocationContainer } from './Containers/UserByLocationContainer';
import { PMaxContainer } from './Containers/PMaxContainer'

import { ResizableGrid } from '../../../../components/ResizableGrid';

import { TopProductsContainer } from './Containers/TopProductsContainer';
import { DashboardToolbar } from './Toolbar';
import { ElementPicker } from '../../../../components/ElementPicker';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { UserInfoDispatchContext } from '../../../../context/UserInfoDispatchContext';
import { setAccount } from '../../../../utils/userInfoDispatchFunctions';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import {
  StyledBox,
  StyledBoxSec,
  StyledLinkYourAccount,
} from '../../../ProjectManagement/Pages/AccountList/components/LinkAccountModal/styles';
import { CompanyBell } from '../../../../widgets/ImportantPopups/Popups';

const GoogleDashboard = ({ dateRange, accountId }) => (
  <>
    <Box
      sx={{
        my: { xs: 2, sx: 2, md: 2, lg: 2, xl: 3 },
        mx: { xs: 2, sx: 2, md: 2, lg: 3.5, xl: 4 },
      }}
    >
      <QuickStatsContainer
        dateRange={dateRange}
        accountId={accountId}
        platform='google'
      />
    </Box>
    <ResizableGrid platform='google'>
      {/* you can either use 'div's as wrappers or call use React.forwarRef on components for react-grid-layout to work with them */}
      <div key='usrByAg'>
        <UserByAgeContainer
          platform='google'
          dateRange={dateRange}
          accountId={accountId}
        />
      </div>
      <div key='usrByGndr'>
        <UserByGenderContainer
          platform='google'
          dateRange={dateRange}
          accountId={accountId}
        />
      </div>
      <div key='usrByDvc'>
        <UserByDeviceContainer
          platform='google'
          dateRange={dateRange}
          accountId={accountId}
        />
      </div>
      <div key='perfrmnceMax'>
        <PMaxContainer
          platform='google'
          dateRange={dateRange}
          accountId={accountId}
        />
      </div>
      <div key='adInsghts'>
        <AdInsightsContainer
          dateRange={dateRange}
          accountId={accountId}
          platform='google'
        />
      </div>
      {/* <div key='qckStt'> */}
      {/* <QuickStatsContainer
        dateRange={dateRange}
        accountId={accountId}
        platform='google'
      /> */}
      {/* </div> */}
      <div key='mnthlyInsghts'>
        <MonthlyInsightsContainer
          platform='google'
          dateRange={dateRange}
          accountId={accountId}
        />
      </div>
      <div key='yrlyInsghts'>
        <YearlyInsightsContainer accountId={accountId} platform='google' />
      </div>

      {/* <div key='avgMrktngCst'>
      <AverageMarketingCostContainer
        accountId={accountId}
        platform='google'
        dateRange={dateRange}
      /> 
    </div> */}
      <div key='shppngPrdctsInsghts'>
        <ShoppingProductsInsightsContainer
          accountId={accountId}
          dateRange={dateRange}
        />
      </div>
      <div key='tpPrd'>
        <TopProductsContainer
          platform='google'
          accountId={accountId}
          dateRange={dateRange}
        />
      </div>
      <div key='lctn'>
        <UserByLocationContainer
          platform='google'
          accountId={accountId}
          dateRange={dateRange}
        />
      </div>
    </ResizableGrid>
  </>
);

const MetaDashboard = ({ dateRange, accountId }) => (
  <>
    <Box
      sx={{
        my: { xs: 2, sx: 2, md: 2, lg: 2, xl: 3 },
        mx: { xs: 2, sx: 2, md: 2, lg: 3.5, xl: 4 },
      }}
    >
      <QuickStatsContainer
        dateRange={dateRange}
        accountId={accountId}
        platform='meta'
      />
    </Box>
    <ResizableGrid platform='meta'>
      {/* you can either use 'div's as wrappers or call use React.forwarRef on components for react-grid-layout to work with them */}
      <div key='usrByAg'>
        <UserByAgeContainer
          platform='meta'
          dateRange={dateRange}
          accountId={accountId}
        />
      </div>
      <div key='usrByGndr'>
        <UserByGenderContainer
          platform='meta'
          dateRange={dateRange}
          accountId={accountId}
        />
      </div>
      <div key='usrByDvc'>
        <UserByDeviceContainer
          platform='meta'
          dateRange={dateRange}
          accountId={accountId}
        />
      </div>
      {/* <div key='qckStt'>
        <QuickStatsContainer
          dateRange={dateRange}
          accountId={accountId}
          platform='meta'
        />
      </div> */}
      <div key='cnvrsnFnnl'>
        <ConversionFunnelContainer
          dateRange={dateRange}
          accountId={accountId}
        />
      </div>
      <div key='cmpgnSmmry'>
        <CampaignSummaryContainer dateRange={dateRange} accountId={accountId} />
      </div>
      <div key='mnthlyInsghts'>
        <MonthlyInsightsContainer
          platform='meta'
          dateRange={dateRange}
          accountId={accountId}
        />
      </div>
      <div key='yrlyInsghts'>
        <YearlyInsightsContainer accountId={accountId} platform='meta' />
      </div>
      <div key='adInsghtsMt'>
        <AdInsightsMetaContainer accountId={accountId} dateRange={dateRange} />
      </div>
      <div key='lctn'>
        <UserByLocationContainer
          accountId={accountId}
          dateRange={dateRange}
          platform='meta'
        />
      </div>
    </ResizableGrid>
  </>
);

const PlatformDisplay = ({ accountId, platform, dateRange }) => {
  if (
    platform.toLowerCase().includes('meta') ||
    platform.toLowerCase().includes('face')
  ) {
    return (
      <MetaDashboard
        accountId={accountId}
        dateRange={{
          start_date: dateRange.startDate,
          end_date: dateRange.endDate,
        }}
      />
    );
  }

  return (
    <GoogleDashboard
      accountId={accountId}
      dateRange={{
        start_date: dateRange.startDate,
        end_date: dateRange.endDate,
      }}
    />
  );
};

const Dashboard = () => {
  const [fetchedStatus, setFetchedStatus] = useState(false);
  const { accountList, account, company, user, accountListFetchingStatus } =
    useContext(UserInfoContext);
  const dispatch = useContext(UserInfoDispatchContext);
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      // If the account list is still empty after the timeout, update fetchedStatus
      if (
        accountList.length === 0 &&
        accountListFetchingStatus === 'FETCHING'
      ) {
        setFetchedStatus(true);
      }
    }, 10000);
    setFetchedStatus(false);
    return () => clearTimeout(timer);
  }, [accountList]);

  useEffect(() => {
    // set account if location state is passed from previous route
    if (state?.account) {
      setAccount(dispatch, state.account);

      // reset state to prevent setting account after page REFRESH
      navigate('', { replace: true, state: { ...state, account: null } });
    }
  }, [state, setAccount]);

  const redirectToAccounts = () => {
    navigate('/accounts', { state: { fromDashboard: true } });
  };

  // main contest here
  const contextRenderFunction = () => {
    // until the company data is not available
    if (accountListFetchingStatus === 'FETCHING' && !fetchedStatus && !accountList) {
      return (
        <StyledBox>
          <StyledBoxSec>
            <CircularProgress />
          </StyledBoxSec>
        </StyledBox>
      );
    }
    if (accountList?.length === 0 && fetchedStatus) {
      return (
        <StyledBox>
          <StyledBoxSec>
            <StyledLinkYourAccount>
              <HeadingWithUnderline heading='Link your accounts' />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                <Typography variant='smallMedium'>
                  Link your accounts to track their performance and create ads
                </Typography>
                <Box sx={{ mt: 2, mb: 0.5 }}>
                  <Button
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 2,
                      '&:hover': { background: 'transparent !important' },
                    }}
                    onClick={redirectToAccounts}
                  >
                    <AddIcon
                      fontSize='large'
                      sx={{
                        backgroundColor: '#0096ff',
                        borderRadius: '50%',
                        width: '56px',
                        height: '56px',
                        padding: '10px',
                        color: '#ffffff !important',
                      }}
                    />
                    <Typography sx={{ display: 'block' }} variant='h5'>
                      Connect New Account
                    </Typography>
                  </Button>
                </Box>
                <Typography
                  variant='smallMedium'
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  Click on <AddIcon fontSize='small' /> to link your platforms
                </Typography>
              </Box>
            </StyledLinkYourAccount>
          </StyledBoxSec>
        </StyledBox>
      );
    }
    return (
      <ElementPicker
        case1={!company}
        element1={
          <Box
            sx={{
              height: 'calc(100vh - 264px)',
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <CompanyBell
              heading='No Company is selected'
              primaryText='Select a Company and Account'
              secText='at the top of the page'
            />
          </Box>
        }
        case2={!account}
        element2={
          <Box
            sx={{
              height: 'calc(100vh - 264px)',
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <CompanyBell
              heading='No Account is Selected'
              primaryText='Select an Account'
              secText='at the top right corner'
            />
          </Box>
        }
        case3={Boolean(company && account)}
        element3={
          <PlatformDisplay
            accountId={account?.id}
            platform={account?.platform}
            dateRange={user.selected_dashboard_date}
          />
        }
      />
    );
  };

  return (
    <>
      <DashboardToolbar />
      {contextRenderFunction()}
    </>
  );
};

export { Dashboard };