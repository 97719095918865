/* eslint-disable camelcase */
// /* eslint-disable no-nested-ternary */

import React, { useEffect, useRef, useState, useContext } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { EditProfileForm } from '../Form/EditProfileForm';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { Databox } from '../../../components/Databox';
import { ErrorDisplay } from '../../../components/ErrorDisplay';
import { Nav } from '../../../components/Navigation';
import { fetchUserInfo } from '../../../services/auth';
import { UserInfoDispatchContext } from '../../../context/UserInfoDispatchContext';
import { setUser } from '../../../utils/userInfoDispatchFunctions';
import {
  useGetProfileQuery,
  useEditProfileMutation,
} from '../../../api/RTK/services/user';

const EditProfilePage = () => {
  const formikRef = useRef();
  const dispatch = useContext(UserInfoDispatchContext);
  const { data, isLoading, isError, error, refetch } = useGetProfileQuery();
  const [
    editProfile,
    { isLoading: editLoading, isError: editIsError, error: editError },
  ] = useEditProfileMutation();

  const [formData, setFormData] = useState(data || {});

  const onSubmit = (values) => {
    editProfile({
      full_name: values.full_name.trim(),
      phone_number: values.phone_number,
      username: values.username,
    })
      .unwrap()
      .then(() => {
        refetch();
      })
      .then(() => fetchUserInfo())
      .then((userInfo) => {
        setUser(dispatch, userInfo);
      })
      .catch(() => {});
  };
  useEffect(() => {
    if (!isLoading && data) {
      setFormData(data);
    }
  }, [data, isLoading]);

  return (
    <>
      <Nav />
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{ minHeight: '100vh' }}
      >
        <Databox
          sx={{
            maxWidth: '600px',
            minWidth: '500px',
            p: 4,
            m: 2,
          }}
        >
          <FetchingDisplay
            isError={isError || editIsError}
            isLoading={isLoading || editLoading}
            LoadingElement={
              <Box sx={{ display: 'grid', placeItems: 'center' }}>
                <CircularProgress />
              </Box>
            }
            ErrorElement={
              <ErrorDisplay
                message={
                  error?.data?.error?.detail || editError?.data?.error?.detail
                }
              />
            }
            SuccessElement={
              <EditProfileForm
                formData={formData}
                handleSubmit={onSubmit}
                formikRef={formikRef}
              />
            }
          />
        </Databox>
      </Box>
    </>
  );
};

export { EditProfilePage };
