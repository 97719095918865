/* eslint-disable no-prototype-builtins */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */

/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect } from 'react';
import { Chart } from 'chart.js';
import { Typography } from '@mui/material';
import displaycheckbox from '../../../../../assests/images/Other/displaycheckbox.png';
import shopppingcheckbox from '../../../../../assests/images/Other/shopppingcheckbox.png';
import searchCheckbox from '../../../../../assests/images/Other/searchCheckbox.png';
import videocheckbox from '../../../../../assests/images/Other/videocheckbx.png';
import blankcheckbox from '../../../../../assests/images/Other/blankcheckbox.png';

const PMaxChart = ({ data, yTitle, xTitle, selectedFilter, currencyCode }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const displayCheckboxYes = new Image(20, 20);
  const shopppingcheckboxYes = new Image(20, 20);
  const videoCheckboxYes = new Image(20, 20);
  const searchCheckboxYes = new Image(20, 20);
  const checkboxNo = new Image(20, 20);
  displayCheckboxYes.src = displaycheckbox;
  shopppingcheckboxYes.src = shopppingcheckbox;
  videoCheckboxYes.src = videocheckbox;
  searchCheckboxYes.src = searchCheckbox;
  checkboxNo.src = blankcheckbox;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (data && data.length > 0 && selectedFilter) {
      if (chartInstance.current) {
        // If chart instance already exists, destroy it
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');

      const filteredData = data.filter(
        (campaign) => campaign['Campaign Name'] !== 'Total'
      );
      const campaignNames = filteredData.map(
        (campaign) => campaign['Campaign Name']
      );

      const compareArrays = (array1, array2) => {
        if (array1.length !== array2.length) {
          return false;
        }
        return array1.every((element, index) => element === array2[index]);
      };

      // dynamically created legends
      const findFirstMatchingArray = (arrays) => {
        if (arrays.length === 0) {
          return [];
        }
        const firstArray = arrays[0];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < arrays.length - 1; index++) {
          if (!compareArrays(arrays[index], firstArray)) {
            // if campaign have differrent values for the verticals  we will create default legends
            return ['Shopping', 'Video', 'Display', 'Search'];
          }
        }
        // if all the campaign have same  values for the verticals  we will create legends for the values we have

        return firstArray;
      };

      const newVarticalarray = data.map((el) => {
        if (el.Verticals) {
          return el.Verticals.map((ver) => ver['Vertical Name']);
        }
        return [];
      });

      const verticalNames = findFirstMatchingArray(newVarticalarray);

      const datasets = verticalNames.map((verticalName) => ({
        label: verticalName,
        backgroundColor:
          verticalName === 'Shopping'
            ? '#005F95'
            : verticalName === 'Video'
              ? '#0096FF'
              : verticalName === 'Display'
                ? '#7FC9FF'
                : '#CCEAFF',
        data: filteredData.map((campaign) => {
          const matchingVertical =
            campaign.Verticals &&
            campaign.Verticals.find((v) => v['Vertical Name'] === verticalName);
          let value = 0.0;
          if (matchingVertical) {
            if (selectedFilter.name !== 'ROAS') {
              const selectedValue = matchingVertical[selectedFilter.name];
              if (selectedValue) {
                const valueArray = selectedValue.split(' ');
                if (valueArray.length > 1) {
                  value = parseFloat(valueArray[valueArray.length - 1]);
                }
              }
            } else {
              value = parseFloat(matchingVertical[selectedFilter.name]);
            }
          }
          return value;
        }),
      }));

      const truncatedCampaignNames = campaignNames.map((name) => {
        if (name.length > 20) {
          return `${name.substring(0, 17)}...`;
        }
        return name;
      });

      const chartData = {
        labels: truncatedCampaignNames,
        datasets,
      };
      const options = {
        indexAxis: 'x',
        maintainAspectRatio: false,
        barThickness: 48,

        scales: {
          y: {
            title: {
              display: true,
              text: `${yTitle}  ${yTitle === 'Cost' || yTitle === 'Conversion Value'
                  ? currencyCode
                  : ''
                }`,
              color: '#323C46',
              font: {
                size: 14,
                family: 'Montserrat',
                weight: '600',
              },
            },
            stacked: true,
            ticks: {
              beginAtZero: true,
              steps: 10,
              stepValue: 5,
              maxTicksLimit: 5,
              // stepSize: selectedFilter.name === 'ROAS' ? 5 : selectedFilter.name === 'Cost' ? 1000 : 5000,
              min: 0,
              font: {
                weight: '500',
                family: 'Montserrat',
              },
            },
          },
          x: {
            title: {
              display: Boolean(xTitle),
              text: `${xTitle}`,
              color: '#323C46',
              font: {
                size: 14,
                family: 'Montserrat',
                weight: '600',
              },
            },
            stacked: true,
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              font: {
                weight: '500',
                family: 'Montserrat',
              },
            },
            grid: {
              display: false,
            },
          },
        },
        responsive: true,

        plugins: {
          tooltip: {
            yAlign: 'bottom',
            mode: 'index',
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y.toFixed(2);
                }
                return label;
              },

              footer: function (context) {
                const stackedBarArray = context[0].chart.legend.legendItems.map(
                  (item, index) => {
                    if (!item.hidden) {
                      return context[0].parsed._stacks.y[index];
                    }
                    return 0; // Return 0 if the legend is hidden
                  }
                );

                let sum = stackedBarArray.reduce((curr, acc) => curr + acc, 0);
                sum = sum.toFixed(1);
                return `Total-${sum}`;
              },
            },
          },
          legend: {
            display: true,
            align: 'end',
            padding: 50,
            labels: {
              usePointStyle: true,
              font: {
                size: 14,
                family: 'Montserrat',
                weight: '600',
              },

              // eslint-disable-next-line object-shorthand
              generateLabels: (chart) => {
                let labels =
                  Chart.defaults.plugins.legend.labels.generateLabels(chart);
                // Filter out labels for which there is no data
                // eslint-disable-next-line arrow-body-style
                labels = labels.filter((label) => {
                  // Check if there's data for the label

                  return chart.data.datasets.some(
                    (dataset) =>
                      dataset.label === label.text &&
                      dataset.data.some(
                        (data) => data !== null && data !== undefined
                      )
                  );
                });

                labels = labels.map((label) => ({
                  ...label,
                  pointStyle:
                    !label.hidden && label.text === 'Shopping'
                      ? shopppingcheckboxYes
                      : !label.hidden && label.text === 'Display'
                        ? displayCheckboxYes
                        : !label.hidden && label.text === 'Video'
                          ? videoCheckboxYes
                          : !label.hidden && label.text === 'Search'
                            ? searchCheckboxYes
                            : checkboxNo,
                }));

                return labels;
              },
            },
          },
          datalabels: {
            display: false,
          },
        },
      };
      // adding space bw chart and legend
      const legendMargin = {
        id: 'legendMargin',
        beforeInit(chart) {
          const originalFit = chart.legend.fit;
          // eslint-disable-next-line no-param-reassign
          chart.legend.fit = function () {
            originalFit.call(chart.legend);
            const padding = 20;
            // eslint-disable-next-line react/no-this-in-sfc
            this.height += padding;
          };
        },
        // setting sum on the top of every bar
        afterDatasetsDraw: function (chart) {
          const {
            ctx,
            scales: { x },
          } = chart;

          const sums = chart.data.datasets.reduce(
            (acc, dataset, datasetIndex) => {
              if (!chart.getDatasetMeta(datasetIndex).hidden) {
                dataset.data.forEach((value, index) => {
                  acc[index] = (acc[index] || 0) + value;
                });
              }
              return acc;
            },
            []
          );

          const visibleIndices = chart.legend.legendItems
            .map((el, ind) => {
              if (!el.hidden) {
                return ind;
              }
              return null;
            })
            .filter((legend) => legend !== null);

          const max = Math.max(...visibleIndices) || 0;
          const maxIndex = max > 0 ? max : 0;

          sums.forEach((sum, index) => {
            // if the sum is zero we will not show it in the ui
            if (sum !== 0) {
              const postionOFSum =
                chart.getDatasetMeta(maxIndex).data[index].y - 10;
              const formattedSum =
                selectedFilter?.name === 'ROAS'
                  ? sum.toFixed(1)
                  : Math.floor(sum).toLocaleString();

              // eslint-disable-next-line no-param-reassign
              sum = Math.floor(sum).toLocaleString();
              ctx.font = '500 14px Montserrat';
              ctx.textAlign = 'center';
              ctx.fillStyle = '#000000';

              ctx.fillText(
                formattedSum,
                x.getPixelForValue(index),
                postionOFSum
              );
            }
          });
        },
      };

      chartInstance.current = new Chart(ctx, {
        type: 'bar',
        data: chartData,
        options,
        plugins: [legendMargin],
      });

      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    }
  }, [data, selectedFilter, xTitle, yTitle]);

  const total = data && data.filter((el) => el['Campaign Name'] === 'Total');

  let selectedFilterValue =
    total.length > 0 ? +total[0][selectedFilter?.name] : null;
  selectedFilterValue =
    selectedFilter?.name === 'ROAS'
      ? selectedFilterValue?.toFixed(1)
      : Math.floor(selectedFilterValue).toLocaleString();

  return (
    <>
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 600,
          fontFamily: 'Montserrat',
          marginLeft: '1.6rem',
          // marginTop: '0.5rem',
          marginBottom: '-1.5rem',
          padding: '0',
        }}
      >
        All Campaigns {selectedFilter?.name} - {selectedFilterValue}
      </Typography>
      <canvas ref={chartRef} id='myChart' />
    </>
  );
};

export { PMaxChart };
