import { api } from '../api';

const platformApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCred: builder.query({
      query: (platform) => ({
        url: `/google-ads/creds-validity`,
        params: {
          platform,
        },
        method: 'GET',
      }),
      providesTags: ['CRED'],
    }),
    getAccounts: builder.query({
      query: (platform) => ({
        url: `/google-ads/accounts`,
        params: {
          platform,
        },
        method: 'GET',
      }),
      providesTags: (result, error, platform) => [
        { type: 'ACCOUNTS', platform },
      ],
    }),
    getOauthUrl: builder.query({
      query: (platform) => ({
        url: `/google-ads/connector`,
        params: {
          platform,
        },
        method: 'GET',
      }),
      providesTags: (result, error, platform) => [
        { type: 'OAUTHURL', platform },
      ],
    }),
    postPlatform: builder.mutation({
      query: ({ data, platform }) => ({
        url: `/google-ads/connector?platform=${platform}`,
        body: {
          ...data,
        },
        method: 'POST',
      }),
      invalidatesTags: ['PLATFORM'],
    }),
  }),
});

const {
  useGetCredQuery,
  useGetOauthUrlQuery,
  usePostPlatformMutation,
  useGetAccountsQuery,
} = platformApi;
export {
  platformApi,
  useGetCredQuery,
  useGetOauthUrlQuery,
  usePostPlatformMutation,
  useGetAccountsQuery,
};
