import React, { useState, useEffect } from 'react';
import { Box, Button, Fade, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {
  StyledModal,
  StyledGlobalModal,
} from '../../../components/Modal/styles';
import {
  addSelectedMetric,
  resetSelectedMetric,
} from '../../FeedManagement/Redux/CustomizedColumn/slice';
import { FetchingDataLoader } from '../../../widgets/Loader/FetchingDataLoader';
import { CloseButton } from '../../../widgets/CloseButton';
import { HeadingWithUnderline } from '../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { AccordianCheckboxList } from './SelectWithCheckmark';
import { DragableComponent } from './DragableComponent';
import {
  useCustomizedColumnGetQuery,
  useCustomizedColumnPatchMutation,
} from '../../../api/RTK/services/metric';

// Function to format naming
function formatNaming(inputString) {
  const words = inputString.split('_');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  return (
    `${capitalizedWords.join(' ')} - Customize Columns` || 'Customize Columns'
  );
}

// Function to handle success and error messages
function handleMessages(isSuccess, setOpenChildModal, setOpen, dispatch) {
  setOpenChildModal(true);

  const modalTimeout = setTimeout(() => {
    setOpenChildModal(false);
  }, 3000);

  const finalTimeout = setTimeout(() => {
    setOpenChildModal(false);
    setOpen(false);
    dispatch(resetSelectedMetric());
  }, 2900);

  return () => {
    clearTimeout(modalTimeout);
    clearTimeout(finalTimeout);
  };
}

// Function for rendering error message
function renderErrorMessage() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
      <Typography variant='smallMedium' color='error'>
        Select at least one metric.
      </Typography>
    </Box>
  );
}

// CustomizedColumnModal component
const CustomizedColumnModal = ({
  open,
  setOpen,
  databoxType,
  accountId,
  count,
  setCount,
}) => {
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState({
    msg: '',
    color: '',
    icon: '',
  });
  const [openChildModal, setOpenChildModal] = useState(false);
  const select = useSelector(
    (state) => state.customizedColumnRedux.selectedMetric
  );

  const handleClose = () => {
    dispatch(resetSelectedMetric());
    setOpen(false);
  };

  const handleCloseChildModal = () => {
    setOpenChildModal(false);
    setOpen(false);
  };

  const [customizedColumnPatch] = useCustomizedColumnPatchMutation();
  const CustomizedColumnData = useCustomizedColumnGetQuery(
    {
      accountId,
      databoxType,
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    dispatch(
      addSelectedMetric(
        CustomizedColumnData?.data?.custom_databox_info?.selected_columns
      )
    );
  }, [CustomizedColumnData]);

  const handleClick = () => {
    const selectedColumnId =
      CustomizedColumnData?.data?.custom_databox_info?.id;

    if (selectedColumnId) {
      customizedColumnPatch({
        id: selectedColumnId,
        data: { selected_columns: select },
      })
        .unwrap()
        .then(() => {
          setOpenChildModal(true);
          setCount(count + 1);
          setMessage(() => ({
            msg: 'Metrics Updated Successfully',
            color: 'text.primary',
            icon: <CheckCircleIcon color='primary' fontSize='large' />,
          }));
          handleMessages(true, setOpenChildModal, setOpen, dispatch);
          CustomizedColumnData?.refetch();
        })
        .catch(() => {
          setOpenChildModal(true);
          setMessage(() => ({
            msg: `Couldn't save the changes, Please try again.`,
            color: 'error',
            icon: <ErrorOutlineIcon color='error' fontSize='large' />,
          }));

          handleMessages(false, setOpenChildModal, setOpen, dispatch);
        });
    }
  };

  const isSaveButtonDisabled = select?.length < 1;

  useEffect(() => {
    setIsError(select?.length < 1);
  }, [select?.length]);

  return (
    <>
      <StyledModal open={open} onClose={handleClose}>
        <Fade in={open}>
          <StyledGlobalModal
            sx={{
              minWidth: 'auto',
              '@media(min-width:600px)': {
                minWidth: '700px',
              },
            }}
          >
            <HeadingWithUnderline
              heading={formatNaming(databoxType || 'Databox')}
            />
            {CustomizedColumnData.isLoading ? (
              <FetchingDataLoader />
            ) : (
              <>
                <Grid container>
                  <Grid
                    item
                    container
                    flexDirection='column'
                    justifyContent='space-between'
                    gap={2}
                    sm={12}
                    md={6}
                    sx={{
                      '@media(min-width:890px)': {
                        borderRight: '.05rem solid #EBEBEB',
                        paddingRight: '16px',
                      },
                    }}
                  >
                    <Box
                      sm={12}
                      sx={{
                        width: '100%',
                        '&. MuiAccordion-root.Mui-expanded:first-of-type': {
                          marginTop: '16px',
                        },
                      }}
                    >
                      <AccordianCheckboxList resp={CustomizedColumnData} />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    container
                    sm={12}
                    md={6}
                    gap={2}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      '@media(min-width:890px)': {
                        borderLeft: '.05rem solid #EBEBEB',
                        paddingLeft: '16px',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <Typography variant='smallBold'>
                        Selected Columns
                      </Typography>
                      <Typography
                        variant='smallRegular'
                        sx={{
                          color: (theme) => theme.palette.text.faded,
                          cursor: 'default',
                        }}
                      >
                        Drag to reorder
                      </Typography>
                    </Box>
                    <DragableComponent resp={CustomizedColumnData} />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    borderTop: '.1rem solid #EBEBEB',
                  }}
                  pt={2}
                >
                  {isError && renderErrorMessage()}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 2,
                    }}
                  >
                    <Button
                      variant='outlined'
                      sx={{ width: '180px', textDecoration: 'none' }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      sx={{ width: '180px' }}
                      onClick={handleClick}
                      disabled={isSaveButtonDisabled}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </>
            )}
            <CloseButton onClick={handleClose} />
          </StyledGlobalModal>
        </Fade>
      </StyledModal>
      {openChildModal && (
        <StyledModal open={openChildModal} onClose={handleCloseChildModal}>
          <Fade in={openChildModal}>
            <StyledGlobalModal
              sx={{
                minWidth: '500px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                p: 4,
                gap: 1,
              }}
            >
              {message.icon}
              <Typography variant='bigSemiBold' color={message.color}>
                {message.msg}
              </Typography>
            </StyledGlobalModal>
          </Fade>
        </StyledModal>
      )}
    </>
  );
};

export { CustomizedColumnModal };
