import { getCellRange, toTitleCase } from './index';

const createTableForAdInsightsGoogle = (
  sheet,
  label,
  dataVariable,
  currencyCode
) => {
  sheet.mergeCells(getCellRange(1, 1, Object.keys(dataVariable[0]).length - 1));
  const titleCell = sheet.getCell(`A1`);

  titleCell.value = toTitleCase(label);
  titleCell.alignment = { horizontal: 'center' };
  sheet.addTable({
    name: label,
    ref: `A2`,
    headerRow: true,
    totalsRow: false,
    style: {
      theme: null,
    },
    columns: [
      {
        name: 'Name',
        totalsRowFunction: 'none',
        totalsRowLabel: 'Total',
        filterButton: false,
      },
      {
        name: 'Clicks',
        totalsRowFunction: 'sum',
        filterButton: false,
      },
      {
        name: `Cost (${currencyCode})`,
        totalsRowFunction: 'sum',
        filterButton: false,
      },
      {
        name: 'Conversions',
        totalsRowFunction: 'sum',
        filterButton: false,
      },
      {
        name: `Total Conversion Value (${currencyCode})`,
        totalsRowFunction: 'sum',
        filterButton: false,
      },
      {
        name: 'ROAS',
        totalsRowFunction: 'sum',
        filterButton: false,
      },
    ],
    rows: dataVariable
      .map((v) => [
        ...v.ads_group.map((v1) => [
          v1.adGroup,
          v1.clicks,
          v1.cost,
          v1.conversions,
          v1.totalConvValue,
          v1.roas,
        ]),
      ])
      .reduce((prev, current) => [...prev, ...current], []),
  });

  ['name', 'clicks', 'cost', 'conversions', 'totalConvValue', 'roas'].forEach(
    (key, index) => {
      // get total row cell
      const totalCell = sheet.getCell(
        `${String.fromCharCode(index + 65)}${
          dataVariable.reduce(
            (prev, current) => prev + current.ads_group.length,
            0
          ) + 3
        }`
      );
      if (index !== 0) {
        totalCell.value = dataVariable.reduce(
          (prev, current) => prev + current[key],
          0
        );
      } else {
        totalCell.value = 'Total';
      }
    }
  );
};

export { createTableForAdInsightsGoogle };
