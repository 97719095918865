import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, Box, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ResetPasswordSchema } from '../Schemas';
import { FormikPasswordField } from './Formik';
import { ScrollToError } from './utils/ErrorScroll';
import { HeadingWithUnderline } from '../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { AuthService } from '../../../services/auth';
import {
  ErrorAlert,
  SuccessAlert,
} from '../../../widgets/NewAlerts/ErrorAlert';
import { StyledLink } from './Formik/Styles';
import { PasswordStrengthChecker } from './utils/PasswordStrengthChecker';

const ResetPasswordForm = () => {
  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const handleResetPassword = (values, formikHelper) => {
    const { token } = params;

    AuthService.resetPassword({ ...values, token })
      .then((response) => {
        if (response.status === 200) {
          setSuccessMessage(() => 'Password Reset successful!');
          setErrorMessage(() => '');
          setTimeout(() => navigate('/signin'), 3000);
          formikHelper.resetForm();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500)
            setErrorMessage('Oops! Something went wrong. Please try again.');
          else setErrorMessage(error.response.data?.errors);
        }
      });
  };

  return (
    <>
      {errorMessage && <ErrorAlert message={errorMessage} />}
      {successMessage && <SuccessAlert message={successMessage} />}
      <Formik
        initialValues={initialValues}
        onSubmit={handleResetPassword}
        validationSchema={ResetPasswordSchema}
      >
        {({ values, dirty, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <HeadingWithUnderline
              heading='Reset Password'
              sx={{ textAlign: 'start', pt: 2 }}
            />
            <Box sx={{ pb: 2 }}>
              <FormikPasswordField
                required
                name='password'
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                label='New Password'
                placeholder='Enter password'
                error={Boolean(errors.password) && touched.password}
                helperText={touched.password && errors.password}
                onChange={handleChange}
                onBlur={handleBlur}
                setShowPassword={setShowPassword}
                showPassword={showPassword}
              />
              <PasswordStrengthChecker value={values.password} />
            </Box>
            <Box sx={{ pb: 3 }}>
              <FormikPasswordField
                required
                name='confirmPassword'
                type={showPassword ? 'text' : 'password'}
                value={values.confirmPassword}
                label='Confirm Password'
                placeholder='Confirm password'
                error={
                  Boolean(errors.confirmPassword) && touched.confirmPassword
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                setShowPassword={setShowPassword}
                showPassword={showPassword}
              />
            </Box>
            <ScrollToError />
            <Button
              variant='contained'
              type='submit'
              disabled={!dirty}
              fullWidth
            >
              Save
            </Button>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              mt={1}
            >
              <StyledLink to='/signin'>Sign In</StyledLink>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
export { ResetPasswordForm };
