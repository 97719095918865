import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';

const PasswordStrengthChecker = ({ value }) => {
  const validateValue = (condition) =>
    // eslint-disable-next-line no-nested-ternary
    value.length === 0 ? (
      <NeutralIcon />
    ) : condition ? (
      <SuccessIcon />
    ) : (
      <ErrorIcon />
    );

  return (
    <Stack
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      spacing={1}
      sx={{ pt: 1 }}
    >
      <Box display='flex'>
        {validateValue(value.length >= 8)}
        <Typography variant='smallRegular'>8 Characters</Typography>
      </Box>
      <Box display='flex'>
        {validateValue(value.match(/[A-Z]/))}
        <Typography variant='smallRegular'>1 Uppercase</Typography>
      </Box>
      <Box display='flex'>
        {validateValue(value.match(/[0-9]/))}
        <Typography variant='smallRegular'>1 Number</Typography>
      </Box>
    </Stack>
  );
};

const SuccessIcon = () => (
  <CheckCircleIcon fontSize='small' color='primary' sx={{ mr: 0.5 }} />
);
const ErrorIcon = () => (
  <CancelIcon fontSize='small' color='error' sx={{ mr: 0.5 }} />
);
const NeutralIcon = () => (
  <DoDisturbOnIcon
    fontSize='small'
    color='svg.grey'
    sx={{
      mr: 0.5,
      transform: 'rotate(90deg)',
      color: (theme) => theme.palette.svg.grey,
    }}
  />
);

export { PasswordStrengthChecker };
