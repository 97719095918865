import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import { CreateCompanySchema } from '../../../Forms/Schemas';
import { FormikField } from '../../../Forms/Form/Formik';
import { StyledLink } from '../../../Forms/Form/Formik/Styles';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { ColoPicker } from '../../../Forms/Form/utils/ColorPicker';
import { userLogout } from '../../../../utils/userInfoDispatchFunctions';
import { UserInfoDispatchContext } from '../../../../context/UserInfoDispatchContext';

const initialValues = {
  name: '',
  website_url: '',
  mcc_id: '',
  color: '',
};

const CreateCompanyForm = ({ handleClose, handleSubmit }) => {
  const dispatch = useContext(UserInfoDispatchContext);
  return (
    <>
      {/* {errorMessage && <ErrorAlert message={errorMessage} />} */}
      <Formik
        initialValues={{ ...initialValues, color: 'BLUE' }}
        onSubmit={(values) =>
          handleSubmit(
            values.name,
            values.website_url,
            values.mcc_id,
            values.color
          )
        }
        validationSchema={CreateCompanySchema}
      >
        {({
          values,
          dirty,
          isValid,
          errors,
          touched,
          handleChange,
          handleBlur,
        }) => (
          <Form noValidate>
            <HeadingWithUnderline heading='Create Company' />
            <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
              <FormikField
                name='name'
                label='Company Name'
                required
                type='text'
                placeholder='Enter your business name'
                value={values.name}
                error={Boolean(errors.name) && touched.name}
                helperText={touched.name && errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
              <FormikField
                name='website_url'
                label='Website URL'
                required
                type='url'
                placeholder='Enter your business website URL'
                value={values.website_url}
                error={Boolean(errors.website_url) && touched.website_url}
                helperText={touched.website_url && errors.website_url}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
              <FormikField
                name='mcc_id'
                label='MCC ID'
                type='text'
                placeholder='000-000-0000'
                value={values.mcc_id}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box display='flex' justifyContent='flex-start'>
              <ColoPicker
                name='color'
                value={values.color}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.color) && touched.color}
              />
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              gap={3}
            >
              <Button
                variant='outlined'
                type='button'
                onClick={handleClose}
                fullWidth
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                type='submit'
                disabled={!dirty || !isValid}
                fullWidth
              >
                Create
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Box display='flex' justifyContent='center' mt={1}>
        <StyledLink
          onClick={() => {
            userLogout(dispatch);
          }}
        >
          Sign Out
        </StyledLink>
      </Box>
    </>
  );
};
export { CreateCompanyForm };
