/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useContext, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box } from '@mui/material';

import { AccessibilityRounded, TableChartOutlined } from '@mui/icons-material';
import { CustomizeButton } from '../../../../../../widgets/CustomizeButton';
import { Filter } from '../../../../../../components/Filter';
import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { UserByGenderChart } from '../../../../Databoxes/UserByGender/Chart';
import { UserByGenderTable } from '../../../../Databoxes/UserByGender/Table';
import { CustomSwitch } from '../../../../../../components/CustomSwitch/CustomSwitch';
import { Loading } from '../../../../../../components/Databox/Loading';
import { getDashboardDataService } from '../../../../../../utils/getDashboardDataService';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';
import { CustomizedColumnModal } from '../../../../../MetricAndDimension/DashboardDatabox/CustomizeColumn';
import { ErrorComponent } from '../../../../../../components/GlobalErrorComponent';

const DisplayComponentSelector = ({ showTable, chart, table }) => (
  <Box flexGrow={1} minHeight={0}>
    {showTable ? table : chart}
  </Box>
);

const UserByGenderContainer = ({ accountId, platform, dateRange }) => {
  const [open, setOpen] = useState(false);
  const { account } = useContext(UserInfoContext);
  const [count, setCount] = useState(0);
  const [filterListForPlatform, setFilterListForPlatform] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState();
  const [showTable, setShowTable] = useState(false);

  const memoizedArgs = useMemo(
    () => ({
      account_id: accountId,
      date_range: dateRange,
      databox_type: 'GENDER',
    }),
    [accountId, dateRange]
  );
  const [state] = useDataFetcher(
    [],
    getDashboardDataService(platform),
    memoizedArgs,
    count
  );

  useEffect(() => {
    const originalData = state.data || [];
    const firstItem = originalData.length > 0 ? originalData[0] : {};

    const MainfilterList = Object.keys(firstItem).map((key) => ({
      key,
      name: key,
      value: key,
    }));

    if (MainfilterList.length > 1) {
      MainfilterList.shift();
    }

    setFilterListForPlatform(MainfilterList);
    if (MainfilterList.length > 0) {
      setSelectedFilter(MainfilterList[0]);
    }
  }, [state.data]);

  const onCustomizeClick = () => {
    setOpen(true);
  };
  const handleFilterChange = (newFilter) => {
    setSelectedFilter(newFilter);
  };

  const handleSwitchChange = () => {
    setShowTable(!showTable);
  };

  const filterValue = selectedFilter ? selectedFilter.value : '';

  return (
    <Databox>
      <ErrorBoundary
        fallback={
          <ErrorComponent
            title='Users by Gender'
            info='Know your audience categorized by gender, and target users in a better way. Click on the table icon to switch to a tabular format.'
          />
        }
      >
        <DataboxHeading
          title='Users by Gender'
          info='Know your audience categorized by gender, and target users in a better way. Click on the table icon to switch to a tabular format.'
        >
          {!showTable ? (
            <Filter
              label='Filter'
              selectedFilter={selectedFilter}
              filterList={filterListForPlatform}
              handleFilterChange={handleFilterChange}
            />
          ) : null}
          {showTable ? (
            <>
              <CustomizeButton
                onCustomizeClick={onCustomizeClick}
                account={account}
              />
              {open && (
                <CustomizedColumnModal
                  open={open}
                  setOpen={setOpen}
                  databoxType='gender'
                  accountId={accountId}
                  count={count}
                  setCount={setCount}
                />
              )}
            </>
          ) : null}
        </DataboxHeading>
        <FetchingDisplay
          isLoading={state.isLoading}
          isError={state.isError}
          LoadingElement={<Loading />}
          ErrorElement={
            <ErrorDisplay
              refresh={() => {
                setCount(count + 1);
              }}
              message={
                state.errorInfo?.code === 204
                  ? 'No data available for selected date range.'
                  : state.errorInfo?.code === 400
                  ? "Couldn't load data"
                  : 'Something went wrong'
              }
              showRefreshButton={
                !(
                  state.errorInfo?.code === 400 || state.errorInfo?.code === 204
                )
              }
            />
          }
          SuccessElement={
            <DisplayComponentSelector
              showTable={showTable}
              chart={
                filterValue ? (
                  <Box p={2} height='100%'>
                    <UserByGenderChart
                      data={state.data}
                      filter={filterValue}
                      currencyCode={account.currency_code}
                    />
                  </Box>
                ) : null
              }
              table={
                <UserByGenderTable
                  data={state.data}
                  platform={platform}
                  currencyCode={account.currency_code}
                />
              }
            />
          }
        />
        <Box
          sx={{ display: 'flex', justifyContent: 'end', padding: 2, zIndex: 3 }}
        >
          <CustomSwitch
            checked={showTable}
            handleChange={handleSwitchChange}
            CheckedIcon={TableChartOutlined}
            Icon={AccessibilityRounded}
          />
        </Box>
      </ErrorBoundary>
    </Databox>
  );
};

export { UserByGenderContainer };
