import React from 'react';

import { Box, Container, Typography, Button } from '@mui/material';

import { Link } from 'react-router-dom';

const CTABox = ({ className, ctaText }) => (
  <Container
    className={className}
    maxWidth='none'
    sx={{
      '@media(min-width: 600px)': {
        padding: '0rem 1rem',
      },

      '@media(min-width: 1366px)': {
        padding: '0rem 2rem',
      },
    }}
  >
    <Box
      border='1px solid'
      bgcolor='secondary.lighter'
      borderColor='secondary.main'
      p={2}
      px={4}
      borderRadius={1}
      mt={2}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '@media(min-width: 776px)': {
          gap: 2,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          alignItems: 'center',
        },
      }}
    >
      <Typography variant='smallMedium'>
        {ctaText}
      </Typography>
      <Box
        display='flex'
        gap={2}
        justifyContent='stretch'
        sx={{
          '@media(min-width: 776px)': {
            justifyContent: 'flex-end',
          },
        }}
      >
        {/* <Button
          component='a'
          href='https://www.adhelp.io/request-demo'
          variant='outlined'
          sx={{
            display: 'inline-block',
            textAlign: 'center',
            minWidth: 'fit-content',
            maxWidth: '200px',
            borderColor: 'secondary.main',
            color: 'secondary.main',
            '&:hover': {
              backgroundColor: 'background',
              borderColor: 'secondary.main',
            },
          }}
        >
          Book a Demo
        </Button> */}
        <Button
          component={Link}
          to='/signup'
          variant='contained'
          sx={{
            display: 'inline-block',
            textAlign: 'center',
            minWidth: 'fit-content',
            maxWidth: '200px',
            backgroundColor: 'secondary.main',
            border: '1px solid',
            borderColor: 'secondary.main',
            '&:hover': {
              backgroundColor: 'background.hoverOrange',
              borderColor: 'secondary.main',
            },
          }}
        >
          Start for Free
        </Button>
      </Box>
    </Box>
  </Container>
);

export { CTABox };
