import React from 'react';

// MUI imports
import { InputLabel, TextField, InputAdornment, FormHelperText } from '@mui/material';

// Styled component import
import { StyledFormControl } from '../StyledComponents/StyledImportFileForm';

const DynamicTextField = ({
  name,
  value,
  textTitle,
  inputPlaceholder,
  className,
  helper,
  inType,
  disabled,
  reference,
  img,
  alt,
  iconEnd,
  onChange,
  onBlur,
  onSubmit,
  style,
  error,
}) => {
  const hasError = error && error !== '';
  return (
    <StyledFormControl
      variant='standard'
      className={className}
      sx={{
        width: '100%',
        '&.MuiFormControl-root': {
          '&:hover': {
            '& .MuiInputBase-root:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
              borderColor: hasError ? 'transparent' : '#0096ff',
            },
          },
          '&.MuiTextField-root:hover': {
            '& .MuiInputBase-root:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
              borderColor: hasError ? 'transparent' : '#0096ff',
            },
          },
          '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: hasError ? 'transparent' : '#0096ff',
          },
        },
      }}

    >
      <InputLabel shrink={false} sx={{ position: 'relative', color: '#3d4955' }}>
        {textTitle}
      </InputLabel>
      <TextField
        hiddenLabel
        autoComplete='off'
        placeholder={inputPlaceholder}
        variant='outlined'
        className={`importInput ${hasError ? 'hasError' : ''}`}
        type={inType}
        ref={reference}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        onSubmit={onSubmit}
        helperText={helper}
        disabled={disabled}
        sx={{
          ...style,
          '& .MuiFormHelperText-root': {
            color: hasError ? '#e13f2a' : null,
          },
          '& .MuiOutlinedInput-root .Mui-disabled': {
            backgroundColor: 'transparent',
          },
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            '&:hover:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
              borderColor: hasError ? 'transparent' : '#0096ff',
            },
          },
        }}
        style={disabled ? { pointerEvents: 'none' } : {}}
        FormHelperTextProps={{
          sx: {
            position: 'absolute',
            bottom: '45px',
            right: '0px',
            marginRight: '2px',
            color: '#999999',
            opacity: '1'
          },
        }}

        InputProps={{
          startAdornment: img ? (
            <InputAdornment position='start'>
              <img src={img} alt={alt} height='20' width='20' />
            </InputAdornment>
          ) : null,
          endAdornment: iconEnd ? (
            <InputAdornment position='end'>{iconEnd}</InputAdornment>
          ) : null,
        }}
      />
      {error && <FormHelperText className='form-error'>
        <p className='errorMsg'>{error}</p></FormHelperText>}
    </StyledFormControl>
  );
};
export { DynamicTextField };
