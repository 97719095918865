import { React, useState } from 'react';
import { IconButton } from '@mui/material';
import { MenuRounded } from '@mui/icons-material';
import { Header } from './Header';
import { Sidebar } from './Sidebar';

const Nav = () => {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <>
      <Header
        SidebarToggle={
          <IconButton
            onClick={() => toggleDrawer()}
            sx={{ '@media(min-width: 996px)': { display: 'none' } }}
          >
            <MenuRounded />
          </IconButton>
        }
      />

      <Sidebar open={open} toggleDrawer={toggleDrawer} />
    </>
  );
};

export { Nav };

