import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ActionData: {},
};

const actionSlice = createSlice({
  name: 'ActionFilterData',
  initialState,

  reducers: {
    setActionForm(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.ActionFilterData = { ...action.payload };
    },
    clearActionSlice(state) {
      // eslint-disable-next-line no-param-reassign
      state.ActionFilterData = {};
    },
  },
});

// eslint-disable-next-line import/no-default-export
export default actionSlice.reducer;
export const { setActionForm, clearActionSlice } = actionSlice.actions;
