import React from 'react';
import { Typography, Box, Button, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { CreateOutlined } from '@mui/icons-material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(
  () => `
  height: 2.2rem;
  display:flex;
  justify-content: center;
  align-items:center;
  width: fit-content;
    `
);
const StyledPaper = styled(Paper)(
  ({ theme }) => `
      background-color:${theme.palette.background.main};
      border-radius:${theme.spacing(1)};
    `
);

const StyledBox = styled(Box)(
  () => `
  display:flex;
  justify-content:space-between;
  align-items:center;
  .MuiSvgIcon-root{
    color:white !important;
  }
    `
);

const ButtonComponent = ({ to }) => (
  <StyledButton
    startIcon={<CreateOutlined sx={{ mr: 0.5 }} fontSize='small' />}
    variant='pillOutlined'
    component={Link}
    to={to}
  >
    <Typography variant='smallMedium'>Edit</Typography>
  </StyledButton>
);

export { ButtonComponent, StyledPaper, StyledBox, StyledButton };
