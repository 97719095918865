import React from 'react';
import { useSelector } from 'react-redux';
import { ErrorSnackBar } from '../../widgets/SnackbarNew/ErrorSnackBar/ErrorSnackBar';
/*
isLoading, isError: Boolean
LoadingElement, ErrorElement, SuccessElement: Component
*/
const FetchingDisplay = ({
  isLoading,
  isError,
  LoadingElement,
  ErrorElement,
  SuccessElement,
}) => {
  const status = useSelector((state) => state.downloadNotification.status);
  const message = useSelector((state) => state.downloadNotification?.message);

  const severity = message === "Downloaded successfully" ? 'success' : 'error';

  const errorStyle = {
    backgroundColor: '#fff0e7 !important',
    color: '#FF823C !important',
    padding: '10px 20px',
    border: '1px solid #FF823C',
  };
  const successStyle = {
    backgroundColor: '#E0F2FF !important',
    color: '#0096FF !important',
    padding: '10px 20px',
    border: '1px solid #0096FF',
  }
  const snackbarstyle = message === "Downloaded successfully" ? successStyle : errorStyle ;

  return (
    <>
      {isError && ErrorElement}
      {isLoading && LoadingElement}
      {!isError && !isLoading && SuccessElement}
      {status && (
        <ErrorSnackBar
          message={message}
          autoHideDuration={2000}
          sx={snackbarstyle}
          severity={severity}
        />
      )}
    </>
  );
};

export { FetchingDisplay };
