/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Databox } from '../../../../../../components/Databox';
import { Loading } from '../../../../../../components/Databox/Loading';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { getDashboardDataService } from '../../../../../../utils/getDashboardDataService';
import { CampaignSummaryTable } from '../../../../Databoxes/CampaignSummary';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';
import { CustomizedColumnModal } from '../../../../../MetricAndDimension/DashboardDatabox/CustomizeColumn';
import { CustomizeButton } from '../../../../../../widgets/CustomizeButton';
import { ErrorComponent } from '../../../../../../components/GlobalErrorComponent';

const CampaignSummaryContainer = ({ accountId, dateRange }) => {
  const { account } = useContext(UserInfoContext);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);

  const memoizedArgs = useMemo(
    () => ({
      account_id: accountId,
      date_range: dateRange,
      databox_type: 'CAMPAIGN_SUMMARY',
    }),
    [accountId, dateRange]
  );
  const [state] = useDataFetcher(
    {},
    getDashboardDataService('meta'),
    memoizedArgs,
    count
  );

  const onCustomizeClick = () => {
    setOpen(true);
  };

  return (
    <Databox>
      <ErrorBoundary
        fallback={
          <ErrorComponent
            title='Campaign Summary'
            info='Ad campaign insights are summarized in a systematic tabular format, making the
            data approachable.'
          />
        }
      >
        <DataboxHeading
          title='Campaign Summary'
          info='Ad campaign insights are summarized in a systematic tabular format, making the
        data approachable.'
        >
          <>
            <CustomizeButton
              onCustomizeClick={onCustomizeClick}
              account={account}
            />
            {open && (
              <CustomizedColumnModal
                open={open}
                setOpen={setOpen}
                databoxType='campaign_summary'
                accountId={accountId}
                count={count}
                setCount={setCount}
              />
            )}
          </>
        </DataboxHeading>
        <FetchingDisplay
          isLoading={state.isLoading}
          isError={state.isError}
          LoadingElement={<Loading />}
          ErrorElement={
            <ErrorDisplay
              refresh={() => {
                setCount(count + 1);
              }}
              message={
                state.errorInfo?.code === 204
                  ? 'No data available for selected date range.'
                  : state.errorInfo?.code === 400
                  ? "Couldn't load data"
                  : 'Something went wrong'
              }
              showRefreshButton={
                !(
                  state.errorInfo?.code === 400 || state.errorInfo?.code === 204
                )
              }
            />
          }
          SuccessElement={
            <CampaignSummaryTable
              data={state.data}
              currencyCode={account.currency_code}
            />
          }
        />
      </ErrorBoundary>
    </Databox>
  );
};

export { CampaignSummaryContainer };
