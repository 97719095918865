import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editedFilterData:{}
}

const editFilterSlice = createSlice({
    name : "editedFilterData",
    initialState,
    reducers : {
      setFilterData(state , action){
        // eslint-disable-next-line no-param-reassign
      state.editedFilterData = {...action.payload};
      },
      clearFilterSlice(state){
        // eslint-disable-next-line no-param-reassign
      state.editedFilterData = {}
      }
    }
})

// eslint-disable-next-line import/no-default-export
export default editFilterSlice.reducer
export const {setFilterData, clearFilterSlice} = editFilterSlice.actions