import React from 'react';

// MUI imports
import { Stack, Typography } from '@mui/material';

// Styled imports
import {
  StyledTitlePaper,
  StyledTypo,
} from '../StyledComponents/StyledTitlePaper';

const TitleCard = ({ title, subTitle, children, imgSrc1, imgSrc2, imgSrc3, style, paperSx }) => (
  <StyledTitlePaper className='titlePaper' elevation={1} sx={paperSx}>
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='flex-start'
      className='titleStack'
      sx={style}
    >
      <StyledTypo>
        <Typography variant='h3' className='title'>
          {imgSrc1 && (
            <>
              <span style={{ paddingRight: '2px' }}>{title} </span>
              <img src={imgSrc1} alt="image1" height={20} width={20} style={{ marginRight: '6px', marginTop: '2px' }} />
              <img src={imgSrc2} alt="image1" height={20} width={20} style={{ marginRight: '6px', marginTop: '2px' }} />
              <img src={imgSrc3} alt="image1" height={20} width={20} style={{ marginRight: '6px', marginTop: '2px' }} />
            </>
          )}
          {!imgSrc1 && title}
        </Typography>
        <Typography variant='h6' className='subTitle'>
          {subTitle}
        </Typography>
      </StyledTypo>
      <div>{children}</div>
    </Stack>
  </StyledTitlePaper>
);

export { TitleCard };
