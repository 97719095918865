import styled from '@emotion/styled';
import { theme } from '../../../../../Maintheme';

const StyledYearlyGraph = styled.div`
  .carouselBox {
    margin: 16px 0px;
  }
  .graphBox {
    .headingTag {
      span {
        color: ${theme.palette.error.main};
        font-size: 16px;
      }
    }
  }
  .noteBox {
    margin: 12px 0px 12px 0px;
  }
  .editButton {
    color: ${theme.palette.text.primary};
    background-color: #e5e2e2;
    font-size: 14px;
    color: #323c46;
    border-radius: 32px;
    padding: 6px 16px;
    &:hover {
      background-color: #cfcfcf;
    }
  }
  .editButtonIcon {
    margin-left: 8px;
  }
`;

export { StyledYearlyGraph };
