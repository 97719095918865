import React from 'react';
import { Typography } from '@mui/material';

const ErrorMessageBox = ({ text, variant }) => (
  <Typography variant={variant} color='error' align='center' py={2}>
    {text}
  </Typography>
);

export { ErrorMessageBox };
