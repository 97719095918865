import React, { useState, useContext, useEffect } from 'react';

import { Box, Button, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FieldArray, useFormikContext } from 'formik';
import { Add } from '@mui/icons-material';
import { MethodSelector } from './Fields/MethodSelector';
import { ValueSelector } from './Fields/ValueSelector';
import { OperationsHelpers } from './helpers';
import { FieldSelector } from './Fields/FieldSelector';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';

const InputGroup = ({
  name,
  fields,
  insert,
  index,
  showAddElse,
  disableElse,
  addFirstElse,
  remove,
  showRemove = false,
}) => {
  const { user } = useContext(UserInfoContext);

  return (
    <Box
      sx={{
        display: 'grid',
        gridGap: '1rem',
        alignItems: 'center',
        '@media(min-width: 1400px)': {
          gridTemplateColumns: '5fr 1fr',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'start',
          gap: 3,
          overflow: 'hidden',
          width: '100%',
          alignItems: 'start',
        }}
      >
        <FieldSelector
          options={['all fields', ...fields]}
          name={`${name}.field`}
          // label='take'
          valueCallback={(field) => field}
          textCallback={(field) => field}
          methodPath={`${name}.action`}
          valuePath={`${name}.value`}
        />
        <MethodSelector
          name={`${name}.action`}
          valuePath={`${name}.value`}
          fieldPath={`${name}.field`}
          label='and'
          fields={fields}
        />
        <ValueSelector
          name={`${name}.value`}
          methodPath={`${name}.action`}
          fields={fields}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          gap: 2,
          gridRow: 2,
          '@media(min-width: 1400px)': {
            gridRow: '1',
            gridColumn: '2',
          },
        }}
      >
        <OperationsHelpers
          insert={insert}
          index={index}
          remove={remove}
          showRemove={showRemove}
          disabled={
            user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR'
          }
        />
        {showAddElse && (
          <Button
            onClick={addFirstElse}
            disabled={
              disableElse ||
              user?.role === 'COLLABORATOR' ||
              user?.role === 'SUB_COLLABORATOR'
            }
            startIcon={<Add />}
            variant='outlined'
            sx={{
              backgroundColor: '#FFF',
              color: 'text.grey',
              border: '1px solid',
              borderRadius: 1,
              borderColor: 'border.main',
              height: '45px',
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            else
          </Button>
        )}
      </Box>
    </Box>
  );
};

const Operations = ({ fields }) => {
  const {
    values: { actions, else_actions: elseActions },
    setFieldValue,
  } = useFormikContext();

  const [isErrorActions, setErrorActions] = useState(false);
  const [isErrorElseActions, setErrorElseActions] = useState(false);

  const addFirstElse = () => {
    setFieldValue('else_actions', [
      {
        field: 'all fields',
        action: '',
        value: '',
      },
    ]);
  };

  useEffect(() => {
    const gtinList = actions?.filter((item) => item?.field === 'gtin');
    if (gtinList.length > 0) {
      setErrorActions(true);
    } else {
      setErrorActions(false);
    }
  }, [actions]);
  useEffect(() => {
    const gtinList = elseActions?.filter((item) => item?.field === 'gtin');
    if (gtinList.length > 0) {
      setErrorElseActions(true);
    } else {
      setErrorElseActions(false);
    }
  }, [elseActions]);

 

  return (
    <>
      <FieldArray
        name='actions'
        render={({ insert, remove }) => (
          <Box>
            {actions.map((operation, index) => (
              <Box
                sx={{
                  padding: 2,
                  background: '#f5f6fa',
                  ...(index !== 0
                    ? {
                        marginLeft: 8,
                        padding: 2,
                        paddingTop: 0,
                      }
                    : {}),
                }}
              >
                <Typography variant='bodyMedium' mb={1} display='block'>
                  {index === 0 ? 'Then' : 'And'}
                </Typography>
                <InputGroup
                  name={`actions[${index}]`}
                  fields={fields}
                  insert={insert}
                  index={index}
                  remove={remove}
                  showAddElse={index === 0 && elseActions.length === 0}
                  disableElse={actions[0].field === 'all fields'}
                  addFirstElse={addFirstElse}
                  showRemove={index > 0}
                />
              </Box>
            ))}
            {isErrorActions && (
              <Typography
                variant='smallRegularFaded'
                gap={1}
                mt={1}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <InfoOutlinedIcon fontSize='small' />
                GTIN supports only numeric values.
              </Typography>
            )}
          </Box>
        )}
      />
      <FieldArray
        name='else_actions'
        render={({ insert, remove }) => (
          <Box>
            {elseActions.map((operation, index) => (
              <Box
                sx={{
                  padding: 2,
                  background: '#f5f6fa',
                  ...(index !== 0
                    ? {
                        marginLeft: 8,
                        padding: 2,
                        paddingTop: 0,
                      }
                    : {}),
                }}
              >
                <Typography variant='smallMedium'>
                  {index === 0 ? 'else' : 'And'}
                </Typography>
                <InputGroup
                  name={`else_actions[${index}]`}
                  fields={fields}
                  insert={insert}
                  index={index}
                  remove={remove}
                  showAddElse={false}
                  showRemove
                />
              </Box>
            ))}
            {isErrorElseActions && (
              <Typography
                variant='smallRegularFaded'
                gap={1}
                mt={1}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <InfoOutlinedIcon fontSize='small' />
                GTIN supports only numeric values.
              </Typography>
            )}
          </Box>
        )}
      />
    </>
  );
};
export { Operations };
