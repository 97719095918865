// react imports
import React from 'react';

// styled imports
import { LinkChild, LinkText } from './styles';

const SidebarButton = ({ text, onClick }) => (
  <li>
    <LinkChild onClick={onClick}>
      <LinkText fontSize='1rem' fontWeight={500}>
        {text}
      </LinkText>
    </LinkChild>
  </li>
);

export { SidebarButton };
