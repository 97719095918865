import { toTitleCase, isCurrency } from './index';

const createTableForAdSchedular = (
  sheet,
  label,
  dataVariable,
  currencyCode
) => {
  sheet.addTable({
    name: label,
    ref: `A1`,
    headerRow: true,
    totalsRow: false,
    style: {
      theme: null,
    },
    columns: Object.keys(dataVariable[0]).map((key) => ({
      name: isCurrency(toTitleCase(key))
        ? `${toTitleCase(key)} (${currencyCode})`
        : toTitleCase(key),
      totalsRowLabel: '',
      filterButton: false,
    })),
    rows: dataVariable.map((v) => Object.values(v)),
  });
};

export { createTableForAdSchedular };
