import React from 'react';

import _ from 'lodash';

import { HorizontalIconGraph } from '../../../../../components/HorizontalGraph';

import { ReactComponent as FemaleArtwork } from '../../../../../assests/images/artWork/FemaleArtwork.svg';
import { ReactComponent as MaleArtwork } from '../../../../../assests/images/artWork/MaleArtwork.svg';
import { ReactComponent as UnknownArtwork } from '../../../../../assests/images/artWork/UnknownGenderArtwork.svg';

const iconMap = {
  FEMALE: FemaleArtwork,
  MALE: MaleArtwork,
  UNDETERMINED: UnknownArtwork,
};

const colorMap = {
  FEMALE: '#FF823C',
  MALE: '#0096FF',
  UNDETERMINED: '#B3B3B3',
};

const MetricWithCurrency = [
  'All Conversion value',
  'Average Cost',
  'Conversion value',
  'Cost',
  'Cost per All Conversions',
  'Cost per Conversions values',
  'All Conversion values (by Conversion time)',
];

const labelFormatter = (data, total) =>
  total !== 0
    ? `${_.round((data.value * 100) / total, 1)}% ${data.label}`
    : `0% ${data.label}`;

const filterFunction = (data, filter) =>
  data.map((d, index) => ({
    key: index,
    id: d.Gender,
    value: d[filter],
    label: [d.Gender],
    icon: iconMap[d.Gender],
    color: colorMap[d.Gender],
  }));

const UserByGenderChart = ({ data, filter, currencyCode }) => {
  const newData = data?.filter((key) => key.Gender !== 'Total');
  return (
    <HorizontalIconGraph
      data={filterFunction(newData, filter)}
      keyField='id'
      limit={10}
      labelFormatter={labelFormatter}
      currencyCode={currencyCode}
      tooltipLabel={filter.name}
      isCurrency={MetricWithCurrency.includes(filter.value)}
      metricName={filter.name}
    />
  );
};

export { UserByGenderChart };
