// import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
  
  const projectFieldItem = [
    {
      title: 'availability',
      value: 'Available'
    },
    {
      title: 'id',
      value: 'Id'
    },
    {
      title: 'out_stock',
      value: 'Out_Stock'
    },
    {
      title: 'in_stock',
      value: 'In_Stock'
    },
    {
      title: 'coming soon',
      value: 'Coming Soon'
    }
  ];

const editMappingRedux = createSlice({
  name: 'editMappingRedux',
  initialState: {
    addItems: '',
    projectField: projectFieldItem[0].title,
    minOrder : [],
    importListLength : null,
 
  },
  reducers: {
    updateVal: (state, { payload: { key, val } }) => {
      // eslint-disable-next-line no-param-reassign
      state[key] = val;
    },  
    updateOrder : (state , action) => {
       // eslint-disable-next-line no-param-reassign
       state.minOrder = action.payload
    }, 
    updateLength : (state ,action) => {
      // eslint-disable-next-line no-param-reassign
      state.importListLength = action.payload
    }
  },


});

// eslint-disable-next-line import/group-exports
export const { updateVal , updateOrder , updateLength} = editMappingRedux.actions;

// eslint-disable-next-line import/no-default-export
export default editMappingRedux.reducer;
