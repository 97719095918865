import React, { useState, useContext } from 'react';
// MUI imports
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Button,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

// Internal imports
import { useSelector, useDispatch } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { ImportFileForm } from '../Components/Forms/ImportFileForm';
import { ToggleButtonImport } from '../Components/OtherComponents/ToggleButtonImport';
import { DataSendingModalBaseRTK } from '../../../components/DataSendingModalBaseRTK';

// Styled imports
import { StyledPaper } from './StyledMainBox';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { clearInputValue } from '../Redux/ImportForm/slice';
import {
  useGetFileDetailsQuery,
  useUpdateFileMutation,
} from '../../../api/RTK/services/feedFile';
import { PageErrors } from '../../../components/PageErrors';
import { ProgressLoader } from '../../../widgets/ContentLoaders/ContentLoaders';
// import { useGenerateMappingMutation } from '../../../api/RTK/services/feedMapping';

const formatter = (values) => ({
  ...values,
  url: values.protocol + values.url.replace(/^(https?:\/\/|sftp:\/\/)/i, ''),
});

const UpdateFile = () => {
  const [toggled, setToggled] = useState('Settings');
  const [sendPatch, setSendPatch] = useState(false);
  const [formValue , setformValue] = useState({})
  const fileSelectId = useSelector((state) => state.importedFilesRedux.fileId);
  const { company, user, accountList } = useContext(UserInfoContext);

  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const dispatch = useDispatch();

  const clearReduxState = () => {
    dispatch(clearInputValue());
  };

  const navigate = useNavigate();

  const [
    updateFile,
    {
      isLoading: updateLoading,
      isSuccess,
      error: updateErrorInfo,
      isError: updateError,
      reset,
    },
  ] = useUpdateFileMutation();

  // the commented code in this file is required until further testing.

  // const [generateMapping, { isLoading: generateLoading, isError: generateError, error: generateErrorInfo, isSuccess: generateSuccess, reset: generateReset }] =
  //   useGenerateMappingMutation();

  // const patchFile = (values) => {
  //   dispatch({ type: 'SENDING' });
  //   return updateFile({
  //     fileId: fileSelectId,
  //     projectId: projectSelectId,
  //     companyId: company.id,
  //     data: formatter(values),
  //   })
  //     // .then((response) =>
  //     //   Promise.all([
  //     //     response,
  //     //     generateMapping({
  //     //       companyId: company.id,
  //     //       projectId: projectSelectId,
  //     //       fileId: response.data.id,
  //     //     }).unwrap(),
  //     //   ])
  //     // )
  //     .then(([patchResponse]) => {
  //       // if (generateResponse.error) {
  //       //   dispatch({ type: 'SENDERROR', error: generateResponse.error.data.errors || generateResponse?.error?.data[0]?.error });
  //       // } else {
  //       dispatch({ type: 'SENDSUCCESS', data: patchResponse.data });
  //       dispatch({ type: 'PRESEND' });
  //       console.log('sgh', patchResponse)
  //       clearReduxState();
  //       navigate(`../edit-mapping/${patchResponse.data.id}`);
  //       // }
  //     })
  //     .catch((error) => {
  //       dispatch({ type: 'SENDERROR', error: error.data });
  //     });
  // };

  const patchFile = (values) => {
    dispatch({ type: 'SENDING' });
    return updateFile({
      fileId: fileSelectId,
      projectId: projectSelectId,
      companyId: company.id,
      data: formatter(values),
    })
      .then((patchResponse) => {
        dispatch({ type: 'SENDSUCCESS', data: patchResponse.data });
        dispatch({ type: 'PRESEND' });
        clearReduxState();
        navigate(`../edit-mapping/${patchResponse.data.id}`);
      })
      .catch((error) => {
        dispatch({ type: 'SENDERROR', error: error.data });
      });
  };

  const { data, isLoading, isError, error } = useGetFileDetailsQuery(
    {
      companyId: company?.id,
      projectId: projectSelectId,
      fileId: fileSelectId,
    },
    {
      skip: !fileSelectId || !company,
    }
  );

  const errorImportPatch = () => {
    if (updateError) {
      return `${
        updateErrorInfo?.data?.errors ||
        updateErrorInfo?.data[0]?.error ||
        'Unknown error'
      }`;
    }
    // if (generateError) {
    //   return `${generateErrorInfo?.data?.errors || generateErrorInfo?.data[0]?.error || 'Unknown error'}`;
    // }
    return 'Unable to update import';
  };
  return (
    <FetchingDisplay
      isLoading={isLoading}
      isError={isError}
      LoadingElement={<GlobalLoadingComponent />}
      ErrorElement={
        <PageErrors
          isServerError
          code={error?.originalStatus || error?.status}
        />
      }
      SuccessElement={
        <ErrorBoundary fallback={<PageErrors />}>
          <Backdrop
            sx={{ color: '#fff', zIndex: 4 }}
            open={
              updateLoading
              // || generateLoading
            }
          >
            <CircularProgress color='inherit' />
          </Backdrop>
          <Container maxWidth='xxl'>
            <TitleCard title='Import Settings'>
              <Button
                variant='pillOutlined'
                disableRipple
                sx={{
                  height: '44px',
                  minWidth: '175px',
                  marginRight: '16px',
                  '&:hover': { svg: { color: '#0096ff !important' } },
                  svg: { paddingRight: '5px' },
                }}
                onClick={() => patchFile(formValue)}
                // disabled={getLoading}
              >
                <RefreshIcon sx={{
                fontSize : "32px"
                }} />
                Re-fetch import
              </Button>
              <ToggleButtonImport
                toggled={toggled}
                setToggled={setToggled}
                fileId={fileSelectId}
              />
            </TitleCard>
            <StyledPaper>
              <ImportFileForm
                data={data}
                handleSubmit={
                  user?.role !== 'COLLABORATOR' &&
                  user?.role !== 'SUB_COLLABORATOR' &&
                  sendPatch
                    ? patchFile
                    : () => navigate(`../edit-mapping/${fileSelectId}`)
                }
                setFormValue = {setformValue}
                
                accountList={accountList}
                setSendPatch={setSendPatch}
              />
            </StyledPaper>
          </Container>
          <DataSendingModalBaseRTK
            open={
              updateLoading || isSuccess || updateError
              // generateLoading ||
              // generateSuccess ||
              // generateError
            }
            handleCloseModal={() => {
              reset();
              // generateReset();
            }}
            isLoading={
              updateLoading
              // || generateLoading
            }
            isSuccess={
              isSuccess
              // && generateError
            }
            isError={
              updateError
              // || generateError
            }
            successMessage='Import successfully updated'
            errorMessage={errorImportPatch()}
            isCustom={data?.file_type === 'WOO_COMMERCE'}
            loadingComponent={
              <ProgressLoader loading={updateLoading} success={isSuccess} />
            }
            reset={() => {
              reset();
            }}
          >
            <Box sx={{ height: '200px' }} />
          </DataSendingModalBaseRTK>
        </ErrorBoundary>
      }
    />
  );
};

export { UpdateFile };