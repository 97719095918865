import React from 'react';
import { getHeadCells } from '../../../../../components/Headcell/Headcell';
import { CollapsibleTable } from '../../../../../components/CollapsibleTable';

const PMaxTable = ({ data, currencyCode = 'ZZZ' }) => {
  if (data.length === 0) {
    return [];
  }

  const firstDataItem = data[0] || [];

  const defaultFormatFunction = (row, key) => {
    if (row[key]) {
      if (
        key === 'Clicks' ||
        key === 'Conversion Value' ||
        key === 'Conversions' ||
        key === 'Cost'
      ) {
        const [percentage, value] = row[key].match(/\((.*)\)\s(.*)/).slice(1);
        const numericValue = parseFloat(value);
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(numericValue)) {
          const formattedValue = `${percentage}, ${numericValue.toFixed(1)}`;
          return formattedValue;
        }
      }
    }
    return row[key] || row['Vertical Name'];
  };


  const excludedKeys = ['Campaign Id', 'Verticals'];

  // Separate "Total" row from the data
  const totalRow = data.find((row) => row['Campaign Name'] === 'Total');
  const filteredData = data.filter((row) => row['Campaign Name'] !== 'Total');

  return (
    <CollapsibleTable
      outerHeadCells={getHeadCells(
        firstDataItem,
        excludedKeys,
        currencyCode,
        defaultFormatFunction
      )}
      innerHeadCells={getHeadCells(
        firstDataItem,
        excludedKeys,
        currencyCode,
        defaultFormatFunction
      )}
      innerDataField='Verticals'
      rows={filteredData}
      totalRow={totalRow}
      outerRowIdField='Campaign Id'
      innerRowIdField='Vertical Id'
      className='tableRow'
    />
  );
};

export { PMaxTable };
