
// function component for common calculation used across different channels
const commonCalculation = (values) => {
    // this calculation shows how many mandatory items, recommended items & optional items are mapped
    const mandatoryItems = values.channelFields.filter((item) => item.priority === 0);
    const mandatoryMapped = mandatoryItems.filter((item) => item.mapped_to !== '');

    const recommendedItems = values.channelFields.filter((item) => item.priority === 1);
    const recommendedMapped = recommendedItems.filter((item) => item.mapped_to !== '');

    const optionalItems = values.channelFields.filter((item) => item.priority === 2);
    const optionalMapped = optionalItems.filter((item) => item.mapped_to !== '');

    // this calculates the total fields & others depending upon the above calc
    const totalFields = Math.round(mandatoryItems.length + recommendedItems.length + optionalItems.length);
    const totalMandatory = Math.round(mandatoryItems.length);
    const totalRecommended = Math.round(recommendedItems.length);
    const totalOptional = Math.round(optionalItems.length);
    const totalFieldsPassed = Math.round(mandatoryMapped.length + recommendedMapped.length + optionalMapped.length);
    const mandatoryFieldFailed = Math.round(mandatoryItems.length - mandatoryMapped.length);
    const recommendedFieldFailed = Math.round(recommendedItems.length - recommendedMapped.length);
    const optionalFieldFailed = Math.round(optionalItems.length - optionalMapped.length);

    return {
        mandatoryMapped,
        mandatoryItems,
        recommendedItems,
        recommendedMapped,
        optionalItems,
        optionalMapped,
        totalFields,
        totalMandatory,
        totalRecommended,
        totalOptional,
        totalFieldsPassed,
        mandatoryFieldFailed,
        recommendedFieldFailed,
        optionalFieldFailed,
    };
}

// calculation for google shopping
const GoogleShopping = (
    values,
    setTotalSum,
    setTotalFields,
    setTotalChecks,
    setTotalOptional,
    setTotalRecommended,
    setTotalMandatory,
    setMandatoryFailed,
    setRecommendedFailed,
    setOptionalFailed,
) => {

    const commonValues = commonCalculation(values);

    const {
        mandatoryMapped,
        mandatoryItems,
        recommendedItems,
        recommendedMapped,
        optionalItems,
        optionalMapped,
        totalFields,
        totalMandatory,
        totalRecommended,
        totalOptional,
        totalFieldsPassed,
        mandatoryFieldFailed,
        recommendedFieldFailed,
        optionalFieldFailed,
    } = commonValues;

    const mandatoryFieldScore = Number.isNaN(parseInt((70 / mandatoryItems.length) * mandatoryMapped.length, 10))
        ? 0
        : parseInt((70 / mandatoryItems.length) * mandatoryMapped.length, 10);

    const recommFieldScore = Number.isNaN(parseInt((20 / recommendedItems.length) * recommendedMapped.length, 10))
        ? 0
        : parseInt((20 / recommendedItems.length) * recommendedMapped.length, 10);

    const optionalFieldScore = Number.isNaN(parseInt((10 / optionalItems.length) * optionalMapped.length, 10))
        ? 0
        : parseInt((10 / optionalItems.length) * optionalMapped.length, 10);

    // total score varies with the channel, thats why its mentioned in function itself
    const totalScore = Math.round(mandatoryFieldScore + recommFieldScore + optionalFieldScore);

    setTotalSum(totalScore);
    setTotalFields(totalFields);
    setTotalChecks(totalFieldsPassed);
    setTotalMandatory(totalMandatory);
    setTotalRecommended(totalRecommended);
    setTotalOptional(totalOptional);
    setMandatoryFailed(mandatoryFieldFailed);
    setRecommendedFailed(recommendedFieldFailed);
    setOptionalFailed(optionalFieldFailed);
};

// calculation for Google dynamic search ads(dsa)
const DSA = (
    values,
    setTotalSum,
    setTotalFields,
    setTotalChecks,
    setTotalOptional,
    setTotalRecommended,
    setTotalMandatory,
    setMandatoryFailed,
    setRecommendedFailed,
    setOptionalFailed,
) => {

    const commonValues = commonCalculation(values);

    const {
        mandatoryMapped,
        totalFields,
        totalMandatory,
        totalRecommended,
        totalOptional,
        totalFieldsPassed,
        mandatoryFieldFailed,
        recommendedFieldFailed,
        optionalFieldFailed,
    } = commonValues;

    let mandatoryFieldScore;
    let recommFieldScore;
    let optionalFieldScore;

    if (mandatoryMapped.length === 0) {
        mandatoryFieldScore = 0;
        recommFieldScore = 0;
        optionalFieldScore = 0;
    }
    if (mandatoryMapped.length === 1) {
        mandatoryFieldScore = 33;
        recommFieldScore = 0;
        optionalFieldScore = 0;
    }

    if (mandatoryMapped.length === 2) {
        mandatoryFieldScore = 100;
        recommFieldScore = 0;
        optionalFieldScore = 0;
    }

    const totalScore = Math.round(mandatoryFieldScore + recommFieldScore + optionalFieldScore);

    setTotalSum(totalScore);
    setTotalFields(totalFields);
    setTotalChecks(totalFieldsPassed);
    setTotalMandatory(totalMandatory);
    setTotalRecommended(totalRecommended);
    setTotalOptional(totalOptional);
    setMandatoryFailed(mandatoryFieldFailed);
    setRecommendedFailed(recommendedFieldFailed);
    setOptionalFailed(optionalFieldFailed);
};

// calculation for pinterest
const Pinterest = (
    values,
    setTotalSum,
    setTotalFields,
    setTotalChecks,
    setTotalOptional,
    setTotalRecommended,
    setTotalMandatory,
    setMandatoryFailed,
    setRecommendedFailed,
    setOptionalFailed,
) => {

    const commonValues = commonCalculation(values);

    const {
        mandatoryMapped,
        mandatoryItems,
        totalFields,
        totalMandatory,
        totalRecommended,
        totalOptional,
        totalFieldsPassed,
        mandatoryFieldFailed,
        recommendedFieldFailed,
        optionalFieldFailed,
    } = commonValues;

    const mandatoryFieldScore = Number.isNaN(parseInt((100 / mandatoryItems.length) * mandatoryMapped.length, 10))
        ? 0
        : parseInt((100 / mandatoryItems.length) * mandatoryMapped.length, 10);

    const recommFieldScore = 0;

    const optionalFieldScore = 0;

    // total score varies with the channel, thats why its mentioned in function itself
    const totalScore = Math.round(mandatoryFieldScore + recommFieldScore + optionalFieldScore);

    setTotalSum(totalScore);
    setTotalFields(totalFields);
    setTotalChecks(totalFieldsPassed);
    setTotalMandatory(totalMandatory);
    setTotalRecommended(totalRecommended);
    setTotalOptional(totalOptional);
    setMandatoryFailed(mandatoryFieldFailed);
    setRecommendedFailed(recommendedFieldFailed);
    setOptionalFailed(optionalFieldFailed);
};

// calculation for tiktok
const Tiktok = (
    values,
    setTotalSum,
    setTotalFields,
    setTotalChecks,
    setTotalOptional,
    setTotalRecommended,
    setTotalMandatory,
    setMandatoryFailed,
    setRecommendedFailed,
    setOptionalFailed,
) => {

    const commonValues = commonCalculation(values);

    const {
        mandatoryMapped,
        mandatoryItems,
        recommendedItems,
        recommendedMapped,
        optionalItems,
        optionalMapped,
        totalFields,
        totalMandatory,
        totalRecommended,
        totalOptional,
        totalFieldsPassed,
        mandatoryFieldFailed,
        recommendedFieldFailed,
        optionalFieldFailed,
    } = commonValues;

    const mandatoryFieldScore = Number.isNaN(parseInt((60 / mandatoryItems.length) * mandatoryMapped.length, 10))
        ? 0
        : parseInt((60 / mandatoryItems.length) * mandatoryMapped.length, 10);

    const recommFieldScore = Number.isNaN(parseInt((30 / recommendedItems.length) * recommendedMapped.length, 10))
        ? 0
        : parseInt((30 / recommendedItems.length) * recommendedMapped.length, 10);

    const optionalFieldScore = Number.isNaN(parseInt((10 / optionalItems.length) * optionalMapped.length, 10))
        ? 0
        : parseInt((10 / optionalItems.length) * optionalMapped.length, 10);

    // total score varies with the channel, thats why its mentioned in function itself
    const totalScore = Math.round(mandatoryFieldScore + recommFieldScore + optionalFieldScore);

    setTotalSum(totalScore);
    setTotalFields(totalFields);
    setTotalChecks(totalFieldsPassed);
    setTotalMandatory(totalMandatory);
    setTotalRecommended(totalRecommended);
    setTotalOptional(totalOptional);
    setMandatoryFailed(mandatoryFieldFailed);
    setRecommendedFailed(recommendedFieldFailed);
    setOptionalFailed(optionalFieldFailed);
};

// calculation for google shopping
const MetaAds = (
    values,
    setTotalSum,
    setTotalFields,
    setTotalChecks,
    setTotalOptional,
    setTotalRecommended,
    setTotalMandatory,
    setMandatoryFailed,
    setRecommendedFailed,
    setOptionalFailed,
    marketType,
) => {
    const commonValues = commonCalculation(values);

    const {
        mandatoryMapped,
        mandatoryItems,
        recommendedItems,
        recommendedMapped,
        totalFields,
        totalMandatory,
        totalRecommended,
        totalOptional,
        totalFieldsPassed,
        mandatoryFieldFailed,
        recommendedFieldFailed,
        optionalFieldFailed,
    } = commonValues;


    // Condition to determine scores based on market type
    let mandatoryFieldScore;
    let recommFieldScore;
    let optionalFieldScore;
    if (marketType === 'HOTELS' || marketType === 'FLIGHTS' || marketType === 'REAL_ESTATE') {
        mandatoryFieldScore = Number.isNaN(parseInt((100 / mandatoryItems.length) * mandatoryMapped.length, 10))
            ? 0
            : parseInt((100 / mandatoryItems.length) * mandatoryMapped.length, 10);

        recommFieldScore = 0;

        optionalFieldScore = 0;
    } else {
        mandatoryFieldScore = Number.isNaN(parseInt((75 / mandatoryItems.length) * mandatoryMapped.length, 10))
            ? 0
            : parseInt((75 / mandatoryItems.length) * mandatoryMapped.length, 10);

        recommFieldScore = Number.isNaN(parseInt((25 / recommendedItems.length) * recommendedMapped.length, 10))
            ? 0
            : parseInt((25 / recommendedItems.length) * recommendedMapped.length, 10);

        optionalFieldScore = 0;
    }

    const totalScore = Math.round(mandatoryFieldScore + recommFieldScore + optionalFieldScore);

    // Update various state values using provided functions
    setTotalSum(totalScore);
    setTotalFields(totalFields);
    setTotalChecks(totalFieldsPassed);
    setTotalMandatory(totalMandatory);
    setTotalRecommended(totalRecommended);
    setTotalOptional(totalOptional);
    setMandatoryFailed(mandatoryFieldFailed);
    setRecommendedFailed(recommendedFieldFailed);
    setOptionalFailed(optionalFieldFailed);
};

// calculation for google shopping
const Bing = (
    values,
    setTotalSum,
    setTotalFields,
    setTotalChecks,
    setTotalOptional,
    setTotalRecommended,
    setTotalMandatory,
    setMandatoryFailed,
    setRecommendedFailed,
    setOptionalFailed,
) => {

    const commonValues = commonCalculation(values);

    const {
        mandatoryMapped,
        mandatoryItems,
        recommendedItems,
        recommendedMapped,
        optionalItems,
        optionalMapped,
        totalFields,
        totalMandatory,
        totalRecommended,
        totalOptional,
        totalFieldsPassed,
        mandatoryFieldFailed,
        recommendedFieldFailed,
        optionalFieldFailed,
    } = commonValues;

    const mandatoryFieldScore = Number.isNaN(parseInt((72 / mandatoryItems.length) * mandatoryMapped.length, 10))
        ? 0
        : parseInt((72 / mandatoryItems.length) * mandatoryMapped.length, 10);

    const recommFieldScore = Number.isNaN(parseInt((24 / recommendedItems.length) * recommendedMapped.length, 10))
        ? 0
        : parseInt((24 / recommendedItems.length) * recommendedMapped.length, 10);

    const optionalFieldScore = Number.isNaN(parseInt((4 / optionalItems.length) * optionalMapped.length, 10))
        ? 0
        : parseInt((4 / optionalItems.length) * optionalMapped.length, 10);

    // total score varies with the channel, thats why its mentioned in function itself
    const totalScore = Math.round(mandatoryFieldScore + recommFieldScore + optionalFieldScore);

    setTotalSum(totalScore);
    setTotalFields(totalFields);
    setTotalChecks(totalFieldsPassed);
    setTotalMandatory(totalMandatory);
    setTotalRecommended(totalRecommended);
    setTotalOptional(totalOptional);
    setMandatoryFailed(mandatoryFieldFailed);
    setRecommendedFailed(recommendedFieldFailed);
    setOptionalFailed(optionalFieldFailed);
};


export {
    GoogleShopping,
    DSA,
    Pinterest,
    Tiktok,
    MetaAds,
    Bing
}


