import styled from '@emotion/styled';
import { Select, TextField } from '@mui/material';
import { theme } from '../../../../../Maintheme';

const StyledPOAScalculatorWrapper = styled.div`
  .POASSubtitle {
    margin: 4px 0px 16px 0px;
  }
  .POASTextFieldBox {
    height: 40px;
    margin: 8px 0px 16px 0px;
    .MuiInput-root:hover:not(.Mui-disabled):before {
      border-bottom: 0px solid rgba(0, 0, 0, 0.42);
    }
    .MuiInput-root:hover:not(.Mui-disabled):before {
      border-bottom: 0px solid #323c46;
    }
    .MuiInput-root:before {
      border-bottom: 0px solid rgba(0, 0, 0, 0.42);
    }
    .MuiInput-root:after {
      border-bottom: 0px solid #0096ff;
    }
    .MuiInput-input {
      margin-left: 20px;
    }
    .MuiSelect-icon {
      margin-right: 0px;
    }
  }
  .POASTextField {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }
  .calculateButton {
    width: 200px;
    height: 35px;
    margin-top: 32px;
    background-color: #0096ff;
    border-radius: 5px;
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
  .errorNotificationIcon {
    margin-left: 4px;
    color: #999;
  }
  .sliderBox {
    margin: 16px 0px 28px 0px;
    padding: 0px 12px 0px 0px;
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }
  .sliderLabel {
    margin-bottom: 8px;
  }
`;

const StyledTextField = styled(TextField)`
  border: 1px solid #dedede;
  width: 100%;
  height: 35px;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-left: 12px;

  .MuiInput-root {
    font-size: 14px;
    color: ${theme.palette.text.primary};
  }
  .MuiInput-root:before {
    border-bottom: 0px solid rgba(0, 0, 0, 0);
  }
  .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 0px solid rgba(0, 0, 0, 0);
  }
  .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 0px solid #000;
  }
  .MuiInput-root:after {
    border-bottom: 0px solid #0096ff;
  }
  .MuiInput-input:focus {
    background-color: none;
  }
  .MuiMenuItem-root {
    font-size: 0.8rem;
  }
`;

const StyledSelect = styled(Select)`
  border: 1px solid #dedede;
  width: 30%;
  height: 35px;
  border-radius: 0px 5px 5px 0px;
  padding-right: 8px;
  font-size: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiSelect-icon {
    background-color: #d7e0e7;
    border-radius: 20px;
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }
  .MuiInput-input:focus {
    background-color: rgba(0, 0, 0, 0);
  }
`;

export { StyledPOAScalculatorWrapper, StyledTextField, StyledSelect };
