import { IconButton } from '@mui/material';
import { styled } from '@mui/system';

const DragHandleButton = styled(IconButton)`
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  height: fit-content;

  align-items: center;
  border-radius: 0px;
  bottom: 0px;
  cursor: grab;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: 0px;
  padding: 0px 10px;
  position: absolute;
  top: 0px;
  transition: opacity 100ms;
  z-index: 10;
  &:hover {
    opacity: 1;
  }
  @media (min-width: 810px) {
    transform: none;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0px;
    right: 0px;
  }
`;

export { DragHandleButton };
