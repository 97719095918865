import { getMetaAdsData, getGoogleAdsData } from '../services/dashboard';

const getDashboardDataService = (platform = 'google') => {
  if (platform === 'meta') {
    return getMetaAdsData;
  }
  return getGoogleAdsData;
};

export { getDashboardDataService };
