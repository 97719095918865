import React from 'react';

import { Box, Container } from '@mui/material';

import { Company } from '../../../../../components/Company';
import { DownloadExcelButton } from '../../../../../components/DownloadExcel';
import { Toolbar } from '../../../../../components/Toolbar';
import { AccountSelectorDemo } from './AccountSelectorDemo';
import { DateRangeSelector } from '../../../../../components/DateRangeSelector';

const DemoDashboardToolbar = ({
  CompanyName,
  selectedAccount,
  className,
  accountList,
  handleSelectedAccountChange,
}) => (
  <Toolbar>
    <Container
      maxWidth='none'
      sx={{
        display: 'grid',
        justifyContent: 'space-between',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        gap: 2,
        '@media(min-width: 600px)': {
          padding: '0rem 1rem',
        },
        '@media(min-width: 996px)': {
          display: 'flex',
        },
        '@media(min-width: 1366px)': {
          padding: '0rem 2rem',
        },
      }}
    >
      <Box
        sx={{
          gridArea: '1/1/2/3',
          '@media(min-width: 996px)': {
            order: 0,
            flex: 1,
          },
          '@media(min-width: 1440px)': {
            flex: `0 0 calc(50% - 4px)`,
          },
          '@media(min-width: 1920px)': {
            flex: `0 0 calc(50%)`,
          },
        }}
      >
        <Company name={CompanyName} />
      </Box>
      <Box
        sx={{
          gridArea: '1/2/2/3',
          justifySelf: 'end',
          '@media(min-width: 996px)': {
            order: 3,
          },
        }}
      >
        <DownloadExcelButton
          platform={selectedAccount.platform}
          currencyCode={selectedAccount.currencyCode}
        />
      </Box>
      <Box
        className={className}
        sx={{
          display: 'grid',
          gridArea: '2/1/4/3',
          columnGap: 2,
          rowGap: 2,
          '@media(min-width: 600px)': {
            gridArea: '2/1/2/3',
          },
          '@media(min-width: 996px)': {
            display: 'flex',
            flex: 1,
          },
        }}
      >
        <Box
          sx={{
            gridArea: '1/1/2/2',
            '@media(min-width: 600px)': {
              gridArea: '1/1/2/2',
            },
            '@media(min-width: 996px)': {
              order: 1,
              flex: 1,
            },
          }}
        >
          <AccountSelectorDemo
            selectedAccount={selectedAccount}
            accountList={accountList}
            handleChange={handleSelectedAccountChange}
          />
        </Box>
        <Box
          sx={{
            gridArea: '2/1/3/2',
            '@media(min-width: 600px)': {
              gridArea: '1/2/2/3',
            },
            '@media(min-width: 996px)': {
              order: 2,
              flex: 1,
            },
          }}
        >
          <DateRangeSelector />
        </Box>
      </Box>
    </Container>
  </Toolbar>
);

export { DemoDashboardToolbar };
