import { api } from '../api';

const feedFileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getActions: builder.query({
      query: ({ companyId, projectId, feedId }) => ({
        url: `/feed-management/action/${companyId}/${projectId}`,
        params: {
          feed_id: feedId,
        },
      }),
      providesTags: (result, query, arg) => {
        // do not map over result if it is undefined
        if (result) {
          return [
            ...result.map(({ id }) => ({ type: 'ACTIONS', id })),
            { type: 'ACTIONS', id: `LIST-FEED-${arg.feedId}` },
          ];
        }
        return [{ type: 'ACTIONS', id: `LIST-FEED-${arg.feedId}` }];
      },
    }),

    // get projects, feeds and respective action list
    getProjectImportActions: builder.query({
      query: ({ companyId, projectId }) => ({
        url: `/feed-management/import/${companyId}/${projectId}`,
        params: {
          type: 'ACTION',
        },
      }),
      providesTags: (result, query, arg) => {
        // do not map over result if it is undefined
        if (result) {
          return [
            ...result.map(({ id }) => ({ type: 'ACTIONS', id })),
            { type: 'ACTIONS', id: `LIST-FEED-${arg.feedId}` },
          ];
        }
        return [{ type: 'ACTIONS', id: `LIST-FEED-${arg.feedId}` }];
      },
    }),


    getAffectedItems: builder.query({
      query: ({ companyId, projectId, feedId, operationType, operationId }) => ({
        url: `/feed-management/effective-items/${companyId}/${projectId}`,
        method: 'POST',
        body: {
          feed_id: feedId,
          operation_type: operationType,
          operation_id: operationId
        },
      }),
      providesTags: (result, query, arg) => [
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'FEEDITEMS', id: `LIST-FEED-${arg.feedId}` },
      ],
    }),
    getFilters: builder.query({
      query: ({ companyId, projectId, feedId }) => ({
        url: `/feed-management/filter/${companyId}/${projectId}`,
        params: {
          feed_id: feedId,
        },
      }),
      providesTags: (result, query, arg) => {
        // do not map over result if it is undefined
        if (result) {
          return [
            ...result.map(({ id }) => ({ type: 'FILTERS', id })),
            { type: 'FILTERS', id: `LIST-FEED-${arg.feedId}` },
          ];
        }
        return [{ type: 'FILTERS', id: `LIST-FEED-${arg.feedId}` }];
      },
    }),

    getProjectImportFilters: builder.query({
      query: ({ companyId, projectId }) => ({
        url: `/feed-management/import/${companyId}/${projectId}`,
        params: {
          type: 'FILTER',
        },
      }),
      providesTags: (result, query, arg) => {
        // do not map over result if it is undefined
        if (result) {
          return [
            ...result.map(({ id }) => ({ type: 'FILTERS', id })),
            { type: 'FILTERS', id: `LIST-FEED-${arg.feedId}` },
          ];
        }
        return [{ type: 'FILTERS', id: `LIST-FEED-${arg.feedId}` }];
      },
    }),

    editActions: builder.mutation({
      query: ({ companyId, projectId, actions }) => ({
        url: `/feed-management/action/${companyId}/${projectId}`,
        method: 'PATCH',
        body: actions,
      }),
      invalidatesTags: (result, query, args) =>
        args?.actions?.map(({ id }) => ({ type: 'ACTIONS', id })) || [],
    }),
    editAction: builder.mutation({
      query: ({ companyId, projectId, data }) => ({
        url: `/feed-management/action/${companyId}/${projectId}`,
        method: 'PATCH',
        body: [data],
      }),
      invalidatesTags: (result, query, args) => [
        { type: 'ACTIONS', id: args.data?.id },
        { type: 'ITEMS', id: `LIST-PROJECT-${args.projectId}` },
      ],
    }),



    editFilters: builder.mutation({
      query: ({ companyId, projectId, filters }) => ({
        url: `/feed-management/filter/${companyId}/${projectId}`,
        method: 'PATCH',
        body: filters,
      }),
      invalidatesTags: (result, query, args) =>
        args?.filters?.map(({ id }) => ({ type: 'FILTERS', id })) || [],

    }),
    editFilter: builder.mutation({
      query: ({ companyId, projectId, data }) => ({
        url: `/feed-management/filter/${companyId}/${projectId}`,
        method: 'PATCH',
        body: [data],
      }),
      invalidatesTags: (result, query, args) => [
        { type: 'FILTERS', id: args.data?.id },
        { type: 'ITEMS', id: `LIST-PROJECT-${args.projectId}` },
      ],
    }),
    enhanceFeed: builder.mutation({
      query: ({ companyId, projectId, feedId }) => ({
        url: `/feed-management/enhance`,
        method: 'POST',
        body: {
          company_id: companyId,
          feed_project_id: projectId,
          feed_id: feedId,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FEEDITEMS', id: `LIST-FEED-${arg.feedId}` },
        { type: 'FEEDITEMS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),
    getFeedPreview: builder.query({
      query: ({ companyId, projectId, feedId }) => ({
        url: `/feed-management/preview/${companyId}/${projectId}`,
        method: 'GET',
        params: {
          feed_id: feedId,
        },
      }),
      providesTags: (result, query, arg) => [
        { type: 'FEED', id: arg.feedId },
        { type: 'FEED', id: `PROJECT-${arg.projectId}` },
      ],
    }),

    exportAs: builder.mutation({
      query: ({ companyId, projectId, feedId }) => ({
        url: `/feed-management/exportas`,
        method: 'POST',
        body: {
          company_id: companyId,
          feed_project_id: projectId,
          feed_id: feedId,
          export_type: 'CSV',
        },
        responseHandler: (response) => response.blob(),
      }),
      providesTags: (result, query, arg) => [
        { type: 'FEED', id: arg.feedId },
        { type: 'FEED', id: `PROJECT-${arg.projectId}` },
      ],
    }),
    getDataPreview: builder.query({
      query: ({ companyId, projectId, feedId, pageNo, search }) => ({
        url: `/feed-management/data-preview/${companyId}/${projectId}`,
        method: 'GET',
        params: {
          feed_id: feedId,
          page: pageNo,
          search,
        },
      }),
      providesTags: (result, query, arg) => [
        { type: 'FEED', id: arg.feedId },
        { type: 'FEED', id: `PROJECT-${arg.projectId}` },
        // { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),
    activateFeed: builder.mutation({
      query: ({ companyId, projectId, feedId }) => ({
        url: `/feed-management/feed/${companyId}/${projectId}`,
        method: 'PATCH',
        body: {
          feed_id: feedId,
          active: true,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FEED', id: arg.feedId },
        { type: 'FEEDITEMS', id: `LIST-FEED-${arg.feedId}` },
      ],
    }),
    createAction: builder.mutation({
      query: ({ companyId, projectId, feedId, data }) => ({
        url: `/feed-management/action`,
        method: 'POST',
        body: {
          feed_project_id: projectId,
          company_id: companyId,
          feed_id: feedId,
          active: true,
          ...data,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'ACTIONS', id: `LIST-FEED-${arg.feedId}` },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),

    // import action from other projects
    importAction: builder.mutation({
      query: ({ companyId, projectId, feedId, data }) => ({
        url: `/feed-management/import/${companyId}/${projectId}`,
        params: {
          feed_id: feedId,
        },
        method: 'POST',
        body: {
          import_type: 'ACTION',
          ...data,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'ACTIONS', id: `LIST-FEED-${arg.feedId}` },
        // { type: 'ACTIONS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),

    importFilter: builder.mutation({
      query: ({ companyId, projectId, feedId, data }) => ({
        url: `/feed-management/import/${companyId}/${projectId}`,
        params: {
          feed_id: feedId,
        },
        method: 'POST',
        body: {
          import_type: 'FILTER',
          ...data,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FILTERS', id: `LIST-FEED-${arg.feedId}` },
        // { type: 'FILTERS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),

    createFilter: builder.mutation({
      query: ({ companyId, projectId, feedId, data }) => ({
        url: `/feed-management/filter`,
        method: 'POST',
        body: {
          feed_project_id: projectId,
          company_id: companyId,
          feed_id: feedId,
          active: true,
          ...data,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FILTERS', id: `LIST-FEED-${arg.feedId}` },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),
    deleteAction: builder.mutation({
      query: ({ companyId, projectId, actionId }) => ({
        url: `/feed-management/action/${companyId}/${projectId}`,
        method: 'DELETE',
        body: {
          action_id: actionId,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'ACTIONS', id: arg.actionId },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),
    deleteFilter: builder.mutation({
      query: ({ companyId, projectId, filterId }) => ({
        url: `/feed-management/filter/${companyId}/${projectId}`,
        method: 'DELETE',
        body: {
          filter_id: filterId,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FILTERS', id: arg.filterId },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),
    updateFinalMapping: builder.mutation({
      query: ({ companyId, projectId, feedId, fields }) => ({
        url: `/feed-management/channel-fields/${companyId}/${projectId}`,
        method: 'PUT',
        body: {
          feed_id: feedId,
          fields,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FEED', id: arg.feedId },
      ],
    }),
    finalizeXML: builder.mutation({
      query: ({ companyId, projectId, feedId }) => ({
        url: `/feed-management/download-feed/${companyId}/${projectId}`,
        method: 'GET',
        params: {
          feed_id: feedId,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FEED', id: arg.feedId },
      ],
    }),
    createFeed: builder.mutation({
      query: ({ data }) => ({
        url: `/feed-management/feed`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FEED', id: arg.feedId },
      ],
    }),
    updateFeed: builder.mutation({
      query: ({ companyId, projectId, data }) => ({
        url: `/feed-management/feed/${companyId}/${projectId}`,
        method: 'PATCH',
        body: {
          ...data,
        },
      }),
    }),
  }),
});
export const {
  useEditActionsMutation,
  useEditFiltersMutation,
  useEnhanceFeedMutation,
  useActivateFeedMutation,
  useGetFeedPreviewQuery,
  useExportAsMutation,
  useGetDataPreviewQuery,
  useGetActionsQuery,
  useGetProjectImportActionsQuery,
  useGetProjectImportFiltersQuery,
  useGetAffectedItemsQuery,
  useGetFiltersQuery,
  useImportActionMutation,
  useImportFilterMutation,
  useCreateActionMutation,
  useCreateFilterMutation,
  useDeleteActionMutation,
  useDeleteFilterMutation,
  useEditActionMutation,
  useEditFilterMutation,
  useUpdateFinalMappingMutation,
  useFinalizeXMLMutation,
  useCreateFeedMutation,
  useUpdateFeedMutation,
} = feedFileApi;
