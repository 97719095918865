// react imports
import React, { useContext, useState } from 'react';

// third party imports
import { Button, Popover } from '@mui/material';
import { KeyboardArrowDown, CalendarTodayOutlined } from '@mui/icons-material';

// styled imports
import { CurrentDateRange } from './styles';
import { UserInfoContext } from '../../context/UserInfoContext';

const Layout = ({ DateRangeComponent, isLoading }) => {
  const { user } = useContext(UserInfoContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'dateRangePickerPopover' : undefined;
  const date = new Date().toDateString();
  const startDateForDisplay = user.selected_dashboard_date
    ? new Date(user.selected_dashboard_date.startDate).toDateString().split(' ')
    : date;
  const endDateForDisplay = user.selected_dashboard_date
    ? new Date(user.selected_dashboard_date.endDate).toDateString().split(' ')
    : date;

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    if (!isLoading) setAnchorEl(null);
  };
  return (
    <>
      <Button
        aria-describedby={id}
        endIcon={<KeyboardArrowDown />}
        onClick={openPopover}
        startIcon={<CalendarTodayOutlined color='primary' />}
        variant='pill'
        fullWidth
      >
        <CurrentDateRange>
          {`${startDateForDisplay[1]} ${startDateForDisplay[2]}, ${startDateForDisplay[3]} - ${endDateForDisplay[1]} ${endDateForDisplay[2]}, ${endDateForDisplay[3]}`}
        </CurrentDateRange>
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 42,
          horizontal: 'left',
        }}
        elevation={2}
        id={id}
        open={open}
        onClose={closePopover}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DateRangeComponent onClose={closePopover} />
      </Popover>
    </>
  );
};

export { Layout };
