// Account URIs
const accountGroupURI = '/api/accounts/websites';
const accountsURI = '/api/accounts/';
const accountsMetricsURI = '/api/accounts/metric/';
const accountsLaunchURI = '/api/accounts/launch';
const accountsLinkRequestURI = '/api/accounts/link-request';
const createAdURI = '/api/accounts/create-ads';
const dashboardURI = '/api/accounts/dashboard';
const getPageInfo = '/api/accounts/create-ads';
const invitedPlatformsURI = '/api/accounts/manual-invites';
const topViewDashboardURI = '/api/accounts/dashboard/top-view';
// export const getPageInfo = "/api/facebook-ads/pages";

export {
  accountGroupURI,
  accountsURI,
  accountsMetricsURI,
  accountsLaunchURI,
  accountsLinkRequestURI,
  createAdURI,
  dashboardURI,
  getPageInfo,
  invitedPlatformsURI,
  topViewDashboardURI,
};
