import { useState, useEffect } from 'react';
import { getLayout } from './layouts';
import {
  getLayout as getLayoutFromDB,
  setLayout as setLayoutInDB,
} from '../../services/dashboard';

const breakpoints = {
  lg: 1920,
  md: 1440,
  sm: 1366,
  xs: 600,
  xxs: 0,
};
const isXXS = (width) => width < breakpoints.xs;
const isXS = (width) => width < breakpoints.sm && width >= breakpoints.xs;
const isSM = (width) => width < breakpoints.md && width >= breakpoints.sm;
const isMD = (width) => width < breakpoints.lg && width >= breakpoints.md;
const isLG = (width) => width >= breakpoints.lg;

const rowHeightCalculator = (containerWidth, cols, margin, padding) => {
  switch (true) {
    case isXXS(containerWidth):
      return (containerWidth - (cols - 1) * margin[0] - 2 * padding[0]) / 4;
    case isXS(containerWidth):
      return (containerWidth - (cols - 1) * margin[0] - 2 * padding[0]) / 8;
    case isSM(containerWidth):
      return (containerWidth - (cols - 1) * margin[0] - 2 * padding[0]) / 8;
    case isMD(containerWidth):
      return (containerWidth - (cols - 1) * margin[0] - 2 * padding[0]) / 12;
    case isLG(containerWidth):
      return (containerWidth - (cols - 1) * margin[0] - 2 * padding[0]) / 12;
    default:
      return (containerWidth - (cols - 1) * margin[0] - 2 * padding[0]) / cols;
  }
};

const findPlatform = (platformString) => {
  if (
    platformString.toLowerCase().includes('meta') ||
    platformString.toLowerCase().includes('face')
  ) {
    return 'META_ADS';
  }
  return 'GOOGLE_ADS';
};

/*
  This custom hook returns all the values and callback required by react-resizable-grid
  arguments
  platform: string. google / meta
  demo: boolean

  returns
  layout: object. This is the layout for current platform
  rowHeight: Number. Row height calculated using the current width and current breakpoint in handleWidthChange
  handleWidthChange: function. Callback called when width changes
  handleLayoutChange: function. Callback called when layout changes.(dragging/resizing)

  initially the layout is null and we fetch is from the db. If the fetch fails the layout is set to the default layout
*/

const useLayout = (platform, demo) => {
  const [layout, setLayout] = useState(null);
  const [rowHeight, setRowHeight] = useState(300);

  const handleWidthChange = (containerWidth, margin, cols, padding) => {
    setRowHeight(rowHeightCalculator(containerWidth, cols, margin, padding));
  };

  const handleLayoutChange = (_, newLayouts) => {
    setLayout(newLayouts);
    if (!demo) {
      setLayoutInDB(findPlatform(platform), newLayouts);
    }
  };

  useEffect(() => {
    if (!demo) {
      getLayoutFromDB(findPlatform(platform))
        .then((response) => {
          // if response has a platform_layout set it.
          // if not set the default layout
          if (response.data.platform_layout) {
            setLayout(response.data.platform_layout);
          } else {
            setLayout(getLayout(platform));
          }
        })
        .catch(() => {
          setLayout(getLayout(platform));
        });
    } else {
      setLayout(getLayout(platform));
    }
  }, [platform, demo]);

  return [layout, rowHeight, handleWidthChange, handleLayoutChange];
};

export { useLayout };
