import React, { useMemo, useContext } from 'react';

import { Databox } from '../../../../../../components/Databox';
import { Loading } from '../../../../../../components/Databox/Loading';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';

import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { getDemoDashboardService } from '../../../../../../services/DemoDashboard';
import { AdInsights } from '../../../../Databoxes/AdInsights';
import { AccountContext } from '../../accountContext';

const AdInsightsContainer = ({ platform }) => {
  const { currencyCode } = useContext(AccountContext);
  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'ADS_INSIGHTS',
    }),
    []
  );

  const [state] = useDataFetcher(
    [],
    getDemoDashboardService(platform),
    memoizedArgs
  );

  return (
    <Databox>
      <DataboxHeading
        title='Ad Insights'
        info='Know the progress of the ad campaigns of your account.'
      />
      <FetchingDisplay
        isError={state.isError}
        isLoading={state.isLoading}
        LoadingElement={<Loading />}
        ErrorElement={<ErrorDisplay />}
        SuccessElement={
          <AdInsights data={state.data} currencyCode={currencyCode} />
        }
      />
    </Databox>
  );
};

export { AdInsightsContainer };
