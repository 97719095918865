import React, { useRef, useEffect } from 'react';

// MUI imports
import {
  Typography,
  InputLabel,
  Select,
  Autocomplete,
  TextField,
  Popper,
  // useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

// Styled component import
import {
  StyledFormControl,
  StyledMenuItem,
} from '../StyledComponents/StyledImportFileForm';

const Placeholder = ({ children }) => (
  <div className='placeholder'>{children}</div>
);

const DynamicSelectBox = ({
  name,
  value,
  inputTitle,
  placeholderText,
  selectedItem,
  itemImage,
  className,
  handleChange,
  handleBlur,
  errorColor = 'transparent',
  error,
  disabled,
  sx = { position: 'relative', color: '#3d4955' },
  custmiseBox,
  index,
}) => {
  const hasError = error && error !== '';
  const listboxRef = useRef(null);

  useEffect(() => {
    if (listboxRef.current && value) {
      const selectedOption = listboxRef.current.querySelector(
        `[data-value="${value}"]`
      );

      if (selectedOption) {
        selectedOption.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [value]);
  return (
    <StyledFormControl
      variant='standard'
      hiddenLabel
      className={className}
      sx={{
        width: '100%',
        '&.MuiFormControl-root': {
          '&:hover': {
            '&.MuiInputBase-root:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline':
              {
                borderColor: hasError ? errorColor : '#0096ff',
              },
          },
          '& .Mui-disabled': {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      <InputLabel shrink={false} sx={sx}>
        {inputTitle}
      </InputLabel>
      {custmiseBox ? (
        <Autocomplete
          disableClearable
          className={`importDropdown ${hasError ? 'hasError' : ''}`}
          options={selectedItem}
          getOptionLabel={(option) => option.title || ''}
          value={selectedItem.find((item) => item.value === value) || null}
          onChange={(event, newValue) => {
            handleChange({
              target: { name, value: newValue ? newValue.value : '' },
            });
          }}
          onBlur={handleBlur}
          disabled={disabled}
          autoComplete
          includeInputInList
          disablePortal
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              variant='outlined'
              placeholder={placeholderText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment?.props.children?.[0]}
                    <KeyboardArrowDown
                      onClick={() =>
                        params.InputProps.endAdornment.props.children[1]?.props.onClick()
                      }
                      size='small '
                      sx={{
                        cursor: 'pointer !important',
                        color: '#b0b0b0 !important',
                        fontSize: '20px !important',
                      }}
                    />
                  </>
                ),
              }}
              sx={{
                '& .inputRoot.MuiInputBase-root': {
                  padding: 'none !important',
                },
                '& .MuiOutlinedInput-root': {
                  borderColor: hasError ? errorColor : '',
                  padding: '0px 16px !important',
                  height: '40px !important',
                  '&:hover:not(.Mui-disabled):not(.Mui-error)': {
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: hasError ? errorColor : '#0096ff',
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    top: '-4px',
                    bottom: '4px',
                    padding: '0px !important',
                    border: '0px',
                  },
                },
              }}
            />
          )}
          // eslint-disable-next-line react/no-unstable-nested-components
          PopperComponent={(popperProps) => (
            <Popper
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...popperProps}
              sx={{
              
                position: 'fixed !important',
                zIndex: 999999999,
              }}
              placement='auto'
            />
          )}
          ListboxProps={{
            ref: listboxRef,
            style: {
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              backgroundColor: 'white',
              position: 'relative',
              borderRadius: '4px',
              },
          }}
          renderOption={(props, option) => (
            <StyledMenuItem
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              key={option.value}
              value={option.value}
              className='selectMenu'
              sx={{
                backgroundColor: option.value === value ? '#e8f4f8' : 'inherit',
              }}
            >
              {option.title}
            </StyledMenuItem>
          )}
          PopperProps={{
            style: {
              zIndex: 999999999,
            },
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 10],
                },
              },
            ],
          }}
          PaperProps={{
            // sx: {
            //   zIndex: 99999999,
            //   position: 'absolute',
            // },
          }}
        />
      ) : (
        <Select
          variant='outlined'
          autoComplete='off'
          displayEmpty
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          className={`importDropdown ${hasError ? 'hasError' : ''}`}
          disabled={disabled}
          IconComponent={ExpandMoreIcon}
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          renderValue={
            value === ''
              ? () => <Placeholder>{placeholderText}</Placeholder>
              : () => selectedItem.find((item) => item.value === value)?.title
          }
          inputProps={{ 'aria-label': 'Without label' }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            PaperProps: {
              sx: {
                marginTop: '15px',
                maxHeight: '200px',
              },
            },
          }}
          // sx={{
          //   '&.MuiOutlinedInput-root': {
          //     borderColor: hasError ? errorColor : '',
          //     '&.MuiSelect-root': {
          //       '&:hover:not(.Mui-disabled):not(.Mui-error)': {
          //         '.MuiOutlinedInput-notchedOutline': {
          //           borderColor: hasError ? errorColor : '#0096ff',
          //         },
          //       },
          //       '&.Mui-focused': {
          //         '.MuiOutlinedInput-notchedOutline': {
          //           borderColor: hasError ? errorColor : '#0096ff',
          //         },
          //       },
          //     },
          //     '&:hover:not(.Mui-disabled):not(.Mui-error)': {
          //       '.MuiOutlinedInput-notchedOutline': {
          //         borderColor: hasError ? errorColor : '#0096ff',
          //       },
          //     },
          //     '&.Mui-focused': {
          //       '.MuiOutlinedInput-notchedOutline': {
          //         borderColor: hasError ? errorColor : '#0096ff',
          //       },
          //     },
          //   },
          // }}
        >
          {selectedItem?.map((item) => (
            <StyledMenuItem
              key={item.value}
              value={item.value}
              className='selectMenu'
            >
              {itemImage !== undefined ? (
                <img
                  src={itemImage[item.value]}
                  alt='optionImage'
                  height='20'
                  width='20'
                  style={{ marginRight: '5px' }}
                />
              ) : null}
              {item.title}
            </StyledMenuItem>
          ))}
        </Select>
      )}
      {error && (
        <Typography
          variant='smallerRegular'
          color='error'
          sx={
            index
              ? { marginLeft: '-40px' }
              : {
                  marginLeft: 'none',
                }
          }
        >
          {index ? error.props.children[index] : error}
        </Typography>
      )}
    </StyledFormControl>
  );
};

export { DynamicSelectBox };
