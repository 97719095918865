import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// MUI imports
import { Button, Container } from '@mui/material';

// Internal imports
import { TitleCard } from '../FeedManagement/Components/GlobalComponents/TitlePaper';
import { FeedsQualityTable } from '../FeedManagement/Components/OtherComponents/FeedsQualityTable';
import { CTABox } from '../Dashboards/Pages/DemoDashboard/CTABox'
import { GoogleAdsLogo, FacebookLogo, InstagramLogo } from '../../constants/endpoints/assests/logos';
import { Tips } from './Tips';
import { Joyride } from './DemoJoyride';

// Styled imports
import { StyledPaper } from '../FeedManagement/PageViewComponents/StyledMainBox';




const quality = {
    channel_fields: [
        {
            name: 'shipping.price',
            data_type: 'parent',
            priority: 0,
            value: 'g:shipping.price',
            mapped_to: 459,
        },
        {
            name: 'shipping.country',
            data_type: 'parent',
            priority: 0,
            value: 'g:shipping.country.service',
            mapped_to: 460,
        },
        {
            name: 'availability',
            data_type: 'string',
            priority: 0,
            value: 'g:availability',
            mapped_to: null,
        },
        {
            name: 'brand',
            data_type: 'string',
            priority: 0,
            value: 'g:brand',
            mapped_to: 448,
        },
        {
            name: 'condition',
            data_type: 'string',
            priority: 0,
            value: 'g:condition',
            mapped_to: null,
        },
        {
            name: 'gender',
            data_type: 'string',
            priority: 2,
            value: 'g:gender',
            mapped_to: 450,
        },
        {
            name: 'id',
            data_type: 'string',
            priority: 0,
            value: 'g:id',
            mapped_to: 451,
        },
        {
            name: 'image_link',
            data_type: 'string',
            priority: 0,
            value: 'g:image_link',
            mapped_to: 452,
        },
        {
            name: 'item_group_id',
            data_type: 'string',
            priority: 1,
            value: 'g:item_group_id',
            mapped_to: null,
        },
        {
            name: 'price',
            data_type: 'string',
            priority: 2,
            value: 'g:price',
            mapped_to: null,
        },
        {
            name: 'shipping',
            data_type: 'parent',
            priority: 0,
            value: 'g:shipping.min_handling_time',
            mapped_to: 455,
        },
        {
            name: 'description',
            data_type: 'string',
            priority: 0,
            value: 'description',
            mapped_to: 444,
        },
        {
            name: 'additional_image_link',
            data_type: 'string',
            priority: 1,
            value: 'g:additional_image_link',
            mapped_to: 445,
        },
        {
            name: 'age_group',
            data_type: 'string',
            priority: 2,
            value: 'g:age_group',
            mapped_to: null,
        },
        {
            name: 'shipping_weight',
            data_type: 'string',
            priority: 2,
            value: 'g:shipping_weight',
            mapped_to: null,
        },
        {
            name: 'link',
            data_type: 'string',
            priority: 0,
            value: 'link',
            mapped_to: null,
        },
        {
            name: 'title',
            data_type: 'string',
            priority: 0,
            value: 'title',
            mapped_to: null,
        },
    ],
    project_fields: [
        {
            pkid: 451,
            name: 'id',
            data_type: 1,
            is_list: false,
            imported_field: 2934,
        },
        {
            pkid: 454,
            name: 'price',
            data_type: 1,
            is_list: false,
            imported_field: 2935,
        },
        {
            pkid: 458,
            name: 'title',
            data_type: 1,
            is_list: false,
            imported_field: 2936,
        },
        {
            pkid: 459,
            name: 'shipping.price',
            data_type: 0,
            is_list: false,
            imported_field: 2937,
        },
        {
            pkid: 460,
            name: 'shipping.country',
            data_type: 0,
            is_list: false,
            imported_field: 2938,
        },
        // {
        //     pkid: 0,
        //     name: 'id',
        //     data_type: 1,
        //     is_list: false,
        //     imported_field: 2926,
        // },
        {
            pkid: 452,
            name: 'image_link',
            data_type: 1,
            is_list: false,
            imported_field: 2927,
        },
        {
            pkid: 453,
            name: 'item_group_id',
            data_type: 1,
            is_list: false,
            imported_field: 2928,
        },
        // {
        //     pkid: 454,
        //     name: 'price',
        //     data_type: 1,
        //     is_list: false,
        //     imported_field: 2929,
        // },
        {
            pkid: 455,
            name: 'shipping',
            data_type: 0,
            is_list: false,
            imported_field: 2930,
        },
        {
            pkid: 456,
            name: 'shipping_weight',
            data_type: 1,
            is_list: false,
            imported_field: 2931,
        },
        {
            pkid: 457,
            name: 'link',
            data_type: 1,
            is_list: false,
            imported_field: 2932,
        },
        // {
        //     pkid: 458,
        //     name: 'title',
        //     data_type: 1,
        //     is_list: false,
        //     imported_field: 2933,
        // },
        {
            pkid: 444,
            name: 'description',
            data_type: 1,
            is_list: false,
            imported_field: 2919,
        },
        {
            pkid: 445,
            name: 'additional_image_link',
            data_type: 1,
            is_list: false,
            imported_field: 2920,
        },
        {
            pkid: 446,
            name: 'age_group',
            data_type: 1,
            is_list: false,
            imported_field: 2921,
        },
        {
            pkid: 447,
            name: 'availability',
            data_type: 1,
            is_list: false,
            imported_field: 2922,
        },
        {
            pkid: 448,
            name: 'brand',
            data_type: 1,
            is_list: false,
            imported_field: 2923,
        },
        {
            pkid: 449,
            name: 'condition',
            data_type: 1,
            is_list: false,
            imported_field: 2924,
        },
        {
            pkid: 450,
            name: 'gender',
            data_type: 1,
            is_list: false,
            imported_field: 2925,
        },
    ],
};

const FeedManagementDemoDashboard = () => {

    const [run, setRun] = useState(false);
    const [stepIndexState, setStepIndexState] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const location = useLocation();
    const fetchedData = location.state?.fetchedData;

    useEffect(() => {
        setTimeout(() => {
            if (window.localStorage.getItem('guideHasRun') !== 'true') {
                setRun(true);
                window.localStorage.setItem('guideHasRun', 'true');
            }
        }, 3000);
    }, []);

    return (
        <>
            <Joyride
                run={run}
                className='reactJoy'
                setRun={setRun}
                stepIndexState={stepIndexState}
                setStepIndexState={setStepIndexState}
            />
            <Button
                sx={{
                    position: 'fixed',
                    zIndex: '999',
                    bottom: '8px',
                    right: '12px',
                    padding: '5px 15px',
                    '@media screen and (max-width: 991px)': {
                        display: 'none',
                    }
                }}
                variant='contained'
                onClick={() => {
                    setRun(true);
                    setStepIndexState(0);
                }}
            >
                Start Tour
            </Button>

            <CTABox className='startNowQuality' ctaText='Get optimal feed score today. Create a free account' />
            <Container maxWidth='xxl'>
                <>
                    <TitleCard title='Improve your Ad Results for' imgSrc1={GoogleAdsLogo} imgSrc2={FacebookLogo} imgSrc3={InstagramLogo} />
                    <StyledPaper sx={{ padding: '15px' }}>
                        {fetchedData ? (
                            <FeedsQualityTable explanation="Create a product feed that matches your marketing platform standards. Use our recommended fields option to make your ads stand out on"
                                imageSrc1={GoogleAdsLogo}
                                text1="Google,"
                                imageSrc2={FacebookLogo}
                                text2="Facebook and"
                                imageSrc3={InstagramLogo}
                                text3="Instagram Ads. Make sure your products have optional feed score."
                                classNameInternalField="classNameInternalField"
                                tooltipClass="tooltipClass"
                                qualityScoreClass="qualityScoreClass"
                                channelFields={fetchedData?.channel_fields}
                                internalFields={fetchedData?.project_fields}
                                channelInfo='GOOGLE_SHOPPING'
                            />
                        ) : (

                            <FeedsQualityTable
                                explanation="Create a product feed that matches your marketing platform standards. Use our recommended fields option to make your ads stand out on"
                                imageSrc1={GoogleAdsLogo}
                                text1="Google,"
                                imageSrc2={FacebookLogo}
                                text2="Facebook and"
                                imageSrc3={InstagramLogo}
                                text3="Instagram Ads. Make sure your products have optional feed score."
                                classNameInternalField="classNameInternalField"
                                tooltipClass="tooltipClass"
                                qualityScoreClass="qualityScoreClass"
                                channelFields={quality?.channel_fields}
                                internalFields={quality?.project_fields}
                                channelInfo='GOOGLE_SHOPPING'
                            />
                        )}

                    </StyledPaper>
                </>

            </Container>
            <Tips />
        </>
    );
};


export { FeedManagementDemoDashboard };