import React, { useReducer } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Typography, Box, CircularProgress } from '@mui/material';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { fetchingStatusReducer } from './reducers/fetchingStatusReducer';
import { UserReducer } from './reducers/UserReducer';
import { Routers } from './routes/Routers';
import { useAuthAxios } from './utils/useAuthAxios';
import './App.scss';
import { BaseProvider } from './context/BaseProvider';
import { useLogin } from './Hooks/useLogin';
import { store, persistor } from './pages/FeedManagement/Redux/store';
import { LocalStorageManager } from './components/LocalStorageManager';

const initialState = {
  isLoggedIn: false,
  user: {
    username: '',
    email: '',
    internalUser: false,
    invitationCount: 0,
    selected_dashboard_date: {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      )
        .toISOString() //  toISOString()  method converts the date object into a string representation of the date & time
        .split('T')[0],
      endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0)
        .toISOString()
        .split('T')[0],
    },
  },
  accountList: null,
  accountListFetchingStatus: 'PREFETCH',
  account: null,
  company: null,
  companyList: null,
  companies: [],
  accessToken: '',
  platformCreds: [],
};

const App = () => {
  const [state, dispatch] = useReducer(UserReducer, initialState);
  const [fetchingState, fetchingStateDispatch] = useReducer(
    fetchingStatusReducer,
    { loggingIn: true }
  );

  useAuthAxios(dispatch);
  useLogin(state, dispatch, fetchingState, fetchingStateDispatch);

  return (
    <BaseProvider
      userInfo={state}
      userInfoDispatch={dispatch}
      fetchingInfoDispatch={fetchingStateDispatch}
    >
      <LocalStorageManager />
      <BrowserRouter>
        <LoadingPicker loggingIn={fetchingState.loggingIn} />
      </BrowserRouter>
    </BaseProvider>
  );
};

const LoadingPicker = ({ loggingIn }) => {
  if (loggingIn) {
    return (
      <Box className='OuterDiv'>
        <Box className='InnerDiv'>
          <CircularProgress color='primary' />
          <Typography variant='h2' className='typography'>
            Fetching your data
            <div className='stage'>
              <div className='dot-flashing' />
            </div>
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    // wrapped the store around the entire app
    // this allows is to use RTK
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routers />
      </PersistGate>
    </Provider>
  );
};

export { App };
