import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';


// MUI imports
import {
  Box,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// Styled component imports
import { StyledMenu, StyledMobileList } from '../StyledComponents/StyledCommon';

const drawerWidth = 240;

const NavigationMenu = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();

  const ActiveLink = {
    projects: {
      path: '/feed-management',
    },
    import: {
      path: '/feed-management/imports/settings',
    },
    importedfile: {
      path: '/feed-management/imports',
    },
    allitems: {
      path: '/feed-management/imports/all-items',
    },
    editmapping: {
      path: '/feed-management/imports/edit-mapping',
    },
    feedsettings: {
      path: '/feed-management/feeds/feed-settings',
    },
    feeds: {
      path: '/feed-management/feeds',
    },
    enhance: {
      path: '/feed-management/feeds/enhance',
    },
    quality: {
      path: '/feed-management/feeds/quality',
    },
    preview: {
      path: '/feed-management/feeds/preview',
    },
    settings: {
      path: '/feed-management/settings',
    },
    action: {
      path: '/feed-management/feeds/enhance/new-action'
    },
    schedule: {
      path: '/feed-management/settings/schedule',
    },
    importSettings: {
      path: '/feed-management/imports/import-settings',
    },
    updateImports: {
      path: '/feed-management/imports/update',
    },
    createFilter: {
      path: '/feed-management/feeds/enhance/new-filter',
    },
    editFilter: {
      path: '/feed-management/feeds/enhance/filter'
    },
    editAction: {
      path: '/feed-management/feeds/enhance/action'
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <StyledMobileList>
        <ListItem disablePadding sx={{ flexDirection: 'column' }}>
          <Link to='.'>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary='Projects' />
            </ListItemButton>
          </Link>
          <Link to='./imports'>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary='Imports' />
            </ListItemButton>
          </Link>
          <Link to='./feeds'>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary='Feeds' />
            </ListItemButton>
          </Link>
          <Link to='./settings'>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary='Settings' />
            </ListItemButton>
          </Link>
        </ListItem>
      </StyledMobileList>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ zIndex: 2 }}>
      <StyledMenu className='navMenu'>
        <Box>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' }, color: '#3d4955' }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{ display: { xs: 'none', sm: 'block' } }}
            className='navLinks'
          >
            <Link
              to='.'
              className={` ${location.pathname === ActiveLink.projects.path ? 'active' : ''
                }`}
            >
              <Button disableRipple className='navItem'>
                Projects
              </Button>
            </Link>

            <NavLink
              to='./imports'
              className={` ${location.pathname === ActiveLink.import.path ||
                location.pathname === ActiveLink.importedfile.path ||
                location.pathname === ActiveLink.allitems.path ||
                location.pathname === ActiveLink.editmapping.path ||
                location.pathname === ActiveLink.importSettings.path ||
                location.pathname === ActiveLink.updateImports.path
                ? 'active'
                : ''
                }`}
            >
              <Button disableRipple className='navItem'>
                Imports
              </Button>
            </NavLink>
            <NavLink
              to='./feeds'
              className={` ${location.pathname === ActiveLink.feeds.path ||
                location.pathname === ActiveLink.feedsettings.path ||
                location.pathname === ActiveLink.enhance.path ||
                location.pathname === ActiveLink.quality.path ||
                location.pathname === ActiveLink.preview.path ||
                location.pathname === ActiveLink.action.path ||
                location.pathname === ActiveLink.createFilter.path ||
                location.pathname === ActiveLink.editFilter.path ||
                location.pathname === ActiveLink.editAction.path
                ? 'active'
                : ''
                }`}
            >
              <Button disableRipple className='navItem'>
                Feeds
              </Button>
            </NavLink>
            <NavLink
              to='./settings'
              className={` ${location.pathname === ActiveLink.settings.path ||
                location.pathname === ActiveLink.schedule.path
                ? 'active'
                : ''
                }`}
            >
              <Button disableRipple className='navItem'>
                Settings
              </Button>
            </NavLink>
          </Box>
        </Box>
      </StyledMenu>
      <Box>
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export { NavigationMenu };
