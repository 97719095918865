// scroll to error sol-1

import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export const ScrollToError = ({
  scrollBehavior = { behavior: 'smooth', block: 'center' },
}) => {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;

  useEffect(() => {
    const el = document.querySelector('.Mui-error, [data-error]');
    (el?.parentElement ?? el)?.scrollIntoView(scrollBehavior);
  }, [submitting]);
  return null;
};

// scroll to error sol-2

// import { useEffect } from 'react';
// import { useFormikContext } from 'formik';

// const getFieldErrorNames = (formikErrors) => {
//   const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
//     Object.keys(obj).forEach((key) => {
//       const value = obj[key];
//       if (!value) return;
//       const nextKey = prefix ? `${prefix}.${key}` : key;
//       if (typeof value === 'object') {
//         transformObjectToDotNotation(value, nextKey, result);
//       } else {
//         result.push(nextKey);
//       }
//     });
//     return result;
//   };
//   return transformObjectToDotNotation(formikErrors);
// };

// export const ScrollToError = ({
//   scrollBehavior = { behavior: 'smooth', block: 'center' },
// }) => {
//   const { submitCount, isValid, errors } = useFormikContext();
//   useEffect(() => {
//     if (isValid) return;
//     const fieldErrorNames = getFieldErrorNames(errors);
//     if (fieldErrorNames.length <= 0) return;
//     const element = document.querySelector(
//       `input[name='${fieldErrorNames[0]}']`
//     );
//     if (!element) return;
//     element.scrollIntoView(scrollBehavior);
//   }, [submitCount]);

//   return null;
// };
