import React, { useContext } from 'react';
import { CreateCompanyForm } from '../../Forms/CreateCompanyForm';
import { sendReducer } from '../../../../../reducers/sendReducer';
import { DataSendingModalBase } from '../../../../../components/DataSendingModalBase';
import { CompanyService } from '../../../../../services/company';
import { UserInfoContext } from '../../../../../context/UserInfoContext';
import { setCompanyList } from '../../../../../utils/userInfoDispatchFunctions';

const defaultState = { status: 'PRESEND' };

const CreateCompanyModal = ({ open, handleCloseModal, reloadFunction }) => {
  const { companyList } = useContext(UserInfoContext);
  const [state, dispatch] = React.useReducer(sendReducer, defaultState);
  const handleSubmit = (name, url, mccId, color) => {
    dispatch({ type: 'SENDING' });
    return CompanyService.createCompany(name, url, mccId, color)
      .then((response) => {
        dispatch({ type: 'SENDSUCCESS', data: response.data });
        setTimeout(() => {
          // trigger company list reload
          setCompanyList(dispatch, [...companyList, response.data]);
          dispatch({ type: 'PRESEND' });
          reloadFunction();
          handleCloseModal();
        }, 1000);
      })
      .catch((error) => {
        dispatch({ type: 'SENDERROR', error: error.data });
        setTimeout(() => {
          handleCloseModal();
          dispatch({ type: 'PRESEND' });
        }, 1000);
      });
  };
  return (
    <DataSendingModalBase
      open={open}
      handleCloseModal={handleCloseModal}
      status={state.status}
      successMessage='Company Created Successfully.'
      errorMessage='Unable to Create Company'
    >
      <CreateCompanyForm
        handleClose={handleCloseModal}
        handleSubmit={handleSubmit}
      />
    </DataSendingModalBase>
  );
};
export { CreateCompanyModal };
