import { Fade, CircularProgress, Box } from '@mui/material';
import React, { useRef } from 'react';
import { EditProfileForm } from '../Form/EditProfileForm';
import { StyledModal, StyledFormModal } from './Styles';
import { CloseButton } from '../../../widgets/CloseButton';
import { DataSendingModalBase } from '../../../components/DataSendingModalBase';
import { AuthService } from '../../../services/auth';
import { useDataFetcher } from '../../../Hooks/useDataFetcher';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { sendReducer } from '../../../reducers/sendReducer';

const defaultState = { status: 'PRESEND' };

const EditProfileModal = ({ open, handleClose }) => {
  const [sendingState, dispatch] = React.useReducer(sendReducer, defaultState);

  const formikRef = useRef();

  const onSubmit = (values, formikHelpers) => {
    dispatch({ type: 'SENDING' });

    AuthService.editProfile(values)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: 'SENDSUCCESS', data: response.data });
          setTimeout(() => {
            dispatch({ type: 'PRESEND' });
            formikHelpers.resetForm();
            handleClose();
          }, 1000);
        }
      })
      .catch((error) => {
        dispatch({ type: 'SENDERROR', error: error.data });
      });
  };

  const [state] = useDataFetcher({}, AuthService.profile, '', open, open);

  return (
    <StyledModal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <StyledFormModal>
          <DataSendingModalBase
            open
            handleCloseModal={handleClose}
            status={sendingState.status}
            successMessage='Profile Updated'
            errorMessage='Unable to Update Profile'
          >
            <FetchingDisplay
              isError={state.isError}
              isLoading={state.isLoading}
              LoadingElement={
                <Box
                  sx={{
                    height: '435px',
                    width: '400px',
                    display: 'grid',
                    placeItems: 'center',
                  }}
                >
                  <CircularProgress />
                </Box>
              }
              ErrorElement={<>Error</>}
              SuccessElement={
                <EditProfileForm
                  state={state}
                  handleSubmit={onSubmit}
                  formikRef={formikRef}
                />
              }
            />
          </DataSendingModalBase>
          <CloseButton onClick={handleClose} />
        </StyledFormModal>
      </Fade>
    </StyledModal>
  );
};

export { EditProfileModal };
