import React from 'react';
import { Stack, Alert, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DoneIcon from '@mui/icons-material/Done';

const ErrorAlert = ({ message }) => (
  <Stack sx={{ width: '100%' }} spacing={1}>
    {message && (
      <Alert
        variant='standardError'
        icon={<ErrorOutlineIcon fontSize='small' />}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          '&.MuiAlert-root': { padding: '0px 16px' },
        }}
      >
        <Typography variant='smallBold'>{message}</Typography>
      </Alert>
    )}
  </Stack>
);

const SuccessAlert = ({ message }) => (
  <Stack sx={{ width: '100%' }} spacing={1}>
    {message && (
      <Alert
        variant='standardSuccess'
        icon={<DoneIcon fontSize='inherit' />}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          '&.MuiAlert-root': { padding: '0px 16px' },
        }}
      >
        <Typography variant='smallBold'>{message}</Typography>
      </Alert>
    )}
  </Stack>
);

export { ErrorAlert, SuccessAlert };

// const ErrorAlert = ({ message }) => {
//   const [open, setOpen] = useState(true);

//   useEffect(() => {
//     setTimeout(() => {
//       setOpen(false);
//     }, 8000);
//   }, [open]);

//   return (
//     open && (
//     <Stack sx={{ width: '100%' }} spacing={1}>
//       {message && (
//         <Alert
//           variant='standardError'
//           icon={<ErrorOutlineIcon fontSize='small' />}
//           sx={{
//             display: 'flex',
//             justifyContent: 'center',
//             '&.MuiAlert-root': { padding: '0px 16px' },
//           }}
//         >
//           <Typography variant='smallBold'>{message}</Typography>
//         </Alert>
//       )}
//     </Stack>
//     )
//   );
// };
