/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const allProjectsRedux = createSlice({
  name: 'project',
  initialState: {
    projectId: '',
    feeds: [],
    files: [],
    projectName: '',
  },
  reducers: {
    setProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    setProjectName(state, action) {
      state.projectName = action.payload;
    },
    setFeeds(state, action) {
      state.feeds = action.payload;
    },
    setFiles(state, action) {
      state.files = action.payload;
    },
    addFeed: (state, action) => {
      state.feeds.push(action.payload);
    },
    clearReduxStore: () => ({
      projectId: '',
      feeds: [],
      files: [],
      projectName: '',
    }),
  },
});

export const {
  setProjectId,
  setFeeds,
  setFiles,
  setProjectName,
  addFeed,
  clearReduxStore,
} = allProjectsRedux.actions;

// eslint-disable-next-line import/no-default-export
export default allProjectsRedux.reducer;
