import React from 'react';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

const StyledCloseButton = styled(CloseIcon)(
  ({ theme }) => `
      position: absolute;
      top: ${theme.spacing(1.5)};
      right: ${theme.spacing(1.5)};
      padding: ${theme.spacing(0.2)};
      cursor: pointer;
      background-color:#EDEDED;
      width:${theme.spacing(2)};
      height:${theme.spacing(2)};
      font-size: 1.2rem;
      border-radius: 100%;
         
        `
);

const CloseButton = ({ onClick }) => (
  <StyledCloseButton fontSize='small' onClick={onClick} />
);

export { CloseButton };
