import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Stack,
} from '@mui/material';
import React, { useState, useContext } from 'react';
import { StyledDateRangePicker } from './styles';
import { UserInfoContext } from '../../context/UserInfoContext';
import { UserInfoDispatchContext } from '../../context/UserInfoDispatchContext';
import { useUpdateDateRangeMutation } from '../../api/RTK/services/user';
import { setUser } from '../../utils/userInfoDispatchFunctions';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const formattedDate = (dateRange) => ({
  startDate: new Date(
    dateRange.startDate.getTime() -
      dateRange.startDate.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split('T')[0],
  endDate: new Date(
    dateRange.endDate.getTime() - dateRange.endDate.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split('T')[0],
});

const DateComponent = ({ onClose }) => {
  const { user } = useContext(UserInfoContext);

  const dispatch = useContext(UserInfoDispatchContext);
  const [updateDateRange, { isLoading }] = useUpdateDateRangeMutation();
  const [localDateRange, setLocalDateRange] = useState([
    {
      startDate: new Date(user.selected_dashboard_date.startDate),
      endDate: new Date(user.selected_dashboard_date.endDate),
      key: 'selection',
    },
  ]);

  const setDateRange = () => {
    const formattedDateRange = formattedDate({
      startDate: localDateRange[0].startDate,
      endDate: localDateRange[0].endDate,
    });
    updateDateRange({
      dateRange: formattedDateRange,
    })
      .unwrap()
      .then(() => {
        onClose();
        setUser(dispatch, {
          ...user,
          selected_dashboard_date: formattedDateRange,
        });
      });
  };
  return (
    <Stack direction='column' sx={{ position: 'relative' }}>
      <Backdrop sx={{ zIndex: 1 }} open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <StyledDateRangePicker
        ranges={localDateRange}
        onChange={(item) => {
          setLocalDateRange([item.selection]);
        }}
        maxDate={new Date()}
      />
      <Divider variant='middle' sx={{ borderColor: '#eff2f7' }} />
      <Stack direction='row' justifyContent='flex-end' spacing={2} p='10px'>
        <Button variant='outlined' type='button' onClick={onClose} fullWidth>
          Cancel
        </Button>
        <Button
          variant='contained'
          type='button'
          onClick={setDateRange}
          fullWidth
        >
          Apply
        </Button>
      </Stack>
    </Stack>
  );
};

export { DateComponent };
