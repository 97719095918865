import React from 'react';
import ReactDOM from 'react-dom';
import 'react-grid-layout/css/styles.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-resizable/css/styles.css';
import { ThemeProvider, CssBaseline } from '@mui/material';

import { theme } from './Maintheme';

import { App } from './App';

import { reportWebVitals } from './reportWebVitals';

ReactDOM.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  // </React.StrictMode>
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
