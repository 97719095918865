/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { InfoTooltip } from '../../../../../components/InfoTooltip';

import { RangeSlider, RangeSliderBox } from './StyledRangeSlider';

const RangeSliderComponentMain = ({
  setProductMakingValue,
  setMarketingValue,
  setTaxValue,
  setPackingValue,
  setShippingValue,
  setOtherValue,
  totalRevenue,
  setProductMakingCost,
  productMakingCost,
  setMarketingCost,
  marketingCost,
  setTax,
  tax,
  setPackingCost,
  packingCost,
  setShippingCost,
  shippingCost,
  setOtherCosts,
  otherCosts,
  setTotalProfit,
  totalProfit,
  setProfitPercentage,
  setPOAS,
}) => {
  const handleChangeProductCost = (event, newValue) => {
    setProductMakingCost(newValue);
  };

  const handleChangeMarketing = (event, newValue) => {
    setMarketingCost(newValue);
  };

  const handleChangeTax = (event, newValue) => {
    setTax(newValue);
  };

  const handleChangePacking = (event, newValue) => {
    setPackingCost(newValue);
  };

  const handleChangeShiping = (event, newValue) => {
    setShippingCost(newValue);
  };

  const handleChangeOther = (event, newValue) => {
    setOtherCosts(newValue);
  };

  const calculatedProductMakingCost = (
    (productMakingCost / 100) *
    parseFloat(totalRevenue)
  ).toFixed(0);

  const calculatedMarketingCost = (
    (marketingCost / 100) *
    parseFloat(totalRevenue)
  ).toFixed(0);

  const calculatedTax = ((tax / 100) * parseFloat(totalRevenue)).toFixed(0);

  const calculatedPackingCost = (
    (packingCost / 100) *
    parseFloat(totalRevenue)
  ).toFixed(0);

  const calculatedShippingCost = (
    (shippingCost / 100) *
    parseFloat(totalRevenue)
  ).toFixed(0);

  const calculatedOtherCosts = (
    (otherCosts / 100) *
    parseFloat(totalRevenue)
  ).toFixed(0);

  useEffect(() => {
    setProductMakingValue(calculatedProductMakingCost);
    setMarketingValue(calculatedMarketingCost);
    setTaxValue(calculatedTax);
    setPackingValue(calculatedPackingCost);
    setShippingValue(calculatedShippingCost);
    setOtherValue(calculatedOtherCosts);

    const totalSpend =
      parseFloat(calculatedProductMakingCost) +
      parseFloat(calculatedMarketingCost) +
      parseFloat(calculatedTax) +
      parseFloat(calculatedPackingCost) +
      parseFloat(calculatedShippingCost) +
      parseFloat(calculatedOtherCosts);
    setTotalProfit(parseFloat(totalRevenue) - parseFloat(totalSpend));

    setProfitPercentage(
      ((parseFloat(totalRevenue) / parseFloat(totalSpend)) * 100 - 100).toFixed(
        0
      )
    );

    const POAS = (
      parseFloat(totalProfit) / parseFloat(calculatedMarketingCost)
    ).toFixed(2);
    setPOAS(POAS);
  }, [
    totalProfit,
    calculatedProductMakingCost,
    calculatedMarketingCost,
    calculatedTax,
    calculatedPackingCost,
    calculatedShippingCost,
    calculatedOtherCosts,
    totalRevenue,
  ]);

  return (
    <Grid container>
      <Grid item xs={12} md={6} className='sliderBox sliderBox1'>
        <RangeSliderLabel
          propsLabel='Product Making Cost'
          propsTooltipLabel='Money spent on making your product(s).'
        />
        <RangeSliderNew
          propsValue={
            productMakingCost ? productMakingCost.toLocaleString() : 0
          }
          propsOnChange={handleChangeProductCost}
          propsContent={
            isNaN(calculatedProductMakingCost)
              ? 0
              : calculatedProductMakingCost.toLocaleString()
          }
          propsInputValue={totalRevenue}
        />
      </Grid>
      <Grid item xs={12} md={6} className='sliderBox sliderBox1'>
        <RangeSliderLabel
          propsLabel='Marketing Cost'
          propsTooltipLabel='Total amount spent on marketing your product(s).'
        />
        <RangeSliderNew
          propsValue={marketingCost ? marketingCost.toLocaleString() : 0}
          propsOnChange={handleChangeMarketing}
          propsContent={
            isNaN(calculatedMarketingCost)
              ? 0
              : calculatedMarketingCost.toLocaleString()
          }
          propsInputValue={totalRevenue}
        />
      </Grid>
      <Grid item xs={12} md={6} className='sliderBox sliderBox1'>
        <RangeSliderLabel
          propsLabel='Tax'
          propsTooltipLabel='Amount spent on tax.'
        />
        <RangeSliderNew
          propsValue={tax || 0}
          propsOnChange={handleChangeTax}
          propsContent={isNaN(calculatedTax) ? 0 : calculatedTax}
          propsInputValue={totalRevenue}
        />
      </Grid>
      <Grid item xs={12} md={6} className='sliderBox sliderBox1'>
        <RangeSliderLabel
          propsLabel='Packing Cost'
          propsTooltipLabel='Total amount spent on packaging.'
        />
        <RangeSliderNew
          propsValue={packingCost || 0}
          propsOnChange={handleChangePacking}
          propsContent={
            isNaN(calculatedPackingCost) ? 0 : calculatedPackingCost
          }
          propsInputValue={totalRevenue}
        />
      </Grid>
      <Grid item xs={12} md={6} className='sliderBox sliderBox1'>
        <RangeSliderLabel
          propsLabel='Shipping Cost'
          propsTooltipLabel='Total money spent on shipping.'
        />
        <RangeSliderNew
          propsValue={shippingCost || 0}
          propsOnChange={handleChangeShiping}
          propsContent={
            isNaN(calculatedShippingCost) ? 0 : calculatedShippingCost
          }
          propsInputValue={totalRevenue}
        />
      </Grid>
      <Grid item xs={12} md={6} className='sliderBox sliderBox1'>
        <RangeSliderLabel
          propsLabel='Other Costs'
          propsTooltipLabel='Money spent on miscellaneous expenses, if any.'
        />
        <RangeSliderNew
          propsValue={otherCosts || 0}
          propsOnChange={handleChangeOther}
          propsContent={isNaN(calculatedOtherCosts) ? 0 : calculatedOtherCosts}
          propsInputValue={totalRevenue || 0}
        />
      </Grid>
    </Grid>
  );
};

const RangeSliderNew = ({
  propsValue,
  propsOnChange,
  propsContent,
  propsInputValue,
}) => {
  const valueLabelFormat = (props) => {
    const units = ['%'];
    const scaledValue = props;
    return `${scaledValue} ${units}`;
  };

  return (
    <RangeSliderBox display='flex' alignItems='center'>
      <span className='spanStyle'>0</span>
      <RangeSlider
        value={Number(propsValue)}
        onChange={propsOnChange}
        min={0}
        max={100}
        defaultValue={8}
        size='small'
        valueLabelDisplay='on'
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        sx={{
          '& .MuiSlider-thumb::before': {
            content: `'${propsContent}'`,
          },
        }}
      />
      <span className='spanStyle'>{propsInputValue.toLocaleString()}</span>
    </RangeSliderBox>
  );
};

const RangeSliderLabel = ({ propsLabel, propsTooltipLabel }) => (
  <Box display='flex' alignItems='center' className='sliderLabel'>
    <Typography variant='smallMedium' sx={{ mr: 1 }}>
      {propsLabel}
    </Typography>
    <InfoTooltip title={propsTooltipLabel} />
  </Box>
);

export { RangeSliderComponentMain };
