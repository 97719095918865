import * as React from 'react';
import { useContext } from 'react';
import { sendReducer } from '../../../../../reducers/sendReducer';
import { DataSendingModalBase } from '../../../../../components/DataSendingModalBase';
import { EditCompanyForm } from '../../Forms/EditCompanyForm';
import { CompanyService } from '../../../../../services/company';
import { UserInfoContext } from '../../../../../context/UserInfoContext';
import { setCompanyList } from '../../../../../utils/userInfoDispatchFunctions';
import { UserInfoDispatchContext } from '../../../../../context/UserInfoDispatchContext';

const defaultState = { status: 'PRESEND' };

const EditCompanyModal = ({
  open,
  handleCloseModal,
  name,
  id,
  color,
  reloadFunction = null,
}) => {
  const { companyList } = useContext(UserInfoContext);
  const userInfoDispatch = useContext(UserInfoDispatchContext);
  const [state, dispatch] = React.useReducer(sendReducer, defaultState);

  const handleSubmit = (newName, newColor) => {
    dispatch({ type: 'SENDING' });
    CompanyService.editCompany(id, newName, newColor)
      .then((response) => {
        dispatch({ type: 'SENDSUCCESS', data: response.data });
        setCompanyList(
          userInfoDispatch,
          companyList.map((company) => {
            if (company.id === response.data.id) {
              return { ...company, ...response.data };
            }
            return company;
          })
        );
        reloadFunction();
        setTimeout(() => {
          dispatch({ type: 'PRESEND' });
          handleCloseModal();
          // trigger account list reload
        }, 1000);
      })
      .catch((error) => {
        dispatch({ type: 'SENDERROR', error: error.data });
        setTimeout(() => {
          handleCloseModal();
          dispatch({ type: 'PRESEND' });
        }, 1000);
      });
  };

  return (
    <DataSendingModalBase
      open={open}
      handleCloseModal={handleCloseModal}
      status={state.status}
      successMessage='Saved'
      errorMessage='Not Saved'
    >
      <EditCompanyForm
        handleClose={handleCloseModal}
        handleSubmit={handleSubmit}
        initialValues={{ companyName: name, color }}
      />
    </DataSendingModalBase>
  );
};

export { EditCompanyModal };
