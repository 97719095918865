import { openInstance } from '../api/open.instance';
import { apiBaseUrl } from '../constants/api';
import { authTokenRefreshURI } from '../constants/endpoints';

// returs a function which returns refreshed accessToken
const useRefreshToken = () => {
  const refresh = () =>
    openInstance
      .post(`${apiBaseUrl}${authTokenRefreshURI}`, {
        withCredentials: true,
      })
      .then((response) => response.data.access);

  return refresh;
};

export { useRefreshToken };
