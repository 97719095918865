import styled from '@emotion/styled';
// eslint-disable-next-line import/no-extraneous-dependencies
import isPropValid from '@emotion/is-prop-valid';

const CustomSwitchRoot = styled.span`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 72px;
  height: 30px;
  background: #eeeeee;
  border-radius: 15px;
  cursor: pointer;
  & .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
`;

const CustomSwitchTrack = styled.span`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const CustomSwitchThumb = styled('span', {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'checked',
})`
  width: 34px;
  height: 26px;
  border-radius: 13px;
  background-color: #fff;
  position: relative;
  margin: 2px;
  transition: all 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #0096ff;

  transform: ${(props) =>
    props.checked ? 'translateX(34px)' : 'translateX(0px'};
`;

const CustomSwitchInput = styled.input`
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
`;

export {
  CustomSwitchRoot,
  CustomSwitchTrack,
  CustomSwitchThumb,
  CustomSwitchInput,
};

