/* eslint-disable no-nested-ternary */
// react imports
import React, { useContext, useState, useEffect } from 'react';

// third party imports
import {
  Typography,
  Stack,
  Drawer,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@mui/material';
import { useLocation } from 'react-router-dom';

// Component imports
import { ArrowBackRounded, ExpandMoreRounded } from '@mui/icons-material';
import { SidebarLink } from './SidebarLink';
import { EditProfileModal } from '../../../pages/Forms/Modal/EditProfileModal';
import { SidebarButton } from './SidebarLink/button';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { UserInfoDispatchContext } from '../../../context/UserInfoDispatchContext';
import { userLogout } from '../../../utils/userInfoDispatchFunctions';
import { IconDecider } from '../../../pages/UserManagement/Components/IconDecider';

const SidebarProfile = ({ toggleDrawer }) => {
  const { user, companyList } = useContext(UserInfoContext);
  const dispatch = useContext(UserInfoDispatchContext);
  const [isProfileClicked, setIsProfileClicked] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === '/details' ||
      location.pathname === '/edit-profile' ||
      location.pathname === '/invites-page'
    ) {
      setIsProfileClicked(true);
    } else setIsProfileClicked(false);
  }, [location]);

  return (
    <Accordion elevation={0} variant='sidebar'>
      <AccordionSummary
        expandIcon={
          <ExpandMoreRounded
            sx={{
              color: isProfileClicked ? '#0096FF' : 'inherit',
            }}
          />
        }
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography
          variant='bodyMedium'
          sx={{
            color: isProfileClicked ? '#0096FF' : 'inherit',
          }}
        >
          Profile
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box component='ul' sx={{ listStyle: 'none' }}>
          <>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='flex-start'
              padding='0rem 1.5rem 0rem 1rem'
              sx={{ cursor: 'default' }}
            >
              <IconDecider
                row={user}
                fill={
                  user.projectType === 'AGENCY' &&
                  ['OWNER', 'ADMIN', 'COLLABORATOR'].includes(user.role)
                    ? '#FF823C'
                    : '#0096FF'
                }
              />
              <Box>
                <Typography variant='h4'>{user.username}</Typography>
                {companyList.length > 0 ? (
                  <Typography color='text.secondary' variant='smallMedium'>
                    {user.projectName}
                  </Typography>
                ) : null}
              </Box>
            </Stack>
            <SidebarLink
              text={
                user.projectType === 'AGENCY' &&
                ['OWNER', 'ADMIN', 'COLLABORATOR'].includes(user.role)
                  ? 'Agency Details'
                  : 'Company Details'
              }
              to='/details'
            />
            <SidebarLink text='Edit Profile' to='/edit-profile' />
            {user.role !== 'OWNER' && (
              <SidebarLink
                text='Invitations'
                to='/invites-page'
                badge={user.invitationCount > 0}
                number={user.invitationCount}
              />
            )}
            <SidebarButton
              text='Log Out'
              onClick={() => {
                userLogout(dispatch);
                toggleDrawer();
              }}
            />
          </>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const Sidebar = ({ open, toggleDrawer }) => {
  const { user, isLoggedIn } = useContext(UserInfoContext);
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <Drawer anchor='left' open={open} onClose={() => toggleDrawer(false)}>
      <Box component='nav' sx={{ minWidth: '200px' }}>
        <Box display='flex' justifyContent='end' padding={2}>
          <IconButton onClick={toggleDrawer}>
            <ArrowBackRounded />
          </IconButton>
        </Box>
        <ul>
          {isLoggedIn &&
          user.projectType === 'AGENCY' &&
          ['ADMIN', 'OWNER', 'COLLABORATOR'].includes(user.role) ? (
            <SidebarLink
              to='/companies'
              text='Companies'
              onClick={toggleDrawer}
            />
          ) : null}
          {isLoggedIn ? (
            <SidebarLink
              to='/accounts'
              text='Accounts'
              onClick={toggleDrawer}
            />
          ) : null}
          <SidebarLink
            to={isLoggedIn ? '/dashboard' : '/demo-dashboard'}
            text='Dashboard'
            onClick={toggleDrawer}
          />
          {!isLoggedIn ? (
            <SidebarLink to='/feed-management' text='Feed Management' />
          ) : null}
          {isLoggedIn ? (
            <SidebarLink
              text='Feed Management'
              to='/feed-management'
              onClick={toggleDrawer}
            />
          ) : null}
          {!isLoggedIn ? (
            <Accordion elevation={0} variant='sidebar'>
              <AccordionSummary
                expandIcon={<ExpandMoreRounded />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography variant='bodyMedium'>Tools</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box component='ul' sx={{ listStyle: 'none' }}>
                  <SidebarLink
                    text='Profit Calculator'
                    to='/profit'
                    onClick={toggleDrawer}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          ) : null}
          {isLoggedIn &&
          (user.projectType === 'COMPANY' ||
            (user.projectType === 'AGENCY' &&
              ['SUB_ADMIN', 'SUB_COLLABORATOR'].includes(user.role))) ? (
            <SidebarLink
              to='/users/company'
              text='Users'
              onClick={toggleDrawer}
            />
          ) : (
            <SidebarLink
              to='/users/agency'
              text='Users'
              onClick={toggleDrawer}
            />
          )}
          {isLoggedIn ? (
            <SidebarProfile
              toggleDrawer={toggleDrawer}
              openModal={() => setModalOpen(true)}
            />
          ) : (
            <SidebarLink text='Sign In' to='/signin' />
          )}
        </ul>
      </Box>
      <EditProfileModal
        open={modalOpen}
        handleClose={() => {
          setModalOpen(false);
        }}
      />
    </Drawer>
  );
};

export { Sidebar };
