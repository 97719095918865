import { styled } from '@mui/system';

const IconContainer = styled('span')(
  ({ color, row, column }) => `
  aspect-ratio: 3/7;
  color: ${color};
  display: inline-block;
  height: 100%;
  margin-right: 0.25rem;
  max-width: 100%;
  overflow: hidden;
  grid-row: ${row};
  grid-column: ${column};
  z-index: 1;
  .barGraphIcon {
    fill: ${color};
    height: 100%;
    width: 100%;
    aspect-ratio: 3/7;
  }
`
);

const FractionalIconContainer = styled(IconContainer)(
  ({ fraction, color }) => `
  max-width: calc(100% * ${fraction});
  aspect-ratio: calc(3/7 * ${fraction});
  z-index: 2;
  .barGraphIcon{
    fill: ${color};
    height: 100%;
    // the fractional icon is hidden using it's container which has overflow: hidden and smaller width
    // as a result the overflowing, normally sized icon is only partially visible
    // to make it normally sized it should take width equal to a normally sized container i.e. the current container's width without the fraction
    width: calc(100% / ${fraction});
  }
`
);

export { IconContainer, FractionalIconContainer };
