import React from 'react';
import { MenuItem, Typography, InputLabel } from '@mui/material';
import { StyledWrapper, StyledSelect } from './StyledGoogleAnalytics';
import { currencies } from '../../../../../../POAS/POASDataboxes/POASDemoDashboard/POASCalculator/CurrencyList';

const CurrencySelect = ({ values, handleChange, defaultValue }) => (
  <StyledWrapper display='flex' alignItems='center'>
    <InputLabel
      htmlFor='select-standard'
      sx={{
        '&.MuiInputLabel-root': {
          color: ' rgba(0, 0, 0, .8)',
        },
      }}
    >
      <Typography variant='smallBold'>Currency</Typography>
      <StyledSelect
        labelId='select-standard-label'
        id='select-standard'
        name='currency_code'
        value={values?.currency_code}
        onChange={handleChange}
        defaultValue={defaultValue}
        variant='standard'
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: '250px',
              width: 'auto',
              overflowY: 'scroll',
              '.MuiMenuItem-root': { fontSize: '0.85rem' },
            },
          },
        }}
        sx={{ mt: 0.2, mb: 0.8 }}
      >
        {Object.keys(currencies).map((item) => (
          <MenuItem
            key={currencies[item].code}
            value={currencies[item].code}
            sx={{ px: 1, py: 0.5 }}
          >
            {currencies[item].code}
          </MenuItem>
        ))}
      </StyledSelect>
    </InputLabel>
  </StyledWrapper>
);

export { CurrencySelect };
