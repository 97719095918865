/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector , useDispatch} from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Skeleton,
  Stack,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import { SyledLoadingCircle } from '../Components/StyledComponents/StyledCommon';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { useDataFetcher } from '../../../Hooks/useDataFetcher';
import { feedManagementService } from '../../../services/feedManagement';
import { ActionForm } from './Forms/Action';
import { PageErrors } from '../../../components/PageErrors';
import {
  useDeleteActionMutation,
  useEditActionMutation,
  useGetAffectedItemsQuery,
} from '../../../api/RTK/services/feedFeed';
import { StyledPaper } from './StyledMainBox';
import { DeleteModal } from './Forms/Components/DeleteModal';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { DataSendingModalBaseRTK } from '../../../components/DataSendingModalBaseRTK';
import { AffectedItemsButton } from '../Components/GlobalComponents/AffectedItemButton';
import { updateAffectedItemsData , updateActionId } from '../Redux/CustomizableHeadcells/All-Items/slice';

const EditAction = ({ reloadList }) => {
  const dispatch = useDispatch()
  const { company, user } = useContext(UserInfoContext);
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const location = useLocation();

  const feedId = useSelector((state) => state?.feedSummaryRedux?.feedId);

  const memoizedArgs = useMemo(
    () => ({ companyId: company?.id, feedProjectId: projectSelectId, feedId }),
    []
  );

  const { id } = useParams();

  const memoizedActionArgs = useMemo(
    () => ({
      companyId: company?.id,
      feedProjectId: projectSelectId,
      feedId,
      actionId: id,
    }),
    [company, projectSelectId, id]
  );

  const [state] = useDataFetcher(
    {},
    feedManagementService.getProjectFields,
    memoizedArgs
  );

  const [actionState] = useDataFetcher(
    {},
    feedManagementService.getActionDetails,
    memoizedActionArgs
  );

  const [editAction, { isLoading, isError, isSuccess, reset, error }] =
    useEditActionMutation();

  const errorHandleEditAction = () => {
    if (error && error.status === 403) {
      if (error?.data[0]) {
        return error.data[0].error || 'Same name action exists in other feeds';
      }
    }
    return 'Unable to update action';
  };

  // Query to get affected items count
  const {
    data: affectedItemsData,
    status: affectedItemStatus,
    isLoading: affecttedItemLoading,
  } = useGetAffectedItemsQuery({
    companyId: company?.id,
    projectId: projectSelectId,
    feedId,
    operationType: 'ACTION',
    operationId: id,
  });
  useEffect(() => {
    dispatch(updateActionId(id))
    dispatch(updateAffectedItemsData(affectedItemsData?.action_effected_items?.items))
  },[affectedItemStatus , affectedItemsData , affecttedItemLoading])
  const handleSubmit = (values) => {
    editAction({
      projectId: projectSelectId,
      companyId: company?.id,
      data: { id, ...values },
    });
  };

  return (
    <FetchingDisplay
    isLoading={state.isLoading || actionState.isLoading}
    isError={state.isError || actionState.isError}
    SuccessElement={
      <ErrorBoundary fallback={<PageErrors />}>
        <Container maxWidth='none'>
          <TitleCard title='Edit Action'>
          
            <AffectedItemsButton
             className="animate-border"
              to={{
                pathname: '../feeds/enhance/affected-items',
                state: {
                  data: affectedItemsData?.action_effected_items.items,
                  path: location.pathname,
                },
              }}
              // affecttedItemLoading={affecttedItemLoading || isLoading || !(affectedItemsData && affectedItemStatus === 'fulfilled')}
              affecttedItemLoading={
                affecttedItemLoading ||
                isLoading ||
                (!affectedItemsData && affectedItemStatus !== 'rejected')
              }
              sx={{ marginRight: '24px' }}
              label={
                affectedItemsData && affectedItemStatus === 'fulfilled' ? (
                  affectedItemsData?.action_effected_items?.count.toString()
                ) : affectedItemStatus === 'pending' ? (
                  <CircularProgress
                    color='inherit'
                    disableShrink
                    sx={{
                      zIndex: 2,
                      width: '16px !important',
                      height: '16px !important',
                    }}
                  />
                ) : 
                  <ErrorOutlineIcon sx={{ color: 'white !important', fontSize: '25px' , marginTop:"5px !important" }} />
                
              }
              buttonName='View Affected Items'
            />
            {(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') && (
              <DeleteModal
                heading='Delete Action'
                prompt='Deleting this will permanently remove this action'
                successMessage='Action deleted successfully'
                errorMessage='Unable to delete action'
                deleteHook={useDeleteActionMutation}
                args={{
                  companyId: company.id,
                  projectId: projectSelectId,
                  actionId: actionState.data?.id,
                }}
                reloadList={reloadList}
              />
            )}
          </TitleCard>
          <StyledPaper sx={{ padding: '24px 0px' }}>
            <ActionForm
              fields={state.data?.map((field) => field.name) || []}
              handleSubmit={handleSubmit}
              initialData={actionState?.data}
              btnName='Save'
            />
          </StyledPaper>
        </Container>{' '}
        <DataSendingModalBaseRTK
          open={isLoading || isSuccess || isError}
          handleCloseModal={reset}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          successMessage='Action updated successfully'
          errorMessage={errorHandleEditAction()}
          reset={reset}
        >
          <Box sx={{ height: '200px' }} />
        </DataSendingModalBaseRTK>
      </ErrorBoundary>
    }
    LoadingElement={
      <Container maxWidth='none'>
        <TitleCard title='Edit Action'>
          <Button variant='pillOutlined' sx={{ height: '44px', minWidth: '175px', marginRight: '16px' }}>
            <Skeleton animation="wave" width="100%" />
          </Button>
          <Button variant='pillOutlined' sx={{ height: '44px', minWidth: '175px' }}>
            <Skeleton animation="wave" width="100%" />
          </Button>
        </TitleCard>
        <StyledPaper>
          <Stack direction='row' alignItems='center' gap={3} sx={{ paddingBottom: '16px' }} >
            <Skeleton variant='rectangular' animation="wave" width='100%' height={50} sx={{ borderRadius: '8px' }} />
            <Skeleton variant='rectangular' animation="wave" width='100%' height={50} sx={{ borderRadius: '8px' }} />
          </Stack>
          <Skeleton variant='rectangular' animation="wave" width='100%' height={150} sx={{ borderRadius: '8px', marginBottom: '16px' }} />
          <Skeleton variant='rectangular' animation="wave" width='100%' height={100} sx={{ borderRadius: '8px', marginBottom: '16px' }} />
          <Skeleton variant='rectangular' animation="wave" width='100%' height={100} sx={{ borderRadius: '8px', marginBottom: '16px' }} />
        </StyledPaper>
      </Container>
    }
    ErrorElement={
      <PageErrors
        isServerError
        code={state?.errorInfo?.code || actionState?.errorInfo?.code}
      />
    }
  />
    
   
  );
};

export { EditAction };
