import React from 'react';

// MUI imports
import { Container } from '@mui/material';

// Internal imports
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { EnhanceItemDetails } from '../Components/OtherComponents/EnhanceItemDetails';

// Styled imports
import { StyledPaper } from './StyledMainBox';
import { PageErrors } from '../../../components/PageErrors';

const EnhanceItems = () => {
  const { state: itemDetails } = useLocation();

  return (
    <ErrorBoundary fallback={<PageErrors />}>
      <Container maxWidth='xxl'>
        <TitleCard title='Items - Item Name' />
        <StyledPaper sx={{ padding: '15px 0px' }}>
          <EnhanceItemDetails item={itemDetails} />
        </StyledPaper>
      </Container>
    </ErrorBoundary>
  );
};

export { EnhanceItems };
