import React from 'react';
import { SnackbarContent, Stack, Typography } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const ErrorMessageBox = ({ mainMessage, description }) => (
  <Stack spacing={2} mb={2} justifyContent='flex-start'>
    <SnackbarContent
      message={
        <Stack direction='row' alignItems='flex-start' spacing={1}>
          <ErrorOutlineOutlinedIcon sx={{ color: '#FF823C' }} />
          <Stack direction='column' alignItems='flex-start' spacing={0.5}>
            <Typography variant='smallBold'>{mainMessage}</Typography>
            <Typography variant='smallRegular'>{description}</Typography>
          </Stack>
        </Stack>
      }
      sx={{ backgroundColor: '#fff0e7', color: '#FF823C' }}
    />
  </Stack>
);

export { ErrorMessageBox };
