import * as Yup from 'yup';

const MetaPlatformSchema = Yup.object().shape({
  name: Yup.string()
    .required('Business Name is a required field')
    .min(2, 'Name must be at least 2 characters')
    .max(100, 'Name must not exceed 100 characters'),
  email: Yup.string().email().required('Email is a required field'),
  ads_account_id: Yup.string().required('Ads Account ID is a required field'),
});
const MerchantCenterSchema = Yup.object().shape({
  customer_id: Yup.string().required('Customer ID is a required field'),
});
const GoogleTagManagerSchema = Yup.object().shape({
  customer_id: Yup.string().required(
    'Google Tag Manager ID is a required field'
  ),
});
const GoogleAnalyticsSchema = Yup.object().shape({
  customer_id: Yup.string().required('Google Analytics ID is a required field'),
});
const MccIdFormSchema = Yup.object().shape({
  mcc_id: Yup.string().required('MCC ID is a required field'),
});

const ShopifySchema = Yup.object().shape({
  account_name: Yup.string()
    .required('Account name is a required field')
    .min(2, 'Account name must be at least 2 characters')
    .max(100, 'Account name must not exceed 100 characters'),
  // shop_url: Yup.string()
  //   .url('Please include https:// or http:// ')
  //   .required('Please Enter Shop URL including https:// or http://'),
});

const WooCommerceSchema = Yup.object().shape({
  account_name: Yup.string()
    .required('Name is a required field')
    .min(2, 'Name must be at least 2 characters')
    .max(100, 'Name must not exceed 100 characters'),
  website_url: Yup.string()
    .url('Please include https:// or http:// ')
    .required('Shop URL is a required field'),
  consumer_key: Yup.string()
    .required('Consumer Key is a required field')
    .matches(
      /^ck_[a-zA-Z0-9]{2,}$/,
      'Begin with ck_ and use alphanumerics only.'
    ),
  consumer_secret: Yup.string()
    .required('Consumer Secret is a required field')
    .matches(
      /^cs_[a-zA-Z0-9]{2,}$/,
      'Begin with ck_ and use alphanumerics only.'
    ),
});

export {
  MetaPlatformSchema,
  MerchantCenterSchema,
  GoogleTagManagerSchema,
  GoogleAnalyticsSchema,
  MccIdFormSchema,
  ShopifySchema,
  WooCommerceSchema,
};
