const countOptions = [
  { value: 'first', name: 'first' },
  { value: 'second', name: 'second' },
  { value: 'third', name: 'third' },
  { value: 'fourth', name: 'fourth' },
  { value: 'fifth', name: 'fifth' },
  { value: 'sixth', name: 'sixth' },
  { value: 'seventh', name: 'seventh' },
  { value: 'eigth', name: 'eigth' },
  { value: 'ninth', name: 'ninth' },
  { value: 'tenth', name: 'tenth' },
  { value: 'eleventh', name: 'eleventh' },
  { value: 'twelfth', name: 'twelfth' },
  { value: 'thirteenth', name: 'thirteenth' },
  { value: 'fourteenth', name: 'fourteenth' },
  { value: 'fiftenth', name: 'fiftenth' },
  { value: 'sixteenth', name: 'sixteenth' },
  { value: 'seventeenth', name: 'seventeenth' },
  { value: 'eighteenth', name: 'eighteenth' },
  { value: 'nineteenth', name: 'nineteenth' },
  { value: 'twentieth', name: 'twentieth' },
  { value: 'last', name: 'last' },
  { value: 'second_last', name: 'second_last' },
  { value: 'third_last', name: 'third_last' },
  { value: 'fourth_last', name: 'fourth_last' },
  { value: 'fifth_last', name: 'fifth_last' },
];

const modifyOptions = [
  {
    value: 'CAPITALIZE',
    name: 'capitalize only first character',
  },
  {
    value: 'TITLE',
    name: 'capitalize first character per word',
  },
  {
    value: 'CAMELCASE',
    name: 'capitalize first character per sentence',
  },
  { value: 'LOWERCASE', name: 'lowercase all words' },
  { value: 'UPPERCASE', name: 'uppercase all words' },
  {
    value: 'ONLY_DIGITS',
    name: 'remove all non numeric characters',
  },
  { value: 'REMOVE_DIGITS', name: 'remove all numeric characters' },
  { value: 'REMOVE_LINEBREAKS', name: 'remove linebreaks' },
  {
    value: 'REMOVE_WHITESPACES',
    name: 'remove unnecessary whitespaces',
  },
  { value: 'STRIP_HTML', name: 'remove HTML from text' },
];
export { countOptions, modifyOptions };
