import { authInstance } from '../api/authentication.instance';

const fetchCompanies = () => authInstance.post('/api/accounts/company-metric');

const createCompany = (name, url, mccId, color) =>
  authInstance.post(`/api/accounts/company/`, {
    name,
    website_url: url,
    mcc_id: mccId,
    company_colour: color,
  });

const deleteCompany = (id) =>
  authInstance.delete(`/api/accounts/company/${id}`);

const editCompany = (id, name, color) =>
  authInstance.patch(`/api/accounts/company/${id}`, {
    name,
    company_colour: color,
  });

const createCompanyProject = (company) =>
  authInstance.post('/api/accounts/company-projects', company);

const CompanyService = {
  createCompany,
  fetchCompanies,
  deleteCompany,
  editCompany,
  createCompanyProject,
};

export { CompanyService };
