import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useField, useFormikContext } from 'formik';
import { SelectInput } from '../../../../../../../components/Inputs/SelectInput';
import { setActionForm } from '../../../../../Redux/AddActionFilter/EditActionSlice';

const FieldSelector = ({
  options,
  name,
  valueCallback,
  textCallback,
  methodPath,
  valuePath,
}) => {
  const { setFieldValue, values } = useFormikContext();
  const { projectFields, ...data } = values;

  const [{ value }] = useField(name);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActionForm(data));
  }, [values]);

  // reset method value if the field values changes to or from 'all fields'
  const handleChange = (e) => {
    if (e.target.value === 'all fields' && value !== 'all fields') {
      setFieldValue(methodPath, 'DO_NOTHING');
    } else if (e.target.value !== 'all fields' && value === 'all fields') {
      setFieldValue(methodPath, 'SET_TO_VALUE');
      setFieldValue(valuePath, { value: '' });
    }
  };

  return (
    <SelectInput
      name={name}
      optionValueCallback={valueCallback}
      optionContentCallback={textCallback}
      options={options}
      label='take'
      labelIsAdjacent
      customOnChange={handleChange}
      id={name}
      sx={{
        width: '100%',
        '@media(min-width:800px)': {
          width: 'auto',
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            flex: 'initial',
            width: '300px',
            maxWidth: '100%',
          },
        },
      }}
    />
  );
};

export { FieldSelector };
