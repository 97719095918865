import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  RuleFormulaButton: 'Formula',
};

const RuleFormulaSliceRedux = createSlice({
  name: 'RuleFormulaButton',
  initialState,
  reducers: {
    setRuleFormulaState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.RuleFormulaButton = action.payload;
    },
  },
});

// eslint-disable-next-line import/no-default-export
export default RuleFormulaSliceRedux.reducer;
export const { setRuleFormulaState } = RuleFormulaSliceRedux.actions;
