import React from 'react';
import { Box } from '@mui/material';

const AdminIcon = ({ fill = '#ff823c', isBorder = true, mr = 1 }) => (
  <Box
    sx={{
      border: isBorder ? `1px solid #D4D4D4` : `0px solid #D4D4D4`,
      borderRadius: '100%',
      width: '40px',
      height: '40px',
      display: 'grid',
      placeItems: 'center',
      mr,
      svg: {
        width: '36px',
      },
    }}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28.024'
      height='27.2'
      viewBox='0 0 28.024 27.2'
    >
      <g id='Admin' transform='translate(-2.386 -3.7)'>
        <path
          id='Path_2659'
          data-name='Path 2659'
          d='M16.368,43.54l1.386-1.386a.782.782,0,0,1,.177-.147A7.1,7.1,0,0,0,14.717,39.5a6.575,6.575,0,0,1-7.255,0,7.162,7.162,0,0,0-4.129,4.247A17.409,17.409,0,0,0,2.39,48.17,1.192,1.192,0,0,0,3.6,49.438H14.481V48.082A2,2,0,0,1,16.1,46.106,2.093,2.093,0,0,1,16.368,43.54Z'
          transform='translate(0 -25.242)'
          fill={fill}
        />
        <path
          id='Path_2660'
          data-name='Path 2660'
          d='M19.913,13.727A5.013,5.013,0,1,0,14.9,8.713,5.009,5.009,0,0,0,19.913,13.727Z'
          transform='translate(-8.824)'
          fill={fill}
        />
        <path
          id='Exclusion_1'
          data-name='Exclusion 1'
          d='M7.1,14.2a7.1,7.1,0,1,1,7.1-7.1A7.108,7.108,0,0,1,7.1,14.2Zm.1-4.7h0l2.6,1.4L9.3,8l2.1-2L8.5,5.6,7.2,3,5.9,5.6,3,6,5.1,8l-.5,2.9L7.2,9.5Z'
          transform='translate(16.209 16.7)'
          fill={fill}
        />
      </g>
    </svg>
  </Box>
);

const OwnerIcon = ({ fill = '#ff823c', isBorder = true, mr = 1 }) => (
  <Box
    sx={{
      border: isBorder ? `1px solid #D4D4D4` : `0px solid #D4D4D4`,
      borderRadius: '100%',
      width: '40px',
      height: '40px',
      display: 'grid',
      placeItems: 'center',
      mr,
      svg: {
        width: '36px',
      },
    }}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25.374'
      height='28'
      viewBox='0 0 25.374 28'
    >
      <path
        id='owner'
        d='M30.309,6.218h0a18.131,18.131,0,0,1-9.146-3.187,2.274,2.274,0,0,0-2.921,0A18.487,18.487,0,0,1,9.065,6.218,2.277,2.277,0,0,0,7,8.489C7,25.012,18.3,30.116,18.772,30.323a2.451,2.451,0,0,0,1.829,0c.472-.207,11.772-5.311,11.772-21.834A2.277,2.277,0,0,0,30.309,6.218Zm-10.651,22.1a15.743,15.743,0,0,1-4.426-3.275l.3-2.567A2.8,2.8,0,0,1,18.3,20h2.744a2.782,2.782,0,0,1,2.773,2.478l.3,2.567A14.818,14.818,0,0,1,19.658,28.317Zm.03-10.976a2.567,2.567,0,1,1,2.567-2.567A2.569,2.569,0,0,1,19.687,17.341Zm6.4,5.34-.059-.472A5.04,5.04,0,0,0,23.021,18.2a4.779,4.779,0,1,0-8.114-3.423A4.74,4.74,0,0,0,16.353,18.2a5,5,0,0,0-3.009,4.013l-.059.472C11.131,19.583,9.272,15.01,9.3,8.46A21.613,21.613,0,0,0,19.746,4.772,20.343,20.343,0,0,0,30.1,8.46l.059.059C30.161,15.04,28.273,19.613,26.09,22.681Z'
        transform='translate(-7 -2.5)'
        fill={fill}
      />
    </svg>
  </Box>
);

const CollaboratorIcon = ({ fill = '#ff823c', isBorder = true, mr = 1 }) => (
  <Box
    sx={{
      border: isBorder ? `1px solid #D4D4D4` : `0px solid #D4D4D4`,
      borderRadius: '100%',
      width: '40px',
      height: '40px',
      display: 'grid',
      placeItems: 'center',
      mr,
      svg: {
        width: '36px',
      },
    }}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17.545'
      height='19.938'
      viewBox='0 0 17.545 19.938'
    >
      <g id='Collaborator' transform='translate(-22.26 -15.8)'>
        <g
          id='Group_4897'
          data-name='Group 4897'
          transform='translate(26.47 15.8)'
        >
          <path
            id='Path_2656'
            data-name='Path 2656'
            d='M38.823,25.145a4.673,4.673,0,1,0-4.673-4.67A4.673,4.673,0,0,0,38.823,25.145Z'
            transform='translate(-34.15 -15.8)'
            fill={fill}
          />
        </g>
        <path
          id='Union_5'
          data-name='Union 5'
          d='M7422.451,2466.938h-4.238a1.193,1.193,0,0,1-1.209-1.269,17.35,17.35,0,0,1,.944-4.422,7.157,7.157,0,0,1,4.129-4.247l.052.033.084-.033a6.359,6.359,0,0,0,7.118,0l.086.034.052-.034a7.161,7.161,0,0,1,4.127,4.247,17.346,17.346,0,0,1,.944,4.424,1.129,1.129,0,0,1-.224.777v0h0a1.227,1.227,0,0,1-.982.486Z'
          transform='translate(-7394.741 -2431.2)'
          fill={fill}
        />
      </g>
    </svg>
  </Box>
);

export { AdminIcon, OwnerIcon, CollaboratorIcon };
