/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext, useRef, useEffect } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { ProjectModal } from '../../../../Components/Modals/ProjectModal';
import { EditAccountModal } from '../../../../Components/Modals/EditAccount';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';

const getModalProps = (functionName) => {
  switch (functionName) {
    case 'delete':
      return {
        successMessage: 'Account Deleted',
        errorMessage: 'Account Not Deleted',
        functionName: 'delete',
        prompt: 'Are you sure you want to Delete?',
        confirmButtonText: 'Delete',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      };
    // case 'link':
    //   return {
    //     successMessage: 'Account Linked',
    //     errorMessage: 'Account Not Linked',
    //     functionName: 'link',
    //     confirmButtonText: 'Link',
    //     showCancelButton: false,
    //   };
    case 'activate':
      return {
        successMessage: 'Account Activated',
        errorMessage: 'Account Not Activated',
        functionName: 'activate',
        confirmButtonText: 'Activate',
        showCancelButton: false,
      };
    default:
      return null;
  }
};

const CardMenu = ({ name, id, isActive, reloadFunction }) => {
  const { user } = useContext(UserInfoContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [ConfirmModal, setConfirmModal] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const OnClickModal = () => {
    setAnchorEl(null);
    setRenameModal(true);
    setOpen(false);
  };

  const OnClickSubmitModal = (functionName) => {
    setModalProps(getModalProps(functionName));
    setAnchorEl(null);
    setConfirmModal(true);
    setOpen(false);
  };
  const handleCloseModal = () => {
    if (isMountedRef.current) {
      setRenameModal(false);
      setConfirmModal(false);
    }
    setAnchorEl(null);
    // setOpen(false);
  };

  return (
    <>
      <Box>
        <Tooltip>
          <IconButton
            onClick={handleClick}
            size='small'
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            disableRipple
          >
            <MoreVertIcon
              fontSize='small'
              sx={{
                '&:hover': { color: (theme) => theme.palette.primary.main },
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {user.internalUser && !isActive
          ? [
              // <MenuItem
              //   key='link'
              //   sx={{ p: 1 }}
              //   onClick={() => OnClickSubmitModal('link')}
              // >
              //   <ListItemIcon>
              //     <InsertLinkOutlinedIcon fontSize='small' />
              //   </ListItemIcon>
              //   <Typography variant='smallRegular'> Link </Typography>
              // </MenuItem>,
              // <Divider key='divider1' />,
              <MenuItem
                sx={{ p: 1 }}
                onClick={() => OnClickSubmitModal('activate')}
                key='activate'
              >
                <ListItemIcon>
                  <CheckCircleOutlineIcon fontSize='small' />
                </ListItemIcon>
                <Typography variant='smallRegular'> Activate </Typography>
              </MenuItem>,
              <Divider key='divider2' />,
            ]
          : null}
        <MenuItem sx={{ p: 1 }} onClick={OnClickModal}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='smallRegular'> Rename </Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ p: 1 }}
          onClick={() => {
            OnClickSubmitModal('delete');
          }}
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='smallRegular'> Delete </Typography>
        </MenuItem>
        <Divider />
      </Menu>
      <EditAccountModal
        open={renameModal}
        setClose={setRenameModal}
        handleCloseModal={handleCloseModal}
        name={name}
        id={id}
        reloadFunction={reloadFunction}
      />
      <ProjectModal
        open={ConfirmModal}
        handleCloseModal={handleCloseModal}
        name={name}
        id={id}
        reloadFunction={reloadFunction}
        {...modalProps}
      />
    </>
  );
};

export { CardMenu };
