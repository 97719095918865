import * as React from 'react';
import {
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

const instruction = [
    {
        main: '1. Create a project',
        sub: 'Click on “New Project” and enter project name.',
        icon:
            <ListItemIcon sx={{ minWidth: '32px', marginRight: '16px', marginLeft: '12px', borderRadius: '50%', padding: '12px', background: '#e0f2ff' }}>
                <AssignmentOutlinedIcon sx={{ color: '#149dff', fontSize: '20px' }} />
            </ListItemIcon>
    },
    {
        main: '2. Import a file',
        sub: 'Select a file type and paste your file link to import.',
        icon:
            <ListItemIcon sx={{ minWidth: '32px', marginRight: '16px', marginLeft: '12px', borderRadius: '50%', padding: '12px', background: '#fff0e7' }}>
                <UploadFileOutlinedIcon sx={{ color: '#ff8440', fontSize: '20px' }} />
            </ListItemIcon>
    },
    {
        main: '3. Edit mapping',
        sub: 'To create a new field, click on “Add new field” at the bottom of the page. To skip the import of a particular field, select “Do not import” from the drop-down.',
        icon:
            <ListItemIcon sx={{ minWidth: '32px', marginRight: '16px', marginLeft: '12px', borderRadius: '50%', padding: '12px', background: '#e0f2ff' }}>
                <FormatListBulletedOutlinedIcon sx={{ color: '#149dff', fontSize: '20px' }} />
            </ListItemIcon>
    },
    {
        main: '4. Feed create',
        sub: 'Select the marketing channel and country you want to create a feed for.',
        icon:
            <ListItemIcon sx={{ minWidth: '32px', marginRight: '16px', marginLeft: '12px', borderRadius: '50%', padding: '12px', background: '#fff0e7' }}>
                <CreateNewFolderOutlinedIcon sx={{ color: '#ff8440', fontSize: '20px' }} />
            </ListItemIcon>
    },
    {
        main: '5. Enhance',
        sub: 'Manipulate the feed by using filters and actions.',
        icon:
            <ListItemIcon sx={{ minWidth: '32px', marginRight: '16px', marginLeft: '12px', borderRadius: '50%', padding: '12px', background: '#e0f2ff' }}>
                <AutoFixHighOutlinedIcon sx={{ color: '#149dff', fontSize: '20px' }} />
            </ListItemIcon>
    },
    {
        main: '6. Quality',
        sub: 'Not mapping mandatory fields takes a direct hit on your quality score, make sure to map all the mandatory fields.',
        icon:
            <ListItemIcon sx={{ minWidth: '32px', marginRight: '16px', marginLeft: '12px', borderRadius: '50%', padding: '12px', background: '#fff0e7' }}>
                <TaskAltOutlinedIcon sx={{ color: '#ff8440', fontSize: '20px' }} />
            </ListItemIcon>
    },
    {
        main: '7. Activate & preview',
        sub: 'You’re all set! Click on “Activate feed” and your feed link will be ready!',
        icon:
            <ListItemIcon sx={{ minWidth: '32px', marginRight: '16px', marginLeft: '12px', borderRadius: '50%', padding: '12px', background: '#e0f2ff' }}>
                <LinkOutlinedIcon sx={{ color: '#149dff', fontSize: '20px' }} />
            </ListItemIcon>
    },
];

const InstructionBox = () => (
    <Paper elevation={0} sx={{ width: '100%', border: '1px solid #dedede', borderRadius: '8px', padding: '16px' }}>
        <nav>
            <List sx={{ marginTop: '16px' }}>
                {instruction.map(({ main, sub, icon }) => (
                    <ListItem key={main} disablePadding sx={{ alignItems: 'flexStart', marginBottom: '16px', position: 'relative' }} >
                        {icon}
                        <ListItemText>
                            <Typography variant='bodySemiBold' sx={{ display: 'block' }}>
                                {main}
                            </Typography>
                            <Typography variant='smallerRegular' sx={{ color: '#bfbfbf' }}>
                                {sub}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </nav>
    </Paper>
);
export { InstructionBox };