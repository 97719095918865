import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: '',
  status: false,
};

const downloadNotificationRedux = createSlice({
  name: 'downloadNotification',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.message = action.payload;
    },
    updateStatus: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.status = action.payload;
    },
  },
});

export const { updateMessage, updateStatus } =
  downloadNotificationRedux.actions;

// eslint-disable-next-line import/no-default-export
export default downloadNotificationRedux.reducer;
