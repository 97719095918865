/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

// MUI imports
import { Container, Button, Stack } from '@mui/material';

// MUI icons
import LogoutIcon from '@mui/icons-material/Logout';


// Internal component imports
import { NavigationMenu } from './NavigationMenu';
import { AdhelpLogo } from '../../../../constants/endpoints/assests/logos';
import { BreadcrumbsDynamic } from './BreadcrumbsDynamic';
import { OpenComponentButton } from './AddButton';
import { AddProjectModal } from '../OtherComponents/AddProjectModal';
import { TempCompanySelector } from '../../../../components/TempCompanySelector';


// Styled imports
import {
  StyledNav,
  StyledStack,
  StyledStackMobile,
} from '../StyledComponents/StyledAppbar';
import { OverviewBox, OverviewDetails } from '../OtherComponents/OverviewBox';
import { AddActionFilter } from '../OtherComponents/AddActionFilter';
import { UserInfoContext } from '../../../../context/UserInfoContext';


const SelectAccExit = () => (
  <Link to='../../../accounts' className='link'>
    <img
      src={AdhelpLogo}
      height={36}
      alt='AdHelp.io'
      style={{ marginTop: '8px' }}
    />
  </Link>
);

// Main function for component
const AppBar = () => {
  const location = useLocation();
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const { user } = useContext(UserInfoContext);

  return (
    <>
      <StyledNav>
        <Container maxWidth='xxl'>
          {['/feed-management', '/feed-management/'].includes(
            location.pathname
          ) ? (
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{ a: { textDecoration: 'none' } }}
            >
              <SelectAccExit />
              <Link to='../../../companies'>
                <Button
                  color='primary'
                  disableRipple
                  sx={{
                    '&:hover': { backgroundColor: 'transparent' },
                    marginLeft: '5px',
                    textDecoration: 'none',
                    color: '#0096ff',
                    fontSize: '16px',
                  }}
                >
                  Exit
                  <LogoutIcon color='primary' className='exitIcon' />
                </Button>
              </Link>
            </Stack>
          ) : (
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{ a: { textDecoration: 'none' } }}
            >
              <SelectAccExit />
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <NavigationMenu />
                <Link to='../../../companies'>
                  <Button
                    color='primary'
                    disableRipple
                    sx={{
                      '&:hover': { backgroundColor: 'transparent' },
                      textDecoration: 'none',
                      color: '#0096ff',
                      fontSize: '16px',
                    }}
                  >
                    Exit
                    <LogoutIcon color='primary' className='exitIcon' />
                  </Button>
                </Link>
              </Stack>
            </Stack>
          )}
        </Container>
      </StyledNav>

      {['/feed-management', '/feed-management/'].includes(location.pathname) ? (
        <Container maxWidth='xxl'>
          <StyledStack
            direction='row'
            justifyContent='flex-start'
            className='forDesktop'
          >
            <div style={{ width: '300px' }}>
              <TempCompanySelector />
            </div>

            <p
              style={{
                fontWeight: '600',
                fontSize: '22px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              Feed Management
            </p>
            <span style={{ width: '300px' }} />
          </StyledStack>

          {/* For mobile */}
          <StyledStackMobile
            direction='column'
            justifyContent='center'
            className='forMobile'
          >
            <p
              style={{
                fontWeight: '600',
                fontSize: '22px',
                textAlign: 'center',
              }}
            >
              Feed Management
            </p>
            <Stack
              sx={{ width: 'auto', marginTop: '20px' }}
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <div style={{ minWidth: '150px' }}>
                <TempCompanySelector />
              </div>

              <OpenComponentButton
                name='New Project'
                setOpen={setOpen}
                className='addProjectButton'
              >
                <AddProjectModal
                  open={open}
                  setOpen={setOpen}
                  modalTitle='Add New Project'
                />
              </OpenComponentButton>
            </Stack>
          </StyledStackMobile>
        </Container>
      ) : [
        '/feed-management/feeds/enhance',
        '/feed-management/feeds/enhance/',
      ].includes(location.pathname) ? (
        <>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <BreadcrumbsDynamic />
            <OverviewBox
              isBoxVisible={isBoxVisible}
              setIsBoxVisible={setIsBoxVisible}
            />
          </Stack>
          <OverviewDetails isBoxVisible={isBoxVisible} />
        </>
      ) : /^\/feed-management\/feeds\/enhance\/action\/[a-zA-Z0-9-]+$/.test(location.pathname) ? (

        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '20px'
          }}
        >
          <BreadcrumbsDynamic />
          {(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') &&
            <AddActionFilter name="Add new action" pageName="edit action" />
          }
        </Stack>


      ) : /^\/feed-management\/feeds\/enhance\/filter\/[a-zA-Z0-9-]+$/.test(location.pathname) ? (

        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <BreadcrumbsDynamic />
          {(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') &&
            <AddActionFilter name="Add new filter" pageName="edit filter" />
          }
        </Stack>


      ) :
        (
          <BreadcrumbsDynamic />
        )}
    </>
  );
};

export { AppBar, SelectAccExit };
