/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect } from 'react';
import {
  // Box,
  Button,
  Container,
  Typography,
  ToggleButton,
  TextField,
  InputAdornment,
  Grid,
} from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { Toolbar } from '../../../components/Toolbar';
import { StyledToggleButtonGroup } from '../../FeedManagement/Components/StyledComponents/StyledCommon';
import { UserInfoContext } from '../../../context/UserInfoContext';

const UserToolbar = ({ list, setList, allUsersList }) => {
  const location = useLocation();
  const currentURL = location.pathname;

  const { user } = useContext(UserInfoContext);

  const { projectName } = user;

  const [toggled, setToggled] = useState(
    currentURL === '/users/agency' ? 'Agency Users' : 'Companies Users'
  );

  const handleToggledFeed = (event, newToggled) => {
    if (newToggled !== null) {
      setToggled(newToggled);
    }
  };

  const [search, setSearch] = useState('');

  const onChange = (e) => {
    setSearch(e.target.value.toLowerCase());
  };
  useEffect(() => {
    const filterUsers = async () => {
      if (search) {
        if (toggled === 'Agency Users' && user.projectType === 'AGENCY') {
          const filteredList = (allUsersList?.agency_user || []).filter(
            (users) =>
              users.username.includes(search) || users.email.includes(search)
          );
          setList({ ...list, agency_user: filteredList });
        } else if (user?.projectType === 'COMPANY') {
          const filteredList =
            await allUsersList?.company_users[0]?.users?.filter(
              (users) =>
                users?.username.includes(search) ||
                users?.email.includes(search)
            );
          setList({
            ...list,
            company_users: [
              {
                ...list.company_users[0],
                users: filteredList,
              },
            ],
          });
        } else {
          const filteredList = (allUsersList?.company_users || [])
            .map((company) => ({
              ...company,
              users: company.users.filter(
                (users) =>
                  users.username.toLowerCase().includes(search) ||
                  users.email.toLowerCase().includes(search)
              ),
            }))
            .filter((company) => company.users.length > 0);

          // Remove companies with no matching users
          setList({ ...list, company_users: filteredList });
        }
      } else {
        // If search text is empty, reset list to the original data
        setList(allUsersList);
      }
    };

    filterUsers();
  }, [search, allUsersList]);

  return (
    <Toolbar colwidth={600}>
      <Container maxWidth='none' display='flex'>
        <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={4}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            sx={{
              '@media(max-width: 600px)': {
                flexWrap: 'wrap',
                justifyContent: 'center',
              },
            }}
          >
            {user.projectType === 'AGENCY' &&
            ['ADMIN', 'COLLABORATOR', 'OWNER'].includes(user.role) ? (
              <StyledToggleButtonGroup
                value={toggled}
                exclusive
                onChange={handleToggledFeed}
                className='feedToggleParent'
                sx={{
                  backgroundColor: '#fff',
                  boxShadow: '0px 0px 10px rgba(0,0,0,.08)',
                  '&.MuiToggleButtonGroup-root.feedToggleParent': {
                    border: '1px solid #fff',
                  },
                  '& .MuiToggleButton-root.Mui-selected': {
                    minWidth: '154px !important',
                  },
                  '& .feedToggle3': { minWidth: '154px !important' },
                  '& .feedToggle1': { minWidth: '154px !important' },
                  marginRight: '10px',
                }}
              >
                <ToggleButton
                  value='Agency Users'
                  aria-label='toggle button 1'
                  component={Link}
                  to='/users/agency'
                  color={toggled === 'Agency Users' ? 'primary' : 'standard'}
                  className='feedToggle1'
                >
                  Agency Users
                </ToggleButton>
                <ToggleButton
                  value='Companies Users'
                  aria-label='toggle button 1'
                  component={Link}
                  to='/users/company'
                  color={toggled === 'Companies Users' ? 'primary' : 'standard'}
                  className='feedToggle3'
                >
                  Companies Users
                </ToggleButton>
              </StyledToggleButtonGroup>
            ) : null}
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon color='primary' />
                  </InputAdornment>
                ),
                style: {
                  border: '0px solid #fff !important',
                  backgroundColor: '#fff',
                  borderRadius: '24px',
                  boxShadow: '0px 0px 10px rgba(0,0,0,.08)',
                },
              }}
              value={search}
              placeholder='Search...'
              onChange={onChange}
              variant='outlined'
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: '0.7rem 0.5rem 0.7rem 0rem',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#fff ',
                },
                '& .MuiOutlinedInput-notchedOutline:hover': {
                  borderColor: '#0096FF !important',
                },
                maxWidth: '224px',
                minWidth: '100px',
                '@media(max-width: 568px)': {
                  marginTop: 1,
                },
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            lg={4}
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{
              order: -1,
              '@media(min-width: 1200px)': { order: 0 },
              '@media (max-width:600px)': {
                padding: 2,
              },
            }}
          >
            <Typography
              component='h2'
              variant='pageHeading'
              sx={{ whiteSpace: 'nowrap' }}
            >
              {toggled === 'Agency Users' && user.projectType === 'AGENCY'
                ? `${projectName} Users`
                : `${projectName} Users`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={6}
            lg={4}
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            sx={{
              svg: {
                color: '#fff',
              },
              '@media (max-width:600px)': {
                justifyContent: 'center',
              },
            }}
          >
            {['ADMIN', 'OWNER', 'SUB_ADMIN'].includes(user.role) ? (
              <Button
                component={Link}
                variant='contained'
                startIcon={
                  <AddIcon
                    sx={{ '&.MuiSvgIcon-root': { color: '#fff !important' } }}
                  />
                }
                sx={{
                  borderRadius: '100px',
                  height: '44px',
                  width: '224px',
                  boxShadow: '0px 0px 10px rgba(0,0,0,.16)',
                }}
                to={
                  user.projectType === 'COMPANY'
                    ? '/users/company/add-company-user'
                    : user.projectType === 'AGENCY' &&
                      toggled === 'Agency Users'
                    ? '/users/agency/add-user'
                    : '/users/company/add-user'
                }
              >
                {toggled === 'Agency Users' && user.projectType === 'AGENCY'
                  ? 'Add Agency User'
                  : 'Add Company User'}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Container>
      <Outlet />
    </Toolbar>
  );
};

export { UserToolbar };
