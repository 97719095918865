import React from 'react';
import { ResetPasswordForm } from '../Form/ResetPasswordForm';
import { PagesWrraper } from './PagesWrraper';

const ResetPasswordPage = () => (
  <PagesWrraper>
    <ResetPasswordForm />
  </PagesWrraper>
);

export { ResetPasswordPage };
