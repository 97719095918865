/* eslint-disable no-useless-escape */
import { object, string } from 'yup';

const AddAgencyUserSchema = object().shape({
  name: string().required('Name is required'),
  email: string().email('Invalid email').required('Email is required'),
  radioValue: string()
    .oneOf(['agency_admin', 'agency_collaborator'])
    .required('Role is required'),
});
const AddCompanyUserSchema = object().shape({
  name: string().required('Name is required'),
  email: string().email('Invalid email').required('Email is required'),
  radioValue: string()
    .oneOf(['admin', 'collaborator'])
    .required('Role is required'),
});

const AddAgencyCompanyUserSchema = object().shape({
  name: string().required('Name is required'),
  email: string().email('Invalid email').required('Email is required'),
  company: string().required('Company is required'),
  radioValue: string()
    .oneOf(['company_admin', 'company_collaborator'])
    .required('Role is required'),
});
const AddAgencyCompanyUserEditSchema = object().shape({
  name: string().required('Name is required'),
  email: string().email('Invalid email').required('Email is required'),
  radioValue: string()
    .oneOf(['company_admin', 'company_collaborator'])
    .required('Role is required'),
});
const detailsSchema = object().shape({
  projectName: string().required('Name is required'),
  ownerName: string().required('Name is required'),
});
const DeleteUserSchema = object().shape({
  password: string().required('Please Enter your password'),
});

export {
  AddCompanyUserSchema,
  AddAgencyUserSchema,
  AddAgencyCompanyUserEditSchema,
  AddAgencyCompanyUserSchema,
  detailsSchema,
  DeleteUserSchema,
};
