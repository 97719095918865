import { Box, Button, Typography } from '@mui/material';
import React, { useContext } from 'react';
import _ from 'lodash';
import { sendReducer } from '../../../../reducers/sendReducer';
import { AccountsService } from '../../../../services/accounts';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { DataSendingModalBase } from '../../../../components/DataSendingModalBase';
import { CompanyService } from '../../../../services/company';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { setCompanyList } from '../../../../utils/userInfoDispatchFunctions';

const defaultState = { status: 'PRESEND' };

const accountFunctionsMap = {
  delete: AccountsService.deleteAccount,
  activate: AccountsService.activateAccount,
  link: AccountsService.linkAccount,
};

const companyFunctionsMap = {
  delete: CompanyService.deleteCompany,
};

const getFunction = (isCompany, functionName) => {
  if (isCompany) {
    return companyFunctionsMap[functionName];
  }

  return accountFunctionsMap[functionName];
};

const ProjectModal = ({
  open,
  handleCloseModal,
  name = 'Name',
  id,
  reloadFunction = null,
  successMessage = 'Success',
  errorMessage = 'Error',
  functionName = null,
  prompt = '',
  confirmButtonText = 'Confirm',
  showCancelButton = false,
  cancelButtonText = 'Cancel',
  isCompany = false,
}) => {
  const { companyList } = useContext(UserInfoContext);
  const [state, dispatch] = React.useReducer(sendReducer, defaultState);
  const handleSubmit = () => {
    dispatch({ type: 'SENDING' });
    getFunction(
      isCompany,
      functionName
    )(id)
      .then((response) => {
        dispatch({ type: 'SENDSUCCESS', data: response.data });
        if (isCompany) {
          setCompanyList(
            dispatch,
            companyList.filter((company) => company.id !== id)
          );
        }
        setTimeout(() => {
          if (reloadFunction) {
            reloadFunction();
          }
        }, 500);
        setTimeout(() => {
          handleCloseModal();
        }, 2000);
      })
      .catch((error) => {
        dispatch({ type: 'SENDERROR', error: error.data });
        setTimeout(() => {
          handleCloseModal();
          dispatch({ type: 'PRESEND' });
        }, 2000);
      });
  };

  return (
    <DataSendingModalBase
      open={open}
      handleCloseModal={handleCloseModal}
      status={state.status}
      successMessage={successMessage}
      errorMessage={errorMessage}
    >
      <HeadingWithUnderline heading={`${_.startCase(functionName)} ${name}`} />
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        {prompt ? (
          <Typography variant='h4' sx={{ mb: 3 }}>
            {prompt}
          </Typography>
        ) : null}
        <Box
          sx={{ width: '100%' }}
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          gap={3}
        >
          {showCancelButton ? (
            <Button variant='outlined' onClick={handleCloseModal} fullWidth>
              {cancelButtonText}
            </Button>
          ) : null}
          <Button variant='contained' onClick={handleSubmit} fullWidth>
            {confirmButtonText}
          </Button>
        </Box>
      </Box>
    </DataSendingModalBase>
  );
};

export { ProjectModal };
