import React from 'react';
import { TextAndSelectInput } from '../../Inputs/TextAndSelectInput';
import { WrappedCheckbox } from '../Fields/WrappedCheckbox';
import { MultilineFlexTextInput } from '../../Inputs/FlexInputs';

const fieldMap = (fields, name) => ({
  CONTAINS: [<TextAndSelectInput name={name} options={fields} />],
  DOES_NOT_CONTAINS: [<TextAndSelectInput name={name} options={fields} />],
  IS_EQUAL_TO: [<TextAndSelectInput name={name} options={fields} />],
  IS_NOT_EQUAL_TO: [<TextAndSelectInput name={name} options={fields} />],
  LENGTH_EXCEEDS: [<TextAndSelectInput name={name} options={fields} />],
  LENGTH_DOES_NOT_EXCEEDS: [
    <TextAndSelectInput name={name} options={fields} />,
  ],
  WORD_COUNT_EXCEEDS: [<TextAndSelectInput name={name} options={fields} />],
  WORD_COUNT_DOES_NOT_EXCEEDS: [
    <TextAndSelectInput name={name} options={fields} />,
  ],
  IS_GREATER_THAN: [<TextAndSelectInput name={name} options={fields} />],
  IS_GREATER_OR_EQUAL_TO: [<TextAndSelectInput name={name} options={fields} />],
  IS_LESS_THAN: [<TextAndSelectInput name={name} options={fields} />],
  IS_LESS_OR_EQUAL_TO: [<TextAndSelectInput name={name} options={fields} />],
  STARTS_WITH: [
    <TextAndSelectInput name={name} options={fields} />,
    <WrappedCheckbox name={`${name}.caseSensitive`} label='Case sensitive' />,
  ],
  DOES_NOT_STARTS_WITH: [
    <TextAndSelectInput name={name} options={fields} />,
    <WrappedCheckbox name={`${name}.caseSensitive`} label='Case sensitive' />,
  ],
  ENDS_WITH: [
    <TextAndSelectInput name={name} options={fields} />,
    <WrappedCheckbox name={`${name}.caseSensitive`} label='Case sensitive' />,
  ],
  DOES_NOT_ENDS_WITH: [
    <TextAndSelectInput name={name} options={fields} />,
    <WrappedCheckbox name={`${name}.caseSensitive`} label='Case sensitive' />,
  ],
  IS_EMPTY: [],
  IS_NOT_EMPTY: [],
  CONTAINS_ANY_OF: [
    <MultilineFlexTextInput
      id={`${name}.value`}
      label='value'
      hideLabel
      placeholder='Enter a value'
    />,
  ],
  DOES_NOT_CONTAINS_ANY_OF: [
    <MultilineFlexTextInput
      id={`${name}.value`}
      label='value'
      hideLabel
      placeholder='Enter a value'
    />,
  ],
  IS_EQUAL_TO_ANY: [
    <MultilineFlexTextInput
      id={`${name}.value`}
      label='value'
      hideLabel
      placeholder='Enter a value'
    />,
  ],
  IS_NOT_EQUAL_TO_ANY: [
    <MultilineFlexTextInput
      id={`${name}.value`}
      label='value'
      hideLabel
      placeholder='Enter a value'
    />,
  ],
  IS_IN_HIGHEST: [
    <MultilineFlexTextInput
      id={`${name}.value`}
      label='value'
      hideLabel
      placeholder='Enter a value'
    />,
  ],
  IS_IN_LOWEST: [
    <MultilineFlexTextInput
      id={`${name}.value`}
      label='value'
      hideLabel
      placeholder='Enter a value'
    />,
  ],
  MATCHES_REGEX: [
    <MultilineFlexTextInput
      id={`${name}.value`}
      label='value'
      hideLabel
      placeholder='Enter a value'
    />,
  ],
  DOES_NOT_MATCHES_REGEX: [
    <MultilineFlexTextInput
      id={`${name}.value`}
      label='value'
      hideLabel
      placeholder='Enter a value'
    />,
  ],
});

export { fieldMap };
