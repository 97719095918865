import React from 'react';

import _ from 'lodash';

import { Box, Link, Tooltip, Typography } from '@mui/material';
import { DashboardCustomTable } from '../../../../components/Table';

const AvailabilityDot = ({ inStock = true }) => (
  <Box
    sx={{
      backgroundColor: inStock ? 'stock.inStock' : 'stock.outOfStock',
      borderRadius: '99px',
      height: '0.5rem',
      width: '0.5rem',
      left: '0',
    }}
  />
);
const MetricWithCurrency = [
  'All Conversion value',
  'Average Cost',
  'Conversion value',
  'Cost',
  'Cost per All Conversions',
  'Cost per Conversions values',
  'All Conversion values (by Conversion time)',
];
const MetricWithPercentage = [
  'Engagement rate',
  'Interactions Rate',
  'Videos (Played to 100%)',
  'Videos (Played to 50%)',
  'Videos (Played to 25%)',
  'Videos (Played to 75%)',
  'Conversion rate (interactions)',
];

const headCells = (firstDataItem, currencyCode) =>
  Object.keys(firstDataItem)
    .filter(
      (key) => key !== 'Product Link' && key !== 'Availability' && key !== 'Id'
    )
    .map((key) => {
      // Specific format functions based on key
      const formatFunction = (row) => {
        if (key === 'Image Link') {
          return (
            <Tooltip
              title={
                row.Availability === 'in stock' ? 'In Stock' : 'Out of Stock'
              }
            >
              <Link href={row['Product Link']} target='_blank' rel='noreferrer'>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AvailabilityDot inStock={row.Availability === 'in stock'} />
                  <img
                    src={row['Image Link']}
                    alt={row.Title}
                    className='dataImage'
                  />
                  <Box component='span' className='screenReaderOnly'>
                    Opens in a new window
                  </Box>
                </Box>
              </Link>
            </Tooltip>
          );
        }
        if (key === 'Title') {
          return (
            <Tooltip title={row.Title}>
              <Typography
                variant='body'
                sx={{
                  '& a': { textDecoration: 'none', color: 'text.primary' },
                }}
              >
                <a href={row['Product Link']} target='_blank' rel='noreferrer'>
                  {row.Title.length > 27
                    ? `${row.Title.slice(0, 27)}...`
                    : row.Title}
                </a>
                <Box component='span' className='screenReaderOnly'>
                  Opens in a new window
                </Box>
              </Typography>
            </Tooltip>
          );
        }
        if (typeof row[key] === 'number') {
          const formattedValue = _.round(row[key], 2).toLocaleString();
          if (key === 'ROAS') {
            return _.round(formattedValue, 1)?.toLocaleString();
          }
          if (MetricWithPercentage.includes(key)) {
            return `${formattedValue} %`;
          }
          return MetricWithCurrency.includes(key)
            ? `${currencyCode} ${formattedValue} `
            : formattedValue;
        }
        return row[key];
      };
      return {
        key,
        id: key,
        numeric: typeof firstDataItem[key] === 'number',
        label: [key],
        alignRight: typeof firstDataItem[key] === 'number',
        formatFunction,
        totalFunction: (rows, totalRow) => {
          if (totalRow || (totalRow[key] && totalRow[key] === 0)) {
            const formattedValue = _.round(totalRow[key], 2).toLocaleString();
            if (MetricWithCurrency.includes(key)) {
              return `${currencyCode} ${formattedValue} `;
            }
            if (MetricWithPercentage.includes(key)) {
              return `${formattedValue} %`;
            }
            if (key === 'ROAS') {
              return `${_.round(totalRow[key], 1).toLocaleString()} `;
            }
            return formattedValue.toLocaleString();
          }
          return null;
        },
      };
    });

// use the current filter to filter objects from the dataArg
const ShoppingProductsInsights = ({ data, currencyCode = 'ZZZ' }) => {
  if (data.length === 0) {
    return [];
  }

  const firstDataItem = data[0];

  // Separate "Total" row from the data
  const totalRow = data.find((row) => row.Title === 'Total');
  const filteredData = data.filter((row) => row.Title !== 'Total');

  if (filteredData.length === 0) {
    return null; // Return null or another fallback if filteredData is empty
  }

  return (
    <DashboardCustomTable
      headCells={headCells(firstDataItem, currencyCode)}
      rows={filteredData}
      totalRow={totalRow}
      rowIdField='Id'
      verticalBorders
      defaultOrder='desc'
      defaultOrderBy='Conversion Value'
    />
  );
};

export { ShoppingProductsInsights };
