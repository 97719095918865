import { styled } from '@mui/system';
import { Box, Modal } from '@mui/material';

const StyledModal = styled(Modal)(
  () => `
  right: -8px;
  overflow-y: scroll;
  min-width: 400px;  
  @media(max-width:600px){
    min-width: 300px;  
  }
  .MuiModal-root{
    display:flex;
    justify-content:center;
  }
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter:blur(5px);
   }
          `
);

const StyledFormModal = styled(Box)(
  ({ theme }) => `
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color:${theme.palette.background.main};
      border-radius: 12px;
      max-width: 500px;
      min-width: 400px;
      padding:${theme.spacing(3)} ${theme.spacing(3)};
      box-shadow: ${theme.shadows[2]};  
      @media(max-width:1400px){
       top: 50%;
       }
      @media(max-width:1200px){
      top: 30%;
      }
      @media(max-width:900px){
       top: 30%;
      }
      @media(max-width:600px){
      padding:${theme.spacing(2)} ${theme.spacing(1.5)};
      min-width: 95%;
      }
     
    `
);

export { StyledModal, StyledFormModal };
