import React from 'react';

import { Bar } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { StyledPOASBarGraph } from './StyledPOASBarGraph';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const POASBarGraph = ({ data, currency }) => {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: false,
      datalabels: {
        align(context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value < 0.0 ? 'end' : 'start';
        },
        clamp: false,
        anchor(context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value < 0.0 ? 'start' : 'end';
        },
        backgroundColor: null,
        borderColor: null,
        borderRadius: 4,
        borderWidth: 1,
        color: '#fff',
        font: {
          size: 14,
          weight: 600,
        },
        offset: 4,
        padding: 5,
        rotation: 270,
        formatter(value) {
          if (value >= 1000) {
            if (value >= 1000000) {
              return `${Number((value / 1000000).toFixed(2).toString())}M `;
            }
            return `${Number((value / 1000).toFixed(2).toString())}K `;
          }
          return Number(value);
        },
      },
    },
    scales: {
      y1: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'POAS',
          fontColor: '#3D4955',
          font: {
            size: 12,
            family: 'Montserrat',
            weight: '600',
          },
          padding: {
            bottom: 8,
          },
        },
        ticks: {
          gridLines: {
            color: '#CFCECE',
          },
        },
      },
      y: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: currency,
          fontColor: '#3D4955',
          font: {
            size: 12,
            family: 'Montserrat',
            weight: '600',
          },
          padding: {
            bottom: 8,
          },
        },
        ticks: {
          callback(
            value
            // index,
            // values
          ) {
            if (value >= 1000) {
              return `${Number((value / 1000).toString())}K `;
            } // pass tick values as a string into Number function

            return value;
          },
        },
        // grid line settings
        grid: {
          drawOnChartArea: false,
        },
        gridLines: {
          color: '#CFCECE',
        },
      },
      x: {
        offset: true,
        title: {
          display: true,
          text: 'Months',
          fontColor: '#3D4955',
          font: {
            size: 12,
            family: 'Montserrat',
            weight: '600',
          },
          padding: {
            bottom: 8,
          },
        },
        gridLines: {
          color: '#CFCECE',
        },
      },
    },
  };
  const stackedChartPlugins = [ChartDataLabels];
  return (
    <StyledPOASBarGraph className='VisualCanvas'>
      <div className='parent'>
        <Bar
          id='myChart'
          data={data}
          options={options}
          plugins={stackedChartPlugins}
        />
      </div>
    </StyledPOASBarGraph>
  );
};

export { POASBarGraph };
