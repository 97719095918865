/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import _ from 'lodash';
// MUI imports
import {
  Container,
  Button,
  InputAdornment,
  TextField,
  Stack,
  Box,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

// Internal imports
import { ErrorBoundary } from 'react-error-boundary';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { GlobalErrorComponent } from '../../../components/GlobalErrorComponent';
// import { StyledEnhanceTable } from '../Components/StyledComponents/StyledCommon';
import { CustomTable } from '../../../components/Table';
import { AddColumnModal } from '../Components/OtherComponents/AddColumnModal';

// Styled imports
import { StyledPaper } from './StyledMainBox';
import { ToggleAffectedButton } from '../Components/OtherComponents/ToggleAffectedButton';
import {
  updateColumnInAffectedItems,
  updateColumnTobeRenderdInAffectedItems,
} from '../Redux/CustomizableHeadcells/All-Items/slice';
import { setSearchTermAffectedItems } from '../Redux/PaginationVales';

const TableCellWithEllipsis = ({ value }) => (
  <div
    style={{
      // maxWidth: '300px',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
  >
    {value}
  </div>
);

const headCells = (data) => {
  if (data?.length > 0) {
    const columns = Object.keys(data[0]).map((key) => ({
      id: key,
      numeric: false,
      label:
        key === 'image_link'
          ? 'Image'
          : key
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' '),
      disablePadding: false,
      alignRight: false,
      formatFunction: (row) => {
        if (key === 'image_link') {
          return (
            <img
              src={row[key]}
              alt='item_image'
              height='70'
              width='50'
              style={{
                maxWidth: '100px',
                maxHeight: '100px',
                objectFit: 'contain',
              }}
            />
          );
        }
        return <TableCellWithEllipsis value={row[key]} />;
      },
    }));
    columns.push({
      id: 'image_links',
      numeric: false,
      label: 'Image Link',
      disablePadding: false,
      alignRight: false,
      formatFunction: (row) => (
        // eslint-disable-next-line dot-notation
        <TableCellWithEllipsis value={row.image_link}>
          {row.image_link}
        </TableCellWithEllipsis>
      ),
    });
    // Move the "Image" column to the beginning
    const imageColumnIndex = columns.findIndex(
      (column) => column.id === 'image_link'
    );
    if (imageColumnIndex > -1) {
      const imageColumn = columns.splice(imageColumnIndex, 1)[0];
      columns.unshift(imageColumn);
    }

    return columns;
  }
  return [];
};

const transformHeadCellsWithValues = (array) => {
  // Array to store format functions with identifiers
  const formatFunctions = [];

  // Create a new array without the 'formatFunction' property in each object
  const transformedArray = array.map((headCell) => {
    const { formatFunction, ...rest } = headCell;
    if (formatFunction) {
      formatFunctions.push({
        label: headCell.label,
        formatFunction,
      });
    }
    return rest;
  });
  return { transformedArray, formatFunctions };
};
// function to get the column values based on the location
const columnSelector = (location) => {
  if (location.pathname === '/feed-management/feeds/enhance/affected-items') {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.affectedItemsColumn
    );
  }
};

// function to get the column values to be rendered based on the location
const columnTobeRenderdSelector = (location) => {
  if (location.pathname === '/feed-management/feeds/enhance/affected-items') {
    return useSelector(
      (state) =>
        state.allItemCustomizedColumnSlice.columnTobeRenderdInAffectedItems
    );
  }
};

// state for desired columns
const desiredColumnUpdater = (location) => {
  if (location.pathname === '/feed-management/feeds/enhance/affected-items') {
    return useSelector(
      (state) => state.allItemCustomizedColumnSlice.desiredColumnInAffectedItems
    );
  }
};

// main function component
const AffectedItemsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openCustomCol, setopenCustomCol] = useState(false);
  const [isResizable, setResizable] = useState(
    Array.from({ length: 50 }, () => false)
  );
  const [maxWidth, setMaxWidth] = useState(
    Array.from({ length: 50 }, () => 300)
  );
  const actionId = useSelector(
    (state) => state.allItemCustomizedColumnSlice.actionId
  );

  const filterId = useSelector(
    (state) => state?.EditFilteredData?.editedFilterData?.id
  );
  const toggle = useSelector(
    (state) => state.allItemCustomizedColumnSlice.toggleButton
  );
  const affectedItemsData = useSelector(
    (state) => state.allItemCustomizedColumnSlice.affectedItemsData
  );
  const remainingItemsData = useSelector(
    (state) => state.allItemCustomizedColumnSlice.remainingItemsData
  );
  const removedItemsData = useSelector(
    (state) => state.allItemCustomizedColumnSlice.removedItemsData
  );

  const location = useLocation();

  const handleRedirect = () => {
    if (location.pathname.includes('affected-items')) {
      navigate(`../feeds/enhance/action/${actionId}`);
    }
    if (location?.state?.path.includes('filter')) {
      navigate(`../feeds/enhance/filter/${filterId}`);
    }
  };

  const data = location?.state?.data ?? affectedItemsData ?? [];
  const searchQuery = useSelector((state) => state.PaginationValuesReducers.searchTermAffectedItems);
  const [formatFunctionsContainer, setFormatFunctionsContainer] = useState([]);
  const [
    filterFormettedFunctionContainer,
    setfilterFormettedFunctionContainer,
  ] = useState([]);
  const [toggled, setToggled] = useState('');
  const [disable, setDisable] = useState(false);
  const tableValues = columnSelector(location);
  let columnsTobeRender = columnTobeRenderdSelector(location);
  columnsTobeRender = columnsTobeRender.filter((el) => el);
  const desiredColumn = desiredColumnUpdater(location);

  // table headcell for action page
  const headCellsTable = headCells(data);
  // table data & headcell for filter page
  const tableDataFiltered =
    location?.state?.data?.filtered_out_data ?? removedItemsData ?? [];
  const tableDataRemaining =
    location?.state?.data?.remaining_data ?? remainingItemsData ?? [];
  const headCellsTableFiltered = headCells(tableDataFiltered) || [];
  const headCellsTableRemaining = headCells(tableDataRemaining) || [];
  useEffect(() => {
    setToggled(toggle);
  }, [data?.tog, toggle]);

  // Effect to set toggled state from URL or fallback to Redux state
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const toggledFromURL = urlParams.get('toggled');

    if (toggledFromURL) {
      setToggled(toggledFromURL);
    } else {
      setToggled(toggle);
    }
  }, [location, toggle]);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    // setSearchQuery(query);
    dispatch(setSearchTermAffectedItems(query))
  };

  const filteredRows = Array.isArray(data)
    ? data?.filter((row) =>
        Object?.values(row)?.some(
          (value) =>
            typeof value === 'string' &&
            value?.toLowerCase()?.includes(searchQuery)
        )
      )
    : [];

  const remainingDataForSearch = Array.isArray(tableDataRemaining)
    ? tableDataRemaining?.filter((row) =>
        Object?.values(row)?.some(
          (value) =>
            typeof value === 'string' &&
            value?.toLowerCase()?.includes(searchQuery)
        )
      )
    : [];

  const filteredDataForSearch = Array.isArray(tableDataFiltered)
    ? tableDataFiltered?.filter((row) =>
        Object?.values(row)?.some(
          (value) =>
            typeof value === 'string' &&
            value?.toLowerCase()?.includes(searchQuery)
        )
      )
    : [];
  const headCellsToUse =
    // eslint-disable-next-line no-nested-ternary
    location.pathname === '/feed-management/feeds/enhance/affected-items'
      ? headCellsTable
      : location.pathname === '/feed-management/feeds/enhance/filtered-items'
      ? headCellsTableFiltered
      : headCellsTableRemaining;

  useEffect(() => {
    if (headCellsToUse?.length === 0) {
      setDisable(true);
    }
  }, [headCellsToUse, data, setDisable]);

  useEffect(() => {
    const { transformedArray, formatFunctions } =
      transformHeadCellsWithValues(headCellsToUse);
    if (location.pathname === '/feed-management/feeds/enhance/affected-items') {
      dispatch(updateColumnInAffectedItems(transformedArray));
    }
    setFormatFunctionsContainer(formatFunctions);
  }, [data, dispatch, desiredColumn]);

  useEffect(() => {
    if (
      tableValues &&
      desiredColumn.length > 0 &&
      location.pathname === '/feed-management/feeds/enhance/affected-items'
    ) {
      const orderedTableValues = desiredColumn.map((label) => {
        const foundItem = tableValues.find(
          (item) => item?.label?.toLowerCase() === label?.toLowerCase()
        );
        return foundItem || null;
      });
      dispatch(updateColumnTobeRenderdInAffectedItems(orderedTableValues));

      // Set formatted columns container
      const filterFormattedColumns = desiredColumn.map((label) => {
        const foundItem = formatFunctionsContainer.find(
          (item) => item?.label?.toLowerCase() === label?.toLowerCase()
        );
        return foundItem || null;
      });
      setfilterFormettedFunctionContainer(filterFormattedColumns);
    }
  }, [
    tableValues,
    dispatch,
    desiredColumn,
    formatFunctionsContainer,
    location.pathname,
  ]);

  return (
    <>
      <Container maxWidth='xxl'>
        <TitleCard
          title={
            (toggled &&
              location.pathname !==
                '/feed-management/feeds/enhance/affected-items' &&
              (toggled === 'Remaining Items'
                ? 'Remaining Items'
                : 'Removed Items')) ||
            'Affected Items'
          }
        >
          <Stack direction='row' justifyContent='space-between'>
            <TextField
              type='search'
              placeholder='Search here'
              value={searchQuery}
              onChange={handleSearch}
              sx={{
                input: {
                  paddingLeft: '5px',
                },
                backgroundColor: '#ffffff',
                borderRadius: '30px',
                width: '200px',
                paddingRight: '16px',
              }}
              InputProps={{
                style: {
                  height: '42px',
                  borderRadius: '30px',
                },
                classes: {
                  root: 'custom-input-root',
                },
                startAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon sx={{ fontSize: '16px', color: '#999999' }} />
                  </InputAdornment>
                ),
              }}
            />
            {tableDataFiltered &&
            tableDataRemaining &&
            location.pathname !==
              '/feed-management/feeds/enhance/affected-items' ? (
              <ToggleAffectedButton toggled={toggled} setToggled={setToggled} />
            ) : null}

            <Button
              variant='pillOutlined'
              disableRipple
              sx={{
                height: '44px',
                minWidth: '220px',
                '&:hover': { svg: { color: '#0096ff !important' } },
                svg: { paddingRight: '5px', fontSize: '30px' },
              }}
              onClick={() => setopenCustomCol(true)}
              disabled={disable}
            >
              <EditNoteOutlinedIcon />
              Customise Columns
            </Button>
          </Stack>
        </TitleCard>
        <StyledPaper sx={{ padding: '0px 0px 4px 0px' }}>
          <ErrorBoundary fallback={<GlobalErrorComponent />}>
            {tableDataFiltered &&
            tableDataRemaining &&
            location.pathname !==
              '/feed-management/feeds/enhance/affected-items' ? (
              <CustomTable
                headCells={
                  toggled === 'Remaining Items'
                    ? headCellsTableRemaining
                    : headCellsTableFiltered
                }
                rowIdField='id'
                rows={
                  toggled === 'Remaining Items'
                    ? remainingDataForSearch
                    : filteredDataForSearch
                }
                verticalBorders={false}
                displayFooter={false}
                // eslint-disable-next-line react/jsx-boolean-value
                enablePagination={true}
                style={{ height: 'auto' }}
                linkRows
                linkFormatter={() => `./item-details?toggled=${toggled}`}
                linkStateFormatter={(row) => row}
              />
            ) : (
              <CustomTable
                headCells={headCellsTable}
                rowIdField='id'
                rows={filteredRows}
                verticalBorders={false}
                displayFooter={false}
                enablePagination='true'
                style={
                  // filteredRows?.length === 0
                  { height: 'auto' }
                  // : { height: '1000px' }
                }
                linkRows
                linkFormatter={() => `./item-details?toggled=${toggled}`}
                linkStateFormatter={(row) => row}
                filterFormettedFunctionContainer={
                  filterFormettedFunctionContainer
                }
                columnsTobeRender={columnsTobeRender}
                styleBodyCell={(index) => ({
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: !isResizable[index]
                    ? `${maxWidth[index]}px`
                    : '10px',
                })}
                setMaxWidth={(index, value) => {
                  setMaxWidth((prevMaxWidth) => {
                    const newMaxWidth = [...prevMaxWidth];
                    newMaxWidth[index] = value;
                    return newMaxWidth;
                  });
                }}
                setResizable={(index, value) => {
                  setResizable((prevResizable) => {
                    const newResizable = [...prevResizable];
                    newResizable[index] = value;
                    return newResizable;
                  });
                }}
              />
            )}
            <Box sx={{ margin: '16px' }}>
              <Button
                sx={{
                  width: '200px',
                  display: 'flex',
                  margin: '25px auto',
                }}
                variant='outlined'
                onClick={handleRedirect}
              >
                Back
              </Button>
            </Box>
          </ErrorBoundary>
        </StyledPaper>
      </Container>

      <AddColumnModal
        openModel={openCustomCol}
        setModelOpen={setopenCustomCol}
        modalTitle='Add or Delete Columns'
        setftype=''
      />
    </>
  );
};

export { AffectedItemsList };
