/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  UsersList: {},
  // isLoading: true,
  isError: false,
};

const UsersListRedux = createSlice({
  name: 'UsersList',
  initialState,
  reducers: {
    setUsersList(state, action) {
      state.UsersList = action.payload;
      // state.isLoading = false; // Set loading state to false when data is fetched
    },
    setLoadingState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.isLoading = action.payload;
    },
    setErrorState(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.isError = action.payload;
    },
  },
});

// eslint-disable-next-line import/no-default-export
export default UsersListRedux.reducer;
export const { setUsersList, setLoadingState, setErrorState } =
  UsersListRedux.actions;
