/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useContext, useEffect } from 'react';

import { Box } from '@mui/material';
import _ from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';
import { Filter } from '../../../../../../components/Filter';
import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { AdInsightsMeta } from '../../../../Databoxes/AdInsightsMeta';
import { Loading } from '../../../../../../components/Databox/Loading';
import { getDashboardDataService } from '../../../../../../utils/getDashboardDataService';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';
import { ErrorComponent } from '../../../../../../components/GlobalErrorComponent';

const createFilterList = (rawFilters) => [
  { name: 'All', value: 'ALL' },
  ...rawFilters.map((filter) => ({
    name: _.startCase(_.toLower(filter)),
    value: filter,
  })),
];

const AdInsightsMetaContainer = ({ accountId, dateRange }) => {
  const { account } = useContext(UserInfoContext);
  const [count, setCount] = useState(0);
  const memoizedArguments = useMemo(
    () => ({
      date_range: dateRange,
      account_id: accountId,
      databox_type: 'ADS_INSIGHTS',
    }),
    [accountId, dateRange]
  );

  const [state] = useDataFetcher(
    [],
    getDashboardDataService('meta'),
    memoizedArguments,
    count
  );
  const [filter, setFilter] = useState();

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  // set filter once filter list is fetched
  useEffect(() => {
    if (state.data?.filter && !filter) {
      setFilter(createFilterList(state.data.filter)[0]);
    }
  });

  return (
    <Databox>
      <ErrorBoundary
        fallback={
          <ErrorComponent
            title='Ad Insights'
            info='Know the progress of the ad campaigns of your account.'
          />
        }
      >
        <DataboxHeading
          title='Ad Insights'
          info='Know the progress of the ad campaigns of your account.'
        >
          {/* dont show unless filter is set */}
          {filter ? (
            <Filter
              label='Filter'
              selectedFilter={filter}
              filterList={createFilterList(state.data.filter)}
              handleFilterChange={handleFilterChange}
            />
          ) : null}
        </DataboxHeading>
        <FetchingDisplay
          isLoading={state.isLoading || !filter}
          isError={state.isError}
          LoadingElement={<Loading />}
          ErrorElement={
            <ErrorDisplay
              refresh={() => {
                setCount(count + 1);
              }}
              message={
                state.errorInfo?.code === 204
                  ? 'No data available for selected date range.'
                  : state.errorInfo?.code === 400
                  ? "Couldn't load data"
                  : 'Something went wrong'
              }
              showRefreshButton={
                !(
                  state.errorInfo?.code === 400 || state.errorInfo?.code === 204
                )
              }
            />
          }
          SuccessElement={
            <Box m={2} sx={{ overflowY: 'auto' }}>
              <AdInsightsMeta
                data={state.data?.campaign_ads}
                filter={filter}
                currencyCode={account.currency_code}
              />
            </Box>
          }
        />
      </ErrorBoundary>
    </Databox>
  );
};

export { AdInsightsMetaContainer };
