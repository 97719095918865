/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { useField } from 'formik';
import React, { useContext } from 'react';
import { UserInfoContext } from '../../context/UserInfoContext';

const CheckboxInput = ({ name, label, sx }) => {
  const { user } = useContext(UserInfoContext);
  const [field, meta, helpers] = useField(name);
  const checked = field.value === "on" ? true : field.value;
  return (
    <FormControl error={meta.error && meta.touched} sx={sx}>
      <FormControlLabel
        sx={{ marginLeft: 0 }}
        control={
          <Checkbox
            name={name}
            disableRipple
            sx={{
              backgroundColor: '#FFF',
              padding: 0,
              borderRadius: 0,
              mr: 1,
              ...sx,
            }}
            {...field}
            checked={checked}
            onChange={(e) => helpers.setValue(e.target.checked)}
            disabled={user.role === 'COLLABORATOR' || user.role === 'SUB_COLLABORATOR'}
          />
        }
        label={label}
      />
    </FormControl>
  );
};

export { CheckboxInput };
