// React imports
import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

// Local Pages imports
import { DemoDashboard } from '../pages/Dashboards/Pages/DemoDashboard';
import { Error404Page } from '../pages/Error404Page/Error404Page';
import { FeedManagementMain } from '../pages/FeedManagement/Main/FeedManagementMain';

// Local component imports
import { ProtectedRoute } from './ProtectedRoute';

// Other local imports
import { Nav } from '../components/Navigation';
import { Dashboard } from '../pages/Dashboards/Pages/Dashboard';
import { SignInPage } from '../pages/Forms/Pages/SignInPage';
import { SignUpNewPage } from '../pages/Forms/Pages/SignUpNewPage';
import { CreateCompanyPage } from '../pages/ProjectManagement/Pages/CreateCompanyOnboarding';
import { InvitationPage } from '../pages/Forms/Pages/InvitationPage';
import { ResetPasswordPage } from '../pages/Forms/Pages/ResetPasswordPage';
import { ForgetPasswordPage } from '../pages/Forms/Pages/ForgetPasswordPage';
import { EditProfilePage } from '../pages/Forms/Pages/EditProfilePage';
import { ShopifyPlatform } from '../pages/ProjectManagement/Pages/AccountList/components/LinkAccountSpeedDial/parts/ShopifyPlatform';
import { MccIdForm } from '../pages/ProjectManagement/Pages/AccountList/components/LinkAccountSpeedDial/utils/MccIdForm';

import { Oauth2CallBackComponent } from '../pages/ProjectManagement/Pages/0auth';
import { Footer } from '../components/Footer';
import { UserManagementRouter } from '../pages/UserManagement/Router';
import { CompanyList } from '../pages/ProjectManagement/Pages/CompanyList';
import { POASmain } from '../pages/POAS/Main/POASmain';
import { FeedManagementDemoDashboard } from '../pages/FeedManagementDemoDashboard/index';
import { DemoFeedForm } from '../pages/FeedManagementDemoDashboard/Form';
import { AccountList } from '../pages/ProjectManagement/Pages/AccountList';
import { SubmitMessage } from '../pages/Forms/Pages/SubmitPage';
import { UserInfoContext } from '../context/UserInfoContext';
import { Details } from '../pages/UserManagement/UserDetails/Details';
import { RedirectToadhelpFromShopify, Shopify } from '../services/ShopifyFLow';
import { InvitesPage } from '../pages/Forms/Pages/InvitesPage';

const Routers = () => {
  const { user, isLoggedIn, companyList } = useContext(UserInfoContext);

  const createCompanyRedirectPath =
    user.projectType === 'AGENCY' ? '/companies' : '/accounts';
  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute
            isAllowed={false}
            redirectPath={isLoggedIn ? '/accounts' : '/demo-dashboard'}
          />
        }
      />

      {/* dashboard */}
      <Route
        path='demo-dashboard'
        element={
          <>
            <Nav />
            <DemoDashboard />
            <Footer />
          </>
        }
      />
      <Route
        path='dashboard'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            <Nav />
            <Dashboard />
            <Footer />
          </ProtectedRoute>
        }
      />

      {/* feed management  */}
      <Route
        path={isLoggedIn ? 'feed-management/*' : 'feed-management'}
        element={
          isLoggedIn ? (
            <FeedManagementMain />
          ) : (
            <>
              <Nav />
              <FeedManagementDemoDashboard />
            </>
          )
        }
      />

      <Route
        path={!isLoggedIn ? 'feed-management/form' : null}
        element={
          <>
            <Nav />
            <DemoFeedForm />
          </>
        }
      />

      {/* accounts pages  */}
      <Route
        path='accounts'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            <Nav />
            <AccountList />
          </ProtectedRoute>
        }
      />

      {/* cpmpanies pages  */}

      <Route
        path='/companies'
        element={
          <ProtectedRoute
            isAllowed={
              isLoggedIn &&
              user.projectType === 'AGENCY' &&
              ['ADMIN', 'OWNER', 'COLLABORATOR'].includes(user.role)
            }
            redirectPath={isLoggedIn ? '/accounts' : '/signin'}
          >
            <Nav />
            <CompanyList />
          </ProtectedRoute>
        }
      />

      {/* sign in */}
      <Route path='signin' element={<SignInPage />} />
      <Route path='signin/:verificationToken' element={<SignInPage />} />
      <Route path='/signinsubmit' element={<SubmitMessage state='error' />} />

      {/* sign up  */}
      <Route
        path='signup'
        element={
          <ProtectedRoute
            isAllowed={!isLoggedIn}
            redirectPath='/dashboard'
            escapeProjectCheck
          >
            <SignUpNewPage />
          </ProtectedRoute>
        }
      />
      <Route path='/signupsubmit' element={<SubmitMessage state='success' />} />

      <Route path='/resetsubmit' element={<SubmitMessage state='empty' />} />

      {/* reset password  */}
      <Route path='reset-password/:token' element={<ResetPasswordPage />} />

      {/* forgot password  */}
      <Route path='forgot-password' element={<ForgetPasswordPage />} />

      <Route path='oauth2callback' element={<Oauth2CallBackComponent />} />

      {/* shopify */}
      <Route path='shopify' element={<Shopify />} />
      <Route path='shopify-platform' element={<ShopifyPlatform />} />
      <Route
        path='shopifyredirectoauth2callback'
        element={<RedirectToadhelpFromShopify />}
      />

      {/* poas */}
      <Route
        path={!isLoggedIn ? '/profit' : ''}
        element={
          <>
            <Nav />
            <POASmain />
          </>
        }
      />

      {/* user management */}
      <Route
        path='invites'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            <InvitationPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='users/*'
        element={
          <ProtectedRoute
            isAllowed={isLoggedIn}
            redirectPath='/signin'
            escapeProjectCheck
          >
            <Nav />
            <UserManagementRouter />
          </ProtectedRoute>
        }
      />

      {/* profile  */}
      <Route
        path='edit-profile'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            <EditProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path='details'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            <Nav />
            <Details />
          </ProtectedRoute>
        }
      />
      <Route
        path='invites-page'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            <Nav />
            <InvitesPage />
          </ProtectedRoute>
        }
      />

      <Route path='mccid' element={<MccIdForm />} />
      <Route
        path='create-company'
        element={
          <ProtectedRoute
            isAllowed={isLoggedIn && companyList?.length < 1}
            redirectPath={
              // eslint-disable-next-line no-nested-ternary
              !isLoggedIn ? '/signin' : createCompanyRedirectPath
            }
            escapeProjectCheck
          >
            <CreateCompanyPage />
          </ProtectedRoute>
        }
      />

      <Route path='*' element={<Error404Page />} />
    </Routes>
  );
};

export { Routers };
