/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import { Typography, Box, Stack, Container } from '@mui/material';
import _ from 'lodash';
import { StyledPaper, ButtonComponent } from '../../Components/utils';
import {
  useGetProjectListQuery,
  useDeleteUsersDetailsMutation,
} from '../../../../api/RTK/services/user';
import { PasswordDeleteDialog } from '../../Components/DeleteConfirmationDialog/PasswordDeleteDialog';
import {
  DeleteDialog,
  SuccessDialog,
  ErrorDialog,
} from '../../Components/DeleteConfirmationDialog/DeleteDialog';
import { FetchingDisplay } from '../../../../components/FetchingDisplay';
import { CustomTable } from '../../../../components/Table';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { UserInfoDispatchContext } from '../../../../context/UserInfoDispatchContext';
import { userLogout } from '../../../../utils/userInfoDispatchFunctions';
import { UsersSkeleton } from '../../Components/Skeleton/UsersSkeleton';
import { UserToolbar } from '../../UserToolbar';
import { PageErrors } from '../../../../components/PageErrors';
import { RemoveButton } from '../../Components/RemoveButton';
import { IconDecider } from '../../Components/IconDecider';
import { getAccessToken } from '../../../../utils/token';

const headCells = (openModal, data, user, isEditable = false) => [
  {
    id: 'username',
    numeric: false,
    label: `${
      !data?.company_users[0]?.users?.length
        ? `Users (0)`
        : `Users (${data?.company_users[0]?.users?.length})`
    }`,
    disablePadding: false,
    isImage: false,
    formatFunction: (row) => (
      <Stack
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='row'
      >
        <IconDecider row={row} fill='#0096FF' />
        <Box display='flex' flexDirection='column'>
          <Typography variant='smallMedium' sx={{ mb: 0.2 }}>
            {row.username}
          </Typography>
          <Typography variant='smallRegular'>{row.email}</Typography>
        </Box>
      </Stack>
    ),
  },
  {
    id: 'role',
    numeric: false,
    label: data?.company_users[0]?.users?.length > 0 ? 'Company Access' : '',
    disablePadding: false,
    isImage: false,
    formatFunction: (row) => (
      <Typography variant='smallMedium'>
        {['SUB_ADMIN'].includes(row?.role)
          ? 'Company Admin'
          : ['SUB_COLLABORATOR'].includes(row?.role)
          ? 'Company Collaborator'
          : _.startCase(row.role.toLowerCase())}
      </Typography>
    ),
  },
  ...(data?.company_users[0]?.users?.length > 0
    ? [
        {
          id: 'edit',
          numeric: false,
          label: '',
          disablePadding: false,
          isImage: false,
          formatFunction: (row) => {
            if (row.role === 'OWNER' || row.id === user.id) {
              return null;
            }
            if (isEditable) {
              return (
                <ButtonComponent
                  disabled={row.isLoading}
                  to={`/users/company/edit-company-user/${row.id}`}
                />
              );
            }
            return null;
          },
        },
      ]
    : []),
  ...(data?.company_users[0]?.users?.length > 0
    ? [
        {
          id: 'remove',
          numeric: false,
          label: '',
          disablePadding: false,
          isImage: false,
          formatFunction: (row) => {
            if (row.role === 'OWNER') {
              return null;
            }
            if (
              ['COLLABORATOR', 'SUB_COLLABORATOR'].includes(row.role) &&
              row.id === user.id
            ) {
              return (
                <RemoveButton text='Exit' onClick={() => openModal(row?.id)} />
              );
            }
            if (isEditable) {
              return (
                <RemoveButton
                  text={user.id === row?.id ? 'Exit' : 'Remove'}
                  edit={user.id === row?.id}
                  onClick={() => openModal(row?.id)}
                />
              );
            }
            return null;
          },
        },
      ]
    : []),
];

// adds accessibleCount field to row object. This is necessary for sorting
const dataFormatter = (rawData) =>
  rawData?.map((d) => ({
    ...d,
  }));
const CompanyPage = () => {
  const token = getAccessToken();
  const {
    data: allUsersList,
    isLoading: loadingState,
    isError: errorState,
    error: errorStatus,
  } = useGetProjectListQuery({ skip: token });

  const [id, setId] = useState(null);
  const [open, setOpen] = useState(false);
  const [list, setList] = useState(allUsersList);
  const dispatch = useContext(UserInfoDispatchContext);

  const { user } = useContext(UserInfoContext);
  const isEditable = ['ADMIN', 'OWNER', 'SUB_ADMIN'].includes(user.role);
  const [msg, setMsg] = useState(false);
  const [deleteUser, { isError, isSuccess, error }] =
    useDeleteUsersDetailsMutation();

  const openDialog = (idArg) => {
    setId(idArg);
    setOpen(true);
  };

  const closeDialog = () => {
    setId(null);
    setOpen(false);
  };

  const closeModalWithClick = () => {
    setMsg(false);
  };
  const handleDelete = (userID) => {
    deleteUser({ id: userID })
      .then(() => {
        setOpen(false);
        setMsg(true);
        setTimeout(() => {
          setMsg(false);
        }, 2000);
        if (user.id === userID) {
          userLogout(dispatch);
        }
      })
      .catch(() => {
        setMsg(false);
      });
  };
  return (
    <FetchingDisplay
      isLoading={loadingState}
      isError={errorState}
      LoadingElement={<UsersSkeleton />}
      ErrorElement={
        <PageErrors
          code={errorStatus?.status}
          message={errorStatus?.data?.error?.detail}
          isServerError
          hideText
          customButton
        />
      }
      SuccessElement={
        <>
          <UserToolbar
            list={list}
            setList={setList}
            allUsersList={allUsersList}
          />
          {allUsersList ? (
            <Container
              maxWidth={false}
              sx={{
                mt: 1,
                mb: 2,
                position: 'relative',
                '.MuiTableContainer-root': {
                  borderRadius: '14px',
                },
              }}
            >
              <StyledPaper>
                <CustomTable
                  headCells={headCells(openDialog, list, user, isEditable)}
                  rows={dataFormatter(
                    list?.company_users[0]?.users?.length > 0
                      ? list?.company_users[0]?.users
                      : [
                          {
                            id: '',
                            email: '',
                            username: 'No User Found',
                            is_active: false,
                            role: '',
                          },
                        ]
                  )}
                  rowIdField='id'
                  displayFooter={false}
                  defaultOrder='desc'
                  defaultOrderBy='role'
                  height='auto'
                />
              </StyledPaper>
              {user.id === id ? (
                <PasswordDeleteDialog
                  open={Boolean(open && id)}
                  handleClose={closeDialog}
                  handleConfirm={() => {
                    handleDelete(id);
                  }}
                  buttonText='Exit'
                  copy={`Please note that after you exit you will no longer have
                  access to this ${_.capitalize(user.projectType)}.`}
                />
              ) : (
                <>
                  <DeleteDialog
                    open={Boolean(open && id)}
                    handleClose={closeDialog}
                    handleConfirm={() => {
                      handleDelete(id);
                    }}
                  />
                  {isSuccess && (
                    <SuccessDialog
                      msgopen={msg}
                      closeModalWithClick={closeModalWithClick}
                    />
                  )}
                  {isError && (
                    <ErrorDialog
                      msgopen={msg}
                      copy={error}
                      closeModalWithClick={closeModalWithClick}
                    />
                  )}
                </>
              )}
            </Container>
          ) : null}
        </>
      }
    />
  );
};

export { CompanyPage };
