import React from 'react';
import { Box, Typography } from '@mui/material';
import { CloseButton } from '../CloseButton';
import { StyledModal } from '../../pages/ProjectManagement/Pages/AccountList/components/LinkAccountSpeedDial/utils/StyledPlatformComponents';
import { GoogleAnalyticsInvite } from '../../constants/endpoints/assests/video';
import { GoogleAnalyticsPoster } from '../../constants/endpoints/assests/Other';

const VideoModal = ({ openVideo, handleOpen, handleClose }) => (
  <>
    <Box sx={{ display: 'flex' }}>
      <Typography
        variant='smallMedium'
        color='primary'
        onClick={handleOpen}
        mr={0.5}
        mb={2}
        sx={{ cursor: 'pointer', ' &:hover': { textDecoration: 'underline' } }}
      >
        Watch Video
      </Typography>
      <Typography variant='smallMedium'>
        to know where to find MCC ID.
      </Typography>
    </Box>
    <StyledModal
      open={openVideo}
      onClose={handleClose}
      aria-labelledby='child-modal-title'
      aria-describedby='child-modal-description'
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'grid',
          '& video': {
            width: '80%',
            placeSelf: 'center center',
            mt: 0,
            '@media (max-width:1200px)': {
              mt: 4,
              width: '90%',
              placeSelf: 'start center',
            },
            '@media (max-width:900px)': {
              mt: 4,
              width: '98%',
              placeSelf: 'start center',
            },
          },
        }}
      >
        <video autoPlay loop muted poster={GoogleAnalyticsPoster} controls>
          <source src={GoogleAnalyticsInvite} type='video/mp4' />
        </video>
        <CloseButton onClick={handleClose} />
      </Box>
    </StyledModal>
  </>
);

export { VideoModal };
