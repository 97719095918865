/* eslint-disable no-unused-expressions */
import React from 'react';

import { Box } from '@mui/material';
import _ from 'lodash';

import { ImageSlider } from '../../../../components/ImageSlider';
import { EmptyImage } from '../../../../constants/endpoints/assests/Other';

const TopProducts = ({ data }) => {
  // Creates labels.
  const labelGenerator = (metric) => {
    switch (metric) {
      case 'Clicks':
        return 'Highest Clicked product.';
      case 'Roas':
        return 'Highest ROAS product.';
      case 'Ctr':
        return 'Highest CTR product.';
      case 'Roi':
        return 'Highest ROI product.';
      default:
        return `Highest ${metric} product.`;
    }
  };

  // converts metric name from snake_case to space separated and capitalized string
  const metricNameFormatter = (metric) =>
    `${metric
      .split('_')
      .map((s) => _.capitalize(s))
      .join(' ')}`;

  // eg: converts 3000 to 3K
  const valueFormatter = (value, metricName = '') => {
    if (value > 1000) {
      return `${Math.round(value / 1000)}K`;
    }
    if (metricName.toLowerCase === 'roas' || metricName.toLowerCase === 'cpc') {
      return `${_.round(value, 1)}`;
    }
    return `${_.round(value, 1)}`;
  };

  const dataFormatter = (rawData) =>
    rawData
      .map((a) => ({
        imageSrc: a.imageLink ? a.imageLink : EmptyImage,
        link: a.link,
        label: labelGenerator(
          metricNameFormatter(a.metricName),
          valueFormatter(a.value, a.metricName),
          !a.highest
        ),
      }))
      .filter((a) => a.imageSrc !== '--');

  return (
    <Box sx={{ padding: 3, paddingInline: 6, minHeight: 0, flexGrow: 1 }}>
      <ImageSlider
        data={dataFormatter(data)}
        idField={['title', 'metricName']}
      />
    </Box>
  );
};

export { TopProducts };
