const sendReducer = (state, action) => {
  switch (action.type) {
    case 'PRESEND':
      return { status: 'PRESEND', data: null };
    case 'SENDING':
      return { status: 'SENDING', data: null };
    case 'SENDSUCCESS':
      return { status: 'SENDSUCCESS', data: action.data };
    case 'SENDERROR':
      return { status: 'SENDERROR', error: action.error };
    default:
      return state;
  }
};

export { sendReducer };
