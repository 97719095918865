import React, { useState, useMemo, useContext } from 'react';

import { Box } from '@mui/material';

import { TableChartOutlined, PeopleAlt } from '@mui/icons-material';
import { Filter } from '../../../../../../components/Filter';
import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';

import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { UserByAgeTable } from '../../../../Databoxes/UserByAge/Table';
import { UserByAgeChart } from '../../../../Databoxes/UserByAge/Chart';
import { CustomSwitch } from '../../../../../../components/CustomSwitch/CustomSwitch';
import { Loading } from '../../../../../../components/Databox/Loading';

import { getDemoDashboardService } from '../../../../../../services/DemoDashboard';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { AccountContext } from '../../accountContext';

const getFilterList = (platform) =>
  platform === 'meta'
    ? [
        {
          name: 'Reach',
          value: 'Reach',
        },
        {
          name: 'Cost',
          value: 'Spend',
        },
        {
          name: 'Clicks',
          value: 'Clicks',
        },
        {
          name: 'Impressions',
          value: 'Impressions',
        },
        {
          name: 'CPC',
          value: 'Cpc',
        },
      ]
    : [
        {
          name: 'Clicks',
          value: 'clicks',
        },
        {
          name: 'Cost',
          value: 'marketing_cost',
        },
        {
          name: 'Conversions',
          value: 'conversions',
        },
        {
          name: 'Revenue',
          value: 'online_sales',
        },
        {
          name: 'ROAS',
          value: 'roas',
        },
      ];

const DisplayComponentSelector = ({ showTable, chart, table }) => (
  <Box flexGrow={1} minHeight={0}>
    {showTable ? table : chart}
  </Box>
);

const UserByAgeContainer = ({ platform }) => {
  const { currencyCode } = useContext(AccountContext);
  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'AGE',
    }),
    []
  );

  const [state] = useDataFetcher(
    [],
    getDemoDashboardService(platform),
    memoizedArgs
  );
  const [filter, setFilter] = useState(getFilterList(platform)[0]);
  // eslint-disable-next-line no-unused-vars
  const [showTable, setShowTable] = useState(false);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleSwitchChange = () => {
    setShowTable(!showTable);
  };

  return (
    <Databox>
      <DataboxHeading
        title='Users by Age'
        info='Know the users interested in your ads by age group. Click on the table icon to switch to a tabular format.'
      >
        {!showTable ? (
          <Filter
            label='Filter'
            selectedFilter={filter}
            filterList={getFilterList(platform)}
            handleFilterChange={handleFilterChange}
          />
        ) : null}
      </DataboxHeading>
      <FetchingDisplay
        isError={state.isError}
        isLoading={state.isLoading}
        LoadingElement={<Loading />}
        ErrorElement={<ErrorDisplay />}
        SuccessElement={
          <DisplayComponentSelector
            showTable={showTable}
            chart={
              <Box p={2} height='100%'>
                <UserByAgeChart
                  data={state.data}
                  filter={filter}
                  currencyCode={currencyCode}
                />
              </Box>
            }
            table={
              <UserByAgeTable
                data={state.data}
                platform={platform}
                currencyCode={currencyCode}
              />
            }
          />
        }
      />

      <Box sx={{ display: 'flex', justifyContent: 'end', padding: 2 }}>
        <CustomSwitch
          checked={showTable}
          handleChange={handleSwitchChange}
          CheckedIcon={TableChartOutlined}
          Icon={PeopleAlt}
        />
      </Box>
    </Databox>
  );
};

export { UserByAgeContainer };

