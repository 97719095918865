const valueMap = (fields) => ({
  SET_TO_VALUE: {
    value: '',
  },
  APPEND_VALUE: { value: '' },
  COPY_VALUE: { value: fields[0] },
  COMBINE_VALUE: [
    {
      value: '',
      type: 'static',
    },
  ],
  SEARCH_FOR_VALUE: { using: '', replaces: '', value: fields[0] },
  REPLACE_VALUE: {
    query: [
      {
        value: '',
        type: 'static',
      },
    ],
    value: [
      {
        value: '',
        type: 'static',
      },
    ],
    caseSensitive: false,
  },
  LOOKUP_AND_REPLACE_VALUE: {
    replaces: '',
    value: '',
    caseSensitive: false,
  },
  SEARCH_AND_REPLACE_VALUE: {
    replaces: '',
    value: '',
    caseSensitive: false,
  },
  SPLIT_TEXT: {
    field: fields[0],
    separator: ',',
    useFrom: 'first',
    useTill: 'second',
    addTogetherUsing: '',
  },
  MAXIMUM_LENGTH: {
    option: 'trim_on_words',
    value: 0,
  },
  MODIFY_TEXT: {
    value: 'CAPITALIZE',
  },
  ROUND_NUMBER: {
    direction: 'closest',
    value: '1_cent',
  },
  REFORMAT_NUMBER: {
    number_of_decimals: 'no_rounding',
    thousands_separator: ',',
    decimal_separator: '.',
  },
  CALCULATE: {
    field: fields[0],
    value: '',
    operation: 'multiply_by',
  },
  CALCULATE_SUM: {
    value: fields[0],
  },
  CALCULATE_LENGTH: {
    value: fields[0],
  },
  CALCULATE_LIST_LENGTH: {
    value: fields[0],
  },
  GROUP_ITEMS: {
    value: [],
  },
  SPLIT_ITEMS: {},
  DEDULICATE_ITEMS: {
    sub_filter: 'exclude_duplicates',
    preservedField: fields[0],
    sort: false,
  },
  CALCULATE_ITEM_GROUP: {
    method: 'count_items',
    field: fields[0],
    storageField: fields[1],
    sort: false,
  },
  SORT_LIST: { sort_numbers: false, direction: 'desc' },
  SLICE_LIST: { field: fields[0], start: 'first', end: 'second' },
  SPLIT_TEXT_TO_LIST: { value: '' },
  JOIN_LIST_TO_TEXT: { value: '' },
  DEDULICATE_LIST: {},
});

export { valueMap };
