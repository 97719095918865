import React, { useMemo } from 'react';

import { Databox } from '../../../../../../components/Databox';
import { Loading } from '../../../../../../components/Databox/Loading';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';

import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { ConversionFunnelChart } from '../../../../Databoxes/ConversionFunnel/Chart';

import { getDemoDashboardService } from '../../../../../../services/DemoDashboard';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';

const ConversionFunnelContainer = ({ platform }) => {
  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'CONVERSION_FUNNEL',
    }),
    []
  );

  const [state] = useDataFetcher(
    [],
    getDemoDashboardService(platform),
    memoizedArgs
  );
  return (
    <Databox>
      <DataboxHeading
        title='Conversion Funnel'
        info='Know how many users have advanced from adding items to the cart to buying 
        them. Also, know the conversion percentage for a quick understanding.'
      />
      <FetchingDisplay
        isError={state.isError}
        isLoading={state.isLoading}
        LoadingElement={<Loading />}
        ErrorElement={<ErrorDisplay />}
        SuccessElement={<ConversionFunnelChart data={state.data} />}
      />
    </Databox>
  );
};

export { ConversionFunnelContainer };

