import { styled } from '@mui/system';
import { Link, Box, InputLabel } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Field } from 'formik';

const StyledFormikWrapper = styled(Box)(
  () => `
      display: flex;
      flex-direction: column;
      .MuiFormLabel-root{
        text-align:left;
      }
    `
);

const StyledFormikField = styled(Field)(
  ({ theme }) => `
 .MuiOutlinedInput-input{
    padding: ${theme.spacing(1.2)} ${theme.spacing(1)}
 }
 .MuiFormHelperText-root{
    margin-left: 0px;
    margin-right: 0px;
 }
`
);

const StyledInputLabel = styled(InputLabel)(
  ({ theme }) => `
      margin-bottom:${theme.spacing(1)} `
);

const StyledLink = styled(RouterLink)(
  ({ theme }) => `
    color:${theme.palette.primary.main};
    text-decoration:none;
    font-size: 0.9rem;
    &:hover{
      text-decoration: underline;
      color:${theme.palette.primary.main};
    }
    `
);
const StyledMuiLink = styled(Link)(
  ({ theme }) => `
    color:${theme.palette.primary.main};
    text-decoration:none;
    font-size: 0.9rem;
    font-weight:400;
    &:hover{
      text-decoration: underline;
      color:${theme.palette.primary.main};
    }
    `
);

export {
  StyledFormikWrapper,
  StyledFormikField,
  StyledInputLabel,
  StyledLink,
  StyledMuiLink,
};
