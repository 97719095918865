import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { SignInForm } from '../Form/SignInForm';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { PagesWrraper } from './PagesWrraper';

const SignInPage = () => {
  const {
    user: { project_type: projectType, invitationCount, projectId, role },
    isLoggedIn,
    companyList,
  } = useContext(UserInfoContext);

  if (!isLoggedIn) {
    return (
      <PagesWrraper>
        <SignInForm />
      </PagesWrraper>
    );
  }
  if (invitationCount > 0 && !projectType && !projectId)
    return <Navigate to='/invites' />;
  if (!companyList || companyList.length === 0)
    return <Navigate to='/create-company' />;
  if (projectType === 'COMPANY') return <Navigate to='/accounts' />;
  if (
    projectType === 'AGENCY' &&
    ['SUB_ADMIN', 'SUB_COLLABORATOR'].includes(role)
  )
    return <Navigate to='/accounts' />;
  return <Navigate to='/companies' />;
};

export { SignInPage };
