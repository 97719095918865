import { styled } from '@mui/system';
import { Box, ListItemIcon } from '@mui/material';

const StyledWrapperBox = styled(Box)`
  background-size: 100%;
  border-radius: 18px;
  position: relative;
`;

const StyledAccountMenuBox = styled(Box)(
  ({ theme }) => `
  position: absolute;
  top: 10%;
  right: 0%;
  transform: translate(-50%,-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 20px;
  background-color: #fff;
  border-radius: 2px;
  border:1px solid ${theme.palette.border.main};
                `
);

const StyledListItemIconBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: ${theme.shadows[3]};
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: absolute;
    top: 0%;
    left: 10%;
    transform: translate(-50%,-50%);
                  `
);

const StyledListItemIcon = styled(ListItemIcon)(
  ({ theme }) => `
    height: ${theme.spacing(4)};
    min-width: 30px;
    max-width:35px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      height: 100%;
      width:100%;
    }

                  `
);

export {
  StyledAccountMenuBox,
  StyledWrapperBox,
  StyledListItemIconBox,
  StyledListItemIcon,
};
