import React, { useContext } from 'react';
import { Button, Container, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Selectors } from '../Components/Selectors';
import { Operations } from '../Components/Operations';
import { initialValues } from './initialValues';
import { TextInput } from '../../../../../components/Inputs/TextInput';
import { actionSchema } from './Validator/schema';
import { UserInfoContext } from '../../../../../context/UserInfoContext';

const ActionForm = ({ fields, initialData, handleSubmit, btnName }) => {
  const { user } = useContext(UserInfoContext);
  return (
    <Formik
      initialValues={
        initialData
          ? { ...initialData, projectFields: fields }
          : {
              ...initialValues,
              projectFields: fields,
            }
      }
      onSubmit={handleSubmit}
      validationSchema={actionSchema}
    >
      {() => (
        <Form>
          <Container
            maxWidth='none'
            sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
          >
            <Stack direction='row' alignItems='center' gap={3}>
              <TextInput
                name='name'
                label='Name'
                id='filter-name'
                placeholder='Enter Action Name'
                sx={{
                  flex: '1 0 200px',
                  '& .MuiOutlinedInput-root .Mui-disabled': {
                    backgroundColor: 'transparent',
                  },
                }}
                disabled={
                  user?.role === 'COLLABORATOR' ||
                  user?.role === 'SUB_COLLABORATOR'
                }
              />
              <TextInput
                name='description'
                label='Description'
                id='filter-description'
                placeholder='Enter Action Description'
                sx={{
                  flex: '1 0 200px',
                  '& .MuiOutlinedInput-root .Mui-disabled': {
                    backgroundColor: 'transparent',
                  },
                }}
                disabled={
                  user?.role === 'COLLABORATOR' ||
                  user?.role === 'SUB_COLLABORATOR'
                }
              />
            </Stack>
            <Selectors fields={fields} />
            <Operations fields={fields} />

            <Stack direction='row' justifyContent='center' alignItems='center'>
              <Button
                sx={{
                  width: '200px',
                  margin: '5px',
                  textAlign: 'center',
                  textDecoration: 'none',
                }}
                variant='outlined'
                as={Link}
                to='../feeds/enhance'
              >
                Back
              </Button>
              {user?.role !== 'COLLABORATOR' &&
                user?.role !== 'SUB_COLLABORATOR' && (
                  <Button
                    sx={{
                      width: '200px',
                      margin: '25px 5px',
                    }}
                    variant='contained'
                    type='submit'
                  >
                    {btnName}
                  </Button>
                )}
            </Stack>
          </Container>
        </Form>
        // )
      )}
    </Formik>
  );
};
export { ActionForm };
