import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const Datalist = styled('dl')`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const HeadingNameValue = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 5;
  padding: 1rem;
`;

const NameValueWrapper = styled('div')(({ theme, percentage }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(1.75),
  margin: '0rem 1rem 1.25rem 1rem',
  position: 'relative',
  '&:before': {
    background: theme.palette.background.grey,
    borderRadius: '99px',
    bottom: '0px',
    content: '""',
    height: theme.spacing(0.75),
    left: '0px',
    position: 'absolute',
    right: '0px',
  },
  '&:after': {
    background: theme.palette.primary.main,
    borderRadius: '99px',
    bottom: '0px',
    content: '""',
    height: theme.spacing(0.75),
    left: '0px',
    position: 'absolute',
    width: `${Math.round(percentage)}%`,
  },
}));

const DataTerm = styled(Typography)`
  flex: 1 0 0;
  max-width: 50%;
  overflow: hidden;
  text-align: end;
  text-overflow: ellipsis;
`;

const DataDescription = styled(Typography)`
  flex: 1 0 0;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export {
  Datalist,
  DataTerm,
  DataDescription,
  HeadingNameValue,
  NameValueWrapper,
};

