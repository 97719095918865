import { styled } from '@mui/system';

// MUI imports
import { Paper } from '@mui/material';

const StyledTitlePaper = styled(Paper)(
  ({ theme }) => `
  &.titlePaper{
    margin-top: 14px;
    border-radius: 8px 8px 0px 0px;
    padding: 15px 24px;
    // box-shadow: 0px 0px 5px 0px #dedede;
    border-bottom: 1px solid ${theme.palette.border.main};
    
  }
  .titleStack{
    @media (max-width: 425px) {
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
  
    `
);

const StyledTypo = styled('div')(
  ({ theme }) => `
  .title{
    padding: 10px 0px;
    font-size: 1.25rem;
    font-weight: 600;
  }
  .subTitle {
    font-size: 14px;
    margin: 2px 0px;
    color: ${theme.palette.text.primary}
  }
`
);

export { StyledTitlePaper, StyledTypo };
