/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from 'react';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Radio,
  ListItemText,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { FiberManualRecord } from '@mui/icons-material';
import { AdminIcon, CollaboratorIcon } from '../PersonSvgIcon/Admin';
import {
  StyledRadioGroup,
  StyledStyledFormControlLabel,
} from './StyledSelectCategory';
import { UserInfoContext } from '../../context/UserInfoContext';

const CategoryCheckbox = ({
  data,
  value,
  setValue,
  onChange,
  onBlur,
  name,
  error,
}) => {
  const { user } = useContext(UserInfoContext);
  const [isChecked, setIsChecked] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);
    if (onChange) onChange(event);
    setIsChecked(!isChecked);
    setChecked(isChecked);
  };

  const handleBlur = (event) => {
    if (onBlur) onBlur(event);
  };

  return (
    <StyledRadioGroup
      row
      aria-labelledby='demo-row-radio-buttons-group-label'
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    >
      <Typography variant='smallMedium' sx={{ mb: 0.2 }}>
        Select Access
      </Typography>
      <Grid
        container
        spacing={2}
        gap={2}
        sx={{
          '& .MuiFormControlLabel-root': {
            border: (theme) =>
              error && `1px solid ${theme.palette.error.main} `,
          },
          '& .MuiFormControlLabel-root .MuiRadio-root': {
            borderBottom: (theme) =>
              error && `1px solid ${theme.palette.error.main} `,
          },
        }}
      >
        {data?.map((item) => (
          <Grid item xs={12} sm={12} md={5} lg={4} key={item.value}>
            <StyledStyledFormControlLabel
              value={item.value}
              control={<Radio disableRipple sx={{ p: 2 }} />}
              label={
                <>
                  <Typography
                    variant='h4'
                    className={`radioName ${
                      value === item.value ? 'whiteText' : ''
                    }`}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      top: '3%',
                      width: '100%',
                    }}
                  >
                    {_.startCase(item.value).replace(/ /g, ' ')}
                    {item.id === 1 ? (
                      <AdminIcon
                        fill={
                          !isChecked
                            ? user.projectType === 'AGENCY' &&
                              ['ADMIN', 'OWNER', 'COLLABORATOR'].includes(
                                user.role
                              )
                              ? '#FF823C'
                              : '#0096FF'
                            : '#fff'
                        }
                        isBorder={false}
                        mr={7}
                      />
                    ) : (
                      <CollaboratorIcon
                        fill={
                          !checked
                            ? user.projectType === 'AGENCY' &&
                              ['ADMIN', 'OWNER', 'COLLABORATOR'].includes(
                                user.role
                              )
                              ? '#FF823C'
                              : '#0096FF'
                            : '#fff'
                        }
                        isBorder={false}
                        mr={7}
                      />
                    )}
                  </Typography>
                  <Box className='radioLabel'>
                    <List
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {item?.labels?.map((label) => (
                        <ListItem key={label}>
                          <ListItemIcon
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              paddingLeft: '8px',
                            }}
                          >
                            <FiberManualRecord />
                          </ListItemIcon>
                          <ListItemText
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'baseline',
                              '& .MuiTypography-root': {
                                display: 'contents',
                                textWrap: 'wrap',
                              },
                              '& .MuiListItemText-primary': {
                                textWrap: 'nowrap',
                              },
                              '& .MuiListItemText-secondary': {
                                textWrap: 'balance',
                              },
                            }}
                            primary={label}
                            secondary={
                              item?.labelSecond?.length > 0
                                ? ` -  ${
                                    item?.labelSecond[
                                      item?.labels?.indexOf(label)
                                    ]
                                  }`
                                : null
                            }
                            primaryTypographyProps={{
                              variant: 'smallMedium',
                              pr: 1,
                            }}
                            secondaryTypographyProps={{
                              variant: 'smallMedium',
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </>
              }
            />
          </Grid>
        ))}
      </Grid>
      {error && (
        <Typography variant='smallerRegular' color='error' textAlign='start'>
          {error}
        </Typography>
      )}
    </StyledRadioGroup>
  );
};

export { CategoryCheckbox };
