import React from 'react';

// MUI imports
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// Styled imports
import { StyledButtonModal } from '../StyledComponents/StyledCommon';

const OpenComponentButton = ({ name, children, setOpen, className }) => {
  const handleOpenModal = () => setOpen(true);
  return (
    <StyledButtonModal>
      <Button className={className} onClick={handleOpenModal}>
        <AddIcon />
        {name}
      </Button>
      {children}
    </StyledButtonModal>
  );
};
export { OpenComponentButton };
