import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, Container, Typography, Button, Stack } from '@mui/material';
import { TitleCard } from '../../FeedManagement/Components/GlobalComponents/TitlePaper';
import { StyledPaper } from '../../FeedManagement/PageViewComponents/StyledMainBox';


const Tips = () => (
    <Container maxWidth='xxl'>
        <TitleCard title='Tips' />
        <StyledPaper>
            <List>
                <ListItem>
                    <Typography variant="body1">
                        1. Map a field to g:availability to increase your feed quality by 6%. You can map the g:availability with an internal field or you can import or create data using actions in the Feed Management Tool
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1">
                        2. If you map all the mandatory fields your feed quality will increase to 70%. You can do this effortlessly by creating actions or adding an import in the Feed Management Tool.
                    </Typography>
                </ListItem>
            </List>

            <Stack justifyContent='center' alignItems='center' sx={{ marginBottom: '20px' }}>
                <Button
                    component={Link}
                    to='/signup'
                    variant='contained'
                    sx={{
                        textAlign: 'center',
                        minWidth: 'fit-content',
                        width: '200px',
                        marginBottom: '8px',
                        backgroundColor: 'secondary.main',
                        border: '1px solid',
                        borderColor: 'secondary.main',
                        '&:hover': {
                            backgroundColor: 'background.hoverOrange',
                            borderColor: 'secondary.main',
                        },
                    }}
                >
                    Start for Free

                </Button>
                <Typography>to create an account for FREE</Typography>
            </Stack>
        </StyledPaper>
    </Container>
);

export { Tips };