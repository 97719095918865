import React, { useContext } from 'react';

import { Box, Container } from '@mui/material';
import { Toolbar } from '../../../../../components/Toolbar';
import { AccountSelector } from '../../../../../components/AccountSelector';
// import { DownloadExcelButton } from '../../../../../components/DownloadExcel';
import { UserInfoContext } from '../../../../../context/UserInfoContext';
import { TempCompanySelector } from '../../../../../components/TempCompanySelector';
import { DateRangeSelector } from '../../../../../components/DateRangeSelector';

const DashboardToolbar = () => {
  const { account, accountList } = useContext(UserInfoContext);
  return (
    <Toolbar>
      {account ? (
        <Container
          maxWidth='none'
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            alignItems: 'center',
            gap: 2,
            '@media(min-width: 600px)': {
              padding: '0rem 1rem',
            },
            '@media(min-width: 996px)': {
              display: 'flex',
            },
            '@media(min-width: 1366px)': {
              padding: '0rem 2rem',
            },
          }}
        >
          <Box
            sx={{
              gridArea: '1/1/2/2',
              '@media(min-width: 996px)': {
                order: 0,
                flex: 1,
              },
              '@media(min-width: 1440px)': {
                flex: `0 0 calc(50% - 4px)`,
              },
              '@media(min-width: 1920px)': {
                flex: `0 0 calc(50%)`,
              },
            }}
          >
            <Box
              sx={{
                maxWidth: 'fit-content',
                '@media(min-width: 996px)': { minWidth: '392px', flex: 1 },
              }}
            >
              <TempCompanySelector />
            </Box>
          </Box>
          {/*
              <Box
            sx={{
              gridArea: '1/2/2/3',
              justifySelf: 'end',
              '@media(min-width: 996px)': {
                order: 3,
              },
              border:"2px solid red"
            }}
          >
           <DownloadExcelButton /> 
             
          </Box>
        */}
      
          <Box
            sx={{
              gridArea: '2/1/3/3',
              '@media(min-width: 600px)': {
                gridArea: '2/1/2/2',
              },
              '@media(min-width: 996px)': {
                order: 1,
                flex: 1,
              },
            }}
          >
            {accountList && (
              <AccountSelector
                filterFunction={(acc) =>
                  ['GOOGLE_ADS', 'META_ADS'].includes(acc.platform)
                }
                noAccountsMessage='No Google or Meta Ads Accounts'
              />
            )}
          </Box>
          <Box
            sx={{
              gridArea: '3/1/4/3',
              '@media(min-width: 600px)': {
                gridArea: '2/2/3/3',
              },
              '@media(min-width: 996px)': {
                order: 2,
                flex: 1,
              },
            }}
          >
            <DateRangeSelector />
          </Box>
        </Container>
      ) : (
        <Container
          maxWidth='none'
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              '@media(min-width: 600px)': {
                maxWidth: '300px',
                minWidth: '150px',
              },
              '@media(min-width: 900px)': {
                width: '392px',
                maxWidth: '400px',
                minWidth: '200px',
              },
            }}
          >
            <TempCompanySelector />
          </Box>
          <Box
            sx={{
              width: '100%',
             
              '@media(min-width: 600px)': {
                maxWidth: '300px',
                minWidth: '150px',
              },
              '@media(min-width: 900px)': {
                width: '392px',
                maxWidth: '400px',
                minWidth: '200px',
              },
            }}
          >
            <AccountSelector
              filterFunction={(acc) =>
                ['GOOGLE_ADS', 'META_ADS'].includes(acc.platform)
              }
              noAccountsMessage='No Google or Meta Ads Accounts'
            />
          </Box>
        </Container>
      )}
    </Toolbar>
  );
};

export { DashboardToolbar };
