/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
// react imports
import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
// third party imports
import { Divider, MenuItem, Typography, Stack, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { AdhelpLogo } from '../../../constants/endpoints/assests/logos';
import { HeaderLink } from './HeaderLink';
import {
  HeaderLogo,
  HeaderBase,
  LogoHeaderBase,
  HeaderNavContainer,
  ContainedLink,
} from './styles';
import { HeaderLinkList } from './HeaderDropdown/styles';
import { HeaderMenu } from './HeaderDropdown';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { UserInfoDispatchContext } from '../../../context/UserInfoDispatchContext';
import { userLogout } from '../../../utils/userInfoDispatchFunctions';
import { IconDecider } from '../../../pages/UserManagement/Components/IconDecider';

const HeaderMenuLink = ({
  text,
  to,
  variant = 'a',
  badge = false,
  number = null,
  onClick = null,
  sx,
}) => (
  <ContainedLink
    component={Link}
    to={to}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
    onClick={onClick}
  >
    <Typography variant={variant} fontSize='1rem' fontWeight='400' sx={sx}>
      {text}
    </Typography>
    {badge && (
      <Typography
        sx={{
          color: '#fff',
          backgroundColor: '#0096FF',
          borderRadius: ' 100%',
          width: '24px',
          height: '24px',
          display: 'grid',
          placeItems: 'center',
        }}
        variant='smallBold'
      >
        {number}
      </Typography>
    )}
  </ContainedLink>
);

const HeaderProfile = () => {
  const { user, companyList } = useContext(UserInfoContext);
  const dispatch = useContext(UserInfoDispatchContext);
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const [activeState, setActiveState] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === '/details' ||
      location.pathname === '/edit-profile' ||
      location.pathname === '/invites-page' ||
      activeState
    ) {
      setIsProfileClicked(true);
    } else {
      setIsProfileClicked(false);
      setActiveState(false);
    }
  }, [location, activeState]);

  return (
    <HeaderMenu
      text='Profile'
      setActiveState={setActiveState}
      sx={{
        color: isProfileClicked ? '#0096FF' : 'inherit',
        '&.MuiButton-root:after': {
          bottom: ' 0px',
          display: 'block',
          backgroundColor: isProfileClicked ? '#0096FF' : '#fff',
        },
        '&.MuiButton-root:hover:after': {
          backgroundColor: '#0096FF',
        },
      }}
    >
      <MenuItem variant='linkContainer' sx={{ cursor: 'default' }}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='flex-start'
          gap={1}
          px={2}
          py={1}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconDecider
              row={user}
              fill={
                user.projectType === 'AGENCY' &&
                ['OWNER', 'ADMIN', 'COLLABORATOR'].includes(user.role)
                  ? '#FF823C'
                  : '#0096FF'
              }
            />
          </Box>
          <Box>
            <Typography variant='h4'>{user.username}</Typography>
            {companyList?.length > 0 ? (
              <Typography color='text.secondary' variant='smallMedium'>
                {user.company ? user.company : user.projectName} -{' '}
                {/* {_.startCase(
                  ['SUB_ADMIN', 'SUB_COLLABORATOR'].includes(user.role)
                    ? user.role.slice(4).toLowerCase()
                    : user.role.toLowerCase()
                )} */}
                {user.role
                  ? _.startCase(
                      ['SUB_ADMIN', 'SUB_COLLABORATOR'].includes(user.role)
                        ? user.role.slice(4).toLowerCase()
                        : user.role.toLowerCase()
                    )
                  : ''}
              </Typography>
            ) : null}
          </Box>
        </Stack>
      </MenuItem>
      <Divider variant='middle' component='li' />
      <MenuItem variant='linkContainer'>
        <HeaderMenuLink
          text={
            user.projectType === 'AGENCY' &&
            ['OWNER', 'ADMIN', 'COLLABORATOR'].includes(user.role)
              ? 'Agency Details'
              : 'Company Details'
          }
          to='/details'
          variant='sidebar'
          sx={{
            color:
              location?.pathname === '/details' ? 'primary.main' : 'inherit',
          }}
        />
      </MenuItem>
      <Divider variant='middle' component='li' />
      <MenuItem variant='linkContainer'>
        <HeaderMenuLink
          text='Edit Profile'
          to='/edit-profile'
          variant='sidebar'
          sx={{
            color:
              location?.pathname === '/edit-profile'
                ? 'primary.main'
                : 'inherit',
          }}
        />
      </MenuItem>
      <Divider variant='middle' component='li' />
      {user.role !== 'OWNER' && (
        <span>
          <MenuItem variant='linkContainer'>
            <HeaderMenuLink
              text='Invitations'
              to='/invites-page'
              variant='sidebar'
              badge={user.invitationCount > 0}
              number={user.invitationCount}
              sx={{
                color:
                  location?.pathname === '/invites-page'
                    ? 'primary.main'
                    : 'inherit',
              }}
            />
          </MenuItem>
          <Divider variant='middle' component='li' />
        </span>
      )}
      <MenuItem variant='linkContainer' onClick={() => userLogout(dispatch)}>
        <MenuItem component='span' sx={{ width: '100%' }}>
          <Typography variant='sidebar'>Sign Out</Typography>
        </MenuItem>
      </MenuItem>
    </HeaderMenu>
  );
};

const Header = ({ SidebarToggle }) => {
  const { user, isLoggedIn } = useContext(UserInfoContext);
  // const [open, setOpen] = useState(false);

  return (
    <HeaderBase component='header'>
      <HeaderNavContainer fluid maxWidth={false}>
        {SidebarToggle}
        <Link to='/signin'>
          <HeaderLogo src={AdhelpLogo} alt='adhelp logo' />
        </Link>
        <HeaderLinkList>
          {isLoggedIn &&
          user.projectType === 'AGENCY' &&
          ['ADMIN', 'OWNER', 'COLLABORATOR'].includes(user.role) ? (
            <HeaderLink to='/companies' text='Companies' />
          ) : null}
          {isLoggedIn ? <HeaderLink to='/accounts' text='Accounts' /> : null}
          <HeaderLink
            to={isLoggedIn ? '/dashboard' : '/demo-dashboard'}
            text='Dashboard'
          />
          {isLoggedIn ? (
            <HeaderLink to='/feed-management' text='Feed Management' />
          ) : null}

          {!isLoggedIn ? (
            <HeaderLink to='/feed-management' text='Feed Management' />
          ) : null}
          {!isLoggedIn ? (
            <HeaderMenu text='Tools'>
              <MenuItem variant='linkContainer'>
                <HeaderMenuLink text='Profit Calculator' to='/profit' />
              </MenuItem>
            </HeaderMenu>
          ) : null}
          {isLoggedIn &&
          (user.projectType === 'COMPANY' ||
            (user.projectType === 'AGENCY' &&
              ['SUB_ADMIN', 'SUB_COLLABORATOR'].includes(user.role))) ? (
            <HeaderLink to='/users/company' text='Users' />
          ) : (
            <HeaderLink to='/users/agency' text='Users' />
          )}
          {isLoggedIn ? (
            <HeaderProfile />
          ) : (
            <MenuItem variant='linkContainer'>
              <HeaderMenuLink text='Sign In' to='/signin' />
            </MenuItem>
          )}
        </HeaderLinkList>
      </HeaderNavContainer>
    </HeaderBase>
  );
};

const LogoHeader = () => (
  <LogoHeaderBase component='header'>
    <HeaderNavContainer fluid maxWidth={false}>
      <Link to='/signin'>
        <HeaderLogo src={AdhelpLogo} alt='adhelp logo' />
      </Link>
    </HeaderNavContainer>
  </LogoHeaderBase>
);

export { Header, LogoHeader };
