import React from 'react';
import { SignUpForm } from '../Form/SignUpForms';
import { PagesWrraper } from './PagesWrraper';

const SignUpNewPage = () => (
  <PagesWrraper>
    <SignUpForm />
  </PagesWrraper>
);

export { SignUpNewPage };
