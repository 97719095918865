import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toggleButton: 'Custom',
};

const accountSliceRedux = createSlice({
  name: 'toggleButton',
  initialState,
  reducers: {
    setAccountState(state, action) {
     
      // eslint-disable-next-line no-param-reassign
      state.toggleButton = action.payload;
    },
  },
});

// eslint-disable-next-line import/no-default-export
export default accountSliceRedux.reducer;
export const { setAccountState } = accountSliceRedux.actions;
