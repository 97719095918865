/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  Input,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCheckedMetric,
  removeCheckedMetric,
} from '../../FeedManagement/Redux/CustomizedColumn/slice';
import {
  GoogleAdsLogo,
  MetaIconLogo,
} from '../../../constants/endpoints/assests/logos';

const AccordianCheckboxList = ({ resp }) => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [accountMetric, setAccountMetric] = useState([]);

  const selectedMetrics = useSelector(
    (state) => state.customizedColumnRedux.selectedMetric
  );
  const [selectedMetricsState, setSelectedMetricsState] = useState([]);

  useEffect(() => {
    setSelectedMetricsState(selectedMetrics);
  }, [selectedMetrics]);

  useEffect(() => {
    if (!resp?.isLoading) {
      const Metric = resp?.data?.account_metric_columns || [];
      setAccountMetric(Metric);
    }
  }, [resp]);

  useEffect(() => {
    if (selectedMetricsState && accountMetric) {
      const checkedFields = selectedMetricsState.map((metric) => metric.column);
      setSelectedItems(
        accountMetric.filter((item) => checkedFields.includes(item.name))
      );
    }
  }, [selectedMetricsState, accountMetric]);

  const handleToggle = (item) => {
    const selectedIndex = selectedItems.findIndex(
      (selectedItem) => selectedItem.value === item.value
    );

    if (selectedIndex === -1) {
      const transformedItem = {
        order: selectedMetricsState.length,
        value: item.value,
        column: item.name,
        metric_type: item.metric_type,
      };
      setSelectedItems([...selectedItems, transformedItem]);
      dispatch(addCheckedMetric(transformedItem));
    } else {
      setSelectedItems(
        selectedItems.filter(
          (selectedItem) => selectedItem.value !== item.value
        )
      );
      dispatch(removeCheckedMetric(item.name));
    }
  };

  // if we want to disable checkbox
  // const handleToggle = (item) => {
  //   const selectedIndex = selectedItems.findIndex(
  //     (selectedItem) => selectedItem.value === item.value
  //   );
  //   // Check if the selected items array will be empty after the toggle
  //   const willBeEmpty = selectedIndex !== -1 && selectedItems.length === 1;
  //   if (!willBeEmpty) {
  //     if (selectedIndex === -1) {
  //       const transformedItem = {
  //         order: selectedMetricsState.length,
  //         value: item.value,
  //         column: item.name,
  //       };
  //       setSelectedItems([...selectedItems, transformedItem]);
  //       dispatch(addCheckedMetric(transformedItem));
  //     } else {
  //       setSelectedItems(
  //         selectedItems.filter(
  //           (selectedItem) => selectedItem.value !== item.value
  //         )
  //       );
  //       dispatch(removeCheckedMetric(item.name));
  //     }
  //   }
  // };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const filteredItems = accountMetric.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedItems = filteredItems
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box>
      <Input
        placeholder='Search metric...'
        variant='outlined'
        size='small'
        startAdornment={
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        }
        sx={{
          border: (theme) => `1px solid ${theme.palette.border.main}`,
          borderRadius: '24px',
          padding: '12px',
          fontSize: '14px',
          width: '100%',
          '& .MuiInput-input': {
            paddingTop: '0px',
            padding: '0px 0 0px',
          },
          '&.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: 'none',
          },
          '&.MuiInput-root::before': {
            borderBottom: 'none',
          },
          '&.MuiInput-root::after': {
            borderBottom: 'none',
          },
        }}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          // setExpanded(true);
          if (e.target.value) {
            setExpanded(true);
          } else {
            setExpanded(false);
          }
        }}
      />
      <Box
        sx={{
          '& .MuiAccordion-root.Mui-expanded:last-of-type': {
            marginTop: '16px',
            marginBottom: '16px',
          },
        }}
      >
        <Accordion
          expanded={expanded}
          onChange={handleExpand}
          sx={{
            marginTop: '16px',
            '&.MuiAccordion-root': {
              boxShadow: 'none',
              border: (theme) => `1px solid ${theme.palette.border.main}`,
            },
            '&:before': {
              backgroundColor: 'rgba(0, 0, 0, 0.0)',
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
            },
            '& .MuiAccordionSummary-root': {
              minHeight: 'auto',
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
              minHeight: 'auto',
            },
            '& .MuiAccordionDetails-root': {
              padding: '0rem 1rem 0rem',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
          >
            <Box
              component='img'
              src={
                resp?.data?.custom_databox_info?.platform_type.includes(
                  'GOOGLE_ADS'
                )
                  ? GoogleAdsLogo
                  : MetaIconLogo
              }
              alt='logo'
              sx={{
                height: 20,
                width: 20,
                mr: 1,
              }}
            />
            <Typography variant='smallMedium'>
              {resp?.data?.custom_databox_info?.platform_type.includes(
                'GOOGLE_ADS'
              )
                ? 'Google Ads'
                : resp?.data?.custom_databox_info?.platform_type.includes(
                    'META,FACEBOOK'
                  )
                ? 'Meta Ads'
                : 'Platform Metrics'}
            </Typography>
          </AccordionSummary>
          {sortedItems.length === 0 ? (
            <Box display='flex' justifyContent='center'>
              <Typography variant='smallBold' color='error' p={2}>
                No matching results found.
              </Typography>
            </Box>
          ) : null}
          <AccordionDetails
            sx={{
              overflowY: 'auto',
              maxHeight: '250px',
              minHeight: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {sortedItems.map((item) => (
              <FormControlLabel
                key={item.value}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '.9rem',
                  },
                  '.MuiTypography-root': {
                    textAlign: 'left',
                  },
                }}
                control={
                  <Checkbox
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    checked={selectedItems.some(
                      (selectedItem) => selectedItem.value === item.value
                    )}
                    onChange={() => handleToggle(item)}
                    sx={{ padding: '6px' }}
                  />
                }
                label={item.name}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export { AccordianCheckboxList };
