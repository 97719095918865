import _ from 'lodash';

const MetricWithCurrency = [
  'All Conversion value',
  'Average Cost',
  'Conversion value',
  'Cost',
  'Cost per All Conversions',
  'Cost per Conversions values',
  'All Conversion values (by Conversion time)',
];

const MetricWithPercentage = [
  'Engagement rate',
  'Interactions Rate',
  'Videos (Played to 100%)',
  'Videos (Played to 50%)',
  'Videos (Played to 25%)',
  'Videos (Played to 75%)',
  'Conversion rate (interactions)',
];

const getHeadCells = (
  firstDataItem,
  excludedKeys,
  currencyCode,
  defaultFormatFunction = (row, key) => row[key]
) =>
  Object.keys(firstDataItem)
    //   .filter((key) => key !== 'Campaign Id')
    .filter((key) => !excludedKeys.includes(key))
    .map((key) => ({
      key,
      id: key,
      numeric: typeof firstDataItem[key] === 'number',
      label: [key],
      alignRight: typeof firstDataItem[key] === 'number',
      formatFunction: (row) => {
        if (typeof row[key] === 'number') {
          const formattedValue = _.round(row[key], 2).toLocaleString();
          if (MetricWithCurrency.includes(key)) {
            return `${currencyCode} ${formattedValue}`;
          }
          if (MetricWithPercentage.includes(key)) {
            return `${formattedValue} %`;
          }
          if (key === 'ROAS') {
            return _.round(formattedValue, 1);
          }
          return formattedValue.toLocaleString();
        }
        // return row[key];
        return defaultFormatFunction(row, key);
      },
      totalFunction: (rows, totalRow) => {
        if (totalRow && totalRow[key] !== undefined) {
          const formattedValue = _.round(totalRow[key], 2).toLocaleString();
          if (MetricWithCurrency.includes(key)) {
            return `${currencyCode} ${formattedValue} `;
          }
          if (MetricWithPercentage.includes(key)) {
            return `${formattedValue} %`;
          }
          if (key === 'ROAS') {
            return `${_.round(totalRow[key], 1).toLocaleString()} `;
          }
          return formattedValue.toLocaleString();
        }
        return null;
      },
    }));

export { getHeadCells };
