import React, { useMemo, useContext } from 'react';

import _ from 'lodash';
import { Box } from '@mui/material';

import {
  NearMeOutlined,
  InsightsRounded,
  PaymentsRounded,
  PercentRounded,
  PublicRounded,
  TrendingUpRounded,
} from '@mui/icons-material';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { QuickStats } from '../../../../Databoxes/QuickStats';

import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { Loading } from '../../../../../../components/Databox/Loading';

import { getDemoDashboardService } from '../../../../../../services/DemoDashboard';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { AccountContext } from '../../accountContext';

const IconMap = {
  clicks: NearMeOutlined,
  conversion_rate: InsightsRounded,
  conversions: TrendingUpRounded,
  cost: PaymentsRounded,
  revenue: PaymentsOutlinedIcon,
  roas: PercentRounded,
  reach: PublicRounded,
};

const valueFormatter = (currencyCode, key, value) => {
  if (key.includes('revenue') || key.includes('cost')) {
    return `${currencyCode} ${_.round(value).toLocaleString()}`;
  }

  if (key === 'conversion_rate') {
    return `${_.round(value * 100, 1)}%`;
  }
  return _.round(value).toLocaleString();
};

const dataFormatter = (dataArg, currencyCode) => {
  const sortedData = {
    cost: dataArg.marketing_cost,
    revenue: dataArg.online_sales,
    clicks: dataArg.clicks,
    conversions: dataArg.conversions,
    roas: dataArg.roas,
  };

  if (Object.keys(dataArg).includes('conversion_rate')) {
    sortedData.conversion_rate = dataArg.conversion_rate;
  }

  if (Object.keys(dataArg).includes('reach')) {
    sortedData.reach = dataArg.reach;
  }

  return Object.keys(sortedData).map((key) => ({
    label: key
      .split('_')
      .map((s) => {
        if (s === 'roas') {
          return s.toUpperCase();
        }
        return s[0].toUpperCase() + s.slice(1);
      })
      .join(' '),
    value: valueFormatter(currencyCode, key, sortedData[key]),
    icon: IconMap[key.toLowerCase()],
  }));
};
const QuickStatsContainer = ({ platform }) => {
  const { currencyCode } = useContext(AccountContext);
  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'STATS',
    }),
    []
  );

  const [state] = useDataFetcher(
    {},
    getDemoDashboardService(platform),
    memoizedArgs
  );

  return (
    <Databox
      sx={{
        '.MuiIconButton-root': {
          cursor: 'default !important',
          minHeight: '180px',
        },
      }}
    >
      <DataboxHeading
        title='Quick Stats'
        info='A quick look at your business analytics.'
        sx={{
          display: 'none',
        }}
      />
      <FetchingDisplay
        isError={state.isError}
        isLoading={state.isLoading}
        LoadingElement={<Loading />}
        ErrorElement={<ErrorDisplay />}
        SuccessElement={
          <Box
            sx={{
              padding: '1.5rem',
              paddingInline: '3rem',
              width: '100%',
              '.slick-slide > div': {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRight: '1px solid #DEDEDE',
              },
              '.slick-list': {
                borderRight: '4px solid #fff !important',
                borderLeft: '4px solid #fff !important',
              },
              '.slick-track': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
          >
            <QuickStats
              data={state.data ? dataFormatter(state.data, currencyCode) : []}
            />
          </Box>
        }
      />
    </Databox>
  );
};

export { QuickStatsContainer };
