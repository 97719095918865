import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fileId: '',
  fileOrder: '',
}

const importedFilesRedux = createSlice({
  name: 'importedFile',
  initialState,
  reducers: {
    setFileId: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.fileId = action.payload;
    },
    setFileOrder: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.fileOrder = action.payload;
    },
    clearInputValueImported: (state) => {
      // eslint-disable-next-line no-param-reassign, no-unused-vars
      state.fileId = initialState.fileId
      // eslint-disable-next-line no-param-reassign
      state.fileOrder = initialState.fileOrder
    },
  }
});

export const { setFileId, clearInputValueImported, setFileOrder } = importedFilesRedux.actions;

// eslint-disable-next-line import/no-default-export
export default importedFilesRedux.reducer; 