import React, { useContext, useEffect, useRef } from 'react';
// third party imports
// import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import {
  FormControl,
  TextField,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Internal component imports
import { NameSchema } from '../../Schemas';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { useCreateProjectMutation } from '../../../../api/RTK/services/feedProject';
import { DataSendingModalBaseRTK } from '../../../../components/DataSendingModalBaseRTK';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { setProjectId, setProjectName } from '../../Redux/AllProjects/slice';
import { clearInputValue } from '../../Redux/FeedSummary/slice';
import { clearInputValue as clearImportForm } from '../../Redux/ImportForm/slice';
import { clearInputValueImported } from '../../Redux/ImportedFiles/slice';
import { clearInputValue as clearInputValueSchedule } from '../../Redux/ScheduleForm/slice';
import { clearInputValue as clearInputValueFeedForm } from '../../Redux/FeedForm/slice';

// Main function component
const AddProjectModal = ({ open, setOpen }) => {
  const { company } = useContext(UserInfoContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMountRef = useRef(false);

  useEffect(() => {
    isMountRef.current = true;
    return () => {
      isMountRef.current = false;
    };
  }, []);

  const /* The `handleClearReduxIDs` function is responsible for dispatching actions to clear the input
  values in various Redux slices. It is called when the modal is closed to ensure that the
  input values are cleared when the modal is reopened. The function dispatches actions such as
  `clearInputValue`, `clearInputValueImported`, `clearImportForm`, `clearInputValueSchedule`,
  and `clearInputValueFeedForm` to clear the input values in different Redux slices. */
    handleClearReduxIDs = () => {
      dispatch(clearInputValue());
      dispatch(clearInputValueImported());
      dispatch(clearImportForm());
      dispatch(clearInputValueSchedule());
      dispatch(clearInputValueFeedForm());
    };

  const [
    createProject,
    { isLoading, isError, isSuccess, isUninitialized, reset, error },
  ] = useCreateProjectMutation();

  const handleCloseModal = () => {
    setOpen(false);
  };

  // create project request
  const handleSubmit = (data) =>
    createProject({ companyId: company?.id, ...data })
      .unwrap()
      .then((response) => {
        if (response && response.id) {
          dispatch(setProjectId(response.id));
          dispatch(setProjectName(response.name));
          handleClearReduxIDs();
          navigate('/feed-management/imports/import-settings');
        } else {
          console.error('Error: Project ID is undefined in the response');
        }
      });

  return (
    <DataSendingModalBaseRTK
      open={open}
      handleCloseModal={handleCloseModal}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      isUninitialized={isUninitialized}
      successMessage='Project Created'
      errorMessage={
        error
          ? `Error: ${error?.data?.error?.detail}`
          : 'Project cannot be created'
      }
      reset={reset}
    >
      <HeadingWithUnderline heading='Add New Project' />
      <Formik
        initialValues={{
          projectName: '',
        }}
        onSubmit={(values, { resetForm }) =>
          handleSubmit({
            name: values.projectName,
            company_id: company?.id,
          })
            .then(() => {
              if (isMountRef.current) {
                resetForm();
              }
            })
            .catch(() => {
              if (isMountRef.current) {
                resetForm();
              }
            })
        }
        validationSchema={NameSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          dirty,
          isValid,
        }) => (
          // removed StyledModal
          <Form>
            <FormControl
              variant='standard'
              className='addProjectWrap'
              fullWidth
            >
              <Typography sx={{ paddingBottom: '8px', textAlign: 'justify' }}>
                Project Name
              </Typography>
              <TextField
                name='projectName'
                value={values.projectName}
                hiddenLabel
                placeholder='Eg: Ecommerce'
                type='text'
                variant='outlined'
                className='addProjectInput'
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
              <div
                className='errorMsg'
                style={{ color: 'red', fontSize: '14px' }}
              >
                {errors.projectName && touched.projectName ? (
                  <p className='form-error'>{errors.projectName}</p>
                ) : null}
              </div>
            </FormControl>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              marginTop='15px'
              marginBottom='10px'
            >
              <Button
                sx={{
                  width: '200px',
                  margin: '15px 5px',
                }}
                variant='outlined'
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                sx={{
                  width: '200px',
                  margin: '15px 5px',
                }}
                variant='contained'
                disabled={!dirty || !isValid}
              >
                Create
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </DataSendingModalBaseRTK>
  );
};

export { AddProjectModal };
