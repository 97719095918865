import React from 'react';
import { Link as BrowserLink } from 'react-router-dom';
import { Paper, Container, Typography, Box } from '@mui/material';
import { StyledError } from './StyledError404Page';
import { AdhelpLogo } from '../../constants/endpoints/assests/logos';

const Error404Page = () => (
  <StyledError>
    <Container xs={12}>
      <div className='error404Nav'>
        <img src={AdhelpLogo} width='auto' height='30' alt='AdHelp Logo' />
      </div>
    </Container>
    <div className='error404BodyBox'>
      <Container maxWidth='lg' lg={12}>
        <div className='contentBox'>
          <Typography variant='smallRegular'>
            <Typography variant='smallBold'>Error 404</Typography> | The page
            you were looking for does not exist.
          </Typography>
          <Box className='quote' sx={{ py: 1.5 }}>
            <div className='inQuote'>
              <Paper elevation={2}>
                <div className='innerCon'>
                  <h1>Not all those who wander are lost.</h1>
                  <p>- J.R.R. Tolkien</p>
                </div>
              </Paper>
            </div>
          </Box>
          <div className='lastCon'>
            <Typography variant='bodyMedium'>
              In case you&#39;ve landed here, you can go back to the{' '}
              <BrowserLink to='/dashboard'>Dashboard</BrowserLink> or{' '}
              <BrowserLink to='/signin'>Sign in</BrowserLink> again.
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  </StyledError>
);

export { Error404Page };
