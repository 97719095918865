import React, { useEffect, useState } from 'react';
import { Stack, Typography, Box, Button, TextField } from '@mui/material';
import { StyledMuiLink } from '../Form/Formik/Styles';
import { AuthService } from '../../../services/auth';
import { PagesWrraper } from './PagesWrraper';

import {
  ErrorAlert,
  SuccessAlert,
} from '../../../widgets/NewAlerts/ErrorAlert';

const SubmitMessage = ({ state }) => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [resendStatus, setResendStatus] = useState('');
  const [color, setColor] = useState('');
  const [email, setEmail] = useState('');
  const [showTimer, setShowTimer] = useState(false);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let timer;

    if (showTimer) {
      timer = setInterval(() => {
        if (minutes === 0 && seconds === 0) {
          clearInterval(timer);
          setShowTimer(false);
        } else if (seconds === 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          setSeconds(seconds - 1);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [showTimer, minutes, seconds]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,8}$/i.test(email);

  const handleResendEmail = (event) => {
    event.preventDefault();
    setIsAlertVisible(false);
    setMinutes(5);
    setSeconds(0);
    setShowTimer(true);
    AuthService.resendVerificationEmail({
      registered_email: email.toLowerCase(),
    })
      .then((response) => {
        if (response.status === 200) {
          setIsAlertVisible(true);
          setColor('primary');
          setResendStatus(response?.data?.message);
        }
      })
      .catch((error) => {
        setIsAlertVisible(true);
        setColor('error');
        if (error.response) {
          if (error.response.status === 500)
            setResendStatus('Oops! something went wrong. Please try again.');
          else {
            setResendStatus(error?.response?.data?.errors);
          }
        }
      });
    setTimeout(() => {
      setShowTimer(false);
      setResendStatus(false);
    }, 300000);
  };

  const domainToSignInPage = {
    'gmail.com': 'https://mail.google.com/',
    'rediff.com': 'https://mail.rediff.com/',
    'yahoo.com': 'https://login.yahoo.com/',
    'icloud.com': 'https://www.icloud.com/',
  };

  const defaultSignInPage = 'https://login.live.com/';

  const redirectToSignInPage = () => {
    // Extract the domain from the entered email
    const domain = email.split('@')[1];

    if (domainToSignInPage[domain]) {
      const url = domainToSignInPage[domain];
      window.open(url, '_blank');
    } else {
      window.open(defaultSignInPage, '_blank');
    }
  };

  return (
    <PagesWrraper>
      {state === 'error' && (
        <ErrorAlert message='Email not verified. Check your email and click on the verification link to sign in.' />
      )}
      {state === 'success' && (
        <SuccessAlert message='Verification email sent. Click on the link in the email to verify your email and sign in.' />
      )}
      {state === 'empty' && null}

      <Stack sx={{ margin: '16px 0px 0px 0px' }}>
        {isAlertVisible ? (
          <Typography
            variant='smallBold'
            color={color}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 2,
              alignItems: 'center',
              testAlign: 'center',
            }}
          >
            {resendStatus}
          </Typography>
        ) : null}
        <form onSubmit={handleResendEmail}>
          <TextField
            id='email'
            variant='outlined'
            value={email}
            onChange={handleEmailChange}
            type='email'
            required
            fullWidth
            placeholder='Enter your Registered Email ID'
            sx={{ mb: 1 }}
          />
          <Stack direction='column' alignItems='center' mt={1}>
            {showTimer && (
              <Typography variant='smallRegular' mb={1}>
                Resend Email in{' '}
                <span style={{ color: '#0096ff', fontWeight: '600' }}>
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}{' '}
                </span>
              </Typography>
            )}
            <Button
              variant='contained'
              type='submit'
              fullWidth
              disabled={!isEmailValid || showTimer}
            >
              Resend Email
            </Button>
          </Stack>
        </form>
      </Stack>
      <Box sx={{ my: 2 }}>
        <Typography
          variant='smallMedium'
          sx={{
            '&.MuiTypography-root': {
              margin: '0rem',
            },
            cursor: 'pointer',
          }}
          onClick={() => redirectToSignInPage(email)}
        >
          Didn&#39;t get a link&#63; Check your spam folder or{' '}
          <StyledMuiLink
            // href='https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@adhelp.io'
            target='_blank'
          >
            Contact support
          </StyledMuiLink>
          .
        </Typography>
      </Box>
    </PagesWrraper>
  );
};

export { SubmitMessage };
