// react importsntainer
import React from 'react';

// third party imports
import { Container, Link, Paper } from '@mui/material';
import { styled } from '@mui/system';

const HeaderLogo = styled('img')({
  height: '2.25rem',
  order: 1,
  '@media(min-width:996px)': {
    order: 0,
  },
});

const HeaderNavContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'fluid',
})({
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'row',
  height: '5rem',
  justifyContent: 'space-between',
});

const HeaderBase = styled(Paper)({});
const LogoHeaderBase = styled(Paper)({
  position: 'fixed',
  top: '0px',
  width: '100%',
  background: 'none',
  boxShadow: 'none',
  '.MuiContainer-root': {
    background: 'none',
  },
});

const ContainedLink = styled(
  ({ component, to, onClick = null, className, children }) => (
    <Link
      component={component}
      to={to}
      onClick={onClick}
      className={className}
      underline='none'
      color='inherit'
      sx={{ padding: '0.75rem 1rem' }}
    >
      {children}
    </Link>
  )
)(() => ({ width: '100%' }));

export {
  HeaderLogo,
  HeaderNavContainer,
  HeaderBase,
  ContainedLink,
  LogoHeaderBase,
};
