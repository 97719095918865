import React, { useState, useContext } from 'react';
import {
  Box,
  Container,
  Typography,
  ListItem,
  List,
  ListItemText,
  Divider,
  Button,
  Stack,
  ListItemIcon,
} from '@mui/material';
import _ from 'lodash';

import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { StyledPaper } from '../../UserManagement/Components/utils';
import { PasswordDeleteDialog } from '../../UserManagement/Components/DeleteConfirmationDialog/PasswordDeleteDialog';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { Toolbar } from '../../../components/Toolbar';
import {
  useGetInvitesListQuery,
  useRejectInvitationMutation,
  useAcceptInvitationMutation,
} from '../../../api/RTK/services/invites';
import { DashboardService } from '../../../services/dashboard';
import { fetchUserInfo } from '../../../services/auth';
import { PageErrors } from '../../../components/PageErrors';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { ReactComponent as EmptyBox } from '../../../assests/images/artWork/EmptyBox.svg';
import {
  setUser,
  setCompany,
  setCompanyList,
} from '../../../utils/userInfoDispatchFunctions';
import { getAccessToken } from '../../../utils/token';
import { UserInfoDispatchContext } from '../../../context/UserInfoDispatchContext';
import { UserInfoContext } from '../../../context/UserInfoContext';

const InvitesPage = () => {
  const { user } = useContext(UserInfoContext);
  const navigate = useNavigate();
  const token = getAccessToken();
  const dispatch = useContext(UserInfoDispatchContext);
  const [inviteInfo, setInviteInfo] = useState(null);
  const { data, isLoading, isError, error } = useGetInvitesListQuery({
    skip: token,
  });
  const [
    rejectInvite,
    // { isLoading: rejectLoading, isError: rejectIsError, error: rejectError },
  ] = useRejectInvitationMutation();
  const [
    acceptInvite,
    // { isLoading: acceptLoading, isError: acceptIsError, error: acceptError },
  ] = useAcceptInvitationMutation();
  const [open, setOpen] = useState(false);

  const openDialog = (idArg) => {
    setInviteInfo(idArg);
    setOpen(true);
  };

  const closeDialog = () => {
    setInviteInfo(null);
    setOpen(false);
  };

  const rejectInvitation = async (invite) => {
    try {
      await rejectInvite({
        token: invite?.token,
        invitedBy: invite?.invited_by,
      })
        .unwrap()
        .then(() =>
          fetchUserInfo().then((userInfo) => setUser(dispatch, userInfo))
        );
    } catch (errors) {
      console.error(errors);
    }
  };

  const acceptInvitation = async (invite) => {
    try {
      await acceptInvite({
        token: invite?.token,
        invitedBy: invite?.invited_by,
      })
        .unwrap()
        .then(() =>
          Promise.all(
            data?.map((item) =>
              rejectInvite({
                token: item?.token,
                invitedBy: item?.invited_by,
              })
            )
          )
        )
        .then(() => fetchUserInfo())
        .then((userInfo) => {
          const newCompanyList =
            DashboardService.getCompanyListWithoutMetrics();
          return Promise.all([userInfo, newCompanyList]);
        })
        .then(([userInfo, newCompanyList]) => {
          setUser(dispatch, userInfo);
          setCompanyList(dispatch, newCompanyList?.data);
          if (userInfo.project_type === 'AGENCY') {
            setCompany(dispatch, null);
          } else {
            setCompany(dispatch, newCompanyList?.data[0]);
          }
          navigate('/companies');
          fetchUserInfo();
        });
    } catch (errors) {
      console.error(errors);
    }
  };

  return (
    <>
      <Toolbar colwidth={600}>
        <Container
          maxWidth='none'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            gap: 1,
            '@media(min-width: 800px)': {
              gap: 3.5,
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{
              flex: 1,
              order: -1,
              '@media(min-width: 800px)': { order: 0 },
            }}
          >
            <Typography
              component='h2'
              variant='pageHeading'
              sx={{ whiteSpace: 'nowrap' }}
            >
              Invitations
            </Typography>
          </Box>
        </Container>
      </Toolbar>
      <Container
        maxWidth={false}
        sx={{
          mt: 1,
          mb: 2,
          position: 'relative',
          '.MuiTableContainer-root': {
            borderRadius: '14px',
          },
        }}
      >
        <FetchingDisplay
          isLoading={isLoading}
          isError={isError}
          ErrorElement={
            <PageErrors
              code={error?.status}
              message={error?.data?.error?.detail}
              isServerError
              error={false}
              hideText={false}
              customButton
            />
          }
          LoadingElement={<GlobalLoadingComponent />}
          SuccessElement={
            data?.length > 0 ? (
              <>
                <StyledPaper sx={{ minHeight: '60vh' }}>
                  {data?.map((item) => (
                    <List
                      key={item?.token}
                      sx={{ paddingTop: '0px', paddingBottom: '0px' }}
                    >
                      <ListItem
                        sx={{
                          alignItems: 'center',
                          paddingTop: '16px',
                          paddingBottom: '16px',
                        }}
                      >
                        <ListItemIcon sx={{ mr: 2, minWidth: '120px' }}>
                          {item.invited_date}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant='smallBold'
                              sx={{ textAlign: 'left' }}
                            >
                              {!item.company ? item.project.name : item.company}
                              &nbsp;
                              {_.capitalize(
                                !item.company
                                  ? item.project.project_type
                                  : 'COMPANY'
                              )}
                              &nbsp;have invited you to join them with&nbsp;
                              {['SUB_ADMIN', 'ADMIN'].includes(item?.user_type)
                                ? 'Admin'
                                : 'Collaborator'}
                              &nbsp;access
                            </Typography>
                          }
                        />
                        <Stack
                          display='flex'
                          flexDirection='row'
                          justifyContent='center'
                          alignItems='center'
                        >
                          <Button
                            variant='contained'
                            startIcon={
                              <CheckIcon
                                sx={{
                                  '&.MuiSvgIcon-root': {
                                    color: '#fff !important',
                                  },
                                }}
                              />
                            }
                            sx={{ mr: 3 }}
                            onClick={() => openDialog(item)}
                          >
                            Accept
                          </Button>
                          <Button
                            variant='outlined'
                            startIcon={
                              <CloseIcon
                                sx={{
                                  '&.MuiSvgIcon-root': {
                                    color: '#3B9CFA !important',
                                  },
                                }}
                              />
                            }
                            onClick={() => rejectInvitation(item)}
                          >
                            Reject
                          </Button>
                        </Stack>
                      </ListItem>
                      <Divider component='li' />
                    </List>
                  ))}
                </StyledPaper>
                <PasswordDeleteDialog
                  open={Boolean(open && inviteInfo)}
                  handleClose={closeDialog}
                  handleConfirm={() => {
                    acceptInvitation(inviteInfo);
                  }}
                  buttonText='Accept'
                  copy={`By Clicking on 'Accept' you will get a new access to "${_.capitalize(
                    inviteInfo?.company
                      ? inviteInfo?.company
                      : inviteInfo?.project?.name
                  )}" and will be exited from your current ${_.lowerCase(
                    user.company ? 'Company' : user.project
                  )}. All the existing invites will be automatically rejected.`}
                />
              </>
            ) : (
              <Box sx={{ svg: { minWidth: '100%' } }}>
                <PageErrors
                  customErrorArtwork={<EmptyBox />}
                  code=''
                  message=''
                  isServerError
                  error={false}
                  hideText={false}
                  customButton
                  customText={
                    <Typography color='primary' variant='bigSemiBold'>
                      You currently have no invitations.
                    </Typography>
                  }
                />
              </Box>
            )
          }
        />
      </Container>
    </>
  );
};

export { InvitesPage };
