import React, { useContext } from 'react';
import { Typography, Box, Grid, Stack, Button, Tooltip } from '@mui/material';
import { StyledAccountMenuBox, StyledWrapperBox } from './styles';
import {
  GoogleAdsLogo,
  GoogleAnalyticsLogo,
  MerchantCenterLogo,
  TagManagerLogo,
  MetaIconLogo,
  ShopifyLogo,
  WooLogo,
} from '../../../../../../constants/endpoints/assests/logos';
import {
  AdWordsBground,
  FacebookAdsBground,
  GoogleAnalyticsBground,
  GoogleMerchantBground,
  GoogleTagManagerBground,
  ShopifyBground,
  WoocomBground,
} from '../../../../../../constants/endpoints/assests/Other';
import { CardMenu } from './CardMenu';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';

const AccountCard = ({
  accountName,
  accountId,
  plateformName,
  isActive,
  errorCode,
  error,
  accountUUID,
  reloadFunction,
  handleClick,
  handleErrorClick,
  val1,
  val2,
}) => {
  const platformData = {
    GOOGLE_ADS: {
      title: 'Google Ads',
      logo: GoogleAdsLogo,
      background: AdWordsBground,
      text1: 'Ads Cost',
      text1Value: val1,
      text2: 'Revenue',
      text2Value: val2,
      OnClickHandle: null,
    },
    META_ADS: {
      title: 'Meta Ads',
      logo: MetaIconLogo,
      background: FacebookAdsBground,
      text1: 'Ads Cost',
      text1Value: val1,
      text2: 'Revenue',
      text2Value: val2,
      OnClickHandle: null,
    },
    GOOGLE_ANALYTICS: {
      title: 'Google Analytics',
      logo: GoogleAnalyticsLogo,
      background: GoogleAnalyticsBground,
      text1: 'ID',
      text1Value: accountId,
      text2: 'Revenue',
      text2Value: val2,
      OnClickHandle: null,
    },
    MERCHANT_CENTER: {
      title: 'Merchant Center',
      logo: MerchantCenterLogo,
      background: GoogleMerchantBground,
      text1: 'Approved Products',
      text1Value: val1,
      text2: 'Disapproved Products',
      text2Value: val2,
      OnClickHandle: null,
    },
    TAG_MANAGER: {
      title: 'Tag Manager',
      logo: TagManagerLogo,
      background: GoogleTagManagerBground,
      text1: 'ID',
      text1Value: accountId,
      OnClickHandle: null,
    },
    SHOPIFY: {
      title: 'Shopify',
      logo: ShopifyLogo,
      background: ShopifyBground,
      text1: 'Shop',
      text1Value: accountId,
      // OnClickHandle: () => {
      //   setIsLoading(true);
      //   ShopifyOAuth2FlowComponent(accountId);
      //   setTimeout(() => {
      //     setIsLoading(false);
      //   }, 2000);
      // },
      OnClickHandle: null,
    },
    WOO_COMMERCE: {
      title: 'WooCommerce',
      logo: WooLogo,
      background: WoocomBground,
      text1: 'Shop',
      text1Value: accountId,
      // OnClickHandle: () => {
      //   setIsLoading(true);
      //   ShopifyOAuth2FlowComponent(accountId);
      //   setTimeout(() => {
      //     setIsLoading(false);
      //   }, 2000);
      // },
      OnClickHandle: null,
    },
  };
  const { user } = useContext(UserInfoContext);

  return (
    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
      <StyledWrapperBox
        sx={{
          backgroundImage: `url(${platformData[plateformName]?.background})`,
          boxShadow: '0px 0px 10px rgba(0,0,0,.08)',
          paddingTop: '64px',
          minHeight: '100%',
          display: 'flex',
          minWidth: '100%',
          '@media (min-width: 900px)': {
            minWidth: '101%',
          },
          '@media (min-width: 1400px)': {
            minWidth: '102%',
          },
        }}
      >
        {['OWNER', 'ADMIN'].includes(user?.role) ? (
          <StyledAccountMenuBox>
            <CardMenu
              name={accountName}
              id={accountUUID}
              isActive={isActive}
              reloadFunction={reloadFunction}
            />
          </StyledAccountMenuBox>
        ) : null}
        <Box
          sx={{
            bgcolor: '#fff',
            borderRadius: '16px',
            minWidth: '100%',
            minHeight: '140px',
            cursor: `${
              (plateformName === 'GOOGLE_ADS' ||
                plateformName === 'META_ADS') &&
              isActive &&
              !error
                ? 'pointer'
                : null
            }`,
            p: 2,
          }}
          onClick={
            (plateformName === 'GOOGLE_ADS' || plateformName === 'META_ADS') &&
            isActive
              ? handleClick
              : handleErrorClick
          }
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: '30px',
                lineHeight: 'normal',
                img: { width: '30px' },
              }}
            >
              <img
                src={platformData[plateformName]?.logo}
                alt='Google Ads Logo'
              />
            </Box>
            <Typography textAlign='center' variant='h5' fontSize='1rem' noWrap>
              {accountName} -&nbsp;
              <Tooltip title={accountId} arrow>
                <span>{accountId || ''}</span>
              </Tooltip>
            </Typography>
          </Box>

          {isActive && !error ? (
            <Box>
              <Box sx={{ pt: 1, pb: 1 }}>
                <Typography variant='smallBold'>
                  {platformData[plateformName]?.title}
                </Typography>
              </Box>
              <Stack
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography variant='smallMedium' sx={{ minWidth: '100px' }}>
                  {platformData[plateformName]?.text1}
                </Typography>
                <Tooltip title={platformData[plateformName]?.text1Value} arrow>
                  <Typography
                    variant='smallMedium'
                    color='primary'
                    noWrap
                    sx={{
                      display: 'flex',
                      justifyContent: ' space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    {platformData[plateformName]?.text1Value}
                  </Typography>
                </Tooltip>
              </Stack>
              <Stack
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography variant='smallMedium'>
                  {platformData[plateformName]?.text2}
                </Typography>
                <Tooltip title={platformData[plateformName]?.text2Value} arrow>
                  <Typography
                    variant='smallMedium'
                    color='primary'
                    noWrap
                    sx={{
                      display: 'flex',
                      justifyContent: ' space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    {platformData[plateformName]?.text2Value}
                  </Typography>
                </Tooltip>
              </Stack>
            </Box>
          ) : null}
          {/* error text with error button */}
          {isActive && error && errorCode === 403 ? (
            <Box sx={{ marginTop: '0.2rem' }}>
              {['OWNER'].includes(user.role) ? (
                <Button
                  variant='contained'
                  sx={{ height: '2.2rem', my: 1 }}
                  fullWidth
                  onClick={platformData[plateformName]?.OnClickHandle}
                >
                  Link
                </Button>
              ) : null}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  pt: 1,
                }}
              >
                <Typography variant='smallMedium' color='error'>
                  {['OWNER'].includes(user.role)
                    ? 'Your platform has been disconnected. Please click on the Link to reconnect the account.'
                    : 'Your platform has been disconnected. Please reach out to the owner to reconnect the account.'}
                </Typography>
              </Box>
            </Box>
          ) : null}
          {isActive && error && errorCode !== 403 ? (
            <Box sx={{ marginTop: '0.2rem' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pt: 1,
                }}
              >
                <Typography variant='smallMedium' color='error'>
                  {error}
                </Typography>
              </Box>
            </Box>
          ) : null}
          {/* error text */}
          {!isActive && !error ? (
            <Box sx={{ mt: 1 }}>
              <Typography
                variant='smallMedium'
                color='error'
                textAlign='center'
              >
                We are connecting your account. It might take some time.
              </Typography>
            </Box>
          ) : null}
        </Box>
      </StyledWrapperBox>
    </Grid>
  );
};

export { AccountCard };
