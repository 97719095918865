import React, { useState, useContext, useEffect, useMemo } from 'react';

// MUI imports
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material';

// Internal imports
import {
  getAllCountries,
  getTimezonesForCountry,
} from 'countries-and-timezones';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { useDebouncedCallback } from 'use-debounce';
import { Link } from 'react-router-dom';
import { FeedsPreviewList } from '../Components/OtherComponents/FeedPreviewList';
import { StyledPaper } from './StyledMainBox';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { ToggleButtonFeeds } from '../Components/OtherComponents/ToggleButtonFeeds';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { PageErrors } from '../../../components/PageErrors';
import { ReactComponent as ErrorIcon } from '../../../assests/images/artWork/ErrorIcon.svg';
import {
  useActivateFeedMutation,
  useGetFeedPreviewQuery,
} from '../../../api/RTK/services/feedFeed';
import {
  useGetProjectDetailsQuery,
  useGetScheduleQuery,
} from '../../../api/RTK/services/feedProject';
import { SnackbarWithCta } from '../Components/GlobalComponents/SnackbarWithCta';
import { updateVal } from '../Redux/ScheduleForm/slice';
import { feedManagementService } from '../../../services/feedManagement';
import { setFeeds } from '../Redux/AllProjects/slice';
import { useDataFetcher } from '../../../Hooks/useDataFetcher';
import { ErrorMessageBox } from '../Components/GlobalComponents/ErrorMessageBox';

const FeedsPreview = () => {
  const [toggled, setToggled] = useState('Preview');
  const dispatch = useDispatch();
  const { company, user } = useContext(UserInfoContext);

  // redux store
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );
  const scheduleTimezone = useSelector((state) => state.scheduleFormRedux);
  const scheduleUpdateStore = useDebouncedCallback((key, val) => {
    dispatch(updateVal({ key, val }));
  }, 10);

  const feedId = useSelector((state) => state.feedSummaryRedux.feedId);

  const projectFeeds = useSelector((state) => state.allProjectsRedux.feeds);

  const activeFeed =
    projectFeeds && projectFeeds.find((feed) => feed?.id === feedId);

  const isFeedInactive = activeFeed && !activeFeed.active;

  const areAllFeedsInactive =
    projectFeeds &&
    projectFeeds.length > 0 &&
    projectFeeds.every((feed) => !feed.active);

  const { data: projectDetails } = useGetProjectDetailsQuery(
    {
      companyId: company?.id,
      projectId: projectSelectId,
    },
    { skip: !company }
  );

  const { data: scheduleData } = useGetScheduleQuery({
    companyId: company?.id,
    projectId: projectSelectId,
    feedId,
  });

  const { isLoading, isError, data, error } = useGetFeedPreviewQuery(
    {
      companyId: company?.id,
      projectId: projectSelectId,
      feedId,
    },
    {
      skip: !company,
    }
  );

  const memoizedArgs = useMemo(
    () => ({
      company_id: company?.id,
      projectId: projectSelectId,
    }),
    [company?.id, projectSelectId]
  );

  const [activate, { isLoading: activateIsLoading }] =
    useActivateFeedMutation();

  const [state] = useDataFetcher(
    { isLoading: false, isError: false },
    feedManagementService.getFeedList,
    memoizedArgs,
    !!company
  );

  useEffect(() => {
    if (state && state?.data) {
      dispatch(setFeeds(state?.data));
    }
  }, [state, state?.data]);

  const activateFeed = () => {
    activate({ companyId: company?.id, projectId: projectSelectId, feedId })
      .then(() => feedManagementService.getFeedList(memoizedArgs))
      .then((fetchDataResponse) => {
        if (fetchDataResponse.status === 200) {
          dispatch(setFeeds(fetchDataResponse?.data));
        }
      })
      .catch((err) => {
        console.error('Error during activation or data fetching:', err);
      });
  };

  const projectActive = projectDetails && projectDetails.active;
  // filter country code from country name & set timezone
  const filterCountryCode = (countryName) => {
    const allCountries = getAllCountries();

    const filteredCountries = Object.keys(allCountries)
      .filter(
        (countryCode) =>
          allCountries[countryCode].name.toLowerCase() ===
          countryName.toLowerCase()
      )
      .map((countryCode) => ({
        code: countryCode,
        name: allCountries[countryCode].name,
      }));

    return filteredCountries;
  };

  useEffect(() => {
    if (projectFeeds && projectFeeds.length > 0) {
      const firstCountryName = projectFeeds[0].country;
      const filteredCountryCodes = filterCountryCode(firstCountryName);
      const countryTimezones = getTimezonesForCountry(
        filteredCountryCodes[0]?.code
      );
      const timezone =
        countryTimezones && countryTimezones.length > 0
          ? countryTimezones[0]?.name
          : '';
      scheduleUpdateStore('timezone', timezone);
    }
  }, [data, projectFeeds]);

  return (
    <FetchingDisplay
      isError={isError && error.status !== 403}
      isLoading={isLoading}
      ErrorElement={
        <PageErrors
          isServerError
          code={error?.originalStatus || error?.status}
        />
      }
      LoadingElement={<GlobalLoadingComponent />}
      SuccessElement={
        <ErrorBoundary fallback={<PageErrors />}>
          <Container maxWidth='xxl'>
            <TitleCard title='Feed preview'>
              <Stack direction='row' spacing={2}>
                <ToggleButtonFeeds toggled={toggled} setToggled={setToggled} />
              </Stack>
            </TitleCard>
            <StyledPaper>
              {isFeedInactive ? (
                <>
                  <Backdrop
                    open={activateIsLoading}
                    sx={{ zIndex: 3, color: '#FFF' }}
                  >
                    <CircularProgress color='inherit' />
                  </Backdrop>
                  <Box sx={{ textAlign: 'center' }}>
                    <ErrorIcon />
                    <br />
                    <Typography variant='bodyMedium'>
                      Your feed is not active. <br /> Click on activate to
                      preview.
                    </Typography>
                    <Stack
                      direction='row'
                      justifyContent='center'
                      gap={2}
                      mt={1}
                      flexWrap='wrap'
                      mx='auto'
                    >
                      <Button
                        component={Link}
                        to='../feeds'
                        variant='outlined'
                        sx={{
                          flex: '1 1 180px',
                          maxWidth: '220px',
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        variant='contained'
                        onClick={activateFeed}
                        sx={{
                          flex: '1 1 180px',
                          maxWidth: '220px',
                        }}
                        disabled={
                          user?.role === 'COLLABORATOR' ||
                          user?.role === 'SUB_COLLABORATOR'
                        }
                      >
                        Activate Feed
                      </Button>
                    </Stack>
                  </Box>
                </>
              ) : (
                <>
                  {!areAllFeedsInactive &&
                    (!scheduleData || !projectActive) &&
                    (user?.role === 'COLLABORATOR' ||
                      user?.role === 'SUB_COLLABORATOR') && (
                      <ErrorMessageBox mainMessage='This Project is in in-active mode.' />
                    )}
                  {!areAllFeedsInactive &&
                    (!scheduleData || !projectActive) &&
                    user?.role !== 'COLLABORATOR' &&
                    user?.role !== 'SUB_COLLABORATOR' && (
                      <SnackbarWithCta
                        mainMessage={`This project is in in-active mode. Click on 'Activate & Schedule' to activate and run this project once every ${scheduleTimezone.selectOnly} hrs at ${scheduleTimezone.secondSelect} - ${scheduleTimezone.timezone}.`}
                        description='You can choose a different timezone in Settings > Schedule'
                      />
                    )}
                  <FeedsPreviewList data={data} />
                </>
              )}
            </StyledPaper>
          </Container>
        </ErrorBoundary>
      }
    />
  );
};

export { FeedsPreview };
