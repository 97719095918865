import React from 'react';
import { Box, Typography } from '@mui/material';
import { ArrowDownwardRounded } from '@mui/icons-material';
import { ConversionBox, StatBox } from './styles';

const ConversionFunnelGraph = ({ data }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      height: '100%',
      clipPath: 'polygon(0% 0, 100% 0%, 80% 100%, 20% 100%);',
      '@media(min-width:1000px)': {
        clipPath: 'polygon(0% 0, 100% 0%, 65% 100%, 35% 100%);',
      },
    }}
  >
    <StatBox index={0}>
      <Typography component='dd' variant='button'>
        Added to Cart{' '}
      </Typography>
      <Typography component='dt' variant='button'>
        {data.addToCart}
      </Typography>
    </StatBox>
    <ConversionBox>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <ArrowDownwardRounded />
        <span>
          <Typography component='dd' variant='button' textAlign='center'>
            {Math.round((data.initiateCheckout * 100) / data.addToCart)} %
          </Typography>
          <Typography component='dt' variant='button'>
            Conversion Rate
          </Typography>
        </span>
      </Box>
    </ConversionBox>
    <StatBox index={1}>
      <Typography component='dd' variant='button'>
        Initiate Checkout
      </Typography>
      <Typography component='dt' variant='button'>
        {data.initiateCheckout}
      </Typography>
    </StatBox>
    <ConversionBox>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <ArrowDownwardRounded />
        <span>
          <Typography component='dd' variant='button' textAlign='center'>
            {Math.round((data.purchases * 100) / data.initiateCheckout)} %
          </Typography>
          <Typography component='dt' variant='button'>
            Conversion Rate
          </Typography>
        </span>
      </Box>
    </ConversionBox>
    <StatBox placetop='true' index={2}>
      <Typography
        component='dd'
        variant='button'
        color='#FFF'
        sx={{
          marginTop: 2,
          '@media(min-width:600px)': {
            marginTop: 0,
          },
        }}
      >
        Purchases
      </Typography>
      <Typography component='dt' variant='button' color='#FFF'>
        {data.purchases}
      </Typography>
    </StatBox>
  </Box>
);

export { ConversionFunnelGraph };
