/* eslint-disable no-nested-ternary */
import React, { useState, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ConversionFunnelChart } from '../../../../Databoxes/ConversionFunnel/Chart';

import { Databox } from '../../../../../../components/Databox';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { Loading } from '../../../../../../components/Databox/Loading';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';

import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { getDashboardDataService } from '../../../../../../utils/getDashboardDataService';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { ErrorComponent } from '../../../../../../components/GlobalErrorComponent';

const ConversionFunnelContainer = ({ accountId, dateRange }) => {
  const [count, setCount] = useState(0);
  const memoizedArgs = useMemo(
    () => ({
      account_id: accountId,
      date_range: dateRange,
      databox_type: 'CONVERSION_FUNNEL',
    }),
    [accountId, dateRange]
  );
  const [state] = useDataFetcher(
    [],
    getDashboardDataService('meta'),
    memoizedArgs,
    count
  );
  return (
    <Databox>
      <ErrorBoundary
        fallback={
          <ErrorComponent
            title='Conversion Funnel'
            info='Know how many users have advanced from adding items to the cart to buying 
            them. Also, know the conversion percentage for a quick understanding.'
          />
        }
      >
        <DataboxHeading
          title='Conversion Funnel'
          info='Know how many users have advanced from adding items to the cart to buying 
        them. Also, know the conversion percentage for a quick understanding.'
        />
        <FetchingDisplay
          isLoading={state.isLoading}
          isError={state.isError}
          LoadingElement={<Loading />}
          ErrorElement={
            <ErrorDisplay
              refresh={() => {
                setCount(count + 1);
              }}
              message={
                state.errorInfo?.code === 204
                  ? 'No data available for selected date range.'
                  : state.errorInfo?.code === 400
                  ? "Couldn't load data"
                  : 'Something went wrong'
              }
              showRefreshButton={
                !(
                  state.errorInfo?.code === 400 || state.errorInfo?.code === 204
                )
              }
            />
          }
          SuccessElement={<ConversionFunnelChart data={state.data} />}
        />
      </ErrorBoundary>
    </Databox>
  );
};

export { ConversionFunnelContainer };
