import React from 'react';
import { Box, Typography } from '@mui/material';
import { SvgContainer } from './styles';

const Stat = ({ icon, label, type, value, hasBackground, stayVertical }) => {
  const Icon = icon;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 32px',
        width: '100%',
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
            gap: 0.5,
          }}
        >
          <Typography variant='smallRegular' fontSize='1rem'>
            {label}
          </Typography>
          <Typography variant='smallBold' fontSize='1rem'>
            {value}
          </Typography>
        </Typography>

        {/* <Tooltip title={label} sx={{ color: 'text.faded', marginX: '12px' }}>
          <HelpOutlineIcon fontSize='small' />
        </Tooltip> */}
      </Box>
      <SvgContainer
        sx={{ height: 'fit-content' }}
        type={type}
        hasBackground={hasBackground}
        stayVertical={stayVertical}
      >
        <Icon className='statIcon' />
      </SvgContainer>
    </Box>
  );
};

export { Stat };
