/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import { object, string, boolean, mixed, array, number } from 'yup';

const isAValidSingleFieldObject = () =>
  object({
    value: mixed().required('Value cannot be empty'),
  });

const isAValidObjectWithField = (fields) =>
  object({
    value: string()
      .required('Select a field')
      .oneOf(fields, 'Field should be present in the current project'),
  });

const isAValidReplacerObject = () =>
  object({
    value: string().required('Value cannot be empty'),
    replaces: string().required('Value cannot be empty'),
    caseSensitive: boolean().required(),
  });

const isAValidCombinationObject = (fields) =>
  array().of(
    object({
      type: string().required().oneOf(['field', 'static']),
      value: mixed().when('type', {
        is: 'field',
        then: string()
          .typeError('Field cannot be null')
          .required('Select a field')
          .oneOf(fields, 'Field should be present in the current project'),
      }),
    })
  );

const isAValidSearchUsingObject = (fields) =>
  object({
    using: string(),
    replaces: string().required('Value cannot be empty'),
    value: string()
      .required('Select a field')
      .oneOf(fields, 'Field should be present in the current project'),
  });

const isAValidReplaceValueObject = (fields) =>
  object({
    query: array().of(
      object({
        type: string().required().oneOf(['field', 'static']),
        value: mixed().when('type', {
          is: 'field',
          then: string()
            .typeError('Field cannot be null')
            .required('Select a field')
            .oneOf(fields, 'Field should be present in the current project'),
        }),
      })
    ),
    value: array().of(
      object({
        type: string().required().oneOf(['field', 'static']),
        value: mixed().when('type', {
          is: 'field',
          then: string()
            .typeError('Field cannot be null')
            .required('Select a field')
            .oneOf(fields, 'Field should be present in the current project'),
        }),
      })
    ),
  });

const isAValidMaxiumLengthObject = () =>
  object({
    option: string().oneOf(['trim_on_words', 'trim_directly']).required(),
    value: number().typeError('Value must be a number').required(),
  });

const isAValidModifyTextObject = () =>
  object({
    value: string()
      .oneOf(
        [
          'CAPITALIZE',
          'TITLE',
          'CAMELCASE',
          'LOWERCASE',
          'UPPERCASE',
          'ONLY_DIGITS',
          'REMOVE_DIGITS',
          'REMOVE_LINEBREAKS',
          'REMOVE_WHITESPACES',
          'STRIP_HTML',
        ],
        'Select a valid operation'
      )
      .required(),
  });

const isAValidRoundNumberObject = () =>
  object({
    direction: string().oneOf(
      ['closest', 'up', 'down'],
      'Select a valid direction'
    ),
    value: string().oneOf(
      [
        '1_cent',
        '5_cents',
        '10_cents',
        '25_cents',
        '95_cents',
        '99_cents',
        'whole_number',
      ],
      'Select a valid value'
    ),
  });

const isAValidCalculateObject = (fields) =>
  object({
    field: mixed()
      .required('Select a field')
      .oneOf(fields, 'Field should be present in the current project'),
    operation: string().oneOf(
      [
        'multiply_by',
        'divide_by',
        'plus',
        'minus',
        'multiply_by_field',
        'divide_by_field',
        'plus_field',
        'minus_field',
      ],
      'Select a valid operation'
    ),
    value: mixed()
      .required()
      .when('operation', {
        is: (value) => value.includes('field'),
        then: string()
          .typeError('Field cannot be null')
          .oneOf(fields, 'Field should be present in the current project'),
        otherwise: number().typeError('Enter a number'),
      }),
  });

const isAValidGroupItemsObject = (fields) =>
  object({
    value: array().of(
      string().oneOf(fields, 'Field should be present in the current project')
    ),
  });

const isAValidDedulicateItemsObject = (fields) =>
  object({
    sub_filter: string()
      .required()
      .oneOf(
        ['exclude_duplicates', 'keep_lowest', 'keep_highest'],
        'Select a valid filter'
      ),
    preservedField: string()
      .oneOf(fields, 'Field should be present in the current project')
      .when('sub_filter', {
        is: (value) => ['keep_lowest', 'keep_highest'].includes(value),
        then: (schema) => schema.required(),
      }),
    sort: boolean().required(),
  });

const isAValidSplitTextObject = (fields) =>
  object({
    field: string()
      .oneOf(fields, 'Field should be present in the current project')
      .required(),
    separator: string()
      // .oneOf([',', '.'], "Value can be either , or '")
      .required(),
    useFrom: string().required(),
    useTill: string().required(),
    addTogetherUsing: string(),
    // .oneOf([',', '.'], "Value can be either , or '")
    // .required(),
  });

const isAValidReformatObject = () =>
  object({
    decimal_separator: string()
      .oneOf([',', '.'], "Value can be either , or '")
      .required(),
    number_of_decimals: string()
      .oneOf(
        ['no_rounding', '0', '1', '2', '3', '4', '5', '6'],
        'Select a valid value'
      )
      .required(),
    thousands_separator: string()
      .oneOf([',', '.'], "Value can be either , or '")
      .required(),
  });

const isAValidCalculateItemGroupObject = (fields) =>
  object({
    method: string()
      .required()
      .oneOf(
        [
          'count_items',
          'calculate_sum',
          'find_lowest_value',
          'find_highest_value',
        ],
        'Select a valid value'
      ),
    field: string()
      .oneOf(fields, 'Field should be present in the current project')
      .when('method', {
        is: (value) =>
          ['calculate_sum', 'find_lowest_value', 'find_highest_value'].includes(
            value
          ),
        then: (schema) => schema.required(),
      }),
    storageField: string().when('method', {
      is: (value) =>
        ['calculate_sum', 'find_lowest_value', 'find_highest_value'].includes(
          value
        ),
      then: (schema) =>
        schema
          .required()
          .oneOf(fields, 'Field should be present in the current project'),
      otherwise: (schema) => schema.nullable(),
    }),
    sort: boolean().when('method', {
      is: (value) =>
        ['calculate_sum', 'find_lowest_value', 'find_highest_value'].includes(
          value
        ),
      then: (schema) => schema.required(),
    }),
  });

const isAValidSortListObject = () =>
  object({
    sort_numbers: boolean().required(),
    direction: string()
      .typeError('Must be a string')
      .required()
      .oneOf(['desc', 'asc']),
  });

const isAValidSliceListObject = (fields) =>
  object({
    start: string().typeError('Must be a string').required(),
    end: string().typeError('Must be a string').required(),
    field: string().typeError('Must be a string').required().oneOf(fields),
  });

const getValidationFunction = (method, parent) => {
  if (
    ['SET_TO_VALUE', 'APPEND_VALUE'].includes(method) &&
    parent?.field === 'gtin'
  ) {
    return () =>
      object({
        value: number()
          .typeError('Value must be a number')
          .required('Field cannot be empty'),
      });
  }
  switch (true) {
    case [
      'SET_TO_VALUE',
      'APPEND_VALUE',
      'JOIN_LIST_TO_TEXT',
      'SPLIT_TEXT_TO_LIST',
    ].includes(method):
      return isAValidSingleFieldObject;
    case [
      'COPY_VALUE',
      'CALCULATE_LENGTH',
      'CALCULATE_LIST_LENGTH',
      'CALCULATE_SUM',
    ].includes(method):
      return isAValidObjectWithField;
    case ['LOOKUP_AND_REPLACE_VALUE', 'SEARCH_AND_REPLACE_VALUE'].includes(
      method
    ):
      return isAValidReplacerObject;
    case method === 'COMBINE_VALUE':
      return isAValidCombinationObject;
    case method === 'SEARCH_FOR_VALUE':
      return isAValidSearchUsingObject;
    case method === 'REPLACE_VALUE':
      return isAValidReplaceValueObject;
    case method === 'MAXIMUM_LENGTH':
      return isAValidMaxiumLengthObject;
    case method === 'MODIFY_TEXT':
      return isAValidModifyTextObject;
    case method === 'ROUND_NUMBER':
      return isAValidRoundNumberObject;
    case method === 'CALCULATE':
      return isAValidCalculateObject;
    case method === 'GROUP_ITEMS':
      return isAValidGroupItemsObject;
    case method === 'DEDULICATE_ITEMS':
      return isAValidDedulicateItemsObject;
    case method === 'SPLIT_TEXT':
      return isAValidSplitTextObject;
    case method === 'REFORMAT_NUMBER':
      return isAValidReformatObject;
    case method === 'CALCULATE_ITEM_GROUP':
      return isAValidCalculateItemGroupObject;
    case method === 'SORT_LIST':
      return isAValidSortListObject;
    case method === 'SLICE_LIST':
      return isAValidSliceListObject;
    default:
      return null;
  }
};

export { getValidationFunction };
