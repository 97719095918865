// react imports
import React from 'react';
import { Paper, Typography } from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

const NoData = () => (
  <Paper
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      maxWidth: '60%',
      borderRadius: '8px',
      svg: {
        maxWidth: '70%',
      },
      p: 3,
      m: 2,
    }}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      // xmlns:xlink='http://www.w3.org/1999/xlink'
      width='174'
      height='134'
      viewBox='0 0 174 134'
    >
      <defs>
        <clipPath id='clip-path'>
          <rect
            id='Rectangle_2560'
            data-name='Rectangle 2560'
            width='174'
            height='134'
            transform='translate(-4662 -18421)'
            fill='#fff'
          />
        </clipPath>
      </defs>
      <g
        id='Mask_Group_69'
        data-name='Mask Group 69'
        transform='translate(4662 18421)'
        clipPath='url(#clip-path)'
      >
        <g
          id='Group_4878'
          data-name='Group 4878'
          transform='translate(-931.3 239.9)'
        >
          <path
            id='Path_2652'
            data-name='Path 2652'
            d='M4.738-.166l63.433.53,48.681,38.074-61.241-1.6Z'
            transform='translate(-3708.301 -18621.701) rotate(-19)'
            fill='#adcce0'
          />
          <path
            id='Path_2647'
            data-name='Path 2647'
            d='M-3673-18585.467l-29.9-9.754v46.621l59.034,20.775v-77.475l-29.134,19.832-57.7-19.832,27.8-18.268-27.8-18.1,57.7-19.234,29.134,17.9,27.8-17.9,58.867,19.234-27.434,18.1,27.434,18.268-56.934,19.832-29.733-19.832,59.233-18.268-59.233-19.434-59.034,19.434,59.034,18.268'
            fill='#e0f2ff'
            stroke='#8ad2ff'
            strokeWidth='1'
          />
          <path
            id='Path_2648'
            data-name='Path 2648'
            d='M-3702.5-18595.824v48'
            fill='#e0f2ff'
            stroke='#8ad2ff'
            strokeWidth='1'
          />
          <path
            id='Path_2649'
            data-name='Path 2649'
            d='M-3585.075-18595.824v48l-59,20.3'
            fill='#e0f2ff'
            stroke='#8ad2ff'
            strokeWidth='1'
          />
          <path
            id='Path_2651'
            data-name='Path 2651'
            d='M-3585.075-18595.824v0l-28.245,10.344-30.755-19.66v77.615'
            fill='#e0f2ff'
            stroke='#8ad2ff'
            strokeWidth='1'
          />
          <path
            id='Path_2662'
            data-name='Path 2662'
            d='M147.355,27.672l11.633,2.912a2.693,2.693,0,0,0,3.343-2.615h0a2.708,2.708,0,0,0-2.035-2.615l-11.633-2.912a2.693,2.693,0,0,0-3.343,2.615h0a2.708,2.708,0,0,0,2.035,2.615Z'
            transform='translate(-3771.6 -18663.188) rotate(3)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
    <Typography variant='smallBold' p={1}>
      No Data
    </Typography>
    <Typography
      variant='smallMedium'
      sx={{ textAlign: 'center', textAlignLast: 'center' }}
    >
      There is no data in your account in selected data range.
    </Typography>
    <Typography variant='smallMedium'>
      Select a different date range.
    </Typography>
  </Paper>
);

const CompanyBell = ({ heading, primaryText, secText }) => (
  <Paper
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      borderRadius: '8px',
      m: 2,
      p: 3,
      maxWidth: '60%',
    }}
  >
    <NotificationsNoneOutlinedIcon color='error' sx={{ fontSize: '4rem' }} />
    <Typography variant='smallMedium' color='text.grey' fontSize='1rem'>
      {heading}
    </Typography>
    <Typography
      variant='smallMedium'
      color='text.grey'
      fontSize='1rem'
      sx={{
        textAlign: 'center',
        textAlignLast: 'center',
      }}
    >
      <Typography variant='smallBold' color='text.primary' fontSize='1rem'>
        {primaryText}
      </Typography>
      &nbsp;{secText}
    </Typography>
  </Paper>
);

export { NoData, CompanyBell };
