/* eslint-disable max-classes-per-file */
import { authInstance } from '../api/authentication.instance';
import {
  accountsURI,
  accountGroupURI,
  companyProjectURI,
  companyURI,
  companyDetailURI,
  getBranchesAccountsListURI,
  invitedPlatformsURI,
  getCompaniesBranchesListURI,
  accessibleAccountsURI,
  projectListURI,
  accountURI,
  getAccountsURI,
} from '../constants/endpoints';
import { accountsMetricsURI } from '../constants/endpoints/api/account';

const fetchAccounts = () =>
  authInstance
    .get(accountsURI)
    .then((response) => response)
    .catch((error) => error);

const createAccount = (account, branchId = null) => {
  const URI = branchId ? getBranchesAccountsListURI(branchId) : accountsURI;
  return authInstance
    .post(URI, account)
    .then((response) => response)
    .catch((error) => error);
};

class AccountsService {
  static fetchAccounts = () => authInstance.get(accountsURI);

  static createAccount = (account, branchId = null) => {
    const URI = branchId ? getBranchesAccountsListURI(branchId) : accountsURI;
    return authInstance.post(URI, account);
  };

  static createAccountGroupId = (data) =>
    authInstance.post(accountGroupURI, data);

  static createManualInvite = () =>
    new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });

  static createCompany = (company) => authInstance.post(companyURI, company);

  static accessibleAccounts = (config = {}) =>
    authInstance.get(accessibleAccountsURI, {
      config,
    });

  static createAccounts = (data) => authInstance.post(accountURI, data);

  static getAccounts = (companyId, config = {}) =>
    authInstance.get(getAccountsURI(companyId), config);

  static getAccountsWithoutMetrics = ({ companyId }) =>
    authInstance.get(getAccountsURI(companyId));

  static getAccountsMetrics = ({ companyId, dateRange }) =>
    authInstance.post(accountsMetricsURI, {
      company_id: companyId,
      date_range: dateRange,
    });

  static renameAccount = (name, id) =>
    authInstance.patch(`/api/accounts/details/${id}`, {
      name,
    });

  static deleteAccount = (id) =>
    authInstance.delete(`/api/accounts/details/${id}`);

  static activateAccount = (id) =>
    authInstance.patch(`/api/accounts/details/${id}`, {
      is_active: true,
    });

  static linkAccount = (id) =>
    authInstance.post(`/api/google-ads/link-accounts`, {
      account_id: id,
    });

  // static handleLinkClick = (e, platform) => {

  // };
}

class CompanyService {
  // static fetchCompanies = () => authInstance.get(companiesURI);

  static fetchCompany = (data) => authInstance.post(companyDetailURI, data);

  static createCompanyProject = (company) =>
    authInstance.post(companyProjectURI, company);

  static createCompany = (company) => authInstance.post(companyURI, company);

  static getCompany = (config = {}) => authInstance.get(companyURI, config);

  static fetchBranches = (companyId) => {
    const URI = getCompaniesBranchesListURI(companyId);
    return authInstance.get(URI);
  };
}

class ProjectService {
  static getProject = () => authInstance.get(projectListURI);
}

// export const createAccountForBranch = (branchId, account) => {

// };
class PlatformManualInviteService {
  static createManualInvites = (data) =>
    authInstance
      .post(invitedPlatformsURI, data)
      .then((response) => response)
      .catch((error) => error);

  static getManualInvitesOfPlaforms = () =>
    authInstance.get(invitedPlatformsURI);
}

export {
  fetchAccounts,
  createAccount,
  AccountsService,
  CompanyService,
  PlatformManualInviteService,
  ProjectService,
};
