import { Box, Button, Fade, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { StyledGlobalModal } from '../Modal/styles';
import { FetchingDisplay } from '../FetchingDisplay';

// runs a function onclick which fires a request
// OR
// is part of a form which fires a request on submit
// opens and displays a modal on click which shows the status of the sent request
// works with RTK hooks
const SubmitButtonWithStatusModal = ({
  buttonText = 'Submit',
  variant = 'contained',
  buttonSX,
  isLoading,
  isSuccess,
  isError,
  isUninitialized,
  reset,
  loadingTextElement = (
    <Typography variant='bodyMedium' textAlign='center' component='p'>
      Processing
    </Typography>
  ),
  onClick,
  successTextElement = 'Success',
  errorTextElement = 'Error',
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const successTimeout =
      isSuccess &&
      (() => {
        reset();
      },
      1000);

    return clearTimeout(successTimeout);
  }, [isSuccess, reset]);

  const closeModal = () => {
    if (isUninitialized || isSuccess || isError) {
      setOpen(false);
      setTimeout(() => {
        reset();
      }, 100);
    }
  };

  const openModal = () => {
    setOpen(true);
  };

  return (
    <>
      <Button
        sx={buttonSX}
        type='submit'
        onClick={() => {
          openModal();
          if (onClick) onClick();
        }}
        variant={variant}
      >
        {buttonText}
      </Button>
      <Modal
        open={open && !isUninitialized}
        closeAfterTransition
        onClose={closeModal}
      >
        <Fade in={open && !isUninitialized}>
          <StyledGlobalModal>
            <FetchingDisplay
              isLoading={isLoading}
              isError={isError}
              isSuccess={isSuccess}
              LoadingElement={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {loadingTextElement}
                </Box>
              }
              SuccessElement={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CheckCircle sx={{ color: 'primary.main' }} />
                  {successTextElement}
                </Box>
              }
              ErrorElement={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Cancel sx={{ color: 'error.main' }} />
                  {errorTextElement}
                </Box>
              }
            />
          </StyledGlobalModal>
        </Fade>
      </Modal>
    </>
  );
};

export { SubmitButtonWithStatusModal };
