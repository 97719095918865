/* eslint-disable import/no-default-export */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const AllItemCustomizedColumnSlice = createSlice({
  name: 'CustomizedColumn',
  initialState: {
    toggleButton : "",
    actionId : "",
    // states for "All Items"
    allItemsColumn: [],
    columnTobeRenderd: [],
    desiredColumn: [
      'image',
      'id',
      'title',
      'description',
      'price',
      'availability',
    ],

    // state for Enhance table
    enhaceColumn: [],
    columnTobeRenderdInEnhance: [],
    desiredColumnInEnhance: [
      'image',
      'id',
      'title',
      'description',
      'price',
      'availability',
    ],

    // state for preview
    previewColumn: [],
    columnTobeRenderdInPreview: [],
    desiredColumnInPreview: [
      'image',
      'id',
      'title',
      'description',
      'price',
      'availability',
    ],

    // state for affected Items
    affectedItemsData : [],
    affectedItemsColumn: [],
    columnTobeRenderdInAffectedItems: [],
    desiredColumnInAffectedItems: [
      'image',
      'id',
      'title',
      'description',
      'price',
      'availability',
    ],
  
   


  },
  reducers: {
    updateActionId : (state , action) => {
      state.actionId = action.payload
    },
    updateToggleButton : (state , action) => {
      state.toggleButton = action.payload;
    },
    // reducers for "All Items"
    updateDesirdColumn: (state, action) => {
      state.desiredColumn = action.payload;
    },
    updateColumnTobeRenderd: (state, action) => {
      state.columnTobeRenderd = action.payload;
    },
    updateColumn: (state, action) => {
      state.allItemsColumn = action.payload;
    },

    // reducers for Enhance table
    updateDesirdColumnInEnhance: (state, action) => {
      state.desiredColumnInEnhance = action.payload;
    },
    updateColumnTobeRenderdInEnhance: (state, action) => {
      state.columnTobeRenderdInEnhance = action.payload;
    },
    updateColumnInEnhance: (state, action) => {
      state.enhaceColumn = action.payload;
    },

    // reducers for preview
    updateDesirdColumnInPreview: (state, action) => {
      state.desiredColumnInPreview = action.payload;
    },

    updateColumnTobeRenderdInPreview: (state, action) => {
      state.columnTobeRenderdInPreview = action.payload;
    },
    updateColumnInPreview: (state, action) => {
      state.previewColumn = action.payload;
    },

    // reducers for affected Items
    updateDesirdColumnInAffectedItems: (state, action) => {
      state.desiredColumnInAffectedItems = action.payload;
    },

    updateColumnTobeRenderdInAffectedItems: (state, action) => {
      state.columnTobeRenderdInAffectedItems = action.payload;
    },
    updateColumnInAffectedItems: (state, action) => {
      state.affectedItemsColumn = action.payload;
    },
    updateAffectedItemsData : (state , action) => {
      state.affectedItemsData = action.payload
    },
    
  },
});

// eslint-disable-next-line import/no-default-export
export default AllItemCustomizedColumnSlice.reducer;
export const {
  updateColumn,
  updateColumnTobeRenderd,
  updateDesirdColumn,
  updateColumnInEnhance,
  updateColumnTobeRenderdInEnhance,
  updateDesirdColumnInEnhance,
  updateColumnInPreview,
  updateColumnTobeRenderdInPreview,
  updateDesirdColumnInPreview,
  updateColumnInAffectedItems,
  updateColumnTobeRenderdInAffectedItems,
  updateDesirdColumnInAffectedItems,
  updateAffectedItemsData,
  updateRemainingItemsData,
  updateRemovedItemsData,
  updateToggleButton,
  updateActionId
} = AllItemCustomizedColumnSlice.actions;
