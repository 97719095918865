import _ from 'lodash';
import { getCellRange, toTitleCase } from './index';

const createTableForAdInsightsMeta = (
  sheet,
  label,
  dataVariable,
  currencyCode
) => {
  sheet.mergeCells(getCellRange(1, 1, 6));
  const titleCell = sheet.getCell(`A1`);

  titleCell.value = toTitleCase(label);
  titleCell.alignment = { horizontal: 'center' };
  sheet.addTable({
    name: label,
    ref: `A2`,
    headerRow: true,
    totalsRow: false,
    style: {
      theme: null,
    },
    columns: [
      {
        name: 'Name',
        totalsRowFunction: 'none',
        totalsRowLabel: 'Total',
        filterButton: false,
      },
      {
        name: 'Inline Link Clicks',
        totalsRowFunction: 'sum',
        totalsRowLabel: 'Total',
        filterButton: false,
      },
      {
        name: 'Reach',
        totalsRowFunction: 'sum',
        totalsRowLabel: 'Total',
        filterButton: false,
      },
      {
        name: `Spend (${currencyCode})`,
        totalsRowFunction: 'sum',
        totalsRowLabel: 'Total',
        filterButton: false,
      },
      {
        name: 'Conversions',
        totalsRowFunction: 'sum',
        totalsRowLabel: 'Total',
        filterButton: false,
      },
      {
        name: 'ROAS',
        totalsRowFunction: 'average',
        totalsRowLabel: 'Total',
        filterButton: false,
      },
    ],
    rows: dataVariable
      .map((v) => [
        ...v.ads.map((v1) => [
          v1.Ad_Name,
          v1.Inline_Link_Clicks,
          v1.Reach,
          v1.Spend,
          v1.Conversions,
          v1.ROAS,
        ]),
      ])
      .reduce((prev, current) => [...prev, ...current], []),
  });

  [
    'Ad_Name',
    'Inline_Link_Clicks',
    'Reach',
    'Spend',
    'Conversions',
    'ROAS',
  ].forEach((key, index) => {
    // pattern total row
    const totalCell = sheet.getCell(
      `${String.fromCharCode(index + 65)}${
        dataVariable.reduce((prev, current) => prev + current.ads.length, 0) + 3
      }`
    );
    if (index === 0) {
      totalCell.value = 'Total';
    } else if (index === 5) {
      totalCell.value =
        dataVariable.reduce(
          (prev, current) => prev + _.sumBy(current.ads, key),
          0
        ) /
        dataVariable.reduce((prev, current) => prev + current.ads.length, 0);
    } else {
      totalCell.value = dataVariable.reduce(
        (prev, current) => prev + _.sumBy(current.ads, key),
        0
      );
    }
  });
};

export { createTableForAdInsightsMeta };
