/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useContext, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box } from '@mui/material';
import { CustomizeButton } from '../../../../../../widgets/CustomizeButton';
import { MonthlyInsights } from '../../../../Databoxes/MonthyInsights';
import { Databox } from '../../../../../../components/Databox';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { Filter } from '../../../../../../components/Filter';
import { Loading } from '../../../../../../components/Databox/Loading';
import { getDashboardDataService } from '../../../../../../utils/getDashboardDataService';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';
import { CustomizedColumnModal } from '../../../../../MetricAndDimension/DashboardDatabox/CustomizeColumn';
import { ErrorComponent } from '../../../../../../components/GlobalErrorComponent';

const MonthlyInsightsContainer = ({ platform, accountId }) => {
  const [open, setOpen] = useState(false);
  const { account } = useContext(UserInfoContext);
  const [count, setCount] = useState(0);
  const [filterListForPlatform, setFilterListForPlatform] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState();

  const memoizedArgs = useMemo(
    () => ({
      account_id: accountId,
      databox_type: 'MONTH_ON_MONTH',
    }),
    [accountId]
  );

  const [state] = useDataFetcher(
    {},
    getDashboardDataService(platform),
    memoizedArgs,
    count
  );
  const onCustomizeClick = () => {
    setOpen(true);
  };

  const handleFilterChange = (newFilter) => {
    setSelectedFilter(newFilter);
  };

  const filterList = state?.data?.reduce(
    (prev1, obj) => [
      ...prev1,
      ...Object.keys(obj).reduce((prev, current) => {
        if (obj[current].error) {
          return prev;
        }
        return [...prev, { name: current, value: current }];
      }, []),
    ],
    []
  );

  useEffect(() => {
    if (filterList) {
      setFilterListForPlatform(filterList || []);
      setSelectedFilter(filterList[0]);
    }
  }, [state.data]);

  return (
    <Databox>
      <ErrorBoundary
        fallback={
          <ErrorComponent
            title='Monthly Insights'
            info='Know your monthly account performance for the selected year. This will help in distributing the ad budget more efficiently.'
          />
        }
      >
        <DataboxHeading
          title='Monthly Insights'
          info='Know your monthly account performance for the selected year. This will help in distributing the ad budget more efficiently.'
        >
          <Box sx={{ display: 'flex' }}>
            <CustomizeButton
              onCustomizeClick={onCustomizeClick}
              account={account}
            />
            {open && (
              <CustomizedColumnModal
                open={open}
                setOpen={setOpen}
                databoxType='month_on_month'
                accountId={accountId}
                count={count}
                setCount={setCount}
              />
            )}
            {selectedFilter ? (
              <Filter
                label='Filter'
                selectedFilter={selectedFilter}
                filterList={filterListForPlatform || []}
                handleFilterChange={handleFilterChange}
              />
            ) : null}
          </Box>
        </DataboxHeading>
        <FetchingDisplay
          isLoading={state.isLoading || !selectedFilter}
          isError={state.isError}
          LoadingElement={<Loading />}
          ErrorElement={
            <ErrorDisplay
              refresh={() => {
                setCount(count + 1);
              }}
              message={
                state.errorInfo?.code === 204
                  ? 'No data available for selected date range.'
                  : state.errorInfo?.code === 400
                  ? "Couldn't load data"
                  : 'Something went wrong'
              }
              showRefreshButton={
                !(
                  state.errorInfo?.code === 400 || state.errorInfo?.code === 204
                )
              }
            />
          }
          SuccessElement={
            <MonthlyInsights
              data={state.data}
              filter={selectedFilter}
              currencyCode={account.currency_code}
            />
          }
        />
      </ErrorBoundary>
    </Databox>
  );
};

export { MonthlyInsightsContainer };
