/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import { visuallyHidden } from '@mui/utils';

const TextInput = ({
  name,
  label,
  placeholder,
  id,
  sx,
  hideLabel,
  type = 'text',
  labelIsAdjacent,
  multiline,
  customOnChange = () => { },
  disabled
}) => {
  const [field, meta] = useField(name);
  return (
    <FormControl
      fullWidth
      error={meta.error && meta.touched}
      sx={{
        ...(labelIsAdjacent
          ? {
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            alignItems: 'center',
            '& .MuiInputLabel-root': {
              fontSize: '0.875rem',
              fontWeight: 400,
              marginBottom: 0,
            },
          }
          : {}),
        ...sx,
      }}
    >
      <InputLabel
        htmlFor='id'
        sx={{
          transform: 'none',
          position: 'static',
          flexShrink: 0,
          textAlign: 'justify',
          mb: 1,
          ...(hideLabel && visuallyHidden),
        }}
      >
        {label}
      </InputLabel>
      {/* 
        Addind this wrapper makes sure the helpertext is not adjacent to the input field when the label is adjacent
      */}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <OutlinedInput
          name={name}
          sx={{
            backgroundColor: '#FFF',
            minHeight: '45px',
            flex: 1,
          }}
          id={id}
          type={type}
          {...field}
          onChange={(e) => {
            customOnChange(e);
            field.onChange(e);
          }}
          placeholder={placeholder}
          multiline={multiline}
          minRows={5}
          disabled={disabled}
        />
        {meta.error && meta.touched && (
          <FormHelperText>{meta.error}</FormHelperText>
        )}
      </Box>
    </FormControl>
  );
};

export { TextInput };
