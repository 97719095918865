import React from 'react';

// import _ from 'lodash';

import { DashboardCustomTable } from '../../../../../components/Table';
import { getHeadCells } from '../../../../../components/Headcell/Headcell';

const UserByLocationTable = ({ data, platform, currencyCode = 'ZZZ' }) => {
  if (data.length === 0) {
    return null; // Return null if data is empty
  }

  const formattedData = data.map((item) => {
    const formattedItem = {};

    Object.keys(item).forEach((key) => {
      const formattedKey = key
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (c) => c.toUpperCase());

      formattedItem[formattedKey] = item[key];
    });

    return formattedItem;
  });

  // Separate "Total" row from the data
  const totalRow = formattedData.find(
    (row) => row[platform === 'meta' ? 'Region' : 'Region Name'] === 'Total'
  );

  const filteredData = formattedData.filter(
    (row) => row[platform === 'meta' ? 'Region' : 'Region Name'] !== 'Total'
  );

  if (filteredData.length === 0) {
    return null; // Return null as fallback if filteredData is empty
  }

  const firstDataItem = filteredData[0];
  const defaultFormatFunction = (row, key) => String(row[key]);
  const excludedKeys = ['Criteria Id', 'Latitude', 'Longitude'];

  // Defined getHeadCells function
  const headCells = getHeadCells(
    firstDataItem,
    excludedKeys,
    currencyCode,
    defaultFormatFunction
  );

  return (
    <DashboardCustomTable
      headCells={headCells}
      rows={filteredData}
      totalRow={totalRow}
      rowIdField={platform === 'meta' ? 'Region' : 'Criteria Id'}
      verticalBorders
    />
  );
};

export { UserByLocationTable };
