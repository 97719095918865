import React, { useState, useMemo, useContext } from 'react';

import { Box } from '@mui/material';

import { Filter } from '../../../../../../components/Filter';
import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';

import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { AdInsightsMeta } from '../../../../Databoxes/AdInsightsMeta';
import { Loading } from '../../../../../../components/Databox/Loading';
import { getDemoDashboardService } from '../../../../../../services/DemoDashboard';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { AccountContext } from '../../accountContext';

const filterList = [
  {
    name: 'All',
    value: 'ALL',
  },
  {
    name: 'Conversions',
    value: 'CONVERSIONS',
  },
  {
    name: 'Outcome Sales',
    value: 'OUTCOME_SALES',
  },
];

const AdInsightsMetaContainer = ({ platform, className }) => {
  const { currencyCode } = useContext(AccountContext);
  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'ADS_INSIGHTS',
    }),
    []
  );

  const [state] = useDataFetcher(
    [],
    getDemoDashboardService(platform),
    memoizedArgs
  );

  const [filter, setFilter] = useState(filterList[0]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <Databox>
      <DataboxHeading
        title='Ad Insights'
        info='Know the progress of the ad campaigns of your account.'
      >
        <Filter
          label='Filter'
          selectedFilter={filter}
          filterList={filterList}
          handleFilterChange={handleFilterChange}
          className={className}
        />
      </DataboxHeading>
      <FetchingDisplay
        isError={state.isError}
        isLoading={state.isLoading}
        LoadingElement={<Loading />}
        ErrorElement={<ErrorDisplay />}
        SuccessElement={
          <Box m={2} flexGrow={1} minHeight={0} sx={{ overflowY: 'auto' }}>
            <AdInsightsMeta
              data={state.data}
              filter={filter}
              currencyCode={currencyCode}
            />
          </Box>
        }
      />
    </Databox>
  );
};

export { AdInsightsMetaContainer };
