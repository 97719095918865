import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { UsersPage } from '../UserList/Agency';
import { Error404Page } from '../../Error404Page/Error404Page';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { UserToolbar } from '../UserToolbar';
import { AddAgencyUser } from '../AddUser/AddAgencyUser';
import { AddCompanyUser } from '../AddUser/AddCompanyUser';
import { UsersCompanyPage } from '../UserList/Company';
import { CompanyPage } from '../UserList/Company/Compnay';
import { AddAgencyCompanyUser } from '../AddUser/AddAgencyCompUsers';
import { ProtectedRoute } from '../../../routes/ProtectedRoute';

const UserManagementRouter = () => {
  const { user, isLoggedIn } = useContext(UserInfoContext);

  return (
    <Routes>
      <Route index element={<UserToolbar />} />
      <Route
        path='agency'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            {user.projectType === 'AGENCY' &&
            ['ADMIN', 'COLLABORATOR', 'OWNER'].includes(user.role) ? (
              <UsersPage />
            ) : null}
          </ProtectedRoute>
        }
      />
      <Route
        path='company'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            {user.projectType === 'COMPANY' ||
            (user.projectType === 'AGENCY' &&
              ['SUB_ADMIN', 'SUB_COLLABORATOR'].includes(user.role)) ? (
              <CompanyPage />
            ) : (
              <UsersCompanyPage />
            )}
          </ProtectedRoute>
        }
      />
      <Route
        path='agency/edit-user/:id'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            <AddAgencyUser />
          </ProtectedRoute>
        }
      />
      <Route
        path='agency/add-user'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            <AddAgencyUser />
          </ProtectedRoute>
        }
      />
      <Route
        path='company/add-user'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            <AddAgencyCompanyUser />
          </ProtectedRoute>
        }
      />
      <Route
        path='company/edit-user/:id'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            <AddAgencyCompanyUser />
          </ProtectedRoute>
        }
      />
      <Route
        path='company/add-company-user'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            <AddCompanyUser />
          </ProtectedRoute>
        }
      />
      <Route
        path='company/edit-company-user/:id'
        element={
          <ProtectedRoute isAllowed={isLoggedIn} redirectPath='/signin'>
            <AddCompanyUser />
          </ProtectedRoute>
        }
      />
      <Route path='*' element={<Error404Page />} />
    </Routes>
  );
};

export { UserManagementRouter };
