/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  FormControl,
  TextField,
  Autocomplete,
  FormHelperText,
} from '@mui/material';
import { useField, Field } from 'formik';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomSelect = ({
  name,
  options,
  formatter,
  className,
  defaultValue,
  disabled,
  onChange,
  style,
  val,
}) => {
  const [field, meta, helpers] = useField(name);
  const handleAutocompleteChange = (event, value) => {
    const selectedValue = value ? value.pkid : null;
    helpers.setValue(selectedValue);
    if (onChange) {
      onChange(value);
    }
  };

  const getOptionLabel = (option) => {
    const formettedOption = formatter(option);
    if (option.pkid === '') {
      return formettedOption.props.children.props.children;
      // eslint-disable-next-line no-else-return
    }
    return formettedOption.props.children;
  };

  return (
    <FormControl error={meta.error} className={className} sx={style}>
      <Field
        sx={{
          width: '300px', height: meta.error ? '72px' : '42px',
          '&.Mui-disabled .MuiSelect-select, .MuiSelect-iconOutlined': { backgroundColor: 'transparent' },
          '& .MuiOutlinedInput-root .Mui-disabled': {
            backgroundColor: 'transparent'
          }
        }}
        error={meta.error}
        name={name}
        as={Autocomplete}
        id='autoCompleteField'
        disableClearable
        popupIcon={<ExpandMoreIcon />}
        disabled={disabled}
        defaultValue={defaultValue}
        options={options}
        getOptionLabel={getOptionLabel}
        value={
          field.value !== undefined
            ? options.find((option) => option.pkid === field.value)
            : options.find((option) => option.pkid === field.name) || null
        }
        onChange={handleAutocompleteChange}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            helperText={<FormHelperText>{meta.error}</FormHelperText>}
            error={meta.error || val}
          />
        )}
      />
    </FormControl>
  );
};

export { CustomSelect };
