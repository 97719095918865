/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button } from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const CustomizeButton = ({ onCustomizeClick, account }) => (
  <Button
    startIcon={<DriveFileRenameOutlineIcon fontSize='small' />}
    variant='pillOutlined'
    fontSize='small'
    sx={{
      '&.MuiButton-root': {
        height: 'auto',
        color: 'text.primary',
        fontWeight: '400',
        display: account?.data_stored ? 'inline-flex' : 'none',
        ':hover': {
          color: 'primary.main',
        },
      },
    }}
    onClick={onCustomizeClick}
  >
    Customize
  </Button>
);

export { CustomizeButton };
