import React from 'react';

import _ from 'lodash';
import {
  DataDescription,
  Datalist,
  DataTerm,
  HeadingNameValue,
  NameValueWrapper,
} from './styles';

const MapLegend = ({ data, metricName, currencyCode = 'ZZZ', isCurrency }) => {
  const aggregate = _.round(_.sumBy(data, 'value'), 1);

  const maxValue = Math.max(...data.map((d) => d.value));

  const roundTo =
    metricName.toLowerCase() === 'roas' || metricName.toLowerCase() === 'cpc'
      ? 1
      : 0;
  return (
    <Datalist>
      {/* datalist requires dd tags to be after dt. */}
      <HeadingNameValue>
        <DataTerm component='dt' variant='bodySemiBold'>
          {isCurrency && currencyCode}{' '}
          {_.round(aggregate, roundTo).toLocaleString()}
        </DataTerm>
        <DataDescription component='dd'>Total {metricName}</DataDescription>
      </HeadingNameValue>
      {_.orderBy(data, 'value', 'desc').map((d) => (
        <NameValueWrapper
          key={`${d.label}`}
          percentage={(d.value * 100) / maxValue}
        >
          <DataTerm component='dt' variant='smallRegular'>
            {isCurrency && currencyCode}{' '}
            {_.round(d.value, roundTo).toLocaleString()}
          </DataTerm>
          <DataDescription
            component='dd'
            variant='smallRegular'
            color='text.grey'
          >
            {d.label}
          </DataDescription>
        </NameValueWrapper>
      ))}
    </Datalist>
  );
};

export { MapLegend };

