import styled from '@emotion/styled';
import { Select } from '@mui/material';

const StyledWrapper = styled.div`
  .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 0px solid rgba(0, 0, 0, 0.42);
  }
  .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 0px solid #323c46;
  }
  .MuiInput-root:before {
    border-bottom: 0px solid rgba(0, 0, 0, 0.42);
  }
  .MuiInput-root:after {
    border-bottom: 0px solid #0096ff;
  }
  .MuiSelect-icon {
    margin-right: 0px;
  }
  &.MuiMenuItem-root {
    font-size: 0.85rem;
  }
  margin-bottom: 12px;
  ul {
    li {
      font-size: 0.85rem;
    }
  }
`;

const StyledSelect = styled(Select)`
  border: 1px solid #e6e6e6;
  width: 100%;
  font-size: 0.85rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiSelect-select {
    padding: 0.5rem 1rem !important;
    height: 20px !important;
  }
  .MuiSelect-icon {
    background-color: #d7e0e7;
    border-radius: 20px;
    width: 18px;
    height: 18px;
    margin-top: 2px;
  }
  .MuiInput-input:focus {
    background-color: rgba(0, 0, 0, 0);
  }
  &.MuiMenuItem-root {
    font-size: 0.85rem !important;
  }
  ul {
    li {
      font-size: 0.85rem;
    }
  }
`;

export { StyledWrapper, StyledSelect };
