import { Box, Typography } from '@mui/material';
import React from 'react';
import _ from 'lodash';
import { AdContainer, AdWrapper, DataList, NameValuePair } from './styles';

const DataCell = ({ label, value }) => (
  <NameValuePair
    sx={{
      display: 'flex',
      flex: '1 0 34%',
      flexDirection: 'row-reverse',
      padding: 1,
      gap: 1,
      borderRight: '1px solid border.main',
    }}
  >
    <Typography sx={{ flex: '1 0 33%', textAlign: 'end' }} component='dt'>
      {value}
    </Typography>
    <Typography sx={{ color: 'text.grey', flex: '1 0 33%' }} component='dd'>
      {label}:
    </Typography>
  </NameValuePair>
);

const defaultFilter = {
  name: 'All',
  value: 'ALL',
};

const AdInsightsMeta = ({
  data,
  filter = defaultFilter,
  currencyCode = 'ZZZ',
}) => {
  const filteredAds =
    filter.value === 'ALL'
      ? data
      : data.filter((ad) => ad.filter === filter.value);

  return (
    <>
      {filteredAds.map((d) => (
        <Box key={d.name} marginBottom={4}>
          <Box marginBottom={2}>
            <Typography variant='bodySemiBold'>Campaign: </Typography>
            <Typography variant='body'>{d.name}</Typography>
          </Box>
          <AdContainer>
            {d.ads.map((ad) => (
              <AdWrapper key={ad.Ad_Id}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 2,
                    padding: '1rem 1.5rem',
                  }}
                >
                  <img
                    src={ad.image_url}
                    alt={ad.Ad_Name}
                    height='64'
                    width='64'
                  />
                  <Typography variant='bodySemiBold' component='h3'>
                    {_.startCase(ad.Ad_Name)}
                  </Typography>
                </Box>
                <DataList>
                  <DataCell
                    label='Reach'
                    value={Number(ad.Reach).toLocaleString()}
                  />
                  <DataCell
                    label='Cost'
                    value={`${currencyCode} ${ad.Spend.toLocaleString()}`}
                  />
                  <DataCell
                    label='Link Clicks'
                    value={ad.Inline_Link_Clicks.toLocaleString()}
                  />
                  <DataCell label='Conversion' value={ad.Conversions} />
                  <DataCell label='CTR' value={_.round(ad.Ctr, 2)} />
                  <DataCell label='ROAS' value={ad.ROAS} />
                </DataList>
              </AdWrapper>
            ))}
            {d.ads.length === 1 ? (
              <Box sx={{ flex: '1 0 34%', minWidth: '350px' }} />
            ) : null}
          </AdContainer>
        </Box>
      ))}
    </>
  );
};

export { AdInsightsMeta };
