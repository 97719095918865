import React, { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  Box,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { CustomSelect } from '../MapperTable/CustomSelect';
import { QualityScoreCalc } from './QualityScoreCalc';
import { UserInfoContext } from '../../../../../context/UserInfoContext';

const headCells = [
  {
    id: 'channelField',
    numeric: false,
    labelformatFunction: () => (
      <Typography variant='smallBold'>Channel field name</Typography>
    ),
    disablePadding: false,
    alignRight: false,
    formatFunction: (row) => row.name,
  },
  {
    id: 'internalField',
    labelformatFunction: () => (
      <Typography variant='smallBold'>Internal field</Typography>
    ),
  },
  {
    id: 'priority',
    labelformatFunction: () => (
      <Typography variant='smallBold'>Priority</Typography>
    ),
  },
  { id: 'mapStatus', labelformatFunction: () => '' },
  { id: 'infoTooltip', labelformatFunction: () => '' },
];

const PriorityType = ({ priority }) => {
  if (priority === 0)
    return <Typography variant='smallRegular'> Mandatory </Typography>;

  if (priority === 1)
    return <Typography variant='smallRegular'> Recommended </Typography>;

  if (priority === 2)
    return <Typography variant='smallRegular'> Optional </Typography>;
  return null;
};

const ChannelFieldWithColor = ({ name, priority }) => {
  if (priority === 0)
    return (
      <Box
        sx={{
          '&::before': {
            content: "''",
            width: '10px',
            height: '100%',
            backgroundColor: '#0096ff',
            position: 'absolute',
            left: '0',
            top: '0',
            '@media screen and (max-width: 500px)': {
              backgroundColor: 'transparent',
            },
          },
        }}
      >
        {name}
      </Box>
    );

  if (priority === 1)
    return (
      <Box
        sx={{
          '&::before': {
            content: "''",
            width: '10px',
            height: '100%',
            backgroundColor: '#FF823C',
            position: 'absolute',
            left: '0',
            top: '0',
            '@media screen and (max-width: 500px)': {
              backgroundColor: 'transparent',
            },
          },
        }}
      >
        {name}
      </Box>
    );

  if (priority === 2)
    return (
      <Box
        sx={{
          '&::before': {
            content: "''",
            width: '10px',
            height: '100%',
            backgroundColor: '#dedede',
            position: 'absolute',
            left: '0',
            top: '0',
            '@media screen and (max-width: 500px)': {
              backgroundColor: 'transparent',
            },
          },
        }}
      >
        {name}
      </Box>
    );
  return null;
};

const MapperQualityTable = ({
  setTotalSum,
  channelFields,
  channelInfo,
  marketType,
  setTotalFields,
  setTotalChecks,
  setTotalOptional,
  setTotalRecommended,
  setTotalMandatory,
  setMandatoryFailed,
  setRecommendedFailed,
  setOptionalFailed,
  classNameInternalField,
  tooltipClass,
  setIsFormDirty,
  setPatchValue ,
  
}) => {
  const { values , dirty  } = useFormikContext();
  const { user } = useContext(UserInfoContext);
  useEffect(()=>{
    if(setIsFormDirty){
      setIsFormDirty(dirty)
    }
  },[dirty])
  useEffect(()=>{
    if(setPatchValue){
      setPatchValue(values)
    }
  },[values])
  useEffect(() => {
    if (channelInfo === 'GOOGLE_SHOPPING') {
      QualityScoreCalc.GoogleShopping(
        values,
        setTotalSum,
        setTotalFields,
        setTotalChecks,
        setTotalOptional,
        setTotalRecommended,
        setTotalMandatory,
        setMandatoryFailed,
        setRecommendedFailed,
        setOptionalFailed
      );
    }
    if (channelInfo === 'GOOGLE_DYNAMIC_SEARCH_ADS') {
      QualityScoreCalc.DSA(
        values,
        setTotalSum,
        setTotalFields,
        setTotalChecks,
        setTotalOptional,
        setTotalRecommended,
        setTotalMandatory,
        setMandatoryFailed,
        setRecommendedFailed,
        setOptionalFailed
      );
    }
    if (channelInfo === 'PINTEREST') {
      QualityScoreCalc.Pinterest(
        values,
        setTotalSum,
        setTotalFields,
        setTotalChecks,
        setTotalOptional,
        setTotalRecommended,
        setTotalMandatory,
        setMandatoryFailed,
        setRecommendedFailed,
        setOptionalFailed
      );
    }
    if (channelInfo === 'TIKTOK') {
      QualityScoreCalc.Tiktok(
        values,
        setTotalSum,
        setTotalFields,
        setTotalChecks,
        setTotalOptional,
        setTotalRecommended,
        setTotalMandatory,
        setMandatoryFailed,
        setRecommendedFailed,
        setOptionalFailed
      );
    }
    if (channelInfo === 'META_ADS') {
      QualityScoreCalc.MetaAds(
        values,
        setTotalSum,
        setTotalFields,
        setTotalChecks,
        setTotalOptional,
        setTotalRecommended,
        setTotalMandatory,
        setMandatoryFailed,
        setRecommendedFailed,
        setOptionalFailed,
        marketType
      );
    }
    if (channelInfo === 'BING') {
      QualityScoreCalc.Bing(
        values,
        setTotalSum,
        setTotalFields,
        setTotalChecks,
        setTotalOptional,
        setTotalRecommended,
        setTotalMandatory,
        setMandatoryFailed,
        setRecommendedFailed,
        setOptionalFailed,
        marketType
      );
    }
  }, [values, channelInfo]);

  useEffect(() => {
    channelFields?.sort((a, b) => a.priority - b.priority);
    channelFields?.sort((a, b) => {
      if (a.priority === b.priority) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    });
  }, []);

  const optionFormatter = (field) => {
    if (!field.pkid) {
      return (
        <MenuItem key={field.pkid} value={field.pkid}>
          <em>{field.name}</em>
        </MenuItem>
      );
    }

    return (
      <MenuItem key={field.pkid} value={field.pkid}>
        {field.name}
      </MenuItem>
    );
  };

  return (
    <Table stickyHeader aria-labelledby='tableTitle' size='small'>
      <TableHead>
        <TableRow className='noVerticalBorders'>
          {headCells.map((headCell, index) => {
            const uniqueKey = `${headCell.id}_${index}`;
            if (index !== 3) {
              return (
                <TableCell
                  key={uniqueKey}
                  align={index === 0 ? 'left' : 'left'}
                >
                  {headCell.labelformatFunction()}
                </TableCell>
              );
            }
            return (
              <TableCell key={uniqueKey} align='left'>
                <Typography variant='smallBold' sx={{ whiteSpace: 'nowrap' }}>
                  {headCell.label}
                </Typography>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>

      <TableBody>
        {values.channelFields.map((row, index) => (
          <TableRow
            hover
            tabIndex={-1}
            key={row.pkid}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}
            className='noVerticalBorders'
          >
            <>
              <TableCell component='th' scope='row' align='left'>
                <Box
                  sx={{
                    paddingLeft: '15px',
                  }}
                >
                  <ChannelFieldWithColor
                    name={row.value}
                    priority={row.priority}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <CustomSelect
                  name={`channelFields[${index}].mapped_to`}
                  options={[
                    { pkid: '', name: '--' },
                    ...values.internalFields,
                  ].sort((a, b) => (a.name > b.name ? 1 : -1))}
                  formatter={optionFormatter}
                  className={
                    channelFields[index]?.mapped_to == null
                      ? classNameInternalField
                      : ''
                  }
                  val={!values.channelFields[index].mapped_to}
                  disabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
                />
              </TableCell>
              <TableCell>
                <PriorityType priority={row.priority} />
              </TableCell>

              <TableCell>
                {values.channelFields[index].mapped_to ? null : (
                  <Typography sx={{ color: '#E13F2A' }} variant='smallRegular'>
                    Not Mapped
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                {values.channelFields[index].mapped_to ? (
                  <Tooltip
                    title='The internal field is correctly mapped to channel field'
                    arrow
                    PopperProps={{
                      sx: {
                        '&.MuiTooltip-popper ': {
                          zIndex: '999999999',
                        },
                      },
                    }}
                  >
                    <HelpOutlineOutlinedIcon
                      fontSize='small'
                      color='disabled'
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      <>
                        This field is either unmapped or doesnot exist.
                        <br />
                        Try adding this field by going to <br /> Imports &gt;
                        Edit Mapping &gt; Add New Field.
                        <br />
                        <br />
                        Try Enhance &gt; Action &gt; Add a new Action or Import
                        if the error still persists.
                      </>
                    }
                    arrow
                    PopperProps={{
                      sx: {
                        '&.MuiTooltip-popper ': {
                          zIndex: '999999999',
                        },
                      },
                    }}
                  >
                    <HelpOutlineOutlinedIcon
                      fontSize='small'
                      sx={{ color: '#E13F2A' }}
                      className={tooltipClass}
                    />
                  </Tooltip>
                )}
              </TableCell>
            </>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export { MapperQualityTable };
