import React from 'react';
import { Routes, Route } from 'react-router-dom';

// import internal Components
import { AllProjects } from '../PageViewComponents/AllProjects';
import { ImportedFileSummary } from '../PageViewComponents/ImportedFileSummary';
import { EditMapping } from '../PageViewComponents/EditMapping';
import { EnhancePage } from '../PageViewComponents/EnhanceMainList';
import { EnhanceItems } from '../PageViewComponents/EnhanceItems';
import { FeedsSummary } from '../PageViewComponents/FeedsSummary';
import { FeedSetting } from '../PageViewComponents/FeedSetting';
import { ProjectSettings } from '../PageViewComponents/ProjectSettings';
import { AllItemsList } from '../PageViewComponents/AllItems';
import { FeedsPreview } from '../PageViewComponents/FeedsPreview';
import { FeedsQuality } from '../PageViewComponents/FeedsQuality';
import { Schedule } from '../PageViewComponents/Schedule';
import { CreateAction } from '../PageViewComponents/CreateAction';
import { CreateFilter } from '../PageViewComponents/CreateFilter';
import { EditFilter } from '../PageViewComponents/EditFilter';
import { EditAction } from '../PageViewComponents/EditAction';
import { AddFile } from '../PageViewComponents/AddFile';
import { UpdateFile } from '../PageViewComponents/UpdateFile';
import { PageErrors } from '../../../components/PageErrors';
import { AppBar } from '../Components/GlobalComponents/Appbar';
import { AffectedItemsList } from '../PageViewComponents/AffectedItemsList';

// main function component
const FeedManagementMain = () => (
  <div>
    <AppBar />
    <Routes>
      <Route path='' element={<AllProjects />} />
      <Route path='imports'>
        <Route path='add' element={<AddFile />} />
        <Route path='import-settings' element={<AddFile />} />
        <Route path='update' element={<UpdateFile />} />
        <Route path='edit-mapping/:fileId' element={<EditMapping />} />
        <Route path='all-items' element={<AllItemsList />} />
        <Route path='all-items/item-details' element={<EnhanceItems />} />
        <Route index element={<ImportedFileSummary />} />
      </Route>
      <Route
        path='feeds/enhance/affected-items'
        element={<AffectedItemsList />}
      />
      <Route
      path='feeds/enhance/filtered-items'
      element={<AffectedItemsList />}
     />
     <Route
     path='feeds/enhance/remaining-items'
     element={<AffectedItemsList />}
    />
      <Route path='feeds/enhance/item-details' element={<EnhanceItems />} />
      <Route path='/feeds/enhance/affected-items/item-details' element={<EnhanceItems />} />
      <Route path='/feeds/enhance/filtered-items/item-details' element={<EnhanceItems />} />
      <Route path='/feeds/enhance/remaining-items/item-details' element={<EnhanceItems />} />
      <Route path='feeds/enhance/new-action' element={<CreateAction />} />
      <Route path='feeds/enhance/new-filter' element={<CreateFilter />} />
      <Route path='feeds/enhance/filter/:id' element={<EditFilter />} />
      <Route path='feeds/enhance/action/:id' element={<EditAction />} />
      <Route path='feeds/*' element={<FeedsSummary />} />
      <Route path='feeds/feed-settings' element={<FeedSetting />} />
      <Route path='feeds/enhance' element={<EnhancePage />} />
      <Route path='feeds/quality' element={<FeedsQuality />} />
      <Route path='feeds/preview' element={<FeedsPreview />} />
      <Route path='feeds/preview/item-details' element={<EnhanceItems />} />
      <Route path='settings' element={<ProjectSettings />} />
      <Route path='settings/schedule' element={<Schedule />} />
      <Route path='feed-list' element={<FeedsSummary />} />
      <Route path='*' element={<PageErrors code={404} />} />
    </Routes>
  </div>
);
export { FeedManagementMain };
