import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import { theme } from '../../../../../Maintheme';

const StyledBox = styled(Box)`
  position: relative !important;
  margin-bottom: 16px;
  .doughnutGraph {
    position: relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .centerText {
    position: absolute;
    width: 40%;
    height: 40%;
    border-radius: 100%;
    background-color: transparent;
    color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

// export default StyledBox;

const StyledDiv = styled(Box)`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin: 8px 12px 8px 0px;
`;

const StyledPaper = styled(Paper)`
  .doughnutResult {
    font-size: 1.1rem;
    font-weight: 600;
    color: #323c46;
  }
  .editButton {
    color: ${theme.palette.text.primary};
    background-color: #e5e2e2;
    font-size: 14px;
    color: #323c46;
    font-weight: 400;
    border-radius: 32px;
    padding: 5px 16px;
    &:hover {
      background-color: #cfcfcf;
    }
  }
  .editButtonIcon {
    margin-left: 6px;
    color: #000;
  }
  .downloadEditButton {
    margin-top: 16px;
  }
`;

export { StyledBox, StyledDiv, StyledPaper };
