import { Add, Delete, DeleteOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Typography,
} from '@mui/material';
import { Field, FieldArray, useField } from 'formik';
import React from 'react';
import { TextAndSelectInput } from './TextAndSelectInput';
import { SelectInput } from '../../../../../../components/Inputs/SelectInput';
import { FlexSelectInput, FlexTextInput } from './FlexInputs';

const ConditionalWrapper = ({
  dependeeFileName,
  conditionCallback,
  children,
}) => {
  const [field] = useField(dependeeFileName);
  if (conditionCallback(field.value)) return children;
  return null;
};

const TwoFieldConditionalWrapper = ({
  firstFieldPath,
  secondFieldPath,
  conditionCallback,
  children,
}) => {
  const [{ value: firstValue }] = useField(firstFieldPath);
  const [{ value: secondValue }] = useField(secondFieldPath);

  if (conditionCallback(firstValue, secondValue)) return children;
  return null;
};

const CheckboxInput = ({ name, label, sx }) => (
  <FormControlLabel
    fullWidth
    control={
      <Field
        as={Checkbox}
        type='checkbox'
        name={name}
        sx={{ backgroundColor: '#FFF', ...sx }}
      >
        {label}
      </Field>
    }
    label={label}
  />
);

const TextOrSelect = ({
  name,
  label,
  dependeeFileName,
  isText = false,
  options = [],
  optionValueCallback,
  optionContentCallback,
}) => {
  const [field] = useField(dependeeFileName);

  if (isText(field.value)) {
    return (
      <FlexTextInput
        name={name}
        label={label}
        placeholder='Enter a number'
        id={name}
        hideLabel
      />
    );
  }
  return (
    <FlexSelectInput
      name={name}
      optionValueCallback={optionValueCallback}
      optionContentCallback={optionContentCallback}
      options={options}
      label={label}
      id={name}
      labelIsAdjacent
      hideLabel
    />
  );
};

const DynamicSelectField = ({ name, options, valueCallback, textCallback }) => {
  const [field] = useField(name);
  if (field.value) {
    return (
      <FormGroup
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
          '& .MuiInputLabel-root': {
            fontSize: '0.875rem',
            fontWeight: 400,
            marginBottom: 0,
          },
        }}
      >
        <InputLabel>
          <Typography variant=''>combine</Typography>
        </InputLabel>
        <FieldArray name={name}>
          {({ push, pop }) => (
            <>
              {field.value.map((_, index) => (
                <SelectInput
                  name={`${name}[${index}]`}
                  optionValueCallback={valueCallback}
                  optionContentCallback={textCallback}
                  options={options}
                  hideLabel={index === 0}
                  label={index === 0 ? 'combine fields' : 'and'}
                  id={name}
                  labelIsAdjacent
                  sx={{
                    width: '100%',
                    '@media(min-width:800px)': {
                      width: 'auto',
                      '& .MuiInputBase-root.MuiOutlinedInput-root': {
                        flex: 'initial',
                        width: '300px',
                        maxWidth: '100%',
                      },
                    },
                  }}
                />
              ))}
              <Box>
                <Button
                  onClick={() => push(options[0])}
                  startIcon={<Add />}
                  variant='outlined'
                  sx={{
                    height: '45px',
                    border: '1px solid',
                    borderColor: 'border.main',
                    color: 'text.grey',
                    background: '#fff',
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }}
                >
                  Add field
                </Button>
                {field.value.length > 0 && (
                  <IconButton
                    onClick={() => pop()}
                    sx={{
                      ml: 2,
                      borderRadius: 1,
                      height: '45px',
                      border: '1px solid',
                      borderColor: 'border.main',
                      background: '#fff',
                      color: 'text.grey',
                      '&:hover': {
                        borderColor: 'primary.main',
                        color: 'primary.main',
                      },
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                )}
              </Box>
            </>
          )}
        </FieldArray>
      </FormGroup>
    );
  }
  return null;
};

const DynamicTextorSelectInput = ({ name, options, sx }) => {
  const [field] = useField(name);
  if (Array.isArray(field.value)) {
    return (
      field.value && (
        <FieldArray name={name}>
          {({ push, pop }) => (
            <Box sx={{ display: 'flex', ...sx }}>
              {field.value.map((_, index) => (
                <TextAndSelectInput
                  name={`${name}[${index}]`}
                  options={options}
                  sx={{ flex: 1 }}
                />
              ))}
              <IconButton onClick={() => push('')}>
                <Add />
              </IconButton>
              <IconButton
                onClick={() => pop()}
                disabled={field.value.length < 2}
              >
                <Delete />
              </IconButton>
            </Box>
          )}
        </FieldArray>
      )
    );
  }

  return null;
};

export {
  CheckboxInput,
  TextOrSelect,
  DynamicTextorSelectInput,
  DynamicSelectField,
  ConditionalWrapper,
  TwoFieldConditionalWrapper,
};
