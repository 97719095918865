import * as React from 'react';
import {
  Backdrop,
  Modal,
  Fade,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { useEffect } from 'react';
import { CloseButton } from '../../widgets/CloseButton';
import { StyledGlobalModal } from '../Modal/styles';

const elementPicker = (
  isLoading,
  isSuccess,
  isError,
  successMessage,
  errorMessage,
  loadingComponent,
  isCustom = false
) => {
  switch (true) {
    case isLoading:
      return isCustom ? loadingComponent : <CircularProgress />;
    case isSuccess:
      return (
        <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
          <CheckCircle
            sx={{
              color: 'primary.main',
            }}
          />
          <Typography variant='bigSemiBold'>{successMessage}</Typography>
        </Box>
      );
    case isError:
      return (
        <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
          <Cancel
            sx={{
              color: 'error.main',
            }}
          />
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='bigSemiBold'>{errorMessage}</Typography>
            <Typography variant='bigSemiBold' sx={{ color: 'error.main' }}>
              Please Try Again
            </Typography>
          </Box>
        </Box>
      );
    default:
      return null;
  }
};

// updated version of DataSendingModalBase that is compatible with RTK's hooks
// accepts the isError, isSuccess and isLoading booleans which RTK's hooks provide
const DataSendingModalBaseRTK = ({
  open,
  sx,
  handleCloseModal,
  isLoading,
  isError,
  isSuccess,
  children,
  successMessage,
  errorMessage,
  loadingComponent,
  isCustom,
  reset = () => {},
}) => {
  // closes the modal and resets the request state on success
  // the reset function is called 0.5s after the handleCloseModal() to make sure the initial ui is not shown while the modal is closing
  useEffect(() => {
    const closeTimeout =
      isSuccess &&
      setTimeout(() => {
        handleCloseModal();
      }, 2000);
    const resetTimeout =
      isSuccess &&
      setTimeout(() => {
        reset();
      }, 2000);

    return () => {
      clearTimeout(closeTimeout);
      clearTimeout(resetTimeout);
    };
  }, [isSuccess, reset]);

  return (
    <Modal
      open={open}
      onClose={() => {
        if (!isLoading) {
          handleCloseModal();
          setTimeout(() => {
            reset();
          }, 200);
        }
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <StyledGlobalModal sx={sx}>
          <Backdrop
            open={isLoading || isSuccess || isError}
            sx={{ zIndex: 99999, borderRadius: 3, backgroundColor: '#FFF' }}
          >
            {elementPicker(
              isLoading,
              isSuccess,
              isError,
              successMessage,
              errorMessage,
              loadingComponent,
              isCustom
            )}
          </Backdrop>
          {children}
          <CloseButton onClick={handleCloseModal} />
        </StyledGlobalModal>
      </Fade>
    </Modal>
  );
};

export { DataSendingModalBaseRTK };