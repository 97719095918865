import { useReducer, useEffect } from 'react';

// initialData : object containing the initial state
// dataFetchingFunction: function. MUST NOT BE DEFINED INSIDE A REACT COMPONENT. DOING THIS WILL CAUSE AN INFINITE LOOP.
// argument: any. OBJECT or LISTS MUST BE MEMOIZED WITH CORRECT DEPENDENCIES. THIS WILL DECIDE WHEN THE DATA FETCHING FUNCTION IS RUN
const useFetch = (
  initialData,
  dataFetchingFunction,
  argument,
  dependencies = '',
  executeFetch = true
) => {
  const dataFetchReducer = (stateArg, action) => {
    switch (action.type) {
      case 'PREFETCH':
        return {
          ...stateArg,
          isLoading: true,
          isError: false,
          isPrefetch: true,
        };
      case 'FETCH_INIT':
        return {
          ...stateArg,
          isLoading: true,
          isError: false,
          isPrefetch: false,
        };
      case 'FETCH_SUCCESS':
        return {
          ...stateArg,
          isLoading: false,
          isError: false,
          isPrefetch: false,
          data: action.payload,
        };
      case 'FETCH_FAILURE':
        return {
          ...stateArg,
          isLoading: false,
          isError: true,
          isPrefetch: false,
          errorInfo: action.payload,
        };
      case 'UPDATE_STATE':
        return action.payload;
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: true,
    isError: false,
    errorInfo: null,
    isPrefetch: true,
    ...initialData,
  });

  useEffect(() => {
    dispatch({ type: 'PREFETCH' });
    let didCancel = false;
    const controller = new AbortController();
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      if (dataFetchingFunction) {
        dataFetchingFunction(argument, controller.signal)
          .then((result) => {
            if (!didCancel) {
              if (result.status === 204) {
                dispatch({
                  type: 'FETCH_FAILURE',
                  payload: {
                    code: 204,
                    message: result.data,
                  },
                });
              } else {
                dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
              }
            }
          })
          .catch((error) => {
            if (!didCancel) {
              dispatch({
                type: 'FETCH_FAILURE',
                payload: {
                  code: error?.response?.status,
                  message: error?.response?.data.error,
                },
              });
            }
          });
      } else {
        dispatch({
          type: 'FETCH_FAILURE',
          payload: {
            code: null,
            message: 'dataFetchingFunction is undefined',
          },
        });
      }
    };

    // Can be used to run dataFetcher after any particular condition is met
    if (executeFetch) {
      fetchData();
    }
    /* 
      this cleanup function is called when component unmounts.
      we set didCancel to true here and prevent state changes from being made
    */
    return () => {
      didCancel = true;
      controller.abort();
    };
  }, [dataFetchingFunction, argument, dependencies, executeFetch]);
  const updateState = (newData) => {
    dispatch({ type: 'UPDATE_STATE', payload: newData });
  };

  return [state, updateState];
};

export { useFetch };
