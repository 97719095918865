import React from 'react';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Typography, Button } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { dataPreprocess } from './DataUtils';

const ExportExcel = ({
  sheetNames,
  columnList,
  filename,
  dataToWrite,
  dateRange = {},
  title = '',
}) => {
  const excelExport = (data) => {
    const ExcelWorkbook = new ExcelJS.Workbook();
    const preProcessedData = dataPreprocess(data);
    const noOfSheets = preProcessedData.length;

    for (let i = 0; i < noOfSheets && i < sheetNames.length; i += 1) {
      const worksheet = ExcelWorkbook.addWorksheet(sheetNames[i]);
      const columns = columnList;
      worksheet.mergeCells('A1:E1');
      worksheet.getCell('A1').value = `${title}`;
      worksheet.getCell('A1').alignment = { horizontal: 'center' };
      worksheet.getCell('A1').font = { bold: true };
      worksheet.getCell('A1').border = {
        left: { style: 'thick' },
        right: { style: 'thick' },
        top: { style: 'thick' },
        bottom: { style: 'thick' },
      };

      if (!_.isEmpty(dateRange)) {
        const startDate = moment(dateRange[0].startDate).format('MMMM D, YYYY');
        const endDate = moment(dateRange[0].endDate).format('MMMM D, YYYY');

        worksheet.mergeCells('A2:E2');

        // timeStampRow.font = {bold: true};
        worksheet.getCell('A2').value = `Date Range: ${startDate} - ${endDate}`;
        worksheet.getCell('A2').alignment = { horizontal: 'center' };
        worksheet.getCell('A2').font = { bold: true };
        worksheet.getCell('A2').border = {
          left: { style: 'thick' },
          right: { style: 'thick' },
          top: { style: 'thick' },
          bottom: { style: 'thick' },
        };
        // timeStampCell.value = "Date Range:" + ' ' + startDate + " - " + endDate;
      }

      const spareRow = worksheet.addRow();
      const spareColumn = spareRow.getCell(1);
      spareColumn.value = '';

      const headerRow = worksheet.addRow();

      headerRow.font = { bold: true };

      if (columns) {
        for (let colNo = 1; colNo <= columns.length; colNo += 1) {
          const cell = headerRow.getCell(colNo);
          cell.value = columns[colNo - 1];
          cell.border = {
            left: { style: 'thin' },
            right: { style: 'thin' },
            top: { style: 'thin' },
            bottom: { style: 'thin' },
          };
        }

        if (preProcessedData[i] && Array.isArray(preProcessedData[i])) {
          preProcessedData[i].map((d) => {
            const row = worksheet.addRow();
            Object.entries(d).map((value, key) => {
              const cell = row.getCell(key + 1);
              [, cell.value] = value;
              cell.border = {
                right: { style: 'thin' },
              };
              return 0;
            });
            return 0;
          });
        } else {
          preProcessedData.map((d) => {
            const row = worksheet.addRow();
            Object.entries(d).map((value, key) => {
              const cell = row.getCell(key + 1);
              [, cell.value] = value;
              return 0;
            });
            return 0;
          });
          // Object.entries(data).map((sheetValue, key) => {

          // });
        }
      }
    }
    ExcelWorkbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], {
          type: 'application/octet-stream',
        }),
        `${filename}.xlsx`
      );
    });
  };

  return (
    <Button
      variant='contained'
      onClick={() => excelExport(dataToWrite)}
      sx={{ width: 'auto' }}
    >
      <Typography variant='p' className='button-text'>
        Download
      </Typography>
      <SaveAltIcon
        sx={{
          ml: 1,
          fontSize: '1.1rem',
        }}
      />
    </Button>
  );
};

export { ExportExcel };
