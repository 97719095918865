import React, { useContext, useState } from 'react';


// MUI imports
import { CircularProgress, Container } from '@mui/material';

// Internal imports
import { useSelector } from 'react-redux';
import { useParams} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { EditMappingTable } from '../Components/OtherComponents/EditMappingTable';
import { ToggleButtonImport } from '../Components/OtherComponents/ToggleButtonImport';
// Styled imports
import { StyledPaper } from './StyledMainBox';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { useGetMappingQuery , useUpdateMappingMutation} from '../../../api/RTK/services/feedMapping';
import { PageErrors } from '../../../components/PageErrors';

// Main function component
const EditMapping = () => {
  const [{ isLoading: updateLoading }] =
    useUpdateMappingMutation();
  const [toggled, setToggled] = useState('Edit Mapping');
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );
  const { fileId } = useParams();
  const { company } = useContext(UserInfoContext);

  const { data, isLoading, isError, error } = useGetMappingQuery(
    {
      companyId: company?.id,
      projectId: projectSelectId,
      fileId,
    },
    {
      skip: !company || !fileId,
    }
  );

  return (
    <FetchingDisplay
      isLoading={isLoading || updateLoading}
      isError={isError}
      LoadingElement={<GlobalLoadingComponent />}
      ErrorElement={
        <PageErrors
          isServerError
          code={error?.originalStatus || error?.status}
        />
      }
      SuccessElement={
        <ErrorBoundary fallback={<PageErrors />}>
          <Container maxWidth='xxl'>
            {data ? (
              <>
                <TitleCard
                  title='Editing Mapping'
                  subTitle='Only selected fields will be imported'
                >
                  <ToggleButtonImport
                    toggled={toggled}
                    setToggled={setToggled}
                    fileId={fileId}
                  />
                </TitleCard>
                <StyledPaper>
                  <EditMappingTable
                    projectFields={data.project_fields}
                    importedFields={data.imported_fields}
                  />
                </StyledPaper>
              </>
            ) : (
              <CircularProgress />
            )}
          </Container>
        </ErrorBoundary>
      }
    />
  );
};

export { EditMapping };
