import styled from '@emotion/styled';

const StyledError = styled.div`
  .error404Nav {
    padding: 20px 0px;
  }
  .contentBox {
    margin-top: 80px;
    text-align: center;
    a {
      color: #666666;
      text-decoration: underline;
    }
  }
  .lightText {
    color: #666666;
    font-weight: 200;
    margin: 10px 0 10px 0;
    font-size: 14px;
    text-align: center;
  }
  .quote {
    width: 730px;
    margin: auto;
  }
  .innerCon {
    padding: 40px 32px;
    display: flex;
    flex-direction: column;
    justify-content: normal;
    align-items: self-end;
    h1 {
      color: #323c46;
      font-weight: bold;
      font-size: 32px;
    }
    p {
      color: #323c46;
      font-weight: 300;
      font-style: italic;
      font-size: 24px;
    }
  }
  @media only screen and (max-width: 991px) {
    h1 {
      font-size: 28px !important;
    }
    .quote {
      width: auto;
      .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
        display: flex;
        flex-direction: column;
        width: 610px;
        margin: auto;
      }
    }

    .inQuote {
      p {
        font-size: 20px !important;
        margin: 10px 10px !important;
      }
      h1 {
        margin: 0px !important;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .innerCon {
      align-items: center;
    }
    h1 {
      font-size: 27px !important;
    }
    .quote {
      .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
        width: auto;
      }
      .inQuote {
        p {
          text-align: center !important;
        }
      }
    }
  }

  @media only screen and (max-width: 375px) {
    h1 {
      font-size: 22px !important;
    }
    .lightText {
      p {
        font-size: 12px !important;
      }
    }
    .quote {
      .inQuote {
        p {
          font-size: 16px !important;
        }
      }
    }
  }
`;

export { StyledError };
