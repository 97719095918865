/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Typography, Box, Button } from '@mui/material';
import { CircularProgressWithLabel } from '../../pages/FeedManagement/Components/OtherComponents/FeedListCard';
import { WooComUserBrowseModal } from '../../pages/ProjectManagement/Pages/AccountList/components/LinkAccountSpeedDial/utils/WooComUserBrowseModal';

const ProgressLoader = ({ loading, success }) => {
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    const timer = setInterval(() => {
      const interval = 100 / 300;
      setProgress((prevProgress) =>
        success ? 100 : (prevProgress >= 93 ? 93 : prevProgress + interval)
      );
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [loading, success]);

  return (
    <>
      <Box p={2}>
        <CircularProgressWithLabel value={progress} />
        <Typography variant='h3'>
          Your updated data is being downloaded, this might take couple of
          minutes
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 2,
            px: 2,
          }}
        >
          <Typography variant='smallMedium'>
            In the meantime, feel free to delve into our in-built action and
            their uses.
          </Typography>
          <Button
            variant='text'
            sx={{ fontSize: '16px' }}
            onClick={handleClick}
          >
            <Typography variant='smallBold'>Start exploring now!</Typography>
          </Button>
        </Box>
      </Box>
      <WooComUserBrowseModal open={open} handleCloseModal={handleCloseModal} />
    </>
  );
};

export { ProgressLoader };
