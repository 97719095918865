/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ProjectModal } from '../../../../Components/Modals/ProjectModal';
import { EditCompanyModal } from '../../../../Components/Modals/EditCompany';

const CardMenu = ({ name, id, color, reloadFunction }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const mountedRef = useRef(true);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const openEditCompanyModal = () => {
    setAnchorEl(null);
    setIsEditModalOpen(true);
    setOpen(false);
  };

  const openDeleteModal = () => {
    setAnchorEl(null);
    setIsDeleteModalOpen(true);
    setOpen(false);
  };
  useEffect(() => {
    // Set the mountedRef to true when the component mounts
    mountedRef.current = true;
    return () => {
      // Set the mountedRef to false when the component unmounts
      mountedRef.current = false;
    };
  }, []);
  const handleCloseModal = () => {
    if (!mountedRef.current) return;
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip>
        <IconButton
          onClick={handleClick}
          size='small'
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          disableRipple
          sx={{ padding: 0 }}
        >
          <MoreVertIcon
            fontSize='small'
            sx={{
              '&:hover': { color: (theme) => theme.palette.primary.main },
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{ p: 1 }} onClick={openEditCompanyModal}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='smallRegular'> Edit </Typography>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ p: 1 }} onClick={() => openDeleteModal()}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='smallRegular'> Delete </Typography>
        </MenuItem>
        <Divider />
      </Menu>
      <EditCompanyModal
        open={isEditModalOpen}
        handleCloseModal={handleCloseModal}
        name={name}
        id={id}
        color={color}
        reloadFunction={reloadFunction}
      />
      <ProjectModal
        open={isDeleteModalOpen}
        handleCloseModal={handleCloseModal}
        name={name}
        id={id}
        isCompany
        reloadFunction={reloadFunction}
        successMessage='Company Deleted'
        errorMessage='Company Not Deleted'
        functionName='delete'
        prompt='Are you sure you want to Delete?'
        confirmButtonText='Delete'
        showCancelButton
        cancelButtonText='Cancel'
      />
    </>
  );
};

export { CardMenu };
