import { Box } from '@mui/material';
import { styled } from '@mui/system';

const ConversionBox = styled(Box)(
  ({ theme }) => `
  flex: 2 0 0;
  text-align: start;
  display: grid;
  place-content: center;
  background-color: ${theme.palette.background.grey};
`
);

const bgColorPicker = (theme, index) => {
  switch (index) {
    case 0:
      return theme.palette.primary.lighter;
    case 1:
      return theme.palette.primary.light;
    case 2:
      return theme.palette.primary.main;
    default:
      return theme.palette.primary.lighter;
  }
};

const StatBox = styled(ConversionBox)(
  ({ placetop, theme, index }) => `
  text-align: center;
  flex: 3 0 0;
  ${placetop ? ' align-content: start;' : ''}
  @media(min-width: 600px) {
    align-content: center;
  }
  background-color: ${bgColorPicker(theme, index)}
`
);
export { ConversionBox, StatBox };
