import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { CompanyManagementBg } from '../../../../../../constants/endpoints/assests/artWork';

const StyledBox = styled(Box)(
  () => `
         height: 85vh;
         background-image:url(${CompanyManagementBg});
         background-repeat: no-repeat;
         background-position: top;
         @media (max-width:600px){
            background-image:none;
          }
        `
);

const StyledBoxSec = styled(Box)(
  () => `
           width:100%;
           height:85vh;
           display:flex;
           justify-content:center;
           align-items:center;
           backdrop-filter: blur(5px) brightness(0.5);
          `
);

const StyledLinkYourAccount = styled(Box)(
  ({ theme }) => `
      background-color:${theme.palette.background.main};
      border-radius: 12px;
      max-width: 500px;
      min-width: 400px;
      padding:${theme.spacing(3)};
      box-shadow: ${theme.shadows[2]};
      @media (max-width:600px){
        max-width: 400px;
        min-width: 320px;
      }
      @media (max-width:400px){
        width:100%;
      }
    `
);

export { StyledBox, StyledBoxSec, StyledLinkYourAccount };
