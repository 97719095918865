import React from 'react';

import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { BarGraph } from '../../../../../components/BarGraph';

const dataFormatter = (data, filter) => ({
  labels: data.map((d) => d['Age Group']),
  datasets: [
    {
      label: [filter.name],
      data: data.map((d) => d[filter.name]),
      backgroundColor: '#0096FF',
      borderSkipped: 'bottom',
      hoverBorderColor: '#fff',
      borderRadius: { topLeft: 4, topRight: 4 },
      dataLabels: {
        color: 'white',
        font: {
          weight: 'bold',
        },
      },
    },
  ],
});

const MetricWithCurrency = [
  'All Conversion value',
  'Average Cost',
  'Conversion value',
  'Cost',
  'Cost per All Conversions',
  'Cost per Conversions values',
  'All Conversion values (by Conversion time)',
];

const yTitleGenerator = (filter, currencyCode) => {
  if (MetricWithCurrency.includes(filter.value)) {
    return `${[filter.name]} ${currencyCode}`;
  }
  return [filter.name];
};

const UserByAgeChart = ({ data, filter, currencyCode = 'ZZZ' }) => {
  Chart.register(ChartDataLabels);
  const newData = data?.filter((key) => key['Age Group'] !== 'Total');
  return (
    <BarGraph
      data={dataFormatter(newData, filter)}
      yTitle={yTitleGenerator(filter, currencyCode)}
      xTitle='Age'
      currencyCode={currencyCode}
      isCurrency={MetricWithCurrency.includes(filter.value)}
      metricName={filter.name}
    />
  );
};

export { UserByAgeChart };
