/* eslint-disable camelcase */
import { data } from './data';

const demoGoogleService = ({ databox_type }) => {
  const response = { status: 200, data: null };
  switch (databox_type) {
    case 'ADS_INSIGHTS':
      response.data = data.adInsights.google;
      break;
    case 'PMAX_COMPAINGNS':
      response.data = data.pmaxCampaingns.google;
      break;
    case 'AVERAGE_COST':
      response.data = data.averageMarketingCost.google;
      break;
    case 'MONTH_ON_MONTH':
      response.data = data.monthlyInsights.google;
      break;
    case 'YEARLY_INSIGHTS':
      response.data = data.yearlyInsights.google;
      break;
    case 'STATS':
      response.data = data.quickStats.google;
      break;
    case 'SHOPPING_PRODUCTS_INSIGHTS':
      response.data = data.shoppingProductsInsights.google;
      break;
    case 'AGE':
      response.data = data.userByAge.google;
      break;
    case 'DEVICE':
      response.data = data.userByDevice.google;
      break;
    case 'GENDER':
      response.data = data.userByGender.google;
      break;
    case 'TOP_PRODUCTS':
      response.data = data.topProducts;
      break;
    case 'USER_LOCATION':
      response.data = data.userByLocation.google;
      break;

    case 'ALL':
      response.data = {
        averageCost: data.averageMarketingCost.google,
        quickStats: data.quickStats.google,
        shoppingProductsInsights: data.shoppingProductsInsights.google,
        adInsights: data.adInsights.google,
        gender: data.userByGender.google,
        age: data.userByAge.google,
        device: data.userByDevice.google,
        location: data.userByLocation.google,
        // convert array of objects to single object
        monthOnMonth: data.monthlyInsights.google.reduce(
          (prev, current) => ({
            ...prev,
            ...Object.keys(current).reduce(
              (prev2, current2) => ({
                ...prev2,
                [current2]: current[current2],
              }),
              {}
            ),
          }),
          {}
        ),
      };
      break;
    default:
      response.status = 400;
      response.data = null;
      response.message = 'Incorrect databox_type';
  }
  return new Promise((resolve, reject) => {
    if (response.status === 200) {
      resolve(response);
    } else {
      reject(response);
    }
  });
};

const demoMetaService = ({ databox_type }) => {
  const response = { status: 200, data: null };
  switch (databox_type) {
    case 'ADS_INSIGHTS':
      response.data = data.adInsights.meta;
      break;
    case 'AVERAGE_COST':
      response.data = data.averageMarketingCost.meta;
      break;
    case 'CONVERSION_FUNNEL':
      response.data = data.conversionFunnel;
      break;
    case 'CAMPAIGN_SUMMARY':
      response.data = data.campaignSummary;
      break;
    case 'MONTH_ON_MONTH':
      response.data = data.monthlyInsights.meta;
      break;
    case 'YEARLY_INSIGHTS':
      response.data = data.yearlyInsights.meta;
      break;
    case 'STATS':
      response.data = data.quickStats.meta;
      break;
    case 'SHOPPING_PRODUCTS_INSIGHTS':
      response.data = data.shoppingProductsInsights.meta;
      break;
    case 'AGE':
      response.data = data.userByAge.meta;
      break;
    case 'DEVICE':
      response.data = data.userByDevice.meta;
      break;
    case 'GENDER':
      response.data = data.userByGender.meta;
      break;
    case 'USER_LOCATION':
      response.data = data.userByLocation.meta;
      break;
    case 'ALL':
      response.data = {
        averageCost: data.averageMarketingCost.meta,
        quickStats: data.quickStats.meta,
        adInsights: data.adInsights.meta,
        conversionFunnel: data.conversionFunnel.meta,
        campaignSummary: data.campaignSummary,
        gender: data.userByGender.meta,
        age: data.userByAge.meta,
        device: data.userByDevice.meta,
        location: data.userByLocation.meta,
        // convert array of objects to single object
        monthOnMonth: data.monthlyInsights.meta.reduce(
          (prev, current) => ({
            ...prev,
            ...Object.keys(current).reduce(
              (prev2, current2) => ({
                ...prev2,
                [current2]: current[current2],
              }),
              {}
            ),
          }),
          {}
        ),
      };
      break;
    default:
      response.status = 400;
      response.data = null;
      response.message = 'Incorrect databox_type';
  }
  return new Promise((resolve, reject) => {
    if (response.status === 200) {
      resolve(response);
    } else {
      reject(response);
    }
  });
};

const getDemoDashboardService = (platform) =>
  platform === 'meta' ? demoMetaService : demoGoogleService;
export { getDemoDashboardService, demoGoogleService, demoMetaService };
