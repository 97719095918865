/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  AdminIcon,
  OwnerIcon,
  CollaboratorIcon,
} from '../../../../widgets/PersonSvgIcon/Admin';
import { StyledBox } from '../utils';

const IconDecider = ({ row, fill }) => (
  <StyledBox>
    {['OWNER'].includes(row.role) ? (
      <OwnerIcon fill={fill} />
    ) : ['ADMIN', 'SUB_ADMIN'].includes(row.role) ? (
      <AdminIcon fill={fill} />
    ) : (
      <CollaboratorIcon fill={fill} />
    )}
  </StyledBox>
);

export { IconDecider };
