import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';


// mui imports
import { Box, Button, Container } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DeleteOutline } from '@mui/icons-material';

// internal component imports
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { useDataFetcher } from '../../../Hooks/useDataFetcher';
import { feedManagementService } from '../../../services/feedManagement';
import { ActionForm } from './Forms/Action';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { PageErrors } from '../../../components/PageErrors';
import { useCreateActionMutation } from '../../../api/RTK/services/feedFeed';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { DataSendingModalBaseRTK } from '../../../components/DataSendingModalBaseRTK';
import { AffectedItemsButton } from '../Components/GlobalComponents/AffectedItemButton';
import { clearActionSlice } from '../Redux/AddActionFilter/EditActionSlice';

// styled component
import { StyledPaper } from './StyledMainBox';

const CreateAction = () => {
  const { company } = useContext(UserInfoContext);
  const navigate = useNavigate();
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );
  const feedId = useSelector((state) => state.feedSummaryRedux.feedId);

  const memoizedArgs = useMemo(
    () => ({ companyId: company.id, feedProjectId: projectSelectId, feedId }),
    []
  );
  const [state] = useDataFetcher(
    {},
    feedManagementService.getProjectFields,
    memoizedArgs,
    '',
    !!company && !!projectSelectId && !!feedId
  );

  const [createAction, { isLoading, isError, isSuccess, reset, error }] =
    useCreateActionMutation();

  const errorHandleAction = () => {
    if (error && error.status === 403) {
      if (error?.data[0]) {
        return error?.data[0]?.error || 'Same name action exists in other feeds';
      }
    }
    return 'Unable to create action';
  };

  const [shouldExecuteQuery, setShouldExecuteQuery] = useState(false);
  const dispatch = useDispatch()
  const handleSubmit = (values) => {
    createAction({
      projectId: projectSelectId,
      companyId: company.id,
      feedId,
      data: values,
    }).then((response) => {
      const actionId = response?.data[0]?.id;
      setShouldExecuteQuery(true);
      setTimeout(() => {
        navigate(`../feeds/enhance/action/${actionId}`);
      }, 2000);
    });
    dispatch(clearActionSlice({}))
  };

  // useEffect to reset shouldExecuteQuery to false when component unmounts
  useEffect(() => () => setShouldExecuteQuery(false), []);

  return (
    <FetchingDisplay
      isLoading={state.isLoading}
      isError={state.isError}
      SuccessElement={
        <ErrorBoundary fallback={<PageErrors />}>
          <Container maxWidth='none'>
            <TitleCard title='New Action'>
              <AffectedItemsButton
                to={{ pathname: '/feeds/enhance/affected-items', state: {} }}
                sx={{ marginRight: '24px' }}
                disabled={!shouldExecuteQuery}
                label={
                  <RefreshIcon
                    color='inherit'
                    sx={{
                      zIndex: 2,
                      width: '20px !important',
                      height: '20px !important',
                      marginTop: '8px',
                    }}
                  />
                }
                buttonName='View Affected Items'
              />
              <Button
                variant='pillOutlined'
                sx={{
                  height: '44px',
                  minWidth: '150px',
                  '&:hover': {
                    svg: { color: '#e13f2a !important' },
                    color: '#e13f2a',
                    border: '1px solid #e13f2a',
                  },
                }}
                startIcon={<DeleteOutline />}
                disabled={!shouldExecuteQuery}
              >
                Delete Action
              </Button>
            </TitleCard>
            <StyledPaper sx={{ padding: '24px 0px' }}>
              <ActionForm
                fields={state.data?.map((field) => field.name) || []}
                handleSubmit={handleSubmit}
                btnName='Save'
              />
            </StyledPaper>
          </Container>
          <DataSendingModalBaseRTK
            open={isLoading || isSuccess || isError}
            handleCloseModal={reset}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            successMessage='Action created successfully'
            errorMessage={errorHandleAction()}
            reset={reset}
          >
            <Box sx={{ height: '200px' }} />
          </DataSendingModalBaseRTK>
        </ErrorBoundary>
      }
      LoadingElement={<GlobalLoadingComponent />}
      ErrorElement={<PageErrors isServerError code={state?.errorInfo?.code} />}
    />
  );
};

export { CreateAction };
