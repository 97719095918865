import React, { useMemo } from 'react';

import { Databox } from '../../../../../../components/Databox';
import { Loading } from '../../../../../../components/Databox/Loading';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { TopProducts } from '../../../../Databoxes/TopProducts';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';

import { getDemoDashboardService } from '../../../../../../services/DemoDashboard';

const TopProductsContainer = ({ platform }) => {
  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'TOP_PRODUCTS',
    }),
    []
  );

  const [state] = useDataFetcher(
    [],
    getDemoDashboardService(platform),
    memoizedArgs
  );

  return (
    <Databox>
      <DataboxHeading
        title='Top Products Insights'
        info='Know the most successful products of your business.'
      />
      <FetchingDisplay
        isError={state.isError}
        isLoading={state.isLoading}
        LoadingElement={<Loading />}
        ErrorElement={<ErrorDisplay />}
        SuccessElement={<TopProducts data={state.data} />}
      />
    </Databox>
  );
};

export { TopProductsContainer };

