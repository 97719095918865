// import React, { useEffect, useRef, useState } from 'react';
// import {
//   MapContainer,
//   TileLayer,
//   useMap,
//   CircleMarker,
//   Tooltip,
// } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import { Box, Typography } from '@mui/material';
// import _ from 'lodash';

// const ResizeMap = ({ renderCount }) => {
//   const map = useMap();

//   useEffect(() => {
//     map.invalidateSize();
//   }, [renderCount]);
//   return null;
// };

// const generateBounds = (latitudeArray, logitudeArray) => {
//   // top left and bottom right points
//   const maxLatitude = Math.max(...latitudeArray);
//   const minLogitude = Math.min(...logitudeArray);

//   const minLatitude = Math.min(...latitudeArray);
//   const maxLogitude = Math.max(...logitudeArray);

//   return [
//     [maxLatitude, minLogitude],
//     [minLatitude, maxLogitude],
//   ];
// };

// /*
// data: Array of Objects with the following skeleton
// {
//   value: String or Number,
//   label: String. Displayed in Tooltip,
//   latitude: String co-ordinate,
//   longitude: String co-ordinate,
// }

// isCurrency: String
// currencyCode: String
// idField: String
// */

// const Map = ({
//   data,
//   isCurrency,
//   currencyCode = 'ZZZ',
//   idField,
//   metricName,
// }) => {
//   const [renderCount, setRenderCount] = useState(0);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     const resizeObserver = new ResizeObserver(() => {
//       setRenderCount((currentRenderCount) => currentRenderCount + 1);
//     });

//     resizeObserver.observe(mapRef.current);

//     return () => {
//       if (mapRef.current) {
//         resizeObserver.unobserve(mapRef.current);
//       }
//     };
//   }, []);

//   // constants for radius logic
//   const radiusSegments = 20;
//   const maxObj = _.maxBy(data, 'value');
//   const minObj = _.minBy(data, 'value');
//   const maxMinusMinValue = maxObj.value - minObj.value;
//   const metricsSegmentSize = _.divide(maxMinusMinValue, radiusSegments);

//   const roundTo =
//     metricName.toLowerCase() === 'roas' || metricName.toLowerCase() === 'cpc'
//       ? 1
//       : 0;

//   return (
//     <Box width='100%' height='100%' ref={mapRef}>
//       <MapContainer
//         attributionControl={false}
//         style={{ height: '100%', width: '100%' }}
//         bounds={generateBounds(
//           data.map((d) => d.latitude),
//           data.map((d) => d.longitude)
//         )}
//         scrollWheelZoom={false}
//       >
//         <ResizeMap renderCount={renderCount} />
//         <TileLayer url='https://api.mapbox.com/styles/v1/adhelp/cl5s6jxaq000114r1vce0iqum/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWRoZWxwIiwiYSI6ImNsNXM2MWs2eTAxNjkzZXBzZXNxMWNsOXYifQ.2N_IziFNg2UPG7tWVlQL3Q' />
//         {mapRef.current
//           ? data.map((d) => (
//               <CircleMarker
//                 center={[d.latitude, d.longitude]}
//                 radius={
//                   _.divide(d.value - minObj.value, metricsSegmentSize) + 5
//                 }
//                 fillOpacity={0.5}
//                 stroke={false}
//                 color='rgb(255, 130, 60)'
//                 key={d[idField]}
//               >
//                 <Tooltip>
//                   <Typography variant='smallBold'>{d.label}</Typography>
//                   <br />
//                   {isCurrency ? `${currencyCode} ` : null}
//                   {_.round(d.value, roundTo).toLocaleString()}
//                 </Tooltip>
//               </CircleMarker>
//             ))
//           : null}
//       </MapContainer>
//     </Box>
//   );
// };

// export { Map };

import React, { useEffect, useRef, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  useMap,
  CircleMarker,
  Tooltip,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';

const ResizeMap = ({ renderCount }) => {
  const map = useMap();

  useEffect(() => {
    map.invalidateSize();
  }, [renderCount]);

  return null;
};

const generateBounds = (latitudeArray, logitudeArray) => {
  const maxLatitude = Math.max(...latitudeArray);
  const minLogitude = Math.min(...logitudeArray);

  const minLatitude = Math.min(...latitudeArray);
  const maxLogitude = Math.max(...logitudeArray);

  return [
    [maxLatitude, minLogitude],
    [minLatitude, maxLogitude],
  ];
};

const Map = ({
  data,
  isCurrency,
  currencyCode = 'ZZZ',
  idField,
  metricName,
}) => {
  const [renderCount, setRenderCount] = useState(0);
  const mapRef = useRef(null);
  const isMounted = useRef(true);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (isMounted.current) {
        setRenderCount((currentRenderCount) => currentRenderCount + 1);
      }
    });

    resizeObserver.observe(mapRef.current);

    return () => {
      isMounted.current = false;
      if (mapRef.current) {
        resizeObserver.unobserve(mapRef.current);
      }
    };
  }, []);

  const radiusSegments = 20;
  const maxObj = _.maxBy(data, 'value');
  const minObj = _.minBy(data, 'value');
  const maxMinusMinValue = maxObj.value - minObj.value;
  const metricsSegmentSize = _.divide(maxMinusMinValue, radiusSegments);

  const roundTo =
    metricName.toLowerCase() === 'roas' || metricName.toLowerCase() === 'cpc'
      ? 1
      : 0;

  return (
    <Box width='100%' height='100%' ref={mapRef}>
      <MapContainer
        attributionControl={false}
        style={{ height: '100%', width: '100%' }}
        bounds={generateBounds(
          data.map((d) => d.latitude),
          data.map((d) => d.longitude)
        )}
        scrollWheelZoom={false}
      >
        <ResizeMap renderCount={renderCount} />
        <TileLayer url='https://api.mapbox.com/styles/v1/adhelp/cl5s6jxaq000114r1vce0iqum/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWRoZWxwIiwiYSI6ImNsNXM2MWs2eTAxNjkzZXBzZXNxMWNsOXYifQ.2N_IziFNg2UPG7tWVlQL3Q' />
        {mapRef.current
          ? data.map((d) => (
              <CircleMarker
                center={[d.latitude, d.longitude]}
                radius={
                  _.divide(d.value - minObj.value, metricsSegmentSize) + 5
                }
                fillOpacity={0.5}
                stroke={false}
                color='rgb(255, 130, 60)'
                key={d[idField]}
              >
                <Tooltip>
                  <Typography variant='smallBold'>{d.label}</Typography>
                  <br />
                  {isCurrency ? `${currencyCode} ` : null}
                  {_.round(d.value, roundTo).toLocaleString()}
                </Tooltip>
              </CircleMarker>
            ))
          : null}
      </MapContainer>
    </Box>
  );
};

export { Map };
