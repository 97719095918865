import { api } from '../api';

const metricAndDimensionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllMetrics: builder.query({
      query: ({ accountId }) => ({
        url: `/accounts/custom-metrics/${accountId}`,
      }),
      providesTags: ['METRIC'],
    }),
    getAllDimensions: builder.query({
      query: ({ accountId }) => ({
        url: `/accounts/custom-dimensions/${accountId}`,
      }),
      providesTags: ['DIMENSION'],
    }),
    viewMetric: builder.query({
      query: ({ metricId }) => ({
        url: `/accounts/custom-metrics-detail/${metricId}`,
      }),
      invalidatesTags: ['METRIC'],
    }),
    viewDimension: builder.query({
      query: ({ dimensionId }) => ({
        url: `/accounts/custom-dimensions-detail/${dimensionId}`,
      }),
      invalidatesTags: ['DIMENSION'],
    }),
    deleteMetric: builder.mutation({
      query: ({ metricId }) => ({
        url: `/accounts/custom-metrics/`,
        method: 'DELETE',
        body: { metric_id: metricId },
      }),
      invalidatesTags: ['METRIC'],
    }),
    deleteDimension: builder.mutation({
      query: ({ dimensionId }) => ({
        url: `/accounts/custom-dimensions/`,
        method: 'DELETE',
        body: { dimension_id: dimensionId },
      }),
      invalidatesTags: ['DIMENSION'],
    }),
    createMetric: builder.mutation({
      query: ({ accountId, formData }) => ({
        url: `/accounts/custom-metrics/`,
        method: 'POST',
        body: {
          account_id: accountId,
          ...formData,
        },
      }),
      invalidatesTags: ['METRIC'],
    }),
    createDimension: builder.mutation({
      query: ({ accountId, formData }) => ({
        url: `/accounts/custom-dimensions/`,
        method: 'POST',
        body: {
          account_id: accountId,
          ...formData,
        },
      }),
      invalidatesTags: ['DIMENSION'],
    }),
    editMetric: builder.mutation({
      query: ({ metricId, formData }) => ({
        url: `/accounts/custom-metrics/`,
        method: 'PATCH',
        body: {
          metric_id: metricId,
          ...formData,
        },
      }),
      invalidatesTags: ['METRIC'],
    }),
    editDimension: builder.mutation({
      query: ({ dimensionId, formData }) => ({
        url: `/accounts/custom-dimensions/`,
        method: 'PATCH',
        body: {
          dimension_id: dimensionId,
          name: formData.name,
        },
      }),
      invalidatesTags: ['DIMENSION'],
    }),
    customizedColumnPatch: builder.mutation({
      query: ({ id, data }) => ({
        url: `/users/custom-columns`,
        method: 'PATCH',
        body: {
          id,
          ...data,
        },
      }),
      invalidatesTags: [],
    }),
    customizedColumnGet: builder.query({
      query: ({ accountId, databoxType }) => ({
        url: `/users/custom-columns/${accountId}/?databox_type=${databoxType}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  // metric
  useGetAllMetricsQuery,
  useViewMetricQuery,
  useCreateMetricMutation,
  useEditMetricMutation,
  useDeleteMetricMutation,
  useCustomizedColumnGetQuery,
  useCustomizedColumnPatchMutation,
  // dimension
  useGetAllDimensionsQuery,
  useViewDimensionQuery,
  useCreateDimensionMutation,
  useEditDimensionMutation,
  useDeleteDimensionMutation,
} = metricAndDimensionApi;
