import { api } from '../api';

const feedFileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllFiles: builder.query({
      query: ({ companyId, projectId }) =>
        `/feed-management/imports/${companyId}/${projectId}`,
      providesTags: (result, query, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'FILES', id })),
              { type: 'FILES', id: `LIST-PROJECT-${arg.projectId}` },
            ]
          : [{ type: 'FILES', id: `LIST-PROJECT-${arg.projectId}` }],
    }),

    getFileDetails: builder.query({
      query: ({ companyId, projectId, fileId }) => ({
        url: `/feed-management/imports/${companyId}/${projectId}`,
        params: {
          file_id: fileId,
        },
      }),
      providesTags: (result, query, arg) => [
        { type: 'FILES', id: result?.id },
        { type: 'FILES', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),

    addFile: builder.mutation({
      query: ({ companyId, projectId, data }) => ({
        url: '/feed-management/imports',
        method: 'POST',
        body: {
          company_id: companyId,
          feed_project_id: projectId,
          ...data,
        },
      }),
      invalidatesTags: (value, query, arg) => [
        { type: 'FILES', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'PROJECTS', id: arg.projectId },
      ],
    }),

    updateFile: builder.mutation({
      query: ({ fileId, companyId, projectId, data }) => ({
        url: `/feed-management/imports/${companyId}/${projectId}`,
        method: 'PATCH',
        body: {
          file_id: fileId,
          ...data,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FILES', id: result?.id },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),
    deleteFile: builder.mutation({
      query: ({ fileId, companyId, projectId }) => ({
        url: `/feed-management/imports/${companyId}/${projectId}`,
        method: 'DELETE',
        body: {
          file_id: fileId,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FILES', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'FILES', id: arg.fileId },
        { type: 'MAPPINGS', id: arg.fileId },
      ],
    }),
    activateFile: builder.mutation({
      query: ({ fileId, companyId, projectId }) => ({
        url: `/feed-management/imports/${companyId}/${projectId}`,
        method: 'PATCH',
        body: {
          file_id: fileId,
          active: true,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FILES', id: result?.id },
        { type: 'FILES', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),
    deactivateFile: builder.mutation({
      query: ({ fileId, companyId, projectId }) => ({
        url: `/feed-management/imports/${companyId}/${projectId}`,
        method: 'PATCH',
        body: {
          file_id: fileId,
          active: false,
        },
      }),
      invalidatesTags: (result, query, arg) => [
        { type: 'FILES', id: result?.id },
        { type: 'FILES', id: `LIST-PROJECT-${arg.projectId}` },
        { type: 'ITEMS', id: `LIST-PROJECT-${arg.projectId}` },
      ],
    }),
  }),
});

export const {
  useGetAllFilesQuery,
  useGetFileDetailsQuery,
  useAddFileMutation,
  useUpdateFileMutation,
  useDeleteFileMutation,
  useActivateFileMutation,
  useDeactivateFileMutation,
} = feedFileApi;
