/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useContext, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box } from '@mui/material';

import { CustomizeButton } from '../../../../../../widgets/CustomizeButton';
import { YearlyInsightsChart } from '../../../../Databoxes/YearlyInsights/Chart';

import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { Filter } from '../../../../../../components/Filter';
import { Loading } from '../../../../../../components/Databox/Loading';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';

import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { getDashboardDataService } from '../../../../../../utils/getDashboardDataService';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';
import { CustomizedColumnModal } from '../../../../../MetricAndDimension/DashboardDatabox/CustomizeColumn';
import { ErrorComponent } from '../../../../../../components/GlobalErrorComponent';

const YearlyInsightsContainer = ({ accountId, platform }) => {
  const { account } = useContext(UserInfoContext);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [filterListForPlatform, setFilterListForPlatform] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState();

  const memoizedArgs = useMemo(
    () => ({
      account_id: accountId,
      platform,
      databox_type: 'YEARLY_INSIGHTS',
    }),
    [platform, accountId]
  );
  const [state] = useDataFetcher(
    [],
    getDashboardDataService(platform),
    memoizedArgs,
    count
  );

  const onCustomizeClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    const prevYear = new Date().getFullYear() - 1;
    const originalData = state?.data?.[1]?.[prevYear]?.[0] || [];

    const MainfilterList = Object.keys(originalData).map((key) => ({
      name: key,
      value: key,
    }));
    if (MainfilterList.length > 1) {
      MainfilterList.shift();
    }
    setFilterListForPlatform(MainfilterList);
    if (MainfilterList.length > 0) {
      setSelectedFilter(MainfilterList[0]);
    }
  }, [state?.data]);

  const handleFilterChange = (newFilter) => {
    setSelectedFilter(newFilter);
  };

  return (
    <Databox>
      <ErrorBoundary
        fallback={
          <ErrorComponent
            title='Yearly Insights'
            info='Know your yearly account performance, using the analytical filters. This will help evaluate your yearly expense more efficiently.'
          />
        }
      >
        <DataboxHeading
          title='Yearly Insights'
          info='Know your yearly account performance, using the analytical filters. This will help evaluate your yearly expense more efficiently.'
        >
          <Box sx={{ display: 'flex' }}>
            <CustomizeButton
              onCustomizeClick={onCustomizeClick}
              account={account}
            />
            {open && (
              <CustomizedColumnModal
                open={open}
                setOpen={setOpen}
                databoxType='yearly_insights'
                accountId={accountId}
                count={count}
                setCount={setCount}
              />
            )}
            {selectedFilter ? (
              <Filter
                label='Filter'
                selectedFilter={selectedFilter}
                filterList={filterListForPlatform || []}
                handleFilterChange={handleFilterChange}
              />
            ) : null}
          </Box>
        </DataboxHeading>
        <FetchingDisplay
          isLoading={state.isLoading}
          isError={state.isError}
          LoadingElement={<Loading />}
          ErrorElement={
            <ErrorDisplay
              refresh={() => {
                setCount(count + 1);
              }}
              message={
                state.errorInfo?.code === 204
                  ? 'No data available for selected date range.'
                  : state.errorInfo?.code === 400
                  ? "Couldn't load data"
                  : 'Something went wrong'
              }
              showRefreshButton={
                !(
                  state.errorInfo?.code === 400 || state.errorInfo?.code === 204
                )
              }
            />
          }
          SuccessElement={
            <Box padding={2} flexGrow={1} minHeight={0}>
              <YearlyInsightsChart
                data={state.data}
                filter={selectedFilter || []}
                currencyCode={account.currency_code}
              />
            </Box>
          }
        />
      </ErrorBoundary>
    </Databox>
  );
};

export { YearlyInsightsContainer };
