/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useRef } from 'react';

import { Divider, Stack, Typography, TextField, Popper } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { StyledMenuItem, CustomAutocomplete } from './styles';

const MenuItemChild = ({ option }) => {
  const Icon = option.icon;
  return (
    <Stack alignItems='center' direction='row' className='itemChild'>
      {Icon ? <Icon className='menuItemSvg' /> : null}
      <Typography className='accountName' variant='button'>
        {option.name}
      </Typography>
    </Stack>
  );
};
/*
object skeleton for options and selected
{
id: string,
icon: ReactComponent svg,
name: string
}

name: string. Unique. Used as id substring for the button and menu.
options: array of objects.
selected: object.
handleChange: callback. This changes the 'selected' state in the calling component.
sx: sx styles. Applied to the trigger 'Button' component
defaultText: string. This is displayed if selected is not provided
*/

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const PillMenu = ({
  name,
  options,
  selected,
  handleChange,
  sx,
  defaultText,
  showPulse = false,
}) => {
  const [open, setOpen] = useState(false);
  const [showIcon, setShowIcon] = useState(!!selected?.icon);
  const [inputValue, setInputValue] = useState('');
  const autocompleteRef = useRef();

  const debouncedHandleSelect = debounce((newValue) => {
    handleChange(newValue);
    setOpen(false);
  }, 300);

  const handleSelect = (event, newValue) => {
    if (newValue) {
      debouncedHandleSelect(newValue);
      setShowIcon(!!newValue.icon);
    } else {
      setShowIcon(false);
    }
    setInputValue('');
  };
  const handleClose = () => {
    setOpen(false);
    setShowIcon(!!selected?.icon);
    if (!inputValue && !selected) {
      setShowIcon(false);
    }
  };

  return (
    <CustomAutocomplete
      key={name}
      hasvalue={selected?.id.toString()}
      showPulse={showPulse}
      sx={{
        background: '#ffffff !important',
        borderRadius: '50px',
        width: '100%',
        ...sx,
      }}
      ref={autocompleteRef}
      options={options}
      getOptionLabel={(option) => option.name}
      onChange={handleSelect}
      value={selected}
      inputValue={inputValue}
      onInputChange={(e, value) => setInputValue(value)}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      PopperComponent={(popperProps) => (
        <Popper
          {...popperProps}
          anchorEl={autocompleteRef.current}
          sx={{ marginTop: '4px !important' }}
        />
      )}
      renderInput={(params) => (
        <TextField
          key={selected?.id}
          {...params}
          placeholder={defaultText}
          sx={{
            border: 'none !important',
            '& .MuiOutlinedInput-root': {
              border: 'none !important',
            },
            borderRadius: '50px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, .08)',
            ...sx,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {showIcon && selected?.icon && (
                  <selected.icon
                    style={{
                      marginRight: '8px',
                      width: '25px',
                      height: '25px',
                      fontSize: '20px',
                      color: '#b0b0b0',
                    }}
                  />
                )}
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              <>
                {params.InputProps.endAdornment?.props.children?.[0]}
                <KeyboardArrowDown
                  onClick={() =>
                    params.InputProps.endAdornment.props.children[1]?.props.onClick()
                  }
                  size='small '
                  sx={{
                    cursor: 'pointer !important',
                    color: '#b0b0b0 !important',
                    fontSize: '20px !important',
                  }}
                />
              </>
            ),
          }}
        />
      )}
      componentsProps={{
        clearIndicator: {
          sx: {
            '& svg': {
              fontSize: '14px !important',
            },
          },
        },
      }}
      renderOption={(props, option) => (
        <span key={option.id}>
          <StyledMenuItem
            {...props}
            key={option.id}
            onClick={() => handleSelect(null, option)}
            variant='platformItem'
          >
            <MenuItemChild option={option} />
          </StyledMenuItem>
          <Divider
            variant='middle'
            component='li'
            sx={{ paddingTop: '5px !important' }}
          />
        </span>
      )}
    />
  );
};

export { PillMenu };
