import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { setFilterData } from '../../../../Redux/AddActionFilter/EditFilterSlice';
import { SelectorHelpers } from './helpers';
import { MethodSelector } from './Fields/MethodSelector';
import { ValueSelector } from './Fields/ValueSelector';
import { SelectInput } from '../../../../../../components/Inputs/SelectInput';

const InputGroup = ({
  name,
  fields,
  insert,
  remove,
  index,
  insertChild,
  replace = null,
}) => (
  <Box
    sx={{
      display: 'grid',
      gridGap: '1rem',
      alignItems: 'center',
      '@media(min-width: 1400px)': {
        gridTemplateColumns: '5fr 1fr',
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'start',
        gap: 3,
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <SelectInput
        options={['all', ...fields]}
        name={`${name}.field`}
        optionValueCallback={(field) => field}
        optionContentCallback={(field) => field}
        label='field'
        placeholder='Select a field'
        id={`${name}-field`}
        hideLabel
        sx={{
          width: '100%',
          '@media(min-width:800px)': {
            width: 'auto',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              flex: 'initial',
              width: '300px',
              maxWidth: '100%',
            },
          },
        }}
      />
      <MethodSelector
        name={`${name}.filter`}
        valuePath={`${name}.value`}
        fieldName={`${name}.field`}
      />
      <ValueSelector
        name={`${name}.value`}
        methodName={`${name}.filter`}
        fields={fields}
      />
    </Box>
    <SelectorHelpers
      name={name}
      insertChild={insertChild}
      insert={insert}
      index={index}
      remove={remove}
      replace={replace}
    />
  </Box>
);

const Selectors = ({ fields, helperText = null, sx = null }) => {
  const dispatch = useDispatch();

  const {
    values: { queries },
  } = useFormikContext();

  const { values } = useFormikContext();

  useEffect(() => {
    dispatch(setFilterData(values));
  }, [values, dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        marginBottom: '-24px',
      }}
    >
      <FieldArray
        name='queries'
        render={({ insert, replace, remove }) =>
          queries.map((query, index) => (
            <Box>
              <Box
                sx={{
                  backgroundColor: '#f5f6fa',
                  padding: 2,
                  borderRadius: 1,
                }}
              >
                <Typography variant='bodyMedium' mb={1} display='block'>
                  {index === 0 ? 'If' : 'And'}
                </Typography>
                <InputGroup
                  name={`queries[${index}]`}
                  index={index}
                  fields={fields}
                  insert={insert}
                  remove={remove}
                  insertChild={null}
                  replace={replace}
                />
              </Box>
              {query.children.length > 0 && (
                <Box
                  sx={{
                    backgroundColor: '#f5f6fa',
                    marginLeft: 8,
                    padding: 2,
                    paddingTop: 0,
                    borderRadius: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <FieldArray
                    name={`queries[${index}].children`}
                    render={({ insert: insertChild, remove: removeChild }) =>
                      query.children.map((_, childIndex) => (
                        <>
                          <Typography
                            variant='bodyMedium'
                            sx={{ paddingBottom: '8px' }}
                          >
                            or
                          </Typography>
                          <InputGroup
                            name={`queries[${index}].children[${childIndex}]`}
                            index={childIndex}
                            fields={fields}
                            insert={insert}
                            remove={removeChild}
                            insertChild={insertChild}
                          />
                        </>
                      ))
                    }
                  />
                </Box>
              )}
            </Box>
          ))
        }
      />
      <Box sx={sx} className='selectorText'>
        <Typography variant='smallRegular' sx={{ color: 'text.grey' }}>
          {helperText}
        </Typography>
      </Box>
    </Box>
  );
};

export { Selectors };
