/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useContext, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box } from '@mui/material';
import { TableChartOutlined, PeopleAlt } from '@mui/icons-material';
import { CustomizeButton } from '../../../../../../widgets/CustomizeButton';
import { Filter } from '../../../../../../components/Filter';
import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { UserByAgeTable } from '../../../../Databoxes/UserByAge/Table';
import { UserByAgeChart } from '../../../../Databoxes/UserByAge/Chart';
import { CustomSwitch } from '../../../../../../components/CustomSwitch/CustomSwitch';
import { Loading } from '../../../../../../components/Databox/Loading';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { getDashboardDataService } from '../../../../../../utils/getDashboardDataService';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';
import { CustomizedColumnModal } from '../../../../../MetricAndDimension/DashboardDatabox/CustomizeColumn';
import { ErrorComponent } from '../../../../../../components/GlobalErrorComponent';

const DisplayComponentSelector = ({ showTable, chart, table }) => (
  <Box flexGrow={1} minHeight={0}>
    {showTable ? table : chart}
  </Box>
);

const UserByAgeContainer = ({ accountId, platform, dateRange }) => {
  const [open, setOpen] = useState(false);
  const { account } = useContext(UserInfoContext);
  const [count, setCount] = useState(0);
  const [filterListForPlatform, setFilterListForPlatform] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(); // Initialize selectedFilter without a default value

  const memoizedArgs = useMemo(
    () => ({
      account_id: accountId,
      date_range: dateRange,
      databox_type: 'AGE',
    }),
    [accountId, dateRange]
  );

  const [state] = useDataFetcher(
    [],
    getDashboardDataService(platform),
    memoizedArgs,
    count
  );

  const onCustomizeClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    const originalData = state.data || [];
    const firstItem = originalData.length > 0 ? originalData[0] : {};

    const MainfilterList = Object.keys(firstItem).map((key) => ({
      name: key,
      value: key,
    }));

    if (MainfilterList.length > 1) {
      MainfilterList.shift();
    }

    setFilterListForPlatform(MainfilterList);
    if (MainfilterList.length > 0) {
      setSelectedFilter(MainfilterList[0]);
    }
  }, [state.data]);

  const handleFilterChange = (newFilter) => {
    setSelectedFilter(newFilter);
  };

  const handleSwitchChange = () => {
    setShowTable(!showTable);
  };

  return (
    <Databox>
      <ErrorBoundary
        fallback={
          <ErrorComponent
            title='Users by Age'
            info='Know the users interested in your ads by age group. Click on the table icon to switch to a tabular format.'
          />
        }
      >
        <DataboxHeading
          title='Users by Age'
          info='Know the users interested in your ads by age group. Click on the table icon to switch to a tabular format.'
        >
          {!showTable ? (
            <Filter
              label='Filter'
              selectedFilter={selectedFilter}
              filterList={filterListForPlatform}
              handleFilterChange={handleFilterChange}
            />
          ) : null}
          {showTable ? (
            <>
              <CustomizeButton
                onCustomizeClick={onCustomizeClick}
                account={account}
              />
              {open && (
                <CustomizedColumnModal
                  open={open}
                  setOpen={setOpen}
                  databoxType='age'
                  accountId={accountId}
                  count={count}
                  setCount={setCount}
                />
              )}
            </>
          ) : null}
        </DataboxHeading>
        <FetchingDisplay
          isLoading={state.isLoading}
          isError={state.isError}
          LoadingElement={<Loading />}
          ErrorElement={
            <ErrorDisplay
              refresh={() => {
                setCount(count + 1);
              }}
              message={
                state.errorInfo?.code === 204
                  ? 'No data available for selected date range.'
                  : state.errorInfo?.code === 400
                  ? "Couldn't load data"
                  : 'Something went wrong'
              }
              showRefreshButton={
                !(
                  state.errorInfo?.code === 400 || state.errorInfo?.code === 204
                )
              }
            />
          }
          SuccessElement={
            <DisplayComponentSelector
              showTable={showTable}
              chart={
                selectedFilter ? (
                  <Box p={2} height='100%'>
                    <UserByAgeChart
                      data={state.data}
                      filter={selectedFilter}
                      currencyCode={account.currency_code}
                    />
                  </Box>
                ) : null
              }
              table={
                <UserByAgeTable
                  data={state.data}
                  platform={platform}
                  currencyCode={account.currency_code}
                />
              }
            />
          }
        />
        <Box sx={{ display: 'flex', justifyContent: 'end', padding: 2 }}>
          <CustomSwitch
            checked={showTable}
            handleChange={handleSwitchChange}
            CheckedIcon={TableChartOutlined}
            Icon={PeopleAlt}
          />
        </Box>
      </ErrorBoundary>
    </Databox>
  );
};

export { UserByAgeContainer };
