import React, { useState, useContext, useMemo } from 'react';

// MUI imports
import { Container } from '@mui/material';

// Internal imports
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { ToggleButtonSettings } from '../Components/OtherComponents/ToggleButtonSettings';
import { ProjectSettingsForm } from '../Components/OtherComponents/ProjectSettingsForm';

// Styled imports
import { StyledPaper } from './StyledMainBox';
import { UserInfoContext } from '../../../context/UserInfoContext';

import { useDataFetcher } from '../../../Hooks/useDataFetcher';
import { feedManagementService } from '../../../services/feedManagement';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { PageErrors } from '../../../components/PageErrors';
import { useGetProjectsFieldsQuery } from '../../../api/RTK/services/feedProject';

const ProjectSettings = () => {
  const [toggled, setToggled] = useState('Project');
  const { company } = useContext(UserInfoContext);
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  // get request
  const memoizedArgs = useMemo(
    () => ({
      company_id: company?.id,
      projectId: projectSelectId,
    }),
    [company?.id, projectSelectId]
  );

  const [state] = useDataFetcher(
    { isLoading: false, isError: false },
    feedManagementService.getProjectForm,
    memoizedArgs,
    '',
    !!company && !!projectSelectId
  );

  const { data, isLoading, isError } = useGetProjectsFieldsQuery(
    {
      companyId: company?.id,
      projectId: projectSelectId,
    },
    {
      skip: !company,
    }
  );

  return (
    <FetchingDisplay
      isError={state.isError || isError}
      isLoading={state.isLoading || isLoading}
      SuccessElement={
        <ErrorBoundary fallback={<PageErrors />}>
          <Container maxWidth='xxl'>
            <TitleCard title='Project Settings'>
              <ToggleButtonSettings toggled={toggled} setToggled={setToggled} />
            </TitleCard>
            <StyledPaper>
              <ProjectSettingsForm
                data={state.data}
                fields={data?.map((field) => ({
                  value: field.id,
                  title: field.name,
                }))}
                defaultField={{
                  title: 'id',
                  // get id of 'id' project field
                  value: data?.filter((field) => field.name === 'id')[0]?.id,
                }}
              />
            </StyledPaper>
          </Container>
        </ErrorBoundary>
      }
      ErrorElement={<PageErrors isServerError code={state?.errorInfo?.code} />}
      LoadingElement={<GlobalLoadingComponent />}
    />
  );
};

export { ProjectSettings };
