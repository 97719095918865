import React, { useContext, useState } from 'react';

// MUI imports
import { Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { UserInfoContext } from '../../../../context/UserInfoContext';

// removed modal and modal data setter logic from the menu
// this make sure that only one modal is created for the entire page instead of one per menu
// set the id and function for the modal using the menu options
const ImportedFileMenu = ({ id, url, active, handleOptionClick }) => {
  const { user } = useContext(UserInfoContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  // download functionality
  const handleDownload = () => {
    window.open(url, '_blank');
  };

  return (
    <>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        sx={{
          border: '1px solid #dedede',
          borderRadius: '4px',
          padding: '1px',
          maxHeight: '30px',
        }}
      >
        <MoreVertIcon
          sx={{
            '&:hover': { color: (theme) => theme.palette.primary.main },
          }}
        />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          // elevation: 0,
          style: {
            overflow: 'visible',
            boxShadow: '0px 2px 5px #dedede',
          },
        }}
      >
        <MenuItem onClick={handleDownload} key='download'>
          <ArrowDownwardIcon fontSize='small' sx={{ color: '#999999', paddingRight: '4px' }} />
          <Typography variant='smallRegular'> Download </Typography>
        </MenuItem>
        {(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') && (
          <>
            <Divider />
            {active === false ? (
              <MenuItem
                key='activate'
                onClick={() => handleOptionClick(id, 'activate')}
              >
                <CheckCircleOutlinedIcon fontSize='small' sx={{ color: '#999999', paddingRight: '4px' }} />
                <Typography variant='smallRegular'> Activate </Typography>
              </MenuItem>
            ) : (
              <MenuItem
                key='deactivate'
                onClick={() => handleOptionClick(id, 'deactivate')}
              >
                <HighlightOffOutlinedIcon fontSize='small' sx={{ color: '#999999', paddingRight: '4px' }} />
                <Typography variant='smallRegular'> Deactivate </Typography>
              </MenuItem>
            )}
            <Divider />
            <MenuItem onClick={() => handleOptionClick(id, 'delete')}>
              <DeleteOutlineIcon fontSize='small' sx={{ color: '#999999', paddingRight: '4px' }} />
              <Typography variant='smallRegular'> Delete </Typography>
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};

export { ImportedFileMenu };
