import axios from 'axios';

import { apiBaseUrl } from '../constants/api';

// use this instance if you don't want an accessToken to be passed with the request
export const openInstance = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true, // for CORS credentials
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
});
