import * as React from 'react';
import { Button, Box } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { HeadingWithUnderline } from '../../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { FormikField } from '../../../../../widgets/FormikForm/FormikField';
import { AccountsService } from '../../../../../services/accounts';
import { sendReducer } from '../../../../../reducers/sendReducer';
import { DataSendingModalBase } from '../../../../../components/DataSendingModalBase';

const Schema = Yup.object().shape({
  name: Yup.string()
    .required('Account name field is required')
    .min(2, 'Account name must be at least 2 characters')
    .max(100, 'Account name must not exceed 100 characters'),
});

const defaultState = { status: 'PRESEND' };

const EditAccountModal = ({
  open,
  // setClose,
  handleCloseModal,
  name = 'Name',
  id,
  reloadFunction,
}) => {
  const [state, dispatch] = React.useReducer(sendReducer, defaultState);

  const handleSubmit = (newName) => {
    dispatch({ type: 'SENDING' });
    AccountsService.renameAccount(newName, id)
      .then((response) => {
        dispatch({ type: 'SENDSUCCESS', data: response.data });
        reloadFunction();
        setTimeout(() => {
          handleCloseModal();
        }, 2000);
      })
      .catch((error) => {
        dispatch({ type: 'SENDERROR', error: error.data });
        setTimeout(() => {
          handleCloseModal();
          dispatch({ type: 'PRESEND' });
        }, 2000);
      });
  };

  return (
    <DataSendingModalBase
      open={open}
      handleCloseModal={handleCloseModal}
      status={state.status}
      successMessage='Saved'
      errorMessage='Not Saved'
    >
      <HeadingWithUnderline heading='Rename Account' />
      <Formik
        initialValues={{ name }}
        validationSchema={Schema}
        onSubmit={(values) => {
          handleSubmit(values.name);
        }}
      >
        {({
          errors,
          touched,
          values,
          isSubmitting,
          handleChange,
          handleBlur,
        }) => (
          <Form noValidate>
            <Box
              display='flex'
              flexDirection='column'
              sx={{ mb: 2, textAlign: 'left' }}
            >
              <FormikField
                name='name'
                label='Account Name'
                required
                type='text'
                placeholder='Enter Name'
                value={values.name}
                error={Boolean(errors.name) && touched.name}
                helperText={touched.name && errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                fullWidth
              />
            </Box>
            <Box
              sx={{ width: '100%' }}
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              gap={3}
            >
              <Button variant='outlined' onClick={handleCloseModal} fullWidth>
                Cancel
              </Button>
              <Button
                variant='contained'
                type='submit'
                disabled={state.status === 'SENDING'}
                fullWidth
              >
                {state.status === 'SENDING' ? 'Saving' : 'Save'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </DataSendingModalBase>
  );
};

export { EditAccountModal };
