import React from 'react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import {
  ClickAwayListener,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

const InfoTooltip = styled(({ className, title }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <ClickAwayListener
      onClickAway={handleTooltipClose}
      // onMouseEnter={handleTooltipClose}
    >
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        classes={{ popper: className }}
        title={<Typography variant='smallRegular'>{title}</Typography>}
      >
        <QuestionMarkIcon
          sx={{
            fontSize: '20px',
            color: 'text.grey',
            border: '.1rem solid #999999',
            borderRadius: '100%',
            zIndex: 99,
          }}
          onClick={handleTooltipOpen}
          onMouseEnter={handleTooltipOpen}
        />
      </Tooltip>
    </ClickAwayListener>
  );
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    border: 'none',
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2),
  },
}));

export { InfoTooltip };
