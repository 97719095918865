import styled from '@emotion/styled'; // Styled Components for styling
import {Box} from '@mui/material';
import Slider from '@mui/material/Slider';

const RangeSliderBox = styled(Box)`
  .spanStyle {
    color: grey;
    font-size: 14px;
    padding: 8px;
  }
  .tooltipBottom {
    background-color: transparent;
    border: 1px solid #d4d4d4;
    padding: 5px;
    color: #0096ff;
    font-size: 0.8rem;
    font-weight: 500;
    box-shadow: 0px 0px 5px #d4d4d4;
    border-radius: 5px;
  }
`;

const RangeSlider = styled(Slider)`
  position: relative;
  color: #0096ff;
  .css-1q1vknx-MuiSlider-thumb {
    height: 12;
    width: 12;
    border: 1px solid #fff;
    outline: 2px solid #0096ff;
    background-color: #0096ff;
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.13),
      0 0 0 1px rgba(0, 0, 0, 0.02);
    position: relative;
    &:focus,
    &:hover,
    &.mui-active {
      box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.3),
        0 0 0 1px rgba(0, 0, 0, 0.02);
      @media (hover: none) {
        box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.13),
          0 0 0 1px rgba(0, 0, 0, 0.02);
      }
    }
  }
  .MuiSlider-valueLabel {
    font-size: 14px !important;
    font-weight: 400; 
    top: 0;
    background-color: transparent;
    color: #323C46;
    &:before {
      display: none;
    }
    &* {
      background: transparent;
      color: #000;
    }
  }
  .css-7367be-MuiSlider-valueLabel {
    font-size: 14px;
    font-weight: 400; 
    top: 0px;
    background-color: transparent;   
    color: #323C46;
  }
  .css-14pt78w-MuiSlider-rail {
    border: none;
    background-color: #0096ff;
    height: 3;
  }
  .css-14pt78w-MuiSlider-rail {
    opacity: 1;
    background-color: #bfbfbf !important;
  }
  .css-6cwnna-MuiSlider-mark {
    width: 2px;
    height: 8px;
    background-color: #03a9f4;
  }
  .css-1k3d9s1-MuiSlider-markLabel {
    font-size: 0.8rem;
    top: 20px;
  }
  .MuiSlider-thumb {
    position: relative;
  }
  .MuiSlider-thumb::before {
    position: absolute;
    bottom: -38px;
    width: auto;
    height: 30px;
    background-color: #fff;
    border-radius: 5px;
    color: #0096ff;
    font-size: 0.8rem;
    font-weight: 500;
    box-shadow: 0px 0px 5px #d4d4d4;
    display: grid;
    place-content: center;
    padding: 0px 8px;
  }
`;
export {
    RangeSlider,
    RangeSliderBox
};
