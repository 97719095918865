const fetchingStatusReducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loggingIn: true,
      };
    case 'error':
      return {
        ...state,
        loggingIn: false,
      };
    case 'success':
      return {
        ...state,
        loggingIn: false,
      };
    default:
      return {
        state,
      };
  }
};

export { fetchingStatusReducer };
