import React from 'react';

import { Skeleton } from '@mui/material';

const Loading = () => (
  <Skeleton
    variant='rectangular'
    height='100%'
    width='100%'
    sx={{ display: 'block', flexGrow: 1, minHeight: 0 }}
  />
);

export { Loading };
