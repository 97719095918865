import React, { useContext, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

// MUI imports
import { Container, Button, TableContainer, Box, Stack } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

// import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

// Internal imports
import { useSelector , useDispatch  } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { AddColumnModal } from '../Components/OtherComponents/AddColumnModal';

// Styled imports
import { StyledPaper } from './StyledMainBox';
import { GlobalErrorComponent } from '../../../components/GlobalErrorComponent';
import { PaginatedTable } from '../../../components/PaginatedTable';

import { UserInfoContext } from '../../../context/UserInfoContext';
import { StyledEnhanceTable } from '../Components/StyledComponents/StyledCommon';
import { useGetAllItemsQuery } from '../../../api/RTK/services/feedItems';
import { SearchWithButton } from '../Components/GlobalComponents/SearchWithButton';
import { setSearchTermAllItems } from '../Redux/PaginationVales';


// MUI imports

// Internal component import

const renderCell = (val) =>{
  if (Array.isArray(val)) {
    return `[${val.join(", ")}]`;
  }
  return val;
}

const headCells = (data) =>
  data?.data[0]
    ? (Object.keys(data?.data[0]) && [
        {
          id: 'image',
          numeric: false,
          label: 'Image',
          disablePadding: false,
          alignRight: false,
          formatFunction: (row) => (
            <img
              src={Array.isArray(row.image_link) ? row.image_link[0] : row.image_link || row.g_image_link || row['g:image_link']}
              alt=''
              height='70'
              width='50'
              style={{
                maxWidth: '100px',
                maxHeight: '100px',
                objectFit: 'contain',
              }}
            />
          ),
        },
        ...Object.keys(data?.data[0]).map((field) => ({
          id: field,
          numeric: false,
          label: _.startCase(field),
          disablePadding: false,
          alignRight: false,
          formatFunction: (row) =>
            ['description', 'additional_image_link'].includes(field) ? (
              <Box
                as='span'
                sx={{
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {renderCell(row[field])}
              </Box>
            ) : (
              renderCell(row[field])
            ),
        })),
      ]) ||
      []
    : [];

// main function component
const AllItemsList = () => {
  const dispatch = useDispatch()
  const search = useSelector((state) => state.PaginationValuesReducers.searchTermAllItems)
  const [openCustomCol, setopenCustomCol] = useState(false)
  const [disable , setDisable] = useState(false)
  const [isResizable, setResizable] = useState(
    Array.from({ length: 50 }, () => false)
  );
  const [maxWidth, setMaxWidth] = useState(
    Array.from({ length: 50 }, () => 300)
  );
  // const [searchTerm, setSearchTerm] = useState('');
  const [fromNewPage , setfromNewPage] = useState(false)
  const navigate = useNavigate();
  const handleImported = () => {
    navigate('../');
  };
  const { company } = useContext(UserInfoContext);
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const handleSearchButtonClick = (value) => {
    dispatch(setSearchTermAllItems(value.trim()))
    setfromNewPage(true)
  };

  // Handlers to update state
  const handleSetResizable = useCallback((index, value) => {
    setResizable((prevResizable) => {
      const newResizable = [...prevResizable];
      newResizable[index] = value;
      return newResizable;
    });
  }, []);

  const handleSetMaxWidth = useCallback((index, value) => {
    setMaxWidth((prevMaxWidth) => {
      const newMaxWidth = [...prevMaxWidth];
      newMaxWidth[index] = value;
      return newMaxWidth;
    });
  }, []);
  return (
    <>
      <Container maxWidth='xxl'>
        <TitleCard
          title='All Items'
          subTitle='These are all the items that are imported'
        >
          <Stack direction='row' spacing={3}>
            <SearchWithButton
              placeholder='Search'
              showEndAdornment
              onClick={handleSearchButtonClick}
            />

            <Button
              variant='pillOutlined'
              disableRipple
              sx={{
                height: '44px',
                minWidth:{ xl: "250px", lg: "250px", md:'220px' , sm : "250px"},
                '&:hover': { svg: { color: '#0096ff !important' } },
                svg: { paddingRight: '5px', fontSize: '30px' },
              }}
              disabled={disable}
              onClick={() => setopenCustomCol(true)}
            >
              <EditNoteOutlinedIcon />
              Customise Columns
            </Button>
          </Stack>
      </TitleCard>
      <StyledPaper sx={{ padding: '0px 0px' }}>
        <ErrorBoundary fallback={<GlobalErrorComponent />}>
          <StyledEnhanceTable sx={{ width: '100%', paddingBottom: '15px' }}>
            <TableContainer sx={{ borderBottom: '1px solid #dedede' }}>
              <PaginatedTable
                rowIdField='id'
                rowsPerPage={10}
                fromNewPage={fromNewPage}
                setfromNewPage={setfromNewPage}
                verticalBorders={false}
                displayFooter={false}
                styleBodyCell={(index) => ({
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: !isResizable[index]
                    ? `${maxWidth[index]}px`
                    : '10px',
                })}
                setMaxWidth={(index, value) => handleSetResizable(index, value)}
                setResizable={(index, value) => handleSetMaxWidth(index, value)}
                fetcherHook={useGetAllItemsQuery}
                args={{
                  companyId: company?.id,
                  projectId: projectSelectId,
                  search,
                }}
                linkRows
                linkFormatter={() => `./item-details`}
                linkStateFormatter={(row) => row}
                headCellGenerator={headCells}
                setDisable={setDisable}
              />
            </TableContainer>

              <Button
                sx={{
                  width: '200px',
                  display: 'flex',
                  margin: '25px auto',
                }}
                variant='outlined'
                onClick={handleImported}
              >
                Back
              </Button>
            </StyledEnhanceTable>
          </ErrorBoundary>
        </StyledPaper>
      </Container>
      <AddColumnModal
        openModel={openCustomCol}
        setModelOpen={setopenCustomCol}
        modalTitle='Add or Delete Columns'
        setftype=''
      />
    </>
  );
};

export { AllItemsList };
