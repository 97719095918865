/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

// MUI imports
import { Stack, Button, Box } from '@mui/material';

// Internal components import
import { Link } from 'react-router-dom';
import { DynamicSelectBox } from '../../GlobalComponents/DynamicSelectBox';
import { DynamicTextField } from '../../GlobalComponents/DynamicTextField';
import { ImportSchema } from '../../../Schemas';
import { updateVal } from '../../../Redux/ImportForm/slice';
import {
  fileTypeImageMap,
  authenticationOptions,
  delimiterOptions,
  encodingOptions,
  quotingOptions,
  protocolOptions,
  fileTypeOptions,
} from './options';
import { UserInfoContext } from '../../../../../context/UserInfoContext';

// Styled component import
import { StyledBox } from '../../StyledComponents/StyledImportFileForm';
import { WooComPlatform } from '../../../../ProjectManagement/Pages/AccountList/components/LinkAccountSpeedDial/parts/WooPlatform';

const preFormatter = (data) => ({
  file_type: data?.file_type || 'XML',
  name: data?.name || '',
  protocol: data?.protocol || 'https://',
  url: data?.url || '',
  authentication: data?.authentication || 'NO_AUTH',
  encoding: data?.encoding || 'AUTO',
  quoting: data?.quoting || 'AUTO',
  delimiters: data?.delimiters || 'AUTO',
  password: data?.password || '',
  username: data?.username || '',
});

// Main function for component
const ImportFileForm = ({
  data = {},
  handleSubmit,
  accountList,
  errorMessage,
  setSendPatch,
  setFormValue
}) => {
  const formikRef = useRef();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { user } = useContext(UserInfoContext);
  const importUpdateStore = useDebouncedCallback((key, val) => {
    dispatch(updateVal({ key, val }));
  }, 0);

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Only run this effect if data.url has changed and it's not just the protocol being updated.
    if (data?.url && formikRef.current) {
      const protocolMatch = data.url.match(/^(https?|ftp|sftp):\/\//);
      if (protocolMatch) {
        const protocol = protocolMatch[0];
        formikRef.current.setFieldValue('protocol', protocol, false);
      }
    }
  }, [data.url]);

  const projectImportFiles = useSelector(
    (state) => state.allProjectsRedux.files
  );

  const updateFieldInStore = (field, value) => {
    if (!data.id) {
      importUpdateStore(field, value);
    }
  };

  const filterAndMapAccounts = (platform) =>
    accountList
      ?.filter((item) => item.platform === platform)
      .map((account) => ({
        title: account.name,
        value: account.name,
      })) || [];

  // Original arrays
  const updatedAccountNames = filterAndMapAccounts('SHOPIFY');
  const updatedWooComNames = filterAndMapAccounts('WOO_COMMERCE');

  // set url when name is selected for SHOPIFY
  useEffect(() => {
    if (data.name.length > 0) {
      const account = accountList?.filter((item) => item.name === data.name);
      if (account.length > 0) {
        updateFieldInStore('url', account[0].customer_id);
      }
    }
  }, [data.name]);

  // for opening and closing the WooComPlatform
  useEffect(() => {
    if (data.file_type === 'WOO_COMMERCE' && updatedWooComNames.length === 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [data.file_type, updatedWooComNames.length]);

  return (
    <Formik
      initialValues={preFormatter(data)}
      innerRef={formikRef}
      enableReinitialize
      validationSchema={ImportSchema}
      onSubmit={(values, { setSubmitting }) => {
        const trimmedValues = {
          ...values,
          url: values.url.trim(),
        };
        handleSubmit(trimmedValues);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        dirty
      }) =>{ 
        useEffect(() =>{
          if(setSendPatch){
            setSendPatch(dirty)
            setFormValue(values)
          }
      
        }, [dirty , values])
       
       return (
        <Form>
          <Stack
            alignItems='center'
            direction={{ xs: 'column', lg: 'row' }}
            flexWrap='wrap'
          >
            <StyledBox className='importSelectForm'>
              <DynamicSelectBox
                name='file_type'
                value={values.file_type}
                inputTitle='Select file type'
                placeholderText='Select file type'
                selectedItem={fileTypeOptions}
                itemImage={fileTypeImageMap}
                disabled={
                  isSubmitting ||
                  user?.role === 'COLLABORATOR' ||
                  user?.role === 'SUB_COLLABORATOR'
                }
                handleChange={(event) => {
                  updateFieldInStore('file_type', event.target.value);
                  handleChange(event);
                }}
                handleBlur={handleBlur}
              />
            </StyledBox>
            {['SHOPIFY', 'WOO_COMMERCE'].includes(data?.file_type) ||
            ['SHOPIFY', 'WOO_COMMERCE'].includes(values.file_type) ? (
              <StyledBox className='importSelectForm'>
                <DynamicSelectBox
                  name='name'
                  value={values.name}
                  inputTitle={
                    data.file_type === 'SHOPIFY' ||
                    values.file_type === 'SHOPIFY'
                      ? 'Select Shopify shop'
                      : 'Select WooCommerce shop '
                  }
                  placeholderText={
                    data.file_type === 'SHOPIFY' ||
                    values.file_type === 'SHOPIFY'
                      ? 'Select Shopify shop'
                      : 'Select WooCommerce shop'
                  }
                  selectedItem={
                    data.file_type === 'SHOPIFY' ||
                    values.file_type === 'SHOPIFY'
                      ? updatedAccountNames
                      : updatedWooComNames
                  }
                  disabled={
                    isSubmitting ||
                    user?.role === 'COLLABORATOR' ||
                    user?.role === 'SUB_COLLABORATOR'
                  }
                  handleChange={(event) => {
                    updateFieldInStore('name', event.target.value);
                    handleChange(event);
                  }}
                  handleBlur={handleBlur}
                  error={
                    errors.name && touched.name ? <Box>{errors.name}</Box> : ''
                  }
                />
              </StyledBox>
            ) : (
              <StyledBox className='importInputForm'>
                <DynamicTextField
                  name='name'
                  value={values.name}
                  textTitle='Name'
                  inputPlaceholder='e.g., Webshop'
                  disabled={
                    isSubmitting ||
                    user?.role === 'COLLABORATOR' ||
                    user?.role === 'SUB_COLLABORATOR'
                  }
                  inType='text'
                  onChange={(event) => {
                    const trimmedName = event.target.value.trim();
                    handleChange(event);
                    updateFieldInStore('name', trimmedName);
                  }}
                  onBlur={handleBlur}
                  error={
                    (errors.name && touched.name) || errorMessage ? (
                      <Box>{errors.name}</Box>
                    ) : (
                      ''
                    )
                  }
                />
              </StyledBox>
            )}
          </Stack>
          {['SHOPIFY', 'WOO_COMMERCE'].includes(data?.file_type) ||
          ['SHOPIFY', 'WOO_COMMERCE'].includes(values.file_type) ? null : (
            <>
              <Stack
                alignItems='center'
                direction={{ xs: 'column', lg: 'row' }}
                flexWrap='wrap'
              >
                <StyledBox className='importSelectSecondLineFirst'>
                  <DynamicSelectBox
                    name='protocol'
                    value={values.protocol}
                    inputTitle='File url'
                    disabled={
                      isSubmitting ||
                      user?.role === 'COLLABORATOR' ||
                      user?.role === 'SUB_COLLABORATOR'
                    }
                    selectedItem={protocolOptions}
                    handleChange={(event) => {
                      handleChange(event);
                      updateFieldInStore('protocol', event.target.value);
                    }}
                    handleBlur={handleBlur}
                  />
                </StyledBox>
                <StyledBox
                  className='importInputSecondLine'
                  style={{
                    padding:
                      values?.protocol !== 'sftp://'
                        ? '0px 30px'
                        : '0px 0px 0px 30px',
                  }}
                >
                  <DynamicTextField
                    name='url'
                    value={values.url}
                    inputPlaceholder='www.example.com/feed'
                    disabled={
                      isSubmitting ||
                      user?.role === 'COLLABORATOR' ||
                      user?.role === 'SUB_COLLABORATOR'
                    }
                    inType='text'
                    onChange={(event) => {
                      const trimmedURL = event.target.value.trim();
                      handleChange(event);
                      updateFieldInStore('url', trimmedURL);
                    }}
                    onBlur={(event) => {
                      const enteredUrl = event.target.value.trim();
                      const protocolMatch = enteredUrl.match(
                        /^(https?|ftp|sftp):\/\//i
                      );
                      const protocol = protocolMatch
                        ? protocolMatch[0]
                        : values.protocol;

                      const trimmedUrl =
                        enteredUrl.startsWith('http://') ||
                        enteredUrl.startsWith('https://') ||
                        enteredUrl.startsWith('sftp://')
                          ? enteredUrl.slice(protocol.length)
                          : enteredUrl;

                      updateFieldInStore('url', trimmedUrl);
                      updateFieldInStore('protocol', protocol);

                      handleChange(event);
                      handleBlur(event);
                    }}
                    error={
                      errors.url && touched.url ? <Box>{errors.url}</Box> : ''
                    }
                  />
                </StyledBox>
                {values?.protocol !== 'sftp://' && (
                  <StyledBox className='importSelectSecondLine'>
                    <DynamicSelectBox
                      name='authentication'
                      disabled={
                        isSubmitting ||
                        user?.role === 'COLLABORATOR' ||
                        user?.role === 'SUB_COLLABORATOR'
                      }
                      value={values.authentication}
                      selectedItem={authenticationOptions}
                      handleChange={(event) => {
                        handleChange(event);

                        updateFieldInStore(
                          'authentication',
                          event.target.value
                        );
                      }}
                      handleBlur={handleBlur}
                    />
                  </StyledBox>
                )}

                {values.authentication !== 'NO_AUTH' ||
                values.protocol === 'sftp://' ||
                data?.url.startsWith('sftp://') ? (
                  <Stack
                    alignItems='center'
                    direction={{ xs: 'column', lg: 'row' }}
                    flexWrap='wrap'
                    width='100%'
                  >
                    <StyledBox className='authInputs'>
                      <DynamicTextField
                        name='username'
                        value={values.username}
                        textTitle='Username'
                        inputPlaceholder='Enter Username'
                        disabled={
                          isSubmitting ||
                          user?.role === 'COLLABORATOR' ||
                          user?.role === 'SUB_COLLABORATOR'
                        }
                        inType='text'
                        onChange={(event) => {
                          handleChange(event);

                          updateFieldInStore('username', event.target.value);
                        }}
                        onBlur={handleBlur}
                      />
                    </StyledBox>

                    <StyledBox className='authPass'>
                      <DynamicTextField
                        name='password'
                        value={values.password}
                        textTitle='Password'
                        inputPlaceholder='Enter Password'
                        disabled={
                          isSubmitting ||
                          user?.role === 'COLLABORATOR' ||
                          user?.role === 'SUB_COLLABORATOR'
                        }
                        inType='password'
                        onChange={(event) => {
                          handleChange(event);

                          updateFieldInStore('password', event.target.value);
                        }}
                        onBlur={handleBlur}
                      />
                    </StyledBox>
                  </Stack>
                ) : null}
              </Stack>
              <Stack
                alignItems='center'
                direction={{ xs: 'column', lg: 'row' }}
                flexWrap='wrap'
              >
                <StyledBox className='importSelectThirdLine'>
                  <DynamicSelectBox
                    name='encoding'
                    value={values.encoding}
                    disabled={
                      isSubmitting ||
                      user?.role === 'COLLABORATOR' ||
                      user?.role === 'SUB_COLLABORATOR'
                    }
                    inputTitle='Encoding'
                    selectedItem={encodingOptions}
                    handleChange={(event) => {
                      handleChange(event);
                      updateFieldInStore('encoding', event.target.value);
                    }}
                    handleBlur={handleBlur}
                  />
                </StyledBox>

                <StyledBox className='importSelectMiddle'>
                  <DynamicSelectBox
                    name='quoting'
                    value={values.quoting}
                    inputTitle='Quoting'
                    disabled={
                      isSubmitting ||
                      user?.role === 'COLLABORATOR' ||
                      user?.role === 'SUB_COLLABORATOR'
                    }
                    selectedItem={quotingOptions}
                    handleChange={(event) => {
                      handleChange(event);
                      updateFieldInStore('quoting', event.target.value);
                    }}
                    handleBlur={handleBlur}
                  />
                </StyledBox>
                <StyledBox className='importSelectThirdLine'>
                  <DynamicSelectBox
                    name='delimiters'
                    value={values.delimiters}
                    disabled={
                      isSubmitting ||
                      user?.role === 'COLLABORATOR' ||
                      user?.role === 'SUB_COLLABORATOR'
                    }
                    inputTitle='Delimiter'
                    selectedItem={delimiterOptions}
                    handleChange={(event) => {
                      handleChange(event);

                      updateFieldInStore('delimiters', event.target.value);
                    }}
                    handleBlur={handleBlur}
                  />
                </StyledBox>
              </Stack>
            </>
          )}

          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            sx={{ marginTop: '40px', marginBottom: '20px' }}
          >
            <Button
              sx={{
                width: '200px',
                margin: '5px',
              }}
              variant='outlined'
              component={Link}
              to={projectImportFiles?.length > 0 ? '..' : '../..'}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: '200px',
                margin: '5px',
              }}
              variant='contained'
              disabled={isSubmitting}
              type='submit'
            >
              {user?.role !== 'COLLABORATOR' &&
              user?.role !== 'SUB_COLLABORATOR'
                ? 'Save & Next'
                : 'Next'}
            </Button>
          </Stack>
          <WooComPlatform open={open} handleCloseModal={handleCloseModal} />
        </Form>
) }}
    </Formik>
  );
};

export { ImportFileForm };
