import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { usePostPlatformMutation } from '../../../../api/RTK/services/platformFlow';
import { GlobalLoadingComponent } from '../../../../components/GlobalLoadingComponent';

const Oauth2CallBackComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [postPlatform, { isLoading, error, isError, isSuccess }] =
    usePostPlatformMutation();

  useEffect(() => {
    let unsubscribed = false;
    const abortController = new AbortController();

    const fetchData = async () => {
      if (!unsubscribed) {
        const code = searchParams.get('code');
        const state = searchParams.get('state');
        const platform = searchParams.get('platform');

        postPlatform({
          platform,
          data: {
            code,
            state,
          },
        })
          .unwrap()
          .then(() => {
            setTimeout(() => navigate(`/accounts`), 3000);
          })
          .catch(() => {
            setTimeout(() => window.close(), 3000);
          });
      }
    };

    fetchData();

    return () => {
      unsubscribed = true;
      abortController.abort();
    };
  }, [searchParams]);

  return (
    <Box
      sx={{
        display: 'grid',
        placeItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isLoading && <GlobalLoadingComponent />}
        {(isError || error) && (
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap={1}
          >
            <Cancel
              sx={{
                color: 'error.main',
              }}
            />
            <Box display='flex' flexDirection='column' alignItems='center'>
              <Typography variant='bigSemiBold'>
                OOPS! Something went wrong!
              </Typography>
              <Typography variant='bigSemiBold' sx={{ color: 'error.main' }}>
                Please Try Again
              </Typography>
            </Box>
          </Box>
        )}
        {isSuccess && (
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap={1}
          >
            <CheckCircle
              sx={{
                color: 'primary.main',
              }}
            />
            <Typography variant='bigSemiBold'>
              Consent taken successfully!
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export { Oauth2CallBackComponent };
