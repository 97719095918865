import DummyImg1 from '../../assests/images/Other/DummyImg1.jpg';
import DummyImg2 from '../../assests/images/Other/DummyImg2.jpg';
import DummyImg3 from '../../assests/images/Other/DummyImg3.jpg';
import DummyImg4 from '../../assests/images/Other/DummyImg4.jpg';
import DummyImg5 from '../../assests/images/Other/DummyImg5.jpg';
import DummyImg6 from '../../assests/images/Other/DummyImg6.jpg';
import {
  DummyImg7,
  DummyImg8,
  DummyImg9,
} from '../../constants/endpoints/assests/Other';

const data = {
  adInsights: {
    google: [
      {
        'Campaign Id': '1',
        'Campaign Name': 'Cosmetics',
        'Conversion value': 16568,
        Clicks: 712,
        Cost: 4272,
        Conversions: 8,
        ROAS: 3.8,
        ads_group: [
          {
            'Ad Group Id': 97315258062,
            'Ad Group Name': 'Cosmetics 1 ',
            'Conversion value': 6213,
            Clicks: 309,
            Cost: 1293,
            Conversions: 3,
            ROAS: 4.8,
          },
          {
            'Ad Group Id': 97315258222,
            'Ad Group Name': 'Cosmetics 2 ',
            'Conversion value': 10355,
            Clicks: 403,
            Cost: 2979,
            Conversions: 5,
            ROAS: 3.4,
          },
        ],
      },
      {
        'Campaign Id': '2',
        'Campaign Name': 'Serums',
        'Conversion value': 362526.564,
        Clicks: 3961,
        Cost: 27330.9,
        Conversions: 114.869,
        ROAS: 13.26434783,
        ads_group: [
          {
            'Ad Group Id': 973152580622,
            'Ad Group Name': 'Serums 1 ',
            'Conversion value': 362526.564,
            Clicks: 3961,
            Cost: 27330.9,
            Conversions: 114.869,
            ROAS: 13.26434783,
          },
        ],
      },
      {
        'Campaign Id': '3',
        'Campaign Name': 'Facewash',
        'Conversion value': 266800.014,
        Clicks: 9462,
        Cost: 76831.44,
        Conversions: 123.006,
        ROAS: 3.472536946,
        ads_group: [
          {
            'Ad Group Id': 973152580623,
            'Ad Group Name': 'Facewash 1 ',
            'Conversion value': 266800.014,
            Clicks: 9462,
            Cost: 76831.44,
            Conversions: 123.006,
            ROAS: 3.472536946,
          },
        ],
      },
      {
        'Campaign Id': '4',
        'Campaign Name': 'Shampoos',
        'Conversion value': 11029.436,
        Clicks: 439,
        Cost: 2151.1,
        Conversions: 4.829,
        ROAS: 5.127346939,
        ads_group: [
          {
            'Ad Group Id': 9731525806212,
            'Ad Group Name': 'Shampoos 1 ',
            'Conversion value': 11029.436,
            Clicks: 439,
            Cost: 2151.1,
            Conversions: 4.829,
            ROAS: 5.127346939,
          },
        ],
      },
      {
        'Campaign Id': '5',
        'Campaign Name': 'Conditioners',
        'Conversion value': 175245.84,
        Clicks: 1680,
        Cost: 3864,
        Conversions: 48.72,
        ROAS: 45.35347826,
        ads_group: [
          {
            'Ad Group Id': 973152580362,
            'Ad Group Name': 'Conditioners 1 ',
            'Conversion value': 175245.84,
            Clicks: 1680,
            Cost: 3864,
            Conversions: 48.72,
            ROAS: 45.35347826,
          },
        ],
      },
      {
        'Campaign Id': '6',
        'Campaign Name': 'Facewash 2',
        'Conversion value': 623106.216,
        Clicks: 4978,
        Cost: 29370.2,
        Conversions: 189.164,
        ROAS: 21.21559322,
        ads_group: [
          {
            'Ad Group Id': 973152582062,
            'Ad Group Name': 'Facewash 1 ',
            'Conversion value': 623106.216,
            Clicks: 4978,
            Cost: 29370.2,
            Conversions: 189.164,
            ROAS: 21.21559322,
          },
        ],
      },
      {
        'Campaign Id': '7',
        'Campaign Name': 'Makeup removal',
        'Conversion value': 118289.808,
        Clicks: 5369,
        Cost: 20402.2,
        Conversions: 64.428,
        ROAS: 5.797894737,
        ads_group: [
          {
            'Ad Group Id': 973152580462,
            'Ad Group Name': 'Makeup removal 1 ',
            'Conversion value': 118289.808,
            Clicks: 5369,
            Cost: 20402.2,
            Conversions: 64.428,
            ROAS: 5.797894737,
          },
        ],
      },
      {
        'Campaign Id': '8',
        'Campaign Name': 'Night Cream',
        'Conversion value': 99595.98,
        Clicks: 9315,
        Cost: 43780.5,
        Conversions: 37.26,
        ROAS: 2.274893617,
        ads_group: [
          {
            'Ad Group Id': 973152518062,
            'Ad Group Name': 'Night Cream 1 ',
            'Conversion value': 99595.98,
            Clicks: 9315,
            Cost: 43780.5,
            Conversions: 37.26,
            ROAS: 2.274893617,
          },
        ],
      },
      {
        'Campaign Id': '9',
        'Campaign Name': 'Moisturizers',
        'Conversion value': 0,
        Clicks: 5312,
        Cost: 16467.2,
        Conversions: 0,
        ROAS: 0,
        ads_group: [
          {
            'Ad Group Id': 973152538062,
            'Ad Group Name': 'Moisturizer 1 ',
            'Conversion value': 0,
            Clicks: 5312,
            Cost: 16467.2,
            Conversions: 0,
            ROAS: 0,
          },
        ],
      },
      {
        'Campaign Id': '',
        'Campaign Name': 'Total',
        'Conversion value': 828687,
        Clicks: 25529,
        Cost: 144465,
        Conversions: 358,
        ROAS: 5.7,
      },
    ],
    meta: [
      {
        name: 'AH - Conversion Anniversary - Images',
        filter: 'CONVERSIONS',
        ads: [
          {
            Ad_Id: '23849490197410528',
            Ad_Name: 'Anniversary Image 1',
            Ctr: 2.9,
            Inline_Link_Clicks: 10144,
            Reach: 73498,
            Spend: 20510,
            Conversions: 218,
            ROAS: 3.72,
            thumbnail_url: '',
            image_url: DummyImg1,
          },
          {
            Ad_Id: '23849490217920528',
            Ad_Name: 'Anniversary Image 2',
            Ctr: 2.5,
            Inline_Link_Clicks: 9339,
            Reach: 68510,
            Spend: 13264,
            Conversions: 136,
            ROAS: 3.5,
            thumbnail_url: '',
            image_url: DummyImg2,
          },
        ],
      },
      {
        name: 'AH - Conversion Anniversary - Video',
        filter: 'CONVERSIONS',
        ads: [
          {
            Ad_Id: '23849490083210528',
            Ad_Name: 'Anniversary Video 1',
            Ctr: 3.1,
            Inline_Link_Clicks: 8374,
            Reach: 78026,
            Spend: 25860,
            Conversions: 205,
            ROAS: 2.7,
            thumbnail_url: '',
            image_url: DummyImg3,
          },
          {
            Ad_Id: '23849490256050528',
            Ad_Name: 'Anniversary Video 2',
            Ctr: 3.5,
            Inline_Link_Clicks: 10574,
            Reach: 50786,
            Spend: 27891,
            Conversions: 316,
            ROAS: 3.9,
            thumbnail_url: '',
            image_url: DummyImg4,
          },
        ],
      },
      {
        name: 'AH - Summer Fest - Images',
        filter: 'CONVERSIONS',
        ads: [
          {
            Ad_Id: '23849490197410528',
            Ad_Name: 'Summer Fest Image 1',
            Ctr: 2.3,
            Inline_Link_Clicks: 9442,
            Reach: 44322,
            Spend: 17136,
            Conversions: 218,
            ROAS: 4.88,
            thumbnail_url: '',
            image_url: DummyImg7,
          },
          {
            Ad_Id: '23849490217920528',
            Ad_Name: 'Summer Fest Image 2',
            Ctr: 2.4,
            Inline_Link_Clicks: 7003,
            Reach: 51243,
            Spend: 10023,
            Conversions: 123,
            ROAS: 5.1,
            thumbnail_url: '',
            image_url: DummyImg8,
          },
        ],
      },
      {
        name: 'AH - Summer Fest - Video',
        filter: 'OUTCOME_SALES',
        ads: [
          {
            Ad_Id: '23849490256050528',
            Ad_Name: 'Summer Fest Video 1',
            Ctr: 2.8,
            Inline_Link_Clicks: 16445,
            Reach: 42111,
            Spend: 36410,
            Conversions: 297,
            ROAS: 5.65,
            thumbnail_url: '',
            image_url: DummyImg9,
          },
        ],
      },
      {
        name: 'AH - Summer Fest - Video 2',
        filter: 'OUTCOME_SALES',
        ads: [
          {
            Ad_Id: '23849490256050528',
            Ad_Name: 'Summer Fest Video 2',
            Ctr: 2.8,
            Inline_Link_Clicks: 9249,
            Reach: 40009,
            Spend: 23410,
            Conversions: 297,
            ROAS: 6.42,
            thumbnail_url: '',
            image_url: DummyImg9,
          },
        ],
      },
      {
        name: 'AH - Summer Fest - Image 2',
        filter: 'OUTCOME_SALES',
        ads: [
          {
            Ad_Id: '23849490256050528',
            Ad_Name: 'Summer Fest Image 2',
            Ctr: 2.1,
            Inline_Link_Clicks: 81071,
            Reach: 31562,
            Spend: 22455,
            Conversions: 291,
            ROAS: 3.1,
            thumbnail_url: '',
            image_url: DummyImg8,
          },
        ],
      },
    ],
  },

  pmaxCampaingns: {
    google:[
      {
          "Campaign Name": "KW - Pmax - NO",
          "Campaign Id": "20671201194",
          "Cost": 382.002807,
          "Clicks": 51,
          "Conversions": 2.0,
          "Conversion Value": 1145.776453319,
          "ROAS": 2.9993927592238867,
          "Verticals": [
              {
                  "Vertical Name": "Shopping",
                  "Vertical Id": "1",
                  "Cost": "(24.82%) 94.81498",
                  "Clicks": "(39.22%) 20",
                  "Conversions": "(100.0%) 2.0",
                  "Conversion Value": "(100.0%) 1145.776453319",
                  "ROAS": 12.08
              },
              {
                  "Vertical Name": "Video",
                  "Vertical Id": "2",
                  "Cost": "(0.2%) 0.765579",
                  "Clicks": "(0.0%) 0.0",
                  "Conversions": "(0.0%) 0.0",
                  "Conversion Value": "(0.0%) 0.0",
                  "ROAS": 0.0
              },
              {
                  "Vertical Name": "Display",
                  "Vertical Id": "3",
                  "Cost": "(1605.19%) 6131.866428",
                  "Clicks": "(1341.18%) 684",
                  "Conversions": "(0.0%) 0.0",
                  "Conversion Value": "(0.0%) 0.0",
                  "ROAS": 0.0
              },
              {
                  "Vertical Name": "Search",
                  "Vertical Id": "4",
                  "Cost": "(0.0%) 0",
                  "Clicks": "(0.0%) 0",
                  "Conversions": "(0.0%) 0.0",
                  "Conversion Value": "(0.0%) 0.0",
                  "ROAS": 0
              }
          ]
      },
      {
          "Campaign Name": "KW - Pmax - DK",
          "Campaign Id": "20671205910",
          "Cost": 184.6,
          "Clicks": 36,
          "Conversions": 0.823043,
          "Conversion Value": 464.374391058,
          "ROAS": 2.515570915807151,
          "Verticals": [
              {
                  "Vertical Name": "Shopping",
                  "Vertical Id": "1",
                  "Cost": "(51.72%) 95.48",
                  "Clicks": "(75.0%) 27",
                  "Conversions": "(100.0%) 0.823043",
                  "Conversion Value": "(100.0%) 464.374391058",
                  "ROAS": 4.86
              },
              {
                  "Vertical Name": "Video",
                  "Vertical Id": "2",
                  "Cost": "(0.0%) 0.0",
                  "Clicks": "(0.0%) 0.0",
                  "Conversions": "(0.0%) 0.0",
                  "Conversion Value": "(0.0%) 0.0",
                  "ROAS": 0.0
              },
              {
                  "Vertical Name": "Display",
                  "Vertical Id": "3",
                  "Cost": "(352.0%) 649.8",
                  "Clicks": "(422.22%) 152",
                  "Conversions": "(0.0%) 0.0",
                  "Conversion Value": "(0.0%) 0.0",
                  "ROAS": 0.0
              },
              {
                  "Vertical Name": "Search",
                  "Vertical Id": "4",
                  "Cost": "(0.0%) 0",
                  "Clicks": "(0.0%) 0",
                  "Conversions": "(0.0%) 0.0",
                  "Conversion Value": "(0.0%) 0.0",
                  "ROAS": 0
              }
          ]
      },
      {
          "Campaign Name": "KW - Pmax - SE",
          "Campaign Id": "20676823115",
          "Cost": 831.595488,
          "Clicks": 126,
          "Conversions": 1.0,
          "Conversion Value": 248.0,
          "ROAS": 0.29822191627860295,
          "Verticals": [
              {
                  "Vertical Name": "Shopping",
                  "Vertical Id": "1",
                  "Cost": "(1.93%) 16.09",
                  "Clicks": "(9.52%) 12",
                  "Conversions": "(0.0%) 0.0",
                  "Conversion Value": "(0.0%) 0.0",
                  "ROAS": 0.0
              },
              {
                  "Vertical Name": "Video",
                  "Vertical Id": "2",
                  "Cost": "(0.57%) 4.747683",
                  "Clicks": "(0.79%) 1.0",
                  "Conversions": "(0.0%) 0.0",
                  "Conversion Value": "(0.0%) 0.0",
                  "ROAS": 0.0
              },
              {
                  "Vertical Name": "Display",
                  "Vertical Id": "3",
                  "Cost": "(890.24%) 7403.16",
                  "Clicks": "(965.08%) 1216",
                  "Conversions": "(0.0%) 0.0",
                  "Conversion Value": "(0.0%) 0.0",
                  "ROAS": 0.0
              },
              {
                  "Vertical Name": "Search",
                  "Vertical Id": "4",
                  "Cost": "(0.0%) 0",
                  "Clicks": "(0.0%) 0",
                  "Conversions": "(100.0%) 1.0",
                  "Conversion Value": "(100.0%) 248.0",
                  "ROAS": 0
              }
          ]
      },
      {
          "Campaign Name": "KW - Pmax - FI",
          "Campaign Id": "20677322786",
          "Cost": 450.22703,
          "Clicks": 58,
          "Conversions": 0.0,
          "Conversion Value": 0.0,
          "ROAS": 0.0,
          "Verticals": [
              {
                  "Vertical Name": "Shopping",
                  "Vertical Id": "1",
                  "Cost": "(45.79%) 206.164361",
                  "Clicks": "(70.69%) 41",
                  "Conversions": "(0%) 0.0",
                  "Conversion Value": "(0%) 0.0",
                  "ROAS": 0.0
              },
              {
                  "Vertical Name": "Video",
                  "Vertical Id": "2",
                  "Cost": "(5.54%) 24.938451999999998",
                  "Clicks": "(3.45%) 2.0",
                  "Conversions": "(0%) 0.0",
                  "Conversion Value": "(0%) 0.0",
                  "ROAS": 0.0
              },
              {
                  "Vertical Name": "Display",
                  "Vertical Id": "3",
                  "Cost": "(135.78%) 611.325948",
                  "Clicks": "(150.0%) 87",
                  "Conversions": "(0%) 0.0",
                  "Conversion Value": "(0%) 0.0",
                  "ROAS": 0.0
              },
              {
                  "Vertical Name": "Search",
                  "Vertical Id": "4",
                  "Cost": "(0.0%) 0",
                  "Clicks": "(0.0%) 0",
                  "Conversions": "(0%) 0.0",
                  "Conversion Value": "(0%) 0.0",
                  "ROAS": 0
              }
          ]
      },
      {
          "Campaign Name": "Total",
          "Clicks": 271,
          "Cost": 1848.4253250000002,
          "Conversions": 3.823043,
          "Conversion Value": 1858.1508443769999,
          "ROAS": 1.0052615159754965
      }
  ],
  },

  averageMarketingCost: {
    google: [
      { label: 'Average daily marketing cost', value: 5451 },
      { label: 'Average monthly marketing cost', value: 5451 },
    ],
    meta: [
      { label: 'Average daily marketing cost', value: 5451 },
      { label: 'Average monthly marketing cost', value: 5451 },
    ],
  },
  campaignSummary: [
    {
      'Campaign Id': '238448696463505289',
      'Campaign Name': 'AH - Conversion Anniversary - Images 22',
      Clicks: 19483,
      Reach: 142008,
      Spend: 33774,
      // Account_Currency: 'SEK',
      'Add To Cart': 560,
      'Checkouts Initiated': 498,
      Purchases: 354,
      Revenue: 123987.5,
      ROAS: 3.67,
    },
    {
      'Campaign Id': '238449267353805287',
      'Campaign Name': 'AH - Conversion Anniversary - Video 52',
      Clicks: 18948,
      Reach: 128812,
      Spend: 53751,
      // Account_Currency: 'SEK',
      'Add To Cart': 786,
      'Checkouts Initiated': 639,
      Purchases: 521,
      Revenue: 182350,
      ROAS: 3.39,
    },
    {
      'Campaign Id': '238449267353804283',
      'Campaign Name': 'AH - Summer Fest - Images 64',
      Clicks: 16445,
      Reach: 95565,
      Spend: 47159,
      // Account_Currency: 'SEK',
      'Add To Cart': 861,
      'Checkouts Initiated': 711,
      Purchases: 538,
      Revenue: 235761,
      ROAS: 4.99,
    },
    {
      'Campaign Id': '238449267353804282',
      'Campaign Name': 'AH - Summer Fest - Video 88',
      Clicks: 16445,
      Reach: 42111,
      Spend: 36410,
      // Account_Currency: 'SEK',
      'Add To Cart': 661,
      'Checkouts Initiated': 411,
      Purchases: 388,
      Revenue: 205761,
      ROAS: 5.65,
    },
    {
      'Campaign Id': '238449267353804281',
      'Campaign Name': 'AH - Summer Fest - Video 53',
      Clicks: 9249,
      Reach: 40009,
      Spend: 23410,
      // Account_Currency: 'SEK',
      'Add To Cart': 521,
      'Checkouts Initiated': 281,
      Purchases: 250,
      Revenue: 150542,
      ROAS: 6.42,
    },
    {
      'Campaign Id': '238449267353804285',
      'Campaign Name': 'AH - Summer Fest Image 28',
      Clicks: 81071,
      Reach: 31562,
      Spend: 22455,
      // Account_Currency: 'SEK',
      'Add To Cart': 411,
      'Checkouts Initiated': 293,
      Purchases: 231,
      Revenue: 115321,
      ROAS: 5.135,
    },
    {
      'Campaign Id': 'Total',
      'Campaign Name': '',
      Clicks: 161641,
      Reach: 480067,
      Spend: 216959,
      // Account_Currency: 'SEK',
      'Add To Cart': 3800,
      'Checkouts Initiated': 2833,
      Purchases: 2282,
      Revenue: 1013723,
      ROAS: 4.7,
    },
  ],
  conversionFunnel: [
    {
      // Campaign_Id: '23844869646350528',
      'Campaign Name': 'AH - Conversion Anniversary - Images 80',
      Clicks: 19483,
      Reach: 142008,
      Spend: 33774,
      // Account_Currency: 'SEK',
      Actions_Add_To_Cart: 560,
      'Checkouts Initiated': 498,
      Purchases: 354,
      Revenue: 123987.5,
      ROAS: 3.67,
    },
    {
      // Campaign_Id: '23844926735380528',
      'Campaign Name': 'AH - Conversion Anniversary - Video 86',
      Clicks: 18948,
      Reach: 128812,
      Spend: 53751,
      // Account_Currency: 'SEK',
      Actions_Add_To_Cart: 786,
      'Checkouts Initiated': 639,
      Purchases: 521,
      Revenue: 182350,
      ROAS: 3.39,
    },
    {
      // Campaign_Id: '23844926735380428',
      'Campaign Name': 'AH - Summer Fest - Images 55',
      Clicks: 16445,
      Reach: 95565,
      Spend: 47159,
      // Account_Currency: 'SEK',
      Actions_Add_To_Cart: 861,
      'Checkouts Initiated': 711,
      Purchases: 538,
      Revenue: 235761,
      ROAS: 4.99,
    },
    {
      // Campaign_Id: '23844926735380428',
      'Campaign Name': 'AH - Summer Fest - Video 26',
      Clicks: 16445,
      Reach: 42111,
      Spend: 36410,
      // Account_Currency: 'SEK',
      Actions_Add_To_Cart: 661,
      'Checkouts Initiated': 411,
      Purchases: 388,
      Revenue: 205761,
      ROAS: 5.65,
    },
    {
      // Campaign_Id: '23844926735380428',
      'Campaign Name': 'AH - Summer Fest - Video 23',
      Clicks: 9249,
      Reach: 40009,
      Spend: 23410,
      // Account_Currency: 'SEK',
      Actions_Add_To_Cart: 521,
      'Checkouts Initiated': 281,
      Purchases: 250,
      Revenue: 150542,
      ROAS: 6.42,
    },
    {
      // Campaign_Id: '23844926735380428',
      'Campaign Name': 'AH - Summer Fest Image 22',
      Clicks: 81071,
      Reach: 31562,
      Spend: 22455,
      // Account_Currency: 'SEK',
      Actions_Add_To_Cart: 411,
      'Checkouts Initiated': 293,
      Purchases: 231,
      Revenue: 115321,
      ROAS: 5.135,
    },
  ],
  monthlyInsights: {
    google: [
      {
        2024: [
          {
            Month: 'January',
            Revenue: 42589,
            Spend: 35741,
            Clicks: 12312,
            Conversions: 34,
            Reach: 46789,
            ROAS: 8,
          },
          {
            Month: 'February',
            Revenue: 18767,
            Spend: 96528,
            Clicks: 5675,
            Conversions: 13,
            Reach: 43445,
            ROAS: 22,
          },
          {
            Month: 'March',
            Revenue: 94365,
            Spend: 164578,
            Clicks: 78078,
            Conversions: 86,
            Reach: 20093,
            ROAS: 7,
          },
          {
            Month: 'April',
            Revenue: 85398,
            Spend: 18014,
            Clicks: 4563,
            Conversions: 53,
            Reach: 19628,
            ROAS: 9,
          },
          {
            Month: 'May',
            Revenue: 63988,
            Spend: 15987,
            Clicks: 46343,
            Conversions: 72,
            Reach: 10201,
            ROAS: 7,
          },
          {
            Month: 'June',
            Revenue: 43546,
            Spend: 11192,
            Clicks: 3453,
            Conversions: 40,
            Reach: 69611,
            ROAS: 8,
          },
          {
            Month: 'July',
            Revenue: 35618,
            Spend: 10710,
            Clicks: 6456,
            Conversions: 80,
            Reach: 64377,
            ROAS: 2,
          },
          {
            Month: 'August',
            Revenue: 78830,
            Spend: 6502,
            Clicks: 34534,
            Conversions: 7,
            Reach: 54195,
            ROAS: 3,
          },
          {
            Month: 'September',
            Revenue: 181792,
            Spend: 15397,
            Clicks: 3453,
            Conversions: 35,
            Reach: 17633,
            ROAS: 9,
          },
          {
            Month: 'October',
            Revenue: 11141,
            Spend: 18869,
            Clicks: 4565,
            Conversions: 20,
            Reach: 21560,
            ROAS: 4,
          },
          {
            Month: 'November',
            Revenue: 13739,
            Spend: 18756,
            Clicks: 2774,
            Conversions: 21,
            Reach: 21011,
            ROAS: 12,
          },
          {
            Month: 'Total',
            Revenue: 715227,
            Spend: 457886,
            Clicks: 205422,
            Conversions: 485,
            Reach: 408873,
            ROAS: 1.6,
          },
        ],
      },
      {
        2023: [
          {
            Month: 'January',
            Revenue: 98765,
            Spend: 35741,
            Clicks: 4565,
            Conversions: 75,
            Reach: 456789,
            ROAS: 5,
          },
          {
            Month: 'February',
            Revenue: 154375,
            Spend: 18767,
            Clicks: 4534,
            Conversions: 65,
            Reach: 434345,
            ROAS: 10,
          },
          {
            Month: 'March',
            Revenue: 445542,
            Spend: 164578,
            Clicks: 3746,
            Conversions: 77,
            Reach: 207093,
            ROAS: 21,
          },
          {
            Month: 'April',
            Revenue: 135379,
            Spend: 18014,
            Clicks: 3316,
            Conversions: 42,
            Reach: 196528,
            ROAS: 8,
          },
          {
            Month: 'May',
            Revenue: 107428,
            Spend: 15987,
            Clicks: 2370,
            Conversions: 12,
            Reach: 110201,
            ROAS: 7,
          },
          {
            Month: 'June',
            Revenue: 43546,
            Spend: 11192,
            Clicks: 1553,
            Conversions: 2,
            Reach: 69611,
            ROAS: 4,
          },
          {
            Month: 'July',
            Revenue: 35618,
            Spend: 10710,
            Clicks: 1571,
            Conversions: 1,
            Reach: 64377,
            ROAS: 3,
          },
          {
            Month: 'August',
            Revenue: 46830,
            Spend: 6502,
            Clicks: 798,
            Conversions: 6,
            Reach: 54195,
            ROAS: 7,
          },
          {
            Month: 'September',
            Revenue: 181792,
            Spend: 79351,
            Clicks: 2237,
            Conversions: 35,
            Reach: 176339,
            ROAS: 12,
          },
          {
            Month: 'October',
            Revenue: 111411,
            Spend: 18869,
            Clicks: 2571,
            Conversions: 20,
            Reach: 215607,
            ROAS: 6,
          },
          {
            Month: 'November',
            Revenue: 137390,
            Spend: 18756,
            Clicks: 2774,
            Conversions: 21,
            Reach: 210121,
            ROAS: 5,
          },
          {
            Month: 'December',
            Revenue: 45454,
            Spend: 45612,
            Clicks: 3216,
            Conversions: 24,
            Reach: 203307,
            ROAS: 11,
          },
          {
            Month: 'Total',
            Revenue: 1543530,
            Spend: 444079,
            Clicks: 33251,
            Conversions: 380,
            Reach: 2398513,
            ROAS: 3.5,
          },
        ],
      },
    ],
    meta: [
      {
        2024: [
          {
            Month: 'January',
            Revenue: 42589,
            Spend: 35741,
            Clicks: 12312,
            Conversions: 34,
            Reach: 46789,
            ROAS: 8,
          },
          {
            Month: 'February',
            Revenue: 18767,
            Spend: 96528,
            Clicks: 5675,
            Conversions: 13,
            Reach: 43445,
            ROAS: 22,
          },
          {
            Month: 'March',
            Revenue: 94365,
            Spend: 164578,
            Clicks: 78078,
            Conversions: 86,
            Reach: 20093,
            ROAS: 7,
          },
          {
            Month: 'April',
            Revenue: 85398,
            Spend: 18014,
            Clicks: 4563,
            Conversions: 53,
            Reach: 19628,
            ROAS: 9,
          },
          {
            Month: 'May',
            Revenue: 63988,
            Spend: 15987,
            Clicks: 46343,
            Conversions: 72,
            Reach: 10201,
            ROAS: 7,
          },
          {
            Month: 'June',
            Revenue: 43546,
            Spend: 11192,
            Clicks: 3453,
            Conversions: 40,
            Reach: 69611,
            ROAS: 8,
          },
          {
            Month: 'July',
            Revenue: 35618,
            Spend: 10710,
            Clicks: 6456,
            Conversions: 80,
            Reach: 64377,
            ROAS: 2,
          },
          {
            Month: 'August',
            Revenue: 78830,
            Spend: 6502,
            Clicks: 34534,
            Conversions: 7,
            Reach: 54195,
            ROAS: 3,
          },
          {
            Month: 'September',
            Revenue: 181792,
            Spend: 15397,
            Clicks: 3453,
            Conversions: 35,
            Reach: 17633,
            ROAS: 9,
          },
          {
            Month: 'October',
            Revenue: 11141,
            Spend: 18869,
            Clicks: 4565,
            Conversions: 20,
            Reach: 21560,
            ROAS: 4,
          },
          {
            Month: 'November',
            Revenue: 13739,
            Spend: 18756,
            Clicks: 2774,
            Conversions: 21,
            Reach: 21011,
            ROAS: 12,
          },
          {
            Month: 'December',
            Revenue: 45454,
            Spend: 45612,
            Clicks: 3216,
            Conversions: 24,
            Reach: 20330,
            ROAS: 7,
          },
          {
            Month: 'Total',
            Revenue: 45454,
            Spend: 45612,
            Clicks: 3216,
            Conversions: 24,
            Reach: 20330,
            ROAS: 7,
          },
        ],
      },
      {
        2023: [
          {
            Month: 'January',
            Revenue: 98765,
            Spend: 35741,
            Clicks: 4565,
            Conversions: 75,
            Reach: 456789,
            ROAS: 5,
          },
          {
            Month: 'February',
            Revenue: 154375,
            Spend: 18767,
            Clicks: 4534,
            Conversions: 65,
            Reach: 434345,
            ROAS: 10,
          },
          {
            Month: 'March',
            Revenue: 445542,
            Spend: 164578,
            Clicks: 3746,
            Conversions: 77,
            Reach: 207093,
            ROAS: 21,
          },
          {
            Month: 'April',
            Revenue: 135379,
            Spend: 18014,
            Clicks: 3316,
            Conversions: 42,
            Reach: 196528,
            ROAS: 8,
          },
          {
            Month: 'May',
            Revenue: 107428,
            Spend: 15987,
            Clicks: 2370,
            Conversions: 12,
            Reach: 110201,
            ROAS: 7,
          },
          {
            Month: 'June',
            Revenue: 43546,
            Spend: 11192,
            Clicks: 1553,
            Conversions: 2,
            Reach: 69611,
            ROAS: 4,
          },
          {
            Month: 'July',
            Revenue: 35618,
            Spend: 10710,
            Clicks: 1571,
            Conversions: 1,
            Reach: 64377,
            ROAS: 3,
          },
          {
            Month: 'August',
            Revenue: 46830,
            Spend: 6502,
            Clicks: 798,
            Conversions: 6,
            Reach: 54195,
            ROAS: 7,
          },
          {
            Month: 'September',
            Revenue: 181792,
            Spend: 79351,
            Clicks: 2237,
            Conversions: 35,
            Reach: 176339,
            ROAS: 12,
          },
          {
            Month: 'October',
            Revenue: 111411,
            Spend: 18869,
            Clicks: 2571,
            Conversions: 20,
            Reach: 215607,
            ROAS: 6,
          },
          {
            Month: 'November',
            Revenue: 137390,
            Spend: 18756,
            Clicks: 2774,
            Conversions: 21,
            Reach: 210121,
            ROAS: 5,
          },
          {
            Month: 'December',
            Revenue: 45454,
            Spend: 45612,
            Clicks: 3216,
            Conversions: 24,
            Reach: 203307,
            ROAS: 11,
          },
          {
            Month: 'Total',
            Revenue: 45454,
            Spend: 45612,
            Clicks: 3216,
            Conversions: 24,
            Reach: 203307,
            ROAS: 11,
          },
        ],
      },
    ],
  },
  yearlyInsights: {
    google: [
      {
        2023: [
          {
            Month: 'January',
            Revenue: 42589,
            Spend: 35741,
            Clicks: 12312,
            Conversions: 34,
            Reach: 46789,
            ROAS: 8,
          },
          {
            Month: 'February',
            Revenue: 18767,
            Spend: 96528,
            Clicks: 5675,
            Conversions: 13,
            Reach: 43445,
            ROAS: 22,
          },
          {
            Month: 'March',
            Revenue: 94365,
            Spend: 164578,
            Clicks: 78078,
            Conversions: 86,
            Reach: 20093,
            ROAS: 7,
          },
          {
            Month: 'April',
            Revenue: 85398,
            Spend: 18014,
            Clicks: 4563,
            Conversions: 53,
            Reach: 19628,
            ROAS: 9,
          },
          {
            Month: 'May',
            Revenue: 63988,
            Spend: 15987,
            Clicks: 46343,
            Conversions: 72,
            Reach: 10201,
            ROAS: 7,
          },
          {
            Month: 'June',
            Revenue: 43546,
            Spend: 11192,
            Clicks: 3453,
            Conversions: 40,
            Reach: 69611,
            ROAS: 8,
          },
          {
            Month: 'July',
            Revenue: 35618,
            Spend: 10710,
            Clicks: 6456,
            Conversions: 80,
            Reach: 64377,
            ROAS: 2,
          },
          {
            Month: 'August',
            Revenue: 78830,
            Spend: 6502,
            Clicks: 34534,
            Conversions: 7,
            Reach: 54195,
            ROAS: 3,
          },
          {
            Month: 'September',
            Revenue: 181792,
            Spend: 15397,
            Clicks: 3453,
            Conversions: 35,
            Reach: 17633,
            ROAS: 9,
          },
          {
            Month: 'October',
            Revenue: 11141,
            Spend: 18869,
            Clicks: 4565,
            Conversions: 20,
            Reach: 21560,
            ROAS: 4,
          },
          {
            Month: 'November',
            Revenue: 13739,
            Spend: 18756,
            Clicks: 2774,
            Conversions: 21,
            Reach: 21011,
            ROAS: 12,
          },
          {
            Month: 'December',
            Revenue: 45454,
            Spend: 45612,
            Clicks: 3216,
            Conversions: 24,
            Reach: 20330,
            ROAS: 7,
          },
        ],
      },
      {
        2022: [
          {
            Month: 'January',
            Revenue: 98765,
            Spend: 35741,
            Clicks: 4565,
            Conversions: 75,
            Reach: 456789,
            ROAS: 5,
          },
          {
            Month: 'February',
            Revenue: 154375,
            Spend: 18767,
            Clicks: 4534,
            Conversions: 65,
            Reach: 434345,
            ROAS: 10,
          },
          {
            Month: 'March',
            Revenue: 445542,
            Spend: 164578,
            Clicks: 3746,
            Conversions: 77,
            Reach: 207093,
            ROAS: 21,
          },
          {
            Month: 'April',
            Revenue: 135379,
            Spend: 18014,
            Clicks: 3316,
            Conversions: 42,
            Reach: 196528,
            ROAS: 8,
          },
          {
            Month: 'May',
            Revenue: 107428,
            Spend: 15987,
            Clicks: 2370,
            Conversions: 12,
            Reach: 110201,
            ROAS: 7,
          },
          {
            Month: 'June',
            Revenue: 43546,
            Spend: 11192,
            Clicks: 1553,
            Conversions: 2,
            Reach: 69611,
            ROAS: 4,
          },
          {
            Month: 'July',
            Revenue: 35618,
            Spend: 10710,
            Clicks: 1571,
            Conversions: 1,
            Reach: 64377,
            ROAS: 3,
          },
          {
            Month: 'August',
            Revenue: 46830,
            Spend: 6502,
            Clicks: 798,
            Conversions: 6,
            Reach: 54195,
            ROAS: 7,
          },
          {
            Month: 'September',
            Revenue: 181792,
            Spend: 79351,
            Clicks: 2237,
            Conversions: 35,
            Reach: 176339,
            ROAS: 12,
          },
          {
            Month: 'October',
            Revenue: 111411,
            Spend: 18869,
            Clicks: 2571,
            Conversions: 20,
            Reach: 215607,
            ROAS: 6,
          },
          {
            Month: 'November',
            Revenue: 137390,
            Spend: 18756,
            Clicks: 2774,
            Conversions: 21,
            Reach: 210121,
            ROAS: 5,
          },
          {
            Month: 'December',
            Revenue: 45454,
            Spend: 45612,
            Clicks: 3216,
            Conversions: 24,
            Reach: 203307,
            ROAS: 11,
          },
        ],
      },
    ],
    meta: [
      {
        2023: [
          {
            Month: 'January',
            Revenue: 42589,
            Spend: 35741,
            Clicks: 12312,
            Conversions: 34,
            Reach: 46789,
            ROAS: 8,
          },
          {
            Month: 'February',
            Revenue: 18767,
            Spend: 96528,
            Clicks: 5675,
            Conversions: 13,
            Reach: 43445,
            ROAS: 22,
          },
          {
            Month: 'March',
            Revenue: 94365,
            Spend: 164578,
            Clicks: 78078,
            Conversions: 86,
            Reach: 20093,
            ROAS: 7,
          },
          {
            Month: 'April',
            Revenue: 85398,
            Spend: 18014,
            Clicks: 4563,
            Conversions: 53,
            Reach: 19628,
            ROAS: 9,
          },
          {
            Month: 'May',
            Revenue: 63988,
            Spend: 15987,
            Clicks: 46343,
            Conversions: 72,
            Reach: 10201,
            ROAS: 7,
          },
          {
            Month: 'June',
            Revenue: 43546,
            Spend: 11192,
            Clicks: 3453,
            Conversions: 40,
            Reach: 69611,
            ROAS: 8,
          },
          {
            Month: 'July',
            Revenue: 35618,
            Spend: 10710,
            Clicks: 6456,
            Conversions: 80,
            Reach: 64377,
            ROAS: 2,
          },
          {
            Month: 'August',
            Revenue: 78830,
            Spend: 6502,
            Clicks: 34534,
            Conversions: 7,
            Reach: 54195,
            ROAS: 3,
          },
          {
            Month: 'September',
            Revenue: 181792,
            Spend: 15397,
            Clicks: 3453,
            Conversions: 35,
            Reach: 17633,
            ROAS: 9,
          },
          {
            Month: 'October',
            Revenue: 11141,
            Spend: 18869,
            Clicks: 4565,
            Conversions: 20,
            Reach: 21560,
            ROAS: 4,
          },
          {
            Month: 'November',
            Revenue: 13739,
            Spend: 18756,
            Clicks: 2774,
            Conversions: 21,
            Reach: 21011,
            ROAS: 12,
          },
          {
            Month: 'December',
            Revenue: 45454,
            Spend: 45612,
            Clicks: 3216,
            Conversions: 24,
            Reach: 20330,
            ROAS: 7,
          },
        ],
      },
      {
        2022: [
          {
            Month: 'January',
            Revenue: 98765,
            Spend: 35741,
            Clicks: 4565,
            Conversions: 75,
            Reach: 456789,
            ROAS: 5,
          },
          {
            Month: 'February',
            Revenue: 154375,
            Spend: 18767,
            Clicks: 4534,
            Conversions: 65,
            Reach: 434345,
            ROAS: 10,
          },
          {
            Month: 'March',
            Revenue: 445542,
            Spend: 164578,
            Clicks: 3746,
            Conversions: 77,
            Reach: 207093,
            ROAS: 21,
          },
          {
            Month: 'April',
            Revenue: 135379,
            Spend: 18014,
            Clicks: 3316,
            Conversions: 42,
            Reach: 196528,
            ROAS: 8,
          },
          {
            Month: 'May',
            Revenue: 107428,
            Spend: 15987,
            Clicks: 2370,
            Conversions: 12,
            Reach: 110201,
            ROAS: 7,
          },
          {
            Month: 'June',
            Revenue: 43546,
            Spend: 11192,
            Clicks: 1553,
            Conversions: 2,
            Reach: 69611,
            ROAS: 4,
          },
          {
            Month: 'July',
            Revenue: 35618,
            Spend: 10710,
            Clicks: 1571,
            Conversions: 1,
            Reach: 64377,
            ROAS: 3,
          },
          {
            Month: 'August',
            Revenue: 46830,
            Spend: 6502,
            Clicks: 798,
            Conversions: 6,
            Reach: 54195,
            ROAS: 7,
          },
          {
            Month: 'September',
            Revenue: 181792,
            Spend: 79351,
            Clicks: 2237,
            Conversions: 35,
            Reach: 176339,
            ROAS: 12,
          },
          {
            Month: 'October',
            Revenue: 111411,
            Spend: 18869,
            Clicks: 2571,
            Conversions: 20,
            Reach: 215607,
            ROAS: 6,
          },
          {
            Month: 'November',
            Revenue: 137390,
            Spend: 18756,
            Clicks: 2774,
            Conversions: 21,
            Reach: 210121,
            ROAS: 5,
          },
          {
            Month: 'December',
            Revenue: 45454,
            Spend: 45612,
            Clicks: 3216,
            Conversions: 24,
            Reach: 203307,
            ROAS: 11,
          },
        ],
      },
    ],
  },
  quickStats: {
    google: {
      clicks: 41228,
      marketing_cost: 224470,
      online_sales: 1673162,
      conversion_rate: 0.014,
      conversions: 590,
      roas: 7.5,
    },
    meta: {
      clicks: 161641,
      marketing_cost: 216959,
      online_sales: 1013723,
      reach: 480067,
      conversions: 2282,
      roas: 4.9,
    },
  },
  shoppingProductsInsights: {
    google: [
      {
        Id: '1',
        'Image Link': DummyImg1,
        'Product Link': '',
        Availability: 'in stock',
        Title: 'Face Serum',
        'Conversion Value': 99211.32,
        Cost: 15428.4,
        ROAS: 6.430434783,
        Clicks: 2236,
        Conversions: 40.248,
      },
      {
        Id: '2',
        'Image Link': DummyImg2,
        'Product Link': '',
        Availability: 'out of stock',
        Title: 'Body Serum',
        'Conversion Value': 412525.982,
        Cost: 55548.92,
        ROAS: 7.42635468,
        Clicks: 6841,
        Conversions: 150.502,
      },
      {
        Id: '3',
        'Image Link': DummyImg3,
        'Product Link': '',
        Availability: 'in stock',
        Title: 'Moisturizer',
        'Conversion Value': 1761.268,
        Cost: 2151.1,
        ROAS: 0.8187755102,
        Clicks: 439,
        Conversions: 0.878,
      },
      {
        Id: '4',
        'Image Link': DummyImg4,
        'Product Link': '',
        Availability: 'out of stock',
        Title: 'Hair Cream',
        'Conversion Value': 60354.8,
        Cost: 2175.8,
        ROAS: 27.73913043,
        Clicks: 946,
        Conversions: 27.434,
      },
      {
        Id: '5',
        'Image Link': DummyImg5,
        'Product Link': '',
        Availability: 'in stock',
        Title: 'Facewash',
        'Conversion Value': 65900.016,
        Cost: 17469.9,
        ROAS: 3.77220339,
        Clicks: 2961,
        Conversions: 38.493,
      },
      {
        Id: '6',
        'Image Link': DummyImg6,
        'Product Link': '',
        Availability: 'in stock',
        Title: 'Skin Tightening cream',
        'Conversion Value': 135434.607,
        Cost: 12878.2,
        ROAS: 10.51657895,
        Clicks: 3389,
        Conversions: 71.169,
      },
      {
        Id: '7',
        'Image Link': DummyImg6,
        'Product Link': '',
        Availability: 'in stock',
        Title: 'Night Cream',
        'Conversion Value': 53498.94,
        Cost: 34634.3,
        ROAS: 1.544680851,
        Clicks: 7369,
        Conversions: 29.476,
      },
      {
        Id: '8',
        'Image Link': DummyImg6,
        'Product Link': '',
        Availability: 'in stock',
        Title: 'Moisturizing Kit',
        'Conversion Value': 0,
        Cost: 4178.8,
        ROAS: 0,
        Clicks: 1348,
        Conversions: 0,
      },
      {
        Id: '',
        'Image Link': '',
        'Product Link': '',
        Availability: '',
        Title: 'Total',
        'Conversion Value': 828687,
        Cost: 144465,
        ROAS: 5.7,
        Clicks: 25529,
        Conversions: 358,
      },
    ],
  },
  topProducts: [
    {
      item_id: '37201',
      product_link: '#',
      imageLink: DummyImg1,
      metricName: 'Conversion Rate',
      value: 34300,
      highest: true,
    },
    {
      item_id: '37202',
      product_link: '#',
      imageLink: DummyImg2,
      metricName: 'ROI',
      value: 84072,
      highest: true,
    },
    {
      item_id: '37203',
      product_link: '#',
      imageLink: DummyImg3,
      metricName: 'Conversions',
      value: 34300,
      highest: true,
    },
    {
      item_id: '37204',
      product_link: '#',
      imageLink: DummyImg4,
      metricName: 'Clicks',
      value: 15000,
      highest: true,
    },
    {
      item_id: '37205',
      product_link: '#',
      imageLink: DummyImg5,
      metricName: 'Revenue',
      value: 34300,
      highest: true,
    },
    {
      item_id: '37206',
      product_link: '#',
      imageLink: DummyImg6,
      metricName: 'Clicks',
      value: 10001,
      highest: true,
    },
  ],
  userByAge: {
    google: [
      {
        'Age Group': '18-24',
        Cost: 12001,
        Clicks: 334,
        Conversions: 27,
        Revenue: 39933,
        ROAS: 3.3,
      },
      {
        'Age Group': '25-34',
        Cost: 7862,
        Clicks: 218,
        Conversions: 18,
        Revenue: 26622,
        ROAS: 3.3,
      },
      {
        'Age Group': '35-44',
        Cost: 4552,
        Clicks: 126,
        Conversions: 6,
        Revenue: 8874,
        ROAS: 1.9,
      },
      {
        'Age Group': '45-54',
        Cost: 2896,
        Clicks: 81,
        Conversions: 2,
        Revenue: 2958,
        ROAS: 1.0,
      },
      {
        'Age Group': '55-64',
        Cost: 3310,
        Clicks: 92,
        Conversions: 3,
        Revenue: 4437,
        ROAS: 1.3,
      },
      {
        'Age Group': '65+',
        Cost: 2482,
        Clicks: 68,
        Conversions: 4,
        Revenue: 5916,
        ROAS: 2.3,
      },
      {
        'Age Group': 'Unknown',
        Cost: 8276,
        Clicks: 9,
        Conversions: 0,
        Revenue: 29632,
        ROAS: 3.5,
      },
      {
        'Age Group': 'Total',
        Cost: 41379,
        Clicks: 928,
        Conversions: 60,
        Revenue: 118372,
        ROAS: 2.9,
      },
    ],
    meta: [
      {
        'Age Group': '18-24',
        Reach: 12001,
        Cost: 334,
        Clicks: 27,
        Impressions: 39933,
        CPC: 3.3,
      },
      {
        'Age Group': '25-34',
        Reach: 7862,
        Cost: 218,
        Clicks: 18,
        Impressions: 26622,
        CPC: 3.3,
      },
      {
        'Age Group': '35-44',
        Reach: 4552,
        Cost: 126,
        Clicks: 6,
        Impressions: 8874,
        CPC: 1.9,
      },
      {
        'Age Group': '45-54',
        Reach: 2896,
        Cost: 81,
        Clicks: 2,
        Impressions: 2958,
        CPC: 1.0,
      },
      {
        'Age Group': '55-64',
        Reach: 3310,
        Cost: 92,
        Clicks: 3,
        Impressions: 4437,
        CPC: 1.3,
      },
      {
        'Age Group': '65+',
        Reach: 2482,
        Cost: 68,
        Clicks: 4,
        Impressions: 5916,
        CPC: 2.3,
      },
      {
        'Age Group': 'Unknown',
        Reach: 8276,
        Cost: 9,
        Clicks: 0,
        Impressions: 29632,
        CPC: 3.5,
      },
      {
        'Age Group': 'Total',
        Reach: 41379,
        Cost: 928,
        Clicks: 60,
        Impressions: 118372,
        CPC: 2.9,
      },
    ],
  },
  userByDevice: {
    google: [
      {
        Device: 'MOBILE',
        Cost: 37637,
        Clicks: 1045,
        Conversions: 67,
        Revenue: 99136.55,
        ROAS: 2.6,
      },
      {
        Device: 'DESKTOP',
        Cost: 3346,
        Clicks: 93,
        Conversions: 13,
        Revenue: 19235.45,
        ROAS: 5.7,
      },
      {
        Device: 'OTHER',
        Cost: 400,
        Clicks: 11,
        Conversions: 0,
        Revenue: 0,
        ROAS: 0,
      },
      {
        Device: 'Total',
        Cost: 41383,
        Clicks: 1149,
        Conversions: 80,
        Revenue: 118372,
        ROAS: 2.8,
      },
    ],
    meta: [
      {
        Device: 'MOBILE',
        Reach: 37637,
        Cost: 1045,
        Clicks: 67,
        Impressions: 99136.55,
        CPC: 2.6,
      },
      {
        Device: 'DESKTOP',
        Reach: 3346,
        Cost: 93,
        Clicks: 13,
        Impressions: 19235.45,
        CPC: 5.7,
      },
      {
        Device: 'OTHER',
        Reach: 400,
        Cost: 11,
        Clicks: 0,
        Impressions: 0,
        CPC: 0,
      },
      {
        Device: 'Total',
        Reach: 41383,
        Cost: 1149,
        Clicks: 80,
        Impressions: 118372,
        CPC: 2.8,
      },
    ],
  },
  userByGender: {
    google: [
      {
        Gender: 'MALE',
        Cost: 12528,
        Clicks: 348,
        Conversions: 42,
        Revenue: 62118,
        ROAS: 4.9,
      },
      {
        Gender: 'FEMALE',
        Cost: 24912,
        Clicks: 692,
        Conversions: 35,
        Revenue: 41175,
        ROAS: 2.0,
      },
      {
        Gender: 'UNDETERMINED',
        Cost: 3943,
        Clicks: 105,
        Conversions: 3,
        Revenue: 4489,
        ROAS: 1.1,
      },
      {
        Gender: 'Total',
        Cost: 41383,
        Clicks: 1145,
        Conversions: 803,
        Revenue: 107782,
        ROAS: 2.6,
      },
    ],
    meta: [
      {
        Gender: 'MALE',
        Cost: 12528,
        Clicks: 348,
        Conversions: 42,
        Revenue: 62118,
        ROAS: 4.9,
      },
      {
        Gender: 'FEMALE',
        Cost: 24912,
        Clicks: 692,
        Conversions: 35,
        Revenue: 41175,
        ROAS: 2.0,
      },
      {
        Gender: 'UNDETERMINED',
        Cost: 3943,
        Clicks: 105,
        Conversions: 3,
        Revenue: 4489,
        ROAS: 1.1,
      },
      {
        Gender: 'Total',
        Cost: 41383,
        Clicks: 1145,
        Conversions: 803,
        Revenue: 107782,
        ROAS: 2.6,
      },
    ],
  },
  userByLocation: {
    google: [
      {
        region_name: 'Gossau',
        online_sales: 439373.925,
        marketing_cost: 27330.9,
        roas: 16.07608696,
        clicks: 3961,
        conversions: 178.245,
        latitude: '47.4155906',
        longitude: '9.249359',
        'Criteria Id': '1',
      },
      {
        region_name: 'St. Gallen',
        online_sales: 77806.026,
        marketing_cost: 76831.44,
        roas: 1.012684729,
        clicks: 9462,
        conversions: 28.386,
        latitude: '47.4250593',
        longitude: '9.3765878',
        'Criteria Id': '2',
      },
      {
        region_name: 'Zurich',
        online_sales: 880.634,
        marketing_cost: 2151.1,
        roas: 0.4093877551,
        clicks: 439,
        conversions: 0.439,
        latitude: '47.3744489',
        longitude: '8.5410422',
        'Criteria Id': '3',
      },
      {
        region_name: 'Geneva',
        online_sales: 144144,
        marketing_cost: 3864,
        roas: 37.30434783,
        clicks: 1680,
        conversions: 65.52,
        latitude: '46.2017559',
        longitude: '6.1466014',
        'Criteria Id': '4',
      },
      {
        region_name: 'Hamburg',
        online_sales: 247147.744,
        marketing_cost: 29370.2,
        roas: 8.414915254,
        clicks: 4978,
        conversions: 144.362,
        latitude: '53.550341',
        longitude: '10.000654',
        'Criteria Id': '5',
      },
      {
        region_name: 'Munich',
        online_sales: 35760.2245,
        marketing_cost: 20402.2,
        roas: 1.752763158,
        clicks: 5369,
        conversions: 18.7915,
        latitude: '48.1371079',
        longitude: '11.5753822',
        'Criteria Id': '6',
      },
      {
        region_name: 'Frankfurt',
        online_sales: 0,
        marketing_cost: 43780.5,
        roas: 0,
        clicks: 9315,
        conversions: 0,
        latitude: '50.1106444',
        longitude: '8.6820917',
        'Criteria Id': '7',
      },
      {
        region_name: 'Lausanne',
        online_sales: 221351.04,
        marketing_cost: 16467.2,
        roas: 13.44193548,
        clicks: 5312,
        conversions: 95.616,
        latitude: '46.5218269',
        longitude: '6.6327025',
        'Criteria Id': '8',
      },
      {
        region_name: 'Total',
        online_sales: 1166464,
        marketing_cost: 220198,
        roas: 5.3,
        clicks: 40516,
        conversions: 531,
        latitude: '46.5218269',
        longitude: '6.6327025',
        'Criteria Id': '',
      },
    ],
    meta: [
      {
        Region: 'Gossau',
        Spend: 2330.9,
        Clicks: 3961,
        Reach: 20011,
        Impressions: 50011,
        Cpc: 6.9,
        latitude: '47.4155906',
        longitude: '9.249359',
      },
      {
        Region: 'St. Gallen',
        Spend: 5683.44,
        Clicks: 9462,
        Reach: 62330,
        Impressions: 120001,
        Cpc: 8.12,
        latitude: '47.4250593',
        longitude: '9.3765878',
      },
      {
        Region: 'Zurich',
        Spend: 2151.1,
        Clicks: 439,
        Reach: 66304,
        Impressions: 90111,
        Cpc: 4.9,
        latitude: '47.3744489',
        longitude: '8.5410422',
      },
      {
        Region: 'Geneva',
        Spend: 3864,
        Clicks: 1680,
        Reach: 30202,
        Impressions: 100554,
        Cpc: 2.3,
        latitude: '46.2017559',
        longitude: '6.1466014',
      },
      {
        Region: 'Hamburg',
        Spend: 29370.2,
        Clicks: 4978,
        Reach: 14847,
        Impressions: 176442,
        Cpc: 5.9,
        latitude: '53.550341',
        longitude: '10.000654',
      },
      {
        Region: 'Munich',
        Spend: 20402.2,
        Clicks: 5369,
        Reach: 172938,
        Impressions: 309821,
        Cpc: 3.8,
        latitude: '48.1371079',
        longitude: '11.5753822',
      },
      {
        Region: 'Frankfurt',
        Spend: 43780.5,
        Clicks: 9315,
        Reach: 294482,
        Impressions: 401871,
        Cpc: 4.7,
        latitude: '50.1106444',
        longitude: '8.6820917',
      },
      {
        Region: 'Lausanne',
        Spend: 16467.2,
        Clicks: 5312,
        Reach: 80706,
        Impressions: 156696,
        Cpc: 3.1,
        latitude: '46.5218269',
        longitude: '6.6327025',
      },
      {
        Region: 'Total',
        Reach: 741820,
        Spend: 124049.54,
        Clicks: 40516,
        Impressions: 1405507,
        Cpc: 5,
        latitude: '46.5218269',
        longitude: '6.6327025',
      },
    ],
  },
};

export { data };
