/* eslint-disable consistent-return */
/* eslint-disable max-classes-per-file */
import React, { useEffect, useState, useContext } from 'react';
import { Box } from '@mui/material';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
// import { authInstance } from '../api/authentication.instance';
import { openInstance } from '../api/open.instance';
// import { shopifyURI, nonAdhelpUserShopifyURI } from '../constants/endpoints';
import { nonAdhelpUserShopifyURI } from '../constants/endpoints';

import { ErrorAlert, SuccessAlert } from '../widgets/NewAlerts/ErrorAlert';
import { GlobalLoadingComponent } from '../components/GlobalLoadingComponent';
import { UserInfoContext } from '../context/UserInfoContext';

// Shopify Service for Adhelp User
// class ShopifyService {
//   static fetchShopifyAuthURI = ({ shopUrl }) =>
//     authInstance.get(shopifyURI, {
//       params: {
//         shop_url: shopUrl,
//       },
//     });

//   static handleOauth2Callback = (authCode, hmac, host, shopURL, timestamp) => {
//     const data = {
//       code: authCode,
//       hmac,
//       host,
//       shop_url: shopURL,
//       timestamp,
//     };
//     return authInstance.post(shopifyURI, data);
//   };
// }

const createPopup = (authorizationUrl) => {
  const popup = window.open(
    authorizationUrl,
    '_parent',
    'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, width=700, height=900, left=500, top=300, titlebar=no, popup=true'
  );
  return popup;
};

// const ShopifyOAuth2FlowComponent = (shopUrl) => {
//   ShopifyService.fetchShopifyAuthURI({ shopUrl })
//     .then((resp) => {
//       if (resp.status === 200) {
//         const authorizationUrl = resp.data?.authentication_url;
//         if (authorizationUrl) {
//           createPopup(authorizationUrl);
//         }
//       }
//     })
//     .catch(() => {
//       alert('Error while creating authentication URL');
//     });
// };
// const Oauth2CallBackShopifyComponent = () => {
//   const [searchParams] = useSearchParams();
//   const [successState, setSuccessState] = useState(false);
//   const [errorState, setErrorState] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');
//   const navigate = useNavigate();

//   useEffect(() => {
//     let unsubscribed = false;
//     const abortController = new AbortController();

//     if (!unsubscribed) {
//       const code = searchParams.get('code');
//       const hmac = searchParams.get('hmac');
//       const host = searchParams.get('host');
//       const shop = searchParams.get('shop');
//       const timestamp = searchParams.get('timestamp');

//       ShopifyService.handleOauth2Callback(code, hmac, host, shop, timestamp, {
//         signal: abortController.signal,
//       })
//         .then((response) => {
//           if (response.status === 200) {
//             setSuccessState(() => true);
//             setSuccessMessage(() => 'Shopify Consent completed successfully!');
//             setTimeout(() => navigate('/accounts'), 2000);
//           }
//         })
//         .catch(() => {
//           setErrorState(() => true);
//           setErrorMessage(() => 'Error while completing shopify concent');
//           setTimeout(() => window.close(), 2000);
//         });
//     }

//     return () => {
//       unsubscribed = true;
//       abortController.abort();
//     };
//   }, [searchParams, navigate]);

//   return (
//     <Box
//       sx={{
//         display: 'grid',
//         placeItems: 'center',
//         placeContent: 'center',
//         minHeight: '100vh',
//       }}
//     >
//       {!successState && !errorState && <GlobalLoadingComponent />}
//       {errorState && <ErrorAlert message={errorMessage} />}
//       {successState && <SuccessAlert message={successMessage} />}
//     </Box>
//   );
// };

// Shopify Service for Non-Adhelp User
class ShopifyServiceNonAdhelpUser {
  static redirectToAdhelp = (urlSearch) =>
    openInstance.get(`${nonAdhelpUserShopifyURI}${urlSearch}`);

  static handleRedirect2Callback = (
    code,
    hmac,
    host,
    shop,
    state,
    timestamp
  ) => {
    const data = {
      code,
      hmac,
      host,
      shop,
      state,
      timestamp,
    };
    return openInstance.post(nonAdhelpUserShopifyURI, data);
  };
}

const RedirectToadhelpFromShopify = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { isLoggedIn } = useContext(UserInfoContext);

  const [successState, setSuccessState] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const code = searchParams.get('code');
  const hmac = searchParams.get('hmac');
  const host = searchParams.get('host');
  const shop = searchParams.get('shop');
  const state = searchParams.get('state');
  const timestamp = searchParams.get('timestamp');

  useEffect(() => {
    ShopifyServiceNonAdhelpUser.handleRedirect2Callback(
      code,
      hmac,
      host,
      shop,
      state,
      timestamp
    )
      .then((response) => {
        if (response.status === 200) {
          setSuccessState(() => true);
          localStorage.setItem(
            'ShopifyUserData',
            JSON.stringify(response?.data)
          );
          setSuccessMessage(
            () => 'Shopify App Install Completed Successfully!'
          );
          setTimeout(
            () => (!isLoggedIn ? navigate('/signup') : navigate('/signin')),
            3000
          );
        }
      })
      .catch(() => {
        setErrorState(() => true);
        setErrorMessage(() => 'Error while completing shopify app install.');
      });
  }, [searchParams]);

  return (
    <Box
      sx={{
        display: 'grid',
        placeItems: 'center',
        placeContent: 'center',
        minHeight: '100vh',
      }}
    >
      {!successState && !errorState && <GlobalLoadingComponent />}
      {errorState && <ErrorAlert message={errorMessage} />}
      {successState && <SuccessAlert message={successMessage} />}
    </Box>
  );
};

const Shopify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [errorState, setErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    let unsubscribed = false;
    const abortController = new AbortController();

    if (!unsubscribed) {
      const urlSearch = location?.search;
      ShopifyServiceNonAdhelpUser.redirectToAdhelp(urlSearch, {
        signal: abortController.signal,
      })
        .then((response) => {
          if (response.status === 200) {
            const authorizationUrl = response?.data?.authentication_url;
            if (authorizationUrl) {
              createPopup(authorizationUrl);
            }
          }
        })
        .catch(() => {
          setErrorState(() => true);
          setErrorMessage(() => 'Error while completing shopify concent');
        });
    }

    return () => {
      unsubscribed = true;
      abortController.abort();
    };
  }, [navigate]);

  return (
    <Box
      sx={{
        display: 'grid',
        placeItems: 'center',
        placeContent: 'center',
        minHeight: '100vh',
      }}
    >
      {!errorState && <GlobalLoadingComponent />}
      {errorState && <ErrorAlert message={errorMessage} />}
    </Box>
  );
};
export {
  // ShopifyService,
  ShopifyServiceNonAdhelpUser,
  // Oauth2CallBackShopifyComponent,
  // ShopifyOAuth2FlowComponent,
  RedirectToadhelpFromShopify,
  Shopify,
};
