import { useField, useFormikContext } from 'formik';
import React from 'react';
import { valueMap } from '../maps/valueMap';
import { SelectInput } from '../../../../../../../components/Inputs/SelectInput';
import { methodOptions, GTINMethodOptions } from './methodOptions';

const MethodSelector = ({ name, fieldPath, valuePath, fields }) => {
  const [{ value }] = useField(fieldPath);
  const { setFieldValue } = useFormikContext();

  const handleChange = (event) => {
    // reset values based on the new method value
    if (
      event.target.value &&
      ['DO_NOTHING', 'EXCLUDE'].includes(event.target.value)
    ) {
      setFieldValue(valuePath, null);
    } else {
      setFieldValue(valuePath, valueMap(fields)[event.target.value]);
    }
  };

  if (!['gtin', 'all fields'].includes(value)) {
    return (
      <SelectInput
        options={methodOptions}
        name={name}
        optionValueCallback={(field) => field.value}
        optionContentCallback={(field) => field.name}
        label='and'
        labelIsAdjacent
        id={name}
        customOnChange={handleChange}
        sx={{
          width: '100%',
          '@media(min-width:800px)': {
            width: 'auto',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              flex: 'initial',
              width: '300px',
              maxWidth: '100%',
            },
          },
        }}
      />
    );
  }

  if (value === 'gtin') {
    return (
      <SelectInput
        options={GTINMethodOptions}
        name={name}
        optionValueCallback={(field) => field.value}
        optionContentCallback={(field) => field.name}
        label='and'
        placeholder='select a method'
        id={name}
        labelIsAdjacent
        customOnChange={handleChange}
        sx={{
          width: '100%',
          '@media(min-width:800px)': {
            width: 'auto',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              flex: 'initial',
              width: '300px',
              maxWidth: '100%',
            },
          },
        }}
      />
    );
  }
  if (value === 'all fields') {
    return (
      <SelectInput
        options={[
          { value: 'DO_NOTHING', name: 'do nothing' },
          { value: 'EXCLUDE', name: 'exclude' },
        ]}
        name={name}
        optionValueCallback={(field) => field.value}
        optionContentCallback={(field) => field.name}
        label='and'
        placeholder='select a method'
        id={name}
        labelIsAdjacent
        customOnChange={handleChange}
        sx={{
          width: '100%',
          '@media(min-width:800px)': {
            width: 'auto',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              flex: 'initial',
              width: '300px',
              maxWidth: '100%',
            },
          },
        }}
      />
    );
  }

  return null;
};

export { MethodSelector };
