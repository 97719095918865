import { styled } from '@mui/system';

// MUI imports
import { Box, Paper } from '@mui/material';

const StyledMainBox = styled(Box)`
  background-color: #f8f8f9;
  width: 100%;
  min-height: 450px;
  border: 1px solid #dedede;
  padding-bottom: 15px;
`;

const StyledPaper = styled(Paper)(
  ({ theme }) => `
  background-color: ${theme.palette.background.main};
  padding: 24px;
  border-radius: 0px 0px 8px 8px !important;
  box-shadow: 0px 4px 10px rgba(0,0,0,.08);
  margin-bottom: 40px;
`
);

export { StyledMainBox, StyledPaper };
