/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Stack, Tooltip, Typography } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { DynamicSelectBox } from '../GlobalComponents/DynamicSelectBox';
import { StyledBox } from '../StyledComponents/StyledImportFileForm';
import { useImportActionMutation, useImportFilterMutation } from '../../../../api/RTK/services/feedFeed';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { DataSendingModalBaseRTK } from '../../../../components/DataSendingModalBaseRTK';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';


const ImportModal = ({ openImport, onClose, importData, importType }) => {
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedFeed, setSelectedFeed] = useState('');
    const [feedList, setFeedList] = useState([]);
    const [actionList, setActionList] = useState([]);
    const [selectedActions, setSelectedActions] = useState([]);
    const { company } = useContext(UserInfoContext);

    // fetching project id & feed id stored in redux store
    const projectSelectId = useSelector(
        (state) => state.allProjectsRedux.projectId
    );
    const feedId = useSelector((state) => state?.feedSummaryRedux?.feedId);
    // filter out projects with no feed
    const projectList = importData?.filter(item => item.feeds.some(feed => feed.feed_name && feed.feed_name.length > 0))?.map(item => ({ value: item.project, title: item.project }));

    useEffect(() => {
        setSelectedFeed('');
        setActionList([]);
        const allFeeds = importData?.filter(item => item.project === selectedProject)?.flatMap(item => item.feeds);
        const uniqueFeeds = [...new Set(allFeeds)]?.map(feed => ({ value: feed?.feed_name, title: feed?.feed_name }));
        setFeedList(uniqueFeeds);
    }, [selectedProject, importData]);

    const getActionsForSelectedFeed = () => {
        if (selectedProject && selectedFeed) {
            const selectedFeedData = importData?.find(item => item.project === selectedProject)?.feeds?.find(feed => feed.feed_name === selectedFeed);
            const actions = selectedFeedData?.imports || [];
            setActionList(actions);
        }
    };

    useEffect(() => {
        if (!selectedProject || !selectedFeed) {
            setActionList([]);
        } else {
            getActionsForSelectedFeed();
        }
    }, [selectedFeed, selectedProject, importData]);

    const handleActionChange = (event) => {
        const { value, checked } = event.target;
        setSelectedActions(prev =>
            checked
                ? [...prev, value]
                : prev.filter(id => id !== value)
        );
    };

    const [importAction, { isLoading, isError, isSuccess, reset, isUninitialized }] = useImportActionMutation();

    const [importFilter, { isLoading: filterLoading, isError: filterError, isSuccess: filterSuccess, reset: filterReset, isUninitialized: filterUninitialized }] = useImportFilterMutation();

    return (

        <DataSendingModalBaseRTK
            open={openImport}
            handleCloseModal={onClose}
            isLoading={isLoading || filterLoading}
            isError={isError || filterError}
            isSuccess={isSuccess || filterSuccess}
            isUninitialized={isUninitialized || filterUninitialized}
            successMessage='Imported successfully'
            errorMessage='Import failed'
            reset={() => {
                reset();
                filterReset();
            }}
            sx={{ textAlign: 'left', height: '400px' }}
        >
            <HeadingWithUnderline heading={importType === 'ACTION' ? 'Import Actions' : 'Import Filters'} />
            <FormGroup
                sx={{ padding: '0px 4px', zIndex: 0 }}
            >
                <Formik
                    initialValues={{
                        project: '',
                        feeds: '',
                        action: false
                    }}
                    enableReinitialize
                    onSubmit={({ resetForm }) => {
                        if (importType === 'ACTION') {
                            importAction({
                                companyId: company?.id,
                                projectId: projectSelectId,
                                feedId,
                                data: {
                                    data: selectedActions
                                }
                            });
                        } else if (importType === 'FILTER') {
                            importFilter({
                                companyId: company?.id,
                                projectId: projectSelectId,
                                feedId,
                                data: {
                                    data: selectedActions
                                }
                            });
                        }
                        setActionList([]);
                        setSelectedActions([]);
                        resetForm();
                    }}
                >
                    {({ values,
                        handleBlur, errors, touched, setFieldValue }) => (
                        <Form>
                            <StyledBox
                                className='importSelectForm'
                                sx={{ padding: '0rem !important' }}
                            >
                                <DynamicSelectBox
                                    inputTitle='From Project'
                                    name='project'
                                    placeholderText='Select project'
                                    value={values.project}
                                    selectedItem={projectList}
                                    handleChange={(event) => {
                                        const { value } = event.target;
                                        setFieldValue('project', value);
                                        setSelectedProject(value);
                                        setSelectedFeed('');
                                        setFieldValue('feeds', '');
                                    }}
                                    handleBlur={handleBlur}
                                    error={
                                        errors.project && touched.project ? (
                                            <Box>{errors.project}</Box>
                                        ) : (
                                            ''
                                        )
                                    }
                                />
                            </StyledBox>
                            <StyledBox
                                className='importSelectForm'
                                sx={{ padding: '0rem !important', marginBottom: '16px' }}
                            >
                                <DynamicSelectBox
                                    inputTitle='From Feed'
                                    name='feeds'
                                    placeholderText='Select feed'
                                    value={values.feeds}
                                    disabled={!selectedProject}
                                    selectedItem={feedList}
                                    handleChange={(event) => {
                                        const { value } = event.target;
                                        setFieldValue('feeds', value);
                                        setSelectedFeed(value);
                                    }}
                                    handleBlur={handleBlur}
                                    error={
                                        errors.feeds && touched.feeds ? (
                                            <Box>{errors.feeds}</Box>
                                        ) : (
                                            ''
                                        )
                                    }
                                />
                            </StyledBox>
                            <Stack direction='column' sx={{ paddingBottom: '32px' }}>
                                <Typography variant='bodyMedium'>
                                    {importType === 'ACTION' ? 'Select actions to import' : 'Select filters to import'}</Typography>


                                {importType === 'ACTION' && (values?.feeds) && (actionList?.length === 0) ? <Typography variant='bodyMedium' color='error'>No actions available for this feed</Typography>
                                    : importType === 'FILTER' && (values?.feeds) && (actionList?.length === 0) ? <Typography variant='bodyMedium' color='error'>No filters available for this feed</Typography>
                                        : null
                                }

                                {(actionList?.length > 0) && actionList?.map(action => (
                                    <Stack direction='row' alignItems='center'>
                                        <FormControlLabel
                                            key={action.id}
                                            control={
                                                <Checkbox
                                                    name='action'
                                                    value={action.id}
                                                    onChange={handleActionChange}
                                                    checked={selectedActions.includes(action.id)}
                                                    disabled={!action.importable}
                                                    sx={{ padding: '6px' }}
                                                />
                                            }
                                            label={`${action.name}`}
                                        />
                                        <Tooltip
                                            title={!action.importable ? 'Some fields are not present in this feed because of which it cannot be imported' : action.description}
                                            arrow
                                            PopperProps={{
                                                sx: {
                                                    '&.MuiTooltip-popper ': {
                                                        zIndex: '999999999',
                                                    },
                                                },
                                            }}
                                        >
                                            <HelpOutlineOutlinedIcon
                                                fontSize='small'
                                                color='disabled'
                                            />
                                        </Tooltip>
                                    </Stack>
                                ))}
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent='center'
                                alignItems='flex-end'
                                spacing={4}
                            >
                                <Button
                                    sx={{
                                        width: '200px',
                                    }}
                                    variant='outlined'
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    sx={{
                                        width: '200px',
                                    }}
                                    variant='contained'
                                    type='submit'
                                    disabled={selectedActions?.length === 0}

                                >
                                    Import
                                </Button>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </FormGroup>
        </DataSendingModalBaseRTK>
    )
}
export { ImportModal };
