import { Box, Button, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { DataSendingModalBaseRTK } from '../../../../components/DataSendingModalBaseRTK';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import {
  useActivateFileMutation,
  useDeactivateFileMutation,
  useDeleteFileMutation,
} from '../../../../api/RTK/services/feedFile';

const headingMap = {
  activate: 'Are you sure you want to Activate?',
  deactivate: 'Are you sure you want to Deactivate?',
  delete: 'Are you sure you want to Delete?',
};

const ImportedFileProjectModal = ({ open, handleClose, id, functionName, prompt = '' }) => {
  const { company } = useContext(UserInfoContext);

  const projectSelectId = useSelector((s) => s.allProjectsRedux.projectId);

  const [deleteFile, deleteStatus] = useDeleteFileMutation();
  const [activateFile, activateStatus] = useActivateFileMutation();
  const [deactivateFile, deactivateStatus] = useDeactivateFileMutation();

  const functionPicker = () => {
    switch (functionName) {
      case 'delete':
        return deleteFile;
      case 'activate':
        return activateFile;
      case 'deactivate':
        return deactivateFile;
      default:
        return null;
    }
  };

  const fireFunction = () => {
    functionPicker()({
      fileId: id,
      companyId: company?.id,
      projectId: projectSelectId,
    });
  };

  const statusPicker = () => {
    switch (functionName) {
      case 'delete':
        return deleteStatus;
      case 'activate':
        return activateStatus;
      case 'deactivate':
        return deactivateStatus;
      default:
        return {};
    }
  };

  const { isLoading, isError, isSuccess, reset } = statusPicker();

  return (
    <DataSendingModalBaseRTK
      open={open}
      handleCloseModal={handleClose}
      // eslint-disable-next-line react/jsx-props-no-spreading
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      successMessage='Success'
      errorMessage='Error'
      reset={reset}
    >
      <HeadingWithUnderline heading={headingMap[functionName]} />
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        {prompt ? (
          <Typography variant='h4' sx={{ mb: 3 }}>
            {prompt}
          </Typography>
        ) : null}
        <Box
          sx={{ width: '100%' }}
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          gap={3}
        >
          <Button variant='outlined' onClick={handleClose} fullWidth>
            Cancel
          </Button>
          <Button variant='contained' onClick={fireFunction} fullWidth>
            {_.startCase(functionName)}
          </Button>
        </Box>
      </Box>
    </DataSendingModalBaseRTK>
  );
};

export { ImportedFileProjectModal };
