import React from 'react';
import { Stack, Box, Typography, Container, Paper, Grid } from '@mui/material';
import { AdhelpNavLogo } from '../../../widgets/AdhelpLogo';
import {
  WrraperIllustrationTwo,
  WrraperIllustrationOne,
  WrraperIllustrationThree,
} from '../../../constants/endpoints/assests/artWork';
import { Footer } from '../../../components/Footer';

const data = [
  {
    id: 1,
    image: WrraperIllustrationOne,
    primary_context: 'Feed Management',
    sec_context:
      'Make sure your products have optimal feed score. Use AdHelp.io recommended fields to make your ads stand out on Google, Facebook and Instagram.',
  },
  {
    id: 2,
    image: WrraperIllustrationTwo,
    primary_context: 'All Data in One Platform',
    sec_context:
      'Manage your online business on a single platform. Effectively allocate your ad budget between different channels to maximize your return on investment.',
  },
  {
    id: 3,
    image: WrraperIllustrationThree,
    primary_context: 'Sign Up for FREE',
    sec_context:
      'Try out AdHelp.io before you select the paid version that fits your need.',
  },
];

const PagesWrraper = ({ children }) => (
  <>
    <Box sx={{ minHeight:'100vh', flex: 1 }}>
      <AdhelpNavLogo />
      <Container
        component={Paper}
        maxWidth='lg'
        sx={{
          borderRadius: '12px',
          '@media(min-width:898px)': {
            backgroundColor: (theme) => theme.palette.background.lightBlue,
          },

          '&.MuiContainer-root': {
            paddingLeft: '0rem',
            paddingRight: '0rem',
          },
        }}
      >
        <Grid container>
          <Grid md={6} item p={4} order={{ xs: 2, md: 1 }}>
            <Stack gap={4}>
              <Box
                gap={1}
                display='flex'
                flexDirection='column'
                sx={{
                  '@media(max-width:898px)': {
                    display: 'none',
                  },
                }}
              >
                <Typography variant='pageHeading'>
                  Benefits of using AdHelp.io
                </Typography>
                <Typography variant='bodyMedium'>
                  <Typography variant='bigSemiBold'>
                    Sign Up for FREE.{' '}
                  </Typography>
                  Try out AdHelp.io before you select the paid version that fits
                  your need.
                </Typography>
              </Box>
              {data.map((item) => (
                <Box key={item.id}>
                  <Stack
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    rowGap={2}
                    columnGap={3}
                  >
                    <Box
                      component='img'
                      src={item.image}
                      sx={{
                        backgroundColor: '#fff',
                        borderRadius: '14px',
                        width: '150px',
                        p: 2,
                      }}
                    />
                    <Stack display='flex' flexDirection='column' gap={1}>
                      <Typography variant='h3'>
                        {item.primary_context}
                      </Typography>
                      <Typography variant='smallMedium'>
                        {item.sec_context}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              ))}
            </Stack>
          </Grid>
          <Grid
            md={6}
            item
            order={{ xs: 1, md: 2 }}
            sx={{
              backgroundColor: (theme) => theme.palette.background.default,
              p: 4,
              borderRadius: '0px 12px 12px 0px',
              width: '100%',
            }}
          >
            <Box
              gap={1}
              display='flex'
              flexDirection='column'
              sx={{
                '@media(min-width:898px)': {
                  display: 'none',
                },
                mb: 2,
              }}
            >
              <Typography variant='pageHeading'>
                Benefits of using AdHelp.io
              </Typography>
              <Typography variant='bodyMedium'>
                <Typography variant='bigSemiBold'>
                  Sign Up for FREE.{' '}
                </Typography>
                Try out AdHelp.io before you select the paid version that fits
                your need.
              </Typography>
            </Box>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Box>
    <Footer />
  </>
);

export { PagesWrraper };
