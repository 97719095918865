// react imports
import React from 'react';
// third party imports
import { Box, Stack, Typography } from '@mui/material';
import { Person } from '@mui/icons-material';

const Company = ({ name, className }) => (
  <Stack direction='row' alignItems='center' spacing={1} className={className}>
    <Box
      borderRadius='999px'
      backgroundColor='background.secondary'
      height='1.5rem'
      width='1.5rem'
      display='flex'
      alignContent='center'
      justifyContent='center'
      overflow='hidden'
      sx={{
        '@media (min-width:996px)': {
          height: '42px',
          width: '42px',
        },
      }}
    >
      <Person
        sx={{
          fontSize: '30px',
          color: 'faded.primary',

          '@media (min-width:996px)': {
            fontSize: '50px',
          },
        }}
      />
    </Box>
    <Typography
      component='h1'
      variant='smallMedium'
      sx={{
        '@media (min-width:996px)': {
          fontSize: '1.125rem',
          fontWeight: 600,
        },
      }}
    >
      {name}
    </Typography>
  </Stack>
);

export { Company };
