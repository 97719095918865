/* eslint-disable no-unused-vars */
import React, { useState, useContext, useMemo, useEffect, useRef } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Skeleton,
  CircularProgress,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountsService } from '../../../../services/accounts';
import { LinkAccountModal } from './components/LinkAccountModal';
import { TempCompanySelector } from '../../../../components/TempCompanySelector';
import { Toolbar } from '../../../../components/Toolbar';
import { ErrorAlert } from '../../../../widgets/NewAlerts/ErrorAlert';
import { useFetch } from '../../../../Hooks/useFetch';
import { FetchDisplay } from '../../../../components/FetchDisplay';
import { ElementPicker } from '../../../../components/ElementPicker';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { UserInfoDispatchContext } from '../../../../context/UserInfoDispatchContext';
import {
  setAccountList,
  setCompany,
} from '../../../../utils/userInfoDispatchFunctions';
import { LinkAccountComponent, LinkedPlatformCard } from './components/helpers';
import { DateRangeSelector } from '../../../../components/DateRangeSelector';
import { CompanyBell } from '../../../../widgets/ImportantPopups/Popups';
import { GlobalErrorComponent } from '../../../../components/GlobalErrorComponent';

const SuccessDecider = ({ accountListLength, Cards, Modal }) => {
  if (accountListLength === 0) {
    return Modal;
  }
  return Cards;
};

const AccountList = () => {
  const [association, setAssociation] = useState(0);
  const [error, setError] = useState(false);
  const { accountList, company, accountListFetchingStatus, user, companyList } =
    useContext(UserInfoContext);
  const dispatch = useContext(UserInfoDispatchContext);

  const { state: locationState } = useLocation();

  // updates state which is a dependency for dataFetcher trigerring a refetch and reload
  const reloadList = () => {
    setAssociation(association + 1);
  };
  const memoizedArgs = useMemo(
    () => ({
      companyId: company?.id,
      dateRange: user.selected_dashboard_date,
    }),
    [company?.id, user.selected_dashboard_date]
  );

  const [state, updateState] = useFetch(
    {},
    AccountsService.getAccountsMetrics,
    memoizedArgs,
    association,
    // ensures accounts are fetched only when company is set
    !!company
  );
  // error msg scroll to top
  const targetElement = useRef(null);

  const handleErrorClick = () => {
    setError(true);
    if (!targetElement.current) return;
    targetElement?.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    // sets company if previous route passed it in state
    if (locationState?.company) {
      setCompany(dispatch, locationState.company);
      // reset state to prevent setting account after page REFRESH
      navigate('', {
        replace: true,
        state: { ...locationState, company: null },
      });
    }
  }, [locationState, dispatch, navigate]);

  useEffect(() => {
    if (state.data) {
      if (accountList) {
        setAccountList(dispatch, state.data);
      }
    }
  }, [state.data, dispatch, accountList]);

  useEffect(() => {
    if (state.data) {
      // Only set accountList if it's not already set
      if (!accountList) {
        setAccountList(dispatch, state.data);
      }
    }
  }, [state.data, dispatch, accountList]);

  useEffect(() => {
    if (state.errorInfo?.code === 204) {
      updateState({
        isLoading: false,
        isError: false,
        isPrefetch: false,
        data: [],
      });
    }
  }, [state.errorInfo, updateState]);

  return (
    <Box id='main'>
      {error && (
        <Container maxWidth='100%' sx={{ my: 2 }} ref={targetElement}>
          <ErrorAlert message='Select a Google or Meta account to proceed to the Dashboard' />
        </Container>
      )}
      <Toolbar colwidth={600}>
        <Container
          maxWidth='none'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            gap: 1,
            '@media(min-width: 800px)': {
              gap: 3.5,
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box
            sx={{
              minWidth: '200px',
              flex: 1,
              '@media(min-width: 800px)': { maxWidth: '400px' },
            }}
          >
            <TempCompanySelector />
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            sx={{
              flex: 1,
              order: -1,
              '@media(min-width: 800px)': { order: 0 },
            }}
          >
            <Typography
              component='h2'
              variant='pageHeading'
              sx={{ whiteSpace: 'nowrap' }}
            >
              {company?.name} Accounts
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              '@media(min-width: 800px)': { maxWidth: '400px' },
            }}
          >
            <DateRangeSelector />
          </Box>
        </Container>
      </Toolbar>
      <FetchDisplay
        isLoading={accountListFetchingStatus === 'FETCHING' || state.isLoading}
        isError={accountListFetchingStatus === 'ERROR'}
        isPrefetch={state.isPrefetch}
        PrefetchElement={
          companyList?.length > 0 ? (
            <Box
              sx={{
                height: 'calc(100vh - 244px)',
                display: 'grid',
                placeItems: 'center',

                '@media(min-width: 800px)': {
                  height: 'calc(100vh - 152px)',
                },
              }}
            >
              <ElementPicker
                case1={!company}
                element1={
                  <CompanyBell
                    heading='No Company is Selected'
                    primaryText='Select a Company'
                    secText='at the top left corner to view its Accounts'
                  />
                }
              />
            </Box>
          ) : (
            <LinkAccountModal setAssociation={setAssociation} />
          )
        }
        ErrorElement={<GlobalErrorComponent />}
        LoadingElement={
          <Container maxWidth='100%'>
            <Grid container spacing={3}>
              {Array(6)
                .fill()
                .map((_, index) => (
                  <Grid
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={3}
                    sx={{ paddingTop: '0px' }}
                  >
                    <Skeleton
                      animation='wave'
                      variant='rectangular'
                      height={200}
                      sx={{ borderRadius: '16px' }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Container>
        }
        SuccessElement={
          <SuccessDecider
            accountListLength={accountList?.length}
            Cards={
              <Container maxWidth='100%' sx={{ bgcolor: '#F5F6FA' }}>
                <Grid container spacing={3}>
                  {['OWNER', 'ADMIN'].includes(user?.role) ? (
                    <LinkAccountComponent setAssociation={setAssociation} />
                  ) : null}
                  <LinkedPlatformCard
                    accountList={accountList}
                    reloadFunction={reloadList}
                    handleErrorClick={handleErrorClick}
                    state={state}
                  />
                </Grid>
              </Container>
            }
            Modal={
              accountList?.length === 0 ? (
                <LinkAccountModal setAssociation={setAssociation} />
              ) : null
            }
          />
        }
      />
    </Box>
  );
};

export { AccountList };
