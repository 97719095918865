import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// MUI imports
import {
  Chip,
  Stack,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  Tooltip,
} from '@mui/material';

// MUI icons
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';

// Internal component import
import {
  setProjectId,
  setFeeds,
  setProjectName,
  setFiles,
} from '../../Redux/AllProjects';
import { clearInputValue } from '../../Redux/FeedSummary/slice';
import { clearInputValue as clearImportForm } from '../../Redux/ImportForm/slice';
import { clearInputValueImported } from '../../Redux/ImportedFiles/slice';
import { clearInputValue as clearInputValueSchedule } from '../../Redux/ScheduleForm/slice';
import { clearInputValue as clearInputValueFeedForm } from '../../Redux/FeedForm/slice';

// Styled import
import { StyledDetail } from '../StyledComponents/StyledProjectSummaryBoxMain';
import { OpenComponentButton } from '../GlobalComponents/AddButton';
import { AddProjectModal } from './AddProjectModal';
import { InstructionBox } from './InstructionBox';
import { UserInfoContext } from '../../../../context/UserInfoContext';

const getTooltipMessage = (filesAvailable, fileErrorAvailable, activeDataAvailable) => {
  if (!filesAvailable || !fileErrorAvailable || !activeDataAvailable) {
    return 'No active imports. This may result in running ads for old products.';
  }
  return ''
};

// main function component
const SummaryBox = ({ data }) => {
  const { user } = useContext(UserInfoContext);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const projectIdSelect = (projectId) => {
    dispatch(setProjectId(projectId));
  };

  const handleClearReduxIDs = () => {
    dispatch(clearInputValue());
    dispatch(clearInputValueImported());
    dispatch(clearImportForm());
    dispatch(clearInputValueSchedule());
    dispatch(clearInputValueFeedForm());
  };

  return (
    <Grid
      container
      alignItems={data && data.length === 0 ? 'flex-start' : 'center'}
    >
      {user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR' && (
        <Grid item lg={2} sm={4} xs={12}>
          <StyledDetail
            variant='outlined'
            className='projectDetail addProjectCard'
          >
            <OpenComponentButton
              name='New Project'
              setOpen={setOpen}
              className='desktopAddProject'
            >
              <AddProjectModal
                open={open}
                setOpen={setOpen}
                modalTitle='Add New Project'
              />
            </OpenComponentButton>
          </StyledDetail>
        </Grid>
      )}

      {/* show instruction box when there is no project */}
      {data && data.length === 0 ? (
        <Grid
          item
          xl={4}
          lg={5}
          md={6}
          xs={12}
          sx={{
            padding: '14px',
            marginLeft: 'calc(50%/3)',
            '@media screen and (max-width: 1100px)': { marginLeft: 'auto' },
          }}
        >
          <InstructionBox />
        </Grid>
      ) : null}

      {/* show mapped data in ui structure */}
      {data &&
        data.length > 0 &&
        [...data]
          ?.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { numeric: true })
          )
          .map((pData) => {
            const remainingFeeds = pData.feeds
              ?.slice(2)
              .map((feed) => feed.name)
              .join(', ');

            const filesAvailable = pData.files.length > 0;
            const activeDataAvailable = pData.files.some(
              (item) => item.active === true
            );
            const fileErrorAvailable = pData.files.some(
              (item) => item.status !== 4
            );

            const validateProject =
              filesAvailable && activeDataAvailable && fileErrorAvailable;

            const tooltipMessage = getTooltipMessage(
              filesAvailable,
              fileErrorAvailable,
              activeDataAvailable
            );

            return (
              <Grid item lg={2} sm={4} xs={12} key={pData.id}>
                <StyledDetail
                  variant='outlined'
                  className={`projectDetail ${!validateProject ? 'emptyProject' : ''}`}
                >
                  {/* Tooltip wrapping a single Link */}
                  <Tooltip title={tooltipMessage} placement='top' arrow followCursor>
                    <Link
                      to={pData.files.length ? 'imports' : 'imports/import-settings'}
                      onClick={() => {
                        projectIdSelect(pData.id);
                        dispatch(setFeeds(pData.feeds));
                        dispatch(setFiles(pData.files));
                        dispatch(setProjectName(pData.name));
                        handleClearReduxIDs();
                      }}
                    >
                      <div className='allProjectWrapper'>
                        <Stack direction='row' alignItems='flexStart' className='pseudoUnderline'>
                          <StickyNote2OutlinedIcon className='noteIcon' />
                          <div style={{ maxWidth: '75%' }}>
                            {/* Tooltip wrapping a single Typography */}
                            <Tooltip title={pData.name} arrow>
                              <Typography className='projectHeading'>
                                {pData.name}
                              </Typography>
                            </Tooltip>
                            {pData.active === true ? (
                              <Chip size='small' label='Active' className='activeColor' />
                            ) : (
                              <Chip size='small' label='Inactive' className='deactiveColor' />
                            )}
                          </div>
                        </Stack>
                        <Grid container>
                          <Grid item lg={12} className='gridUnderline'>
                            <List className='projectContent'>
                              {pData.feeds
                                ?.slice(0, pData.feeds.length === 3 ? 3 : 2)
                                .map((feedData) => (
                                  <ListItem key={feedData.id} disablePadding>
                                    {/* Tooltip wrapping a single ListItemText */}
                                    <Tooltip title={feedData.name}>
                                      <ListItemText
                                        className={
                                          feedData.active === false
                                            ? 'strikedProjectName'
                                            : 'projectName'
                                        }
                                      >
                                        {feedData.name}
                                      </ListItemText>
                                    </Tooltip>
                                  </ListItem>
                                ))}
                              {/* Tooltip wrapping a single Typography */}
                              <Tooltip title={remainingFeeds}>
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    color: '#0096FF',
                                    fontWeight: '500',
                                  }}
                                >
                                  {pData.feeds.length > 3 && pData.feeds.length - 2 > 0
                                    ? `+${pData.feeds.length - 2} more...`
                                    : ''}
                                </Typography>
                              </Tooltip>
                            </List>
                          </Grid>
                        </Grid>
                      </div>
                    </Link>
                  </Tooltip>

                  {/* Move the second Link outside of Tooltip */}
                  <div className='allProjectOpen'>
                    <Link
                      to={pData.files.length > 0 ? 'imports' : 'imports/import-settings'}
                    >
                      <Button
                        variant='text'
                        sx={{
                          color: !validateProject ? '#FF823C' : '#0096FF',
                          '&:hover': { backgroundColor: 'transparent' },
                        }}
                        disableRipple
                        onClick={() => {
                          projectIdSelect(pData.id);
                          dispatch(setFeeds(pData.feeds));
                          dispatch(setFiles(pData.files));
                          dispatch(setProjectName(pData.name));
                          handleClearReduxIDs();
                        }}
                      >
                        Open
                      </Button>
                    </Link>
                  </div>
                </StyledDetail>

              </Grid>
            );
          })}
    </Grid>
  );
};

export { SummaryBox };
