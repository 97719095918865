import React from 'react';
import { getHeadCells } from '../../../../components/Headcell/Headcell';
import { CollapsibleTable } from '../../../../components/CollapsibleTable';

const AdInsights = ({ data, currencyCode = 'ZZZ' }) => {
  if (data.length === 0) {
    return [];
  }

  const firstDataItem = data[0] || [];

  const defaultFormatFunction = (row, key) =>
    String(row[key] || row['Ad Group Name']);

  const excludedKeys = ['Campaign Id', 'ads_group'];

  // Separate "Total" row from the data
  const totalRow = data.find((row) => row['Campaign Name'] === 'Total');
  const filteredData = data.filter((row) => row['Campaign Name'] !== 'Total');

  return (
    <CollapsibleTable
      outerHeadCells={getHeadCells(
        firstDataItem,
        excludedKeys,
        currencyCode,
        defaultFormatFunction
      )}
      innerHeadCells={getHeadCells(
        firstDataItem,
        excludedKeys,
        currencyCode,
        defaultFormatFunction
      )}
      innerDataField='ads_group'
      rows={filteredData}
      totalRow={totalRow}
      outerRowIdField='Campaign Id'
      innerRowIdField='Ad Group Id'
    />
  );
};

export { AdInsights };
