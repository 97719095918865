import React from 'react';
import { countOptions, modifyOptions } from '../../options';
import {
  ConditionalWrapper,
  DynamicSelectField,
  TextOrSelect,
  TwoFieldConditionalWrapper,
} from '../../Inputs/Inputs';
import { CombinedInput } from '../../Inputs/CombinedInput';
import { WrappedCheckbox } from '../../Selectors/Fields/WrappedCheckbox';
import {
  FlexSelectInput,
  FlexTextInput,
  HiddenHR,
  MultilineFlexTextInput,
} from '../../Inputs/FlexInputs';

const fieldMap = (fields, name) => ({
  SET_TO_VALUE: (
    <FlexTextInput
      label='value'
      placeholder='Enter value'
      id={`${name}.value`}
      hideLabel
    />
  ),
  APPEND_VALUE: (
    <FlexTextInput
      label='value'
      placeholder='Enter value'
      id={`${name}.value`}
      hideLabel
    />
  ),

  COPY_VALUE: (
    <FlexSelectInput id={`${name}.value`} label='from' options={fields || []} />
  ),
  COMBINE_VALUE: (
    <>
      <HiddenHR />
      <CombinedInput name={`${name}`} options={fields} />
    </>
  ),
  SEARCH_FOR_VALUE: (
    <>
      <FlexSelectInput options={fields || []} label='in' id={`${name}.value`} />
      <HiddenHR />
      <MultilineFlexTextInput
        label='Search for value in (part of) field'
        placeholder='Enter one value per line'
        id={`${name}.replaces`}
      />
      <MultilineFlexTextInput
        label='(optional) use replacement values'
        placeholder='Enter one value per line'
        id={`${name}.using`}
      />
    </>
  ),
  REPLACE_VALUE: (
    <>
      <HiddenHR />
      <CombinedInput
        label='replace this'
        name={`${name}.query`}
        options={fields}
      />
      <CombinedInput
        label='replace with'
        name={`${name}.value`}
        options={fields}
      />
      <WrappedCheckbox name={`${name}.caseSensitive`} label='Case Sensitive' />
    </>
  ),
  LOOKUP_AND_REPLACE_VALUE: (
    <>
      <WrappedCheckbox name={`${name}.caseSensitive`} label='Case Sensitive' />
      <HiddenHR />
      <MultilineFlexTextInput
        label='When field is equal to'
        placeholder='Enter one value per line'
        id={`${name}.replaces`}
      />
      <MultilineFlexTextInput
        label='then change value to'
        placeholder='Enter one value per line'
        id={`${name}.value`}
      />
    </>
  ),
  SEARCH_AND_REPLACE_VALUE: (
    <>
      <WrappedCheckbox name={`${name}.caseSensitive`} label='Case Sensitive' />
      <HiddenHR />
      <MultilineFlexTextInput
        label='Search for value in (part of) field'
        placeholder='Enter value'
        id={`${name}.replaces`}
      />
      <MultilineFlexTextInput
        label='Use replacement values'
        placeholder='Enter value'
        id={`${name}.value`}
      />
    </>
  ),
  SPLIT_TEXT: (
    <>
      <HiddenHR />
      <FlexSelectInput
        id={`${name}.field`}
        label='from'
        options={fields || []}
      />
      <FlexTextInput
        label='split on'
        placeholder='Enter , or .'
        id={`${name}.separator`}
      />
      <FlexSelectInput
        id={`${name}.useFrom`}
        label='use'
        options={countOptions || []}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />
      <HiddenHR />
      <FlexSelectInput
        id={`${name}.useTill`}
        label='until'
        options={countOptions || []}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />
      <TwoFieldConditionalWrapper
        firstFieldPath={`${name}.useFrom`}
        secondFieldPath={`${name}.useTill`}
        conditionCallback={
          (firstValue, secondValue) => firstValue !== secondValue // changes for POST call in action
        }
      >
        <FlexTextInput
          label='add together using'
          placeholder='Enter , or .'
          id={`${name}.addTogetherUsing`}
        />
      </TwoFieldConditionalWrapper>
      <WrappedCheckbox name={`${name}.caseSensitive`} label='Case Sensitive' />
    </>
  ),
  MAXIMUM_LENGTH: (
    <>
      <HiddenHR />
      <FlexTextInput
        label='of'
        placeholder='Enter value'
        id={`${name}.value`}
      />
      <FlexSelectInput
        id={`${name}.option`}
        label='characters and'
        options={[
          { name: 'trim on words', value: 'trim_on_words' },
          {
            name: 'trim directly',
            value: 'trim_directly',
          },
        ]}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />
    </>
  ),
  MODIFY_TEXT: (
    <FlexSelectInput
      id={`${name}.value`}
      options={modifyOptions || []}
      optionValueCallback={(option) => option.value}
      optionContentCallback={(option) => option.name}
      hideLabel
      label='operation'
    />
  ),
  ROUND_NUMBER: (
    <>
      <HiddenHR />
      <FlexSelectInput
        id={`${name}.direction`}
        label='calculate'
        options={[
          { name: 'closest', value: 'closest' },
          { name: 'up', value: 'up' },
          { name: 'down', value: 'down' },
        ]}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />
      <FlexSelectInput
        id={`${name}.value`}
        label='to'
        options={[
          { value: '1_cent', name: '1 cent' },
          { value: '5_cents', name: '5 cents' },
          { value: '10_cents', name: '10 cents' },
          { value: '25_cents', name: '25 cents' },
          { value: '95_cents', name: '95 cents' },
          { value: '99_cents', name: '99 cents' },
          { value: 'whole_number', name: 'whole number' },
        ]}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />
    </>
  ),
  REFORMAT_NUMBER: (
    <>
      <HiddenHR />
      <FlexSelectInput
        id={`${name}.decimal_separator`}
        label='Decimal Separator'
        options={[
          { name: '.', value: '.' },
          { name: ',', value: ',' },
        ]}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />
      <FlexSelectInput
        id={`${name}.thousands_separator`}
        label='Thousands Separator'
        options={[
          { name: '.', value: '.' },
          { name: ',', value: ',' },
          { name: 'nothing', value: 'nothing' },
        ]}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />
      <FlexSelectInput
        id={`${name}.number_of_decimals`}
        label='Number of decimals'
        options={[
          { value: 'no_rounding', name: 'no rounding' },
          { value: '0', name: '0 decimals' },
          { value: '1', name: '1 decimal' },
          { value: '2', name: '2 decimal' },
          { value: '3', name: '3 decimal' },
          { value: '4', name: '4 decimal' },
          { value: '5', name: '5 decimal' },
          { value: '6', name: '6 decimal' },
        ]}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />
    </>
  ),
  CALCULATE: (
    <>
      <HiddenHR />
      <FlexSelectInput
        id={`${name}.field`}
        label='take'
        options={fields || []}
        optionValueCallback={(option) => option}
        optionContentCallback={(option) => option}
      />
      <FlexSelectInput
        id={`${name}.operation`}
        label=''
        options={[
          { value: 'multiply_by', name: 'multiply by' },
          { value: 'divide_by', name: 'divide_by' },
          { value: 'plus', name: 'plus' },
          { value: 'minus', name: 'minus' },
          { value: 'multiply_by_field', name: 'multiply by field' },
          { value: 'divide_by_field', name: 'divide by field' },
          { value: 'plus_field', name: 'plus field' },
          { value: 'minus_field', name: 'minus field' },
        ]}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />
      <TextOrSelect
        name={`${name}.value`}
        id={`${name}.value`}
        dependeeFileName={`${name}.operation`}
        label=''
        isText={(value) =>
          ![
            'multiply_by_field',
            'divide_by_field',
            'plus_field',
            'minus_field',
          ].includes(value)
        }
        type='text'
        options={fields || []}
        optionValueCallback={(option) => option}
        optionContentCallback={(option) => option}
      />
    </>
  ),
  CALCULATE_SUM: (
    <FlexSelectInput
      id={`${name}.value`}
      label='of fields'
      options={fields || []}
      optionValueCallback={(option) => option}
      optionContentCallback={(option) => option}
    />
  ),
  CALCULATE_LENGTH: (
    <FlexSelectInput
      id={`${name}.value`}
      label='of fields'
      options={fields || []}
      optionValueCallback={(option) => option}
      optionContentCallback={(option) => option}
    />
  ),

  CALCULATE_LIST_LENGTH: (
    <FlexSelectInput
      id={`${name}.value`}
      label='of'
      options={fields || []}
      optionValueCallback={(option) => option}
      optionContentCallback={(option) => option}
    />
  ),
  GROUP_ITEMS: (
    <>
      <HiddenHR />
      <DynamicSelectField
        name={`${name}.value`}
        label='from'
        type='text'
        options={fields || []}
        valueCallback={(option) => option}
        textCallback={(option) => option}
        sx={{
          flex: '1 0 300px',
          maxWidth: '300px',
        }}
      />
    </>
  ),
  SPLIT_ITEMS: null,
  DEDULICATE_ITEMS: (
    <>
      <HiddenHR />
      <FlexSelectInput
        id={`${name}.sub_filter`}
        label=''
        options={[
          { name: 'exclude duplicates', value: 'exclude_duplicates' },
          {
            name: 'keep lowest',
            value: 'keep_lowest',
          },
          { name: 'keep highest', value: 'keep_highest' },
        ]}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />
      <ConditionalWrapper
        dependeeFileName={`${name}.sub_filter`}
        conditionCallback={(value) =>
          ['keep_lowest', 'keep_highest'].includes(value)
        }
      >
        <FlexSelectInput
          id={`${name}.preservedField`}
          label='from'
          options={fields || []}
          optionValueCallback={(option) => option}
          optionContentCallback={(option) => option}
        />
      </ConditionalWrapper>
      <WrappedCheckbox
        name={`${name}.sort`}
        label='sort numbers'
        sx={{ gridRow: 2 }}
      />
    </>
  ),
  CALCULATE_ITEM_GROUP: (
    <>
      <HiddenHR />
      <FlexSelectInput
        id={`${name}.method`}
        label=''
        options={[
          { value: 'count_items', name: 'count items' },
          { value: 'calculate_sum', name: 'calculate sum' },
          { value: 'find_lowest_value', name: 'find lowest value' },
          { value: 'find_highest_value', name: 'find highest value' },
        ]}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />

      <FlexSelectInput
        id={`${name}.field`}
        label='store in'
        options={fields || []}
        optionValueCallback={(option) => option}
        optionContentCallback={(option) => option}
      />

      <ConditionalWrapper
        dependeeFileName={`${name}.method`}
        conditionCallback={(value) =>
          ['calculate_sum', 'find_lowest_value', 'find_highest_value'].includes(
            value
          )
        }
      >
        <FlexSelectInput
          id={`${name}.storageField`}
          label='store in'
          options={fields || []}
          optionValueCallback={(option) => option}
          optionContentCallback={(option) => option}
        />
      </ConditionalWrapper>

      <ConditionalWrapper
        dependeeFileName={`${name}.method`}
        conditionCallback={(value) =>
          ['calculate_sum', 'find_lowest_value', 'find_highest_value'].includes(
            value
          )
        }
      >
        <WrappedCheckbox name={`${name}.sort`} label='sort numbers' />
      </ConditionalWrapper>
    </>
  ),
  SORT_LIST: (
    <>
      <HiddenHR />
      <FlexSelectInput
        id={`${name}.direction`}
        label='order'
        options={[
          { value: 'desc', name: 'descending' },
          { value: 'asc', name: 'ascending' },
        ]}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />
      <WrappedCheckbox
        name={`${name}.sort_numbers`}
        label='sort numbers'
        sx={{ gridRow: 2 }}
      />
    </>
  ),
  SLICE_LIST: (
    <>
      <HiddenHR />
      <FlexSelectInput
        name={`${name}.field`}
        id={`${name}.field`}
        label='from'
        options={fields || []}
        optionValueCallback={(option) => option}
        optionContentCallback={(option) => option}
      />
      <FlexSelectInput
        id={`${name}.start`}
        label='use'
        options={countOptions || []}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />
      <FlexSelectInput
        id={`${name}.end`}
        label='until'
        options={countOptions || []}
        optionValueCallback={(option) => option.value}
        optionContentCallback={(option) => option.name}
      />
    </>
  ),
  SPLIT_TEXT_TO_LIST: (
    <FlexTextInput
      label='split on'
      placeholder='Enter value'
      id={`${name}.value`}
    />
  ),
  JOIN_LIST_TO_TEXT: (
    <FlexTextInput
      label='join with'
      id={`${name}.value`}
      placeholder='Enter value'
    />
  ),
  DEDULICATE_LIST: null,
});

export { fieldMap };
