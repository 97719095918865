import { Stack } from '@mui/material';
import { styled } from '@mui/system';

const StyledNav = styled('div')(
  ({ theme }) => `
  background-color: ${theme.palette.background.main};
  padding: 15px 0px;
  box-shadow: 0px 0px 10px rgba(0,0,0,.08);
  .MuiButtonBase-root{

    .exitIcon {
      vertical-align: middle;
      font-size: 20px;
      margin-left: 5px;
      color: ${theme.palette.svg.primary} !important;
    }
  }
  .appAccountMenu{
    margin-left: 10px;
    width: auto !important;
    .MuiOutlinedInput-root.MuiInputBase-root{
      height: 40px;
      border-radius: 25px;
      width: 235px;
    }
    svg{
      right: 10px;
      font-size: 20px;
    }
  }
`
);

const StyledStack = styled(Stack)(
  ({ theme }) => `
margin-top: 30px;
@media screen and (max-width: 480px) {
display: none;
}
  .appAccountMenu{
    width: auto !important;
    .MuiOutlinedInput-root.MuiInputBase-root{
      height: 40px;
      border-radius: 25px;
      width: 200px;
      background-color: ${theme.palette.background.main};
    }
    svg{
      right: 10px;
      font-size: 20px;
    }
  }
`
);

const StyledStackMobile = styled(Stack)(
  ({ theme }) => `
  @media screen and (min-width: 480px) {
    display: none;
    }
  @media screen and (max-width: 480px) {
    display: block;
    margin-top: 20px;
    .appAccountMenu{
      
      width: auto !important;
      .MuiOutlinedInput-root.MuiInputBase-root{
        height: 40px;
        border-radius: 25px;
        width: 200px;
        background-color: ${theme.palette.background.main};
      }
      svg{
        right: 10px;
        font-size: 20px;
      }
    }
  }

`
);

export { StyledNav, StyledStack, StyledStackMobile };
