import React from 'react';

import { Typography, Divider, Box, Tooltip } from '@mui/material';

import { DragHandle } from '@mui/icons-material';
import { InfoTooltip } from '../InfoTooltip';
import { DragHandleButton } from './styles';

/*
title: string
info: string. Show in tooltip
*/
const DataboxHeading = ({ title, info, children, display = true, sx = {} }) => (
  <>
    <Box sx={{ position: 'relative', flexShrink: 0 }}>
      <Box
        display='flex'
        p={3}
        direction='row'
        alignItems='center'
        spacing={3}
        flexWrap='wrap'
        gap={2}
        sx={{
          '@media(min-width:810px)': {
            padding: 2,
          },
        }}
      >
        <Tooltip title={title}>
          <Typography
            variant='h2'
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flex: '10 0 fit-content',
            }}
          >
            {title}
          </Typography>
        </Tooltip>
        <Box
          alignItems='center'
          spacing={3}
          sx={{ color: 'text.grey', flex: 1, zIndex: 11 }}
        >
          {children}
        </Box>
        {display === true ? <InfoTooltip title={info} /> : null}
      </Box>
      {display === true ? (
        <DragHandleButton className='dragHandle' disableRipple>
          <Box component='span' bgcolor='#fff'>
            <DragHandle sx={{ display: 'block', ...sx }} />
          </Box>
        </DragHandleButton>
      ) : null}
    </Box>
    <Divider sx={{ borderBottomWidth: '2px' }} />
  </>
);

export { DataboxHeading };
