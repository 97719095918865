/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box } from '@mui/material';
import { PublicOutlined, TableChartOutlined } from '@mui/icons-material';
import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { UserByLocationChart } from '../../../../Databoxes/UserByLocation/Chart';
import { Filter } from '../../../../../../components/Filter';

import { CustomSwitch } from '../../../../../../components/CustomSwitch/CustomSwitch';
import { Loading } from '../../../../../../components/Databox/Loading';

import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { UserByLocationTable } from '../../../../Databoxes/UserByLocation/Table';

import { getDashboardDataService } from '../../../../../../utils/getDashboardDataService';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';
import { ErrorComponent } from '../../../../../../components/GlobalErrorComponent';

const getFilterList = (platform) =>
  platform === 'meta'
    ? [
        {
          name: 'Reach',
          value: 'Reach',
        },
        {
          name: 'Cost',
          value: 'Spend',
        },
        {
          name: 'Clicks',
          value: 'Clicks',
        },
        {
          name: 'Impressions',
          value: 'Impressions',
        },
        {
          name: 'CPC',
          value: 'Cpc',
        },
      ]
    : [
        {
          name: 'Clicks',
          value: 'clicks',
        },
        {
          name: 'Cost',
          value: 'marketing_cost',
        },
        {
          name: 'Conversions',
          value: 'conversions',
        },
        {
          name: 'Revenue',
          value: 'online_sales',
        },
        {
          name: 'ROAS',
          value: 'ROAS',
        },
      ];

const DisplayComponentSelector = ({ showTable, chart, table }) => {
  if (showTable) {
    return table;
  }

  return chart;
};

const UserByLocationContainer = ({ accountId, dateRange, platform }) => {
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState(getFilterList(platform)[0]);

  const { account } = useContext(UserInfoContext);

  const memoizedArgs = useMemo(
    () => ({
      account_id: accountId,
      date_range: dateRange,
      databox_type: 'USER_LOCATION',
    }),
    [accountId, dateRange]
  );

  const [state] = useDataFetcher(
    [],
    getDashboardDataService(platform),
    memoizedArgs,
    count
  );

  const [showTable, setShowTable] = useState(false);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleChange = () => {
    setShowTable(!showTable);
  };

  return (
    <Databox>
      <ErrorBoundary
        fallback={
          <ErrorComponent
            title='User by Location'
            info='Know your audience by location and also your ad account’s performance in a particular country/region. Use filters for specific details and click on the table icon to switch to a tabular format.'
          />
        }
      >
        <DataboxHeading
          title='User by Location'
          info='Know your audience by location and also your ad account’s performance in a particular country/region. Use filters for specific details and click on the table icon to switch to a tabular format.'
        >
          {!showTable ? (
            <Filter
              label='Filter'
              selectedFilter={filter}
              filterList={getFilterList(platform)}
              handleFilterChange={handleFilterChange}
            />
          ) : null}
        </DataboxHeading>
        <FetchingDisplay
          isError={state.isError}
          isLoading={state.isLoading}
          LoadingElement={<Loading />}
          ErrorElement={
            <ErrorDisplay
              refresh={() => {
                setCount(count + 1);
              }}
              message={
                state.errorInfo?.code === 204
                  ? 'No data available for selected date range.'
                  : state.errorInfo?.code === 400
                  ? "Couldn't load data"
                  : 'Something went wrong'
              }
              showRefreshButton={
                !(
                  state.errorInfo?.code === 400 || state.errorInfo?.code === 204
                )
              }
            />
          }
          SuccessElement={
            <DisplayComponentSelector
              showTable={showTable}
              chart={
                <UserByLocationChart
                  data={state.data}
                  filter={filter}
                  platform={platform}
                  currencyCode={account.currency_code}
                />
              }
              table={
                <UserByLocationTable
                  data={state.data}
                  platform={platform}
                  currencyCode={account.currency_code}
                />
              }
            />
          }
        />
        <Box sx={{ display: 'flex', justifyContent: 'end', padding: 2 }}>
          <CustomSwitch
            checked={showTable}
            handleChange={handleChange}
            CheckedIcon={TableChartOutlined}
            Icon={PublicOutlined}
          />
        </Box>
      </ErrorBoundary>
    </Databox>
  );
};

export { UserByLocationContainer };
