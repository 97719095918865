/* eslint-disable no-unused-vars */
import { Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteOutline } from '@mui/icons-material';
import { HeadingWithUnderline } from '../../../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { DataSendingModalBaseRTK } from '../../../../../../components/DataSendingModalBaseRTK';

const DeleteModal = ({
  heading,
  prompt,
  successMessage,
  errorMessage,
  deleteHook,
  args,
  reloadList
}) => {
  const [open, setOpen] = useState(false);

  const closeModal = () => setOpen(false);
  const navigate = useNavigate();

  const [deleteFunction, { isLoading, isSuccess, isError, reset }] =
    deleteHook();

  const handleSubmit = () => {
    deleteFunction(args)
      .unwrap()
      .then(() => {
        navigate('../feeds/enhance');
      })
      .then(() => {
        setTimeout(() => {
          reloadList();
        }, 100);
      });
  };

  return (
    <>
      <Button
        variant='pillOutlined'
        sx={{
          height: '44px',
          minWidth: '150px',
          '&:hover': {
            svg: { color: '#e13f2a !important' },
            color: '#e13f2a',
            border: '1px solid #e13f2a',
          },
        }}
        onClick={() => setOpen(true)}
        startIcon={<DeleteOutline />}
      >
        {heading}
      </Button>
      <DataSendingModalBaseRTK
        open={open}
        handleCloseModal={closeModal}
        successMessage={successMessage}
        errorMessage={errorMessage}
        isSuccess={isSuccess}
        isLoading={isLoading}
        isError={isError}
        reset={reset}
      >
        <HeadingWithUnderline heading={heading} />
        {prompt}
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          marginTop='10px'
        >
          <Button sx={{
            width: '200px',
            margin: '25px 5px',
          }}
            variant='outlined'
            onClick={closeModal}>Cancel</Button>
          <Button sx={{
            width: '200px',
            margin: '25px 5px',
          }}
            variant='contained'
            onClick={handleSubmit}>Delete</Button>
        </Stack>
      </DataSendingModalBaseRTK>
    </>
  );
};

export { DeleteModal };
