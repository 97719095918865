import React from 'react';
import {
  Typography,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
  Select,
  FormControl,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  StyledFormikWrapper,
  StyledFormikField,
  StyledInputLabel,
} from './Styles';

const FormikField = ({
  name,
  label,
  required = false,
  type,
  placeholder,
  value,
  error,
  helperText,
  onChange,
  onBlur,
  readOnly = false,
  disabled = false,
}) => (
  <StyledFormikWrapper className='FormikField'>
    <StyledInputLabel htmlFor={name}>
      <Typography variant='h5'>{label}</Typography>
    </StyledInputLabel>
    <StyledFormikField
      id={name}
      required={required}
      as={TextField}
      variant='outlined'
      fullWidth
      autoComplete='on'
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      error={error}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
      InputProps={{
        readOnly,
      }}
      disabled={disabled}
    />
  </StyledFormikWrapper>
);

const FormikPasswordField = ({
  name,
  label,
  required = false,
  placeholder,
  type,
  value,
  error,
  helperText,
  onChange,
  onBlur,
  setShowPassword,
  showPassword,
}) => {
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <StyledFormikWrapper className='FormikField'>
      <StyledInputLabel htmlFor={name}>
        <Typography variant='h5'>{label}</Typography>
      </StyledInputLabel>
      <StyledFormikField
        id={name}
        required={required}
        as={TextField}
        variant='outlined'
        fullWidth
        autoComplete='on'
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        error={error}
        helperText={helperText}
        onChange={onChange}
        onBlur={onBlur}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </StyledFormikWrapper>
  );
};

const BasicSelect = () => {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={age}
          onChange={handleChange}
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const FormikFieldSelect = ({
  name,
  label,
  required = false,
  type,
  placeholder,
  value,
  error,
  helperText,
  onChange,
  onBlur,
}) => (
  <StyledFormikWrapper className='FormikField'>
    <StyledInputLabel htmlFor={name}>
      <Typography variant='h5'>{label}</Typography>
    </StyledInputLabel>
    <StyledFormikField
      id={name}
      required={required}
      as={BasicSelect}
      variant='outlined'
      fullWidth
      autoComplete='on'
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      error={error}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
    />
  </StyledFormikWrapper>
);

const FormsBackgroundHeading = () => (
  <Box textAlign='center' className='heading'>
    <Typography variant='h1' sx={{ padding: '8px 0px' }}>
      Grow your Webshop faster than ever
    </Typography>
    <Typography variant='body1'>
      Manage your online business in a single platform
    </Typography>
  </Box>
);

export {
  FormikField,
  FormikPasswordField,
  FormikFieldSelect,
  FormsBackgroundHeading,
  BasicSelect,
};
