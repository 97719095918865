/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  authenticationOptions,
  delimiterOptions,
  encodingOptions,
  fileTypeOptions,
  protocolOptions,
  quotingOptions,
} from '../../Components/Forms/ImportFileForm/options';

const initialState = {
  file_type: fileTypeOptions[0].value,
  name: '',
  protocol: protocolOptions[0].value,
  url: '',
  authentication: authenticationOptions[0].value,
  encoding: encodingOptions[0].value,
  quoting: quotingOptions[0].value,
  delimiters: delimiterOptions[0].value,
  username: '',
  password: '',
};

const importFormRedux = createSlice({
  name: 'importFormRedux',
  initialState,
  reducers: {
    updateVal: (state, { payload: { key, val } }) => {
      state[key] = val;
    },
    clearInputValue: () => initialState,
  },
});

export const { updateVal, clearInputValue } = importFormRedux.actions;

// eslint-disable-next-line import/no-default-export
export default importFormRedux.reducer;
