import React, { useRef, useEffect, useState } from 'react';

import _ from 'lodash';

import { Box, Tooltip, Typography } from '@mui/material';

import { BarCreator } from './BarCreator';
import { GraphGrid, TooltipColorBox, TooltipGrid } from './styles';

// data is an array of objects
// id: string, unique.
// value: number
// label: string
// icon: React Component. SVG
// color: String

// labelFormatter: function to display labels for each bar. Recieves current bar's data value and the sum of all values
// keyField: string, unique. Field name whose value is to be used as key in map.
// totalCount: number. This defines the total number of icons.
const HorizontalIconGraph = ({
  data,
  keyField,
  limit,
  labelFormatter,
  tooltipLabel,
  currencyCode = 'ZZZ',
  isCurrency,
  metricName,
}) => {
  const [isTooSmall, setIsTooSmall] = useState(false);
  const total = _.sumBy(data, 'value');

  const roundTo =
    metricName &&
    (metricName.toLowerCase() === 'roas' || metricName.toLowerCase() === 'cpc')
      ? 1
      : 0;
  const mapRef = useRef(null);
  const isMounted = useRef(true);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (mapRef?.current?.clientWidth < 500) {
        if (isMounted.current) {
          setIsTooSmall(true);
        }
      } else if (isMounted.current) {
        setIsTooSmall(false);
      }
    });

    resizeObserver.observe(mapRef.current);

    return () => {
      isMounted.current = false;
      if (mapRef.current) {
        resizeObserver.unobserve(mapRef.current);
      }
    };
  }, []);
  return (
    <>
      <GraphGrid cellCount={isTooSmall ? 5 : limit} ref={mapRef}>
        {_.orderBy(data, 'value', 'desc').map((d, index) => [
          <BarCreator
            key={d[`${keyField}`]}
            icon={d.icon}
            color={d.color}
            id={d.id}
            // calculate the number of icons for current value
            // calculate the percentage of current value w.r.t. the sum of all values
            // the icon count if the calculated percentage applied to totalcount
            count={
              total !== 0
                ? _.round((d.value * (isTooSmall ? 5 : limit)) / total, 2)
                : 0
            }
            value={d.value}
            row={index + 1}
            limit={isTooSmall ? 5 : limit}
          />,
          <Typography
            variant='smallBold'
            color={d.color}
            key={`${d[`${keyField}`]}label`}
            fontSize='1rem'
            sx={{
              gridColumn: (isTooSmall ? 5 : limit) + 1,
              whiteSpace: 'noWrap',
            }}
          >
            {labelFormatter(d, total)}
          </Typography>,
        ])}
      </GraphGrid>
      {/* This grid overlays the grid grid. It is used to apply tooltips to the graph grid */}

      <TooltipGrid cellCount={isTooSmall ? 5 : limit}>
        {_.orderBy(data, 'value', 'desc').map((d, index) => (
          <Tooltip
            arrow
            followCursor
            key={d[`${keyField}`]}
            title={
              <>
                <Typography variant='smallerBold'>{d.label}</Typography>
                <Box display='flex' alignItems='center' gap={0.5}>
                  <TooltipColorBox backgroundColor={d.color} />
                  <Typography variant='smallerBold'>
                    {tooltipLabel}:{' '}
                  </Typography>
                  <Typography variant='smallerBold'>
                    {isCurrency
                      ? `${currencyCode} ${_.round(
                          d.value,
                          1
                        ).toLocaleString()}`
                      : `${_.round(d.value, roundTo).toLocaleString()}`}
                  </Typography>
                </Box>
              </>
            }
          >
            <Box
              sx={{
                gridArea: `${index + 1}/1/${index + 2}/${
                  Math.floor((d.value * (isTooSmall ? 5 : limit)) / total) + 2
                }`,
              }}
            />
          </Tooltip>
        ))}
      </TooltipGrid>
    </>
  );
};

export { HorizontalIconGraph };
