import React from 'react';

import { getHeadCells } from '../../../../../components/Headcell/Headcell';

import { DashboardCustomTable } from '../../../../../components/Table';

const UserByGenderTable = ({ data, currencyCode = 'ZZZ' }) => {
  if (data.length === 0) {
    return [];
  }
  const firstDataItem = data[0];
  const defaultFormatFunction = (row, key) => String(row[key]);
  const excludedKeys = [];

  // Separate "Total" row from the data
  const totalRow = data.find((row) => row.Gender === 'Total');
  const filteredData = data.filter((row) => row.Gender !== 'Total');

  return (
    <DashboardCustomTable
      headCells={getHeadCells(
        firstDataItem,
        excludedKeys,
        currencyCode,
        defaultFormatFunction
      )}
      rows={filteredData}
      totalRow={totalRow}
      rowIdField='Gender'
      verticalBorders
    />
  );
};

export { UserByGenderTable };
