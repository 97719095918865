import {
  toTitleCase,
  aggregateCalculator,
  aggregateFunctionGenerator,
  isCurrency,
} from './index';

const createTableForArray = (
  sheet,
  label,
  dataVariable,
  currencyCode,
  total = true
) => {
  sheet.addTable({
    name: label,
    ref: `A1`,
    headerRow: true,
    totalsRow: false,
    style: {
      theme: null,
    },
    columns: Object.keys(dataVariable[0]).map((key) => ({
      name: isCurrency(toTitleCase(key))
        ? `${toTitleCase(key)} (${currencyCode})`
        : toTitleCase(key),
      totalsRowLabel: '',
      filterButton: false,
    })),
    rows: dataVariable.map((v) => Object.values(v)),
  });

  const aggregateFunctionArray = aggregateFunctionGenerator(dataVariable[0]);

  Object.keys(dataVariable[0]).forEach((key, index) => {
    const cell = sheet.getCell(
      `${String.fromCharCode(index + 65)}${dataVariable.length + 2}`
    );

    if (total) {
      cell.value = aggregateCalculator(
        aggregateFunctionArray,
        dataVariable,
        key,
        index
      );
    }
  });
};

export { createTableForArray };
