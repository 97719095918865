/* eslint-disable no-useless-escape */
import {
  object,
  array,
  number,
  string,
  addMethod,
  ValidationError,
  mixed,
} from 'yup';


const ImportSchema = object().shape({
  url: string().required('URL is required'),
  name: string().required('This field is required'),
});

const FeedSchema = object().shape({
  feedName: string()
    .required('Feed name is required'),
  country: mixed()
    .required('Select a country'),
  channelName: mixed().required('Channel is a required field'),
});

const NameSchema = object().shape({
  projectName: string()
    .required('Project name is required')
    .min(2, 'Name must be at least 2 characters'),
});

const SelectSchema = object().shape({
  selectOnly: string().required('This field cannot be empty'),
});

const ProjectSchema = NameSchema.concat(
  object().shape({
    selectOnly: string().required('This field cannot be empty'),
    projectID: string().required('This field cannot be empty'),
  })
);

const ScheduleSchema = SelectSchema.concat(
  object().shape({
    timezone: string().required('Please select a timezone'),
  })
);

const FilterSchema = object().shape({
  fieldName: string().required('This field cannot be empty'),
  filter: string().required('This field cannot be empty'),
  filterName: string()
    .required('Filter name is required')
    .min(2, 'Name must be at least 2 characters'),
  fieldValue: string().required('This field cannot be empty'),
});


// check if importedFields have unique mapped_to values
addMethod(
  array,
  'unique',
  function (message, mapper = (field) => field.mapped_to) {
    return this.test('unique', message, (list) => {
      let errors = [];
      // list of mapped_to pkid
      const pkidList = list.map(mapper);
      // if list and a set generated from the list has different lengths, the list has a repeated value
      if (!(list.length === new Set(pkidList).size)) {
        // create map where key is the pkid and value is array of indexes where the mapped_to appears in pkidList
        // this map keeps track of the indexes at which the pkid appears in the pkidList
        // if any key (pkid) has more than one value, it is repeated.
        const valueMap = pkidList.reduce((acc, cur, index) => {
          if (typeof cur === 'number') {
            return {
              ...acc,
              [cur]: [...(acc[cur] || []), index],
            };
          }
          return acc;
        }, {});

        const duplicateValIndexes = Object.values(valueMap)
          .filter((pkidIndexes) => pkidIndexes.length > 1) // only keep the entries which have more than one pkid, i.e. are repeated
          .reduce((acc, indexArr) => [...acc, ...indexArr], []);

        // set errors for the repeated indexes
        // these pkid indexes correspond to the imported_field indexes
        errors = duplicateValIndexes.map(
          (pkidIndex) =>
            new ValidationError(
              `Duplicate fields not allowed`,
              pkidList[pkidIndex],
              `channelFields[${pkidIndex}].mapped_to`
            )
        );
      }
      return new ValidationError(errors);
    });
  }
);

const mappingValidationSchema = object({
  internalFields: array().of(
    object({
      pkid: number().required(),
    })
  ),
  channelFields: array()
    .of(
      object({
        mapped_to: number().test(
          'is-correctly-mapped',
          'Internal Fields Must Be Mapped To a Channel Field',
          (v, context) =>
            [
              ...context.from[1].value.internalFields.map((field) => field.pkid),
            ].includes(v) || !v
        ),
      })
    )
    .unique(),
});

const demoValidationSchema = object().shape({
  file_url: string()
    .url('Invalid URL. Start the URL with http:// or https://')
    .matches(/^(https?:\/\/).*/, 'URL must start with http:// or https://')
    .required('URL is required'),
});


export {
  ImportSchema,
  FeedSchema,
  NameSchema,
  SelectSchema,
  ProjectSchema,
  ScheduleSchema,
  FilterSchema,
  mappingValidationSchema,
  demoValidationSchema
};
