import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// MUI imports
import { ToggleButton } from '@mui/material';
// Styled component imports
import { StyledToggleButtonGroup } from '../StyledComponents/StyledCommon';
import { setFileId } from '../../Redux/ImportedFiles';

const ToggleButtonImport = ({ toggled, setToggled, fileId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (fileId) {
      dispatch(setFileId(fileId));
    }
  }, [dispatch, fileId]);
  const handleToggled = (event, newToggled) => {
    if (newToggled !== null) {
      setToggled(newToggled);
    }
  };

  const fileSelectId = useSelector((state) => state.importedFilesRedux.fileId);

  return (
    <StyledToggleButtonGroup
      value={toggled}
      exclusive
      onChange={handleToggled}
      aria-label='toggle button group'
      className='twoToggleParent'
    >
      <ToggleButton
        value='Settings'
        aria-label='toggle button 1'
        component={Link}
        to={fileSelectId ? '../update' : '../add'}
        color={toggled === 'Settings' ? 'primary' : 'standard'}
        className='twoToggle1'
      >
        Settings
      </ToggleButton>
      <ToggleButton
        value='Edit Mapping'
        aria-label='toggle button 2'
        component={Link}
        to={`../edit-mapping/${fileId}`}
        color={toggled === 'Edit Mapping' ? 'primary' : 'standard'}
        className='twoToggle2'
        disabled={!fileSelectId}
      >
        Edit Mapping
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export { ToggleButtonImport };
