import React from 'react';
import { styled } from '@mui/system';
import { Typography, Box, ListItemIcon } from '@mui/material';

const StyledHeadingWithUnderline = styled(Box)(
  ({ theme }) => `
     
      text-align:center;
      padding-bottom:${theme.spacing(2)}; 
      border-bottom: .08rem solid ${theme.palette.primary.main};
      margin-bottom:${theme.spacing(2)};
      `
);

const StyledLogoHeadingWithUnderline = styled(Box)(
  ({ theme }) => `
      display:flex;
      justify-content:center;
      align-items:center;
      text-align:center;
      padding-bottom:${theme.spacing(2)}; 
      border-bottom: .08rem solid ${theme.palette.primary.main};
      margin-bottom:${theme.spacing(2)};
      `
);

const HeadingWithUnderline = ({ heading, sx }) => (
  <StyledHeadingWithUnderline sx={sx}>
    <Typography variant='h2'>{heading}</Typography>
  </StyledHeadingWithUnderline>
);

const LogoHeadingWithUnderline = ({ heading, src = null }) => (
  <StyledLogoHeadingWithUnderline>
    <ListItemIcon
      sx={{
        height: '24px',
        flexBasis: 0,
        minWidth: 'auto',
        marginRight: '8px',
      }}
    >
      <img src={src} alt='Logo' />
    </ListItemIcon>
    <Typography variant='h4'>{heading}</Typography>
  </StyledLogoHeadingWithUnderline>
);

export { HeadingWithUnderline, LogoHeadingWithUnderline };
