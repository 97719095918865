import React, { useContext } from 'react';
import { Box, Button, Container, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Selectors } from '../Components/Selectors';
import { initialValues } from './initialValues';
import { filterSchema } from './validationSchema';
import { TextInput } from '../../../../../components/Inputs/TextInput';
import { UserInfoContext } from '../../../../../context/UserInfoContext';

const FilterForm = ({ fields, initialData, handleSubmit, btnName }) => {
  const { user } = useContext(UserInfoContext);
  return (
    <Formik
      initialValues={initialData || initialValues}
      onSubmit={handleSubmit}
      validationSchema={filterSchema}
    >
      {() => (
        <Form>
          <Container
            maxWidth='none'
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 3,
                alignItems: 'start',
                marginBottom: '8px',
              }}
            >
              <TextInput
                name='name'
                label='Name'
                id='filter-name'
                placeholder='Enter Filter Name'
                sx={{
                  flex: '1 0 200px', '& .MuiOutlinedInput-root .Mui-disabled': {
                    backgroundColor: 'transparent'
                  }
                }}
                disabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
              />
              <TextInput
                name='description'
                label='Description'
                id='filter-description'
                placeholder='Enter Filter Description'
                sx={{
                  flex: '1 0 200px', '& .MuiOutlinedInput-root .Mui-disabled': {
                    backgroundColor: 'transparent'
                  }
                }}
                disabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
              />
            </Box>
            <Selectors
              fields={fields}
              helperText='All the items that match this condition will be removed.'
              sx={{
                '&.selectorText .MuiTypography-smallRegular': {
                  color: '#0096ff !important',
                },
              }}
            />

            <Stack direction='row' justifyContent='center' alignItems='center'>
              <Button
                sx={{
                  width: '200px',
                  margin: '5px',
                  textAlign: 'center',
                  textDecoration: 'none',
                }}
                variant='outlined'
                as={Link}
                to='../feeds/enhance'
              >
                Back
              </Button>
              {(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') && (
                <Button
                  sx={{
                    width: '200px',
                    margin: '25px 5px',
                  }}
                  variant='contained'
                  type='submit'
                >
                  {btnName}
                </Button>
              )}
            </Stack>
          </Container>
        </Form>
      )}
    </Formik>
  );
};
export { FilterForm };
