import React from 'react';

import _ from 'lodash';

import { FractionalIconContainer, IconContainer } from './styles';

// count: number. Number of icons
// value: number. Displayed in tooltip
// color: string. Color given to SVG icons
// icon: React Component. Icon to be used in Bar
const BarCreator = ({ count, color, icon, limit, row }) => {
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      // eslint-disable-next-line no-shadow
      (_, i) => start + i * step
    );
  // maps the colored icons
  const arr = range(1, Math.floor(count), 1);
  // maps the grey icon. After the last whole colored icon
  const arr2 = range(1, limit - Math.floor(count), 1);
  const IconSvg = icon;

  return (
    <>
      {arr.map((i) => (
        <IconContainer key={i} color={color} row={row} column={i}>
          <IconSvg className='barGraphIcon' />
        </IconContainer>
      ))}
      {arr.length !== 10 && (
        <FractionalIconContainer
          row={row}
          color={color}
          fraction={_.round(count - _.floor(count), 2)}
          column={Math.floor(count) + 1}
        >
          <IconSvg className='barGraphIcon' />
        </FractionalIconContainer>
      )}
      {arr2.map((i) => (
        <IconContainer
          key={i}
          color='#EEEEEE'
          row={row}
          column={Math.floor(count) + i}
        >
          <IconSvg className='barGraphIcon' />
        </IconContainer>
      ))}
    </>
  );
};

export { BarCreator };
