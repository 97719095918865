import React, { useContext, useEffect, useState, useMemo, useRef } from 'react';
import { Box, Grid, Container, Typography, Skeleton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card } from './components/Card';
import { Toolbar } from '../../../../components/Toolbar';
import { ConnectCompanyButton } from './components/ConnectCompany';
import { useDataFetcher } from '../../../../Hooks/useDataFetcher';
import { DashboardService } from '../../../../services/dashboard';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { UserInfoDispatchContext } from '../../../../context/UserInfoDispatchContext';
import { setCompanyList } from '../../../../utils/userInfoDispatchFunctions';
import { DateRangeSelector } from '../../../../components/DateRangeSelector';
import { Search } from '../../../FeedManagement/Components/GlobalComponents/Search';
import { FetchingDisplay } from '../../../../components/FetchingDisplay';
import { GlobalErrorComponent } from '../../../../components/GlobalErrorComponent';

const MetricDisplay = ({ isLoading, isError, metric, currency }) => {
  if (isLoading) {
    return <Skeleton width='100%' />;
  }
  if (isError) {
    return '--';
  }
  if (!isError && !isLoading && !metric) {
    return '--';
  }
  if (metric !== undefined) {
    return `${currency} ${Math.round(metric).toLocaleString()}`;
  }
  return '--';
};

const CompanyList = (handleSearch) => {
  const { companyList, user } = useContext(UserInfoContext);
  const dispatch = useContext(UserInfoDispatchContext);
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const [companyIsLoading, setCompanyIsLoading] = useState(false);
  // const [companyIsError, setCompanyIsError] = useState(false);

  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const memoizedArgs = useMemo(
    () => ({
      date_range: user.selected_dashboard_date,
    }),
    [user.selected_dashboard_date]
  );

  const [state] = useDataFetcher(
    { isLoading: false, isError: false },
    DashboardService.getCompanyListWithoutMetrics,
    memoizedArgs,
    count
  );
// console.log(state , "state")
  const reloadList = () => {
    setCount(count + 1);
  };

  const [filteredCompanies, setFilteredCompanies] = useState(companyList || []);
  const showStartAdornment = true;
  useEffect(() => {
    // update company list is previous route passed it in state
    if (locationState?.companyList || locationState?.resetCompanyList)
      setCompanyList(dispatch, locationState?.companyList);
  }, [locationState]);

  useEffect(() => {
    setCompanyIsLoading(true);
    if (!state.isLoading && !state.isError && state.data) {
      // update companyList after metric data is fetched
      setCompanyList(dispatch, state.data);

      // Fetch Company List with Metrics after Company List without Metrics
      // DashboardService.getCompanyList(memoizedArgs)
      //   .then((result) => {
      //     if (result?.data && isMountedRef.current) {
      //       setCompanyList(dispatch, result?.data);
      //       setFilteredCompanies(result?.data);
      //       setCompanyIsLoading(false);
      //     }
      //   })
      //   .catch(() => {
      //     if (isMountedRef.current) {
      //       setCompanyIsError(true);
      //     }
      //   });
    }
  }, [state.data, memoizedArgs, dispatch]);

  useEffect(() => {
    if (!state.isLoading && !state.isError && state.data) {
      setFilteredCompanies(state.data);
    }
  }, [state.data, state.isLoading, state.isError]);

  return (
    <>
      {/* <Container maxWidth>
        <ErrorAlert message='Select a company and an account to see dashboard' />
      </Container> */}
      <Toolbar colwidth={600}>
        <Container
          maxWidth='none'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            gap: 1,
            '@media(min-width: 800px)': {
              gap: 3.5,
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box
            display='flex'
            justifyContent='flex-start'
            sx={{
              minWidth: '200px',
              flex: 1,
              '@media(min-width: 800px)': { maxWidth: '400px' },
            }}
          >
            <Search
              showStartAdornment={showStartAdornment}
              data={companyList}
              setData={setFilteredCompanies}
              searchKey='name'
              placeholder='Search company'
              onSearch={handleSearch}
            />
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{
              flex: 1,
              order: -1,
              '@media(min-width: 800px)': { order: 0 },
            }}
          >
            <Typography
              component='h2'
              variant='pageHeading'
              sx={{ whiteSpace: 'nowrap' }}
            >
              Your Companies
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              '@media(min-width: 800px)': { maxWidth: '400px' },
            }}
          >
            <DateRangeSelector />
          </Box>
        </Container>
      </Toolbar>
      <Container maxWidth='100%' sx={{ bgcolor: '#F5F6FA' }}>
        <FetchingDisplay
          isLoading={!companyList && state.isLoading}
          isError={!companyList}
          ErrorElement={<GlobalErrorComponent />}
          LoadingElement={
            <Container maxWidth='100%'>
              <Grid container spacing={3}>
                {Array(12)
                  .fill()
                  .map((_, index) => (
                    <Grid
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={3}
                      sx={{ paddingTop: '0px' }}
                    >
                      <Skeleton
                        variant='rectangular'
                        animation='wave'
                        height={180}
                        sx={{ borderRadius: '16px' }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Container>
          }
          SuccessElement={
            <Grid container spacing={3}>
              {['OWNER', 'ADMIN'].includes(user?.role) ? (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                  <ConnectCompanyButton reloadFunction={reloadList} />
                </Grid>
              ) : null}
              {filteredCompanies.map((c) => (
                <Grid key={c.id} item xs={12} sm={6} md={4} lg={4} xl={3}>{console.log(c.revenue , c.currency)}
                  <Card
                    id={c.id}
                    name={c.name}
                    handleClick={() =>
                      navigate('/accounts', { state: { company: c } })
                    }
                    revenue={
                      <MetricDisplay
                        // isError={companyIsError}
                        isLoading={companyIsLoading}
                        metric={c?.revenue}
                        currency={c.currency}
                      />
                    }
                    cost={
                      <MetricDisplay
                        // isError={companyIsError}
                        isLoading={companyIsLoading}
                        metric={c?.cost}
                        currency={c.currency}
                      />
                    }
                    bgColor={c.company_colour || 'BLUE'}
                    reloadFunction={reloadList}
                  />
                </Grid>
              ))}
            </Grid>
          }
        />
      </Container>
    </>
  );
};

export { CompanyList };
