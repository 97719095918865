import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FetchingDisplay } from '../../../../components/FetchingDisplay';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { useDeleteProjectFieldMutation } from '../../../../api/RTK/services/feedProject';
import { DataSendingModalBaseRTK } from '../../../../components/DataSendingModalBaseRTK';

const DeleteImportedField = ({ open, setOpen, id, handleClose }) => {
  const handleCloseModal = () => setOpen(false);
  const { company } = useContext(UserInfoContext);
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const [deleteField, { isLoading, isSuccess, isError, error, reset }] =
    useDeleteProjectFieldMutation();
  // placeholder mutation
  // to be removed after correct request is available

  // refetch and close modal after successful deletion
  useEffect(() => {
    const closeTimeout =
      error?.code === 204 &&
      setTimeout(() => {
        handleClose();
      }, 1000);

    return () => {
      clearTimeout(closeTimeout);
    };
  }, [error]);

  useEffect(() => {
    if (open) {
      deleteField({
        companyId: company.id,
        projectId: projectSelectId,
        id,
      });
    }
  }, [company, projectSelectId, id, open]);
  return (
    <DataSendingModalBaseRTK
      isLoading={isLoading}
      isError={isLoading}
      isSuccess={isSuccess}
      open={open}
      handleCloseModal={handleCloseModal}
      successMessage='Field Deleted'
      errorMessage='Error while deleting field. Refresh and try again.'
      reset={reset}
    >
      <FetchingDisplay
        isLoading={isLoading}
        isError={isError}
        LoadingElement={<CircularProgress />}
        ErrorElement={
          <>
            {isError && error?.status === 204 && 'Field Deleted'}
            {isError && error?.status === 403 && (
              <>
                <HeadingWithUnderline heading='Delete this field ?' />
                <Typography variant='smallMedium'>
                  This file was used{' '}
                  {Object.values(error?.data?.error).reduce(
                    (acc, curr) => acc + curr.length,
                    0
                  )}{' '}
                  times
                </Typography>
                <ul>
                  {Object.keys(error?.data?.error)
                    .filter((type) => error?.data?.error[type].length !== 0)
                    .map((type) => (
                      <li>
                        <Typography variant='smallMedium'>{type}</Typography>
                        <Box component='ul' marginLeft={2}>
                          {error?.data?.error[type].map((name) => (
                            <li>
                              <Typography variant='smallMedium'>
                                {name}
                              </Typography>
                            </li>
                          ))}
                        </Box>
                      </li>
                    ))}
                </ul>
                <Typography variant='smallMedium'>
                  Delete these dependencies and try again.
                </Typography>
              </>
            )}
          </>
        }
        SuccessElement='File deleted successfully'
      />
    </DataSendingModalBaseRTK>
  );
};

export { DeleteImportedField };
