import * as React from 'react';
import { Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { StyledBox, StyledBoxSec, StyledLinkYourAccount } from './styles';
import { HeadingWithUnderline } from '../../../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { LinkSpeedDial } from '../LinkAccountSpeedDial';

const LinkAccountModal = ({ setAssociation }) => (
  <StyledBox>
    <StyledBoxSec>
      <StyledLinkYourAccount>
        <HeadingWithUnderline heading='Link your accounts' />
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          <Typography variant='smallMedium'>
            Link your accounts to track their performance and create ads
          </Typography>
          <Box sx={{ mt: 2, mb: 0.5 }}>
            <LinkSpeedDial setAssociation={setAssociation} />
          </Box>
          <Typography
            variant='smallMedium'
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            Click on <AddIcon fontSize='small' /> to link your platforms
          </Typography>
        </Box>
      </StyledLinkYourAccount>
    </StyledBoxSec>
  </StyledBox>
);

export { LinkAccountModal };
