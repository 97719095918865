import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import SnackbarContent from '@mui/material/SnackbarContent';
import { Box, Typography } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { UserInfoContext } from '../../../../../context/UserInfoContext';
import { useCreateScheduleMutation, useEditProjectMutation, useGetScheduleQuery } from '../../../../../api/RTK/services/feedProject';
import { StyledSnackbarStack } from '../../StyledComponents/StyledCommon';

const SnackbarWithCta = ({ mainMessage, description }) => {
    const { company } = useContext(UserInfoContext);

    // redux store
    const projectSelectId = useSelector(
        (state) => state.allProjectsRedux.projectId
    );
    const feedSelectId = useSelector((state) => state.feedSummaryRedux.feedId);
    const scheduleData = useSelector((state) => state.scheduleFormRedux)

    // api calls
    const [editProject] =
        useEditProjectMutation();
    const [
        createSchedule,
    ] = useCreateScheduleMutation();

    const {
        refetch,
    } = useGetScheduleQuery({
        companyId: company?.id,
        projectId: projectSelectId,
        feedId: feedSelectId,
    });

    const handlePostSubmit = (createfeedData) =>
        createSchedule({ data: createfeedData })

    // edit the project and then schedule it
    const handleActionClick = () => {
        editProject({
            companyId: company?.id,
            data: {
                active: true,
                feedproject_id: projectSelectId,
            },
        })
            .then(() => {
                const createfeedData = {
                    company_id: company?.id,
                    feed_project_id: projectSelectId,
                    repeat: scheduleData.selectOnly,
                    time_interval: scheduleData.secondSelect,
                    timezone: scheduleData.timezone,
                };
                handlePostSubmit(createfeedData)
                    .then(() => refetch());
            })
            .catch((error) => {
                console.error('Error', error);
            });
    };

    return (
        <StyledSnackbarStack
            mb={2}
            sx={{
                width: '100%',
            }}
        >
            <SnackbarContent
                message={
                    <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1} sx={{
                        width: '100%',
                        '@media (max-width: 768px)': {
                            flexDirection: 'column',
                        }
                    }}>
                        <Stack direction='row' alignItems='center' spacing={1} sx={{ maxWidth: '80%' }}>
                            <ReportProblemOutlinedIcon sx={{ color: '#0096ff' }} />
                            <Box sx={{ maxWidth: '100%' }}>
                                <Typography variant='smallBold'>{mainMessage}</Typography>
                                <br />
                                <Typography variant='smallRegular'>{description}</Typography>
                            </Box>
                        </Stack>
                        <Button variant='contained' size="small" onClick={handleActionClick}>
                            Activate & Schedule
                        </Button>
                    </Stack>
                }
                sx={{
                    backgroundColor: '#e0f2ff',
                    color: '#323c46',
                    width: '100% !important',
                }}
            />
        </StyledSnackbarStack>
    );
};

export { SnackbarWithCta };
