import React, { useContext } from 'react';
import { Typography, Box, Stack, Card as MuiCard } from '@mui/material';
import { CardMenu } from '../CardMenu';

import { StyledAccountMenuBox, StyledWrapperBox } from './styles';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';

const bgColorMap = {
  BLUE: '#4BB1F8',
  GREEN: '#53DB89',
  ORANGE: '#F98A4B',
  RED: '#F96868',
  YELLOW: '#FDB107',
};

const Card = ({
  id,
  name,
  bgColor = 'BLUE',
  revenue = 'USD XXX',
  cost = 'USD XXX',
  handleClick,
  reloadFunction
}) => {
  const { user } = useContext(UserInfoContext);
  return (
    <MuiCard sx={{ borderRadius: '16px' }}>
      <StyledWrapperBox
        sx={{
          backgroundColor: bgColorMap[bgColor],
        }}
      >
        <Box pt='54px' />
        {['ADMIN', 'OWNER'].includes(user?.role) ? (
          <StyledAccountMenuBox>
            <CardMenu name={name} id={id} color={bgColor} reloadFunction={reloadFunction}/>
          </StyledAccountMenuBox>
        ) : null}
        <Box
          sx={{
            bgcolor: '#fff',
            borderRadius: '16px',
            maxHeight: '160px',
            minHeight: '120px',
            position: 'relative',
            cursor: 'pointer',
            p: 2,
          }}
          onClick={handleClick}
        >
          <Typography textAlign='center' variant='h3' sx={{ mt: 1, mb: 2 }}>
            {name}
          </Typography>
          <Box>
            <Stack
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              mb={1}
            >
              <Typography variant='smallMedium'>Revenue</Typography>
              <Typography
                variant='smallBold'
                color='primary'
                sx={{
                  display: 'flex',
                  justifyContent: ' space-between',
                  alignItems: 'flex-start',
                  maxWidth: ' 140px',
                  minWidth: '100px',
                }}
              >
                {revenue}
              </Typography>
            </Stack>
            <Stack
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography variant='smallMedium'>Ads Cost</Typography>
              <Typography
                variant='smallBold'
                color='primary'
                sx={{
                  display: 'flex',
                  justifyContent: ' space-between',
                  alignItems: 'flex-start',
                  maxWidth: ' 140px',
                  minWidth: '100px',
                }}
              >
                {cost}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </StyledWrapperBox>
    </MuiCard>
  );
};

export { Card };
