/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { Container, Box, Button, Grid } from '@mui/material';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';

import {
  useGetUsersAccessDetailsQuery,
  useCreateCompanyUsersMutation,
  useEditUsersDetailsMutation,
} from '../../../api/RTK/services/user';
import {
  AddCompanyUserSchema,
  AddAgencyCompanyUserEditSchema,
} from '../Schema/schemas';
import { FormikField } from '../../../widgets/FormikForm/FormikField';
import { TitleCard } from '../../FeedManagement/Components/GlobalComponents/TitlePaper';
import { StyledPaper } from '../../FeedManagement/PageViewComponents/StyledMainBox';
import { CategoryCheckbox } from '../../../widgets/SelectCategory/CategoryCheckbox';
import { LoadingDialog } from '../Components/LoadingDialog';
import { GlobalErrorComponent } from '../../../components/GlobalErrorComponent';

const AddCompanyUser = () => {
  const { user, companyList } = useContext(UserInfoContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    radioValue: '',
  });

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  const {
    data: getUser,
    isLoading: getLoading,
    isError: getError,
  } = useGetUsersAccessDetailsQuery({ id }, { skip: !isEdit });

  const [
    addUser,
    { isLoading: addUserLoading, isError: addIsError, error: addError },
  ] = useCreateCompanyUsersMutation();

  useEffect(() => {
    if (isEdit) {
      setInitialValues({
        name: getUser?.username || '',
        email: getUser?.email || '',
        radioValue:
          (user.projectType === 'AGENCY'
            ? getUser?.role === 'SUB_ADMIN'
              ? 'company_admin'
              : 'company_collaborator'
            : getUser?.role.toLowerCase()) || '',
      });
    }
  }, [getUser]);
  const [
    editUser,
    { isError: editUserError, isLoading: editLoading, error: editError },
  ] = useEditUsersDetailsMutation({
    skip: !isEdit,
  });

  const object = [];
  if (companyList.length > 0) {
    companyList.forEach((item) => {
      object.push({
        title: item.name,
        value: item.id,
      });
    });
  }

  const handleOnSubmit = async (values) => {
    const userData = {
      user_type: _.replace(values.radioValue.toUpperCase(), /-/g, '_'),
      agency_id: user?.projectId,
    };
    const userAgencyData = {
      user_type:
        values.radioValue === 'company_admin'
          ? 'SUB_ADMIN'
          : 'SUB_COLLABORATOR',
    };

    const requestData = isEdit
      ? {
          id,
          data: user.projectType === 'AGENCY' ? userAgencyData : userData,
        }
      : {
          name: values.name,
          email: values.email,
          projectID: user?.projectId,
          userType: userData.user_type,
        };
    const requestFunction = isEdit ? editUser : addUser;
    try {
      await requestFunction(requestData)
        .unwrap()
        .then(() => {
          setOpen(true);
          setTimeout(() => setOpen(false), 2999);
          setTimeout(() => navigate('/users/company'), 3000);
        })
        .catch(() => {
          setOpen(true);
        });
    } catch (errors) {
      console.error('Error occurred:', errors);
    }
  };

  const data = [
    {
      id: 1,
      value: `${user.projectType === 'AGENCY' ? 'company_admin' : 'admin'}`,
      labels: ['Accounts', 'Dashboard', 'Feed Management', 'Users', 'Payment'],
      labelSecond: [
        'View',
        'View & Customize',
        'Create, Edit & Delete all projects, Feeds, Actions, Filters, Schedule',
        'Add, Edit & Delete',
        'View history',
      ],
    },
    {
      id: 2,
      value: `${
        user.projectType === 'AGENCY' ? 'company_collaborator' : 'collaborator'
      }`,
      labels: ['Accounts', 'Dashboard', 'Feed Management', 'Users', 'Payment'],
      labelSecond: ['View', 'View & Customize', 'View', 'View', 'View history'],
    },
  ];

  return (
    <FetchingDisplay
      isLoading={getLoading}
      isError={getError}
      ErrorElement={<GlobalErrorComponent />}
      LoadingElement={<GlobalLoadingComponent />}
      SuccessElement={
        <>
          <Container maxWidth='xxl' sx={{ marginTop: '30px' }}>
            <TitleCard title='Company User Access' />
            <StyledPaper>
              <Box>
                <Formik
                  enableReinitialize
                  validateOnBlur={false}
                  initialValues={initialValues}
                  validationSchema={
                    user.projectType === 'AGENCY'
                      ? AddAgencyCompanyUserEditSchema
                      : AddCompanyUserSchema
                  }
                  onSubmit={handleOnSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item md={6}>
                          <FormikField
                            required
                            placeholder='Enter Name'
                            name='name'
                            type='text'
                            value={values.name}
                            label='Name'
                            error={Boolean(errors.name) && touched.name}
                            helperText={touched.name && errors.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isEdit}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <FormikField
                            required
                            placeholder='Enter Email ID'
                            name='email'
                            type='email'
                            value={values.email}
                            label='Email'
                            error={Boolean(errors.email) && touched.email}
                            helperText={touched.email && errors.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isEdit}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <CategoryCheckbox
                            data={data}
                            value={values.radioValue}
                            setValue={(newValue) => {
                              setValue(newValue);
                              handleChange({
                                target: { name: 'radioValue', value: newValue },
                              });
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='radioValue'
                            error={errors.radioValue}
                            variant='smallRegularFaded'
                          />
                        </Grid>
                      </Grid>
                      <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        gap={3}
                        mt={3}
                      >
                        <Button
                          variant='outlined'
                          component={Link}
                          onClick={() => navigate(-1)}
                          sx={{ minWidth: '200px', maxWidth: '220px', p: 1 }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type='submit'
                          variant='contained'
                          sx={{ minWidth: '200px', maxWidth: '220px', p: 1 }}
                        >
                          Save
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </StyledPaper>
          </Container>
          <LoadingDialog
            open={open}
            isLoading={isEdit ? editLoading : addUserLoading}
            isError={isEdit ? editUserError : addIsError}
            onClose={() => setOpen(false)}
            successMessage={isEdit ? 'Access Edited' : 'Invite Send'}
            errorMessage={
              isEdit
                ? editError?.data?.error?.detail
                : addError?.data?.error?.detail
            }
          />
        </>
      }
    />
  );
};

export { AddCompanyUser };
