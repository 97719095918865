import { createTheme } from '@mui/material/styles';

const design = createTheme({
  palette: {
    primary: {
      main: '#0096FF',
      light: '#A3D9FF',
      lighter: '#E0F2FF',
    },
    secondary: {
      main: '#FF823C',
      light: '#FFD2B8',
      lighter: '#FFF0E7',
    },
    // error added
    error: {
      main: '#E13F2A',
      light: '#FFD2B8',
      lighter: '#FFF0E7',
    },
    success: {
      main: '#1DB949',
      lighter: '#E3F9E3',
    },
    border: {
      main: '#DEDEDE',
      primary: '#A3D9FF',
      secondary: '#FFD2B8',
    },
    text: {
      primary: '#323C46',
      grey: '#999999',
      faded: '#BBB8B8',
    },
    background: {
      default: '#FFF',
      main: '#FFF',
      grey: '#EEEEEE',
      lightGrey: '#F2F2F2',
      lightBlue: '#D4ECFC',
      hoverBlue: '#01A8FF',
      hoverOrange: '#FE9D68',
      fadedGrey: '#F5F6FA',
    },
    svg: {
      primary: '#0096FF',
      white: '#FFF',
      grey: '#999999',
    },
    stock: {
      inStock: '#1DB949',
      outOfStock: '#E13F2A',
    },
  },
  shadows: [
    'none',
    '0px 0px 10px rgba(0,0,0,.08)',
    '0px 0px 10px rgba(0,0,0,.16)',
    '0px 3px 10px rgba(0,0,0,.08)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  spacing: (factor) => `${0.5 * factor}rem`,
});

const theme = createTheme(design, {
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 16,
    h1: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '1.375rem',
      fontWeight: '600',
      color: '#323C46',
    },
    h2: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '1.125rem',
      fontWeight: '600',
    },
    h3: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '1.1rem',
      fontWeight: '700',
      color: '#323C46',
    },
    h4: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '1rem',
      fontWeight: '600',
      color: '#323C46',
    },
    h5: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '.9rem',
      fontWeight: '600',
      color: '#323C46',
    },
    h6: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: '500',
      color: '#323C46',
    },
    body1: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '1rem',
    },
    bodySemiBold: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '1rem',
      fontWeight: '600',
      color: '#323C46',
    },
    bodyMedium: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '1rem',
      fontWeight: '500',
      color: '#323C46',
    },
    a: {
      fontWeight: '400',
    },
    button: {
      fontSize: '0.875rem',
      fontFamily: "'Montserrat', sans-serif",
      textTransform: 'none',
    },
    smallMedium: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '500',
      fontSize: '0.875rem',
    },
    smallRegular: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '400',
      fontSize: '0.875rem',
    },
    smallBold: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '600',
      fontSize: '0.875rem',
    },
    smallRegularFaded: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '400',
      fontSize: '0.875rem',
      color: design.palette.text.grey,
    },
    smallerBold: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '600',
      fontSize: '0.75rem',
    },
    smallerRegular: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '500',
      fontSize: '0.75rem',
    },
    bigSemiBold: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '1.125rem',
      fontWeight: '600',
      color: '#323C46',
    },
    primaryEmp: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '600',
      fontSize: '1.125rem',
      color: design.palette.primary.main,
    },
    secondaryEmp: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '600',
      fontSize: '1.125rem',
      color: design.palette.secondary.main,
    },
    pageHeading: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '1.5rem',
      fontWeight: '700',
      color: '#323C46',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: 'clamp(1rem, 0.5rem + 0.4vw, 2rem)',
        },
        body: {
          backgroundColor: '#F5F6FA',
          '.react-resizable-handle': {
            backgroundImage: 'none',
            bottom: '0',
            display: 'none',
            height: 'auto',
            right: '0',
            width: 'auto',
            zIndex: 401,
            '@media(min-width:600px)': {
              display: 'block',
            },
          },
          '.react-grid-item > .react-resizable-handle:after, .react-grid-item > .react-resizable-handle:before':
            {
              content: `''`,
              position: 'absolute',
              right: 'calc(50% + 1px)',
              bottom: 'calc(50% + 1px)',
              height: '1px',
              width: '100%',
              transform: 'translateX(50%) rotateZ(-50deg)',
              background: design.palette.text.grey,
              border: 'none',
            },
          '.react-grid-item > .react-resizable-handle:after': {
            right: 'calc(25% + 1px)',
            bottom: 'calc(25% + 1px)',
            width: '50%',
          },
        },
        '.screenReaderOnly': {
          position: 'absolute',
          width: '1px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          clipPath: 'circle(0%)',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          transition: '250ms color',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&:hover>.MuiFormLabel-root:not(.Mui-disabled)': {
            color: design.palette.primary.main,
          },
          '&:hover .MuiInputBase-root:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline':
            {
              borderColor: design.palette.primary.main,
            },
          '&:hover>.MuiFormLabel-root.Mui-error': {
            color: design.palette.error.main,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          boxShadow: 'none',
          lineHeight: design.spacing(3),
          '& .MuiSvgIcon-root.normal': {
            fontSize: '1.5rem',
            color: design.palette.text.primary,
          },
          '&:not(.Mui-disabled) .MuiSvgIcon-root': {
            color: design.palette.svg.grey,
          },
          '&:hover': {
            '& .MuiButton-startIcon .MuiSvgIcon-root': {
              color: design.palette.svg.primary,
            },
          },
        },
        iconSizeMedium: {
          '& >*:nth-of-type(1)': {
            width: 'auto',
          },
        },
      },
      variants: [
        {
          props: { variant: 'pill' },
          style: {
            borderRadius: '999px',
            backgroundColor: design.palette.background.default,
            boxShadow: design.shadows[1],
            height: design.spacing(5),
            padding: `${design.spacing(1)} ${design.spacing(2)}`,
            transition: 'box-shadow 100ms, outline 100ms',
            outline: `1px solid transparent`,
            '& .MuiButton-startIcon .MuiSvgIcon-root': {
              color: design.palette.svg.primary,
            },
            '&:hover': {
              boxShadow: design.shadows[0],
              outline: `1px solid ${design.palette.primary.main}`,
              backgroundColor: design.palette.background.default,
            },
          },
        },
        {
          props: { variant: 'pillOutlined' },
          style: {
            border: `1px solid ${design.palette.border.main}`,
            borderRadius: '999px',
            backgroundColor: design.palette.background.default,
            height: design.spacing(6),
            padding: `${design.spacing(1)} ${design.spacing(2)}`,
            '&:hover': {
              backgroundColor: design.palette.background.default,
              boxShadow: design.shadows[4],
              color: design.palette.primary.main,
              borderColor: design.palette.primary.main,
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            '& .MuiSvgIcon-root': {
              color: design.palette.svg.white,
            },
            '&:hover': {
              background: design.palette.background.hoverBlue,
              '& .MuiButton-startIcon .MuiSvgIcon-root': {
                color: design.palette.svg.white,
              },
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            '&:hover': {
              background: design.palette.background.fadedGrey,
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '.825rem',
          border: `1px solid ${design.palette.border.main}`,
          backgroundColor: design.palette.background.default,
          color: design.palette.text.primary,
          boxShadow: design.shadows[2],
          borderRadius: 6,
        },
        arrow: {
          color: design.palette.background.default,
          '&:before': {
            border: `1px solid ${design.palette.border.main}`,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',
          padding: `${design.spacing(1.5)} ${design.spacing(2)}`,
        },
        icon: {
          right: design.spacing(2),
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: '0px 0px',
        },
        divider: {
          margin: '0px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          padding: '0.75rem 1rem',
          '& + .MuiDivider-root': {
            marginTop: 0,
            marginBottom: 0,
          },
          '.menuItemSvg': {
            fontSize: '1.5rem',
            height: '1.5rem',
            marginLeft: '-0.25rem',
            marginRight: '0.5rem',
            width: 'auto',
            flexShrink: 0,
          },
          '&.small': {
            fontSize: '0.875rem',
          },
        },
      },
      variants: [
        {
          props: { variant: 'filter' },
          style: {
            padding: '0rem',
          },
        },
        {
          props: { variant: 'linkContainer' },
          style: {
            padding: '0rem',
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: '400',
          border: 'none',
          borderRadius: '4px',
          '&:hover:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline':
            {
              borderColor: design.palette.primary.main,
            },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: design.palette.error.main,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            // removed by kaveri
            // borderColor: '#0096FF',
          },
          'label[data-shrink="false"] + &.MuiOutlinedInput-root .MuiOutlinedInput-input::placeholder':
            {
              opacity: '0.42 !important',
            },
          '& .MuiTypography-root': {
            lineHeight: 1.2,
          },
          '& .Mui-disabled': {
            backgroundColor: 'rgb(0 96 255 / 5%)',
          },
        },
        input: {
          padding: `${design.spacing(1.5)} ${design.spacing(2)}`,
        },
        notchedOutline: {
          borderWidth: '1px',
          borderColor: '#DEDEDE',
          transition: 'border-color 100ms',
          padding: '0px 0px 0px calc(1.5rem - 0.25rem)',
          '&.Mui-disabled ': {
            backgroundColor: 'rgb(0 96 255 / 5%)',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat, sans-serif',
          margin: 'auto 0',
          fontWeight: 500,
          opacity: 0.42,
          '&.Mui-error': {
            opacity: 1,
          },
        },
      },
    },
    MuiStack: {
      variants: [
        {
          props: { variant: 'svgContainer' },
          style: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: 1.2,
          fontWeight: 500,
          transition:
            'color 100ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          color: design.palette.text.primary,
        },
        shrink: {
          transform: 'translate(24px, -9px) scale(0.75)',
        },
      },
      variants: [
        {
          props: { variant: 'actionLabel' },
          style: {
            fontWeight: 400,
            fontSize: '0.875rem',
            margin: '0rem 1rem 0rem 0rem',
          },
        },
      ],
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat, sans-serif',
          background: design.palette.background.default,
          borderBottom: `1px solid ${design.palette.border.main}`,
          borderRight: `1px solid ${design.palette.border.main}`,
          fontSize: '0.875rem',
          lineHeight: '18px',
          padding: design.spacing(2),
          '&.MuiTableCell-root:last-child': {
            borderRight: `0`,
          },
          '& .dataImage': {
            width: '3.5rem',
            height: '3.5rem',
            objectFit: 'contain',
            display: 'block',
            margin: `${design.spacing(-1)} auto ${design.spacing(-1)}`,
          },
        },
        head: {
          fontWeight: '700',
          borderBottom: `2px solid ${design.palette.border.main}`,
        },
        footer: {
          color: design.palette.text.primary,
          fontWeight: '700',
          borderTop: '2px solid #DEDEDE',
          background: design.palette.background.default,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child .MuiTableCell-root': {
            borderRight: `1px solid ${design.palette.border.main}`,
          },
          '& th.MuiTableCell-root:first-of-type': {
            '@media(min-width:600px)': {
              position: 'sticky',
              insetInlineStart: 0,
              zIndex: 3,
            },
          },
          '&.noVerticalBorders': {
            '& .MuiTableCell-root': {
              borderRight: 'none',
            },
          },
        },
        head: {
          '& th.MuiTableCell-root:first-of-type': {
            zIndex: 4,
          },
        },
        footer: {
          '& th.MuiTableCell-root:first-of-type': {
            zIndex: 4,
          },
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'toolbar' },
          style: {
            padding: `${design.spacing(2)} ${design.spacing(4)}`,
            position: 'sticky',
            top: '-1px',
            zIndex: 10,
            transition: '200ms',
            '&.is-pinned': {
              backgroundColor: design.palette.background.default,
              boxShadow: design.shadows[2],
              '& .MuiButton-pill': {
                outline: `1px solid ${design.palette.border.main}`,
                boxShadow: 'none',
              },
              '& .MuiButton-pill:hover': {
                outline: `1px solid ${design.palette.primary.main}`,
              },
            },
          },
        },
        {
          props: { variant: 'rounded' },
          style: {
            borderRadius: '0.5rem',
          },
        },
        {
          props: { variant: 'screenReaderOnly' },
          style: {
            position: 'absolute',
            width: '1px',
            clipPath: 'polygon(0 0,0 0,0 0,0 0)',
            overflow: 'hidden',
            whiteSpace: 'noWrap',
          },
        },
        {
          props: { variant: 'sidebar' },
          style: {
            '&.MuiPaper-root.MuiPaper-sidebar.MuiAccordion-root.Mui-expanded': {
              margin: '0px',
            },
            '&::before': {
              display: 'none',
            },
            '.MuiAccordionSummary-root': {
              padding: '0rem 2rem',
              justifyContent: 'start',
              alignItems: 'center',
              transition: 'background-color 100ms',
            },
            '.MuiAccordionSummary-root:hover': {
              backgroundColor: design.palette.background.grey,
            },
            '.MuiAccordionSummary-root.Mui-expanded': {
              minHeight: '56px',
            },
            '.MuiAccordionSummary-content': {
              margin: '1rem 0',
              fontWeight: '600',
              flex: 0,
              transition: 'color 100ms',
            },
            '.MuiAccordionSummary-content:hover': {
              color: design.palette.primary.main,
            },
            '.MuiAccordionSummary-content.Mui-expanded': {
              margin: '1rem 0rem',
            },
            '.MuiAccordionDetails-root': {
              padding: `0 0 0 1rem`,
            },
          },
        },
      ],
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontFamily: `'Montserrat', sans-serif`,
          flexShrink: 0,
        },
        selectLabel: {
          fontFamily: `'Montserrat', sans-serif`,
        },
        displayedRows: {
          fontFamily: `'Montserrat', sans-serif`,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: '999999',
          '&.blurredBg .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            backdropFilter: 'blur(5px)',
          },
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          position: 'sticky',
          insetBlockEnd: 0,
          zIndex: 3,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: '500',
          // added by kaveri
          textDecoration: 'none',
          cursor: 'poiner',
        },
      },
      variants: [
        {
          props: { variant: 'footer' },
          style: {
            color: design.palette.text.primary,
            fontSize: '0.875rem',
            fontWeight: 400,
            transition: 'color 100ms',
            '&:hover': {
              color: design.palette.primary.main,
            },
          },
        },
        {
          props: { variant: 'sidebar' },
          style: {
            transition: 'background-color 100ms',
            '&:hover': {
              backgroundColor: design.palette.background.grey,
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '&.MuiInputBase-root.MuiOutlinedInput-root': {
            padding: '0.75rem 1rem',
            paddingRight: '10px',
          },
        },
        input: {
          '&.MuiOutlinedInput-input': {
            padding: 0,
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: design.palette.primary.main,
            opacity: '.8',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        middle: {
          margin: '0px 6px',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: design.shadows[3],
        },
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { variant: 'standardError' },
          style: {
            backgroundColor: design.palette.error.lighter,
            color: design.palette.error.main,
            margin: `${design.spacing(2)} ${design.spacing(0)}`,
          },
        },
        {
          props: { variant: 'standardSuccess' },
          style: {
            backgroundColor: design.palette.success.lighter,
            color: design.palette.success.main,
            margin: `${design.spacing(2)} ${design.spacing(0)}`,
          },
        },
      ],
    },
  },
});

export { theme };
