/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Typography,
  Avatar,
  Stack,
  Container,
  Skeleton,
  Box,
} from '@mui/material';
import { StyledPaper } from '../utils';
import { CustomTable } from '../../../../components/Table';

const headCells = () => [
  {
    id: 'username',
    numeric: false,
    label: `Users`,
    disablePadding: false,
    isImage: false,
    formatFunction: () => (
      <Stack
        display='flex'
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
        gap={2}
      >
        <Skeleton variant='circular' animation='wave'>
          <Avatar />
        </Skeleton>
        <Box width='100%' display='flex' flexDirection='column'>
          <Skeleton width='70%' height={24} animation='wave'>
            <Typography>.</Typography>
          </Skeleton>
          <Skeleton width='100%' height={18} animation='wave'>
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Stack>
    ),
  },
];

const obj = Object.fromEntries(
  Array.from({ length: 12 }, (_, index) => [index, ''])
);

const dataFormatter = (ob) =>
  Object.values(ob).map((d, index) => ({
    ...d,
    key: index,
  }));

const UsersSkeleton = () => (
  <Container
    maxWidth={false}
    sx={{
      mt: 1,
      mb: 2,
      position: 'relative',
      '.MuiTableContainer-root': {
        borderRadius: '14px',
      },
    }}
  >
    {/* <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      // sx={{
      //   flex: 1,
      //   order: -1,
      //   '@media(min-width: 800px)': { order: 0 },
      // }}
      p={2}
    >
      <Typography
        component='h2'
        variant='pageHeading'
        sx={{ whiteSpace: 'nowrap' }}
      >
        {/* {toggled === 'Agency Users'
          ? `${projectName} Users`
          : `${projectName} Company Users`} */}
    {/* Agency Users
      </Typography> */}
    {/* </Box> */}
    <StyledPaper sx={{ mt: 10 }}>
      <CustomTable
        headCells={headCells()}
        rows={dataFormatter(obj)}
        rowIdField='key'
        displayFooter={false}
      />
    </StyledPaper>
  </Container>
);

export { UsersSkeleton };
