/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import React, { useEffect, useState, useContext } from 'react';

import {
  Divider,
  Stack,
  Typography,
  Button,
  Skeleton,
  Paper,
  Box,
} from '@mui/material';

import _ from 'lodash';
import moment from 'moment';
import { InfoTooltip } from '../../../../../components/InfoTooltip';
import { POASDatePicker } from './POASDatePicker';
import { RangeSliderComponentMain } from './RangeSliderMain';
import { StyledPOAScalculatorWrapper } from './StyledPOASCalculator';
import { DashboardService } from '../../../../../services/dashboard';
import { POASYearlyGraph } from '../POASGraphs/YearlyGraph';
import { ErrorDisplay } from '../../../../../components/ErrorDisplay';
import { PoasOverlay } from '../../../../../widgets/UniversalOverlay/PoasOverlay';
import { UserInfoContext } from '../../../../../context/UserInfoContext';

const ResultComponent = ({
  editButtonState,
  isError,
  value,
  state,
  graphData,
  currency,
  setEditButtonState,
  poasSheetData,
}) => {
  if (editButtonState) {
    if (isError) {
      return (
        <Box height='400px' display='flex' flexDirection='column'>
          <ErrorDisplay
            refresh={() => {}}
            message='Something went wrong.'
            showRefreshButton={false}
          />
        </Box>
      );
    }
    return (
      <POASYearlyGraph
        value={value}
        state={state}
        graphData={graphData}
        currency={currency}
        setEditButtonState={setEditButtonState}
        poasSheetData={poasSheetData}
      />
    );
  }
  return null;
};

const POASCalculatorMain = ({
  state,
  requestData,
  setTotalRevenue,
  totalRevenue,
  currency,
  dateRangeList,
  setPOAS,
  poas,
  setProfitPercentage,
  profitPercentage,
  setGraphData,
  graphData,
  getResponse,
  value,
  setValue,
  setTotalProfit,
  totalProfit,
  selectedObject,
  setSelectedObject,
}) => {
  const [editButtonState, setEditButtonState] = useState(false);
  const [productMakingCost, setProductMakingCost] = useState(0);
  const [marketingCost, setMarketingCost] = useState(0);
  const [tax, setTax] = useState(0);
  const [packingCost, setPackingCost] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [otherCosts, setOtherCosts] = useState(0);
  const [productMakingValue, setProductMakingValue] = useState(0);
  const [marketingValue, setMarketingValue] = useState(0);
  const [taxValue, setTaxValue] = useState(0);
  const [packingValue, setPackingValue] = useState(0);
  const [shippingValue, setShippingValue] = useState(0);
  const [otherValue, setOtherValue] = useState(0);
  const [name, setName] = useState('');
  const [month, setMonth] = useState('');
  const poasList = [];
  const profitList = [];
  const [poasSheetData, setPoasSheetData] = useState([]);
  const [isError, setIsError] = useState(null);

  const { accountList } = useContext(UserInfoContext);

  requestData.product_making_cost = _.round(productMakingValue);
  requestData.marketing_cost = _.round(marketingValue);
  requestData.tax = _.round(taxValue);
  requestData.packing_cost = _.round(packingValue);
  requestData.shipping_cost = _.round(shippingValue);
  requestData.other_cost = _.round(otherValue);
  requestData.poas = _.round(poas, 2);
  requestData.profit_percentage = _.round(profitPercentage, 2);
  requestData.total_revenue = totalRevenue;
  requestData.poas_month = moment(`01 ${value.replace('-', ' ')}`).format(
    'YYYY-MM-DD'
  );

  const postPoasData = () => {
    setIsError(false);
    setEditButtonState(true);
    const data = requestData;
    DashboardService.postProfitData(data)
      .then((response) => {
        const respData = response.data;
        _.orderBy(respData, 'poas_month', 'asc').forEach((ele) => {
          poasList.push(ele.poas);
          profitList.push(ele.profit_percentage);
        });

        setSelectedObject(
          respData.find(
            (element) =>
              element.poas_month ===
              moment(`01 ${value.replace('-', ' ')}`).format('YYYY-MM-DD')
          )
        );

        if (!_.isUndefined(setSelectedObject)) {
          setProductMakingCost(
            _.round(
              (setSelectedObject.product_making_cost / totalRevenue) * 100
            )
          );
          setMarketingCost(
            _.round((setSelectedObject.marketing_cost / totalRevenue) * 100)
          );
          setTax(_.round((setSelectedObject.tax / totalRevenue) * 100));
          setPackingCost(
            _.round((setSelectedObject.packing_cost / totalRevenue) * 100)
          );
          setShippingCost(
            _.round((setSelectedObject.shipping_cost / totalRevenue) * 100)
          );
          setOtherCosts(
            _.round((setSelectedObject.other_cost / totalRevenue) * 100)
          );
          setTotalRevenue(setSelectedObject.total_revenue);
        }

        setName(respData[0].modified_by);
        setMonth(respData[0].modified_date);
        setGraphData({
          labels: dateRangeList,
          datasets: [
            {
              label: 'POAS',
              data: poasList,
              fill: false,
              backgroundColor: '#FF823C',
              borderColor: 'rgb(26, 143, 240)',
              yAxisID: 'y1',
              minbarThickness: 30,
              maxBarThickness: 60,
              borderRadius: 5,
            },
            {
              label: 'Profit',
              data: profitList,
              fill: false,
              backgroundColor: '#0096FF',
              borderColor: 'rgb(255, 107, 61)',
              yAxisID: 'y',
              minbarThickness: 30,
              maxBarThickness: 60,
              borderRadius: 5,
            },
          ],
        });

        setPoasSheetData(
          respData.map((sheetData) => ({
            date: moment(sheetData.poas_month).format('MMMM-YYYY'),
            product_making_cost: sheetData.product_making_cost,
            marketing_cost: sheetData.marketing_cost,
            packing_cost: sheetData.packing_cost,
            shipping_cost: sheetData.shipping_cost,
            tax: sheetData.tax,
            other_cost: sheetData.other_cost,
            poas: sheetData.poas,
            profit_percentage: sheetData.profit_percentage,
          }))
        );
      })
      .catch((error) => {
        console.error('failed to check POAS data', error);
        setIsError(true);
      });
  };

  useEffect(() => {
    if (!_.isUndefined(getResponse)) {
      if (!_.isUndefined(selectedObject)) {
        // console.log('Obj: ', selectedObject);
        setProductMakingCost(
          _.round((selectedObject.product_making_cost / totalRevenue) * 100)
        );
        setMarketingCost(
          _.round((selectedObject.marketing_cost / totalRevenue) * 100)
        );
        setTax(_.round((selectedObject.tax / totalRevenue) * 100));
        setPackingCost(
          _.round((selectedObject.packing_cost / totalRevenue) * 100)
        );
        setShippingCost(
          _.round((selectedObject.shipping_cost / totalRevenue) * 100)
        );
        setOtherCosts(
          _.round((selectedObject.other_cost / totalRevenue) * 100)
        );
        setTotalRevenue(selectedObject.total_revenue);
        setName(selectedObject.modified_by);
        setMonth(selectedObject.modified_date);
      } else {
        setProductMakingCost(0);
        setMarketingCost(0);
        setTax(0);
        setPackingCost(0);
        setShippingCost(0);
        setOtherCosts(0);
      }
    }
  }, [value, getResponse, totalRevenue]);

  const GAAccountList = accountList
    ? accountList?.filter((acc) => acc.platform === 'GOOGLE_ANALYTICS')
    : [];
  return (
    <StyledPOAScalculatorWrapper>
      <Paper sx={{ borderRadius: '.8rem', mb: 4 }}>
        <Box className='POAScalculator'>
          <Stack
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{ p: 2, flexDirection: { xs: 'column', sm: 'row' } }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Typography variant='h4'>POAS & Profit Calculator</Typography>
              {!_.isEmpty(name) && (
                <Typography
                  variant='smallMedium'
                  sx={{
                    mt: 0.5,
                    pr: 1,
                  }}
                >
                  {_.capitalize(name)} entered the details in the month of{' '}
                  {moment(month).format('MMMM')}
                </Typography>
              )}
            </Box>
            <POASDatePicker
              value={value}
              setValue={setValue}
              dateRangeList={dateRangeList}
            />
          </Stack>
          <Divider />
          <Box
            sx={{
              p: 2,
              maxWidth: '100%',
              overflow: 'hidden',
              position: 'relative',
              minHeight: '400px',
            }}
          >
            {GAAccountList?.length > 0 ? (
              <>
                <Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant='body1'>
                      Note&#8758; For best results, please make sure to put
                      accurate numbers for the desired period (a month, a
                      quarter, or a year)
                    </Typography>
                  </Box>
                  <Stack>
                    <Box
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      flexDirection='row'
                      sx={{ marginBottom: '12px' }}
                    >
                      <Box display='flex' alignItems='center'>
                        <Typography variant='smallMedium' sx={{ mr: 1 }}>
                          Total Revenue
                        </Typography>
                        <InfoTooltip title='Total amount earned from selling your product(s).' />
                      </Box>
                      <Box>
                        <Typography variant='h5'>
                          <span className='totalRevenue'> &#8211; </span>{' '}
                          {currency} {totalRevenue}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                  {state === 'LOADING' ? (
                    <Skeleton height={80} sx={{ width: '94vw' }} />
                  ) : (
                    editButtonState === false && (
                      <>
                        <RangeSliderComponentMain
                          // for storing the actual values from the range slider after calculating the values
                          setProductMakingValue={setProductMakingValue}
                          setMarketingValue={setMarketingValue}
                          setTaxValue={setTaxValue}
                          setPackingValue={setPackingValue}
                          setShippingValue={setShippingValue}
                          setOtherValue={setOtherValue}
                          totalRevenue={totalRevenue}
                          // for storing the range slider values(0-100 range) from the slider
                          setProductMakingCost={setProductMakingCost}
                          productMakingCost={productMakingCost}
                          setMarketingCost={setMarketingCost}
                          marketingCost={marketingCost}
                          setTax={setTax}
                          tax={tax}
                          setPackingCost={setPackingCost}
                          packingCost={packingCost}
                          setShippingCost={setShippingCost}
                          shippingCost={shippingCost}
                          setOtherCosts={setOtherCosts}
                          otherCosts={otherCosts}
                          // for storing profit and profitPercentage
                          setTotalProfit={setTotalProfit}
                          totalProfit={totalProfit}
                          setProfitPercentage={setProfitPercentage}
                          setPOAS={setPOAS}
                          value={value}
                          getResponse={getResponse}
                        />
                        <Stack
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                          sx={{ mt: 2 }}
                        >
                          <Button
                            variant='contained'
                            sx={{ width: 'auto' }}
                            onClick={postPoasData}
                          >
                            Calculate POAS and Profit
                          </Button>
                        </Stack>
                      </>
                    )
                  )}
                </Box>
                <ResultComponent
                  editButtonState={editButtonState}
                  isError={isError}
                  value={value}
                  state={state}
                  graphData={graphData}
                  currency={currency}
                  setEditButtonState={setEditButtonState}
                  poasSheetData={poasSheetData}
                />
              </>
            ) : (
              <PoasOverlay />
            )}
          </Box>
        </Box>
      </Paper>
    </StyledPOAScalculatorWrapper>
  );
};

export { POASCalculatorMain };
