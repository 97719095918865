import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';

const OpenButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  padding: 0px;
`;

const CustomTable = styled(Table)(`
  border-collapse: separate;
`);
const DashboardCustomTable = styled(Table)(`
  border-collapse: separate;
`);
const CustomRow = styled(TableRow)(`
  th.MuiTableCell-root:first-of-type {
    @media(min-width:600px) {
      max-width: 0px;
      min-width: 250px;
      width: 35%;
    }
  }
  td.MuiTableCell-root:last-of-type {
   position: sticky;
   right:0px;
   z-index:3;
  }

  &.MuiTableRow-root .MuiTableCell-root {
    border-right: none;
  }
`);

const CollapsedRow = styled(CustomRow)(
  ({ theme }) => `
  .MuiTableCell-root {
    background-color: ${theme.palette.background.lightGrey}
  }
`
);

const ParentRow = styled(CustomRow)(
  ({ theme, open }) => `
  .MuiTableCell-root {
    transition: background-color ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut
    };
    background-color: ${open
      ? theme.palette.background.lightGrey
      : theme.palette.background.default
    };
  }
`
);

const CustomHeadRow = styled(CustomRow)(`
background-color:#000;
&.tableRow {
  background-color: transparent !important;
}
  .MuiTableCell-root:first-of-type {
    z-index: 4;
  }
  .MuiTableCell-root {
    z-index: 3;   
  }
  th.MuiTableCell-root:last-of-type {
    position: sticky;
    right:0px;
    z-index:5;
 
   }
`);

const CollapsedContainerCell = styled(TableCell)(`
  padding: 0px;
  border: none;
`);

const CustomBody = styled(TableBody)(`
  &.MuiTableRow-root:last-of-type .MuiTableCell-root:last-of-type{
    border-bottom: none;
  }
`);

export {
  ParentRow,
  OpenButton,
  CustomBody,
  CustomTable,
  DashboardCustomTable,
  CustomHeadRow,
  CustomRow,
  CollapsedRow,
  CollapsedContainerCell,
};
