import { api } from '../api';

const invitesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInvitesList: builder.query({
      query: () => ({
        url: `/users/invite`,
        method: 'GET',
      }),
      providesTags: ['INVITE'],
    }),
    rejectInvitation: builder.mutation({
      query: ({ token, invitedBy }) => ({
        url: `/users/reject-invite/${token}`,
        method: 'POST',
        body: {
          invited_by: invitedBy,
        },
      }),
      invalidatesTags: ['INVITE'],
    }),
    acceptInvitation: builder.mutation({
      query: ({ token, invitedBy }) => ({
        url: `/users/accept-invite/${token}`,
        method: 'POST',
        body: {
          invited_by: invitedBy,
        },
      }),
      invalidatesTags: ['INVITE'],
    }),
  }),
});

export const {
  useGetInvitesListQuery,
  useRejectInvitationMutation,
  useAcceptInvitationMutation,
} = invitesApi;
