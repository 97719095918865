import { styled } from '@mui/system';
import { Box, Modal } from '@mui/material';

const StyledModal = styled(Modal)(
  () => `
  right: -8px;
  overflow-y: scroll;
  min-width: 400px;  
  @media(max-width:600px){
    min-width: 300px;  
  }
  .MuiModal-root{
    display:flex;
    justify-content:center;
  }
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter:blur(5px);
   }
          `
);

const StyledGlobalModal = styled(Box)(
  ({ theme }) => `
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color:${theme.palette.background.main};
      border-radius: 12px;
      max-width: 500px;
      min-width: 400px;
      padding:${theme.spacing(4)};
      box-shadow: ${theme.shadows[2]};
      max-height: 80%;
      overflow-y:auto;
      text-align: center;
      @media(max-width:1200px){
        top: 10%;
        left: 50%;
        transform: translate(-50%, 0%);
        padding:${theme.spacing(3)};
        }
      @media(max-width:600px){
          max-width: 500px;
          padding:${theme.spacing(2)} ${theme.spacing(1.5)};
          min-width: 95%;
      }
       
     
    `
);

const StyledGlobalModalLG = styled(StyledGlobalModal)`
  width: 90%;
  max-width: 100%;
  @media (max-width: 600px) {
    minwidth: 95%;
  }
  @media (max-width: 900px) {
    minwidth: 70%;
  }
  @media (max-width: 1200px) {
    minwidth: 800px;
  }
  @media (min-width: 1200px) {
    minwidth: 800px;
  }
`;

export { StyledModal, StyledGlobalModal, StyledGlobalModalLG };
