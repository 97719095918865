import React, { useState, useMemo, useContext } from 'react';
import { Box } from '@mui/material';
import { PublicOutlined, TableChartOutlined } from '@mui/icons-material';
import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { getDemoDashboardService } from '../../../../../../services/DemoDashboard';
import { UserByLocationChart } from '../../../../Databoxes/UserByLocation/Chart';
import { Filter } from '../../../../../../components/Filter';

import { CustomSwitch } from '../../../../../../components/CustomSwitch/CustomSwitch';
import { Loading } from '../../../../../../components/Databox/Loading';

import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { UserByLocationTable } from '../../../../Databoxes/UserByLocation/Table';

import { AccountContext } from '../../accountContext';

const getFilterList = (platform) =>
  platform === 'meta'
    ? [
        {
          name: 'Reach',
          value: 'Reach',
        },
        {
          name: 'Spend',
          value: 'Spend',
        },
        {
          name: 'Clicks',
          value: 'Clicks',
        },
        {
          name: 'Impressions',
          value: 'Impressions',
        },
        {
          name: 'CPC',
          value: 'Cpc',
        },
      ]
    : [
        {
          name: 'Clicks',
          value: 'clicks',
        },
        {
          name: 'Cost',
          value: 'marketing_cost',
        },
        {
          name: 'Conversions',
          value: 'conversions',
        },
        {
          name: 'Revenue',
          value: 'online_sales',
        },
        {
          name: 'ROAS',
          value: 'roas',
        },
      ];

const DisplayComponentSelector = ({ showTable, chart, table }) => {
  if (showTable) {
    return table;
  }

  return chart;
};

const UserByLocationContainer = ({ platform }) => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState(getFilterList(platform)[0]);

  const { currencyCode } = useContext(AccountContext);

  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'USER_LOCATION',
    }),
    []
  );

  const [state] = useDataFetcher(
    [],
    getDemoDashboardService(platform),
    memoizedArgs,
    count
  );

  const [showTable, setShowTable] = useState(false);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleChange = () => {
    setShowTable(!showTable);
  };

  return (
    <Databox>
      <DataboxHeading
        title='User by Location'
        info='Know your audience by location and also your ad account’s performance in a particular country/region. Use filters for specific details and click on the table icon to switch to a tabular format.'
      >
        {!showTable ? (
          <Filter
            label='Filter'
            selectedFilter={filter}
            filterList={getFilterList(platform)}
            handleFilterChange={handleFilterChange}
          />
        ) : null}
      </DataboxHeading>
      <FetchingDisplay
        isError={state.isError}
        isLoading={state.isLoading}
        LoadingElement={<Loading />}
        ErrorElement={<ErrorDisplay />}
        SuccessElement={
          <DisplayComponentSelector
            showTable={showTable}
            chart={
              <UserByLocationChart
                data={state.data}
                filter={filter}
                currencyCode='USD'
                platform={platform}
              />
            }
            table={
              <UserByLocationTable
                data={state.data}
                platform={platform}
                currencyCode={currencyCode}
              />
            }
          />
        }
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', padding: 2 }}>
        <CustomSwitch
          checked={showTable}
          handleChange={handleChange}
          CheckedIcon={TableChartOutlined}
          Icon={PublicOutlined}
        />
      </Box>
    </Databox>
  );
};

export { UserByLocationContainer };
