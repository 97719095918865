/* eslint-disable max-classes-per-file */

import { openInstance } from '../api/open.instance';
import { authInstance } from '../api/authentication.instance';

import {
  authTokenURI,
  usersMeURI,
  authTokenRefreshURI,
  accountsURI,
  authTokenRegisterURI,
  authForgetPasswordURI,
  signupURI,
  profileURI,
  resetPasswordURI,
  forgetPasswordURI,
  resendVerificationEmailURI,
} from '../constants/endpoints';

// returns accessToken if successful
const checkAuth = () =>
  new Promise((resolve, reject) => {
    openInstance
      .get(authTokenURI)
      .then((response) => {
        if (response.data.is_authenticated === true) {
          return openInstance.post(authTokenRefreshURI);
        }
        throw new Error('Error while getting token');
      })
      .then((response) => {
        resolve({
          loggedIn: true,
          accessToken: response.data.access,
        });
      })
      .catch((error) => {
        reject(new Error(error));
      });
  });

// returns email, username and internal_user fields
const fetchUserInfo = () =>
  authInstance
    .get(usersMeURI)
    .then((response) => response.data)
    .catch(() => {});

// returns accounts affiliated with the provided companyId
const fetchAccounts = async (loggedIn, companyId) => {
  let data = {};
  if (loggedIn) {
    const response = await authInstance.get(accountsURI, {
      params: {
        company_id: companyId,
      },
    });
    data = response.data;
  }
  return data;
};

class AuthService {
  static register = (data) => openInstance.post(authTokenRegisterURI, data);

  static login = (email, password, userLogin) => {
    const data = {
      email,
      password,
    };
    return new Promise((resolve, reject) => {
      openInstance
        .post(authTokenURI, data)
        .then((response) => {
          const status = response === undefined ? 404 : response.status;
          if (status === 200) {
            userLogin({ accessToken: response.data.access });
            window.location.href = '/accounts';
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  static signup = (data, config = {}) =>
    openInstance.post(signupURI, data, config);

  static editProfile = (data, config = {}) =>
    authInstance.patch(profileURI, data, config);

  static profile = (config = {}) => authInstance.get(profileURI, config);

  static resetPassword = (data, config = {}) =>
    openInstance.post(resetPasswordURI, data, config);

  static forgetPassword = (config = {}) =>
    openInstance.post(forgetPasswordURI, config);

  static resendVerificationEmail = (config = {}) =>
    openInstance.post(resendVerificationEmailURI, config);
}

class AuthenticationService {
  static login = (data) => openInstance.post(authTokenURI, data);

  static forgotPassword = (data) =>
    openInstance.post(authForgetPasswordURI, data);
}

const getAccounts = (args) =>
  authInstance.get(accountsURI, {
    params: {
      company_id: args.companyId,
    },
  });

export {
  checkAuth,
  fetchUserInfo,
  fetchAccounts,
  AuthService,
  AuthenticationService,
  getAccounts,
};
