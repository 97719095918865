import styled from '@emotion/styled';
import { TextField, Box, Autocomplete } from '@mui/material';

const StyledBox = styled(Box)`
  margin-top: 12px;
  width: auto;
  @media (max-width: 600) {
    margin-top: 0px;
    width: 100%;
  }
  .MuiPaper-root {
    padding: 0px 0px;
    font-size: 14px;
  }
  .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 0px solid #323c46;
  }
  .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 0px solid #323c46;
  }
  .Muiinput-root::after {
    border-bottom: 0px solid rgb(0, 0, 0);
  }
  .MuiInput-root:before {
    border-bottom: 0px solid rgba(0, 0, 0, 0);
  }
  .MuiInput-root {
    padding-bottom: 0px;
  }
`;

const StyledAutocomplete = styled(Autocomplete)`
  border: 1px solid #bfbfbf;
  border-radius: 80px;
  padding: 4px 16px;
  font-size: 14px;
  width: 230px;
  .MuiAutocomplete-popupIndicator {
    transform: rotate(0deg);
  }
`;

const StyledTextField = styled(TextField)`
  font-size: 14px;
  .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 0px solid #323c46;
  }
  .MuiInput-root:before {
    border-bottom: 0px solid rgba(0, 0, 0, 0);
  }
  .MuiInput-root:after {
    border-bottom: 0px solid #0096ff;
  }
  .MuiInput-input {
    font-size: 14px;
    font-weight: 500;
  }
  ::placeholder {
    font-size: 14px;
  }
  .MuiAutocomplete-popupIndicator {
    font-size: 2rem;
    color: rgba(0, 0, 0, 0.8);
  }
`;

export { StyledBox, StyledAutocomplete, StyledTextField };
