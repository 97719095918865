/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addSelectedMetric } from '../../FeedManagement/Redux/CustomizedColumn/slice';
import { FetchingDataLoader } from '../../../widgets/Loader/FetchingDataLoader';

const grid = 8;

const getListStyle = () => ({
  padding: grid,
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  marginBottom: grid,
  ...draggableStyle,
});

const DragItem = styled.div`
  padding: 6px;
  border-radius: 4px;
  border: 1px solid #dedede;
  background: white;
  display: grid;
  flex-direction: column;
`;
const useDraggableInPortal = () => {
  const self = useRef({}).current;

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    div.style.zIndex = '9999999';
    self.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);

  return (render) =>
    (provided, ...args) => {
      const element = render(provided, ...args);
      if (provided.draggableProps.style.position === 'fixed') {
        return createPortal(element, self.elt);
      }
      return element;
    };
};
const DragableComponent = ({ resp }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const select = useSelector(
    (state) => state?.customizedColumnRedux?.selectedMetric
  );
  const renderDraggable = useDraggableInPortal();

  useEffect(() => {
    setItems(select || []);
  }, [select]);

  const updateMetricOrderAndDispatch = (items, dispatch) => {
    const newItems = items.map((item, index) => ({
      ...item,
      order: index,
    }));
    dispatch(addSelectedMetric(newItems));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const newItems = Array.from(items);
    const [removed] = newItems.splice(sourceIndex, 1);
    newItems.splice(destinationIndex, 0, removed);
    setItems(newItems);
    updateMetricOrderAndDispatch(newItems, dispatch);
  };

  const handleRemoveMetric = (item) => {
    const itemToRemove = items.find((i) => i.value === item.value);
    if (itemToRemove) {
      const newItems = items.filter((i) => i.value !== itemToRemove.value);
      updateMetricOrderAndDispatch(newItems, dispatch);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '360px',
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        {resp?.isLoading && items.length <= 1 ? (
          <FetchingDataLoader />
        ) : (
          <Box
            sx={{
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {items && items.length > 0 ? (
                    items.map((item, index) => (
                      <Box key={item.value} sx={{ textAlign: 'left' }}>
                        <Draggable
                          key={item.value}
                          draggableId={item.value}
                          index={index}
                        >
                          {renderDraggable((provided) => (
                            <DragItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Box display='flex' alignItems='center'>
                                  <DragIndicatorIcon
                                    sx={{
                                      fontSize: '18px',
                                      mr: '4px',
                                      color: (theme) =>
                                        theme.palette.text.faded,
                                    }}
                                  />
                                  <Typography
                                    variant='smallMedium'
                                    sx={{ textAlign: 'start' }}
                                  >
                                    {item.column}
                                  </Typography>
                                </Box>
                                {select.length > 1 ? (
                                  <Box
                                    sx={{
                                      cursor: 'pointer',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      padding: '4px',
                                    }}
                                    onClick={() => handleRemoveMetric(item)}
                                  >
                                    <CloseIcon
                                      sx={{
                                        fontSize: '18px',
                                        color: (theme) =>
                                          theme.palette.text.faded,
                                      }}
                                    />
                                  </Box>
                                ) : null}
                              </Box>
                            </DragItem>
                          ))}
                        </Draggable>
                      </Box>
                    ))
                  ) : (
                    <p>No items to display.</p>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Box>
        )}
      </DragDropContext>
    </Box>
  );
};

export { DragableComponent };
