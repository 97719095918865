import { authInstance } from '../api/authentication.instance';
import { openInstance } from '../api/open.instance';

const fetchUsers = () => authInstance.get('/api/users/project-user-list');

const fetchUserDetail = (id) =>
  authInstance.get(`/api/users/access-details/${id}`);

const fetchAllAccounts = () => authInstance.get('/api/users/access-details');

const patchUserDetail = (id, data) =>
  authInstance.patch(`/api/users/access-details/${id}`, data);

const removeUser = (id, companyId) =>
  authInstance.delete(`/api/users/access-details/${id}`, {
    data: {
      company_id: companyId,
    },
  });

const createUser = (data) => authInstance.post(`/api/users/invite`, data);

const getInvites = () => authInstance.get(`/api/users/invite`);

const acceptInvite = (token, invitedBy) =>
  authInstance.post(`/api/users/accept-invite/${token}`, {
    invited_by: invitedBy,
  });

const rejectInvite = (token, invitedBy, rejectAll) =>
  authInstance.post(`/api/users/reject-invite/${token}`, {
    invited_by: invitedBy,
    reject_all: rejectAll
  });

const verification = (token) =>
  openInstance.get(`/api/auth/user-verification/${token}`);

const usersService = {
  fetchUsers,
  fetchUserDetail,
  fetchAllAccounts,
  patchUserDetail,
  createUser,
  removeUser,
  getInvites,
  acceptInvite,
  verification,
  rejectInvite,
};

export { usersService };

