import React, { useMemo, useRef, useContext, useState, useEffect } from 'react';
// import countryList from 'react-select-country-list';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getAllCountries, getTimezonesForCountry } from 'countries-and-timezones';

// third party imports
import {
  Stack,
  Button,
  TextField,
  Autocomplete,
  InputLabel,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Internal components import
import { DynamicSelectBox } from '../GlobalComponents/DynamicSelectBox';
import { DynamicTextField } from '../GlobalComponents/DynamicTextField';
import {
  GOOGLE_SHOPPING,
  GOOGLE_DYNAMIC_SEARCH_ADS,
  TIKTOK,
  PINTEREST,
  META_ADS,
  BING,
} from '../../../../constants/endpoints/assests/logos';
import { FeedSchema } from '../../Schemas';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { updateVal, clearInputValue } from '../../Redux/FeedForm/slice';
import { setFeedChannel, setFeedId, setFeedName } from '../../Redux/FeedSummary';
import { DataSendingModalBaseRTK } from '../../../../components/DataSendingModalBaseRTK';
import {
  useCreateFeedMutation,
  useUpdateFeedMutation,
} from '../../../../api/RTK/services/feedFeed';
import { updateVal as updateValSchedule } from '../../Redux/ScheduleForm/slice';

// Styled component import
import { StyledFeedControl } from '../StyledComponents/StyledCommon';
import { StyledBox } from '../StyledComponents/StyledImportFileForm';
import { addFeed } from '../../Redux/AllProjects';

const channelType = [
  {
    title: 'Google Shopping',
    value: 'GOOGLE_SHOPPING',
  },
  {
    title: 'Google Dynamic Search Ads',
    value: 'GOOGLE_DYNAMIC_SEARCH_ADS',
  },
  {
    title: 'Pinterest',
    value: 'PINTEREST',
  },
  {
    title: 'TikTok',
    value: 'TIKTOK',
  },
  {
    title: 'Meta(Facebook & Instagram)',
    value: 'META_ADS',
  },
  {
    title: 'Bing',
    value: 'BING',
  },
];

const channelTypeImageMap = {
  GOOGLE_SHOPPING,
  GOOGLE_DYNAMIC_SEARCH_ADS,
  TIKTOK,
  PINTEREST,
  META_ADS,
  BING,
};

// main function component
const FeedSettingForm = ({ data }) => {
  const [selectedChannelName, setSelectedChannelName] = useState('');
  const [isChannelSelected, setIsChannelSelected] = useState(false);
  const { company, user } = useContext(UserInfoContext);
  const formikRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFeeds = () => {
    navigate('../feeds/enhance');
  };

  // redux store 
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );
  const projectFeeds = useSelector(
    (state) => state.allProjectsRedux.feeds
  );
  const feedSelectId = useSelector(
    (state) => state.feedSummaryRedux.feedId
  );
  const feedSelectChannel = useSelector(
    (state) => state.feedSummaryRedux.feedChannel
  );
  const feedUpdateStore = useDebouncedCallback((key, val) => {
    dispatch(updateVal({ key, val }));
  }, 10);
  const scheduleUpdateStore = useDebouncedCallback((key, val) => {
    dispatch(updateValSchedule({ key, val }));
  }, 10);
  const handleClearClick = () => {
    dispatch(clearInputValue());
  };

  useEffect(() => {
    if (data) {
      setSelectedChannelName(data?.channel);
    }
  }, [data]);

  // market type according to channel selection
  let selectMarket;
  if ((selectedChannelName || feedSelectChannel) === 'META_ADS')
    selectMarket = [
      {
        title: 'Standard',
        value: 'STANDARD',
      },
      {
        title: 'Appliances',
        value: 'APPLIANCES',
      },
      {
        title: 'Automotives',
        value: 'AUTOMOTIVES',
      },
      {
        title: 'Baby Feeding',
        value: 'BABY_FEEDING',
      },
      {
        title: 'Baby Transport',
        value: 'BABY_TRANSPORT',
      },
      {
        title: 'Beauty',
        value: 'BEAUTY',
      },
      {
        title: 'Bedding',
        value: 'BEDDING',
      },
      {
        title: 'Cell Phones & Smart Watches',
        value: 'CELL_PHONES_AND_SMART_WATCHES',
      },
      {
        title: 'Cleaning Supplies',
        value: 'CLEANING_SUPPLIES',
      },
      {
        title: 'Clothing',
        value: 'CLOTHING',
      },
      {
        title: 'Clothing Accessories',
        value: 'CLOTHING_ACCESSORIES',
      },
      {
        title: 'Computer & Tablets',
        value: 'COMPUTER_AND_TABLETS',
      },
      {
        title: 'Destinations',
        value: 'DESTINATIONS',
      },
      {
        title: 'Diapering & Potty Training',
        value: 'DIAPERING_AND_POTTY_TRAINING',
      },
      {
        title: 'Electronics Accessories',
        value: 'ELECTRONICS_ACCESSORIES',
      },
      {
        title: 'Flights',
        value: 'FLIGHTS',
      },
      {
        title: 'Food & Beverages',
        value: 'FOOD_AND_BEVERAGES',
      },
      {
        title: 'Furniture',
        value: 'FURNITURE',
      },
      {
        title: 'Health',
        value: 'HEALTH',
      },
      {
        title: 'Hotels',
        value: 'HOTELS',
      },
      {
        title: 'Home goods',
        value: 'HOME_GOODS',
      },
      {
        title: 'Jewelery',
        value: 'JEWELERY',
      },
      {
        title: 'Nursery',
        value: 'NURSERY',
      },
      {
        title: 'Pet Supplies',
        value: 'PET_SUPPLIES',
      },
      {
        title: 'Printers & Scanners',
        value: 'PRINTERS_AND_SCANNERS',
      },
      {
        title: 'Projectors',
        value: 'PROJECTORS',
      },
      {
        title: 'Real Estate',
        value: 'REAL_ESTATE',
      },
      {
        title: 'Secondary country feed',
        value: 'SECONDARY_COUNTRY_FEED',
      },
      {
        title: 'Shoes & Footwear',
        value: 'SHOES_AND_FOOTWEAR',
      },
      {
        title: 'Sporting Goods',
        value: 'SPORTING_GOODS',
      },
      {
        title: 'Software',
        value: 'SOFTWARE',
      },
      {
        title: 'Toys',
        value: 'TOYS',
      },
      {
        title: 'TVs & Monitors',
        value: 'TVS_AND_MONITORS',
      },
      {
        title: 'Video Games Consoles & Video Games',
        value: 'VIDEO_GAMES_CONSOLES_AND_VIDEO_GAMES',
      },
      {
        title: 'Watches',
        value: 'WATCHES',
      },
    ];
  else if (
    (selectedChannelName || feedSelectChannel) ===
    'GOOGLE_DYNAMIC_SEARCH_ADS' ||
    (selectedChannelName || feedSelectChannel) === 'PINTEREST' ||
    (selectedChannelName || feedSelectChannel) === 'TIKTOK' ||
    (selectedChannelName || feedSelectChannel) === 'BING'
  )
    selectMarket = [
      {
        title: 'Standard',
        value: 'STANDARD',
      },
    ];
  else {
    selectMarket = [
      {
        title: 'Standard',
        value: 'STANDARD',
      },
      {
        title: 'Books',
        value: 'BOOKS',
      },
      {
        title: 'Fashion',
        value: 'FASHION',
      },
      {
        title: 'Inventory Update',
        value: 'INVENTORY_UPDATE',
      },
      {
        title: 'Local Product Inventory',
        value: 'LOCAL_PRODUCT_INVENTORY',
      },
    ];
  }

  // Add your desired countries here channel specific
  let selectiveCountries = [];
  if (
    (selectedChannelName || feedSelectChannel) === 'GOOGLE_DYNAMIC_SEARCH_ADS'
  ) {
    selectiveCountries = [
      'AL',
      'DZ',
      'AD',
      'AO',
      'AR',
      'AU',
      'AT',
      'BH',
      'BY',
      'BE',
      'BA',
      'BR',
      'BG',
      'CA',
      'CL',
      'CO',
      'HR',
      'CZ',
      'DK',
      'EC',
      'EG',
      'EE',
      'FI',
      'FR',
      'GE',
      'DE',
      'GR',
      'HK',
      'HU',
      'IS',
      'IN',
      'ID',
      'IE',
      'IL',
      'IT',
      'JP',
      'JO',
      'KZ',
      'KW',
      'LV',
      'LB',
      'LI',
      'LT',
      'LU',
      'MY',
      'MT',
      'MX',
      'MD',
      'MC',
      'ME',
      'MA',
      'MK',
      'NL',
      'NZ',
      'NG',
      'NO',
      'OM',
      'PY',
      'PE',
      'PH',
      'PL',
      'PT',
      'RO',
      'RU',
      'SM',
      'SA',
      'RS',
      'SG',
      'SK',
      'SI',
      'ZA',
      'KR',
      'ES',
      'SE',
      'CH',
      'TW',
      'TH',
      'TR',
      'UA',
      'AE',
      'GB',
      'US',
      'UY',
      'UZ',
      'VN',
    ];
  }
  if ((selectedChannelName || feedSelectChannel) === 'PINTEREST') {
    selectiveCountries = [
      'AR',
      'BE',
      'CH',
      'CY',
      'DK',
      'FR',
      'HU',
      'JP',
      'MX',
      'US',
      'NZ',
      'RO',
      'AT',
      'BR',
      'CL',
      'CZ',
      'ES',
      'GB',
      'IE',
      'LU',
      'NL',
      'PL',
      'SE',
      'AU',
      'CA',
      'CO',
      'DE',
      'FI',
      'GR',
      'IT',
      'MT',
      'NO',
      'PT',
      'SK',
    ];
  }
  if ((selectedChannelName || feedSelectChannel) === 'TIKTOK') {
    selectiveCountries = [
      'AF',
      'AL',
      'AD',
      'AR',
      'AM',
      'AW',
      'AU',
      'AT',
      'AZ',
      'BS',
      'BH',
      'BD',
      'BB',
      'BY',
      'BE',
      'BJ',
      'BO',
      'BR',
      'BG',
      'KH',
      'CM',
      'CA',
      'CL',
      'NL',
      'CN',
      'CO',
      'CR',
      'HR',
      'CU',
      'CW',
      'CY',
      'CZ',
      'DK',
      'DO',
      'EC',
      'EG',
      'SV',
      'EE',
      'FJ',
      'FI',
      'FR',
      'PF',
      'GE',
      'DE',
      'GR',
      'GL',
      'GT',
      'GY',
      'HT',
      'HN',
      'HK',
      'HU',
      'IS',
      'ID',
      'IR',
      'IQ',
      'IE',
      'IL',
      'IT',
      'CI',
      'JM',
      'JP',
      'JO',
      'KZ',
      'KW',
      'LA',
      'LB',
      'LU',
      'MY',
      'MV',
      'ML',
      'MT',
      'MU',
      'MX',
      'MD',
      'MC',
      'ME',
      'MA',
      'MM',
      'NP',
      'NL',
      'NZ',
      'NO',
      'PG',
      'PY',
      'PE',
      'PH',
      'PL',
      'PT',
      'PR',
      'QA',
      'RO',
      'RU',
      'WS',
      'SA',
      'SN',
      'RS',
      'SG',
      'SX',
      'SK',
      'SI',
      'ZA',
      'KR',
      'ES',
      'LK',
      'SR',
      'SE',
      'CH',
      'TW',
      'TH',
      'TR',
      'UA',
      'AE',
      'GB',
      'US',
      'UY',
      'VE',
      'VN',
      'YE',
    ];
  }
  if ((selectedChannelName || feedSelectChannel) === 'BING') {
    selectiveCountries = [
      'AU',
      'AT',
      'BE',
      'CA',
      'DK',
      'FI',
      'FR',
      'DE',
      'IN',
      'IE',
      'IT',
      'NL',
      'NO',
      'PL',
      'PT',
      'ES',
      'SE',
      'CH',
      'GB',
      'US',
    ];
  }
  if ((selectedChannelName || feedSelectChannel) === 'META_ADS') {
    selectiveCountries = [
      'AF',
      'AL',
      'AD',
      'AR',
      'AM',
      'AW',
      'AU',
      'AT',
      'AZ',
      'BS',
      'BH',
      'BD',
      'BB',
      'BY',
      'BE',
      'BJ',
      'BO',
      'BR',
      'BG',
      'KH',
      'CM',
      'CA',
      'CL',
      'NL',
      'CN',
      'CO',
      'CR',
      'HR',
      'CU',
      'CW',
      'CY',
      'CZ',
      'DK',
      'DO',
      'EC',
      'EG',
      'SV',
      'EE',
      'FJ',
      'FI',
      'FR',
      'PF',
      'GE',
      'DE',
      'GR',
      'GL',
      'GT',
      'GY',
      'HT',
      'HN',
      'HK',
      'HU',
      'IS',
      'IN',
      'ID',
      'IR',
      'IQ',
      'IE',
      'IL',
      'IT',
      'CI',
      'JM',
      'JP',
      'JO',
      'KZ',
      'KW',
      'LA',
      'LB',
      'LU',
      'MY',
      'MV',
      'ML',
      'MT',
      'MU',
      'MX',
      'MD',
      'MC',
      'ME',
      'MA',
      'MM',
      'NP',
      'NL',
      'NZ',
      'NO',
      'PK',
      'PG',
      'PY',
      'PE',
      'PH',
      'PL',
      'PT',
      'PR',
      'QA',
      'RO',
      'RU',
      'WS',
      'SA',
      'SN',
      'RS',
      'SG',
      'SX',
      'SK',
      'SI',
      'ZA',
      'KR',
      'ES',
      'LK',
      'SR',
      'SE',
      'CH',
      'TW',
      'TH',
      'TT',
      'TR',
      'UA',
      'AE',
      'GB',
      'US',
      'UY',
      'VE',
      'VN',
      'YE',
    ];
  }

  const options = useMemo(() => {
    let selectiveOptions;
    if ((selectedChannelName || feedSelectChannel) !== 'GOOGLE_SHOPPING') {
      const allCountries = Object.entries(getAllCountries()).map(([code, country]) => ({
        value: code,
        label: country.name,
      }));
      selectiveOptions = allCountries
        .filter((country) => selectiveCountries.includes(country.value))
        .sort((a, b) => a.label.localeCompare(b.label));
    } else {
      selectiveOptions = Object.entries(getAllCountries()).map(([code, country]) => ({
        value: code,
        label: country.name,
      })).sort((a, b) => a.label.localeCompare(b.label));
    }
    return selectiveOptions;
  }, [selectedChannelName, feedSelectChannel]);

  const getTimezoneForCountrySelected = (countryCode) => {
    const countryTimezones = getTimezonesForCountry(countryCode);
    // Assuming you want the first timezone for the country, modify this as needed
    const timezone = countryTimezones && countryTimezones.length > 0 ? countryTimezones[0].name : '';
    return timezone;
  };


  useEffect(() => {
    formikRef.current.resetForm({
      values: {
        ...formikRef.current.values,
        country: data?.country || '',
        selectOnly: data?.market_type || selectMarket[0].value,
      },
    });
  }, [selectedChannelName, feedSelectChannel]);


  const [createFeed, { isLoading, isSuccess, isError, reset, error }] =
    useCreateFeedMutation();

  const [
    updateFeed,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
      reset: updateReset,
    },
  ] = useUpdateFeedMutation();
  // post response handlesubmit
  const handlePostSubmit = (createfeedData) =>
    createFeed({ data: createfeedData })
      .unwrap()
      .then((response) => {
        dispatch(setFeedId(response.id));
        dispatch(setFeedChannel(response.channel));
        dispatch(setFeedName(response.name));
        dispatch(addFeed(response));
        handleFeeds();
        handleClearClick();
      });

  // patch request
  const handlePatchSubmit = (createPatchData) =>
    updateFeed({
      companyId: company?.id,
      projectId: projectSelectId,
      data: createPatchData,
    })
      .unwrap()
      .then(() => {
        setTimeout(() => {
          handleClearClick();
          handleFeeds();
        }, 600);
      });

  const getErrorMessage = () => {
    if (feedSelectId === '' && error?.status === 403) {
      return `Error: ${error?.data?.errors || error?.data[0]?.error}`;
    }
    if (feedSelectId === '') {
      return 'Unable to Create Feed';
    }
    return 'Unable to Create Feed';
  };

  return (
    <>
      <Formik
        initialValues={{
          channelName: data?.channel ? `${data?.channel}` : '',
          feedName: data?.name ? `${data?.name}` : '',
          country: data?.country ? `${data?.country}` : '',
          selectOnly: data?.market_type
            ? `${data?.market_type}`
            : selectMarket[0].value,
        }}
        innerRef={formikRef}
        enableReinitialize
        validationSchema={FeedSchema}
        onSubmit={(values) => {
          if ((user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')) {
            navigate('../feeds/enhance');
          } else if (feedSelectId === '') {
            handlePostSubmit({
              feed_project_id: projectSelectId,
              company_id: company?.id,
              channel: values.channelName,
              name: values.feedName,
              country: values.country?.label,
              market_type: values.selectOnly,
            });
          } else {
            handlePatchSubmit({
              feed_id: feedSelectId,
              name: values.feedName,
              channel: values.channelName,
              country: values.country?.label,
              market_type: values.selectOnly,
            });
          }
        }}

      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form>
            <Stack
              alignItems='center'
              direction={{ xs: 'column', lg: 'row' }}
              flexWrap='wrap'
            >
              <StyledBox
                className='importSelectForm'
                sx={{ paddingRight: '0px !important' }}
              >
                <DynamicSelectBox
                  inputTitle='Channel'
                  name='channelName'
                  placeholderText='Select channel'
                  className='importInputForm feedChannel'
                  disabled={feedSelectId || (user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
                  value={values.channelName}
                  selectedItem={channelType}
                  itemImage={channelTypeImageMap}
                  handleChange={(event) => {
                    handleChange(event);
                    feedUpdateStore('channelName', event.target.value);
                    setSelectedChannelName(event.target.value);
                    setIsChannelSelected(true);
                  }}
                  handleBlur={handleBlur}
                  error={
                    errors.channelName && touched.channelName ? (
                      <Box>{errors.channelName}</Box>
                    ) : (
                      ''
                    )
                  }
                />
              </StyledBox>

              <StyledBox className='importInputForm'>
                <DynamicTextField
                  textTitle='Feed name'
                  name='feedName'
                  value={values.feedName}
                  inputPlaceholder='eg: Google Shopping'
                  disabled={isSubmitting || (user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
                  inType='text'
                  onChange={(event) => {
                    handleChange(event);
                    feedUpdateStore('feedName', event.target.value);
                  }}
                  onBlur={handleBlur}
                  error={
                    errors.feedName && touched.feedName ? (
                      <Box>{errors.feedName}</Box>
                    ) : (
                      ''
                    )
                  }
                />
              </StyledBox>
            </Stack>

            <Stack
              alignItems='center'
              direction={{ xs: 'column', lg: 'row' }}
              flexWrap='wrap'
            >
              <StyledFeedControl className='feedSettingCountry'>
                <InputLabel sx={{ dispaly: 'block' }}>
                  Select Country
                </InputLabel>
                <Field
                  as={Autocomplete}
                  id='country'
                  name='country'
                  popupIcon={<ExpandMoreIcon />}
                  disabled={
                    (!isChannelSelected && !feedSelectId) || isSubmitting || (user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')
                  }
                  className='feedsAutocomplete'
                  options={options}
                  getOptionLabel={(option) =>
                    option.label || data?.country || ''
                  }
                  value={values.country}
                  onChange={(event, value) => {
                    setFieldValue('country', value !== null ? value : value);
                    feedUpdateStore('country', value !== null ? value.value : '');
                    const timezone = getTimezoneForCountrySelected(value !== null ? value.value : '');
                    if (projectFeeds && projectFeeds.length === 0) {
                      scheduleUpdateStore('timezone', timezone);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      name='country'
                      placeholder='Select Country'
                      hiddenLabel
                      error={errors.country && touched.country}
                      helperText={
                        errors.country && touched.country && errors.country
                      }
                      className={
                        errors.country && touched.country ? 'error' : ''
                      }
                      sx={{
                        '& .MuiFormHelperText-root': {
                          position: 'absolute',
                          bottom: '-20px',
                        },
                        '& .MuiOutlinedInput-root .Mui-disabled': {
                          backgroundColor: 'transparent',
                        }
                      }}
                    />
                  )}
                />
              </StyledFeedControl>
              <DynamicSelectBox
                inputTitle='Type of market'
                name='selectOnly'
                value={
                  values.selectOnly ? values.selectOnly : selectMarket[0].value
                }
                className='importSelectForm feedSelect'
                disabled={(!isChannelSelected && !feedSelectId) || isSubmitting || (user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
                selectedItem={selectMarket}
                handleChange={(event) => {
                  feedUpdateStore('selectOnly', event.target.value);
                  handleChange(event);
                }}
                handleBlur={handleBlur}
              />
            </Stack>

            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              marginTop='20px'
            >
              <Button
                sx={{
                  width: '200px',
                  margin: '25px 5px',
                }}
                variant='outlined'
                onClick={() => navigate('../feeds')}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '200px',
                  margin: '25px 5px',
                }}
                variant='contained'
                disabled={isSubmitting}
                type='submit'
              >
                {(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') ? 'Save & Next' : 'Next'}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>

      <DataSendingModalBaseRTK
        open={
          (isLoading ||
            isSuccess ||
            isError ||
            updateLoading ||
            updateSuccess ||
            updateError) && (user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR')
        }
        handleCloseModal={() => {
          reset();
          updateReset();
        }}
        isLoading={isLoading || updateLoading}
        isSuccess={isSuccess || updateSuccess}
        isError={isError || updateError}
        successMessage={feedSelectId === '' ? 'Feed created ' : 'Feed updated'}
        errorMessage={getErrorMessage(feedSelectId, error)}
        reset={() => {
          reset();
          updateReset();
        }}
      >
        <Box sx={{ height: '200px' }} />
      </DataSendingModalBaseRTK>
    </>
  );
};

export { FeedSettingForm };
