import React from 'react';
import { styled } from '@mui/system';
import { Stack } from '@mui/material';

const SvgContainer = styled(({ children, sx, className }) => (
  <Stack borderRadius='8px' className={className} sx={sx}>
    {children}
  </Stack>
))(
  ({ theme, type, hasBackground }) => `
  ${
    hasBackground
      ? `background: ${
          type === 'blue'
            ? theme.palette.primary.light
            : theme.palette.secondary.light
        };`
      : `background: ${
          type === 'blue'
            ? theme.palette.primary.lighter
            : theme.palette.secondary.lighter
        };`
  }
  color:
    ${
      type === 'blue'
        ? theme.palette.primary.main
        : theme.palette.secondary.main
    };

  & .statIcon {
    font-size:3rem;
    padding:0.7rem;
    fill:
    ${
      type === 'blue'
        ? theme.palette.primary.main
        : theme.palette.secondary.main
    };
  }
`
);

export { SvgContainer };
