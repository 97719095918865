import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { InvitationForm } from '../Form/InvitationForm';
import { useDataFetcher } from '../../../Hooks/useDataFetcher';
import { usersService } from '../../../services/users';
import { FetchingDisplay } from '../../../components/FetchingDisplay';

import { getAccessToken } from '../../../utils/token';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { PagesWrraper } from './PagesWrraper';

const initialState = {
  isLoading: true,
  isError: false,
};

const InvitationPage = () => {
  const { user, companyList } = useContext(UserInfoContext);
  const [state] = useDataFetcher(initialState, usersService.getInvites);
  const token = getAccessToken();
  if (!token) {
    return <Navigate to='/signin' />;
  }
  const redirectPath =
    user.projectType === 'AGENCY' ? '/companies' : '/accounts';
  return (
    <FetchingDisplay
      isLoading={state.isLoading}
      isError={state.isError}
      LoadingElement={
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          Loading
        </Box>
      }
      ErrorElement={
        state?.errorInfo?.code === 403 ? <Navigate to='..' /> : <>Error</>
      }
      SuccessElement={
        state?.data?.length === 0 ? (
          <Navigate to={companyList === 0 ? '/create-company' : redirectPath} />
        ) : (
          <PagesWrraper>
            <InvitationForm invites={state?.data} />
          </PagesWrraper>
        )
      }
    />
  );
};

export { InvitationPage };
