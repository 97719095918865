import { styled } from '@mui/system';
import { Box } from '@mui/material';

const StyledWrapperBox = styled(Box)`
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px 16px 18px 18px;
  position: relative;
  box-shadow: 2;
  background-position: right;
`;

const StyledAccountMenuBox = styled(Box)(
  ({ theme }) => `
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 20px;
  background-color: #fff;
  border-radius: 2px;
  border:1px solid ${theme.palette.border.main};
                `
);

export { StyledAccountMenuBox, StyledWrapperBox };
