import React, { useState } from 'react';

import DateRangeIcon from '@mui/icons-material/DateRange';

import {
  StyledBox,
  StyledAutocomplete,
  StyledTextField,
} from './StyledPOASDatePicker';

const POASDatePicker = ({ value, setValue, dateRangeList }) => {
  const [inputValue, setInputValue] = useState('');
  return (
    <StyledBox>
      <StyledAutocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        disablePortal
        id='combo-box-demo'
        options={dateRangeList}
        disableClearable
        popupIcon={<DateRangeIcon fontSize='large' />}
        renderInput={(params) => (
          <StyledTextField
            variant='standard'
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder='Year and Month'
          />
        )}
      />
    </StyledBox>
  );
};

export { POASDatePicker };
