import { authInstance } from '../api/authentication.instance';
import {
  companyURI,
  dashboardURI,
  topViewDashboardURI,
  accountsLinkRequestURI,
  getCompaniesBranchesListURI,
  getPageInfoURI,
} from '../constants/endpoints';

class DashboardService {
  static getCompanyList = (dateRange) =>
    authInstance.post(
      companyURI,
      dateRange ? { date_range: dateRange.date_range } : {}
    );

  static getCompanyListWithoutMetrics = () =>
    authInstance.get('/api/accounts/company/');

  static getBranchesOfCompany = (companyId) =>
    // getCompaniesBranchesListURI(companyId)
    authInstance.get(getCompaniesBranchesListURI(companyId));

  static getDashboardData = (data) => authInstance.post(dashboardURI, data);

  static fetchTopViewDashboardData = (data) =>
    authInstance.post(topViewDashboardURI, data);

  static requestToLinkAccount = (data) =>
    authInstance.post(accountsLinkRequestURI, data);

  static getPageInfoData = (data) => authInstance.get(getPageInfoURI, data);

  static postPageInfoData = (data) => authInstance.post(getPageInfoURI, data);

  static createGoogleAd = (data) =>
    authInstance.post('/api/google-ads/create-ads', data);

  static createMetaAd = (data) =>
    authInstance.post('/api/metaads/create-ads', data);

  static getProfitData = (companyId, accountId, poasMonth) =>
    authInstance.get('/api/accounts/profit', {
      params: {
        company_id: companyId,
        account_id: accountId,
        poas_month: poasMonth,
      },
    });

  static postProfitData = (data) =>
    authInstance.post('/api/accounts/profit', data);
}

const getGoogleAdsData = (data, signal) =>
  authInstance.post('/api/google-ads/dashboard', data, { signal });

const getMetaAdsData = (data, signal) =>
  authInstance.post('/api/metaads/', data, { signal });

const getLayout = (platform) =>
  authInstance.get('api/users/layout', {
    params: {
      platform,
    },
  });

const setLayout = (platform, layout) =>
  authInstance.put('/api/users/layout', {
    platform,
    platform_layout: layout,
  });

export {
  DashboardService,
  getGoogleAdsData,
  getMetaAdsData,
  getLayout,
  setLayout,
};

