import React, { useState, useMemo, useContext, useEffect } from 'react';

import { MonthlyInsights } from '../../../../Databoxes/MonthyInsights';
import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { Filter } from '../../../../../../components/Filter';
import { Loading } from '../../../../../../components/Databox/Loading';

import { getDemoDashboardService } from '../../../../../../services/DemoDashboard';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { AccountContext } from '../../accountContext';

const MonthlyInsightsContainer = ({ platform }) => {
  const { currencyCode } = useContext(AccountContext);
  const [filterListForPlatform, setFilterListForPlatform] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState();

  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'MONTH_ON_MONTH',
    }),
    []
  );

  const [state] = useDataFetcher(
    [],
    getDemoDashboardService(platform),
    memoizedArgs
  );

  const handleFilterChange = (newFilter) => {
    setSelectedFilter(newFilter);
  };
  const filterList = state?.data?.reduce(
    (prev1, obj) => [
      ...prev1,
      ...Object.keys(obj).reduce((prev, current) => {
        if (obj[current].error) {
          return prev;
        }
        return [...prev, { name: current, value: current }];
      }, []),
    ],
    []
  );

  useEffect(() => {
    if (filterList && filterList.length > 0 && !selectedFilter) {
      setFilterListForPlatform(filterList || []);
      setSelectedFilter(filterList[0]);
    }
  }, [filterList, selectedFilter, state.data]);

  return (
    <Databox>
      <DataboxHeading
        title='Monthly Insights'
        info='
        Know your monthly account performance for the selected year. This will help in distributing the ad budget more efficiently.
        '
      >
        {selectedFilter ? (
          <Filter
            label='Filter'
            selectedFilter={selectedFilter}
            filterList={filterListForPlatform || []}
            handleFilterChange={handleFilterChange}
          />
        ) : null}
      </DataboxHeading>
      <FetchingDisplay
        isError={state.isError}
        isLoading={state.isLoading || !selectedFilter}
        LoadingElement={<Loading />}
        ErrorElement={<ErrorDisplay />}
        SuccessElement={
          <MonthlyInsights
            data={state.data}
            filter={selectedFilter}
            currencyCode={currencyCode}
          />
        }
      />
    </Databox>
  );
};

export { MonthlyInsightsContainer };
