import React, { useMemo, useState, useContext } from 'react';

// MUI imports
import { Container } from '@mui/material';

// Internal imports
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { FeedSettingForm } from '../Components/OtherComponents/FeedSettingForm';

// Styled imports
import { StyledPaper } from './StyledMainBox';
import { ToggleButtonFeeds } from '../Components/OtherComponents/ToggleButtonFeeds';
import { useDataFetcher } from '../../../Hooks/useDataFetcher';
import { feedManagementService } from '../../../services/feedManagement';

import { UserInfoContext } from '../../../context/UserInfoContext';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { PageErrors } from '../../../components/PageErrors';

const FeedSetting = () => {
  const [toggled, setToggled] = useState('Settings');
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const feedSelectId = useSelector((state) => state.feedSummaryRedux.feedId);

  const { company } = useContext(UserInfoContext);
  const memoizedArgs = useMemo(
    () => ({
      company_id: company?.id,
      projectId: projectSelectId,
      feedId: feedSelectId,
    }),
    [company?.id, projectSelectId, feedSelectId]
  );

  const [state] = useDataFetcher(
    { isLoading: false, isError: false },
    feedManagementService.getFeedForm,
    memoizedArgs,
    '',
    feedSelectId && !!company
  );

  if (feedSelectId) {
    return (
      <FetchingDisplay
        isError={state.isError}
        isLoading={state.isLoading}
        ErrorElement={
          <PageErrors isServerError code={state?.errorInfo?.code} />
        }
        LoadingElement={<GlobalLoadingComponent />}
        SuccessElement={
          <ErrorBoundary fallback={<PageErrors />}>
            <Container maxWidth='xxl'>
              <TitleCard title='Update feed'>
                <ToggleButtonFeeds toggled={toggled} setToggled={setToggled} />
              </TitleCard>
              <StyledPaper>
                <FeedSettingForm data={state.data} />
              </StyledPaper>
            </Container>
          </ErrorBoundary>
        }
      />
    );
  }

  return (
    <ErrorBoundary fallback={<PageErrors />}>
      <Container maxWidth='xxl'>
        <TitleCard title='Create new feed'>
          <ToggleButtonFeeds toggled={toggled} setToggled={setToggled} />
        </TitleCard>
        <StyledPaper>
          <FeedSettingForm data={state.data} />
        </StyledPaper>
      </Container>
    </ErrorBoundary>
  );
};

export { FeedSetting };
