import React, { useState, useMemo, useContext } from 'react';

import { Box } from '@mui/material';
import { TableChartOutlined } from '@mui/icons-material';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { Filter } from '../../../../../../components/Filter';
import { PMaxChart } from '../../../../Databoxes/PMax/Chart';
import { PMaxTable } from '../../../../Databoxes/PMax/Table';
import { Loading } from '../../../../../../components/Databox/Loading';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { CustomSwitch } from '../../../../../../components/CustomSwitch/CustomSwitch';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { getDemoDashboardService } from '../../../../../../services/DemoDashboard';
import { AccountContext } from '../../accountContext';

const getFilterList = () => [
  {
    name: 'Clicks',
    value: 'clicks',
  },
  {
    name: 'Cost',
    value: 'marketing_cost',
  },
  {
    name: 'Conversions',
    value: 'conversions',
  },
  {
    name: 'Conversion Value',
    value: 'online_sales',
  },
  {
    name: 'ROAS',
    value: 'roas',
  },
];

const DisplayComponentSelector = ({ showTable, chart, table }) => (
  <Box flexGrow={1} minHeight={0}>
    {showTable ? table : chart}
  </Box>
);
const PMaxContainer = ({ platform }) => {
  const { currencyCode } = useContext(AccountContext);

  const [showTable, setShowTable] = useState(false);
  const [filter, setFilter] = useState(getFilterList(platform)[0]);
  const [count, setCount] = useState(0);

  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'PMAX_COMPAINGNS',
    }),
    []
  );

  const [state] = useDataFetcher(
    [],
    getDemoDashboardService(platform),
    memoizedArgs
  );

  const handleSwitchChange = () => {
    setShowTable(!showTable);
  };
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <Databox>
      <DataboxHeading
        title='PMax Campaingns'
        info='Know the progress of the PMax campaigns of your account.
      Select or deselect the Ad type to see specific data.
      Click on the table icon to switch to tabular format'
      >
        {!showTable ? (
          <Filter
            label='Filter'
            selectedFilter={filter}
            filterList={getFilterList(platform)}
            handleFilterChange={handleFilterChange}
          />
        ) : null}
      </DataboxHeading>
      <FetchingDisplay
        isLoading={state.isLoading}
        isError={state.isError}
        LoadingElement={<Loading />}
        ErrorElement={
          <ErrorDisplay
            refresh={() => {
              setCount(count + 1);
            }}
            message='Something went wrong'
            showRefreshButton
          />
        }
        SuccessElement={
          <DisplayComponentSelector
            showTable={showTable}
            chart={
              <Box p={2} height='100%'>
                <PMaxChart
                  data={state.data}
                  xTitle='Campaigns'
                  yTitle={filter?.name}
                  currencyCode={currencyCode}
                  selectedFilter={filter}
                />
              </Box>
            }
            table={<PMaxTable data={state.data} currencyCode={currencyCode} />}
          />
        }
      />

      <Box sx={{ display: 'flex', justifyContent: 'end', padding: 2 }}>
        <CustomSwitch
          checked={showTable}
          handleChange={handleSwitchChange}
          CheckedIcon={TableChartOutlined}
          Icon={InsertChartOutlinedTwoToneIcon}
        />
      </Box>
    </Databox>
  );
};

export { PMaxContainer };
