import { Box, CircularProgress } from '@mui/material';
import React, { useMemo } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useLayout } from './useLayout';

const ResponsiveGridLayout = WidthProvider(Responsive);
/*
platform: string. google / meta
demo: Boolean
*/
const ResizableGrid = ({ platform = 'google', demo, children }) => {
  const [layout, rowHeight, handleWidthChange, handleLayoutChange] = useLayout(
    platform,
    demo
  );

  const memoizedChildren = useMemo(() => children, [children]);

  // show grid only if layout is present
  if (layout) {
    return (
      <ResponsiveGridLayout
        layouts={layout}
        /*
          use 1px less than the desired widths
          breakpoints calculation are not lower boundary inclusive
        */
        breakpoints={{ lg: 1919, md: 1439, sm: 1365, xs: 599, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={rowHeight}
        width={1000}
        margin={{
          lg: [32, 32],
          md: [24, 24],
          sm: [16, 16],
          xs: [16, 16],
          xxs: [16, 16],
        }}
        containerPadding={{
          xxs: [16, 0],
          xs: [16, 0],
          sm: [32, 0],
          md: [32, 0],
          lg: [32, 0],
        }}
        onLayoutChange={handleLayoutChange}
        draggableHandle='.dragHandle'
        onWidthChange={handleWidthChange}
      >
        {memoizedChildren}
      </ResponsiveGridLayout>
    );
  }
  return (
    <Box
      minHeight='calc(100vh - 266px)'
      sx={{ display: 'grid', placeItems: 'center' }}
    >
      <CircularProgress />
    </Box>
  );
};

export { ResizableGrid };
