import { styled } from '@mui/system';

const ErrorWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 1rem;
  overflow: auto;
`;

const IconWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 0 0 50%;
  & .errorIcon {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    object-fit: contain;
    object-position: center;
    height: 100%;
  }
`;

export { ErrorWrapper, IconWrapper };
