import React, { useMemo, useContext } from 'react';

// import { Filter } from '../../../../../../components/Filter';
import { Databox } from '../../../../../../components/Databox';
import { ShoppingProductsInsights } from '../../../../Databoxes/ShoppingProductsInsights';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';

import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { Loading } from '../../../../../../components/Databox/Loading';

import { getDemoDashboardService } from '../../../../../../services/DemoDashboard';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { AccountContext } from '../../accountContext';

// const filterList = [
//   {
//     name: 'All',
//     value: 'All',
//   },
//   {
//     name: 'Top 20',
//     value: 'TOP20',
//   },
//   {
//     name: 'Worst 20',
//     value: 'WORST20',
//   },
//   {
//     name: 'Most Clicked',
//     value: 'MOST_CLICKED',
//   },
//   {
//     name: 'Highest Revenue',
//     value: 'HIGHEST_REVENUE',
//   },
//   {
//     name: 'Top Spending',
//     value: 'TOP_SPENDING',
//   },
//   {
//     name: 'In Stock',
//     value: 'IN_STOCK',
//   },
//   {
//     name: 'Out of Stock',
//     value: 'OUT_OF_STOCK',
//   },
// ];

const ShoppingProductsInsightsContainer = ({ platform }) => {
  const { currencyCode } = useContext(AccountContext);
  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'SHOPPING_PRODUCTS_INSIGHTS',
    }),
    []
  );

  const [state] = useDataFetcher(
    [],
    getDemoDashboardService(platform),
    memoizedArgs
  );
  // const [filter, setFilter] = useState(filterList[0]);

  // const handleFilterChange = (newFilter) => {
  //   setFilter(newFilter);
  // };
  return (
    <Databox>
      <DataboxHeading
        title='Shopping Products Insights'
        info='Understand your product progress in-depth and use filters for specific details.'
      >
        {/* <Filter
          label='Filter'
          selectedFilter={filter}
          filterList={filterList}
          handleFilterChange={handleFilterChange}
          className={className}
        /> */}
      </DataboxHeading>{' '}
      <FetchingDisplay
        isError={state.isError}
        isLoading={state.isLoading}
        LoadingElement={<Loading />}
        ErrorElement={<ErrorDisplay />}
        SuccessElement={
          <ShoppingProductsInsights
            data={state.data}
            // filter={filter}
            currencyCode={currencyCode}
          />
        }
      />
    </Databox>
  );
};

export { ShoppingProductsInsightsContainer };
