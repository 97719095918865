import React from 'react';

import { LineGraph } from '../../../../../components/LineGraph';

const labels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

// function for generating backgroud gradient
function getBackgroundColor(ctx, chartArea) {
  let width;
  let height;
  let gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;

  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(1, '#0096FF');
    gradient.addColorStop(0, 'rgba(255,255,255,0)');
  }
  return gradient;
}

const colorMap = {
  0: '#0096FF',
  1: '#A3D9FF',
  2: '#DEDEDE',
};

const getColor = (length, index) => {
  if (length < 3) {
    switch (index) {
      case 0:
        return colorMap[0];
      default:
        return colorMap[2];
    }
  }
  return colorMap[index];
};

const dataFormatter = (rawData, filter) => {
  const data =
    rawData &&
    rawData.slice(0, 2).reduce((prev, current) => {
      if (current && typeof current === 'object') {
        return {
          ...prev,
          ...Object.keys(current).reduce((prev2, current2) => {
            if (current[current2] && !current[current2].error) {
              return {
                ...prev2,
                [current2]: current[current2],
              };
            }
            return prev2;
          }, {}),
        };
      }
      return prev;
    }, {});

  const { length } = Object.keys(data);
  return {
    labels,
    datasets: Object.keys(data)
      .sort((a, b) => b - a)
      .map((key, index) => ({
        label: filter ? `${filter.name} in ${key}` : `${key}`,
        data: data[`${key}`].map((d) =>
          filter && filter.value ? d[filter.value] : d
        ),
        tension: 0.5,
        fill: index === 0,
        cubicInterpolationMode: 'monotone',
        pointBackgroundColor: getColor(length, index),
        backgroundColor(context) {
          // return color for first dataset
          // this is used as the background for the line. i.e the fill.
          if (index !== 0) {
            return getColor(length, index);
          }
          const { chart } = context;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          return getBackgroundColor(ctx, chartArea);
        },
        borderColor: getColor(length, index),
        borderWidth: 2,
      })),
  };
};

const scaleFormatter = (filter, currencyCode) => {
  if (filter && filter.value) {
    if (filter.value === 'Spend' || filter.value === 'Revenue') {
      return `${filter.name} (${currencyCode})`;
    }
    return [filter.name];
  }
  return [''];
};

const showCurrencyCode = (filter) => {
  if ((filter && filter.value === 'Spend') || filter.value === 'Revenue') {
    return true;
  }
  return false;
};

// data: array of objects
// filter: object
const YearlyInsightsChart = ({ data, filter, currencyCode = 'ZZZ' }) => (
  <LineGraph
    data={dataFormatter(data, filter)}
    scale={scaleFormatter(filter, currencyCode)}
    styles={{ height: '100%' }}
    currencyCode={currencyCode}
    showCurrencyCode={showCurrencyCode(filter)}
    metricName={filter.name || ''}
  />
);

export { YearlyInsightsChart };
