import { Box, Grid, Skeleton } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountCard } from './AccountCard';
import { LinkSpeedDial } from './LinkAccountSpeedDial';

const MetricDisplay = ({ isLoading, isError, metric, currency }) => {
  if (isLoading) {
    return <Skeleton width='100px' />;
  }
  if (metric !== undefined) {
    return `${currency} ${Math.round(metric).toLocaleString()}`;
  }
  if (isError) {
    return 'NA';
  }
  return '--';
};
const LinkAccountComponent = ({ setAssociation }) => (
  <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
    <Box
      sx={{
        bgcolor: '#fff',
        borderRadius: '16px',
        minHeight: '144px',
        position: 'relative',
        p: 2,
        display: 'grid',
        placeItems: 'center',
        height: '100%',
      }}
    >
      <LinkSpeedDial setAssociation={setAssociation} />
    </Box>
  </Grid>
);

const LinkedPlatformCard = ({
  accountList,
  reloadFunction,
  handleErrorClick,
  state,
}) => {
  const navigate = useNavigate();
  return accountList
    ? accountList?.map((val) => (
        <AccountCard
          key={val.id}
          accountName={val.name}
          accountId={val.customer_id}
          currency={val.currency_code}
          plateformName={val.platform}
          val1={
            val.platform === 'MERCHANT_CENTER' ? (
              0
            ) : (
              <MetricDisplay
                isError={state.isError}
                isLoading={state.isLoading}
                metric={val.cost}
                currency={val.currency_code}
              />
            )
          }
          val2={
            val.platform === 'MERCHANT_CENTER' ? (
              0
            ) : (
              <MetricDisplay
                isError={state.isError}
                isLoading={state.isLoading}
                metric={val.revenue}
                currency={val.currency_code}
              />
            )
          }
          isActive={val.is_active}
          error={val.error}
          errorCode={val?.status_code}
          accountUUID={val.id}
          reloadFunction={reloadFunction}
          handleClick={() => {
            navigate('/dashboard', { state: { account: val } });
          }}
          handleErrorClick={handleErrorClick}
        />
      ))
    : null;
};

export { LinkAccountComponent, LinkedPlatformCard, MetricDisplay };
