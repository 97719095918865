// third party imports
import { Box } from '@mui/material';
import { DateRange } from 'react-date-range';
import { styled } from '@mui/system';

const CurrentDateRange = styled(Box)({
  flexGrow: 1,
  textAlign: 'start',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const StyledDateRangePicker = styled(DateRange)({
  '& .rdrDateDisplayItem input': {
    fontSize: '16px',
  },
});

export { CurrentDateRange, StyledDateRangePicker };
