// third party imports
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const HeaderMenuToggle = styled(Button)(({ theme, open }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  color: open ? theme.palette.text.selected : theme.palette.text.primary,
  display: 'inline-flex',
  flexDirection: 'row',
  fontSize: '1rem',
  height: '5rem',
  padding: '0rem 1rem',
  position: 'relative',
  textDecoration: 'none',
  transition: '150ms',
  '&:after': {
    content: '""',
    bottom: '0px',
    display: open ? 'block' : 'none',
    position: 'absolute',
    height: '0.25rem',
    left: '0px',
    right: '0px',
    backgroundColor: theme.palette.primary.main,
    transition: '150ms',
  },
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    '&:after': {
      display: 'block',
    },
  },
}));

const HeaderLinkList = styled('ul')({
  display: 'none',
  flexDirection: 'row',
  listStyle: 'none',
  '@media(min-width:996px)': {
    display: 'flex',
  },
});

const HeaderMenuText = styled(
  Typography,
  {}
)(({ theme }) => ({
  '.Mui-focusVisible &': {
    outline: `2px solid ${theme.palette.primary.main}`,
  },
}));

export { HeaderLinkList, HeaderMenuToggle, HeaderMenuText };
