import * as Yup from 'yup';

const NameSchema = Yup.object().shape({
  full_name: Yup.string()
    .required('A name is required')
    .min(5, 'Name must be at least 5 characters'),
  // last_name: Yup.string()
  //   .required('A name is required')
  //   .min(2, 'Name must be at least 2 characters'),
  username: Yup.string()
    .required('Username Field is required')
    .min(6, 'Username must be at least 6 characters')
    .max(20, 'Username must not exceed 20 characters'),
});

const SignInSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is a required field'),
  password: Yup.string().required('Please Enter your password'),
  // .matches(
  //   /^(?=.*\d{1})(?=.*[a-z]{0})(?=.*[A-Z]{1})(?=.*[!@#$%^&*{|}?~_=+.-]{0})(?=.*[a-zA-Z0-9@$!%*?&{|}~_=+.-])(?!.*\s).{8,24}$/,
  //   'Must Contain 8 Characters, 1 Uppercase and 1 Number'
  // ),
});

const SignUpSchema = NameSchema.concat(
  Yup.object().shape({
    email: Yup.string().email().required('Email is a required field'),
    phone_number: Yup.string().required('Phone No is a required field'),
    password: Yup.string()
      .required('Please Enter your password')
      .matches(
        /^(?=.*\d{1})(?=.*[a-z]{0})(?=.*[A-Z]{1})(?=.*[!@#$%^&*{|}?~_=+.-]{0})(?=.*[a-zA-Z0-9@$!%*?&{|}~_=+.-])(?!.*\s).{8,24}$/,
        'Must Contain 8 Characters, 1 Uppercase and 1 Number'
      ),
    confirm_password: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
  })
);
const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is a required field'),
});

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*\d{1})(?=.*[a-z]{0})(?=.*[A-Z]{1})(?=.*[!@#$%^&*{|}?~_=+.-]{0})(?=.*[a-zA-Z0-9@$!%*?&{|}~_=+.-])(?!.*\s).{8,24}$/,
      'Must Contain 8 Characters, 1 Uppercase and 1 Number'
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
});

const PhoneNoSchema = Yup.object().shape({
  phone_number: Yup.string().required('Phone No is a required field'),
});

const CreateCompanySchema = Yup.object().shape({
  name: Yup.string()
    .required('Company name is a required field')
    .min(2, 'Name must be at least 2 characters')
    .max(100, 'Name must not exceed 100 characters'),
  website_url: Yup.string()
    .required('Please Enter Shop URL including https:// or http://')
    .url('Please include https:// or http:// '),
  // mcc_id: Yup.string().required('Please Enter Your MCC ID'),
  // .matches(/^(?:\d{3}-\d{3}-\d{4})$/, 'Must Contain 000-000-0000 format'),
});
const EditProfileSchema = NameSchema.concat(
  Yup.object().shape({
    phone_number: Yup.string().required('Phone No is a required field'),
  })
);
const EditCompanySchema = Yup.object().shape({
  companyName: Yup.string()
    .required('Company name is a required field')
    .min(2, 'Name must be at least 2 characters')
    .max(100, 'Name must not exceed 100 characters'),
  color: Yup.string().required('Color is a required field'),
});

export {
  SignInSchema,
  SignUpSchema,
  ForgetPasswordSchema,
  ResetPasswordSchema,
  EditProfileSchema,
  CreateCompanySchema,
  PhoneNoSchema,
  EditCompanySchema,
};
