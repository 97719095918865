const onboardingFlowURI = '/api/onboarding-flow/';
const onboardingFlowLocationsURI = '/api/onboarding-flow/locations';
const onboardingFlowCurrencyRatesURI = '/api/onboarding-flow/currency-rates';
const onboardingFlowAdSuggestionsURI = '/api/onboarding-flow/ad-suggestions';

const authTokenURI = '/api/auth/token';
const authTokenRefreshURI = '/api/auth/token/refresh';
const authTokenRegisterByOnboardingURI = '/api/auth/register-by-onboarding';
const authTokenRegisterURI = '/api/auth/register';
const authResetPasswordURI = '/api/auth/reset-password';
const authForgetPasswordURI = '/api/auth/forgot-password';
const authLogoutURI = '/api/auth/logout';
const resendVerificationEmailURI = '/api/auth/resend/verification';

const usersMeURI = '/api/users/me';

// const googleAdsAuthURI = '/api/google-ads/auth';
// const googleAdsOauth2CallbackURI = '/api/google-ads/oauth2callback';
const getGoogleAdsAccountURI = '/api/google-ads/accounts';

// Account URIs
const accountGroupURI = '/api/accounts/websites';
const accountsURI = '/api/accounts/';
const accountsLaunchURI = '/api/accounts/launch';
const accountsLinkRequestURI = '/api/accounts/link-request';

// Company URIs
const companyProjectURI = '/api/accounts/company-projects';
const companyURI = '/api/accounts/company-metric/';
const companyDetailURI = '/api/companies/company';
const getCompaniesBranchesListURI = (companyId) =>
  `/api/companies/${companyId}/branches`;

// Branch URIs
const getBranchesDetailURI = (branchId) => `/api/branches/${branchId}`;
const getBranchesAccountsListURI = (branchId) =>
  `/api/branches/${branchId}/accounts`;
const getBranchesAccountsDetailURI = (branchId, accountId) =>
  `/api/branches/${branchId}/accounts/${accountId}`;

const dashboardURI = '/api/accounts/dashboard';
const topViewDashboardURI = '/api/accounts/dashboard/top-view';

const platformsURI = '/api/platforms';

// Growth Plan endpoints
const getTasksURI = '/api/growthplan/tasks';
const addCommentURI = '/api/growthplan/comments/add';
const notifyMeURI = '/api/growthplan/notify-me';
const createInitialTaskURI = '/api/growthplan/initial-tasks';
const likeTaskURI = '/api/growthplan/likes';
const commentURI = '/api/growthplan/comments';
const detailTaskURI = '/api/growthplan/tasks/';
const emailTaskURI = '/api/growthplan/mail_message';

// Creative page endpoints

const createAdURI = '/api/accounts/create-ads';
// export const getPageInfo = "/api/facebook-ads/pages";
const getPageInfoURI = '/api/accounts/create-ads';
const getPoasDataURI = '/api/accounts/profit';
const checkPoasDataURI = '/api/accounts/check_poas';
const invitedPlatformsURI = '/api/accounts/manual-invites';

// New services
const signupURI = '/api/auth/register';
const profileURI = '/api/auth/profile';
const resetPasswordURI = '/api/auth/reset-password';
const forgetPasswordURI = '/api/auth/forgot-password';
const accessibleAccountsURI = '/api/google-ads/accounts';
const credsValidityURI = '/api/google-ads/creds-validity';
const googleAdsAuthURI = '/api/google-ads/auth';
const googleAdsOauth2CallbackURI = '/api/google-ads/oauth2callback';
const projectListURI = '/api/accounts/projects';
const accountURI = '/api/accounts/';
const getAccountsURI = (companyId) => `/api/accounts/?company_id=${companyId}`;
const shopifyURI = `/api/shopify_app/connector`;
const nonAdhelpUserShopifyURI = `/api/shopify_app/appconnector`;

// feed management endpoints

const createprojectFeedListURI = '/api/feed-management/project';
const projectFeedListURI = (companyId) =>
  `/api/feed-management/project/${companyId}`;
const projectFormURI = (companyId, feedProjectId) =>
  `/api/feed-management/project/${companyId}/${feedProjectId}`;
const createImportFormURI = 'api/feed-management/imports';
const importedListURI = (companyId, feedProjectId) =>
  `/api/feed-management/imports/${companyId}/${feedProjectId}`;
const createFeedFormURI = `api/feed-management/feed`;
const feedListURI = (companyId, projectId) =>
  `/api/feed-management/feed/${companyId}/${projectId}`;
const downloadFileUri = (companyId, projectId) =>
  `api/feed-management/download/${companyId}/${projectId}`;
const scheduleSettingsURI = 'api/feed-management/schedule';
const scheduleFormURI = (companyId, projectId) =>
  `/api/feed-management/schedule/${companyId}/${projectId}`;
const getFileMappingUri = (companyId, projectId) =>
  `api/feed-management/fields/${companyId}/${projectId}`;
// const allItemsListURI = (companyId, feedProjectId) =>
//   `api/feed-management/list-items/${companyId}/${feedProjectId}`;
const qualityURI = (companyId, projectId) =>
  `api/feed-management/channel-fields/${companyId}/${projectId}`;
const filterListURI = (companyId, projectId) =>
  `api/feed-management/filter/${companyId}/${projectId}`;

const actionListURI = (companyId, projectId) =>
  `api/feed-management/action/${companyId}/${projectId}`;

// metrics and dimension endpoints

const metricURI = (accountId) => `/api/accounts/custom-metrics/${accountId}`;
const dimensionURI = (accountId) =>
  `/api/accounts/custom-dimensions/${accountId}`;
const testingDimensionURI = `/api/accounts/custom-dimensions/c22400e1-cb40-4fb6-8aec-b388b5783956`;
const demoQualityURI = 'api/feed-management/quality';

export {
  onboardingFlowURI,
  onboardingFlowLocationsURI,
  onboardingFlowCurrencyRatesURI,
  onboardingFlowAdSuggestionsURI,
  authTokenURI,
  authTokenRefreshURI,
  authTokenRegisterByOnboardingURI,
  authTokenRegisterURI,
  authResetPasswordURI,
  authForgetPasswordURI,
  authLogoutURI,
  resendVerificationEmailURI,
  usersMeURI,
  googleAdsAuthURI,
  getGoogleAdsAccountURI,
  accountGroupURI,
  accountsURI,
  accountsLaunchURI,
  accountsLinkRequestURI,
  companyProjectURI,
  companyURI,
  companyDetailURI,
  getCompaniesBranchesListURI,
  getBranchesDetailURI,
  getBranchesAccountsListURI,
  getBranchesAccountsDetailURI,
  dashboardURI,
  topViewDashboardURI,
  platformsURI,
  getTasksURI,
  addCommentURI,
  notifyMeURI,
  createInitialTaskURI,
  likeTaskURI,
  commentURI,
  detailTaskURI,
  emailTaskURI,
  createAdURI,
  getPageInfoURI,
  invitedPlatformsURI,
  shopifyURI,
  nonAdhelpUserShopifyURI,
  // New Exports
  signupURI,
  profileURI,
  resetPasswordURI,
  forgetPasswordURI,
  accessibleAccountsURI,
  credsValidityURI,
  googleAdsOauth2CallbackURI,
  projectListURI,
  accountURI,
  getAccountsURI,
  getPoasDataURI,
  checkPoasDataURI,

  // feed management exports
  projectFeedListURI,
  createprojectFeedListURI,
  importedListURI,
  feedListURI,
  createImportFormURI,
  getFileMappingUri,
  downloadFileUri,
  // allItemsListURI,
  createFeedFormURI,
  projectFormURI,
  scheduleSettingsURI,
  qualityURI,
  filterListURI,
  actionListURI,
  scheduleFormURI,

  // metric and dimension exports
  metricURI,
  dimensionURI,
  testingDimensionURI,
  demoQualityURI,
};
