import { useEffect } from 'react';

// will remove console once it's tested for about a month.
const clearSpecificLocalStorageKeys = (keys) => {
    keys.forEach((key) => {
        localStorage.removeItem(key);
        console.log(`Local storage key '${key}' cleared.`);
    });
};

const LocalStorageManager = () => {
    useEffect(() => {
        const keysToClear = ['AdHelp-account', 'AdHelp-company'];
        const lastUpdateTime = localStorage.getItem('lastUpdateTime');
        const currentTime = new Date().getTime();

        if (!lastUpdateTime || currentTime - lastUpdateTime > 86400000) {
            clearSpecificLocalStorageKeys(keysToClear);
            localStorage.setItem('lastUpdateTime', currentTime);
        }
    }, []);

    return null;
};

export { LocalStorageManager, clearSpecificLocalStorageKeys };