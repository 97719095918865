import { Button, Typography, Box } from '@mui/material';
import React from 'react';
import { ReactComponent as ErrorIcon } from '../../assests/images/artWork/ErrorIcon.svg';
import { ErrorWrapper, IconWrapper } from './styles';

const defaultMessage = `Couldn't load data`;

const ErrorDisplay = ({
  refresh,
  message = defaultMessage,
  showRefreshButton = true,
}) => (
  <ErrorWrapper>
    <IconWrapper>
      <ErrorIcon className='errorIcon' />
    </IconWrapper>
    <Typography variant='body1' sx={{ mt: 1, textAlign: 'center' }}>
      {message}
    </Typography>
    {showRefreshButton ? (
      <>
        <Typography variant='body1' sx={{ mt: 1, textAlign: 'center' }}>
          Click on Reresh to try again.
        </Typography>
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Button onClick={refresh} variant='contained' sx={{ width: '100px' }}>
            Refresh
          </Button>
        </Box>
      </>
    ) : null}
  </ErrorWrapper>
);

export { ErrorDisplay };

