const googleLayouts = {
  lg: [
    {
      w: 4,
      h: 4,
      x: 4,
      y: 3,
      i: 'usrByAg',
      minW: 1,
      maxW: 12,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 4,
      x: 0,
      y: 3,
      i: 'usrByGndr',
      minW: 1,
      maxW: 6,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 4,
      x: 8,
      y: 3,
      i: 'usrByDvc',
      minW: 1,
      maxW: 12,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 3,
      x: 0,
      y: 0,
      i: 'perfrmnceMax',
      minW: 4,
      minH: 3,
      maxH: 12,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 4,
      x: 6,
      y: 7,
      i: 'adInsghts',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 4,
      x: 6,
      y: 11,
      i: 'mnthlyInsghts',
      moved: false,
      static: false,
    },
    {
      w: 12,
      h: 3,
      x: 0,
      y: 15,
      i: 'yrlyInsghts',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 4,
      x: 0,
      y: 7,
      i: 'shppngPrdctsInsghts',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 3,
      x: 6,
      y: 0,
      i: 'tpPrd',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 4,
      x: 0,
      y: 11,
      i: 'lctn',
      moved: false,
      static: false,
    },
  ],
  md: [
    {
      w: 4,
      h: 4,
      x: 4,
      y: 5,
      i: 'usrByAg',
      minW: 3,
      maxW: 6,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 4,
      x: 0,
      y: 5,
      i: 'usrByGndr',
      minW: 3,
      maxW: 6,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 4,
      x: 8,
      y: 5,
      i: 'usrByDvc',
      minW: 3,
      maxW: 6,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 5,
      x: 0,
      y: 0,
      i: 'perfrmnceMax',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 4,
      x: 6,
      y: 9,
      i: 'adInsghts',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 4,
      x: 6,
      y: 13,
      i: 'mnthlyInsghts',
      moved: false,
      static: false,
    },
    {
      w: 12,
      h: 3,
      x: 0,
      y: 17,
      i: 'yrlyInsghts',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 4,
      x: 0,
      y: 9,
      i: 'shppngPrdctsInsghts',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 5,
      x: 6,
      y: 0,
      i: 'tpPrd',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 4,
      x: 0,
      y: 13,
      i: 'lctn',
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      w: 4,
      h: 3,
      x: 0,
      y: 11,
      i: 'usrByAg',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 14,
      i: 'usrByGndr',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 2,
      x: 2,
      y: 14,
      i: 'usrByDvc',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 0,
      i: 'perfrmnceMax',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 8,
      i: 'adInsghts',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 4,
      x: 0,
      y: 19,
      i: 'mnthlyInsghts',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 23,
      i: 'yrlyInsghts',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 5,
      i: 'shppngPrdctsInsghts',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 2,
      x: 0,
      y: 3,
      i: 'tpPrd',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 16,
      i: 'lctn',
      moved: false,
      static: false,
    },
  ],
  xs: [
    {
      w: 4,
      h: 3,
      x: 0,
      y: 11,
      i: 'usrByAg',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 14,
      i: 'usrByGndr',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 2,
      x: 2,
      y: 14,
      i: 'usrByDvc',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 0,
      i: 'perfrmnceMax',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 8,
      i: 'adInsghts',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 4,
      x: 0,
      y: 19,
      i: 'mnthlyInsghts',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 23,
      i: 'yrlyInsghts',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 5,
      i: 'shppngPrdctsInsghts',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 2,
      x: 0,
      y: 3,
      i: 'tpPrd',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 16,
      i: 'lctn',
      moved: false,
      static: false,
    },
  ],
  xxs: [
    {
      h: 5,
      i: 'mnthlyInsghts',
      w: 2,
      x: 0,
      y: 4,
      maxH: 8,
      maxW: 2,
      minH: 2,
      minW: 2,
      moved: false,
      static: false,
    },
    {
      h: 4,
      i: 'yrlyInsghts',
      w: 2,
      x: 0,
      y: 0,
      maxH: 6,
      maxW: 2,
      minH: 2,
      minW: 2,
      moved: false,
      static: false,
    },
    {
      h: 1,
      i: 'allAccounts',
      w: 1,
      x: 0,
      y: 9,
      moved: false,
      static: false,
    },
  ],
};

const metaLayouts = {
  lg: [
    {
      w: 4,
      h: 4,
      x: 4,
      y: 6,
      i: 'usrByAg',
      minW: 3,
      maxW: 6,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 4,
      x: 0,
      y: 6,
      i: 'usrByGndr',
      minW: 3,
      maxW: 6,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 4,
      x: 8,
      y: 6,
      i: 'usrByDvc',
      minW: 3,
      maxW: 6,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 3,
      x: 0,
      y: 0,
      i: 'cnvrsnFnnl',
      minW: 5,
      maxW: 12,
      minH: 2,
      maxH: 3,
      moved: false,
      static: false,
    },
    {
      w: 12,
      h: 3,
      x: 0,
      y: 3,
      i: 'cmpgnSmmry',
      minW: 5,
      maxW: 12,
      minH: 3,
      maxH: 5,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 4,
      x: 6,
      y: 10,
      i: 'mnthlyInsghts',
      minW: 4,
      maxW: 12,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 12,
      h: 4,
      x: 0,
      y: 14,
      i: 'yrlyInsghts',
      minW: 4,
      maxW: 12,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 3,
      x: 6,
      y: 0,
      i: 'adInsghtsMt',
      minW: 5,
      maxW: 12,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 4,
      x: 0,
      y: 10,
      i: 'lctn',
      moved: false,
      static: false,
    },
  ],
  md: [
    {
      w: 4,
      h: 3,
      x: 4,
      y: 8,
      i: 'usrByAg',
      minW: 0,
      maxW: 6,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },

    {
      w: 4,
      h: 4,
      x: 0,
      y: 8,
      i: 'usrByGndr',
      minW: 0,
      maxW: 6,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 4,
      x: 8,
      y: 8,
      i: 'usrByDvc',
      minW: 0,
      maxW: 6,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 4,
      x: 0,
      y: 0,
      i: 'cnvrsnFnnl',
      minW: 1,
      maxW: 12,
      minH: 3,
      maxH: 5,
      moved: false,
      static: false,
    },
    {
      w: 12,
      h: 3,
      x: 0,
      y: 4,
      i: 'cmpgnSmmry',
      minW: 1,
      maxW: 12,
      minH: 3,
      maxH: 5,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 5,
      x: 6,
      y: 12,
      i: 'mnthlyInsghts',
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 7,
      moved: false,
      static: false,
    },
    {
      w: 12,
      h: 5,
      x: 0,
      y: 17,
      i: 'yrlyInsghts',
      minW: 4,
      maxW: 12,
      minH: 4,
      maxH: 7,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 4,
      x: 6,
      y: 0,
      i: 'adInsghtsMt',
      minW: 4,
      maxW: 12,
      minH: 3,
      maxH: 8,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 5,
      x: 0,
      y: 12,
      i: 'lctn',
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      h: 3,
      i: 'adInsghtsMt',
      w: 6,
      x: 0,
      y: 2,
      maxH: 5,
      maxW: 6,
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
    },
    {
      h: 4,
      i: 'cnvrsnFnnl',
      w: 3,
      x: 0,
      y: 5,
      maxH: 4,
      maxW: 4,
      minH: 3,
      minW: 3,
      moved: false,
      static: false,
    },
    {
      h: 3,
      i: 'cmpgnSmmry',
      w: 3,
      x: 3,
      y: 5,
      maxH: 4,
      maxW: 6,
      minH: 3,
      minW: 3,
      moved: false,
      static: false,
    },
    {
      h: 3,
      i: 'usrByGndr',
      w: 3,
      x: 0,
      y: 9,
      maxH: 4,
      maxW: 3,
      minH: 2,
      minW: 2,
      moved: false,
      static: false,
    },
    {
      h: 3,
      i: 'usrByAg',
      w: 3,
      x: 3,
      y: 9,
      maxH: 4,
      maxW: 3,
      minH: 2,
      minW: 2,
      moved: false,
      static: false,
    },
    {
      h: 3,
      i: 'usrByDvc',
      w: 3,
      x: 0,
      y: 12,
      maxH: 4,
      maxW: 3,
      minH: 2,
      minW: 2,
      moved: false,
      static: false,
    },
    {
      h: 4,
      i: 'yrlyInsghts',
      w: 3,
      x: 3,
      y: 15,
      maxH: 4,
      maxW: 6,
      minH: 2,
      minW: 2,
      moved: false,
      static: false,
    },
    {
      h: 3,
      i: 'mnthlyInsghts',
      w: 3,
      x: 3,
      y: 12,
      maxH: 4,
      maxW: 6,
      minH: 2,
      minW: 2,
      moved: false,
      static: false,
    },
    {
      h: 4,
      i: 'lctn',
      w: 3,
      x: 0,
      y: 15,
      moved: false,
      static: false,
    },
  ],
  xs: [
    {
      w: 4,
      h: 4,
      x: 0,
      y: 26,
      i: 'usrByAg',
      minW: 2,
      maxW: 4,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 5,
      x: 0,
      y: 16,
      i: 'usrByGndr',
      minW: 2,
      maxW: 4,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 5,
      x: 0,
      y: 21,
      i: 'usrByDvc',
      minW: 2,
      maxW: 4,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 6,
      x: 0,
      y: 5,
      i: 'cnvrsnFnnl',
      minW: 4,
      maxW: 4,
      minH: 4,
      maxH: 8,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 11,
      i: 'cmpgnSmmry',
      minW: 4,
      maxW: 4,
      minH: 4,
      maxH: 8,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 6,
      x: 0,
      y: 41,
      i: 'mnthlyInsghts',
      minW: 2,
      maxW: 4,
      minH: 3,
      maxH: 7,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 6,
      x: 0,
      y: 35,
      i: 'yrlyInsghts',
      minW: 2,
      maxW: 4,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 5,
      x: 0,
      y: 0,
      i: 'adInsghtsMt',
      minW: 2,
      maxW: 4,
      minH: 2,
      maxH: 7,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 4,
      x: 0,
      y: 31,
      i: 'lctn',
      moved: false,
      static: false,
    },
  ],
  xxs: [
    {
      w: 2,
      h: 4,
      x: 0,
      y: 19,
      i: 'usrByAg',
      minW: 2,
      maxW: 2,
      minH: 2,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 14,
      i: 'usrByGndr',
      minW: 2,
      maxW: 2,
      minH: 2,
      maxH: 10,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 6,
      x: 0,
      y: 24,
      i: 'usrByDvc',
      minW: 2,
      maxW: 2,
      minH: 2,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 4,
      i: 'cnvrsnFnnl',
      minW: 2,
      maxW: 2,
      minH: 5,
      maxH: 8,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 3,
      x: 0,
      y: 9,
      i: 'cmpgnSmmry',
      minW: 2,
      maxW: 2,
      minH: 2,
      maxH: 8,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 40,
      i: 'mnthlyInsghts',
      minW: 2,
      maxW: 2,
      minH: 2,
      maxH: 8,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 35,
      i: 'yrlyInsghts',
      minW: 2,
      maxW: 2,
      minH: 2,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 4,
      x: 0,
      y: 0,
      i: 'adInsghtsMt',
      minW: 1,
      maxW: 2,
      minH: 3,
      maxH: 6,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 30,
      i: 'lctn',
      moved: false,
      static: false,
    },
  ],
};

const getLayout = (platform) =>
  platform === 'meta' ? metaLayouts : googleLayouts;

export { getLayout };
