/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import { Container, Box, Button, Grid } from '@mui/material';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { DynamicSelectBox } from '../../FeedManagement/Components/GlobalComponents/DynamicSelectBox';
import {
  useGetUsersAccessDetailsQuery,
  useEditUsersDetailsMutation,
  useCreateAgencyCompanyUsersMutation,
} from '../../../api/RTK/services/user';
import { AddAgencyCompanyUserSchema } from '../Schema/schemas';
import { FormikField } from '../../../widgets/FormikForm/FormikField';
import { TitleCard } from '../../FeedManagement/Components/GlobalComponents/TitlePaper';
import { StyledPaper } from '../../FeedManagement/PageViewComponents/StyledMainBox';
import { LoadingDialog } from '../Components/LoadingDialog';
import { CategoryCheckbox } from '../../../widgets/SelectCategory/CategoryCheckbox';
import { GlobalErrorComponent } from '../../../components/GlobalErrorComponent';

const AddAgencyCompanyUser = () => {
  const { user, companyList } = useContext(UserInfoContext);
  const { id } = useParams();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    company: '',
    radioValue: '',
  });

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  const {
    data: getUser,
    isLoading: getLoading,
    isError: getError,
  } = useGetUsersAccessDetailsQuery({ id }, { skip: !isEdit });

  useEffect(() => {
    if (isEdit) {
      setInitialValues({
        name: getUser?.username || '',
        email: getUser?.email || '',
        company: getUser?.company_id || '',
        radioValue:
          getUser?.role === 'SUB_ADMIN'
            ? 'company_admin'
            : 'company_collaborator' || '',
      });
    }
  }, [getUser]);

  const [
    addCompanyUser,
    { isLoading: addCompanyUserLoading, isError: addIsError, error: addError },
  ] = useCreateAgencyCompanyUsersMutation();

  const [
    editUser,
    { isError: editUserError, isLoading: editLoading, error: editErrorMsg },
  ] = useEditUsersDetailsMutation({
    skip: !isEdit,
  });

  const companies = [];
  if (companyList.length > 0) {
    companyList.forEach((item) => {
      companies.push({
        title: item.name,
        value: item.id,
      });
    });
  }

  const handleOnSubmit = async (values) => {
    const userType =
      values.radioValue === 'company_admin' ? 'SUB_ADMIN' : 'SUB_COLLABORATOR';

    const userData = {
      user_type: userType,
    };

    if (isEdit && getUser && getUser.company !== values.company) {
      userData.company_id = values.company;
    }
    const requestData = isEdit
      ? { id, data: userData }
      : {
          name: values.name,
          email: values.email.toLowerCase().trim(),
          projectID: user?.projectId,
          userType,
          companyId: values.company,
        };
    const requestFunction = isEdit ? editUser : addCompanyUser;
    setOpen(true);   
    try {
      await requestFunction(requestData)
        .unwrap()
        .then(() => {
          setOpen(true);
          setTimeout(() => setOpen(false), 1500);
          setTimeout(() => navigate('/users/company'), 1500);
        })
        .catch(() => {
          setOpen(true);
        });
    } catch (errors) {
      console.error('Error occurred:', errors);
    }
  };

  const data = [
    {
      id: 1,
      value: 'company_admin',
      labels: ['Accounts', 'Dashboard', 'Feed Management', 'Users', 'Payment'],
      labelSecond: [
        'View',
        'View & Customize',
        'Create, Edit & Delete all projects, Feeds, Actions, Filters, Schedule',
        'Add, Edit & Delete',
        'View history',
      ],
    },
    {
      id: 2,
      value: 'company_collaborator',
      labels: ['Accounts', 'Dashboard', 'Feed Management', 'Users', 'Payment'],
      labelSecond: ['View', 'View & Customize', 'View', 'View', 'View history'],
    },
  ];

  const onClose = () => setOpen(false);

  return (
    <FetchingDisplay
      isLoading={getLoading}
      isError={getError}
      ErrorElement={<GlobalErrorComponent />}
      LoadingElement={<GlobalLoadingComponent />}
      SuccessElement={
        <Container maxWidth='xxl' sx={{ marginTop: '30px' }}>
          <TitleCard title='Company User Access' />
          <StyledPaper>
            <Box>
              <Formik
                enableReinitialize
                validateOnBlur={false}
                initialValues={initialValues}
                validationSchema={AddAgencyCompanyUserSchema}
                onSubmit={handleOnSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <FormikField
                          required
                          placeholder='Enter Name'
                          name='name'
                          type='text'
                          value={values.name}
                          label='Name'
                          error={Boolean(errors.name) && touched.name}
                          helperText={touched.name && errors.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isEdit}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormikField
                          required
                          placeholder='Enter Email ID'
                          name='email'
                          type='email'
                          value={values.email}
                          label='Email'
                          error={Boolean(errors.email) && touched.email}
                          helperText={touched.email && errors.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isEdit}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <DynamicSelectBox
                          name='company'
                          inputTitle='Company Name'
                          value={values.company}
                          placeholderText='Select Company'
                          selectedItem={companies}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={touched.company && errors.company}
                          errorColor='#E13F2A'
                          sx={{
                            position: 'relative',
                            color: '#3d4955',
                            fontSize: '14px',
                            top: '-22px',
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <CategoryCheckbox
                          data={data}
                          value={values.radioValue}
                          setValue={(newValue) => {
                            setValue(newValue);
                            handleChange({
                              target: { name: 'radioValue', value: newValue },
                            });
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='radioValue'
                          error={errors.radioValue}
                          variant='smallRegularFaded'
                        />
                      </Grid>
                    </Grid>
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      gap={3}
                      mt={3}
                    >
                      <Button
                        variant='outlined'
                        component={Link}
                        onClick={() => navigate(-1)}
                        sx={{ minWidth: '200px', maxWidth: '220px', p: 1 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type='submit'
                        variant='contained'
                        sx={{ minWidth: '200px', maxWidth: '220px', p: 1 }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </StyledPaper>
          <LoadingDialog
            open={open}
            isLoading={isEdit ? editLoading : addCompanyUserLoading}
            isError={isEdit ? editUserError : addIsError}
            onClose={onClose}
            successMessage={isEdit ? 'Access Edited' : 'Invite Send'}
            errorMessage={
              isEdit
                ? editErrorMsg?.data?.error?.detail
                : addError?.data?.error?.detail
            }
          />
        </Container>
      }
    />
  );
};

export { AddAgencyCompanyUser };
