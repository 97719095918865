import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// MUI imports
import { Backdrop, Box, CircularProgress, Container } from '@mui/material';

// Internal imports
import { ErrorBoundary } from 'react-error-boundary';
import { SummaryBox } from '../Components/OtherComponents/ProjectSummaryBoxMain';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';

// Styled imports
import { StyledPaper } from './StyledMainBox';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { FetchDisplay } from '../../../components/FetchDisplay';
import { ElementPicker } from '../../../components/ElementPicker';
import { SelectAccOrCompany } from '../../../components/SelectAccOrCompany';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { useGetAllProjectsQuery } from '../../../api/RTK/services/feedProject';
import { Search } from '../Components/GlobalComponents/Search';
import { PageErrors } from '../../../components/PageErrors';
import { clearStore } from '../Redux/store';

// Main functional component
const AllProjects = (handleSearch) => {
  const feedlist = useSelector((state) => state.allProjectsRedux.feeds);
  const { company } = useContext(UserInfoContext);
  const [projects, setProjects] = useState([]);

  const [filteredProjects, setFilteredProjects] = useState([]);
  const showStartAdornment = true;
  const {
    data,
    isLoading,
    isError,
    isUninitialized,
    isFetching,
    error,
    refetch,
  } = useGetAllProjectsQuery({ companyId: company?.id }, { skip: !company });
  useEffect(() => {
    if (!isLoading && !isError && !isUninitialized) {
      setProjects(data);
      setFilteredProjects(data);
    }
  }, [isLoading, isError, isUninitialized, data]);
  //  useEffect(() => {
  //   refetch()
  //  },[feedlist])

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [feedlist]);

  const [lastClearTime, setLastClearTime] = useState(Date.now());

  useEffect(() => {
    // Set an interval to call clearStore every 24 hours
    const clearStoreInterval = setInterval(() => {
      const currentTime = Date.now();
      const hoursPassed = (currentTime - lastClearTime) / (1000 * 60 * 60); // Hours since last clear
      if (hoursPassed >= 24) {
        clearStore();
        setLastClearTime(currentTime);
      }
    }, 60 * 60 * 1000);

    return () => {
      clearInterval(clearStoreInterval);
    };
  }, [lastClearTime]);

  return (
    <FetchDisplay
      isLoading={isLoading}
      isError={isError}
      isPrefetch={isUninitialized}
      PrefetchElement={
        <Box
          sx={{
            height: 'calc(100vh - 244px)',
            display: 'grid',
            placeItems: 'center',

            '@media(min-width: 800px)': {
              height: 'calc(100vh - 152px)',
            },
          }}
        >
          <ElementPicker
            case1={!company}
            element1={
              <SelectAccOrCompany promptText='Select a Company to view its projects' />
            }
          />
        </Box>
      }
      ErrorElement={
        <PageErrors
          isServerError
          code={error?.originalStatus || error?.status}
        />
      }
      LoadingElement={<GlobalLoadingComponent />}
      SuccessElement={
        <ErrorBoundary fallback={<PageErrors />}>
          <Container maxWidth='xxl' sx={{ marginTop: '30px' }}>
            <TitleCard title='Projects'>
              <Search
                showStartAdornment={showStartAdornment}
                data={projects} // pass the data as prop
                setData={setFilteredProjects} // pass the setData as prop
                searchKey='name' // add searchKey prop if needed
                placeholder='Search projects'
                onSearch={handleSearch}
              />
            </TitleCard>
            <StyledPaper>
              <SummaryBox data={filteredProjects} />
            </StyledPaper>
            <Backdrop sx={{ color: '#fff' }} open={!isLoading && isFetching}>
              <CircularProgress color='inherit' />
            </Backdrop>
          </Container>
        </ErrorBoundary>
      }
    />
  );
};

export { AllProjects };
