import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

// mui imports
import { Box, Button, Container } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DeleteOutline } from '@mui/icons-material';

// internal component imports
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { useDataFetcher } from '../../../Hooks/useDataFetcher';
import { feedManagementService } from '../../../services/feedManagement';
import { FilterForm } from './Forms/Filter';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { PageErrors } from '../../../components/PageErrors';
import { useCreateFilterMutation } from '../../../api/RTK/services/feedFeed';
import { TitleCard } from '../Components/GlobalComponents/TitlePaper';
import { DataSendingModalBaseRTK } from '../../../components/DataSendingModalBaseRTK';
import { AffectedItemsButton } from '../Components/GlobalComponents/AffectedItemButton';

// styled component
import { StyledPaper } from './StyledMainBox';

// main function component
const CreateFilter = () => {
  const { company } = useContext(UserInfoContext);
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );
  const navigate = useNavigate();
  const feedId = useSelector((state) => state.feedSummaryRedux.feedId);

  const memoizedArgs = useMemo(
    () => ({
      companyId: company.id,
      feedProjectId: projectSelectId,
      feedId,
      active: true,
    }),
    []
  );

  const [state] = useDataFetcher(
    {},
    feedManagementService.getProjectFields,
    memoizedArgs,
    '',
    !!company && !!projectSelectId && !!feedId
  );

  const [createFilter, { isLoading, isError, isSuccess, reset, error }] =
    useCreateFilterMutation();

  const errorHandle = () => {
    if (error && error.status === 403) {
      if (error?.data[0]) {
        return error.data[0].error || 'Same name filter exists in other feeds';
      }
    }
    return 'Unable to create filter';
  };

  const [shouldExecuteQuery, setShouldExecuteQuery] = useState(false);

  const handleSubmit = (values) => {
    createFilter({
      projectId: projectSelectId,
      companyId: company.id,
      feedId,
      data: values,
    }).then((response) => {
      if (response.data) {
        setShouldExecuteQuery(true);
        const filterId = response.data?.id;
        setTimeout(() => {
          navigate(`../feeds/enhance/filter/${filterId}`);
        }, 600);
      }
    });
  };

  // useEffect to reset shouldExecuteQuery to false when component unmounts
  useEffect(() => () => setShouldExecuteQuery(false), []);

  return (
    <FetchingDisplay
      isLoading={state.isLoading}
      isError={state.isError}
      SuccessElement={
        <ErrorBoundary fallback={<PageErrors />}>
          <Container maxWidth='none'>
            <TitleCard title='New Filter'>
              <AffectedItemsButton
                to={{ pathname: '/feeds/enhance/affected-items', state: {} }}
                sx={{
                  '&.numberChip': {
                    backgroundColor: '#FF823C !important',
                  },
                  marginRight: '24px',
                }}
                disabled={!shouldExecuteQuery}
                label={
                  <RefreshIcon
                    color='inherit'
                    sx={{
                      zIndex: 2,
                      width: '20px !important',
                      height: '20px !important',
                      marginTop: '8px',
                    }}
                  />
                }
                buttonName='View removed items'
              />
              <AffectedItemsButton
                to={{ pathname: '/feeds/enhance/affected-items', state: {} }}
                sx={{ marginRight: '24px' }}
                disabled={!shouldExecuteQuery}
                label={
                  <RefreshIcon
                    color='inherit'
                    sx={{
                      zIndex: 2,
                      width: '20px !important',
                      height: '20px !important',
                      marginTop: '8px',
                    }}
                  />
                }
                buttonName='View remaining items'
              />

              <Button
                variant='pillOutlined'
                sx={{
                  height: '44px',
                  minWidth: '150px',
                  '&:hover': {
                    svg: { color: '#e13f2a !important' },
                    color: '#e13f2a',
                    border: '1px solid #e13f2a',
                  },
                }}
                startIcon={<DeleteOutline />}
                disabled={!shouldExecuteQuery}
              >
                Delete Action
              </Button>
            </TitleCard>
            <StyledPaper sx={{ padding: '24px 0px' }}>
              <FilterForm
                fields={state.data?.map((field) => field.name) || []}
                handleSubmit={handleSubmit}
                btnName='Save'
              />
            </StyledPaper>
          </Container>{' '}
          <DataSendingModalBaseRTK
            open={isLoading || isSuccess || isError}
            handleCloseModal={reset}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            successMessage='Filter created successfully'
            errorMessage={errorHandle()}
            reset={reset}
          >
            <Box sx={{ height: '200px' }} />
          </DataSendingModalBaseRTK>
        </ErrorBoundary>
      }
      LoadingElement={<GlobalLoadingComponent />}
      ErrorElement={<PageErrors isServerError code={state?.errorInfo?.code} />}
    />
  );
};

export { CreateFilter };
