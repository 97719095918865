import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import { Tooltip } from '@mui/material';

const Filter = ({
  label,
  selectedFilter,
  filterList,
  handleFilterChange,
  className,
}) => {
  const onChange = (event) => {
    handleFilterChange(filterList.find((f) => f.value === event.target.value));
  };

  return (
    <Box
      sx={{
        minWidth: 120,
        ml: '8px',
      }}
      className={className}
    >
      <FormControl
        sx={{
          minWidth: '140px',
          maxWidth: '200px',
          '& fieldset': { borderRadius: '999px' },
        }}
        variant='outlined'
      >
        <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-select-label`}
          id={`${label}-simple-select`}
          value={
            selectedFilter?.value ||
            (filterList.length > 0 ? filterList[0].value : '')
          }
          label='Filter'
          onChange={onChange}
          IconComponent={ExpandMoreRounded}
          sx={{ padding: 0 }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: '250px',
                width: 'auto',
              },
            },
          }}
        >
          {filterList.map((f) => (
            <MenuItem
              key={f.value}
              value={f.value}
              sx={{
                minHeight: '140px',
                maxHeight: '400px',
              }}
            >
              <Box
                sx={{
                  padding: '0rem 0.5rem',
                  maxWidth: '220px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: '14px',
                }}
              >
                {f.name.length > 24 ? (
                  <Tooltip
                    title={f.name}
                    PopperProps={{
                      style: { zIndex: 9999999 },
                    }}
                  >
                    <span>{f.name}</span>
                  </Tooltip>
                ) : (
                  f.name
                )}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export { Filter };
