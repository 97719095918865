import React from 'react';
import { Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { usersService } from '../../../../services/users';
import { useDataFetcher } from '../../../../Hooks/useDataFetcher';
import { FetchingDisplay } from '../../../../components/FetchingDisplay';
import { CreateCompanyFormOnboarding } from '../../Components/Forms/CreateCompanyFormOnboarding';
import { PagesWrraper } from '../../../Forms/Pages/PagesWrraper';

const CreateCompanyPage = () => {
  const [state] = useDataFetcher(
    { isLoading: true, isError: false },
    usersService.getInvites
  );

  return (
    <FetchingDisplay
      isLoading={state.isLoading}
      isError={state.isError}
      LoadingElement={
        <Box sx={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
          Loading...
        </Box>
      }
      ErrorElement={
        <Box sx={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
          Error
        </Box>
      }
      SuccessElement={
        state.data?.length > 0 ? (
          <Navigate to='/invites' />
        ) : (
          <PagesWrraper>
            <CreateCompanyFormOnboarding />
          </PagesWrraper>
        )
      }
    />
  );
};

export { CreateCompanyPage };
