import React, { useRef, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Paper,
    Stack,
    Typography,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import { CTABox } from '../../Dashboards/Pages/DemoDashboard/CTABox';
import {
    GoogleAdsLogo,
    FacebookLogo,
    InstagramLogo,
    CSV,
    XML,
    TEXT,
    JSON,
    GoogleShoppingLogo,
} from '../../../constants/endpoints/assests/logos';
import { DynamicSelectBox } from '../../FeedManagement/Components/GlobalComponents/DynamicSelectBox';
import { StyledBox } from '../../FeedManagement/Components/StyledComponents/StyledImportFileForm';
import { DynamicTextField } from '../../FeedManagement/Components/GlobalComponents/DynamicTextField';
import { feedManagementService } from '../../../services/feedManagement';
import { demoValidationSchema } from '../../FeedManagement/Schemas';
// import { useDataFetcher } from '../../../Hooks/useDataFetcher';

const fileTypeOptions = [
    {
        title: 'XML',
        value: 'XML',
    },
    {
        title: 'CSV',
        value: 'CSV',
    },
    {
        title: 'JSON',
        value: 'JSON',
    },
    {
        title: 'Text',
        value: 'TEXT',
    },
];

const fileTypeImageMap = {
    CSV,
    XML,
    TEXT,
    JSON,
};
const DemoFeedForm = () => {
    const formikRef = useRef();
    // eslint-disable-next-line no-unused-vars
    const [fetchedData, setFetchedData] = useState();
    const [isLoading, setisLoading] = useState();

    const navigate = useNavigate();

    const handleRedirect = (data) => {
        feedManagementService
            .getURLDataDemoQuality(data)
            .then((response) => {
                const responseData = response.data; // Store the API response in a separate variable
                setFetchedData(responseData);
                setisLoading(false); // Update the state with the fetched data
                navigate('/feed-management', {
                    state: { fetchedData: responseData },
                }); // Pass the responseData as state
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    return (
        <>
            <CTABox ctaText='Want to get Optimized Feed Score today?' />
            <Container maxWidth='lg' sx={{ marginTop: '30px' }}>
                <Box>
                    <Stack direction='column' justifyContent='center' alignItems='center'>
                        <Typography variant='h2'>
                            Improve your Ad Results for
                            <img
                                src={GoogleAdsLogo}
                                alt='image1'
                                height={15}
                                width={15}
                                style={{ marginRight: '4px', marginTop: '2px' }}
                            />
                            <img
                                src={FacebookLogo}
                                alt='image1'
                                height={15}
                                width={15}
                                style={{ marginRight: '4px', marginTop: '2px' }}
                            />
                            <img
                                src={InstagramLogo}
                                alt='iamge 3'
                                height={15}
                                width={15}
                                style={{ marginRight: '4px', marginTop: '2px' }}
                            />
                        </Typography>
                        <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                            <span style={{ paddingRight: '2px' }}>
                                Create a product feed that matches your marketing platform
                                standards. Use our recommended fields option to make your ads
                                stand out on{' '}
                            </span>
                            <img
                                src={GoogleAdsLogo}
                                alt='image1'
                                height={15}
                                width={15}
                                style={{ marginRight: '4px', marginTop: '2px' }}
                            />
                            <span style={{ paddingRight: '6px' }}>Google,</span>
                            <img
                                src={FacebookLogo}
                                alt='image2'
                                height={15}
                                width={15}
                                style={{ marginRight: '4px', marginTop: '2px' }}
                            />
                            <span style={{ paddingRight: '6px' }}>Facebook and</span>
                            <img
                                src={InstagramLogo}
                                alt='iamge 3'
                                height={15}
                                width={15}
                                style={{ marginRight: '4px', marginTop: '2px' }}
                            />
                            Instagram Ads. Make sure your products have optional feed score.
                        </Box>
                    </Stack>
                    <Container maxWidth='sm'>
                        <Paper
                            sx={{
                                marginTop: '20px',
                                marginBottom: '20px',
                                borderRadius: '5px',
                                paddingLeft: '15px',
                                paddingRight: '15px',
                                paddingTop: '5px',
                            }}
                        >
                            <Formik
                                initialValues={{
                                    file_type: fileTypeOptions[0]?.value || '',
                                    file_url: '',
                                }}
                                innerRef={formikRef}
                                validationSchema={demoValidationSchema}
                                enableReinitialize
                                onSubmit={(values, { setSubmitting }) => {
                                    setisLoading(true);
                                    handleRedirect(values);
                                    // Assuming the fetched data is stored in the `fetchedData` state variable
                                    setSubmitting(false); // Set submitting to false to indicate form submission is complete
                                }}
                            >
                                {({ values, handleChange, handleBlur }) => (
                                    <Form>
                                        <Stack
                                            alignItems='center'
                                            direction='column'
                                            flexWrap='wrap'
                                        >
                                            <StyledBox
                                                className='importSelectForm'
                                                sx={{ width: '100%', paddingRight: '0px !important' }}
                                            >
                                                <DynamicSelectBox
                                                    name='file_type'
                                                    value={values.file_type}
                                                    inputTitle='Select file'
                                                    placeholderText='Select file type'
                                                    selectedItem={fileTypeOptions}
                                                    itemImage={fileTypeImageMap}
                                                    // disabled={isSubmitting}
                                                    handleChange={(event) => {
                                                        handleChange(event);
                                                    }}
                                                    handleBlur={handleBlur}
                                                />
                                            </StyledBox>
                                            <StyledBox
                                                className='importInputForm'
                                                sx={{ width: '100%', paddingLeft: '0px !important' }}
                                            >
                                                <DynamicTextField
                                                    name='file_url'
                                                    value={values.file_url}
                                                    inputPlaceholder='www.example.com/feed'
                                                    textTitle='Paste feed link below'
                                                    // disabled={isSubmitting}
                                                    inType='text'
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                                <ErrorMessage
                                                    name='file_url'
                                                    component='div'
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '12px',
                                                        marginTop: '5px',
                                                    }}
                                                />
                                            </StyledBox>
                                            <Button
                                                sx={{
                                                    width: '200px',
                                                    margin: '25px 25px 5px 25px',
                                                }}
                                                variant='contained'
                                                type='submit'
                                            >
                                                Check Feed Quality
                                            </Button>
                                            <Box sx={{ marginBottom: '20px' }}>
                                                for
                                                <img
                                                    src={GoogleShoppingLogo}
                                                    alt='google_shopping_image'
                                                    height={20}
                                                    width={20}
                                                    style={{ margin: '10px 5px 0px 5px' }}
                                                />
                                                Google Shopping
                                            </Box>
                                        </Stack>
                                    </Form>
                                )}
                            </Formik>
                        </Paper>
                    </Container>
                </Box>
                <Backdrop sx={{ color: '#fff' }} open={isLoading}>
                    <CircularProgress color='inherit' />
                </Backdrop>
            </Container>
        </>
    );
};

export { DemoFeedForm };
