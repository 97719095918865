// react imports
import React, { useState, useRef } from 'react';

// third party imports
import { MenuList, Popover } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { HeaderMenuText, HeaderMenuToggle } from './styles';

const HeaderMenu = ({ text, children, sx, setActiveState }) => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleOpen = () => {
    setOpen(true);
    setActiveState(true);
  };

  const handleClose = () => {
    setOpen(false);
    setActiveState(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  return (
    <li>
      <HeaderMenuToggle
        ref={anchorEl}
        aria-describedby={`dropdown-${text}`}
        aria-owns={open ? `dropdown-${text}` : undefined}
        aria-controls={open ? `dropdown-${text}` : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        disableRipple
        id={`header-${text}`}
        onClick={handleOpen}
        endIcon={<KeyboardArrowDownIcon />}
        open={open}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        sx={sx}
      >
        <HeaderMenuText variant='a'>{text}</HeaderMenuText>
      </HeaderMenuToggle>
      <Popover
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        elevation={1}
        id={`dropdown-${text}`}
        onClose={handleClose}
        onClickCapture={handleClose}
        open={open}
        PaperProps={{ onMouseEnter: handleOpen, onMouseLeave: handleClose }}
        sx={{
          padding: 0,
          pointerEvents: 'none',
          '& .MuiPaper-root': {
            pointerEvents: 'auto',
          },
        }}
        disableRestoreFocus
      >
        <MenuList
          aria-labelledby={`header-${text}`}
          onKeyDown={handleListKeyDown}
          id={`dropdown-${text}`}
          variant='menu'
        >
          {children}
        </MenuList>
      </Popover>
    </li>
  );
};

export { HeaderMenu };
