import { store } from '../pages/FeedManagement/Redux/store';
import { updateCompany } from '../pages/FeedManagement/Redux/CompanyData';

const setCompanyInRTK = (company) => {
  store.dispatch(updateCompany(company));
};

const setAccountInLocalStorage = (account) => {
  if (account) {
    const {
      online_sales: onlineSales,
      marketing_cost: marketingCost,
      customer_id: customerId,
      is_active: isActive,
      is_pinned: isPinned,
      ...rest
    } = account;
    window.localStorage.setItem('AdHelp-account', JSON.stringify(rest));
  } else {
    window.localStorage.setItem('AdHelp-account', JSON.stringify(null));
  }
};

const getCompanyFromRTK = () => {
  try {
    return store.getState().companyData.companyData;
  } catch {
    return null;
  }
};

const getAccountFromLocalStorage = () => {
  try {
    return JSON.parse(window.localStorage.getItem('AdHelp-account'));
  } catch {
    return null;
  }
};

const storageService = {
  setCompanyInRTK,
  setAccountInLocalStorage,
  getCompanyFromRTK,
  getAccountFromLocalStorage,
};
export { storageService };
