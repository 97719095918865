import {
  CSV,
  XML,
  TEXT,
  JSON,
  SHOPIFY,
  WooLogo as WOO_COMMERCE,
} from '../../../../../constants/endpoints/assests/logos';

const fileTypeOptions = [
  {
    title: 'XML',
    value: 'XML',
  },
  {
    title: 'CSV',
    value: 'CSV',
  },
  {
    title: 'JSON',
    value: 'JSON',
  },
  {
    title: 'Text',
    value: 'TEXT',
  },
  {
    title: 'Shopify',
    value: 'SHOPIFY',
  },
  {
    title: 'WooCommerce',
    value: 'WOO_COMMERCE',
  },
];

const fileTypeImageMap = {
  CSV,
  XML,
  TEXT,
  JSON,
  SHOPIFY,
  WOO_COMMERCE,
};

const protocolOptions = [
  {
    title: 'https://',
    value: 'https://',
  },
  {
    title: 'http://',
    value: 'http://',
  },
  {
    title: 'sftp://',
    value: 'sftp://',
  },
];

const authenticationOptions = [
  {
    title: 'No authentication',
    value: 'NO_AUTH',
  },
  {
    title: 'Username & password(basic auth)',
    value: 'BASIC_AUTH',
  },
];

const encodingOptions = [
  {
    title: 'Automatically detect',
    value: 'AUTO',
  },
  {
    title: 'UTF-8',
    value: 'UTF8',
  },
  {
    title: 'UTF-16 with byte order mark',
    value: 'UTF16_BYTE_ORDER_MARK',
  },
  {
    title: 'UTF-16 (little endian)',
    value: 'UTF16_LITTLE_ENDIAN',
  },
  {
    title: 'UTF-16 ( big endian)',
    value: 'UTF16_BIG_ENDIAN',
  },
  {
    title: 'UTF-32 with byte order mark',
    value: 'UTF32_BYTE_ORDER_MARK',
  },
  {
    title: 'UTF-32 (little endain)',
    value: 'UTF32_LITTLE_ENDIAN',
  },
  {
    title: 'UTF-32 (big endian)',
    value: 'UTF32_BIG_ENDIAN',
  },
  {
    title: 'ISO-8859-1',
    value: 'ISO_8859_1',
  },
  {
    title: 'ISO-8859-15',
    value: 'ISO_8859_15',
  },
  {
    title: 'Windows-1250',
    value: 'WINDOWS_1250',
  },
  {
    title: 'Windows-1251',
    value: 'WINDOWS_1251',
  },
  {
    title: 'Windows-1252',
    value: 'WINDOWS_1252',
  },
  {
    title: 'Windows-1254',
    value: 'WINDOWS_1254',
  },
  {
    title: 'ASCII',
    value: 'ASCII',
  },
];

const quotingOptions = [
  {
    title: 'Automatically detect',
    value: 'AUTO',
  },
  {
    title: 'Yes',
    value: 'YES',
  },
  {
    title: 'No',
    value: 'NO',
  },
];

const delimiterOptions = [
  {
    title: 'Automatically detect',
    value: 'AUTO',
  },
  {
    title: ',',
    value: 'COMMA',
  },
  {
    title: ';',
    value: 'SEMI_COLON',
  },
  {
    title: '|',
    value: 'PIPE',
  },
  {
    title: 'tab',
    value: 'TAB',
  },
  {
    title: '~',
    value: 'TILD',
  },
  {
    title: '^',
    value: 'CAP',
  },
];

export {
  fileTypeImageMap,
  authenticationOptions,
  delimiterOptions,
  encodingOptions,
  fileTypeOptions,
  quotingOptions,
  protocolOptions,
};
