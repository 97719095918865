import styled from '@emotion/styled';
import { Box } from '@mui/material';

const POASMainDiv = styled(Box)`
  .POAScalculatorHeading {
    margin: 24px 0px;
  }
  #UniversalOverlayid {
    position: relative;
    #InnerDiv {
      position: absolute;
    }
  }
`;

export { POASMainDiv };
