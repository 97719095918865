import React from 'react';
import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
} from '@mui/material';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  StyledFormikWrapper,
  StyledFormikField,
  StyledInputLabel,
  StyledFormikFieldCheckbox,
} from './StyledFormikField';

const FormikField = ({
  name,
  label,
  required = false,
  type,
  placeholder,
  value,
  error,
  helperText,
  onChange,
  onBlur,
  disabled,
}) => (
  <StyledFormikWrapper className='FormikField'>
    <StyledInputLabel htmlFor={name}>
      <Typography variant='smallMedium'>{label}</Typography>
    </StyledInputLabel>
    <StyledFormikField
      id={name}
      required={required}
      as={TextField}
      variant='outlined'
      fullWidth
      autoComplete='on'
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      error={error}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  </StyledFormikWrapper>
);

const FormikPasswordField = ({
  name,
  label,
  required = false,
  placeholder,
  type,
  value,
  error,
  helperText,
  onChange,
  onBlur,
  setShowPassword,
  showPassword,
}) => {
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <StyledFormikWrapper className='FormikField'>
      <StyledInputLabel htmlFor={name}>
        <Typography variant='smallMedium'>{label}</Typography>
      </StyledInputLabel>
      <StyledFormikField
        id={name}
        required={required}
        as={TextField}
        variant='outlined'
        fullWidth
        autoComplete='on'
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        error={error}
        helperText={helperText}
        onChange={onChange}
        onBlur={onBlur}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </StyledFormikWrapper>
  );
};

const FormikPhone = ({ name, label, value, error, onChange, onBlur, sx }) => (
  <StyledFormikWrapper className='FormikField'>
    <StyledInputLabel htmlFor={name}>
      <Typography variant='h5' sx={{ mb: 0.8 }}>
        {label}
      </Typography>
    </StyledInputLabel>
    <StyledFormikField
      name={name}
      id={name}
      component={ReactPhoneInput}
      variant='outlined'
      fullWidth
      autoFormat
      countryCodeEditable={false}
      value={value}
      error={error}
      onChange={onChange}
      onBlur={onBlur}
      country='in'
      inputStyle={{
        width: '100%',
        height: '38px',
        borderColor: error ? '#E13F2A' : '#DEDEDE',
      }}
      buttonStyle={{
        borderColor: error ? '#E13F2A' : '#DEDEDE',
      }}
      inputProps={{
        required: true,
        placeholder: 'Enter phone number',
        name: 'phone_number',
        id: 'phone_number',
      }}
      sx={sx}
    />
  </StyledFormikWrapper>
);

const FormikCheckboxField = ({
  name,
  required = true,
  type,
  value,
  error,
  onChange,
  onBlur,
}) => (
  <StyledFormikWrapper className='FormikField'>
    <StyledFormikFieldCheckbox
      id={name}
      required={required}
      as={Checkbox}
      variant='outlined'
      name={name}
      type={type}
      value={value}
      error={error}
      onChange={onChange}
      onBlur={onBlur}
      disableRipple
      disableFocusRipple
      disableTouchRipple
    />
  </StyledFormikWrapper>
);

export { FormikField, FormikPasswordField, FormikCheckboxField, FormikPhone };
