import React from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IconButton } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';

import { StyledSlider } from './styles';

const PreviousButton = ({ className, style, onClick }) => (
  <IconButton
    onClick={onClick}
    style={{
      ...style,
      color: '#0096FF',
      height: '40px',
      width: '40px',
      padding: '8px',
      position: 'absolute',
      left: '-44px',
    }}
    className={className}
    aria-label='Next'
    sx={{ '&:before': { display: 'none' } }}
  >
    <ArrowBackIosNew />
  </IconButton>
);

const NextButton = ({ className, style, onClick }) => (
  <IconButton
    onClick={onClick}
    style={{
      ...style,
      color: '#0096FF',
      height: '40px',
      width: '40px',
      padding: '8px',
      position: 'absolute',
      right: '-44px',
    }}
    className={className}
    aria-label='Next'
    sx={{ '&:before': { display: 'none' } }}
  >
    <ArrowForwardIos />
  </IconButton>
);

const defaultSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  swipeToSlide: true,
  adaptiveHeight: true,
  className: 'sliderItemDiv',
  nextArrow: <NextButton />,
  prevArrow: <PreviousButton />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
      },
    },
  ],
};

// requires slides to be passed as children
// this has some default settings which can be overwritten by passing a settings object in props
const CustomSlider = ({ settings, children }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledSlider {...defaultSettings} {...settings}>
    {children}
  </StyledSlider>
);

export { CustomSlider };
