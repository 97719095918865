import React from 'react';
import { PillMenu } from '../../../../../components/PillMenu/Success';

import { ReactComponent as GoogleAdsMenu } from '../../../../../assests/images/logos/GoogleAdsLogo.svg';
import { ReactComponent as MetaMenu } from '../../../../../assests/images/logos/MetaIconLogo.svg';
import { ReactComponent as GoogleAnalyticsLogoMenu } from '../../../../../assests/images/logos/GoogleAnalyticsLogoMenu.svg';

const getIcon = (platformName) => {
  switch (true) {
    case platformName.toLowerCase().includes('meta') ||
      platformName.toLowerCase().includes('face'):
      return MetaMenu;
    case platformName.toLowerCase().includes('analytics'):
      return GoogleAnalyticsLogoMenu;
    default:
      return GoogleAdsMenu;
  }
};
const optionCreator = (rawOption) => ({
  ...rawOption,
  icon: getIcon(rawOption.platform),
});

const AccountSelectorDemo = ({
  accountList,
  handleChange,
  selectedAccount,
}) => (
  <PillMenu
    name='accountMenu'
    options={accountList.map((acc) => optionCreator(acc))}
    selected={selectedAccount ? optionCreator(selectedAccount) : null}
    handleChange={handleChange}
    sx={{ width: '100%' }}
    defaultText='Select an Account'
  />
);

export { AccountSelectorDemo };
