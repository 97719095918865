const initialValues = {
  name: '',
  description: '',
  queries: [
    {
      field: 'all',
      value: { type: 'field', value: '' },
      filter: '',
      children: [],
    },
  ],
  copy_filter: false,
};

export { initialValues };
