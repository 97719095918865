import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const SliderImage = styled('img')`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const StyledFigure = styled('figure')`
  height: 75%;
  width: 100%;
  position: relative;
`;

const StyledFigCaption = styled('figcaption')`
  position: absolute;
  bottom: 0px;
  text-align: center;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
`;

const ClampedText = styled(({ className, children }) => (
  <Typography variant='smallMedium' color='#fff' className={className}>
    {children}{' '}
  </Typography>
))`
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  display: block;
  padding: 4px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export { SliderImage, StyledFigure, StyledFigCaption, ClampedText };
