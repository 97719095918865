import * as React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

const FetchingDataLoader = ({ message, height, isfilterTrue }) => (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    sx={{
      height: { height },
      width: '100%',
      backdropFilter: isfilterTrue ? 'brightness(0.5)' : 'none',
     
    }}
  >
    <CircularProgress color='primary' sx={{ mr: 2 }} />
  <Typography variant='bodySemiBold'>{message}</Typography>
  </Box>
);

export { FetchingDataLoader };

// for full screen it should be
// <FetchingDataLoader
//  message='Fetching your accounts...'
//  height='100vh'
//  isfilterTrue={true}
//  />

//   for Perticular component it should be
//   <FetchingDataLoader
//     message='Fetching your accounts...'
//     height='100px'
//     isfilterTrue={false}
//   />
