/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import { authInstance } from '../api/authentication.instance';
import { getAccessToken, setAccessToken } from './token';
import { useRefreshToken } from './useRefreshToken';
import { removeUserFromState } from './userInfoDispatchFunctions';

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

// this will call all the response handlers which will refetch the requests and
// resolve their original promises
const onAccessTokenFetched = (accessToken) => {
  subscribers.forEach((callback) => {
    try {
      return callback(accessToken);
    } catch (error) {
      return null;
    }
  });
  subscribers = [];
};
// hook to attack request and response interceptors to authInstance
const useAuthAxios = (dispatch) => {
  const refresh = useRefreshToken();
  subscribers = [];
  const requestIntercept = authInstance.interceptors.request.use(
    (config) => {
      // add authorization header only if it is not already present
      // a req with correct authorization header accessToken will thus pass
      // a req with no header will get a header
      // a req qith expired header accessToken will pass and eventually fail and will be handeled in the responseIntercept method
      // and will be refied with the updated accessToken in the header
      if (!config.headers.Authorization) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${getAccessToken()}`;
      }
      return config;
    },
    () => {}
  );

  // handle silent refresh
  // fetches and sets new accessToken if a 401 'forbidden' error is encountered
  // also re-fires the failed request with updated authorization header
  const responseIntercept = authInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      const statusCode = error.response?.status;
      // create promise and add its' resolve handler to subscriber array
      const retryOriginalRequest = new Promise((resolve, reject) => {
        if (statusCode === 401) {
          addSubscriber((accessToken) => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(authInstance(originalRequest));
          });
        } else {
          reject(error);
        }
      });
      if (
        !isAlreadyFetchingAccessToken &&
        statusCode === 401 &&
        !originalRequest.__isRetryRequest
      ) {
        isAlreadyFetchingAccessToken = true;
        originalRequest._retry = true;
        // get and set refreshed access token
        await refresh()
          .then((newAccessToken) => {
            setAccessToken(newAccessToken);
            // originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            // refire the failed request with the refreshed accesstoken
            // return authInstance(originalRequest);
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(getAccessToken());
          })
          .catch(() => {
            isAlreadyFetchingAccessToken = false;
            removeUserFromState(dispatch);
          });
      }

      if (statusCode === 401) {
        // return promise. will show up as pending
        return retryOriginalRequest;
      }
      return Promise.reject(error);
    }
  );
  useEffect(
    () => () => {
      authInstance.interceptors.request.eject(requestIntercept);
      authInstance.interceptors.response.eject(responseIntercept);
    },
    [refresh, requestIntercept, responseIntercept]
  );

  return null;
};

export { useAuthAxios };
