import React, { useContext } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { Add, DeleteOutline } from '@mui/icons-material';
import { useField } from 'formik';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';

const childSelectorObject = {
  field: 'all',
  value: '',
  filter: '',
};

const selectorObject = {
  field: 'all',
  value: '',
  filter: '',
  children: [],
};

const SelectorHelpers = ({
  insert,
  insertChild,
  index,
  remove,
  name,
  sx,
  replace,
}) => {
  const [{ value }] = useField(name);
  const { user } = useContext(UserInfoContext);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'end',
        gap: 2,
        gridRow: 2,
        '@media(min-width: 1400px)': {
          gridRow: '1',
          gridColumn: '2',
        },
        ...sx,
      }}
    >
      <Button
        onClick={() =>
          insertChild
            ? insertChild(index + 1, childSelectorObject)
            : replace(index, {
              ...value,
              children: [...value.children, childSelectorObject],
            })
        }
        variant='outlined'
        sx={{
          backgroundColor: '#FFF',
          color: 'text.grey',
          border: '1px solid',
          borderColor: 'border.main',
          height: '45px',
          '&:hover': {
            color: 'primary.main',
          },
        }}
        startIcon={<Add />}
        disabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
      >
        or
      </Button>
      {!insertChild && (
        <Button
          onClick={() => insert(index + 1, selectorObject)}
          startIcon={<Add />}
          variant='outlined'
          sx={{
            backgroundColor: '#FFF',
            color: 'text.grey',
            border: '1px solid',
            borderColor: 'border.main',
            height: '45px',
            '&:hover': {
              color: 'primary.main',
            },
          }}
          disabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
        >
          and
        </Button>
      )}
      {(index > 0 || insertChild) && (
        <IconButton
          onClick={() => remove(index)}
          startIcon={<Add />}
          sx={{
            backgroundColor: '#FFF',
            color: 'text.grey',
            border: '1px solid',
            borderRadius: 1,
            borderColor: 'border.main',
            height: '45px',
            '&:hover': {
              color: 'primary.main',
            },
          }}
        >
          <DeleteOutline />
        </IconButton>
      )}
    </Box>
  );
};
export { SelectorHelpers };
