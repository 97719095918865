import React from 'react';
import { Link } from 'react-router-dom';

// mui imports
import { Chip } from '@mui/material';

// styled component import
import {
  StyledAffectedItemsButton,
  StyledLoadingCircle,
} from '../StyledComponents/StyledCommon';

// main function component
const AffectedItemsButton = ({
  combinedClassName,
  to,
  label,
  buttonName,
  sx,
  disabled,
  onClick,
  affecttedItemLoading,
  className
}) => (
  <StyledAffectedItemsButton
    variant='pillOutlined'
    // className={`affectedItemsButton ${combinedClassName}`}
    className={`affectedItemsButton ${affecttedItemLoading ? '' : className} ${combinedClassName}`}
    component={Link}
    onClick={onClick}
    to={to}
    state={to.state}
    sx={sx}
    disabled={disabled}
  >
    {!affecttedItemLoading ? (
      <>
      <Chip label={label} className='numberChip' sx={sx} />
        
        {buttonName}
      </>
    ) : (
      <>
      <StyledLoadingCircle />
        {buttonName}
      </>
    )}
  </StyledAffectedItemsButton>
);

export { AffectedItemsButton };
