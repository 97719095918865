import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companyData : ''
};

const companyDataRedux = createSlice({
    name: 'companyData',
    initialState,
    reducers: {
        updateCompany: (state, action) =>{
            // eslint-disable-next-line no-param-reassign
            state.companyData = action.payload;
        }
    }
})

export const { updateCompany } = companyDataRedux.actions;

// eslint-disable-next-line import/no-default-export
export default companyDataRedux.reducer;