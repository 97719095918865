import React from 'react';
import { Box, Button, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import ReactPhoneInput from 'react-phone-input-2';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import 'react-phone-input-2/lib/style.css';
import { EditProfileSchema } from '../Schemas';
import { FormikField } from './Formik';
import { ScrollToError } from './utils/ErrorScroll';
import { StyledInputLabel } from '../../../widgets/FormikForm/StyledFormikField';
import { HeadingWithUnderline } from '../../../widgets/HeadingWithUnderline/HeadingWithUnderline';

const EditProfileForm = ({ formData, handleSubmit, formikRef }) => (
  <Formik
    innerRef={formikRef}
    initialValues={{
      full_name: `${formData?.first_name} ${formData?.last_name}` || '',
      username: formData?.username || '',
      email: formData?.email || '',
      phone_number: formData?.phone_number || '',
    }}
    enableReinitialize
    onSubmit={handleSubmit}
    validationSchema={EditProfileSchema}
  >
    {({ values, dirty, errors, touched, handleChange, handleBlur }) => (
      <Form>
        <HeadingWithUnderline
          heading='Profile'
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.border.main}`,
          }}
        />
        <Box display='flex' justifyContent='center' alignItems='center'>
          <Box
            sx={{
              width: '100px',
              height: '100px',
              border: '1px solid #fff',
              borderRadius: '100%',
              m: 1,
            }}
          >
            <AccountCircleRoundedIcon
              sx={{
                width: '100%',
                height: '100%',
                color: (theme) => theme.palette.svg.grey,
              }}
            />
          </Box>
        </Box>
        <Box sx={{ pb: 2 }}>
          <FormikField
            name='full_name'
            label='Full Name'
            required
            type='text'
            placeholder='Enter Full Name'
            value={values.full_name}
            error={Boolean(errors.full_name) && touched.full_name}
            helperText={touched.full_name && errors.full_name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box sx={{ pb: 2 }}>
          <FormikField
            name='username'
            label='User Name'
            required
            type='text'
            placeholder='Enter Username'
            value={values.username}
            error={Boolean(errors.username) && touched.username}
            helperText={touched.username && errors.username}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box sx={{ pb: 2 }}>
          <Box
            sx={{
              width: '100%',
              '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: (theme) => theme.palette.border.main,
                cursor: 'none',
              },
            }}
          >
            <FormikField
              name='email'
              label='Email ID'
              required
              type='email'
              placeholder='Enter Email ID'
              value={values.email}
              error={Boolean(errors.email) && touched.email}
              helperText={touched.email && errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly
              disabled
            />
          </Box>
        </Box>
        <Box sx={{ pb: 2 }}>
          <StyledInputLabel htmlFor='phone_number'>
            <Typography variant='h5' sx={{ mb: 0.5, textAlign: 'start' }}>
              Mobile
            </Typography>
          </StyledInputLabel>
          <ReactPhoneInput
            name='phone_number'
            id='phone_number'
            country='us'
            value={values.phone_number}
            countryCodeEditable
            autoFormat
            inputStyle={{
              width: '100%',
              height: '38px',
              borderColor:
                touched.phone_number && errors.phone_number
                  ? '#E13F2A'
                  : '#DEDEDE',
            }}
            buttonStyle={{
              borderColor:
                touched.phone_number && errors.phone_number
                  ? '#E13F2A'
                  : '#DEDEDE',
            }}
            inputProps={{
              required: true,
              placeholder: 'Enter phone number',
              name: 'phone_number',
              id: 'phone_number',
            }}
            onBlur={handleBlur}
            onChange={(phoneNumber, country, e) => {
              handleChange(e);
            }}
          />
          {touched.phone_number && errors.phone_number ? (
            <Typography color='error' variant='smallerRegular'>
              {errors.phone_number}
            </Typography>
          ) : null}
        </Box>
        <ScrollToError />
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          gap={2}
          sx={{ margin: '12px 0px 12px 0px' }}
        >
          <Button variant='outlined' fullWidth type='reset'>
            Cancel
          </Button>
          <Button variant='contained' type='submit' disabled={!dirty} fullWidth>
            Save
          </Button>
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center'>
          <MuiLink
            component={Link}
            to='/forgot-password'
            underline='hover'
            sx={{ cursor: 'pointer' }}
          >
            <Typography variant='smallBold'>Reset Password</Typography>
          </MuiLink>
        </Box>
      </Form>
    )}
  </Formik>
);

export { EditProfileForm };
