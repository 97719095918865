/* eslint-disable no-unused-vars */
import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { SelectInput } from '../../../../../../../components/Inputs/SelectInput';

const methodOptions = [
  { value: '', name: 'select a method' },
  { subheader: 'Text' },
  { value: 'CONTAINS', name: 'contains' },
  { value: 'DOES_NOT_CONTAINS', name: 'does not contain' },
  { value: 'IS_EQUAL_TO', name: 'is equal to' },
  { value: 'IS_NOT_EQUAL_TO', name: 'is not equal to' },
  { value: 'STARTS_WITH', name: 'starts with' },
  { value: 'DOES_NOT_STARTS_WITH', name: 'does not start with' },
  { value: 'ENDS_WITH', name: 'ends with' },
  { value: 'DOES_NOT_ENDS_WITH', name: 'does not end with' },
  { value: 'IS_EMPTY', name: 'is empty' },
  { value: 'IS_NOT_EMPTY', name: 'is not empty' },
  { value: 'LENGTH_EXCEEDS', name: 'length exceeds' },
  { value: 'LENGTH_DOES_NOT_EXCEEDS', name: 'length does not exceed' },
  { value: 'WORD_COUNT_EXCEEDS', name: 'word count exceeds' },
  {
    value: 'WORD_COUNT_DOES_NOT_EXCEEDS',
    name: '   word count does not exceed',
  },
  { subheader: 'Multiple' },
  { value: 'CONTAINS_ANY_OF', name: 'contains any of' },
  { value: 'DOES_NOT_CONTAINS_ANY_OF', name: 'does not contain any of' },
  { value: 'IS_EQUAL_TO_ANY', name: 'is equal to any' },
  { value: 'IS_NOT_EQUAL_TO_ANY', name: 'is not equal to any' },
  { subheader: 'Number' },
  { value: 'IS_GREATER_THAN', name: 'is greater than' },
  { value: 'IS_GREATER_OR_EQUAL_TO', name: 'is greater or equal to' },
  { value: 'IS_LESS_THAN', name: 'is less than' },
  { value: 'IS_LESS_OR_EQUAL_TO', name: 'is less or equal to' },
];

const MethodSelector = ({ name, valuePath, fieldName }) => {
  
  const { setFieldValue } = useFormikContext();
  const [field] = useField(fieldName);
 
  useEffect(() => {
    if (field.value === 'all') {
      setFieldValue(name, '');
    }
  }, [field.value]);

  if (field.value !== 'all') {
    return (
      <SelectInput
        name={name}
        placeholder='Select a method'
        optionContentCallback={(option) => option.name}
        optionValueCallback={(option) => option.value}
        options={methodOptions}
        label='method'
        hideLabel
        id={name}
        customOnChange={() => {
          // reset the 'value' on method change
          setFieldValue(valuePath, {
            type: 'static',
            value: '',
            caseSensitive: false,
          });
        }}
        sx={{
          width: '100%',
          '@media(min-width:800px)': {
            width: 'auto',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              flex: 'initial',
              width: '300px',
              maxWidth: '100%',
            },
          },
        }}
      />
    );
  }
  return null;
};

export { MethodSelector };
