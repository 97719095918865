import React, { useEffect, useRef, useState } from 'react';

import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import { Box } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
// data: the standard chartjs object
// this chart displays percentages in the legend instead of values
const DoughnutChart = ({ data, currencyCode, isCurrency, metricName }) => {
  const [isMobile, setIsMobile] = useState(false);
  const roundTo =
    metricName.toLowerCase() === 'roas' || metricName.toLowerCase() === 'cpc'
      ? 1
      : 0;

  const chartRef = useRef(null);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    radius: '80%',
    cutout: '75%',
    plugins: {
      tooltip: {
        backgroundColor: 'rgba(255,255,255,1)',
        titleColor: '#000',
        bodyColor: '#000',
        borderColor: 'rgba(222, 222, 222, 0.8)',
        borderWidth: 1,
        bodySpacing: 8,
        titleFont: {
          size: 12,
          weight: '600',
          family: 'Montserrat',
        },
        bodyFont: {
          size: 12,
          weight: '600',
          family: 'Montserrat',
        },
        callbacks: {
          title(context) {
            return context[0].label;
          },
          label(context) {
            if (isCurrency) {
              return `${context.dataset.label}: ${currencyCode} ${context.formattedValue}`;
            }
            return `${context.dataset.label}: ${_.round(context.raw, roundTo)}`;
          },
        },
      },
      legend: {
        display: true,
        position: isMobile ? 'top' : 'right',
        align: 'center',
        labels: {
          boxWidth: isMobile ? 40 : 45,
          boxHeight: isMobile ? 20 : 23,
          padding: isMobile ? 24 : 20,
          generateLabels(chart) {
            const { data: chartData } = chart;
            const meta = chart.getDatasetMeta(0);
            const style = meta.controller.getStyle(0);
            if (chartData.labels.length && chartData.datasets.length) {
              const {
                labels: { pointStyle },
              } = chart.legend.options;
              const total = _.sum(chartData.datasets[0].data);
              return [
                ...chartData.datasets[0].data.map((value, i) => ({
                  text: `${_.round((value * 100) / total, 1)}% ${
                    chartData.labels[i]
                  }`,
                  fillStyle: chartData.datasets[0].backgroundColor[i],
                  strokeStyle: chartData.datasets[0].backgroundColor[i],
                  lineWidth: style.borderWidth,
                  pointStyle,
                  hidden: !chart.getDataVisibility(i),
                  borderRadius: 4,
                  index: i,
                })),
              ];
            }
            return [];
          },
          font: {
            size: isMobile ? 12 : 14,
            weight: '600',
            family: 'Montserrat',
          },
          color: '#323C46',
        },
      },
      label: {
        display: false,
      },
      datalabels: {
        color: 'transparent',
      },
    },
  };
  const isMounted = useRef(true);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (chartRef?.current?.clientWidth < 500) {
        if (isMounted.current) {
          setIsMobile(true);
        }
      } else if (isMounted.current) {
        setIsMobile(false);
      }
    });

    resizeObserver.observe(chartRef.current);

    return () => {
      isMounted.current = false;
      if (chartRef.current) {
        resizeObserver.unobserve(chartRef.current);
      }
    };
  }, []);

  return (
    <Box ref={chartRef} height='100%'>
      <Doughnut options={options} data={data} />
    </Box>
  );
};

export { DoughnutChart };
