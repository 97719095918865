import React, { useEffect, useState } from 'react';

import { styled } from '@mui/system';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';

import { AdhelpLogo } from '../../constants/endpoints/assests/logos';

const HeaderNavContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'fluid',
})({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  height: '4em',
  justifyContent: 'space-between',
});

const HeaderLogo = styled('img')({
  height: '2.25rem',
  order: 1,
  '@media(min-width:996px)': {
    order: 0,
  },
});

const AdhelpNavLogo = () => {
  const [colorChange, setColorChange] = useState('transparent');
  const [shadowChange, setShadowChange] = useState('0px 0px 0px 0px #000');

  const changeNavbarColor = () => {
    if (window.scrollY >= 40) {
      setColorChange('#fff');
      setShadowChange('0px 2px 4px rgba(0, 0, 0, 0.25)');
    } else {
      setColorChange('transparent');
      setShadowChange('0px 0px 0px 0px #000');
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', changeNavbarColor);
    return () => {
      window.removeEventListener('scroll', changeNavbarColor);
    };
  }, [changeNavbarColor]);

  return (
    <HeaderNavContainer
      fluid
      maxWidth={false}
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 99999,
        boxShadow: shadowChange,
        backgroundColor: colorChange,
      }}
    >
      <Link to='/signin'>
        <HeaderLogo src={AdhelpLogo} alt='adhelp logo' />
      </Link>
    </HeaderNavContainer>
  );
};
export { AdhelpNavLogo };
