// react imports
import { Link } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

// styled imports
import { LinkChild, LinkText } from './styles';

const HeaderLink = ({ text, to }) => (
  <li>
    <Link component={NavLink} to={to} underline='none' color='inherit'>
      <LinkChild>
        <LinkText variant='a'>{text}</LinkText>
      </LinkChild>
    </Link>
  </li>
);

export { HeaderLink };

