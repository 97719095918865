import React, { useState } from 'react';

// MUI imports
import { Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// Internal imports
import { FeedListProjectModal } from './FeedListProjectModal';

const getModalProps = (functionName) => {
  switch (functionName) {
    case 'delete':
      return {
        successMessage: 'Feed Deleted',
        errorMessage: 'Feed Not Deleted',
        functionName: 'delete',
        // prompt: 'Are you sure you want to Delete?',
        confirmButtonText: 'Delete',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      };
    case 'activate':
      return {
        successMessage: 'Feed Activated',
        errorMessage: 'Feed Not Activated',
        functionName: 'activate',
        confirmButtonText: 'Activate',
        showCancelButton: false,
      };
    case 'deactivate':
      return {
        successMessage: 'Feed Deactivated',
        errorMessage: 'Feed Not Deactivated',
        functionName: 'deactivate',
        confirmButtonText: 'Deactivate',
        showCancelButton: false,
      };
    default:
      return null;
  }
};

const FeedListFileMenu = ({ name, id, reloadFunction, active }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [ConfirmModal, setConfirmModal] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const OnClickSubmitModal = (functionName) => {
    setModalProps(getModalProps(functionName));
    setAnchorEl(null);
    setConfirmModal(true);
    setOpen(false);
  };

  const handleCloseModal = () => {
    setConfirmModal(false);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        sx={{
          border: '1px solid #dedede',
          borderRadius: '4px',
          padding: '1px',
          maxHeight: '30px',
        }}
      >
        <MoreVertIcon
          sx={{
            '&:hover': { color: (theme) => theme.palette.primary.main },
          }}
        />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          // elevation: 0,
          style: {
            overflow: 'visible',
            boxShadow: '0px 2px 5px #dedede',
          },
        }}
      >
        {active === false ? (
          <MenuItem
            onClick={() => OnClickSubmitModal('activate')}
            key='activate'
          >
            <CheckCircleOutlinedIcon fontSize='small' sx={{ color: '#999999', paddingRight: '4px' }} />
            <Typography variant='smallRegular'> Activate </Typography>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => OnClickSubmitModal('deactivate')}
            key='deactivate'
          >
            <HighlightOffOutlinedIcon fontSize='small' sx={{ color: '#999999', paddingRight: '4px' }} />
            <Typography variant='smallRegular'> Deactivate </Typography>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={() => OnClickSubmitModal('delete')}>
          <DeleteOutlineIcon fontSize='small' sx={{ color: '#999999', paddingRight: '4px' }} />
          <Typography variant='smallRegular'> Delete </Typography>
        </MenuItem>
      </Menu>
      <FeedListProjectModal
        open={ConfirmModal}
        handleCloseModal={handleCloseModal}
        name={name}
        id={id}
        active={active}
        reloadFunction={reloadFunction}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...modalProps}
      />
    </>
  );
};

export { FeedListFileMenu };
