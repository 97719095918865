/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const allProjectsRedux = createSlice({
  name: 'PaginationVlues',
  initialState: {
    EnhacepageNumber: 0,
    previewpageNumber: 0,
    allItempageNumber: 0,
    searchTesrm : "",
    searchTermEnahnce : "",
    searchTermAllItems : "",
    searchTermAffectedItems:""
  },
  reducers: {
    setAllItemsPageNumber(state , action) {
      state.allItempageNumber = action.payload
    },
    setEnhancePageNumber(state, action) {
      state.EnhacepageNumber = action.payload;
    },
    setPreviewPageNumber(state, action) {
        state.previewpageNumber = action.payload;
    },
    clearEnhancepageNumber: (state) => {
      state.EnhacepageNumber = 0;
    },
    clearPreviewpageNumber: (state) => {
        state.previewpageNumber = 0;
    },
    clearAllItemsPageNumber : (state , action) => {
     state.allItempageNumber = action.payload
    },
    setSearchTerms : (state , action) => { 
     state.searchTesrm = action.payload
    },
    setSearchTermEnahnce :(state , action) =>{
     state.searchTermEnahnce = action.payload
    },
    setSearchTermAllItems : (state , action) => {
      state.searchTermAllItems = action.payload
    },
    setSearchTermAffectedItems : (state , action) => {
      state.searchTermAffectedItems = action.payload
    },
    clearReduxStore: () => ({
        EnhacepageNumber: 0,
        previewpageNumber: 0,
        allItempageNumber: 0,

    
    }),
  },
});

export const {
  setAllItemsPageNumber,
  setEnhancePageNumber,
  setPreviewPageNumber,
  clearEnhancepageNumber,
  clearPreviewpageNumber,
  clearAllItemsPageNumber,
  setSearchTerms,
  setSearchTermAffectedItems,
  setSearchTermAllItems,
  setSearchTermEnahnce,
  clearReduxStore,
} = allProjectsRedux.actions;

// eslint-disable-next-line import/no-default-export
export default allProjectsRedux.reducer;