import React from 'react';
import ReactJoyride, { EVENTS, STATUS, ACTIONS } from 'react-joyride';

const steps = [
    {
        title: 'Click on dropdown',
        content:
            'to map this mandatory field',
        target: '.classNameInternalField',
        placement: 'auto',
        disableBeacon: true,
        // disableScrolling: false,
    },
    {
        title: '"Hover" on tooltip',
        content:
            'to see the tips on how to solve the error to increase Feed Quality',
        target: '.tooltipClass',
        placement: 'auto',
        disableBeacon: true,
        // disableScrolling: false,
    },
    {
        title: 'Quality Score',
        content:
            'to see the quality of the Feed you will be adding',
        target: '.qualityScoreClass',
        placement: 'auto',
        disableBeacon: true,
        // disableScrolling: false,
    },
    {
        title: 'Click the Start for Free Now button on the right to create a free account',
        content:
            'If you need an AdHelp.io expert to assist you, write to us at support@adhelp.io',
        target: '.startNowQuality',
        placement: 'auto',
        disableBeacon: true,
        disableScrolling: false,
    },
];

const Joyride = (props) => {
    const { setRun, run, stepIndexState, setStepIndexState } = props;

    const handleJoyrideCallback = (data) => {
        const { status, type, action } = data;

        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED, ACTIONS.CLOSE];

        if (finishedStatuses.includes(status) || action === 'close') {
            setRun(false);
        } else if (
            data.index === stepIndexState &&
            (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND)
        ) {
            setStepIndexState((prev) => prev + (action === ACTIONS.PREV ? -1 : 1));
        }
    };

    return (
        <ReactJoyride
            run={run}
            steps={steps}
            stepIndex={stepIndexState}
            disableBeacon
            scrollHeight={false}
            continuous
            scrollOffset={80}
            scrollToFirstStep
            spotlightClicks={false}
            showProgress
            callback={handleJoyrideCallback}
            styles={{
                options: {
                    zIndex: 10000000,
                    primaryColor: '#0096ff',
                },
                tooltipContainer: {
                    textAlign: 'left',
                    paddingTop: '15px',
                },
                tooltipContent: {
                    paddingLeft: '0px',
                },
            }}
            locale={{
                last: 'Done',
            }}
        />
    );
};
export { Joyride };