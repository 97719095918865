import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { PERSIST, REGISTER, persistReducer, persistStore, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { AllProjectReducer, clearReduxStore } from './AllProjects';
import { ImportReducer } from './ImportForm';
import { editMappingReducer } from './EditMapping';
import { importedFilesReducer } from './ImportedFiles';
import { FeedReducer } from './FeedForm';
import { feedSummaryReducer } from './FeedSummary';
import { ScheduleReducer } from './ScheduleForm';
import { metricSliceRedux } from './CRUD';
import { accountSliceRedux } from './AccountToggler';
import { RuleFormulaReducer } from './RuleFormulaToggler';
import { FieldTypeReducer } from './FieldIdentifire';
import { CustomizedColumnSliceReducer } from './CustomizedColumn';
import { actionSlice, editFilterSlice } from './AddActionFilter';
import { PaginationValuesReducer } from './PaginationVales';
import { UsersListRedux } from './Users';
import { AllItemCustomizedColumnSlice } from './CustomizableHeadcells/All-Items';
import { downloadNotificationReducer } from './DownloadNotification';
import { companyDataReducer } from './CompanyData';

import { api } from '../../../api/RTK/api';

const reducers = combineReducers({
  importFormRedux: ImportReducer,
  allProjectsRedux: AllProjectReducer,
  editMappingRedux: editMappingReducer,
  importedFilesRedux: importedFilesReducer,
  feedFormRedux: FeedReducer,
  feedSummaryRedux: feedSummaryReducer,
  scheduleFormRedux: ScheduleReducer,
  metricReducerRedux: metricSliceRedux,
  RuleFormulaRedux: RuleFormulaReducer,
  accountReducerRedux: accountSliceRedux,
  UsersListReducer: UsersListRedux,
  fieldTypeRedux: FieldTypeReducer,
  customizedColumnRedux: CustomizedColumnSliceReducer,
  ActionFilterData: actionSlice,
  EditFilteredData: editFilterSlice,
  PaginationValuesReducers: PaginationValuesReducer,
  allItemCustomizedColumnSlice: AllItemCustomizedColumnSlice,
  downloadNotification: downloadNotificationReducer,
  companyData: companyDataReducer,
  [api.reducerPath]: api.reducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [
    'importFormRedux',
    'allProjectsRedux',
    'importedFilesRedux',
    'editMappingRedux',
    'fileType',
    'name',
    'fileURL',
    'weburl',
    'url',
    'encode',
    'quote',
    'delimit',
    'username',
    'password',
    'feedName',
    'selectOnly',
    'fileId',
    'projectName',
    'feeds',
    'files',
    'fileOrder',
    'feedSummaryRedux',
    'feedId',
    'scheduleFormRedux',
    'secondSelect',
    'channelName',
    'feedChannel',
    'allItemCustomizedColumnSlice',
    'companyData',
  ],
};

// know how much local storage memory is used
// function getLocalStorageUsage() {
//   let totalBytes = 0;
//   const value = localStorage.getItem('persist:root');
//   totalBytes += new Blob([value]).size;
//   const totalKB = totalBytes / 1024;
//   const totalMB = totalKB / 1024;
//   const limitBytes = 5 * 1024 * 1024; // 5 MB limit
//   const usedPercentage = (totalBytes / limitBytes) * 100;
//   console.log(
//     `Total localStorage usage: ${totalBytes} bytes, ${totalKB.toFixed(
//       2
//     )} KB, ${totalMB.toFixed(2)} MB`
//   );
//   console.log(`Used percentage of limit: ${usedPercentage.toFixed(2)}%`);
// }
// getLocalStorageUsage();

const persistedReducer = persistReducer(persistConfig, reducers); 

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST, REGISTER, REHYDRATE],
      },
    })
      .concat(api.middleware)
      .concat(thunk), 
});

const persistor = persistStore(store);
setupListeners(store.dispatch);

const clearStore = () => {
  store.dispatch(clearReduxStore());
};

export { store, persistor, clearStore };
