import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import _ from 'lodash';
import { StyledLine } from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
// data: standard chartjs dataset object
// scale: string. To be displayed on the y-axis
const LineGraph = ({
  data,
  scale,
  currencyCode,
  showCurrencyCode,
  metricName,
}) => {
  const roundTo =
    metricName.toLowerCase() === 'roas' || metricName.toLowerCase() === 'cpc'
      ? 1
      : 0;
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    elements: {
      point: {
        radius: 3,
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxWidth: 45,
          boxHeight: 23,
          useBorderRadius: true,
          borderRadius: 6,
          font: {
            size: 14,
            weight: '600',
            family: 'Montserrat',
          },
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        titleFont: {
          size: 12,
          weight: '600',
          family: 'Montserrat',
        },
        bodyFont: {
          size: 12,
          weight: '600',
          family: 'Montserrat',
        },
        callbacks: {
          label(context) {
            if (showCurrencyCode) {
              return ` ${context.dataset.label}: ${currencyCode} ${_.round(
                context.raw
              ).toLocaleString()}`;
            }
            return ` ${context.dataset.label}: ${_.round(
              context.raw,
              roundTo
            ).toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#323C46',
          font: {
            size: 14,
            family: 'Montserrat',
            weight: '600',
          },
        },
        title: {
          display: true,
          text: 'Months',
          color: '#323C46',
          font: {
            size: 14,
            family: 'Montserrat',
            weight: '600',
          },
        },
      },
      y: {
        ticks: {
          color: '#323C46',
          font: {
            size: 14,
            family: 'Montserrat',
            weight: '600',
          },
          callback(value) {
            if (value > 1000) {
              return `${value / 1000}k`;
            }
            return value;
          },
        },
        title: {
          display: true,
          text: `${scale}`,
          color: '#323C46',
          font: {
            size: 14,
            family: 'Montserrat',
            weight: '600',
          },
        },
      },
    },
  };
  return <StyledLine options={options} data={data} />;
};

export { LineGraph };
