// react imports
import React, { useContext } from 'react';
// third party imports
import { Button } from '@mui/material';
import { PillMenu } from '../PillMenu/Success';
import { ReactComponent as GoogleAdsMenu } from '../../assests/images/logos/GoogleAdsLogo.svg';
import { ReactComponent as MetaMenu } from '../../assests/images/logos/MetaIconLogo.svg';
import { ReactComponent as GoogleAnalyticsLogoMenu } from '../../assests/images/logos/GoogleAnalyticsLogoMenu.svg';
import { UserInfoContext } from '../../context/UserInfoContext';
import { UserInfoDispatchContext } from '../../context/UserInfoDispatchContext';
import { setAccount } from '../../utils/userInfoDispatchFunctions';

/*
filterFunction: function. () => Boolean. This function is used to filter accounts from the accountlist in UserInfoContext 
noAccountMessage: String. Text displayed when filteredAccountList is emppty
*/

const getIcon = (platformName) => {
  switch (true) {
    case platformName.toLowerCase().includes('meta') ||
      platformName.toLowerCase().includes('face'):
      return MetaMenu;
    case platformName.toLowerCase().includes('analytics'):
      return GoogleAnalyticsLogoMenu;
    default:
      return GoogleAdsMenu;
  }
};

const AccountSelector = ({
  filterFunction = () => true,
  noAccountsMessage = '0 Accounts Found',
  sx,
}) => {
  const { company, accountList, account, accountListFetchingStatus } =
    useContext(UserInfoContext);
  const dispatch = useContext(UserInfoDispatchContext);

  // format account objects to pill menu consumable options
  const optionCreator = (rawOption) => ({
    ...rawOption,
    icon: getIcon(rawOption.platform),
  });
  if (accountListFetchingStatus === 'PREFETCH') {
    return (
      <Button
        variant='pill'
        disabled
        sx={{ justifyContent: 'start', width: '100%', ...sx }}
      >
        Select an account
      </Button>
    );
  }

  if (accountListFetchingStatus === 'FETCHING') {
    return (
      <Button
        variant='pill'
        disabled
        sx={{ justifyContent: 'start', width: '100%', ...sx }}
      >
        Fetching Accounts
      </Button>
    );
  }

  if (accountListFetchingStatus === 'ERROR') {
    return (
      <Button
        variant='pill'
        disabled
        sx={{ justifyContent: 'start', width: '100%', ...sx }}
      >
        Error while fetching accounts
      </Button>
    );
  }

  if (
    accountListFetchingStatus === 'SUCCESS' &&
    accountList &&
    accountList?.filter(filterFunction).length === 0
  ) {
    return (
      <Button
        variant='pill'
        disabled
        sx={{ justifyContent: 'start', width: '100%', ...sx }}
      >
        {noAccountsMessage}
      </Button>
    );
  }

  return (
    <PillMenu
      name='accountMenu'
      options={
        accountList
          ? accountList?.filter(filterFunction).map((d) => optionCreator(d))
          : []
      }
      selected={account ? optionCreator(account) : null}
      handleChange={(newAccount) => setAccount(dispatch, newAccount)}
      sx={{ width: '100%', ...sx }}
      defaultText='Select an Account'
      showPulse={company && !account}
    />
  );
};

export { AccountSelector };
