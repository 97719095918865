import React, { useRef, useEffect, useState, useContext } from 'react';
import { Field, FieldArray, useField } from 'formik';
import {
  Box,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import _ from 'lodash';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';

const SelectField = ({ name, options, handleRemove, disabled }) => (
  <Field
    id={`${name}-select`}
    as={Select}
    name={name}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: '300px',
        },
      },
    }}
    disabled={disabled}
    sx={{
      minWidth: 0,
      paddingRight: 0,
      '& .MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
        padding: '4px 34px 4px 16px',
      },
      '& .MuiSelect-icon.MuiSelect-iconOutlined': {
        right: '2rem',
      },
    }}
    endAdornment={
      <IconButton onClick={handleRemove} sx={{ padding: '0px 8px' }}>
        <Delete fontSize='small' />
      </IconButton>
    }
    displayEmpty
  >
    {options.map((option) => (
      <MenuItem value={option}>{option}</MenuItem>
    ))}
  </Field>
);

const SmallTextField = ({ name, isLast, showPlaceholder, disabled }) => {
  const [{ value }] = useField(name);
  const fieldRef = useRef();
  const [width, setWidth] = useState(false);
  useEffect(() => {
    if (fieldRef) {
      setWidth(
        fieldRef?.current?.offsetWidth > 10
          ? // adding these 3 pixels allows focusing the input
          // without these extra pixels the inputted text takes up all the width of the input box
          // and you cant focus because you can only focus by clicking at the empty space at the end of the inputted string inside the input box

          fieldRef.current.offsetWidth + 3
          : 10
      );
      // triggers re-render once after the first render when the ref is  which allows the input field to access the width of
    }
  }, [fieldRef, value]);
  return (
    <>
      <Field
        as={OutlinedInput}
        name={name}
        size='small'
        sx={{
          flex: isLast && 1,
          // makes sure the width is equal to the content typed inside the textbox
          width: `${width}px`,
          '& .MuiInputBase-input.MuiOutlinedInput-input': {
            padding: '0px 0px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          justifyContent: 'center',
        }}
        disabled={disabled}
        placeholder={showPlaceholder && 'Enter or Select'}
      />
      <Box
        component='span'
        sx={{
          position: 'absolute',
          fontSize: '0.875rem',
          opacity: 0,
          fontWeight: 400,
        }}
        ref={fieldRef}
      >
        {value}
      </Box>
    </>
  );
};

const Helper = ({ push, fieldToAdd }) => (
  <IconButton
    sx={{
      position: 'absolute',
      inset: '0px 0px 0px auto',
      borderRadius: 0,
      borderLeft: '1px solid #DEDEDE',
    }}
    onClick={() => {
      // this makes sure every select box has a text input on each side
      push({
        value: fieldToAdd,
        type: 'field',
      });
      push({
        value: '',
        type: 'static',
      });
    }}
  >
    <Add fontSize='small' />
  </IconButton>
);

const CombinedInput = ({ name, label = '', options, sx }) => {
  const [{ value }, meta] = useField(name);
  const { user } = useContext(UserInfoContext);
  // removes field
  const removeField = (index, remove, replace) => {
    // appends the next text input's value into the previous text input
    replace(index - 1, {
      type: 'static',
      value: `${value[index - 1].value}${value[index + 1].value}`,
    });
    // removes next text input
    remove(index + 1);
    // remove field input
    remove(index);
  };
  if (Array.isArray(value)) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
          width: '100%',
          '@media(min-width:800px)': {
            width: 'auto',
          },
          ...sx,
        }}
        error={meta.touched && meta.error}
      >
        {label && (
          <FormLabel
            component='legend'
            sx={{ fontSize: '0.875rem', color: 'text.primary' }}
          >
            {label}
          </FormLabel>
        )}
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            minWidth: '300px',
          }}
        >
          <FormGroup
            sx={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: '#FFF',
              padding: '6px',
              paddingRight: '40px',
              border: '1px solid ',
              borderRadius: '4px',
              position: 'relative',
              gap: '3px',
              width: '100%',
              height: '45px',
              borderColor:
                meta.touched && meta.error ? 'error.main' : '#DEDEDE',
            }}
            display='flex'
          >
            <FieldArray name={name}>
              {({ remove, push, replace }) => (
                <>
                  {value.map((val, index) => {
                    // show either select or text input
                    if (val.type === 'field') {
                      return (
                        <SelectField
                          name={`${name}[${index}].value`}
                          options={options}
                          handleRemove={() =>
                            removeField(index, remove, replace)
                          }
                          disabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
                        />
                      );
                    }
                    return (
                      <SmallTextField
                        name={`${name}[${index}].value`}
                        isLast={index === value.length - 1}
                        showPlaceholder={index === 0 && value.length === 1}
                        disabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
                      />
                    );
                  })}
                  <Helper push={push} fieldToAdd={options[0]} />
                </>
              )}
            </FieldArray>
          </FormGroup>
          {/* 
            when the action is changed the input fields change first and later the validation schema updates
            this means meta.error is stale for a couple renders before the correct fresh value is available
            we check if the value is an array before displaying to prevent an incompatible stale value from breaking the page
          */}
          {meta.touched && meta.error && (
            <FormHelperText>
              {_.isArray(meta.error) &&
                meta.error?.map(
                  (e) =>
                    e && (
                      <>
                        {e.value || e.type}
                        <br />
                      </>
                    )
                )}
            </FormHelperText>
          )}
        </Box>
      </Box>
    );
  }

  return null;
};

export { CombinedInput };
