/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  channelName: '',
  selectOnly: [],
  feedName: '',
  country: [],
}

const feedFormRedux = createSlice({
  name: 'feedFormRedux',
  initialState,
  reducers: {
    updateVal: (state, { payload: { key, val } }) => {
      // eslint-disable-next-line no-param-reassign
      state[key] = val;
    },
    setChannelName(state, action) {
      state.channelName = action.payload;
    },
    clearInputValue: (state) => {
      // eslint-disable-next-line no-param-reassign, no-unused-vars
      state.selectOnly = initialState.selectOnly;
      state.feedName = initialState.feedName;
      state.country = initialState.country;
      state.channelName = initialState.channelName;
    },

  },

});


// eslint-disable-next-line import/group-exports
export const { updateVal, clearInputValue, setChannelName } = feedFormRedux.actions;

// eslint-disable-next-line import/no-default-export
export default feedFormRedux.reducer;
