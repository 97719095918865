import React from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';
import { ConversionFunnelGraph } from '../../../../../components/ConversionFunnel';

const dataFormatter = (data) => ({
  addToCart: _.sumBy(data, 'Add To Cart'),
  initiateCheckout: _.sumBy(data, 'Checkouts Initiated'),
  purchases: _.sumBy(data, 'Purchases'),
});

const ConversionFunnelChart = ({ data }) => (
  <Box flexGrow={1} padding={{ xs: 3, md: 7 }} minHeight={0}>
    <ConversionFunnelGraph data={dataFormatter(data)} />
  </Box>
);

export { ConversionFunnelChart };
