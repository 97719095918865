// React imports
import React, { useState, useEffect } from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { SlideTransition } from '../SlideTransition';

const Alert = React.forwardRef(({ onClose, severity, sx, children }, ref) => (
  <MuiAlert
    elevation={6}
    ref={ref}
    variant='filled'
    onClose={onClose}
    severity={severity}
    sx={sx}
  >
    {children}
  </MuiAlert>
));

const SuccessSnackbar = ({ message, setMsg }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsg('');
    setOpen(false);
  };

  useEffect(() => {
    if (message !== '') {
      handleOpen();
    }
  }, [message]);

  return (
    <Snackbar
      open={open}
      TransitionComponent={SlideTransition}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Alert
        onClose={handleClose}
        severity='success'
        sx={{ width: '100%', backgroundColor: '#D4ECFC', color: '#0096FF' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export { SuccessSnackbar };
