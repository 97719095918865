/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';

// MUI imports
import {
  Container,
  Box,
  Stack,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Formik, Form } from 'formik';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { FetchingDisplay } from '../../../components/FetchingDisplay';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { FormikField } from '../../../widgets/FormikForm/FormikField';
import { TitleCard } from '../../FeedManagement/Components/GlobalComponents/TitlePaper';
import { StyledPaper } from '../../FeedManagement/PageViewComponents/StyledMainBox';
import { GlobalLoadingComponent } from '../../../components/GlobalLoadingComponent';
import { detailsSchema } from '../Schema/schemas';
import {
  useGetAgencyDetailsQuery,
  useEditAgencyDetailsMutation,
} from '../../../api/RTK/services/user';
import { UserInfoDispatchContext } from '../../../context/UserInfoDispatchContext';
import { setUser } from '../../../utils/userInfoDispatchFunctions';
import { fetchUserInfo } from '../../../services/auth';
import { PageErrors } from '../../../components/PageErrors';
import { getAccessToken } from '../../../utils/token';

// Main functional component
const Details = () => {
  const [getData, setGetData] = useState({});
  const [isEdited, setiIsEdited] = useState(false);
  const { user } = useContext(UserInfoContext);
  const token = getAccessToken();
  const { data, isLoading, isError, error } = useGetAgencyDetailsQuery({
    skip: token,
  });
  const dispatch = useContext(UserInfoDispatchContext);
  const [editDetails] = useEditAgencyDetailsMutation();

  const onClick = () => {
    setiIsEdited(true);
  };

  const handleOnSubmit = async (values) => {
    try {
      await editDetails({
        name: values.projectName,
      })
        .unwrap()
        .then(() => setiIsEdited(false))
        .then(() => fetchUserInfo())
        .then((userInfo) => {
          setUser(dispatch, userInfo);
        });
    } catch (errors) {
      if (errors) {
        setiIsEdited(true);
      }
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      setGetData(data);
    }
  }, [isLoading, isError, data]);

  function createData(name, used, created) {
    return { name, used, created };
  }

  const filterData = ['id', 'name', 'mcc_id', 'Owner', 'company'];
  const filteredData = Object.fromEntries(
    Object.entries(getData)?.filter(([key]) => !filterData.includes(key))
  );

  const rows = Object.entries(filteredData)?.map(([key, value]) =>
    createData(key, value, null)
  );

  const StyledTableCell = styled(TableCell)`
    border: 0;
  `;

  return (
    <FetchingDisplay
      isLoading={isLoading}
      isError={isError}
      ErrorElement={
        <PageErrors
          code={error?.status}
          message={error?.data?.error?.detail}
          isServerError
          hideText
          customButton
        />
      }
      LoadingElement={<GlobalLoadingComponent />}
      SuccessElement={
        <Container maxWidth='xxl' sx={{ marginTop: '30px' }}>
          <TitleCard
            title={
              user.projectType === 'AGENCY' &&
              ['OWNER', 'ADMIN', 'COLLABORATOR'].includes(user.role)
                ? 'Agency Details'
                : 'Company Details'
            }
          />
          <StyledPaper>
            <Stack display='flex' flexDirection='column' gap={3}>
              <Box
                sx={{
                  border: (theme) => `1px solid ${theme.palette.border.main}`,
                  borderRadius: '14px',
                  p: 3,
                }}
              >
                <Stack
                  display='flex'
                  flexDirection={isEdited ? 'column' : 'row'}
                  justifyContent={isEdited ? 'space-between' : 'space-between'}
                  alignItems='center'
                  gap={3}
                >
                  <Box
                    display='flex'
                    flexDirection={isEdited ? 'column' : 'row'}
                    alignItems='center'
                    justifyContent={isEdited ? 'space-between' : 'flex-start'}
                    gap={2}
                    sx={{ width: '100%' }}
                  >
                    <Box
                      sx={{
                        border: (theme) =>
                          `1px solid ${theme.palette.border.main}`,
                        borderRadius: '100%',
                        width: '60px',
                        height: '60px',
                        fontSize: '2rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignSelf: isEdited ? 'flex-start' : 'center',
                      }}
                      component={Typography}
                      variant={
                        user.projectType === 'AGENCY' &&
                        ['OWNER', 'ADMIN', 'COLLABORATOR'].includes(user.role)
                          ? 'secondaryEmp'
                          : 'primaryEmp'
                      }
                    >
                      {data && (data.company || data.name)
                        ? Array.from(data.company || data.name).splice(0, 1)
                        : null}
                    </Box>
                    {isEdited ? (
                      <Box sx={{ width: '100%' }}>
                        <Formik
                          validateOnBlur={false}
                          initialValues={{
                            projectName: data?.name,
                            ownerName: data?.Owner,
                          }}
                          validationSchema={detailsSchema}
                          onSubmit={handleOnSubmit}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              {/* <Box ref={targetElement}>
                              <ErrorAlert message={errorMessage} />
                            </Box> */}
                              <Box
                                display='flex'
                                justifyContent='space-between'
                                alignItems='baseline'
                                gap={2}
                              >
                                <Box sx={{ width: '100%' }}>
                                  <FormikField
                                    required
                                    placeholder='Enter Name'
                                    name='projectName'
                                    type='text'
                                    value={values.projectName}
                                    label={
                                      user.projectType !== 'AGENCY'
                                        ? 'Company Name'
                                        : 'Agency Name'
                                    }
                                    error={
                                      Boolean(errors.projectName) &&
                                      touched.projectName
                                    }
                                    helperText={
                                      touched.projectName && errors.projectName
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    width: '100%',
                                    '& .Mui-disabled .MuiOutlinedInput-notchedOutline':
                                      {
                                        borderColor: (theme) =>
                                          theme.palette.border.main,
                                        cursor: 'none',
                                      },
                                  }}
                                >
                                  <FormikField
                                    required
                                    placeholder='Enter Owner Name'
                                    name='ownerName'
                                    type='text'
                                    value={values.ownerName}
                                    label='Owner Name'
                                    error={
                                      Boolean(errors.ownerName) &&
                                      touched.ownerName
                                    }
                                    helperText={
                                      touched.ownerName && errors.ownerName
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled
                                  />
                                </Box>
                              </Box>
                              <Box
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                gap={2}
                                pt={2}
                              >
                                <Button
                                  variant='outlined'
                                  sx={{
                                    minWidth: '160px',
                                    maxWidth: '180px',
                                    p: 1,
                                  }}
                                  onClick={() => setiIsEdited(false)}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type='submit'
                                  variant='contained'
                                  sx={{
                                    minWidth: '160px',
                                    maxWidth: '180px',
                                    p: 1,
                                  }}
                                  onClick={onClick}
                                >
                                  Save
                                </Button>
                              </Box>
                            </Form>
                          )}
                        </Formik>
                      </Box>
                    ) : (
                      <Box display='flex' flexDirection='column'>
                        <Typography variant='bigSemiBold'>
                          {data?.company ? data?.company : data?.name}
                        </Typography>
                        <Typography variant='smallBold'>
                          {data?.Owner}
                          <Typography
                            variant='smallMedium'
                            sx={{
                              color: (theme) => ` ${theme.palette.text.grey}`,
                            }}
                          >
                            &nbsp;(
                            {['SUB_ADMIN', 'SUB_COLLABORATOR'].includes(
                              user.role
                            )
                              ? 'Agency Owner'
                              : 'Owner'}
                            )
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  {['ADMIN', 'OWNER'].includes(user.role) ? (
                    !isEdited ? (
                      <Box>
                        <Button
                          variant='pillOutlined'
                          startIcon={<CreateOutlinedIcon />}
                          onClick={onClick}
                        >
                          Edit
                        </Button>
                      </Box>
                    ) : null
                  ) : null}
                </Stack>
                {data?.company && (
                  <Typography
                    variant='smallMedium'
                    sx={{ display: 'flex', mt: 2 }}
                  >
                    This company is under the agency&nbsp;
                    <Typography variant='smallBold'>
                      {data?.company ? data?.name : ''}
                    </Typography>
                  </Typography>
                )}
              </Box>
              <TableContainer>
                <Typography variant='bigSemiBold'>Usage :</Typography>
                <Table>
                  <TableHead sx={{ border: 0 }}>
                    <TableRow
                      sx={{
                        border: (theme) =>
                          `1px solid ${theme.palette.border.main}`,
                        borderRadius: '14px',
                        '& .MuiTableCell-root': {
                          borderRight: '0px solid #DEDEDE !important',
                        },
                      }}
                    >
                      <StyledTableCell />
                      <StyledTableCell align='right'>
                        Used/Created
                      </StyledTableCell>
                      <StyledTableCell align='right'>
                        Total Available in this plan
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      border: (theme) =>
                        `1px solid ${theme.palette.border.main}`,
                      borderRadius: '14px',
                    }}
                  >
                    {rows.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          '& .MuiTableCell-root': {
                            borderRight: '0px solid #DEDEDE !important',
                          },
                        }}
                      >
                        <StyledTableCell component='th' scope='row'>
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align='right'>
                          {row.used}
                        </StyledTableCell>
                        <StyledTableCell align='right'>
                          {row.created} --
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </StyledPaper>

          {/* <Backdrop sx={{ color: '#fff' }} open={!isLoading && isFetching}>
            <CircularProgress color='inherit' />
          </Backdrop> */}
        </Container>
      }
    />
  );
};

export { Details };
