import { Box, Button, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { sendReducer } from '../../../../reducers/sendReducer';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { DataSendingModalBase } from '../../../../components/DataSendingModalBase';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { feedManagementService } from '../../../../services/feedManagement';

const defaultState = { status: 'PRESEND' };

const ImportedFunctionsMap = {
  delete: feedManagementService.deleteFeedListFile,
  activate: feedManagementService.patchActivateFeedListFile,
  deactivate: feedManagementService.patchDeactivateFeedListFile,
};

const getFunction = (isFeed, functionName) => {
  if (isFeed) {
    return ImportedFunctionsMap[functionName];
  }
  return false;
};
const headingMap = {
  activate: 'Are you sure you want to Activate?',
  deactivate: 'Are you sure you want to Deactivate?',
  delete: 'Are you sure you want to Delete?',
};
const FeedListProjectModal = ({
  open,
  handleCloseModal,
  id,
  active,
  reloadFunction = null,
  successMessage = 'Success',
  errorMessage = 'Error',
  functionName = null,
  prompt = '',
  confirmButtonText = 'Confirm',
  showCancelButton = false,
  cancelButtonText = 'Cancel',
  isFeed = true,
}) => {
  const { company } = useContext(UserInfoContext);
  const [state, dispatch] = React.useReducer(sendReducer, defaultState);

  const projectSelectId = useSelector((s) => s.allProjectsRedux.projectId);
  const handleSubmit = () => {
    dispatch({ type: 'SENDING' });
    getFunction(isFeed, functionName)(company?.id, projectSelectId, {
      feed_id: id,
      active: !active,
    })
      .then((response) => {
        dispatch({ type: 'SENDSUCCESS', data: response.data });
        setTimeout(() => {
          handleCloseModal();
          dispatch({ type: 'PRESEND' });
          if (reloadFunction) {
            reloadFunction();
          }
        }, 100);
      })
      .catch((error) => {
        dispatch({ type: 'SENDERROR', error: error.data });
        setTimeout(() => {
          handleCloseModal();
          dispatch({ type: 'PRESEND' });
        }, 1000);
      });
  };
  return (
    <DataSendingModalBase
      open={open}
      handleCloseModal={handleCloseModal}
      status={state.status}
      successMessage={successMessage}
      errorMessage={errorMessage}
    >
      <HeadingWithUnderline heading={headingMap[functionName]} />
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        {prompt ? (
          <Typography variant='h4' sx={{ mb: 3 }}>
            {prompt}
          </Typography>
        ) : null}
        <Box
          sx={{ width: '100%' }}
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          gap={3}
        >
          {showCancelButton ? (
            <Button variant='outlined' onClick={handleCloseModal} fullWidth>
              {cancelButtonText}
            </Button>
          ) : null}
          <Button variant='contained' onClick={handleSubmit} fullWidth>
            {confirmButtonText}
          </Button>
        </Box>
      </Box>
    </DataSendingModalBase>
  );
};

export { FeedListProjectModal };
