import { useField } from 'formik';
import { fieldMap } from '../maps/fieldMap';

const ValueSelector = ({ name, methodName, fields }) => {
  const [{ value }] = useField(methodName);

  return fieldMap(fields, name)[value] || null;
};

export { ValueSelector };
