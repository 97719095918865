import { Link } from '@mui/material';
import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CustomSlider } from '../CustomSlider';
import {
  ClampedText,
  SliderImage,
  StyledFigCaption,
  StyledFigure,
} from './styles';

const ImageContainer = ({ link, imageSrc, label }) => (
  <Link
    href={link}
    target='_blank'
    rel='noreferrer'
    sx={{
      padding: 1,
      height: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
    }}
  >
    <StyledFigure component='figure'>
      <SliderImage src={imageSrc} alt='aa' className='productSliderImage' />
      <StyledFigCaption component='figcaption'>
        <ClampedText>{label}</ClampedText>
      </StyledFigCaption>
    </StyledFigure>
  </Link>
);

const settings = {
  dots: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
      },
    },
  ],
};

// data: array of objects having the following fields
// imageSrc: string. link to image
// link: link to product
// label: string. Text to be shown on image
const ImageSlider = ({ data, idField }) => (
  <CustomSlider settings={settings}>
    {data.map((dataObject) => (
      <ImageContainer
        key={
          typeof idField === 'string'
            ? dataObject[idField]
            : idField.reduce((prev, id) => prev + dataObject[id], '')
        }
        link={dataObject.link}
        imageSrc={dataObject.imageSrc}
        label={dataObject.label}
      />
    ))}
  </CustomSlider>
);

export { ImageSlider };
