import React, { useState, useMemo, useContext } from 'react';
import { Box } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { CustomizeButton } from '../../../../../../widgets/CustomizeButton';
import { Databox } from '../../../../../../components/Databox';
import { ShoppingProductsInsights } from '../../../../Databoxes/ShoppingProductsInsights';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';

import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { Loading } from '../../../../../../components/Databox/Loading';
import { getDashboardDataService } from '../../../../../../utils/getDashboardDataService';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';
import { CustomizedColumnModal } from '../../../../../MetricAndDimension/DashboardDatabox/CustomizeColumn';
import { ErrorComponent } from '../../../../../../components/GlobalErrorComponent';
// const filterList = [
//   {
//     name: 'All',
//     value: 'All',
//   },
//   {
//     name: 'In Stock',
//     value: 'IN_STOCK',
//   },
//   {
//     name: 'Out of Stock',
//     value: 'OUT_OF_STOCK',
//   },
// ];

const ShoppingProductsInsightsContainer = ({
  accountId,
  platform = 'google',
  dateRange,
}) => {
  const { account } = useContext(UserInfoContext);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const memoizedArguments = useMemo(
    () => ({
      account_id: accountId,
      date_range: dateRange,
      databox_type: 'SHOPPING_PRODUCTS_INSIGHTS',
    }),
    [dateRange, accountId]
  );

  const [state] = useDataFetcher(
    [],
    getDashboardDataService(platform),
    memoizedArguments,
    count
  );
  // const [filter, setFilter] = useState(filterList[0]);

  // const handleFilterChange = (newFilter) => {
  //   setFilter(newFilter);
  // };
  const onCustomizeClick = () => {
    setOpen(true);
  };

  return (
    <Databox>
      <ErrorBoundary
        fallback={
          <ErrorComponent
            title='Shopping Products Insights'
            info='Understand your product progress in-depth and use filters for specific details.'
          />
        }
      >
        <DataboxHeading
          title='Shopping Products Insights'
          info='Understand your product progress in-depth and use filters for specific details.'
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <CustomizeButton
              onCustomizeClick={onCustomizeClick}
              account={account}
            />
            {open && (
              <CustomizedColumnModal
                open={open}
                setOpen={setOpen}
                databoxType='shopping_products_insights'
                accountId={accountId}
                count={count}
                setCount={setCount}
              />
            )}
            {/* <Filter
            label='Filter'
            selectedFilter={filter}
            filterList={filterList}
            handleFilterChange={handleFilterChange}
          /> */}
          </Box>
        </DataboxHeading>
        <FetchingDisplay
          isLoading={state.isLoading}
          isError={state.isError}
          LoadingElement={<Loading />}
          ErrorElement={
            <ErrorDisplay
              refresh={() => {
                setCount(count + 1);
              }}
              message={
                state.errorInfo?.message === "'segments.product_item_id'" ||
                state.errorInfo?.code === 400 ||
                state.errorInfo?.code === 204
                  ? 'No data available for selected date range.'
                  : 'Click here to re'
              }
              showRefreshButton={
                !(
                  state.errorInfo?.message === "'segments.product_item_id'" ||
                  state.errorInfo?.code === 400 ||
                  state.errorInfo?.code === 204
                )
              }
            />
          }
          SuccessElement={
            <ShoppingProductsInsights
              data={state.data}
              // filter={filter}/
              currencyCode={account.currency_code}
            />
          }
        />
      </ErrorBoundary>
    </Databox>
  );
};

export { ShoppingProductsInsightsContainer };
