import React from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Container } from '@mui/system';
import { ReactComponent as Avocado404 } from '../../assests/images/artWork/Avocado404.svg';
import { ReactComponent as Avocado204 } from '../../assests/images/artWork/Avocado204.svg';
import { ReactComponent as Avocado400 } from '../../assests/images/artWork/Avocado400.svg';
import { ReactComponent as Avocado500 } from '../../assests/images/artWork/Avocado500.svg';

const ArtworkMap = {
  404: <Avocado404 />,
  204: <Avocado204 />,
  400: <Avocado400 />,
  500: <Avocado500 />,
};

const MessageMap = {
  404: 'The page you were looking for does not exist',
  204: 'No Content',
  400: 'Bad Request',
  500: 'Internal Server Error',
};

const ServerErrorMessageMap = {
  404: 'The requested resource was not found on this server',
  204: 'No Content',
  400: 'Bad Request',
  500: 'Internal Server Error',
  FETCH_ERROR: 'Network error while attempting to fetch resource',
};

const PageErrors = ({
  customErrorArtwork = null,
  code,
  message,
  isServerError = false,
  hideText = false,
  error = true,
  customText = '',
  customButton,
}) => (
  <Container
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(100vh - 105px)',
      overflow: 'hidden',
      padding: 2,
    }}
  >
    <Paper
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        flexGrow: 1,
        maxHeight: '650px',
        minHeight: '0px',
      }}
    >
      <>
        <Box
          sx={{
            flexGrow: 1,
            width: '100%',
            minHeight: 0,
            svg: { maxWidth: '100%', height: '100%' },
          }}
        >
          {customErrorArtwork || ArtworkMap[code] || <Avocado404 />}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            gap: 1,
          }}
        >
          {error && (
            <Typography
              variant='bigSemiBold'
              sx={{
                fontWeight: 400,
                color: 'error.main',
              }}
            >
              Error {code}:{' '}
              {message ||
                (isServerError && code
                  ? ServerErrorMessageMap[code]
                  : MessageMap[code]) ||
                `Something went wrong`}
            </Typography>
          )}
          {!hideText &&
            (customText || (
              <Typography sx={{ color: 'text.grey' }}>
                Let&apos;s get you back to the home page
              </Typography>
            ))}
        </Box>
        {customButton || (
          <Button component={Link} to='/feed-management' variant='outlined'>
            Go to Home
          </Button>
        )}
      </>
    </Paper>
  </Container>
);

export { PageErrors };
