export const dataPreprocess = (dataToProcess) => {
  const type = typeof dataToProcess;
  let processedData = [];

  if (type === 'object') {
    processedData = Object.entries(dataToProcess).map((e) => {
      if (Array.isArray(e[1])) {
        return e[1];
      }
      return e[1];
    });
  }

  return processedData;
};
