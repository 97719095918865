import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { toTitleCase, formatQuickStatsData } from './utils';

import { createTableForAdInsightsGoogle } from './utils/createTableForAdInsightsGoogle';
import { createTableForAdInsightsMeta } from './utils/createTableForAdInsightsMeta';
import { createTableForAdSchedular } from './utils/createTableForAdSchedular';
import { createTableForArray } from './utils/createTableForArray';
import { createTableForObject } from './utils/createTableForObject';

const generateExcel = (data, currencyCode, platform) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'AdHelp.io';
  workbook.lastModifiedBy = 'AdHelp.io';
  const date = new Date();
  workbook.created = date;
  workbook.modified = date;
  workbook.lastPrinted = date;

  Object.entries(data).forEach(([label, rawData]) => {
    const sheet = workbook.addWorksheet(toTitleCase(String(label)));
    try {
      switch (label) {
        case 'adInsights':
          if (platform === 'meta') {
            createTableForAdInsightsMeta(sheet, label, rawData, currencyCode);
          } else {
            createTableForAdInsightsGoogle(sheet, label, rawData, currencyCode);
          }
          break;
        case 'quickStats':
          createTableForArray(
            sheet,
            label,
            formatQuickStatsData(rawData),
            currencyCode,
            false
          );
          break;
        case 'averageCost':
          createTableForArray(sheet, label, rawData, currencyCode, false);
          break;
        case 'adSchedular':
          createTableForAdSchedular(sheet, label, rawData, currencyCode);
          break;
        default:
          if (!Array.isArray(rawData)) {
            createTableForObject(sheet, label, rawData, currencyCode);
          } else {
            createTableForArray(sheet, label, rawData, currencyCode);
          }
      }
    } catch (error) {
      console.log('catch: ', label, error);
    }
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], {
        type: 'application/octet-stream',
      }),
      `AdHelpData.xlsx`
    );
  });
};

export { generateExcel };

