import React from 'react';
import { ForgetPasswordForm } from '../Form/ForgetPasswordForm';
import { PagesWrraper } from './PagesWrraper';

const ForgetPasswordPage = () => (
  <PagesWrraper>
    <ForgetPasswordForm />
  </PagesWrraper>
);

export { ForgetPasswordPage };
