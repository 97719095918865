import React, { useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
// import { useDebouncedCallback } from 'use-debounce';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getAllTimezones } from 'countries-and-timezones'

// MUI imports
import { Stack, Button, Typography, Autocomplete, TextField, InputLabel } from '@mui/material';

// Internal components import
import { DynamicSelectBox } from '../GlobalComponents/DynamicSelectBox';

// Styled component import
import { StyledTime } from '../StyledComponents/StyledCommon';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { updateVal } from '../../Redux/ScheduleForm/slice';
import { ScheduleSchema } from '../../Schemas';

const repeat = [
  {
    title: 'Every 24 hours',
    value: '24',
  },
  {
    title: 'Every 12 hours',
    value: '12',
  },
  {
    title: 'Every 6 hours',
    value: '6',
  },
  {
    title: 'Every 3 hours',
    value: '3',
  },
  {
    title: 'Every hour',
    value: '1',
  },
];

const startTime = [
  {
    title: '00:00-00:15',
    value: '00:00-00:15',
  },
  {
    title: '00:15-00:30',
    value: '00:15-00:30',
  },
  {
    title: '00:30-00:45',
    value: '00:30-00:45',
  },
  {
    title: '00:45-01:00',
    value: '00:45-01:00',
  },
  {
    title: '01:15-01:30',
    value: '01:15-01:30',
  },
  {
    title: '01:30-01:45',
    value: '01:30-01:45',
  },
  {
    title: '01:45-02:00',
    value: '01:45-02:00',
  },
  {
    title: '02:00-02:15',
    value: '02:00-02:15 ',
  },
  {
    title: '02:15-02:30',
    value: '02:15-02:30',
  },
  {
    title: '02:30-02:45',
    value: '02:30-02:45',
  },
  {
    title: '02:45-03:00',
    value: '02:45-03:00',
  },
  {
    title: '03:00-03:15',
    value: '03:00-03:15 ',
  },
  {
    title: '03:15-03:30',
    value: '03:15-03:30',
  },
  {
    title: '03:30-03:45',
    value: '03:30-03:45',
  },
  {
    title: '03:45-04:00',
    value: '03:45-04:00',
  },
  {
    title: '04:00-04:15',
    value: '04:00-04:15 ',
  },
  {
    title: '04:15-04:30',
    value: '04:15-04:30',
  },
  {
    title: '04:30-04:45',
    value: '04:30-04:45',
  },
  {
    title: '04:45-05:00',
    value: '04:45-05:00',
  },
  {
    title: '05:00-05:15',
    value: '05:00-05:15 ',
  },
  {
    title: '05:15-05:30',
    value: '05:15-05:30',
  },
  {
    title: '05:30-05:45',
    value: '05:30-05:45',
  },
  {
    title: '05:45-06:00',
    value: '05:45-06:00',
  },
  {
    title: '06:00-06:15',
    value: '06:00-06:15 ',
  },
  {
    title: '06:15-06:30',
    value: '06:15-06:30',
  },
  {
    title: '06:30-06:45',
    value: '06:30-06:45',
  },
  {
    title: '06:45-07:00',
    value: '06:45-07:00',
  },
  {
    title: '07:00-07:15',
    value: '07:00-07:15',
  },
  {
    title: '07:15-07:30',
    value: '07:15-07:30',
  },
  {
    title: '07:30-07:45',
    value: '07:30-07:45',
  },
  {
    title: '07:45-08:00',
    value: '07:45-08:00',
  },
  {
    title: '08:00-08:15',
    value: '08:00-08:15 ',
  },
  {
    title: '08:00-08:15',
    value: '08:00-08:15',
  },
  {
    title: '08:30-08:45',
    value: '08:30-08:45',
  },
  {
    title: '08:45-09:00',
    value: '08:45-09:00',
  },
  {
    title: '09:00-09:15',
    value: '09:00-09:15',
  },
  {
    title: '09:15-09:30',
    value: '09:15-09:30',
  },
  {
    title: '09:30-09:45',
    value: '09:30-09:45',
  },
  {
    title: '09:45-10:00',
    value: '09:45-10:00',
  },
  {
    title: '10:00-10:15',
    value: '10:00-10:15',
  },
  {
    title: '10:15-10:30',
    value: '10:15-10:30',
  },
  {
    title: '10:30-10:45',
    value: '10:30-10:45',
  },
  {
    title: '10:45-11:00',
    value: '10:45-11:00',
  },
  {
    title: '11:00-11:15',
    value: '11:00-11:15',
  },
  {
    title: '11:15-11:30',
    value: '11:15-11:30',
  },
  {
    title: '11:30-11:45',
    value: '11:30-11:45',
  },
  {
    title: '11:45-12:00',
    value: '11:45-12:00',
  },
  {
    title: '12:00-12:15',
    value: '12:00-12:15',
  },
  {
    title: '12:15-12:30',
    value: '12:15-12:30',
  },
  {
    title: '12:30-12:45',
    value: '12:30-12:45',
  },
  {
    title: '12:45-13:00',
    value: '12:45-13:00',
  },

  {
    title: '13:00-13:15',
    value: '13:00-13:15',
  },
  {
    title: '13:15-13:30',
    value: '13:15-13:30',
  },
  {
    title: '13:30-13:45',
    value: '13:30-13:45',
  },
  {
    title: '13:45-14:00',
    value: '13:45-14:00',
  },
  {
    title: '14:00-14:15',
    value: '14:00-14:15',
  },
  {
    title: '14:15-14:30',
    value: '14:15-14:30',
  },
  {
    title: '14:30-14:45',
    value: '14:30-14:45',
  },
  {
    title: '14:45-15:00',
    value: '14:45-15:00',
  },
  {
    title: '15:00-15:15',
    value: '15:00-15:15',
  },
  {
    title: '15:15-15:30',
    value: '15:15-15:30',
  },
  {
    title: '15:30-15:45',
    value: '15:30-15:45',
  },
  {
    title: '15:45-16:00',
    value: '15:45-16:00',
  },
  {
    title: '16:00-16:15',
    value: '16:00-16:15 ',
  },
  {
    title: '16:15-16:30',
    value: '16:15-16:30',
  },
  {
    title: '16:30-16:45',
    value: '16:30-16:45',
  },
  {
    title: '16:45-17:00',
    value: '16:45-17:00',
  },
  {
    title: '17:00-17:15',
    value: '17:00-17:15',
  },
  {
    title: '17:15-17:30',
    value: '17:15-17:30',
  },
  {
    title: '17:30-17:45',
    value: '17:30-17:45',
  },
  {
    title: '17:45-18:00',
    value: '17:45-18:00',
  },
  {
    title: '18:00-18:15',
    value: '18:00-18:15',
  },
  {
    title: '18:15-18:30',
    value: '18:15-18:30',
  },
  {
    title: '18:30-18:45',
    value: '18:30-18:45',
  },
  {
    title: '18:45-19:00',
    value: '18:45-19:00',
  },
  {
    title: '19:00-19:15',
    value: '19:00-19:15',
  },
  {
    title: '19:15-19:30',
    value: '19:15-19:30',
  },
  {
    title: '19:30-19:45',
    value: '19:30-19:45',
  },
  {
    title: '19:45-20:00',
    value: '19:45-20:00',
  },
  {
    title: '20:00-20:15',
    value: '20:00-20:15',
  },
  {
    title: '20:15-20:30',
    value: '20:15-20:30',
  },
  {
    title: '20:30-20:45',
    value: '20:30-20:45',
  },
  {
    title: '20:45-21:00',
    value: '20:45-21:00',
  },
  {
    title: '21:00-21:15',
    value: '21:00-21:15 ',
  },
  {
    title: '21:15-21:30',
    value: '21:15-21:30',
  },
  {
    title: '21:30-21:45',
    value: '21:30-21:45',
  },
  {
    title: '21:45-21:00',
    value: '21:45-22:00',
  },
  {
    title: '22:00-22:15',
    value: '22:00-22:15 ',
  },
  {
    title: '22:15-22:30',
    value: '22:15-22:30',
  },
  {
    title: '22:30-22:45',
    value: '22:30-22:45',
  },
  {
    title: '22:45-22:00',
    value: '22:45-23:00',
  },
  {
    title: '23:00-23:15',
    value: '23:00-23:15 ',
  },
  {
    title: '23:15-23:30',
    value: '23:15-23:30',
  },
  {
    title: '23:30-23:45',
    value: '23:30-23:45',
  },
  {
    title: '23:45-23:00',
    value: '23:45-00:00',
  },
];

const timezoneOptions = Object.values(getAllTimezones()).map(timezone => ({
  title: timezone.name,
  value: timezone.name,
}));

// get the handleSubmit function from the parent component
// this removes the data sending logic from the form makes the form easier to maintain
const ScheduleForm = ({ data, handleSubmit }) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const { company, user } = useContext(UserInfoContext);
  const navigate = useNavigate();
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );

  const timezonebyCountry = useSelector(
    (state) => state.scheduleFormRedux
  );

  const updateReduxStoreOnSubmit = (values) => {
    dispatch(updateVal({ key: 'selectOnly', val: values.selectOnly }));
    dispatch(updateVal({ key: 'secondSelect', val: values.secondSelect }));
    dispatch(updateVal({ key: 'timezone', val: values.timezone }));
  };
 
  return (
    <Formik
      initialValues={{
        selectOnly: data?.repeat ||  timezonebyCountry.selectOnly || "24",
        secondSelect: data?.start_time ||  timezonebyCountry.secondSelect || "00:00-00:15",
        timezone: data?.timezone || timezonebyCountry.timezone || timezoneOptions[0].value,
        scheduleId: data?.id,
      }}
      innerRef={formikRef}
      enableReinitialize
      validationSchema={ScheduleSchema}
      onSubmit={(values) => {
        // Update the store only on form submission
        updateReduxStoreOnSubmit(values)

        // Call the handleSubmit function to make API request
        handleSubmit({
          feed_project_id: projectSelectId,
          company_id: company?.id,
          timezone: values.timezone,
          time_interval: values.secondSelect ,
          repeat: `${values.selectOnly}` || repeat[0].value,
          ...(data?.id ? { schedule_id: data.id } : {}),
        });

        // If needed, navigate to another page after submission
        if (user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR') {
          navigate('/feed-management/feeds');
        }
      }}
    >
      {({ values, handleBlur, handleChange, errors, touched, setFieldValue }) => (
        <Form>
          <Typography
            sx={{
              color: '#888888',
              fontSize: '16px',
              marginBottom: '20px',
              marginTop: '5px',
            }}
          >
            Choose your time to run this project
          </Typography>
          <Stack
            alignItems='flex-start'
            direction={{ xs: 'column', lg: 'row' }}
            sx={{
              width: '50%', '@media (maxWidth: 1200px)': {
                width: '100%',
              }
            }}
            flexWrap='wrap'
          >
            <StyledTime
              style={{
                width: '100%',
                paddingRight: '15px',
                '@media (maxWidth: 1200px)': {
                  paddingRight: '0px',
                },
              }}
            >
              <InputLabel sx={{ dispaly: 'block', paddingBottom: ' 4px' }}>
                In timezone
              </InputLabel>
              <Autocomplete
                id="timezone"
                name="timezone"
                options={timezoneOptions}
                getOptionLabel={(option) => option.title || ''}
                value={timezoneOptions?.find((option) => option.value === values.timezone) || timezoneOptions[0].value}
                onChange={(event, value) => {
                  setFieldValue('timezone', value !== null ? value.value : '');
                }}
                disabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
                sx={{
                  '& .MuiOutlinedInput-root .Mui-disabled': {
                    backgroundColor: 'transparent',
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    name="timezone"
                    placeholder="Select Timezone"
                    hiddenLabel
                    error={errors.timezone && touched.timezone}
                    helperText={errors.timezone && touched.timezone && errors.timezone}
                    className={errors.timezone && touched.timezone ? 'error' : ''}
                    sx={{
                      '& .MuiFormHelperText-root': {
                        position: 'absolute',
                        bottom: '-20px',
                      },
                    }}
                  />
                )}
              />
            </StyledTime>
          </Stack>
          <Stack
            alignItems='center'
            direction={{ xs: 'column', lg: 'row' }}
            flexWrap='wrap'
          >
            <DynamicSelectBox
              inputTitle='Repeat'
              name='selectOnly'
              // removed persist logic from inside the form
              // makes it easier to maintain
              value={values.selectOnly}
              className='projectSelect'
              selectedItem={repeat}
              handleChange={(event) => {
                handleChange(event);
              }}
              handleBlur={handleBlur}
              disabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
            />
            <DynamicSelectBox
              inputTitle='Start time'
              name='secondSelect'
              value={values.secondSelect}
              className='projectSelect scheduleSelect'
              selectedItem={startTime}
              handleChange={(event) => {
                handleChange(event);
              }}
              handleBlur={handleBlur}
              disabled={(user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR')}
            />
          </Stack>

          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            marginTop='20px'
          >
            <Button
              sx={{
                width: '200px',
                margin: '25px auto',
              }}
              variant='contained'
              onClick={() => {
                if (user?.role === 'COLLABORATOR' || user?.role === 'SUB_COLLABORATOR') {
                  navigate('/feed-management/feeds');
                }
              }}
              type='submit'
            >
              {(user?.role !== 'COLLABORATOR' && user?.role !== 'SUB_COLLABORATOR') ? 'Save' : 'Go to Feeds'}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export { ScheduleForm };
