/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import {
  Typography,
  Box,
  Stack,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ButtonComponent, StyledPaper } from '../../Components/utils';
import {
  useDeleteUsersDetailsMutation,
  useGetProjectListQuery,
} from '../../../../api/RTK/services/user';
import { userLogout } from '../../../../utils/userInfoDispatchFunctions';
import {
  DeleteDialog,
  SuccessDialog,
  ErrorDialog,
} from '../../Components/DeleteConfirmationDialog/DeleteDialog';
import { FetchingDisplay } from '../../../../components/FetchingDisplay';
import { CustomTable } from '../../../../components/Table';
import { UserInfoContext } from '../../../../context/UserInfoContext';
import { UserInfoDispatchContext } from '../../../../context/UserInfoDispatchContext';
import { UsersSkeleton } from '../../Components/Skeleton/UsersSkeleton';
import { UserToolbar } from '../../UserToolbar';
import { PageErrors } from '../../../../components/PageErrors';
import { RemoveButton } from '../../Components/RemoveButton';
import { IconDecider } from '../../Components/IconDecider';
import { getAccessToken } from '../../../../utils/token';

const headCells = (openModal, data, user, isEditable = false) => [
  {
    id: 'username',
    numeric: false,
    label: 'Users',
    disablePadding: false,
    isImage: false,
    formatFunction: (row) => (
      <Stack
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='row'
      >
        <IconDecider row={row} fill='#0096FF' />
        <Box display='flex' flexDirection='column'>
          <Typography variant='smallMedium' sx={{ mb: 0.2 }}>
            {row.username}
          </Typography>
          <Typography variant='smallRegular'>{row.email}</Typography>
        </Box>
      </Stack>
    ),
  },
  {
    id: 'role',
    numeric: false,
    label: 'Company Access',
    disablePadding: false,
    isImage: false,
    formatFunction: (row) => (
      <Typography variant='smallMedium'>
        {['SUB_ADMIN'].includes(row?.role)
          ? 'Company Admin'
          : 'Company Collaborator'}
      </Typography>
    ),
  },
  ...(data?.company_users?.length > 0
    ? [
        {
          id: 'edit',
          numeric: false,
          label: '',
          disablePadding: false,
          isImage: false,
          formatFunction: (row) => {
            if (row.id === user.id) {
              return null;
            }
            if (isEditable) {
              return (
                <ButtonComponent
                  disabled={row.isLoading}
                  to={`/users/company/edit-user/${row.id}`}
                />
              );
            }
            return null;
          },
        },
      ]
    : []),
  ...(data?.company_users?.length > 0
    ? [
        {
          id: 'remove',
          numeric: false,
          label: '',
          disablePadding: false,
          isImage: false,
          formatFunction: (row) => {
            if (row.role === 'OWNER') {
              return null;
            }
            if (
              ['COLLABORATOR', 'SUB_COLLABORATOR'].includes(row.role) &&
              row.id === user.id
            ) {
              return (
                <RemoveButton text='Exit' onClick={() => openModal(row?.id)} />
              );
            }
            if (isEditable) {
              return (
                <RemoveButton
                  text={user.id === row?.id ? 'Exit' : 'Remove'}
                  edit={user.id === row?.id}
                  onClick={() => openModal(row?.id)}
                />
              );
            }
            return null;
          },
        },
      ]
    : []),
];

// adds accessibleCount field to row object. This is necessary for sorting
const dataFormatter = (rawData) =>
  rawData.map((d, index) => ({
    ...d,
    key: index, // Or use a unique identifier from your data if available
  }));

const UsersCompanyPage = () => {
  const { user } = useContext(UserInfoContext);
  const isEditable = ['ADMIN', 'OWNER', 'SUB_ADMIN'].includes(user.role);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [msg, setMsg] = useState(false);
  const dispatch = useContext(UserInfoDispatchContext);
  const token = getAccessToken();
  const {
    data: allUsersList,
    isLoading: loadingState,
    isError: errorState,
    error: errorStatus,
  } = useGetProjectListQuery({ skip: token });

  const [list, setList] = useState(allUsersList);
  const [deleteUser, { isError, isSuccess, error }] =
    useDeleteUsersDetailsMutation({ refetchOnMountOrArgChange: true });

  const openDialog = (idArg) => {
    setId(idArg);
    setOpen(true);
  };

  const closeDialog = () => {
    setId(null);
    setOpen(false);
  };
  const closeModalWithClick = () => {
    setMsg(false);
  };

  const handleDelete = (userID) => {
    deleteUser({ id: userID })
      .then(() => {
        setOpen(false);
        setMsg(true);
        if (user.id === userID) {
          userLogout(dispatch);
        }
        setTimeout(() => {
          setMsg(false);
        }, 2000);
      })
      .catch(() => {
        setMsg(false);
      });
  };
  const colorCode = (data) => {
    let color = '';
    switch (data) {
      case 'BLUE':
        color = '#5EB2FB';
        break;
      case 'GREEN':
        color = '#69DB86';
        break;
      case 'RED':
        color = '#F06765';
        break;
      case 'YELLOW':
        color = '#F6B000';
        break;
      case 'ORANGE':
        color = '#F18943';
        break;
      default:
        color = '#5EB2FB';
    }
    return color;
  };

  return (
    <FetchingDisplay
      isLoading={loadingState}
      isError={errorState}
      LoadingElement={<UsersSkeleton />}
      ErrorElement={
        <PageErrors
          code={errorStatus?.status}
          message={errorStatus?.data?.error?.detail}
          isServerError
          hideText
          customButton
        />
      }
      SuccessElement={
        <>
          <UserToolbar
            list={list}
            setList={setList}
            allUsersList={allUsersList}
          />
          {allUsersList ? (
            <Container
              maxWidth={false}
              sx={{ mt: 1, mb: 2, position: 'relative' }}
            >
              {list?.company_users?.length > 0 ? (
                list?.company_users?.map((item) => (
                  <StyledPaper
                    sx={{ mb: 3, borderRadius: '8px !important' }}
                    // eslint-disable-next-line react/no-array-index-key
                    key={item?.company}
                  >
                    <Accordion
                      sx={{
                        borderRadius: '8px !important',
                        '.MuiAccordionDetails-root': {
                          padding: 0,
                        },
                      }}
                    >
                      <AccordionSummary
                        sx={{
                          '& .MuiAccordionSummary-content': {
                            alignItems: 'center !important',
                          },
                        }}
                        expandIcon={
                          <KeyboardArrowDownIcon
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme.palette.primary.main}`,
                              borderRadius: ' 100%',
                              backgroundColor: (theme) =>
                                theme.palette.background.hoverBlue,
                              color: (theme) => theme.palette.background.main,
                            }}
                          />
                        }
                      >
                        <Box
                          sx={{
                            borderRadius: '100%',
                            backgroundColor: `${colorCode(
                              item?.company_color
                            )}`,
                            width: '40px',
                            height: '40px',
                            p: 2,
                            mr: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant='h3' color='#fff'>
                            {Array.from(item?.company)?.splice(0, 1)}
                          </Typography>
                        </Box>
                        <Typography variant='bodySemiBold'>
                          {`${item?.company} Users (${item?.users?.length})`}
                        </Typography>
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails
                        sx={{
                          '.MuiStack-root': {
                            borderRadius: '18px',
                            minWidth: '250px',
                          },
                          '.MuiTableContainer-root': {
                            borderRadius: '18px',
                          },
                        }}
                      >
                        <CustomTable
                          headCells={headCells(
                            openDialog,
                            list,
                            user,
                            isEditable
                          )}
                          rows={dataFormatter(item?.users)}
                          rowIdField='id'
                          displayFooter={false}
                          defaultOrder='desc'
                          defaultOrderBy='role'
                          height='auto'
                        />
                      </AccordionDetails>
                    </Accordion>
                  </StyledPaper>
                ))
              ) : (
                <p>No data found</p>
              )}
              <>
                <DeleteDialog
                  open={Boolean(open && id)}
                  handleClose={closeDialog}
                  handleConfirm={() => {
                    handleDelete(id);
                  }}
                />
                {isSuccess && (
                  <SuccessDialog
                    msgopen={msg}
                    closeModalWithClick={closeModalWithClick}
                  />
                )}
                {isError && (
                  <ErrorDialog
                    msgopen={msg}
                    copy={error}
                    closeModalWithClick={closeModalWithClick}
                  />
                )}
              </>
            </Container>
          ) : (
            <p>No data</p>
          )}
        </>
      }
    />
  );
};

export { UsersCompanyPage };
