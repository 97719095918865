import React, { useState, useMemo, useContext } from 'react';

import { Box } from '@mui/material';

import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';

import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { Filter } from '../../../../../../components/Filter';
import { YearlyInsightsChart } from '../../../../Databoxes/YearlyInsights/Chart';
import { Loading } from '../../../../../../components/Databox/Loading';

import { getDemoDashboardService } from '../../../../../../services/DemoDashboard';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { AccountContext } from '../../accountContext';

const filterList = [
  {
    name: 'Clicks',
    value: 'Clicks',
  },
  {
    name: 'Cost',
    value: 'Spend',
  },
  {
    name: 'Conversions',
    value: 'Conversions',
  },
  {
    name: 'Revenue',
    value: 'Revenue',
  },
  {
    name: 'Reach',
    value: 'Reach',
  },
  {
    name: 'ROAS',
    value: 'ROAS',
  },
];

const YearlyInsightsContainer = ({ platform }) => {
  const { currencyCode } = useContext(AccountContext);
  const memoizedArgs = useMemo(
    () => ({
      databox_type: 'YEARLY_INSIGHTS',
    }),
    []
  );

  const [state] = useDataFetcher(
    [],
    getDemoDashboardService(platform),
    memoizedArgs
  );
  // instead of setting filter as a 'string' it is set as an object here because we need the value and name inside the chart component
  const [filter, setFilter] = useState(filterList[0]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <Databox>
      <DataboxHeading
        title='Yearly Insights'
        info='Know your yearly account performance, using the analytical filters. This will help evaluate your yearly expense more efficiently.'
      >
        <Filter
          label='Filter'
          selectedFilter={filter}
          filterList={filterList}
          handleFilterChange={handleFilterChange}
        />
      </DataboxHeading>
      <FetchingDisplay
        isError={state.isError}
        isLoading={state.isLoading}
        LoadingElement={<Loading />}
        ErrorElement={<ErrorDisplay />}
        SuccessElement={
          <Box padding={2} flexGrow={1} minHeight={0}>
            <YearlyInsightsChart
              data={state.data}
              filter={filter}
              currencyCode={currencyCode}
            />
          </Box>
        }
      />
    </Databox>
  );
};

export { YearlyInsightsContainer };
