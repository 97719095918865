import React from 'react';
import { ToggleButton } from '@mui/material';
import { Link } from 'react-router-dom';

import { StyledToggleButtonGroup } from '../StyledComponents/StyledCommon';

const ToggleButtonSettings = ({ toggled, setToggled }) => {

  const handleToggled = (event, newToggled) => {
    if (newToggled !== null) {
      setToggled(newToggled);
    }
  };
  return (
    <StyledToggleButtonGroup
      value={toggled}
      exclusive
      onChange={handleToggled}
      aria-label="toggle button group"
      className='twoToggleParent'

    >
      <ToggleButton
        value='Project'
        aria-label="toggle button 1"
        component={Link}
        to="../settings"
        color={toggled === 'Project' ? "primary" : "standard"}
        className='twoToggle1'

      >
        Project

      </ToggleButton>
      <ToggleButton
        value='Schedule'
        aria-label="toggle button 2"
        component={Link}
        to="../settings/schedule"
        color={toggled === 'Schedule' ? "primary" : "standard"}
        className='twoToggle2'

      >
        Schedule
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export { ToggleButtonSettings };