import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  useGetActionsQuery,
  useEditActionMutation,
  useEditFilterMutation,
  useGetFiltersQuery,
} from '../../../../api/RTK/services/feedFeed';

import { UserInfoContext } from '../../../../context/UserInfoContext';
import { DataSendingModalBaseRTK } from '../../../../components/DataSendingModalBaseRTK';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { clearActionSlice } from '../../Redux/AddActionFilter/EditActionSlice';
import { clearFilterSlice } from '../../Redux/AddActionFilter/EditFilterSlice';

const AddActionFilter = ({ name, pageName }) => {
  const { company } = useContext(UserInfoContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  // fetching project id & feed id stored in redux store
  const dispatch = useDispatch();
  const projectSelectId = useSelector(
    (state) => state.allProjectsRedux.projectId
  );
  const feedId = useSelector((state) => state?.feedSummaryRedux?.feedId);

  const editActionFormData = useSelector(
    (state) => state.ActionFilterData.ActionFilterData
  );
  const editFilterFormData = useSelector(
    (state) => state.EditFilteredData.editedFilterData
  );
  const [
    editAction,
    {
      isLoading: isActionLoading,
      isError: isActionHasError,
      isSuccess: isActionSuccess,
      reset,
      errorMessage,
      successMessage,
    },
  ] = useEditActionMutation();
  const [
    editFilter,
    {
      isLoading: isFilterLoading,
      isError: isFilterHasError,
      isSuccess: isFilterSuccess,
    },
  ] = useEditFilterMutation();
  const { data } =
    pageName === 'edit action'
      ? useGetActionsQuery({
        companyId: company?.id,
        projectId: projectSelectId,
        feedId,
      })
      : useGetFiltersQuery({
        companyId: company?.id,
        projectId: projectSelectId,
        feedId,
      });

  // data
  const item =
    data && pageName === 'edit action'
      ? data.filter((element) => element.id === (editActionFormData && editActionFormData.id))
      : data?.filter((element) => element.id === (editFilterFormData && editFilterFormData.id));

  const compareObjects = (obj1, obj2) =>
    JSON.stringify(obj1) === JSON.stringify(obj2);

  // add new action
  const handleAction = () => {
    const valuesMatch = compareObjects(item[0], editActionFormData);

    if (valuesMatch) {
      navigate('/feed-management/feeds/enhance/new-action');
    } else {
      setOpen(true);
    }
  };

  // add new filter
  const handleFilter = () => {
    const valuesMatch = compareObjects(item[0], editFilterFormData);
    if (valuesMatch) {
      navigate('/feed-management/feeds/enhance/new-filter');
    } else {
      setOpen(true);
    }
  };
  // discart all changes and continue with the old data and re-direct to the add new-action page
  const handleActionDiscard = () => {
    navigate('/feed-management/feeds/enhance/new-action');
    setOpen(false);
  };
  // discart all changes and continue with the old data and re-direct to the add new-filter page
  const handleFilterDiscard = () => {
    navigate('/feed-management/feeds/enhance/new-filter');
    setOpen(false);
  };
  // handle action patch call
  const handleActionSubmit = () => {
    editAction({
      projectId: projectSelectId,
      companyId: company.id,
      data: { ...editActionFormData },
    }).then(() => {
      // Redirect to the add new action page once the action is updated
      navigate('/feed-management/feeds/enhance/new-action');
      setOpen(false);
    });
    dispatch(clearActionSlice());
  };

  // handle filter patch call
  const handleFilterSubmit = () => {
    editFilter({
      projectId: projectSelectId,
      companyId: company.id,
      data: { ...editFilterFormData },
    }).then(() => {
      // Redirect to the add new action page once the action is updated
      navigate('/feed-management/feeds/enhance/new-filter');
      setOpen(false);
    });
    dispatch(clearFilterSlice());
  };

  return (
    <>
      <Button
        variant='contained'
        sx={{
          borderRadius: '25px',
          marginTop: '14px',
          marginRight: '24px',
          minWidth: '184px !important',
        }}
        onClick={pageName === 'edit action' ? handleAction : handleFilter}
        startIcon={<AddIcon sx={{ color: '#fff !important' }} />}
      >
        {name}
      </Button>

      <DataSendingModalBaseRTK
        open={open}
        handleCloseModal={onClose}
        successMessage={successMessage}
        errorMessage={errorMessage}
        isSuccess={isActionSuccess || isFilterSuccess}
        isLoading={isActionLoading || isFilterLoading}
        isError={isActionHasError || isFilterHasError}
        reset={reset}
      >
        <HeadingWithUnderline heading='Changes are not saved' />
        <Typography
          sx={{ textAlign: 'left', marginLeft: '4px', fontWeight: '500' }}
        >
          Do You wish to save the changes you made?
        </Typography>
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          marginTop='10px'
        >
          <Button
            sx={{
              width: '200px',
              margin: '25px 5px',
            }}
            variant='outlined'
            onClick={
              pageName === 'edit action'
                ? handleActionDiscard
                : handleFilterDiscard
            }
          >
            Discard Changes
          </Button>
          <Button
            sx={{
              width: '200px',
              margin: '25px 5px',
            }}
            variant='contained'
            onClick={
              pageName === 'edit action'
                ? handleActionSubmit
                : handleFilterSubmit
            }
          >
            Save Changes
          </Button>
        </Stack>
      </DataSendingModalBaseRTK>
    </>
  );
};

export { AddActionFilter };
