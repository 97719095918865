// React imports
import React, { useState, useEffect } from 'react';

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { SlideTransition } from '../SlideTransition';


const Alert = React.forwardRef(({ onClose, severity, sx, children , action , iconMapping}, ref) => (
  <MuiAlert
    elevation={6}
    ref={ref}
    variant='filled'
    onClose={onClose}
    severity={severity}
    sx={sx}
    action={action}
    iconMapping={iconMapping}
  >
    {children}
  </MuiAlert>
));

const ErrorSnackBar = ({ message, setMessage, autoHideDuration , onPatchClick , sx , page , buttonDisabled, severity = 'error' }) => {
  const [open, setOpen] = useState(false);
  const showActionButton = page === 'editMapping' || page === 'quality';

  const handleClose = () => {
    setOpen(false);
    if (setMessage) 
      setMessage('');
  };

  useEffect(() => {
    if (message !== '') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [message]);

  const handleCloseForEditQuality = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  let iconMapping;
  if (severity === 'success') {
    iconMapping = { success: <CheckCircleIcon fontSize="inherit" /> };
  } else if (showActionButton) {
    iconMapping = { error: <WarningAmberOutlinedIcon fontSize="inherit" /> };
  } else {
    iconMapping = { error: <ErrorOutlineOutlinedIcon fontSize="inherit" /> };
  }

  return (
    <Snackbar
      open={open}
      TransitionComponent={SlideTransition}
      autoHideDuration={autoHideDuration}
      onClose={showActionButton ? handleCloseForEditQuality : handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      message={message}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%', ...sx }} action={
        showActionButton && (
          <Button
            size='small'
            onClick={onPatchClick}
            sx={{
              color: 'white',
              backgroundColor: buttonDisabled ? "#DEDEDE" : '#FF823C',
              // Remove hover effect
              '&:hover': {
                backgroundColor: '#FF823C', 
                color: 'white', 
                borderColor: 'transparent',
                textDecoration: 'none', 
              },
            }}
            disabled={buttonDisabled}
          >
            Save Changes
          </Button>
        )
      }
        iconMapping={iconMapping}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export { ErrorSnackBar };
