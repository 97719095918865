/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useContext } from 'react';

import { Box } from '@mui/material';
import _ from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';

import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import StackedBarChartOutlinedIcon from '@mui/icons-material/StackedBarChartOutlined';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import TrendingDownOutlinedIcon from '@mui/icons-material/TrendingDownOutlined';
import ViewStreamOutlinedIcon from '@mui/icons-material/ViewStreamOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { ErrorComponent } from '../../../../../../components/GlobalErrorComponent';
import { CustomizeButton } from '../../../../../../widgets/CustomizeButton';
import { QuickStats } from '../../../../Databoxes/QuickStats';
import { Databox } from '../../../../../../components/Databox';
import { DataboxHeading } from '../../../../../../components/DataboxHeading';
import { Loading } from '../../../../../../components/Databox/Loading';
import { FetchingDisplay } from '../../../../../../components/FetchingDisplay';
import { useDataFetcher } from '../../../../../../Hooks/useDataFetcher';
import { getDashboardDataService } from '../../../../../../utils/getDashboardDataService';
import { ErrorDisplay } from '../../../../../../components/ErrorDisplay';
import { UserInfoContext } from '../../../../../../context/UserInfoContext';
import { CustomizedColumnModal } from '../../../../../MetricAndDimension/DashboardDatabox/CustomizeColumn';

const Icons = [
  {
    metrics: [
      'Conversion value',
      'Conversion rate (interactions)',
      'Conversions',
      'All Conversion value',
      'All Conversions',
      'All Conversions From Interactions Rate',
      'WebApp Adds To Cart Conversion Value',
      'Checkouts Initiated Conversion Value',
      'Pixel Purchases',
      'Pixel Purchases Conversion Value',
      'Checkout Pixel Conversion Value',
      'Purchases Conversion Value',
      'View Content Conversion',
      'Offsite Conversion Pixel Lead',
      'Offsite Conversion Pixel Custom',
      'Onsite Conversion Lead Grouped',
    ],
    icon: TrendingUpOutlinedIcon,
  },
  {
    metrics: ['Average Cost', 'Average CPC', 'Cost', 'Spend', 'CPC', 'Revenue'],
    icon: PaymentsOutlinedIcon,
  },
  {
    metrics: ['Average CPE', 'Average CPV', 'Cost'],
    icon: CalculateOutlinedIcon,
  },
  {
    metrics: [
      'Active View Ctr',
      'Clicks',
      'CTR',
      'Invalid Click Rate',
      'Invalid Clicks',
      'Clicks',
      'CTR',
      'Link Clicks',
      'Click To Call Confirm',
      'Call Confirm Grouped',
      'Inline Link Clicks',
    ],
    icon: AdsClickOutlinedIcon,
  },
  {
    metrics: [
      'Conversion value (by conversion time)',
      'Conversions (by conversion time)',
      'All Conversion values (by Conversion time)',
      'All Conversions (by Conversion time)',
      'Cost per All Conversions',
      'Cost per Conversions values',
      'View Through Conversions',
    ],
    icon: InsightsOutlinedIcon,
  },
  {
    metrics: [
      'Interactions',
      'Interactions Rate',
      'Engagement rate',
      'Engagements',
      'Impressions',
      'Post Engagement',
      'Page Engagement',
      'Post Reactions',
      'Actions Post',
      'Actions Rsvp',
      'Actions Like',
      'Interactive Component Tap',
    ],
    icon: TouchAppOutlinedIcon,
  },
  {
    metrics: ['Video View Rate', 'Video Impressions', 'Thruplays'],
    icon: VideoLibraryOutlinedIcon,
  },
  {
    metrics: ['Search lost Impression share', 'Search Impression share'],
    icon: TrendingDownOutlinedIcon,
  },
  {
    metrics: ['ROAS'],
    icon: ViewStreamOutlinedIcon,
  },
  {
    metrics: [
      'Website Purchases',
      'Website Purchases Conversion Value',
      'WebApp Purchases',
      'Purchases',
      'Actions Purchase',
    ],
    icon: ShoppingBagOutlinedIcon,
  },
  {
    metrics: ['Reach', 'Leads', 'Leadgen Grouped', 'Onsite Web Lead'],
    icon: SignalCellularAltOutlinedIcon,
  },
  {
    metrics: [
      'Adds To Cart Conversion Value',
      'Omni Add To Cart',
      'Add To Cart',
      'Omni values add to cart',
      'Website Add To Cart',
      'Website Adds To Cart Conversion Value',
      'Offsite Pixel Conversion Add To Cart',
      'Offsite Conversion Pixel Values Add To Cart',
      'Web App Add To Cart',
    ],
    icon: ShoppingCartOutlinedIcon,
  },
  {
    metrics: [
      'Checkouts Initiated',
      'Omni Checkout Initiated',
      'Website Checkouts Initiated',
      'Pixel Checkouts Initiated',
    ],
    icon: ShoppingCartCheckoutOutlinedIcon,
  },
  {
    metrics: [
      'WebApp Content Views',
      'Website Content Views',
      'Content Views',
      'Actions View Content',
      'Onsite App View Content',
      'Onsite Conversion View Content',
      'Landing Page View',
      'Video Views',
      'Views p100',
      'Views p75',
      'Views p50',
      'Views p25',
      'Omni View_Content Values',
      'Offsite Conversion Pixel View Content Values',
      'Onsite Web App View Content',
      'Onsite Web View Content',
      'View Content',
    ],
    icon: RemoveRedEyeOutlinedIcon,
  },
  {
    metrics: ['Post Saves'],
    icon: TaskAltOutlinedIcon,
  },
  {
    metrics: ['Post Comments'],
    icon: ModeCommentOutlinedIcon,
  },
];

const MetricWithCurrency = [
  'All Conversion value',
  'Average Cost',
  'Conversion value',
  'Cost',
  'Cost per All Conversions',
  'Cost per Conversions values',
  'All Conversion values (by Conversion time)',
];

const valueFormatter = (currencyCode, key, value) => {
  if (
    MetricWithCurrency.map((item) => item.toLowerCase()).includes(
      key.toLowerCase()
    )
  ) {
    return `${currencyCode} ${_.round(value).toLocaleString()}`;
  }
  if (key === 'Conversion Rate') {
    return `${_.round(value * 100, 1)}%`;
  }
  if (key === 'ROAS') {
    return `${_.round(value, 1)}`;
  }
  return _.round(value).toLocaleString();
};

function convertData(data, currencyCode) {
  const dataObj = data;

  const MainfilterList = Object.keys(dataObj).map((key) => ({
    label: key,
    value: valueFormatter(currencyCode, key, dataObj[key]),
    icon:
      Icons.find((iconObj) => iconObj.metrics.includes(key))?.icon ||
      StackedBarChartOutlinedIcon,
  }));
  return MainfilterList;
}

const QuickStatsContainer = ({ platform, accountId, dateRange }) => {
  const { account } = useContext(UserInfoContext);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const memoizedArgs = useMemo(
    () => ({
      account_id: accountId,
      date_range: dateRange,
      databox_type: 'STATS',
    }),
    [accountId, dateRange]
  );

  const [state] = useDataFetcher(
    [],
    getDashboardDataService(platform),
    memoizedArgs,
    count
  );

  const onCustomizeClick = () => {
    setOpen(true);
  };

  return (
    <Databox
      sx={{
        '.MuiIconButton-root': {
          cursor: 'default !important',
        },
        minHeight: '180px',
      }}
    >
      <ErrorBoundary
        fallback={
          <ErrorComponent
            title='Quick Stats'
            info='A quick look at your business analytics.'
          />
        }
      >
        <DataboxHeading
          title='Quick Stats'
          info='A quick look at your business analytics.'
          sx={{
            display: 'none',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <CustomizeButton
              onCustomizeClick={onCustomizeClick}
              account={account}
            />
            {open && (
              <CustomizedColumnModal
                open={open}
                setOpen={setOpen}
                databoxType='stats'
                accountId={accountId}
                count={count}
                setCount={setCount}
              />
            )}
          </Box>
        </DataboxHeading>
        <FetchingDisplay
          isLoading={state.isLoading}
          isError={state.isError}
          LoadingElement={<Loading />}
          ErrorElement={
            <ErrorDisplay
              refresh={() => {
                setCount(count + 1);
              }}
              message={
                state.errorInfo?.code === 204
                  ? 'No data available for selected date range.'
                  : state.errorInfo?.code === 400
                  ? "Couldn't load data"
                  : 'Something went wrong'
              }
              showRefreshButton={
                !(
                  state.errorInfo?.code === 400 || state.errorInfo?.code === 204
                )
              }
            />
          }
          SuccessElement={
            state.data ? (
              <Box
                sx={{
                  padding: '1.5rem',
                  paddingInline: '3rem',
                  width: '100%',
                  '.slick-slide > div': {
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    borderRight: '1px solid #DEDEDE',
                  },
                  '.slick-list': {
                    borderRight: '2px solid #fff !important',
                  },
                  '.slick-track': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                }}
              >
                <QuickStats
                  data={convertData(state?.data[0], account.currency_code)}
                />
              </Box>
            ) : null
          }
        />
      </ErrorBoundary>
    </Databox>
  );
};

export { QuickStatsContainer };
