import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { TextField, Box, Modal } from '@mui/material';

const StyledBox = styled(Box)(
  ({ theme }) => `
      display:flex ;
      justify-content:center;
      align-items:center;
      flex-direction:row;
      border:1px solid ${theme.palette.primary.main};
      border-radius:${theme.spacing(0.5)};
      margin:${theme.spacing(3)} ${theme.spacing(0)};
              `
);

const StyledTextField = styled(TextField)(
  ({ theme }) => `
        padding-left:8px;
        color: ${theme.palette.primary.main};
        .MuiInput-input{
            color: ${theme.palette.primary.main};
        }
        .MuiInput-root:hover:not(.Mui-disabled):before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.42);
        }
        .MuiInput-root:before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.42);
        }
        .MuiInput-root:hover:not(.Mui-disabled):before {
            border-bottom: 0px solid #323C46;
        } 
        .MuiInput-root:after {
            border-bottom: 0px solid #0096FF;
        }
                `
);

const StyledCloseButton = styled(CloseIcon)(
  ({ theme }) => `
        position: absolute;
        top: ${theme.spacing(1.5)};
        right: ${theme.spacing(1.5)};
        padding: ${theme.spacing(0.2)};
        cursor: pointer;
        background-color:#EDEDED;
        width:${theme.spacing(2)};
        height:${theme.spacing(2)};
        font-size: 1.2rem;
        border-radius: 100%;
           
          `
);

const StyledNavigationBox = styled(Box)(
  () => `
       width: 100%;
       display:flex;
       flex-direction: column;
       justify-content:center;
       align-items:center;
       margin:8px 0px 0px 0px;
            `
);

const StyledModal = styled(Modal)(
  () => `
  right: -8px;
  overflow-y: scroll;
  min-width: 400px; 
 
  @media(max-width:600px){
    min-width: 300px;  
  }
  .MuiModal-root{
    display:flex;
    justify-content:center;
  }
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter:blur(5px);
   }
          `
);

const StyledGlobalModal = styled(Box)(
  ({ theme }) => `
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color:${theme.palette.background.main};
      border-radius: 12px;
      width: 480px;
      padding:${theme.spacing(3)} ${theme.spacing(3)};
      box-shadow: ${theme.shadows[2]};  
      @media(max-width:1400px){
        top: 10%;
        left: 50%;
        transform: translate(-50%, 0%);
        width: 450px;
        }
      @media(max-width:1200px){
      top: 5%;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 400px;
      }
      @media(max-width:600px){
        padding:${theme.spacing(2)} ${theme.spacing(1.5)};
        width: 95%;
        }
     
    `
);

export {
  StyledBox,
  StyledTextField,
  StyledCloseButton,
  StyledNavigationBox,
  StyledModal,
  StyledGlobalModal,
};
