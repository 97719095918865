import { styled } from '@mui/system';

const GraphGrid = styled('div', {
  shouldForwardProp: (prop) => prop !== 'cellCount',
})(
  ({ cellCount }) => `
  align-items:center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(${cellCount}, 1fr) 5fr;
  grid-template-rows: repeat(3,1fr);
  height: 100%;
`
);

const TooltipGrid = styled(GraphGrid)(
  () => `
  align-items: stretch;
  bottom: 1rem;
  height: auto;
  left: 1rem;
  position: absolute;
  right: 1rem;
  top:1rem;
  z-index:3;
  
`
);

const TooltipColorBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})(
  ({ theme, backgroundColor }) => `
  background-color: ${backgroundColor};
  height: 10px;
  width: 10px;
  border: 1px solid ${theme.palette.border.main}
`
);

export { GraphGrid, TooltipGrid, TooltipColorBox };
