import React from 'react';

import { Container, Box, Typography } from '@mui/material';

import { Toolbar } from '../../../components/Toolbar';
import { AccountSelector } from '../../../components/AccountSelector';

import { TempCompanySelector } from '../../../components/TempCompanySelector';

const POASToolbar = () => (
  <Toolbar>
    <Container
      maxWidth='none'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: 1,
        my: 1,
        '@media(min-width: 800px)': {
          gap: 3.5,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
      }}
    >
      <Box
        sx={{
          minWidth: '200px',
          flex: 1,
          '@media(min-width: 800px)': { maxWidth: '400px' },
        }}
      >
        <TempCompanySelector />
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{
          flex: 1,
          order: -1,
          '@media(min-width: 800px)': { order: 0 },
        }}
      >
        <Typography variant='h1' textAlign='center'>
          POAS & Profit Calculator
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          '@media(min-width: 800px)': { maxWidth: '400px' },
        }}
      >
        <AccountSelector
          filterFunction={(acc) => acc.platform === 'GOOGLE_ANALYTICS'}
          noAccountsMessage='0 Google Analytics Accounts'
        />
      </Box>
    </Container>
  </Toolbar>
);

export { POASToolbar };
