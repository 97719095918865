const initialValues = {
  name: '',
  description: '',
  queries: [
    {
      field: 'all',
      value: { type: 'field', value: '' },
      filter: '',
      children: [],
    },
  ],
  actions: [
    {
      field: 'all fields',
      action: 'DO_NOTHING',
      value: '',
    },
  ],

  else_actions: [],
  copy_action: false,
};

export { initialValues };
