import { styled } from '@mui/system';
import { Box, InputLabel } from '@mui/material';
import { Field } from 'formik';

const StyledFormikWrapper = styled(Box)(
  ({ theme }) => `
      display: flex;
      flex-direction: column;
      .form-control {
        width: 100%;
        backgroundColor: #673ab700;
        background: #673ab700;
        border: 1px solid ${theme.palette.border.main};
      }
      .flag-dropdown{
        backgroundColor: #673ab700;
        background: #673ab700;
        border: 1px solid ${theme.palette.border.main};
      }
    `
);

const StyledFormikField = styled(Field)(
  () => `
 .MuiFormHelperText-root{
    margin-left: 0px;
    margin-right: 0px;
 }
`
);

const StyledFormikFieldCheckbox = styled(Field)(
  ({ theme }) => `
  display: flex;
  justify-content: flex-start;
  align-items:center;
  color:${theme.palette.text.grey};
 .MuiFormHelperText-root{
    margin-left: 0px;
    margin-right: 0px;
 }

`
);

const StyledInputLabel = styled(InputLabel)(
  ({ theme }) => `
      margin-bottom:${theme.spacing(0.3)} `
);

export {
  StyledFormikWrapper,
  StyledFormikField,
  StyledInputLabel,
  StyledFormikFieldCheckbox,
};
