import React from 'react';
import { Box, Typography } from '@mui/material';
import { DataboxHeading } from '../DataboxHeading';
import { ReactComponent as ErrorIconUnsized } from '../../assests/images/artWork/ErrorIconUnsized.svg';

const GlobalErrorComponent = ({ message = 'Something went wrong' }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingTop: '100px',
    }}
  >
    <Box width='200px' maxWidth='80%'>
      <ErrorIconUnsized className='errorIcon' />
    </Box>
    <Typography variant='body1' sx={{ mt: 1, textAlign: 'center' }}>
      {message}
    </Typography>
  </Box>
);

const ErrorComponent = ({ title, info, message = 'Something went wrong' }) => (
  <>
    <DataboxHeading title={title} info={info} />
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingTop: '100px',
      }}
    >
      <Box width='124px' maxWidth='75%'>
        <ErrorIconUnsized className='errorIcon' />
      </Box>
      <Typography variant='body1' sx={{ mt: 1, textAlign: 'center' }}>
        {message}
      </Typography>
    </Box>
  </>
);

export { GlobalErrorComponent, ErrorComponent };
