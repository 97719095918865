import React from 'react';
import { Typography } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import { StyledButton } from '../utils';

const RemoveButton = ({ text, onClick, edit = false }) => (
  <StyledButton
    startIcon={
      !edit ? (
        <DeleteOutlined
          sx={{
            mr: 0.5,
          }}
          fontSize='small'
        />
      ) : (
        <LogoutIcon
          sx={{
            mr: 0.5,
          }}
          fontSize='small'
        />
      )
    }
    variant='pillOutlined'
    sx={{
      '&:hover': {
        borderColor: (theme) => theme.palette.error.main,
        color: (theme) => theme.palette.error.main,
        '& .MuiSvgIcon-root': {
          color: (theme) => `${theme.palette.error.main} !important`,
        },
      },
      minWidth: '120px',
    }}
    onClick={onClick}
  >
    <Typography variant='smallMedium'>{text}</Typography>
  </StyledButton>
);

export { RemoveButton };
