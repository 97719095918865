import React from 'react'; // useState

import { Button, Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { ExportExcel } from '../../../../../widgets/ExportToExcel/ExportExcel';

import { POASBarGraph } from './POASBarGraph';
import { StyledYearlyGraph } from './StyledYearlyGraph';

const POASYearlyGraph = ({
  value,
  state,
  graphData,
  currency,
  setEditButtonState,
  poasSheetData,
}) => {
  const columns = [
    'Date',
    'Packing Cost',
    'Marketing Cost',
    'Packing Cost',
    'Shipping',
    'Tax',
    'Other Costs',
    'POAS',
    'Profit Percentage',
  ];
  const sheetNames = ['Poas Data'];
  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledYearlyGraph>
          <Box className='graphBox'>
            <Typography variant='h5' className='headingTag'>
              {`Your POAS for ${value.split('-')[0]}
              is ${
                graphData.datasets[0].data[graphData.labels.indexOf(value)]
              }`}
            </Typography>
            <POASBarGraph data={graphData} currency={currency} />
          </Box>
          <Box className='noteBox'>
            <Typography variant='smallMedium'>
              To compare POAS with previous months click on edit and enter
              values for previous months.
            </Typography>
          </Box>
          <Stack
            display='flex'
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            {state === 'SUCCESS' && (
              <ExportExcel
                filename='Poas Report'
                sheetNames={sheetNames}
                columnList={columns}
                dataToWrite={poasSheetData}
                title='Poas Insights Report'
              />
            )}
            <Button
              variant='contained'
              sx={{ width: 'auto' }}
              onClick={() => {
                setEditButtonState(false);
              }}
            >
              Edit
              <EditOutlinedIcon
                sx={{
                  ml: 1,
                  fontSize: '1rem',
                }}
              />
            </Button>
          </Stack>
        </StyledYearlyGraph>
      </Grid>
    </Grid>
  );
};

export { POASYearlyGraph };
