import React from 'react';

import { getHeadCells } from '../../../../components/Headcell/Headcell';
import { DashboardCustomTable } from '../../../../components/Table';

// this filters data from the array of objects recieved as props
// the default filter argument is the current year
const filterFunction = (dataArg, filter = new Date().getFullYear()) =>
  dataArg[filter];

const dataFormatter = (data) =>
  data.reduce(
    (prev, current) => ({
      ...prev,
      ...Object.keys(current).reduce((prev2, current2) => {
        if (!current[current2].error) {
          return { ...prev2, [current2]: current[current2] };
        }
        return prev2;
      }, {}),
    }),
    {}
  );

const MonthlyInsights = ({ data, filter, currencyCode = 'ZZZ' }) => {
  const yearWithLength = data.find((obj) => {
    const keys = Object.keys(obj);
    return keys.length === 1 && obj[keys[0]].length === 13;
  });

  const expectedYear = yearWithLength && Object.keys(yearWithLength)[0];
  const firstDataItem = yearWithLength ? yearWithLength[expectedYear][0] : {};
  const defaultFormatFunction = (row, key) => String(row[key]);

  const excludedKeys = [];

  const filteredData = filterFunction(dataFormatter(data), filter.value).filter(
    (row) => row.Month !== 'Total' && row.Clicks !== undefined
  );

  const totalRow = filterFunction(dataFormatter(data), filter.value).find(
    (row) => row.Month === 'Total'
  );

  return (
    <DashboardCustomTable
      headCells={getHeadCells(
        firstDataItem,
        excludedKeys,
        currencyCode,
        defaultFormatFunction
      )}
      rows={filteredData}
      totalRow={totalRow}
      rowIdField='Month'
      rowsPerPage={12}
      verticalBorders
    />
  );
};

export { MonthlyInsights };
