import styled from '@emotion/styled';

const StyledPOASBarGraph = styled.div`
  width: 100%;
  .parent {
    position: relative;
    background-size: 100% 100%;
    width: 100%;
    height: 350px;
    @media (max-width: 798px) {
      height: 250px;
    }
    @media (max-width: 600px) {
      height: 200px;
    }
  }
  #myChart {
    position: absolute;
    width: 100%;
    height: 100% !important;
    padding: 0 0px !important;
  }
`;

export { StyledPOASBarGraph };
