import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Button,
  Box,
  Typography,
  DialogActions,
  DialogContent,
} from '@mui/material';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import { CheckCircleSharp, CancelRounded } from '@mui/icons-material';
import { usePasswordConfirmationMutation } from '../../../../api/RTK/services/user';
import { FormikPasswordField } from '../../../../widgets/FormikForm/FormikField';
import { HeadingWithUnderline } from '../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { DeleteUserSchema } from '../../Schema/schemas';
import { FetchDisplay } from '../../../../components/FetchDisplay';

const PasswordDeleteDialog = ({
  open,
  handleClose,
  handleConfirm,
  copy,
  buttonText,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const closeModalWithClick = () => {
    handleClose();
  };

  useEffect(() => {
    if (!open) {
      handleClose();
    }
  }, [open]);

  const [
    getPassword,
    {
      isLoading: passwordIsLoading,
      isError: passwordIsError,
      error: passwordError,
      isUninitialized,
    },
  ] = usePasswordConfirmationMutation();

  const handleOnSubmit = (values) => {
    try {
      getPassword({ password: values.password })
        .unwrap()
        .then(() => {
          handleConfirm();
          setTimeout(() => {
            handleClose();
          }, 2000);
        })
        .catch(() => {
          setTimeout(() => {
            handleClose();
          }, 2000);
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeModalWithClick}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{
        sx: {
          maxHeight: 'auto',
          maxWidth: 'auto',
          borderRadius: '12px',
          display: 'grid',
          placeContent: 'center',
          justifyItems: 'center',
        },
      }}
    >
      <FetchDisplay
        isLoading={passwordIsLoading}
        isError={passwordIsError}
        LoadingElement={null}
        ErrorElement={
          <DialogContent>
            <Box>
              <Box
                sx={{
                  display: 'grid',
                  placeItems: 'center',
                  maxHeight: '300px',
                  maxWidth: '500px',
                  minHeight: '150px',
                  minWidth: '300px',
                }}
                component='span'
              >
                <CancelRounded
                  sx={{ height: '2rem', width: '2rem', color: 'error.main' }}
                />
                <Typography
                  variant='bodySemiBold'
                  fontSize='1.125rem'
                  textAlign='center'
                  component='p'
                >
                  {passwordError?.data?.message
                    ? passwordError?.data?.message
                    : 'Error'}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        }
        isPrefetch={isUninitialized}
        PrefetchElement={
          <DialogContent>
            <HeadingWithUnderline
              heading={`Are you sure you want to ${_.lowerCase(buttonText)}?`}
            />
            <DialogActions
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 3,
                width: '100%',
                '.form': {
                  width: '100%',
                },
              }}
            >
              <Formik
                enableReinitialize
                validateOnBlur={false}
                initialValues={{
                  password: '',
                }}
                validationSchema={DeleteUserSchema}
                onSubmit={handleOnSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} className='form'>
                    <Typography variant='smallBold' color='secondary'>
                      {copy}
                    </Typography>
                    <Box mt={2}>
                      <FormikPasswordField
                        required
                        name='password'
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        label='Password'
                        placeholder='Enter password'
                        error={Boolean(errors.password) && touched.password}
                        helperText={touched.password && errors.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        setShowPassword={setShowPassword}
                        showPassword={showPassword}
                      />
                    </Box>
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      gap={3}
                      mt={3}
                    >
                      <Button
                        onClick={handleClose}
                        variant='outlined'
                        fullWidth
                      >
                        Cancel
                      </Button>
                      <Button type='submit' variant='contained' fullWidth>
                        {buttonText}
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </DialogActions>
          </DialogContent>
        }
        SuccessElement={
          <DialogContent>
            <Box
              sx={{
                display: 'grid',
                placeItems: 'center',
                maxHeight: '200px',
                maxWidth: '500px',
                minHeight: '100px',
                minWidth: '300px',
              }}
              component='span'
            >
              <CheckCircleSharp
                sx={{
                  height: '2rem',
                  width: '2rem',
                  color: 'primary.main',
                }}
              />
              <Typography
                variant='bodySemiBold'
                fontSize='1.125rem'
                textAlign='center'
                component='p'
              >
                Password Validation Successful
              </Typography>
            </Box>
          </DialogContent>
        }
      />
    </Dialog>
  );
};

export { PasswordDeleteDialog };
