// import React, { useContext, useEffect } from 'react';

// import { UserInfoContext } from '../../context/UserInfoContext';
// import { UserInfoDispatchContext } from '../../context/UserInfoDispatchContext';
// import { setCompany } from '../../utils/userInfoDispatchFunctions';
// import { PillMenu } from '../PillMenu/Success';

// /*
//   Temporary company selector used for internal users
// */
// const TempCompanySelector = () => {
//   const { companyList, company } = useContext(UserInfoContext);
//   const dispatch = useContext(UserInfoDispatchContext);

//   const handleSelectedCompanyChange = (newCompany) => {
//     setCompany(dispatch, newCompany);
//   };
//   useEffect(() => {
//     if (companyList.length === 1) {
//       setCompany(dispatch, companyList[0]);
//     }
//   }, [companyList, company]);

//   return (
//     <PillMenu
//       name='companyMenu'
//       type='success'
//       options={
//         [...companyList].sort((a, b) =>
//           a.name.localeCompare(b.name, undefined, { numeric: true })
//         ) || []
//       }
//       selected={company || null}
//       handleChange={handleSelectedCompanyChange}
//       defaultText='Select a Company'
//       showPulse={!company}
//     />
//   );
// };

// export { TempCompanySelector };

import React, { useContext } from 'react';

import { UserInfoContext } from '../../context/UserInfoContext';
import { UserInfoDispatchContext } from '../../context/UserInfoDispatchContext';
import { setCompany } from '../../utils/userInfoDispatchFunctions';
import { PillMenu } from '../PillMenu/Success';

/*
  Temporary company selector used for internal users
*/
const TempCompanySelector = () => {
  const { companyList, company } = useContext(UserInfoContext);
  const dispatch = useContext(UserInfoDispatchContext);
  const handleSelectedCompanyChange = (newCompany) => {
    setCompany(dispatch, newCompany);
  };

  return (
    <PillMenu
      name='companyMenu'
      type='success'
      options={
        (companyList &&
          [...companyList].sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { numeric: true })
          )) ||
        []
      }
      selected={company || null}
      handleChange={handleSelectedCompanyChange}
      defaultText='Select a Company'
      showPulse={!company}
    />
  );
};

export { TempCompanySelector };
