import { SET_PLATFORM_CREDS } from '../constants/actions/actions';

const UserReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'LOGIN':
      // set access token & set isLoggedIn
      return {
        ...state,
        isLoggedIn: true,
        accessToken: payload.accessToken,
      };

    case 'LOGOUT':
      // delete token & unset isLoggedIn
      return {
        ...state,
        ...payload,
      };

    case 'SET_USER':
      // update user info fetched from /me
      return {
        ...state,
        user: payload.user,
        isLoggedIn: payload.isLoggedIn,
      };

    case 'SET_ACCOUNTLIST':
      // update accounts info fetched from /accounts
      return {
        ...state,
        accountList: payload.accountList,
        accountListFetchingStatus: 'SUCCESS',
      };
    case 'ERROR_WHILE_FETCHING_ACCOUNT_LIST':
      return {
        ...state,
        accountListFetchingStatus: 'ERROR',
      };
    case 'SET_ACCOUNT':
      // set account
      return {
        ...state,
        account: payload.account,
      };

    case 'SET_COMPANYLIST':
      // update accounts info fetched from /accounts
      return {
        ...state,
        companyList: payload.companyList,
      };

    case 'SET_COMPANY':
      // update accounts info fetched from /accounts
      return {
        ...state,
        company: payload.company,
        account: null,
        accountList: [],
        accountListFetchingStatus: 'FETCHING',
      };
    case 'SET_COMPANY_AND_ACCOUNT':
      // update accounts info fetched from /accounts
      return {
        ...state,
        company: payload.company,
        account: payload.account,
      };
    case 'SET_ACCESS_TOKEN':
      // update newly feteched access token
      return {
        ...state,
        accessToken: payload.accessToken,
      };

    case SET_PLATFORM_CREDS:
      // update platform creds of user
      return {
        ...state,
        platformCreds: payload.platformCreds,
      };

    default:
      return state;
  }
};

export { UserReducer };
