/* eslint-disable consistent-return */
import React, { useState, useContext, useEffect } from 'react';

import { Backdrop, Fade, Button, Box, Typography, Link } from '@mui/material';
import { Form, Formik } from 'formik';
import { useLocation } from 'react-router-dom';

import { CurrencySelect } from '../utils/CurrencySelector';
import { ShopifySchema } from '../../../../../Components/Forms/Schema';
import { FormikField } from '../../../../../Components/Forms/FormikField';
import { AccountsService } from '../../../../../../../services/accounts';
import { ErrorAlert } from '../../../../../../../widgets/NewAlerts/ErrorAlert';
// import { ShopifyOAuth2FlowComponent } from '../../../../../../../services/ShopifyFLow';
import {
  StyledGlobalModal,
  StyledModal,
  StyledNavigationBox,
} from '../utils/StyledPlatformComponents';

import { LogoHeadingWithUnderline } from '../../../../../../../widgets/HeadingWithUnderline/HeadingWithUnderline';
import { LinkComponent } from '../utils/PlatformComponents';
import { CloseButton } from '../../../../../../../widgets/CloseButton';
import { ShopifyLogo } from '../../../../../../../constants/endpoints/assests/logos';
import { UserInfoContext } from '../../../../../../../context/UserInfoContext';

const ShopifyPlatform = ({ open, handleCloseModal }) => (
  <StyledModal
    open={open}
    onClose={handleCloseModal}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={open}>
      <StyledGlobalModal>
        <LogoHeadingWithUnderline
          src={ShopifyLogo}
          heading='Link Shopify Platforms'
        />
        <Box
          display='flex'
          justifyContent='center'
          alignItems='flex-start'
          flexDirection='column'
          component='ul'
          sx={{
            mb: 2,
            gap: 2,
            listStyleType: 'square',
            listStylePosition: 'inside',
          }}
        >
          <Typography variant='smallBold'>Installation Steps:</Typography>
          <Typography variant='smallRegular' component='li'>
            Navigate to the &quot;
            <Typography component='span' variant='smallRegular' color='primary'>
              Connect Shopify Store
            </Typography>
            &quot; button.
          </Typography>
          <Typography variant='smallRegular' component='li'>
            You will be automatically redirected to the Adhelp.io Product Feed
            Manager Shopify App Installation Page.
          </Typography>
          <Typography variant='smallRegular' component='li'>
            Click on the &quot;
            <Typography component='span' variant='smallRegular' color='primary'>
              Install
            </Typography>
            &quot; button to initiate the installation process.
          </Typography>
          <Typography variant='smallRegular' component='li'>
            Once the app is successfully installed, you will be redirected to
            Adhelp.io. Here, label the connection, select the desired currency,
            and proceed by clicking on the &quot;
            <Typography component='span' variant='smallRegular' color='primary'>
              Create Account
            </Typography>
            &quot; button.
          </Typography>
        </Box>
        <StyledNavigationBox>
          <Button
            component={Link}
            variant='contained'
            href='https://apps.shopify.com/'
          >
            Connect Shopify Store
          </Button>
        </StyledNavigationBox>
        <CloseButton onClick={handleCloseModal} />
      </StyledGlobalModal>
    </Fade>
  </StyledModal>
);

const ShopifyPlatformNonAdhelp = () => {
  const { company, user } = useContext(UserInfoContext);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const ShopifyData = JSON.parse(localStorage.getItem('ShopifyUserData'));
  const CompanyData = localStorage.getItem('AdHelp-company');

  const initialValues = {
    account_name: ShopifyData !== null ? ShopifyData?.name : '',
    shop_url: ShopifyData !== null ? `https://${ShopifyData?.domain}` : '',
    currency_code: ShopifyData !== null ? ShopifyData?.country_code : '',
  };

  useEffect(() => {
    if (
      CompanyData !== null &&
      location?.pathname === '/accounts' &&
      ShopifyData !== null &&
      ['ADMIN', 'OWNER'].includes(user.role)
    ) {
      const timeoutId = setTimeout(() => {
        setOpen(true);
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  const handleCloseModal = () => {
    setOpen(false);
    localStorage.removeItem('ShopifyUserData');
  };

  const handleOnSubmit = async (values, formikHelpers) => {
    try {
      formikHelpers.resetForm();
      const payload = {
        data: [
          {
            name: values.account_name,
            customer_id: values.shop_url,
            currency_code: values.currency_code,
            platform: 'SHOPIFY',
          },
        ],
        company_id: company ? company?.id : null,
      };

      const response = await AccountsService.createAccounts(payload);

      if (response.status === 201) {
        handleCloseModal();
      } else {
        const errorResponse = response.data[0];
        const errorMessage1 = Object.values(errorResponse)[0];
        setErrorMessage(errorMessage1);
      }
    } catch (error) {
      setErrorMessage('Something went wrong.');
    }
  };

  return (
    <StyledModal
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <StyledGlobalModal>
          {errorMessage && <ErrorAlert message={errorMessage} />}
          <LogoHeadingWithUnderline
            src={ShopifyLogo}
            heading='Link Shopify Platforms'
          />
          <Box sx={{ mb: 2 }}>
            <Typography variant='smallRegular'>
              Please enter the below details, then click on the link provided
              and proceed to install the app in the redirected page.
            </Typography>
          </Box>
          <Box>
            <Formik
              initialValues={initialValues}
              onSubmit={handleOnSubmit}
              validationSchema={ShopifySchema}
            >
              {({ values, errors, handleChange, handleBlur }) => (
                <Form>
                  <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
                    <FormikField
                      name='account_name'
                      label='Account Name'
                      required
                      type='text'
                      placeholder='Enter your business name'
                      value={values.account_name}
                      error={Boolean(errors.account_name)}
                      helperText={errors.account_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Box>
                  {/* <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
                    <FormikField
                      name='shop_url'
                      label='Shopify Shop URL'
                      required
                      type='text'
                      placeholder='Paste your shop URL including http/https'
                      value={values.shop_url}
                      error={Boolean(errors.shop_url)}
                      helperText={errors.shop_url}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      readOnly
                    />
                  </Box> */}
                  <Box sx={{ height: (theme) => theme.spacing(10.8) }}>
                    <CurrencySelect
                      defaultValue={
                        ShopifyData !== null ? ShopifyData?.currency : 'USD'
                      }
                      value={values.currency}
                      handleChange={handleChange}
                    />
                  </Box>
                  <Button
                    variant='contained'
                    type='submit'
                    fullWidth
                    sx={{ mb: 1 }}
                    disableFocusRipple
                    disableRipple
                  >
                    Create Account
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
          <StyledNavigationBox>
            <LinkComponent
              text='Go to Shopify'
              to='https://www.shopify.com/in'
            />
          </StyledNavigationBox>
          <CloseButton onClick={handleCloseModal} />
        </StyledGlobalModal>
      </Fade>
    </StyledModal>
  );
};

export { ShopifyPlatform, ShopifyPlatformNonAdhelp };
