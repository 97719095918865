import React from 'react';

import { Paper } from '@mui/material';

const Databox = ({ children, sx }) => (
  <Paper
    component='section'
    sx={{
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      position: 'relative',
      height: '100%',
      overflow: 'hidden',
      ...sx,
    }}
  >
    {children}
  </Paper>
);

export { Databox };

