import * as React from 'react';
import { Box, FormHelperText, Radio, Typography } from '@mui/material';
import { Field } from 'formik';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ColoPicker = ({ onChange, onBlur, name, error }) => {
  const List = [
    { id: 1, color: '#4BB1F8', value: 'BLUE', idName: 'radioButton1' },
    { id: 2, color: '#53DB89', value: 'GREEN', idName: 'radioButton2' },
    { id: 3, color: '#F98A4B', value: 'ORANGE', idName: 'radioButton3' },
    { id: 4, color: '#F96868', value: 'RED', idName: 'radioButton4' },
    { id: 5, color: '#FDB107', value: 'YELLOW', idName: 'radioButton5' },
  ];
  return (
    <FormControl sx={{ mb: 2 }}>
      <Typography variant='h5' textAlign='start' sx={{ mb: 1 }}>
        Choose a color to set priority
      </Typography>
      <RadioGroup
        defaultValue='#4BB1F8'
        name='radio-buttons-group'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
        }}
      >
        {List.map((items) => (
          <Box
            key={items.id}
            sx={{
              width: '45px',
              height: '45px',
              borderRadius: '45px',
              backgroundColor: items.color,
              position: 'relative',
              display: 'flex',
              mr: 1,
            }}
          >
            <Field
              id={items.idName}
              as={Radio}
              icon={
                <CheckCircleIcon
                  sx={{ visibility: 'hidden' }}
                  color='primary'
                />
              }
              checkedIcon={
                <CheckCircleIcon
                  color='primary'
                  sx={{
                    '&.MuiSvgIcon-root': {
                      backgroundColor: '#fff',
                      borderRadius: '100%',
                    },
                  }}
                />
              }
              sx={{
                position: 'absolute',
                top: '-5px',
                right: '-5px',
                p: '0px 0px 25px 25px',
                backgroundColor: 'none',
              }}
              required
              name={name}
              type='radio'
              value={items.value}
              onChange={onChange}
              onBlur={onBlur}
              disableRipple
              disableFocusRipple
              disableTouchRipple
            />
          </Box>
        ))}
      </RadioGroup>
      <FormHelperText error>{error}</FormHelperText>
    </FormControl>
  );
};
export { ColoPicker };
