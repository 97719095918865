/* eslint-disable import/no-default-export */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const CustomizedColumnSlice = createSlice({
  name: 'CustomizedColumn',
  initialState: {
    selectedMetric: [],
  },
  reducers: {
    addSelectedMetric: (state, action) => {
      state.selectedMetric = action.payload;
    },
    addCheckedMetric: (state, action) => {
      state.selectedMetric.push(action.payload);
    },
    removeCheckedMetric: (state, action) => {
      state.selectedMetric = state.selectedMetric.filter(
        (metric) => metric.column !== action.payload
      );
    },
    removeSelectedMetric: (state, action) => {
      const metricToRemove = action.payload;
      state.selectedMetric = state.selectedMetric.filter(
        (item) => item.value !== metricToRemove
      );
    },
    resetSelectedMetric: (state) => {
      state.selectedMetric = [];
    },
  },
});

// eslint-disable-next-line import/no-default-export
export default CustomizedColumnSlice.reducer;
export const {
  addSelectedMetric,
  addCheckedMetric,
  removeCheckedMetric,
  removeSelectedMetric,
  resetSelectedMetric,
} = CustomizedColumnSlice.actions;
