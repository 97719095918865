import React from 'react';

const ElementPicker = ({
  case1,
  case2,
  case3,
  element1,
  element2,
  element3,
  defaultElement = <>Error</>,
}) => {
  switch (true) {
    case case1:
      return element1;
    case case2:
      return element2;
    case case3:
      return element3;
    default:
      return defaultElement;
  }
};

export { ElementPicker };
