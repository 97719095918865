import _, { startCase } from 'lodash';

const toTitleCase = (text) => startCase(text);

const getCellRange = (rowNo, startCol, endCol) =>
  `${String.fromCharCode(startCol + 64)}${rowNo}:${String.fromCharCode(
    endCol + 64
  )}${rowNo}`;

const patternCell = (cell) => {
  // eslint-disable-next-line no-param-reassign
  cell.font = {
    bold: true,
  };
  // eslint-disable-next-line no-param-reassign
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFF00' },
  };
  // eslint-disable-next-line no-param-reassign
  cell.border = {
    top: { style: 'medium', color: { argb: '0000000' } },
    left: { style: 'medium', color: { argb: 'FFFF00' } },
    bottom: { style: 'medium', color: { argb: '0000000' } },
    right: { style: 'medium', color: { argb: 'FFFF00' } },
  };
};

const isCurrency = (text) => {
  if (
    [
      'Cost',
      'Total Conversion Value',
      'Revenue',
      'Spend',
      'Conv Value',
    ].includes(text)
  ) {
    return true;
  }
  return false;
};

const aggregateFunctionGenerator = (dataObject) =>
  Object.keys(dataObject).map((key, index) => {
    if (index === 0) {
      return 'Total';
    }
    if (
      key.toLowerCase().includes('roas') ||
      key.toLowerCase().includes('cpc')
    ) {
      return 'avg';
    }
    if (typeof dataObject[key] === 'string') {
      return 'string';
    }
    return 'sum';
  });

const aggregateCalculator = (aggregateFunctionArray, dataArray, key, index) => {
  switch (aggregateFunctionArray[index]) {
    case 'Total': {
      return 'Total';
    }
    case 'avg': {
      return _.round(_.sumBy(dataArray, key) / dataArray.length, 2);
    }
    case 'string': {
      return '';
    }
    default: {
      return _.sumBy(dataArray, key);
    }
  }
};

const formatQuickStatsData = (data) =>
  Object.entries(data).map(([key, value]) => ({
    label: toTitleCase(key),
    value,
  }));

export {
  toTitleCase,
  getCellRange,
  patternCell,
  isCurrency,
  aggregateCalculator,
  aggregateFunctionGenerator,
  formatQuickStatsData,
};
