import React from 'react';
import { useSelector } from 'react-redux';
import { ToggleButton } from '@mui/material';
import { Link } from 'react-router-dom';

import { StyledToggleButtonGroup } from '../StyledComponents/StyledCommon';

const ToggleButtonFeeds = ({ toggled, setToggled }) => {
  const handleToggledFeed = (event, newToggled) => {
    if (newToggled !== null) {
      setToggled(newToggled);
    }
  };
  const feedSelectId = useSelector(
    (state) => state.feedSummaryRedux.feedId
  );

  return (
    <StyledToggleButtonGroup
      value={toggled}
      exclusive
      onChange={handleToggledFeed}
      aria-label='toggle button group'
      className='feedToggleParent'
    >
      <ToggleButton
        value='Settings'
        aria-label='toggle button 1'
        component={Link}
        to='../feeds/feed-settings'
        color={toggled === 'Settings' ? 'primary' : 'standard'}
        className='feedToggle1'
      >
        Settings
      </ToggleButton>

      <ToggleButton
        value='Enhance'
        aria-label='toggle button 1'
        component={Link}
        to='../feeds/enhance'
        color={toggled === 'Enhance' ? 'primary' : 'standard'}
        className='feedToggle3'
        disabled={!feedSelectId}
      >
        Enhance
      </ToggleButton>

      <ToggleButton
        value='Quality'
        aria-label='toggle button 1'
        component={Link}
        to='../feeds/quality'
        color={toggled === 'Quality' ? 'primary' : 'standard'}
        className='feedToggle4'
        disabled={!feedSelectId}
      >
        Quality
      </ToggleButton>

      <ToggleButton
        value='Preview'
        aria-label='toggle button 2'
        component={Link}
        to='../feeds/preview'
        color={toggled === 'Preview' ? 'primary' : 'standard'}
        className='feedToggle5'
        disabled={!feedSelectId}
      >
        Preview
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export { ToggleButtonFeeds };
