import { object, string, boolean, array, mixed } from 'yup';

const filterSchema = object({
  name: string().required('Name is required'),
  description: string(),
  copy_filter: boolean(),
  queries: array().of(
    object({
      field: string().required('Field is required'),
      filter: string().when('field', {
        is: 'all',
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Method is required'),
      }),
      value: object().when('field', {
        is: 'all',
        then: object({
          type: string().notRequired().oneOf(['field', 'static']),
          value: mixed().notRequired(),
          caseSensitive: boolean().notRequired(),
        }),
        otherwise: object().when('filter', {
          is: (filter) => ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter),
          then: object({
            type: string().notRequired(),
            value: mixed().notRequired(),
            caseSensitive: boolean().notRequired(),
          }),
          otherwise: object().when('filter', {
            is: (v) =>
              [
                'LENGTH_EXCEEDS',
                'LENGTH_DOES_NOT_EXCEEDS',
                'WORD_COUNT_EXCEEDS',
                'WORD_COUNT_DOES_NOT_EXCEEDS',
                'IS_GREATER_THAN',
                'IS_GREATER_OR_EQUAL_TO',
                'IS_LESS_THAN',
                'IS_LESS_OR_EQUAL_TO',
              ].includes(v),
            then: object({
              type: string().required().oneOf(['field', 'static']),
              value: mixed()
                .when('type', {
                  is: 'field',
                  then: string(),
                  otherwise: (schema) =>
                    schema.test({
                      name: 'isNumber',
                      message: 'Value must be a number',
                      test: (va) =>
                        // need to check for blank space because isNaN converts a blank space to 0 before checking it and it returns false
                        // eslint-disable-next-line no-restricted-globals
                        va !== ' ' && !isNaN(va),
                    }),
                })
                .required('Value is required'),
              caseSensitive: boolean().notRequired(),
            }),
            otherwise: object({
              type: string().required().oneOf(['field', 'static']),
              value: mixed().required('Value is required'),
              caseSensitive: boolean().notRequired(),
            }),
          }),
        }),
      }),
    })
  ),
});

export { filterSchema };
